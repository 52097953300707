import React, {FC, useContext, useState} from 'react'
import {useTranslation} from "react-i18next";
import Header from "Components/Layout/Header";
import Table from "Components/Layout/Table";
import DeleteModal from "Components/Modal/DeleteModal";
import {Button} from "react-bootstrap";
import useTranslationDataUtils from "../../../../Vendor/Utils/TranslationDataUtils";
import Api from "../../../../Vendor/Api";
import {UserContext} from "../../../../Vendor/Context/UserContextProvider";
import RefreshModal from "../../../../Components/Modal/RefreshModal";
import FormEmptyModal from "../../../../Components/Modal/FormEmptyModal";

import MentionEvent from "../../../../Entity/Notification/Mention/MentionEvent";
import MentionEventOffCanvas from 'Components/Offcanvas/Notification/MentionEventOffCanvas';
import MentionNotificationTable from 'Table/Admin/Edition/MentionNotificationTable';

const NotificationMentionPage: FC = () => {
    const { currentUser } = useContext(UserContext)
    const { t } = useTranslation()
    const {translate} = useTranslationDataUtils()
    const [toggleReload, setToggleReload] = useState<string>('')
    const [modalShow, setModalShow] = useState<boolean>(false)
    const [offCanvasShow, setOffCanvasShow] = useState<boolean>(false)
    const [search, setSearch] = useState<string>('')
    const [mentionEvents, setMentionEvents] = useState<MentionEvent[]>([])
    const [mentionEvent, setMentionEvent] = useState<MentionEvent|undefined>(undefined)
    const [showRefreshModal, setShowRefreshModal] = useState<boolean>(false)
    const [hasPlatform, setHasPlatform] = useState<boolean>(currentUser?.currentOrganization?.platforms
        ? currentUser?.currentOrganization?.platforms?.length > 0
        : false)
    const handleModalClose = (reload = false) => {
        setModalShow(false)
        if (reload) {
            setToggleReload(new Date().toISOString())
        }
    }
    const handleModalShow = () => setModalShow(true)
    const handleOffCanvasShow = () => setOffCanvasShow(true)
    // remove
    const [showDelete, setShowDelete] = useState<boolean>(false)
    const handleDeleteClose = (reload = false) => {
        setMentionEvent(undefined)
        setShowDelete(false)
        if (reload) {
            setToggleReload(new Date().toISOString())
        }
    }
    const handleDeleteShow = () => setShowDelete(true)

    const onSuccess = (mentionEvent: MentionEvent) => {
        setMentionEvent(mentionEvent)
        setModalShow(false)
        setToggleReload(new Date().toISOString())
    }

    const addMentionEvent = () => {
        setMentionEvent(undefined)
        handleModalShow()
    }

    const editMentionEvent = (mentionEvent: MentionEvent) => {
        setMentionEvent(mentionEvent)
        handleOffCanvasShow()
    }

    const deleteMentionEvent = (mentionEvent: MentionEvent) => {
        setMentionEvent(mentionEvent)
        handleDeleteShow()
    }

    const handlePublish = (mentionEvent: MentionEvent) => {
        setShowRefreshModal(true)
        Api.patch(`/admin/edition/notification/mention/${mentionEvent.id}/change-status/online`)
            .then(() => {})
            .catch(() => {})
            .then(() => {
                setToggleReload(new Date().toISOString())
                setShowRefreshModal(false)
            })
    }

    const handleUnPublish = (mentionEvent: MentionEvent) => {
        setShowRefreshModal(true)
        Api.patch(`/admin/edition/notification/mention/${mentionEvent.id}/change-status/offline`)
            .then(() => {})
            .catch(() => {})
            .then(() => {
                setToggleReload(new Date().toISOString())
                setShowRefreshModal(false)
            })
    }

    const actionsRender = (mentionEvent: MentionEvent) => (
        <>
            {mentionEvent?.isGranted?.edit &&
                <Button
                    variant='outline-primary'
                    size='sm'
                    onClick={() => editMentionEvent(mentionEvent)}>
                    <i className="fa-solid fa-eye me-2"></i>
                    {t('app.show')}</Button>
            }
            {mentionEvent?.isGranted?.publish && hasPlatform &&
                <Button
                    variant='outline-success'
                    size='sm'
                    onClick={() => handlePublish(mentionEvent)}>
                    <i className="fa-solid fa-check me-2"></i>
                    {t('app.publish')}</Button>
            }
            {mentionEvent?.isGranted?.unPublish && hasPlatform &&
                <Button
                    variant='outline-danger'
                    size='sm'
                    onClick={() => handleUnPublish(mentionEvent)}>
                    <i className="fa-solid fa-times me-2"></i>
                    {t('app.unPublish')}</Button>
            }
            {mentionEvent?.isGranted?.delete &&
                <Button
                    variant='outline-danger'
                    size='sm'
                    onClick={() => deleteMentionEvent(mentionEvent)}>
                    <i className="fa-solid fa-trash me-2"></i>
                    {t('app.delete')}</Button>
            }
        </>
    );

    const renderTarget = (notificationEvent: MentionEvent) => {
        if (!notificationEvent.collection) {
            return ''
        }

        let result: string = t('app.enum.property.collection.' + notificationEvent.collection.charAt(0).toUpperCase() + notificationEvent.collection.slice(1).replace("-", ""))
        if (notificationEvent.collection === 'bucket' && notificationEvent.entity) {
            result += ' (' + translate(notificationEvent.entity, 'name') + ')'
        }

        return result
    }

    let table = MentionNotificationTable;
    if (table.hasOwnProperty('target')) {
        table.target.render = (mentionEvent: MentionEvent) => renderTarget(mentionEvent)
    }
    if (table.hasOwnProperty('actions')) {
        table.actions.render = actionsRender;
    }

    return (
        <>
            <Header title={t('app.notification_types.mention')}
                    add={addMentionEvent}
                    search={search}
                    setSearch={setSearch}
            />
            <Table
                path="/admin/edition/notification/mention"
                rows={table}
                toggleReload={toggleReload}
                items={mentionEvents}
                setItems={setMentionEvents}
                keyword={search}
                setKeyword={setSearch}
            />
            <MentionEventOffCanvas
                show={offCanvasShow}
                handleClose={() => setOffCanvasShow(false)}
                id={mentionEvent?.id}
                setInfoShow={setModalShow}
                setToggleReload={setToggleReload}
            />
            <FormEmptyModal
                i18nTitle={mentionEvent?.id ? 'app.edit_event' : 'app.add_event'}
                show={modalShow}
                handleClose={handleModalClose}
                onSuccess={onSuccess}
                path={`/admin/edition/notification/mention/${mentionEvent?.id ?? 'new'}`}
                formPath={mentionEvent?.id !== undefined ? true : false}
                method={mentionEvent?.id !== undefined ? 'patch' : 'post'}
            />
            <DeleteModal
                handleClose={handleDeleteClose}
                show={showDelete}
                path={'/admin/edition/notification/mention/' + (mentionEvent?.id ?? 'error')}
                name={'#' + (mentionEvent ? `${mentionEvent.id} - ${mentionEvent.translations}` : 'error')}
            />
            <RefreshModal show={showRefreshModal} />
        </>
    )
}

export default NotificationMentionPage
