import React, { FC, useState } from 'react'
import { classUtils as c } from 'Vendor/Utils/ClassUtils'
import TextType, { TextProps } from './TextType'
import useTranslationDataUtils from "../../../Utils/TranslationDataUtils";

export type PasswordProps = TextProps

const PasswordType: FC<PasswordProps> = ({
    ...rest
}) => {
    const [isVisible, setIsVisible] = useState(false)
    return (
        <div className="input-password">
            <TextType
                type={isVisible ? 'text' : 'password'}
                {...rest}
            />
            <i
                className={c('fa-solid', isVisible ? 'fa-eye-slash' : 'fa-eye')}
                onClick={() => setIsVisible((isVisible) => !isVisible)}
            ></i>
        </div>
    )
}

export default PasswordType
