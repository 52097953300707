import { RouteObject } from 'react-router-dom'
import MainPage from 'Pages/Admin/Edition/PlatformSecurity/MainPage'
import PropertyPage from 'Pages/Admin/Edition/PlatformSecurity/PropertyPage'

const PlatformSecurityRouting: RouteObject[] = [
    {
        index: true,
        element: <MainPage />,
    },
    {
        path: 'properties',
        element: <PropertyPage />,
    },
]

export default PlatformSecurityRouting
