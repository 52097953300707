import React, {FC, useEffect, useState} from 'react'
import Header from "../Layout/Header";
import {useTranslation} from "react-i18next";
import Collection from "../../Config/Collection";
import EntityInterface from "../../Vendor/Definition/EntityInterface";
import Loader from "../../Vendor/Components/Loader";
import {classUtils as c} from "../../Vendor/Utils/ClassUtils";
import CheckboxType from "../../Vendor/Components/Form/Type/CheckboxType";
import ChoiceType from "../../Vendor/Components/Form/Type/ChoiceType";
import {Button, ButtonGroup} from "react-bootstrap";
import _ColumnSubModal from "../Collection/_ColumnSubModal";
import Column from "../../Entity/Collection/Import/Column";
import _ErrorSubModal from "../Collection/_ErrorSubModal";
import Import from "../../Entity/Collection/Import";

type CollectionImportModalProps = {
    collection: Collection,
    entity?: EntityInterface,
    closeImportModal: () => void,
    currentImport?: Import
    setCurrentImport: (currentImport?: Import) => void
    onParamsChange: (currentImport?: Import) => void
    onDetail: () => void
    canDetail: boolean
    canImport: boolean
    onRequest: boolean
    onImport: () => void
}

const CollectionImportModal: FC<CollectionImportModalProps> = ({
                                                                   collection,
                                                                   entity,
                                                                   closeImportModal,
                                                                   currentImport,
                                                                   setCurrentImport,
                                                                   onParamsChange,
                                                                   canDetail,
                                                                   canImport,
                                                                   onDetail,
                                                                   onRequest,
                                                                   onImport,
                                                               }) => {
    const {t} = useTranslation()
    const [showColumnModal, setShowColumnModal] = useState<boolean>(false)
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
    const [columnModalColumn, setColumnModalColumn] = useState<undefined | Column>(undefined)

    const setColumnModal = (column: Column, error = false) => {
        setColumnModalColumn(column)
        if (error) {
            setShowErrorModal(true)
            setShowColumnModal(false)
        } else {
            setShowErrorModal(false)
            setShowColumnModal(true)
        }
    }

    const getErrorButtonColor = (column: Column): string => {
        let color = 'outline-'

        let resolved = true
        if (column.state === 'error_resolved') {
            color += 'success'
        } else if (column.state === 'warning' || column.state === 'error') {
            column.errors.forEach(error => {
                if (!error.ignored && !error.append && !error.choice && !error.replacement && !error.group) {
                    resolved = false
                }
            })

            if (resolved) {
                color += 'primary'
            } else if (column.state === 'warning') {
                color += 'warning'
            } else {
                color += 'danger'
            }
        }

        return color
    }

    const getErrorButtonIcon = (column: Column): string => {
        let icon = 'fa-'

        if (column.state === 'error_resolved') {
            icon += 'circle-exclamation-check'
        } else if (column.state === 'warning' || column.state === 'error') {
            icon += 'circle-exclamation'
        }

        return icon
    }

    const isMatcherColumn = (column: Column): boolean => {
        return column.type === 'identifier' || ('property' === column.type && column.property?.externalIdentifier) || ('email' === column.type && collection === 'contact')
    }

    const getColumnClass = (column: Column): string => {
        let classes: string[] = [];

        if (column.type === 'ignored') {
            classes.push('text-muted')
        } else if (column.state === 'wait') {
            classes.push('text-info')
        } else if (column.state === 'set') {
            classes.push('text-primary')
        } else if (column.state === 'analyzed' || column.state === 'error_resolved') {
            classes.push('text-success')
        } else if (column.state === 'warning') {
            classes.push('text-warning')
        } else if (column.state === 'error') {
            classes.push('text-danger')
        }

        if (column.type === 'ignored') {
            classes.push('fa-eye-slash')
        } else if (column.state === 'set') {
            classes.push('fa-check')
        } else if (column.state === 'analyzed' || column.state === 'error_resolved') {
            classes.push('fa-check-double')
        } else if (column.state === 'warning') {
            classes.push('fa-triangle-exclamation')
        } else if (column.state === 'error') {
            classes.push('fa-hexagon-exclamation')
        } else {
            classes.push('fa-circle-dot')
        }

        return classes.join(' ')
    }

    return (
        <div id="importCollectionModal">
            <Header title={t(`app.collection_import.modal.${collection}.title`)} prevRotate={true} prev={closeImportModal}/>
            <div className="content">
                {
                    currentImport && [
                        'init',
                        'on-upload',
                        'uploaded',
                        'wait-for-analyze',
                        'on-analyze',
                        'analyzed',
                        'analyze-failed'
                    ].includes(currentImport.status) && <div className="collection-form">
                        <h4>{t('app.collection_import.form.title')}</h4>
                        <CheckboxType id={'form_first_line'} name={'form[first_line]'} fullName={'form[first_line]'}
                                      i18nLabel="app.collection_import.form.first_line.label"
                                      checked={currentImport ? currentImport.ignoredFirstRow : true}
                                      setValue={(ignoredFirstRow: boolean) => {
                                          if (currentImport) {
                                              let tempCurrentImport = {...currentImport}
                                              tempCurrentImport.ignoredFirstRow = ignoredFirstRow
                                              setCurrentImport(tempCurrentImport)
                                              onParamsChange(tempCurrentImport)
                                          }
                                      }} required={false} checkedByDefault={true}/>
                        {currentImport?.isCsv && <ChoiceType id={'form_csv_separator'} name={'form[csv_separator]'}
                                                             fullName={'form[csv_separator]'} choices={[
                            {
                                i18nKey: ';',
                                value: ';',
                            },
                            {
                                i18nKey: ',',
                                value: ',',
                            },
                        ]} value={currentImport ? currentImport.csvSeparator : ';'}
                                                             setValue={(value: string | string[]) => {
                                                                 if (currentImport) {
                                                                     let tempCSVSeparator = value as ';' | ','
                                                                     let tempCurrentImport = {...currentImport}
                                                                     tempCurrentImport.csvSeparator = tempCSVSeparator
                                                                     setCurrentImport(tempCurrentImport)
                                                                     onParamsChange(tempCurrentImport)
                                                                 }
                                                             }}/>}
                    </div>
                }
                {currentImport && [
                    'init',
                    'on-init',
                    'on-upload',
                    'wait-for-analyze',
                    'on-analyze',
                ].includes(currentImport.status) && <Loader
                    auto={true}
                    title={`app.collection_import.modal.loading.title`}
                    message={`app.collection_import.modal.loading.${currentImport ? currentImport.status : 'on-upload'}`}
                />}
                {currentImport && ['analyzed', 'detail', 'accepted'].includes(currentImport.status) &&
                    <>
                        <div className="container-fluid bg-light p-3 mb-3 rounded-4 w-100 preview">
                            <div className="w-100 h-100 overflow-auto">
                                {currentImport.preview && <table className="table table-large mb-0">
                                    <thead>
                                    <tr>
                                        <th></th>
                                        {currentImport.columns.map(column =>
                                            <th scope="col" className={c(column.type ===  'ignored' && 'bg-gray-light', 'text-center')}>{column.name}</th>
                                        )}
                                    </tr>
                                    <tr>
                                        <th></th>
                                        {currentImport.columns.map(column =>
                                            <th scope="col" className={c(column.type ===  'ignored' && 'bg-gray-light', 'text-center')}>
                                                <i className={c('fa-solid', getColumnClass(column))}></i>
                                                {isMatcherColumn(column) && <i className="fa-solid fa-bullseye-arrow text-success ms-3"></i>}
                                            </th>
                                        )}
                                    </tr>
                                    <tr>
                                        <th></th>
                                        {currentImport.columns.map(column =>
                                            <th scope="col" className={c(column.type ===  'ignored' && 'bg-gray-light')}>
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <ButtonGroup>
                                                        <Button variant="outline-primary" onClick={() => {
                                                            setColumnModal(column)
                                                        }}><i className="fa-solid fa-edit"></i></Button>
                                                        {column.errors.length > 0 &&
                                                            <Button variant={getErrorButtonColor(column)}
                                                                    onClick={() => {
                                                                        setColumnModal(column, true)
                                                                    }}><i
                                                                className={c("fa-solid", getErrorButtonIcon(column))}></i></Button>}
                                                    </ButtonGroup>
                                                </div>
                                            </th>
                                        )}
                                    </tr>
                                    </thead>
                                    <tbody className="table-group-divider">
                                    {currentImport.preview.map((row, index) =>
                                        <tr>
                                            <th scope="row">{index + 1}</th>
                                            {row.map((columnData, columnIndex) => <td className={c(currentImport?.columns[columnIndex]?.type === 'ignored' && 'bg-gray-light')}>{columnData}</td>)}
                                        </tr>
                                    )}
                                    {((currentImport.rows ?? 0) - (currentImport.preview?.length ?? 0)) > 1 &&
                                        <tr>
                                            <th colSpan={(currentImport.columns?.length ?? 0) > 1 ? (currentImport.columns?.length + 1) : 1}
                                                className="text-center">
                                                {t('app.and_more_line', {count: (currentImport.rows ?? 0) - (currentImport.preview?.length ?? 0)})}
                                            </th>
                                        </tr>
                                    }
                                    </tbody>
                                </table>}
                            </div>
                        </div>
                        <div className="actions">
                            <div>
                                <button className="btn btn-lg btn-secondary me-3" disabled={!canDetail || onRequest}
                                        onClick={onDetail}>{t('app.analyze')}</button>
                                <button className="btn btn-lg btn-primary" disabled={!canImport || onRequest}
                                        onClick={onImport}>{t('app.import')}</button>
                            </div>
                        </div>
                    </>
                }
            </div>
            <_ColumnSubModal
                show={showColumnModal}
                setShow={setShowColumnModal}
                id={columnModalColumn?.id}
                currentImport={currentImport}
                setCurrentImport={setCurrentImport}
            />
            <_ErrorSubModal
                show={showErrorModal}
                setShow={setShowErrorModal}
                id={columnModalColumn?.id}
                currentImport={currentImport}
                setCurrentImport={setCurrentImport}
            />
        </div>
    )
}

export default CollectionImportModal
