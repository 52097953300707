import {RouteObject} from "react-router-dom";
import BucketPage from "Pages/BucketPage";
import ShowPage from "Pages/File/ShowPage";

const BucketRouting: RouteObject[] = [
    {
        index: true,
        element: <BucketPage />,
    },
    {
        path: ':matchBy/:fileSlug',
        element: <ShowPage />
    },
    {
        path: ':id',
        element: <BucketPage />
    },
]

export default BucketRouting
