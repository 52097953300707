import React, {FC, useEffect, useState} from 'react'
import Spinner from "./Spinner";

const GlobalLoader: FC = () => {
    const [show, setShow] = useState<boolean>(false)

    useEffect(() => {
        const idTimeout = setTimeout(() => {
            setShow(true)
        }, 850)

        return () => {
            clearTimeout(idTimeout)
        }
    }, [])

    return (
        <div
            style={{
                height: '100vh',
                width: '100vw',
                display: 'grid',
                placeItems: 'center',
            }}
        >
            <Spinner show={show} />
        </div>
    )
}

export default GlobalLoader
