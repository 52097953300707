import React, {FC, FormEvent, ReactNode, useEffect, useState} from 'react'
import Api from "../Api";
import FormInterface from "Vendor/Definition/Form/FormInterface";
import FieldType from "Vendor/Config/Form/FieldType";
import FormBuilderInterface, {
    FormSkeletonChildrenInterface,
    FormSkeletonType
} from "../Definition/Form/FormBuilderInterface";
import FormBuilder from "../Definition/Form/FormBuilder";
import ChoiceInterface from "../Definition/Form/Options/ChoiceInterface";
import EntityInterface from "../Definition/Form/Options/EntityInterface";
import CollectionInterface from "../Definition/Form/Options/CollectionInterface";
import {EntityChoice} from "./Form/Type/EntityType";
import Type from "../../Config/Property/Type";
import OptionsInterface from "../Definition/Form/OptionsInterface";
import {TranslationField} from "../../Components/Listing/Listing";
import TranslationsInterface from "../Definition/Form/Options/TranslationsInterface";
import SubForm from "./Form/SubForm";

export type DataType = {
    [key: string]: any
}

type ParseChildrenType = (child: React.ReactNode, index?: number) => React.ReactNode
type ParseElementType = (element: React.ReactElement, index?: number) => React.ReactElement
export type FormErrors = {
    [key: string]: string | string[]
}

export type FormProps = {
    path: string
    optionsPath?: boolean | string
    form: FormInterface
    options?: object
    children: ReactNode
    formData: DataType
    setFormData: (data: DataType) => void
    onSubmit?: (e?: FormEvent<HTMLFormElement>) => void | boolean
    onSuccess?: (data: any) => void
    onError?: (errors?: FormErrors) => void
    onResponse?: (response?: any) => void
    className?: string
    patch?: boolean
    formOptions?: FormOptionsInterface
}

export interface FormEntityFieldInterface {
    choices: EntityChoice[]
}

export interface FormEntitiesFieldInterface {
    [key: string]: FormEntityFieldInterface
}

export interface FormCollectionFieldInterface {
    [key: string]: FormEntitiesFieldInterface
}

export interface FormPropertyFieldInterface {
    type: Type
    options?: OptionsInterface
    i18nLabels: TranslationField
}

export interface FormPropertiesFieldInterface {
    [name: string]: FormPropertyFieldInterface
}

export interface FormOptionsInterface {
    entityFields?: FormEntitiesFieldInterface
    collectionFields?: FormCollectionFieldInterface
    propertyFields?: FormPropertiesFieldInterface
}

export const FormPropertyGetFieldType = (type: Type): FieldType => {
    switch (type) {
        case 'textarea':
            return 'Textarea'
        case 'email':
            return 'Email'
        case 'status':
        case 'choice':
            return 'Entity'
        case 'phone':
            return 'Phone'
        case 'date':
            return 'Text'
        case 'number':
            return 'Number'
        case 'checkbox':
            return 'Checkbox'
        case 'text':
        case 'link':
        default:
            return 'Text'
    }
}

const Form: FC<FormProps> = ({
                                 path,
                                 optionsPath,
                                 form,
                                 options,
                                 children,
                                 formData,
                                 setFormData,
                                 onSubmit,
                                 onSuccess,
                                 onError,
                                 onResponse,
                                 className,
                                 patch,
                                 formOptions,
                             }) => {
    const [formSkeleton, setFormSkeleton] = useState<FormSkeletonType>(() => {
        let builder: FormBuilderInterface = new FormBuilder()
        form.builder(builder, options ?? {})
        return builder.getSkeleton(patch === true || formData?.id ? 'patch' : 'post', formData)
    })
    const [initFormOptions, setInitFormOptions] = useState<boolean>(false)

    const resetChildErrors = (children: FormSkeletonChildrenInterface): FormSkeletonChildrenInterface => {
        for (const [name, child] of Object.entries(children)) {
            children[name] = {...child, errors: undefined}

            if (child.children !== undefined) {
                for (const [index, subChild] of Object.entries(child.children)) {
                    child.children[parseInt(index, 10)] = resetChildErrors(subChild)
                }
            }
        }

        return children
    }

    const resetErrors = () => {
        let tempFormSkeleton: FormSkeletonType = {...formSkeleton}

        tempFormSkeleton.children = resetChildErrors(tempFormSkeleton.children)

        setFormSkeleton(tempFormSkeleton)
    }

    const setErrors = (errors: FormErrors) => {

    }

    const childNeedOptions = (children: FormSkeletonChildrenInterface): boolean => {
        let needOptions: boolean = false
        Object.values(children).forEach(child => {
            if (child.type === 'Entity') {
                needOptions = true
            }

            if (child.children && Object.keys(child.children).length) {
                Object.values(child.children).forEach(subChild => {
                    if (childNeedOptions(subChild)) {
                        needOptions = true
                    }
                })
            }
        })

        return needOptions
    }

    const needOptions = (): boolean => {
        if (Object.keys(formSkeleton.children).length) {
            return childNeedOptions(formSkeleton.children)
        }

        return false
    }

    const getSubmittableData = (children: FormSkeletonChildrenInterface): DataType => {
        let data: DataType = {}

        if (formSkeleton && formSkeleton.children !== undefined) {
            for (const [name, child] of Object.entries(children)) {
                if (child.type === 'Translation') {
                    let translationsOptions = child.options as TranslationsInterface
                    if (translationsOptions.locales && translationsOptions.locales.length) {
                        translationsOptions.locales.forEach(locale => {
                            translationsOptions.fields.forEach(field => {
                                data[name][locale][field.name] = formData[name][locale][field.name]
                            })
                        })
                    }
                } else if (child.type === 'Collection') {
                    if (child.children && Object.keys(child.children).length) {
                        for (const [index, subChild] of Object.entries(child.children)) {
                            data[name][parseInt(index, 10)] = getSubmittableData(subChild)
                        }
                    }
                } else {
                    data[name] = formData[name]
                }
            }
        }

        return data
    }

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        let submittable = true
        if (onSubmit) {
            submittable = onSubmit(e) ?? true
        }

        if (submittable && formSkeleton) {
            let data: DataType = getSubmittableData(formSkeleton.children)

            resetErrors()

            if (formSkeleton.method === 'patch') {
                Api.patch(path, data).then(response => {
                    onSuccess && response && onSuccess(response.data)
                }).catch(error => {
                    let errors: FormErrors = error.response.data;
                    setErrors(errors)
                    onError && onError(errors)
                }).then(response => {
                    onResponse && onResponse(response)
                })
            } else {
                Api.post(path, data).then(response => {
                    onSuccess && response && onSuccess(response.data)
                }).catch(error => {
                    let errors: FormErrors = error.response.data;
                    setErrors(errors)
                    onError && onError(errors)
                }).then(response => {
                    onResponse && onResponse(response)
                })
            }
        }
    }

    const configFormOptions = (formOptions: FormOptionsInterface) => {
        let tempFormSkeleton: FormSkeletonType|undefined = formSkeleton !== undefined ? {...formSkeleton} : undefined

        if (tempFormSkeleton !== undefined) {
            if (formOptions.entityFields && Object.keys(formOptions.entityFields).length) {
                let entityFields = formOptions.entityFields
                Object.keys(entityFields).forEach(fieldName => {
                    if (tempFormSkeleton?.children.hasOwnProperty(fieldName)) {
                        let options = tempFormSkeleton?.children[fieldName].options as EntityInterface
                        if (entityFields[fieldName].hasOwnProperty('choices')) {
                            options.choices = entityFields[fieldName].choices
                            options.disabled = false
                        }
                    }
                })
            }
            if (formOptions.collectionFields && Object.keys(formOptions.collectionFields).length) {
                let collectionFields = formOptions.collectionFields
                Object.keys(collectionFields).forEach(collectionName => {
                    if (tempFormSkeleton?.children.hasOwnProperty(collectionName)) {
                        let options = tempFormSkeleton?.children[collectionName].options as CollectionInterface
                        options.entitiesChoices = collectionFields[collectionName]
                    }
                })
            }

            setFormSkeleton(tempFormSkeleton)
        }

        setInitFormOptions(true)
    }

    useEffect(() => {
        if (!initFormOptions) {
            if (formOptions) {
                if (formOptions.entityFields !== undefined) {
                    configFormOptions(formOptions)
                }
            } else {
                if (needOptions()) {
                    let url = (typeof optionsPath === 'string') ? optionsPath : path;

                    if (url !== 'error') {
                        if (optionsPath === true) {
                            url += '/options'
                        }

                        Api.get(url).then(response => {
                            const formOptions: FormOptionsInterface = response.data
                            configFormOptions(formOptions)
                        })
                    }
                }
            }
        }
    }, [formOptions, formSkeleton])

    return (
        <form noValidate onSubmit={handleSubmit} className={className}>
            <SubForm formData={formData} setFormData={setFormData} prefix={form.name} childSkeleton={formSkeleton.children} setChildSkeleton={(childSkeleton: FormSkeletonChildrenInterface) => {
                let tempFormSkeleton = {...formSkeleton}
                tempFormSkeleton.children = childSkeleton;
                setFormSkeleton(tempFormSkeleton)
            }}>
                {children}
            </SubForm>
        </form>
    )
}

export default Form
