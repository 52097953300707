import React, {FC, useEffect, useState} from 'react'
import { classUtils as c } from 'Vendor/Utils/ClassUtils'
import { useTranslation } from 'react-i18next'
import useTranslationDataUtils from "Vendor/Utils/TranslationDataUtils";
import {TranslationField} from "Components/Listing/Listing";

export type EntityChoice = {
    i18nKey: string
    value: number
}
export interface EntityProps {
    id?: string
    name?: string
    fullName?: string
    choices?: EntityChoice[]
    translateChoiceLabels?: {
        [key: string]: TranslationField
    }
    value?: string|string[]
    setValue?: (value: string|string[]) => void
    label?: string
    i18nLabel?: string
    i18nLabels?: TranslationField
    className?: string
    required?: boolean
    disabled?: boolean
    multiple?: boolean
    hideWhenIsDisabled?: boolean
    i18nPlaceholder?: string
}

const EntityType: FC<EntityProps> = ({
    id,
    name,
    fullName,
    choices,
    translateChoiceLabels,
    value = undefined,
    setValue= (value) => {},
    className,
    label,
    i18nLabel,
    i18nLabels,
    required = true,
    disabled = false,
    multiple = false,
    hideWhenIsDisabled = false,
                                         i18nPlaceholder = undefined,
}) => {
    const { t } = useTranslation()
    const {translateField} = useTranslationDataUtils()
    const [textLabel] = useState<string>(label ? label : i18nLabels ? translateField(i18nLabels) : t(i18nLabel ?? `app.${name}`))

    if ((hideWhenIsDisabled && disabled)) {
        return <></>
    }

    return (
        <div className="form-floating mb-3">
            <select
                id={id}
                name={fullName}
                className={c('form-control', className)}
                onChange={(e) => {
                    if (!disabled) {
                        if (!multiple) {
                            setValue(e.target.value.toString())
                        } else {
                            let values:string[] = []

                            for (let i = 0; i <= (e.target.options.length - 1); i++) {
                                if (e.target.options[i].selected) {
                                    values.push(e.target.options[i].value);
                                }
                            }

                            setValue(values);
                        }
                    }
                }}

                disabled={disabled || typeof choices === 'undefined'}
                multiple={multiple}
                size={multiple && required ? (choices ? choices.length : 1) : undefined}
            >
                {(i18nPlaceholder && !multiple)? (
                    <option value="" selected={!value}>
                        {i18nPlaceholder ? t(i18nPlaceholder) : ''}
                    </option>
                ) : (<></>)}
                {choices && choices.map((choice, index) =>
                    <option key={index + (!required ? 1 : 0)} value={choice.value} selected={ (!multiple && value === choice.value.toString()) || (multiple && Array.isArray(value) && value.includes(choice.value.toString())) }>
                        {translateChoiceLabels && translateChoiceLabels.hasOwnProperty(choice.i18nKey) ? translateField(translateChoiceLabels[choice.i18nKey]) : t(choice.i18nKey)}
                    </option>
                )}
            </select>
            <label className="form-label">
                {textLabel} :
            </label>
        </div>
    )
}

export default EntityType
