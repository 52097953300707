import React, {FC, FormEvent, ReactNode, useEffect, useState} from 'react'
import {
    Button,
    Modal,
    ModalBody as Body,
    ModalFooter as Footer,
    ModalHeader as Header,
    ModalTitle as Title,
} from 'react-bootstrap'
import LoadingButton from "../Button/LoadingButton";
import {useTranslation} from "react-i18next";
import Form, {FormProps} from "../Utils/Form";

export type FormModalProps = FormProps & {
    i18nTitle: string
    show: boolean
    handleClose: () => void
    children: ReactNode
    i18nActionLabel?: string
    isLoading?: boolean
    setIsLoading?: (isLoaded: boolean) => void
    onExited?: () => void
    onSubmit?: (e?: FormEvent<HTMLFormElement>) => void
    onResponse?: (response?: any) => void
    test?: object
}

const FormModal: FC<FormModalProps> = ({i18nTitle, show, handleClose, children, i18nActionLabel , isLoading = false, setIsLoading, onExited, onSubmit, onResponse, test, ...rest}) => {
    const { t } = useTranslation()
    const [isLocalLoading, setIsLocalLoading] = useState<boolean>(false)
    const [isValidate, setIsValidate] = useState<boolean>(false)

    useEffect(() => {
        setIsLocalLoading(isLoading)
    }, [isLoading])

    useEffect(() => {
        if (setIsLoading) {
            setIsLoading(false)
        } else {
            setIsLocalLoading(false)
        }

        setIsValidate(false)
    }, [show, setIsLoading])

    const localOnSubmit = (e?: FormEvent<HTMLFormElement>) => {
        if (setIsLoading) {
            setIsLoading(true)
        } else {
            setIsLocalLoading(true)
        }

        if (onSubmit) {
            onSubmit(e)
        }
    }

    const localOnResponse = () => {
        setIsLoading ? setIsLoading(false) : setIsLocalLoading(false)
        onResponse && onResponse()
    }

    return (
        <Modal show={show} onHide={handleClose} centered onExited={onExited}>
            <Form isValidate={isValidate} setIsValidate={setIsValidate} onResponse={localOnResponse} onSubmit={localOnSubmit} {...rest}>
                <Header closeButton>
                    <Title>{t(i18nTitle)}</Title>
                </Header>
                <Body>{children}</Body>
                <Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t('app.close')}
                    </Button>
                    <LoadingButton type="submit" isLoading={isLocalLoading}>
                        {t(i18nActionLabel ? i18nActionLabel : 'app.validate')}
                    </LoadingButton>
                </Footer>
            </Form>
        </Modal>
    )
}

export default FormModal
