import React, {FC, ReactNode, useEffect, useState} from 'react'
import ListingSetting from "../../Entity/Collection/ListingSetting";
import View from "../../Config/View";
import _Views from "./Header/_Views";
import _Sorters from "./Header/_Sorters";
import {Button, ButtonGroup} from "react-bootstrap";
import DropdownSelect, {OptionProps} from "../Button/DropdownSelect";
import {useTranslation} from "react-i18next";
import direction from "Config/Direction";
import SorterEnum from "Config/Collection/Sorter";
import { classUtils as c } from 'Vendor/Utils/ClassUtils'
import {GroupedActionIcon} from "../../Config/Collection/GroupedAction";
import GroupedAction from "../../Entity/Collection/ListingSetting/GroupedAction";
import _FilterColumn from "../Listing/Filter/_FilterColumn";
import TableColumn from "../../Entity/Collection/ListingSetting/View/TableColumn";
import item from "../../Entity/Item";
import {ListingItems} from "./Listing";

export interface _HeaderProps {
    isLoaded: boolean
    maxResult: number
    pages: number[]
    currentPage: number
    setCurrentPage: (currentPage: number) => void
    shown: number
    setShown: (shown: number) => void
    setting: ListingSetting
    view: View
    setView: (view: View) => void
    condensed: boolean
    setCondensed: (condensed: boolean) => void
    sort?: SorterEnum
    setSort: (sorter?: SorterEnum) => void
    sortDirection?: direction
    setSortDirection: (sortDirection?: direction) => void
    selected: number[]
    setSelected: (selected: number[]) => void
    visibleColumns: TableColumn[]
    setVisibleColumns: (column: TableColumn[]) => void
    handleShowMainActionModal: () => void
    disabledMainModalAction: boolean
    hasSearch: boolean
    handleShowActionModal: (groupedAction?: GroupedAction, actionFromMain?: boolean) => void
    handleToggleReload?: () => void
    items: ListingItems
    availableActions: GroupedAction[]
}

const options: OptionProps[] = [
    {
        label: '10',
        value: 10,
    },
    {
        label: '25',
        value: 25,
    },
    {
        label: '50',
        value: 50,
    },
    {
        label: '100',
        value: 100,
    },
]

const _Header: FC<_HeaderProps> = ({
                                       setting,
                                       view,
                                       setView,
                                       condensed,
                                       setCondensed,
                                       isLoaded,
                                       maxResult,
                                       pages,
                                       currentPage,
                                       setCurrentPage,
                                       shown,
                                       setShown,
                                       sort,
                                       setSort,
                                       sortDirection,
                                       setSortDirection,
                                       selected,
                                       setSelected,
                                       visibleColumns,
                                       setVisibleColumns,
                                       handleShowMainActionModal,
                                       disabledMainModalAction,
                                       hasSearch,
                                       handleShowActionModal,
                                       handleToggleReload,
                                       items,
                                       availableActions,
}) => {
    const {t} = useTranslation()
    const [start, setStart] = useState<number>(0)
    const [end, setEnd] = useState<number>(0)
    const [refreshSpin, setRefreshSpin] = useState<boolean>(false)
    const [isFullSelectedPage, setIsFullSelectedPage] = useState(false);

    const handleSelectAll = () => {
        if (isFullSelectedPage) {
            setSelected([]);
        } else {
            let tempSelect: number[] = [...selected];
            for (const [index, item] of Object.entries(items)) {
                if (!tempSelect.includes(item.id)) {
                    tempSelect.push(item.id);
                }
            }
            setSelected(tempSelect);
        }
    };

    useEffect(() => {
        if (selected.length === 0) {
            setIsFullSelectedPage(false);
        } else {
            let currentPageSelectedItems: number[] = [];
            for (const [index, item] of Object.entries(items)) {
                if (selected.includes(item.id) && !currentPageSelectedItems.includes(item.id)) {
                    currentPageSelectedItems.push(item.id);
                }
            }

            setIsFullSelectedPage(currentPageSelectedItems.length === shown);
        }
    }, [selected, items, shown])

    const handleClearSelected = () => {
        setSelected([]);
        setIsFullSelectedPage(false);
    }

    useEffect(() => {
        setStart((shown * (currentPage - 1)) + 1)
    }, [currentPage, shown, maxResult])

    useEffect(() => {
        if ((start + shown) > maxResult) {
            setEnd(maxResult)
        } else {
            setEnd(start + shown - 1)
        }
    }, [maxResult, shown, start])

    useEffect(() => {
        if (isLoaded && refreshSpin) {
            setRefreshSpin(false)
        }
    }, [isLoaded])

    const handleViewClick = (view: View) => {
        // @TODO : TO IMPROVE
        if (view === 'kanban') {
            setCurrentPage(1)
            setShown(100)
        }

        setView(view)
    }
    
    return (
        <div className="row justify-content-between my-3">
            <div className="col-auto mb-2">
                <div className="row">
                    {availableActions.length > 0 &&
                        <>
                            <div className="col-auto">
                                <ButtonGroup>
                                    <Button variant="primary" disabled={disabledMainModalAction} onClick={handleShowMainActionModal}>
                                        {t('app.groupedAction')} {selected.length > 0 && <span className="badge bg-secondary text-primary">{selected.length}</span>}
                                    </Button>
                                    <Button disabled={selected.length === 0} onClick={handleClearSelected}>
                                        <i className="fa-solid fa-x"></i>
                                    </Button>
                                    <Button variant="primary" onClick={handleSelectAll}>
                                        {isFullSelectedPage ? t('app.unselectAll') : t('app.selectAll') }
                                    </Button>
                                </ButtonGroup>
                                {availableActions.filter(groupedAction => groupedAction.shortcut).map((groupedAction, key) =>
                                    <Button variant="light" className="ms-2"
                                            disabled={groupedAction.action !== 'mailingList' && selected.length === 0}
                                            onClick={() => {
                                                handleShowActionModal(groupedAction, false)
                                            }}
                                            key={key}>
                                        <i className={c("fa-solid", groupedAction.action !== undefined ? "fa-" + GroupedActionIcon[groupedAction.action] : 'fa-circle-nodes', groupedAction.action === 'remove' && 'text-danger')}></i>
                                    </Button>
                                )}
                            </div>
                        </>
                    }
                    {(setting.views.length > 1 || setting.views.includes('card')) && <>
                        {availableActions.length > 0 && <div className="col-auto">
                            <div className="vr h-100"></div>
                        </div>}
                        <_Views view={view} setView={handleViewClick} views={setting.views} condensed={condensed} setCondensed={setCondensed}/>
                    </>}
                </div>
            </div>
            <div className="col-auto">
                <div className="row justify-content-end align-items-center">
                    {view === "table" &&
                        <div className="col-auto">
                            <_FilterColumn columns={setting.tableColumns} visibleColumns={visibleColumns} setVisibleColumns={setVisibleColumns}/>
                        </div>
                    }
                    <div className="col-auto">
                        <span>
                            {isLoaded ? (
                                <>
                                    {maxResult ? (
                                        <>
                                            {view === 'kanban' ? 
                                                maxResult > 100 ? t('app.kanban_max_result', { count: 100 }) : ''
                                            :
                                                ((start < end ? start + ' à ' + end : start) + ' sur ' + maxResult)
                                            }
                                        </>
                                    )
                                        : t('app.no_result')
                                    }
                                </>
                            ) : ''}
                        </span>
                    </div>
                    {view !== 'kanban' &&
                        <>
                            <div className="col-auto">
                                <Button variant="light" disabled={currentPage === 1 || !pages.length} onClick={() => {
                                    currentPage > 1 && setCurrentPage(currentPage - 1)
                                }}><i className="fa-solid fa-angle-left"></i></Button>
                                <Button variant="light" disabled={currentPage === pages.length || !pages} onClick={() => {
                                    currentPage < pages.length && setCurrentPage(currentPage + 1)
                                }}><i className="fa-solid fa-angle-right"></i></Button>
                            </div>
                            <div className="col-auto">
                                <DropdownSelect variant="light" i18nLabel="app.table.show_entries" options={options} selected={shown} setSelected={(selected) => {
                                    if (typeof selected === 'number') {
                                        setShown(selected)
                                    }
                                }}/>
                            </div> 
                        </>
                    }
                    {handleToggleReload &&
                        <>
                            <div className="col-auto">
                                <div className="vr h-100"></div>
                            </div>
                            <div className="col-auto">
                                <Button variant="light" onClick={() => {
                                    handleToggleReload()
                                    setRefreshSpin(true)
                                }}><i className={c("fa-solid fa-arrows-rotate", refreshSpin && 'fa-spin')}></i></Button>
                            </div>
                        </>
                    }
                    <div className="col-auto">
                        <div className="vr h-100"></div>
                    </div>
                    <div className="col-auto">
                        <_Sorters
                            sorters={setting.sorters}
                            sort={sort}
                            setSort={setSort}
                            sortDirection={sortDirection}
                            setSortDirection={setSortDirection}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default _Header
