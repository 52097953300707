import React, {FC, useCallback, useContext, useEffect, useState} from 'react'
import {Modal, ModalFooter as Footer} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'
import Column from "../../Entity/Collection/Import/Column";
import ChoiceType, {Choice} from "../../Vendor/Components/Form/Type/ChoiceType";
import {CollectionImporterContext} from "../../Vendor/Context/CollectionImporterContextProvider";
import useTranslationDataUtils from "../../Vendor/Utils/TranslationDataUtils";
import {classUtils as c} from 'Vendor/Utils/ClassUtils'
import ColumnError from "../../Entity/Collection/Import/ColumnError";
import Api from "Vendor/Api";
import Import from "../../Entity/Collection/Import";
import LoadingButton from "../Button/LoadingButton";
import EntityType, {EntityChoice} from "../../Vendor/Components/Form/Type/EntityType";
import Property from "../../Entity/Property";
import ChooseItemsModal from "../Modal/ChooseItemsModal";
import File from "../../Entity/File";
import EntityInterface from "../../Vendor/Definition/EntityInterface";
import {UserContext} from "../../Vendor/Context/UserContextProvider";
import Company from "../../Entity/Company";
import ChooseItemsInput from "../Modal/ChooseItemsInput";
import ErrorMessage from "../../Config/Collection/Import/Column/ErrorMessage";
import MailingList from "../../Entity/MailingList";

interface _ErrorSubModalProps {
    show: boolean
    setShow: (show: boolean) => void
    id?: number
    currentImport?: Import
    setCurrentImport: (currentImport?: Import) => void
}

type ColumnErrorData = {
    ignored: boolean
    append: boolean
    replacement?: string
    replacementPhone?: string
    choice?: number
    group?: number
    company?: number
    file?: number
    files?: number[]
    mailingList?: number
}

const _ErrorSubModal: FC<_ErrorSubModalProps> = ({
                                                     show,
                                                     setShow,
                                                     id,
                                                     currentImport,
                                                     setCurrentImport,
                                                 }) => {
    const {t} = useTranslation()
    const {i18n} = useTranslation()
    const currentLanguage = i18n.language
    const {translate, translateField} = useTranslationDataUtils()
    const [column, setColumn] = useState<Column | undefined>(undefined)
    const [loadingButtons, setLoadingButtons] = useState<boolean | string>(false)
    const [showChooseModal, setShowChooseModal] = useState<null | string>(null)
    const { currentUser } = useContext(UserContext)

    const isAppendable = (column: Column): boolean => {
        return column.type === 'group'
            || (column.type === 'property'
                && ['choice', 'status'].includes(column.property?.type ?? '')
            )
            || (column.type === 'company' && column.matchedBy === 'name');
    }

    useEffect(() => {
        if (currentImport && show && id) {
            let tempColumn = currentImport.columns.find(c => c.id === id)
            if (tempColumn) {
                if (!tempColumn.type) {
                    tempColumn.type = 'null'
                }

                if (tempColumn.type === 'property' && tempColumn.property) {
                    tempColumn.property = {...tempColumn.property}
                }

                tempColumn = {...tempColumn}
            }
            setColumn(tempColumn ?? undefined)
        }
    }, [currentImport, id, setColumn, show])

    const handleValidate = (cancel: boolean = true) => {
        if (!cancel && column && currentImport) {
            setLoadingButtons('validate')
            let appendable = isAppendable(column);
            let errors: ColumnErrorData[] = [];
            column.errors.forEach(error => {
                let errorData = {
                    ignored: error.ignored,
                } as ColumnErrorData

                if (!error.ignored && appendable) {
                    errorData.append = error.append
                }

                if (!error.ignored
                    && (!appendable || !error.append)
                ) {
                    if (error.replacement) {
                        errorData.replacement = error.replacement
                    }

                    if (error.replacementPhone) {
                        errorData.replacementPhone = error.replacementPhone
                    }

                    if (error.choice) {
                        errorData.choice = error.choice
                    }

                    if (error.group) {
                        errorData.group = error.group
                    }

                    if (error.company) {
                        errorData.company = error.company?.id
                    }

                    if (error.file) {
                        errorData.file = error.file?.id
                    }

                    if (error.files.length) {
                        errorData.files = error.files.map((file: File) => file.id ?? 0)
                    }

                    if (error.mailingList) {
                        errorData.mailingList = error.mailingList?.id
                    }
                }

                errors.push(errorData)
            })

            Api.post(`/collection/import/${currentImport.uuid}/error/${column.position}`, {errors})
                .then(response => {
                    setCurrentImport({...response.data} as Import)
                    setShow(false)
                }).catch(() => {
                //todo show error message
            }).then(() => {
                setLoadingButtons(false)
            })
        } else {
            setShow(false)
        }
    }

    const ignoreAll = () => {
        if (column) {
            let tempColumn = {...column}
            tempColumn.errors = tempColumn.errors.map(e => {
                e.ignored = true
                return e
            })
            setColumn(tempColumn)
        }
    }

    const clearAll = () => {
        if (column) {
            let tempColumn = {...column}
            tempColumn.errors = tempColumn.errors.map(e => {
                e.ignored = false
                e.replacement = ''
                return e
            })
            setColumn(tempColumn)
        }
    }

    const getCountryChoices = useCallback((): Choice[] => {
        let choices: Choice[] = []

        if (currentImport
            && column
            && (column.language || currentLanguage)
            && currentImport.form
            && currentImport.form.countries
            && currentImport.form.countries.hasOwnProperty(column.language ?? currentLanguage)
            && currentImport.form.countries[column.language ?? currentLanguage]
        ) {
            let countries = currentImport.form.countries[column.language ?? currentLanguage]
            for (const [alpha3, name] of Object.entries(countries)) {
                choices.push({
                    value: alpha3,
                    i18nKey: name,
                })
            }
        }

        return choices
    }, [column, currentImport, currentLanguage])

    const getPropertyChoices = (property?: Property): EntityChoice[] => {
        let choices: EntityChoice[] = []

        if (property) {
            property.choices.forEach(choiceData => {
                choices.push({
                    value: choiceData.id ?? 0,
                    i18nKey: translate(choiceData, 'label'),
                })
            })
        }

        return choices
    }

    const getGroupChoices = useCallback((): EntityChoice[] => {
        let groups: EntityChoice[] = []

        if (currentImport) {
            currentImport.form.groups?.forEach(group => {
                groups.push({
                    value: group.id ?? 0,
                    i18nKey: group.name ?? group.id?.toString() ?? 'error',
                })
            })
        }

        return groups
    }, [currentImport])

    const getMailingListChoices = useCallback((): EntityChoice[] => {
        let mailingLists: EntityChoice[] = []

        if (currentImport) {
            currentImport.form.mailingLists?.forEach(mailingList => {
                mailingLists.push({
                    value: mailingList.id ?? 0,
                    i18nKey: translate(mailingList, 'name') ?? 'error',
                })
            })
        }

        return mailingLists
    }, [currentImport])

    return (
        <Modal
            show={show}
            onHide={handleValidate}
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title>{column?.name ?? 'app.column'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table className="table">
                    <tbody>
                    {column && column.errors.map((error, key) => <>
                        <tr key={key}>
                            <td>{error.value !== '' ? error.value : t('app.empty_value')}</td>
                            <td>
                                <label>
                                    <input type="checkbox" checked={error.ignored} onChange={e => {
                                        let tempColumn = {...column}
                                        tempColumn.errors[key].ignored = e.target.checked
                                        setColumn(tempColumn)
                                    }}/>
                                    {t('app.ignore')}
                                </label>
                            </td>
                            {
                                isAppendable(column)
                                && <td>
                                    {!error.ignored && <label>
                                        <input type="checkbox" checked={error.append} onChange={e => {
                                            let tempColumn = {...column}
                                            tempColumn.errors[key].append = e.target.checked
                                            setColumn(tempColumn)
                                        }}/>
                                        {t('app.append')}
                                    </label>}
                                </td>
                            }
                            <td>
                                {!error.ignored && !error.append &&
                                    <>
                                        {(['name', 'firstName', 'lastName', 'email'].includes(column?.type ?? '')
                                                || (column?.type === 'property' && 'text' === column?.property?.type))
                                            && <input type="text"
                                                      className={c('form-control', !error.replacement ? 'is-invalid' : 'is-valid')}
                                                      value={error.replacement && error.replacement.length ? error.replacement : error.value}
                                                      onChange={e => {
                                                          let tempColumn = {...column}
                                                          let newValue = e.target.value
                                                          if (newValue === error.value) {
                                                              newValue = ''
                                                          }
                                                          tempColumn.errors[key].replacement = newValue
                                                          setColumn(tempColumn)
                                                      }} placeholder={t('app.replacedBy')}/>
                                        }
                                        {(column?.type === 'property' && 'phone' === column?.property?.type)
                                            && <input type="text"
                                                      className={c('form-control', !error.replacementPhone ? 'is-invalid' : 'is-valid')}
                                                      value={error.replacementPhone && error.replacementPhone.length ? error.replacementPhone : error.value}
                                                      onChange={e => {
                                                          let tempColumn = {...column}
                                                          let newValue = e.target.value
                                                          if (newValue === error.value) {
                                                              newValue = ''
                                                          }
                                                          tempColumn.errors[key].replacementPhone = newValue
                                                          setColumn(tempColumn)
                                                      }} placeholder={t('app.replacedBy')}/>
                                        }
                                        {column?.type === 'company'
                                            && <ChooseItemsInput
                                                show={showChooseModal === 'company' + key}
                                                setShow={(show: boolean = false) => {
                                                    if (show) {
                                                        setShowChooseModal('company' + key)
                                                    } else {
                                                        setShowChooseModal(null)
                                                    }
                                                }}
                                                selected={error.company ? [error.company] : []}
                                                setSelected={(selected) => {
                                                    if (selected.length) {
                                                        let tempError = {...error}
                                                        tempError.company = selected[0] as Company
                                                        let tempColumn = {...column}
                                                        tempColumn.errors[key] = tempError
                                                        setColumn(tempColumn)
                                                    }
                                                }}
                                                title={t('app.choose_a_company')}
                                                path={`/crm/company`}
                                                entity="company"
                                                multiple={column.separator !== 'none' && currentUser?.currentOrganization?.setting.relationContactCompany === 'many'}
                                            />
                                        }
                                        {column?.type === 'bucket'
                                            && <ChooseItemsInput
                                                show={showChooseModal === 'bucket' + key}
                                                setShow={(show: boolean = false) => {
                                                    if (show) {
                                                        setShowChooseModal('bucket' + key)
                                                    } else {
                                                        setShowChooseModal(null)
                                                    }
                                                }}
                                                selected={
                                                    (!column.separator || 'none' === column.separator) ?
                                                        (error.file ? [error.file] : [])
                                                        : error.files
                                                }
                                                setSelected={(selected) => {
                                                    if (selected.length) {
                                                        let tempError = {...error}

                                                        if (column.separator && column.separator !== 'none') {
                                                            tempError.files = selected as File[]
                                                        } else {
                                                            tempError.file = selected[0] as File
                                                        }


                                                        let tempColumn = {...column}
                                                        tempColumn.errors[key] = tempError
                                                        setColumn(tempColumn)
                                                    }
                                                }}
                                                title={t('app.choose_a_file')}
                                                path={`/b/${column.bucket}`}
                                                entity="bucket"
                                                multiple={column.separator && column.separator !== 'none'}
                                                firstSearch={error.value}
                                            />
                                        }
                                        {column?.type === 'property'
                                            && 'address' === column?.property?.type
                                            && 'country' === column?.addressField
                                            &&
                                            <ChoiceType choices={getCountryChoices()}
                                                        value={error.replacement}
                                                        setValue={value => {
                                                            if (typeof value === 'string') {
                                                                let tempColumn = {...column}
                                                                if (value === error.value) {
                                                                    value = ''
                                                                }
                                                                tempColumn.errors[key].replacement = value
                                                                setColumn(tempColumn)
                                                            }
                                                        }}
                                                        i18nPlaceholder="app.select_country"
                                            />
                                        }
                                        {column?.type === 'property'
                                            && ['choice', 'status'].includes(column?.property?.type ?? '')
                                            && <EntityType choices={getPropertyChoices(column?.property)}
                                                           value={error.choice?.toString()}
                                                           setValue={(value) => {
                                                               let data: undefined | number = undefined
                                                               if (typeof value === 'string') {
                                                                   if (!value.length) {
                                                                       data = undefined
                                                                   } else {
                                                                       data = parseInt(value, 10)
                                                                   }
                                                               } else if (value.length) {
                                                                   data = parseInt(value[0], 10)
                                                               }
                                                               let tempColumn = {...column}
                                                               tempColumn.errors[key].choice = data
                                                               setColumn(tempColumn)
                                                           }}
                                                           i18nPlaceholder="app.choose_an_value"
                                            />
                                        }
                                        {column?.type === 'group'
                                            && <EntityType choices={getGroupChoices()}
                                                           value={error.group?.toString()}
                                                           setValue={(value) => {
                                                               let data: undefined | number = undefined
                                                               if (typeof value === 'string') {
                                                                   if (!value.length) {
                                                                       data = undefined
                                                                   } else {
                                                                       data = parseInt(value, 10)
                                                                   }
                                                               } else if (value.length) {
                                                                   data = parseInt(value[0], 10)
                                                               }
                                                               let tempColumn = {...column}
                                                               tempColumn.errors[key].group = data
                                                               setColumn(tempColumn)
                                                           }}
                                                           i18nPlaceholder="app.choose_a_group"
                                            />
                                        }
                                        {column?.type === 'mailingList'
                                            && <EntityType choices={getMailingListChoices()}
                                                           value={error.mailingList?.toString()}
                                                           setValue={(value) => {
                                                               let data: undefined | number = undefined
                                                               if (typeof value === 'string') {
                                                                   if (!value.length) {
                                                                       data = undefined
                                                                   } else {
                                                                       data = parseInt(value, 10)
                                                                   }
                                                               } else if (value.length) {
                                                                   data = parseInt(value[0], 10)
                                                               }
                                                               let mailingList: MailingList | undefined = undefined
                                                                if (data) {
                                                                     mailingList = currentImport?.form.mailingLists?.find(ml => ml.id === data)
                                                                }
                                                               let tempColumn = {...column}
                                                               tempColumn.errors[key].mailingList = mailingList
                                                               setColumn(tempColumn)
                                                           }}
                                                           i18nPlaceholder="app.choose_a_mailing_list"
                                            />
                                        }
                                    </>
                                }
                            </td>
                        </tr>
                    </>)}
                    </tbody>
                </table>
            </Modal.Body>
            <Footer>
                <LoadingButton variant="outline-secondary" onClick={() => {
                    clearAll()
                }} isLoading={true === loadingButtons || 'clear_all' === loadingButtons}
                               disabled={false !== loadingButtons}>{t('app.clear_all')}</LoadingButton>
                <LoadingButton variant="secondary" onClick={() => {
                    ignoreAll()
                }} isLoading={true === loadingButtons || 'ignore_all' === loadingButtons}
                               disabled={false !== loadingButtons}>{t('app.ignore_all')}</LoadingButton>
                <LoadingButton variant="secondary" onClick={() => {
                    handleValidate(true)
                }} isLoading={true === loadingButtons || 'cancel' === loadingButtons}
                               disabled={false !== loadingButtons}>{t('app.cancel')}</LoadingButton>
                <LoadingButton variant="primary" onClick={() => {
                    handleValidate(false)
                }} isLoading={true === loadingButtons || 'validate' === loadingButtons}
                               disabled={false !== loadingButtons}>{t('app.validate')}</LoadingButton>
            </Footer>
        </Modal>
    )
}

export default _ErrorSubModal
