import React, {FC, useContext, useEffect, useState} from 'react'
import {FormOptionsInterface, FormPropertiesFieldInterface} from "Vendor/Components/Form";
import FormRow from "Vendor/Components/Form/FormRow";
import FormError from "Vendor/Components/Form/FormError";
import DealForm from "Form/Crm/DealForm";
import Deal from "Entity/Deal";
import {UserContext} from "../../Vendor/Context/UserContextProvider";
import {useOutletContext} from "react-router-dom";
import ListingSetting from "../../Entity/Collection/ListingSetting";
import EntityInterface from "../../Vendor/Definition/EntityInterface";
import Api from "../../Vendor/Api";
import Listing from "../../Components/Listing/Listing";
import FormOffCanvas from "../../Components/Modal/FormOffCanvas";
import DeleteModal from "../../Components/Modal/DeleteModal";
import FormEmptyOffCanvas from "../../Components/Modal/FormEmptyOffCanvas";

const DealPage: FC = () => {
    const { currentUser } = useContext(UserContext)
    const [show, setShow, search, setSearch, disabled, setDisabled, setHandleAdd] = useOutletContext<[boolean, (show:boolean) => void, string, (search:string) => void, boolean, (disabled: boolean) => void, (handleAdd: (() => void)|undefined) => void]>()
    const [setting] = useState<ListingSetting|undefined>(currentUser?.currentOrganization?.dealListingSetting)
    const [deal, setDeal] = useState<Deal>({} as Deal)
    const [toggleReload, setToggleReload] = useState<string>('')
    const [propertyFields, setPropertyFields] = useState<FormPropertiesFieldInterface|undefined>(undefined)
    const [formOptions, setFormOptions] = useState<FormOptionsInterface|undefined>(undefined)

    useEffect(() => {
        setHandleAdd(() => {
            setDeal({} as Deal)
        })
    }, [setHandleAdd])

    const handleClose = (reload = false) => {
        setShow(false)
        if (reload) {
            setToggleReload(new Date().toISOString())
        }
    }

    const onExited = () => {
        setDeal({} as Deal)
    }

    const onSuccess = (deal: Deal) => {
        setDeal(deal)
        setShow(false)
        setToggleReload(new Date().toISOString())
    }

    const editAction = (deal: EntityInterface) => {
        setDeal(deal as Deal)
        setShow(true)
    }

    const deleteAction = (deal: EntityInterface) => {
        setDeal(deal as Deal)
        handleDeleteShow()
    }

    // remove
    const [showDelete, setShowDelete] = useState<boolean>(false)
    const handleDeleteClose = (reload = false) => {
        setDeal({} as Deal)
        setShowDelete(false)
        if (reload) {
            setToggleReload(new Date().toISOString())
        }
    }
    const handleDeleteShow = () => setShowDelete(true)

    useEffect(() => {
        setDisabled(true)
        Api.get(`/crm/deal/${deal.id ?? 'new'}`).then(response => {
            const formOptions: FormOptionsInterface = response.data
            setFormOptions(formOptions)
            setPropertyFields(formOptions.propertyFields)
            setDisabled(false)
        })
    }, [])

    if (!setting) return <></>

    return (
        <>
            <Listing setting={setting} search={search} setSearch={setSearch} path='/crm/deal' entity='deal' toggleReload={toggleReload} setToggleReload={setToggleReload} editAction={editAction} deleteAction={deleteAction} />
            <FormEmptyOffCanvas
                i18nTitle={deal.id ? 'app.edit_deal' : 'app.add_deal'}
                show={show}
                handleClose={handleClose}
                onSuccess={onSuccess}
                path={`/crm/deal/${deal.id ?? 'new'}`}
                formPath={!!deal.id}
                method={deal.id ? 'patch' : 'post'}
                onExited={onExited}
            />
            <DeleteModal
                handleClose={handleDeleteClose}
                show={showDelete}
                path={`/crm/deal/${deal.id}`}
                name={`#${deal.id} - ${deal.name}`}
                archive={true}
            />
        </>
    )
}

export default DealPage
