import React, {FC, useContext, useState} from 'react'
import {useTranslation} from "react-i18next";
import Header from "Components/Layout/Header";
import Table from "Components/Layout/Table";
import Brand from "../../../Entity/Brand";
import BrandTable from "../../../Table/Admin/Edition/BrandTable";
import FormRow from "../../../Vendor/Components/Form/FormRow";
import FormModal from "../../../Components/Modal/FormModal";
import BrandForm from "../../../Form/Admin/Edition/BrandForm";
import DeleteModal from "../../../Components/Modal/DeleteModal";
import FormError from "../../../Vendor/Components/Form/FormError";
import {DataType} from "../../../Vendor/Components/Form";
import {useNavigate} from "react-router-dom";
import {Button} from "react-bootstrap";
import {UserContext} from "../../../Vendor/Context/UserContextProvider";
import FormEmptyOffCanvas from "../../../Components/Modal/FormEmptyOffCanvas";

const BrandPage: FC = () => {
    const { currentUser } = useContext(UserContext)
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [toggleReload, setToggleReload] = useState<boolean>(true)
    const [show, setShow] = useState<boolean>(false)
    const [search, setSearch] = useState<string>('')
    const [brands, setBrands] = useState<Brand[]>([])
    const [brand, setBrand] = useState<Brand>({} as Brand)

    const handleClose = (reload = false) => {
        setShow(false)
        if (reload) {
            setToggleReload(!toggleReload)
        }
    }
    const handleShow = () => setShow(true)
    // remove
    const [showDelete, setShowDelete] = useState<boolean>(false)
    const handleDeleteClose = (reload = false) => {
        setBrand({} as Brand)
        setShowDelete(false)
        if (reload) {
            setToggleReload(!toggleReload)
        }
    }
    const handleDeleteShow = () => setShowDelete(true)

    const onSuccess = (brand: Brand) => {
        setBrand(brand)
        setShow(false)
        setToggleReload(!toggleReload)
    }

    const editBrand = (brand: Brand) => {
        navigate(brand.id?.toString() ?? '', {state: brand})
    }

    const deleteBrand = (brand: Brand) => {
        setBrand(brand)
        handleDeleteShow()
    }

    const actionsRender = (brand: Brand) => (
        <>
            {brand?.isGranted?.edit &&
            <Button
                variant='outline-primary'
                size='sm'
                onClick={() => editBrand(brand)}>
                <i className="fa-solid fa-pencil me-2"></i>
                {t('app.edit')}</Button>
            }
            {brand?.isGranted?.delete &&
            <Button
                variant='outline-danger'
                size='sm'
                onClick={() => deleteBrand(brand)}>
                <i className="fa-solid fa-trash me-2"></i>
                {t('app.delete')}</Button>
            }
        </>
    );

    let table = BrandTable;
    if (table.hasOwnProperty('actions')) {
        table.actions.render = actionsRender;
    }

    return (
        <>
            <Header title={t('app.brands')}
                    add={() => {
                        setBrand({} as Brand)
                        handleShow()
                    }}
                    search={search}
                    setSearch={setSearch}
            />
            <Table
                path="/admin/edition/brand"
                rows={table}
                toggleReload={toggleReload}
                items={brands}
                setItems={setBrands}
                keyword={search}
                setKeyword={setSearch}
            />
            <FormEmptyOffCanvas
                i18nTitle={brand.id ? 'app.edit_brand' : 'app.add_brand'}
                show={show}
                handleClose={handleClose}
                onSuccess={onSuccess}
                path={`/admin/edition/brand/${brand.id ?? 'new'}`}
                formPath={brand.id ? true : false}
                method={brand.id ? 'patch' : 'post'}
            />
            <DeleteModal
                handleClose={handleDeleteClose}
                show={showDelete}
                path={'/admin/edition/brand/' + (brand?.id ?? 'error')}
                name={'#' + (brand ? `${brand.id} - ${brand.name}` : 'error')}
                archive={true}
            />
        </>
    )
}

export default BrandPage
