import React, {FC, useContext, useEffect, useState} from 'react'
import Listing from "Components/Listing/Listing";
import Header from "../Components/Layout/Header";
import {useTranslation} from "react-i18next";
import ListingSetting from "../Entity/Collection/ListingSetting";
import BimboShareModal from "../Components/Modal/BimboShareModal";
import {UserContext} from "../Vendor/Context/UserContextProvider";

import EntityInterface from "../Vendor/Definition/EntityInterface";
import BimboShare from "../Entity/BimboShare";
import {useLocation, useOutletContext} from "react-router-dom";
import Brand from "../Entity/Brand";
import Api from "../Vendor/Api";
import DeleteModal from "../Components/Modal/DeleteModal";
import Shopping from "../Entity/Shopping";

export interface BimboShareProps {
}

const BimboSharePage: FC<BimboShareProps> = () => {
    const {currentUser} = useContext(UserContext)
    const {t} = useTranslation()
    const [search, setSearch] = useState<string>('')
    const [setting] = useState<ListingSetting>({
        cardCondensedByDefault: false,
        cardShowBadge: false,
        cardShowStatus: false,
        cardShowSchedule: true,
        cardShowSizeAndWeight: null,
        filters: [],
        groupedActions: [],
        kanbanDatas: [],
        cardDatas: [],
        searchable: [],
        searchableByEmail: false,
        searchableByFirstName: false,
        searchableByLastName: false,
        searchableByName: false,
        sorters: [
            {
                sorter: 'createdAt',
                defaultSort: true,
                defaultDirection: 'desc'
            },
            {
                sorter: 'updatedAt',
                defaultSort: false,
                defaultDirection: 'desc'
            },
            {
                sorter: 'name',
                defaultSort: false,
                defaultDirection: 'asc'
            },
        ],
        tableColumns: [
            {
                column: 'status',
                visibleByDefault: true,
            },
            {
                columnType: 'name',
                visibleByDefault: true,
                translated: true,
            },
            {
                columnType: 'createdAt',
                visibleByDefault: true,
            },
            {
                column: 'expiredAt',
                visibleByDefault: true,
                render: (bimboShare: BimboShare) => {
                    if (bimboShare.status === 'draft') {
                        return <i>N/A</i>
                    }
                    return bimboShare.expiredAt ? (new Date(bimboShare.expiredAt)).toLocaleDateString() : <i>-</i>
                }
            },
            {
                column: 'brand',
                visibleByDefault: !!(currentUser && currentUser.currentOrganization && currentUser.currentOrganization.brands && currentUser.currentOrganization.brands.length > 1),
                field: 'name',
            },
            {
                column: 'platform',
                visibleByDefault: !!(currentUser && currentUser.currentOrganization && currentUser.currentOrganization.platforms && currentUser.currentOrganization.platforms.length > 1),
                field: 'name',
            },
            {
                column: 'views',
                visibleByDefault: false,
            },
            {
                column: 'download',
                visibleByDefault: false,
            },
        ],
        views: ['table'],
    })
    const [toggleReload, setToggleReload] = useState<string>('')
    const [currentBimboShare, setCurrentBimboShare] = useState<string|undefined>(undefined)
    const [showBimboShare, setShowBimboShare] = useState<boolean>(false)
    const location = useLocation()
    const [bimboShare, setBimboShare] = useState<BimboShare>({} as BimboShare)
    const [showDelete, setShowDelete] = useState<boolean>(false)


    useEffect(() => {
        if (location.state
            && typeof location.state === 'object'
            && location.state.hasOwnProperty('bimboShare')
        ) {
            let state = location.state as { bimboShare: string }
            if (typeof state.bimboShare === "string" && state.bimboShare.length > 0) {
                handleOpenModal(state.bimboShare)
            }
        }
    }, [location])

    const handleOpenModal = (tempCurrentBimboShare?: string) => {
        setCurrentBimboShare(tempCurrentBimboShare)
        setShowBimboShare(true)
        setTimeout(() => {
            document.body.classList.add('showBimboShareModal')
            let modal = document.getElementById('bimboShareModal')
            if (modal) {
                modal.style.top = window.scrollY + 'px'
            }
        }, 10)
    }

    const handleDeleteClose = (reload = false) => {
        setBimboShare({} as BimboShare)
        setShowDelete(false)
        if (reload) {
            setToggleReload(new Date().toISOString())
        }
    }

    const handleDeleteShow = () => setShowDelete(true)

    const deleteBimboShare = (bimboShare: EntityInterface) => {
        setBimboShare(bimboShare as BimboShare)
        handleDeleteShow()
    }

    const handleCloseModal = (refresh: boolean = false) => {
        document.body.classList.remove('showBimboShareModal')
        if (refresh) {
            setToggleReload(new Date().toISOString())
        }
        setTimeout(() => {
            setShowBimboShare(false)
        }, 1000)
    }

    return <>
        <Header
            title={t('app.bimboShare')}
            search={search}
            setSearch={setSearch}
            add={() => {
                handleOpenModal(undefined)
            }}
        />
        <Listing
            path="/bimbo-share"
            search={search}
            setSearch={setSearch}
            setting={setting}
            selectable={false}
            toggleReload={toggleReload}
            setToggleReload={setToggleReload}
            editAction={(item) => {
                let bimboShare = item as BimboShare
                handleOpenModal(bimboShare.uuid)
            }}
            deleteAction={deleteBimboShare}
        />
        {showBimboShare && <BimboShareModal
            handleClose={handleCloseModal}
            currentBimboShare={currentBimboShare}
            setCurrentBimboShare={setCurrentBimboShare}
        />}
        <DeleteModal
            handleClose={handleDeleteClose}
            show={showDelete}
            path={'/bimbo-share/' + (bimboShare?.uuid ?? 'error')}
            name={'#' + (bimboShare ? `${bimboShare.uuid}` : 'error')}
            archive={true}
        />
    </>
}

export default BimboSharePage
