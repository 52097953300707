import React, {FC, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    Button, Modal,
    ModalBody as Body,
    ModalFooter as Footer,
    ModalHeader as Header,
    ModalTitle as Title
} from "react-bootstrap";
import GroupedAction from "Entity/Collection/ListingSetting/GroupedAction";
import LoadingButton from "../../Button/LoadingButton";
import {MailingActionModal} from "../Listing";
import Api from "Vendor/Api";
import Collection from "Config/Collection";
import {createArrUtils} from "../../../Vendor/Utils/CreateArrayUtils";
import {ThumbnailModalProps} from "../View/_CardGroup";
import _PreviewFile from "./_PreviewFile";

export interface _PreviewModalProps {
    thumbnailModalProps?: ThumbnailModalProps
    setThumbnailModalProps: (modalProps?: ThumbnailModalProps) => void
}

const _PreviewModal: FC<_PreviewModalProps> = ({
                                                   thumbnailModalProps,
                                                   setThumbnailModalProps,
}) => {

    const handleClose = () => {
        setThumbnailModalProps(undefined)
    }

    return <Modal show={thumbnailModalProps !== undefined} onHide={handleClose} key={1} size="xl" className="thumbnail-modal" fullscreen="md-down">
        <Modal.Header closeButton>
            <Modal.Title>{thumbnailModalProps && thumbnailModalProps.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="content">
                {thumbnailModalProps &&
                    <_PreviewFile thumbnailModalProps={thumbnailModalProps} setThumbnailModalProps={setThumbnailModalProps}/>
                }
            </div>
        </Modal.Body>
    </Modal>
}

export default _PreviewModal
