import React, {FC, useContext, useEffect, useState} from "react";
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import {Navbar as GlobalNavbar, NavbarItem} from "../../../../Components/Layout/Navbar";
import Header from "../../../../Components/Layout/Header";
import Setting from "../../../../Entity/Organization/Setting";
import {useTranslation} from "react-i18next";
import {UserContext} from "Vendor/Context/UserContextProvider";

const Navbar: FC = () => {
    const navigate = useNavigate()
    const {currentUser} = useContext(UserContext)
    const {t} = useTranslation()
    const [items, setItems] = useState<NavbarItem[]>([])
    const [search, setSearch] = useState<string>('')
    const [show, setShow] = useState<boolean>(false)
    const [showAddButton, setShowAddButton] = useState<boolean>(false)
    const [showSearch, setShowSearch] = useState<boolean>(false)

    useEffect(() => {
        let tempItems: NavbarItem[] = [
            {
                i18nLabel: 'app.main',
                path: ''
            },
            {
                i18nLabel: 'app.contact',
                path: 'contact'
            },
            {
                i18nLabel: 'app.contact_export',
                path: 'contact/export-config',
            },
            {
                i18nLabel: 'app.company',
                path: 'company'
            },
            {
                i18nLabel: 'app.company_export',
                path: 'company/export-config',
            },
        ]

        if (currentUser?.currentOrganization?.options?.includes('sales')) {
            tempItems.push({
                i18nLabel: 'app.deal',
                path: 'deal'
            })
            tempItems.push({
                i18nLabel: 'app.deal_export',
                path: 'deal/export-config'
            })
        }

        if (currentUser?.currentOrganization?.options?.includes('shopping')) {
            if (currentUser?.currentOrganization?.setting.shoppingTypes.includes('shopping')) {
                tempItems.push({
                    i18nLabel: 'app.shopping',
                    path: 'shopping'
                })
                tempItems.push({
                    i18nLabel: 'app.shopping_properties',
                    path: 'shopping/properties'
                })
                tempItems.push({
                    i18nLabel: 'app.shopping_view_properties',
                    path: 'shopping/view-properties'
                })
                tempItems.push({
                    i18nLabel: 'app.shopping_export',
                    path: 'shopping/export-config'
                })
                tempItems.push({
                    i18nLabel: 'app.delivery_paper',
                    path: 'shopping/delivery-paper'
                })
            }
            if (currentUser?.currentOrganization?.setting.shoppingTypes.includes('pre-order')) {
                tempItems.push({
                    i18nLabel: 'app.pre_order',
                    path: 'pre-order'
                })
                tempItems.push({
                    i18nLabel: 'app.pre_order_properties',
                    path: 'pre-order/properties'
                })
                tempItems.push({
                    i18nLabel: 'app.pre_order_export',
                    path: 'pre-order/export-config'
                })
            }
        }


        tempItems.push({
            i18nLabel: 'app.miscellaneous',
            path: 'misc'
        })

        setItems(tempItems)
    }, [currentUser?.currentOrganization])

    return (
        <>
            <Header
                title={t('app.settings')} prev={() => {
                    navigate('/admin/edition')
                }}
                search={showSearch ? search : undefined}
                setSearch={showSearch ? setSearch : undefined}
                add={showAddButton ? () => {
                    setShow(true)
                } : undefined}
            />
            <GlobalNavbar locationBase={"/admin/edition/setting"} items={items} />
            <Outlet context={[show, setShow, search, setSearch, setShowAddButton, setShowSearch]} />
        </>
    )
}

export default Navbar
