import React, {FC, useContext, useState} from "react";
import BlankPage from "../../../../Components/Layout/BlankPage";
import ListingSetting from "Components/Form/ListingSetting";
import ListingSettingEntity from 'Entity/Collection/ListingSetting';
import {UserContext} from "../../../../Vendor/Context/UserContextProvider";
import {BannerContext} from "../../../../Vendor/Context/BannerContextProvider";
import User from "../../../../Entity/User";
import LoadingButton from "../../../../Components/Button/LoadingButton";
import FormEmpty from "../../../../Vendor/Components/FormEmpty";
import {useTranslation} from "react-i18next";

const CompanyListingPage: FC = () => {
    const { currentUser, setCurrentUser } = useContext(UserContext)
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)
    const { setBanner } = useContext(BannerContext)

    if (!currentUser || !currentUser.currentOrganization) return <></>

    const setListing = (listing: ListingSettingEntity) => {
        let tempUser = {...currentUser}
        if (tempUser.currentOrganization) {
            tempUser.currentOrganization.companyListingSetting = listing
            setCurrentUser(tempUser)
        }
    }

    const onSuccess = (data: User) => {
        setBanner({type: 'success', i18nTitle: 'app.save', i18nMessage: 'app.data_saved_message'})
        setCurrentUser(data)
    }

    const onSubmit = () => {
        setIsLoading(true)
    }

    const onError = () => {
        setSuccess(false)
    }

    const onResponse = () => {
        setIsLoading(false)
    }

    return (
        <BlankPage>
            <FormEmpty
                path={"/admin/edition/collection/listing-setting/company"}
                formPath={!!currentUser?.currentOrganization.id}
                children={<div className="row justify-content-end align-items-center">
                    <div className="col-auto">
                        <LoadingButton type="submit" isLoading={isLoading}>
                            {t( 'app.validate' )}
                        </LoadingButton>
                    </div>
                </div>}
                method={currentUser?.currentOrganization.id ? 'PATCH' : 'POST'}
                onSuccess={onSuccess}
                onSubmit={onSubmit}
                onResponse={onResponse}
                onError={onError}
            />
        </BlankPage>
    )
}

export default CompanyListingPage