import React, {FC, useContext, useState} from 'react'
import {Button, Modal} from "react-bootstrap";
import Sticker from "../../../Entity/Product/Sticker";
import {useTranslation} from "react-i18next";
import Product from "Entity/Product";
import FormEmpty from "../../../Vendor/Components/FormEmpty";
import {BannerContext} from "../../../Vendor/Context/BannerContextProvider";
import {Link} from "react-router-dom";
import LoadingButton from "../../Button/LoadingButton";
import Api from "../../../Vendor/Api";
import FormEmptyModal from "../../Modal/FormEmptyModal";

interface ProductStickersEditModalProps {
    sticker?: Sticker|null,
    product: Product,
    setProduct: (product: Product) => void,
    showStickerModal: boolean,
    setShowStickerModal: (stickerModal: boolean) => void,
}

const ProductStickersEditModal: FC<ProductStickersEditModalProps> = ({sticker,
                                                                      product,
                                                                      setProduct,
                                                                      showStickerModal,
                                                                      setShowStickerModal,
                                                                     }) => {
    const { t } = useTranslation()
    const [showStickerArchiveModal, setShowStickerArchiveModal] = useState<boolean>(false)
    const [isLoadingCancelArchive, setIsLoadingCancelArchive] = useState<boolean>(false)
    const {setBanner} = useContext(BannerContext)

    const onSuccess = (data: any) => {
        setProduct(data as Product)
        setBanner({type: 'success', i18nTitle: 'app.save', i18nMessage: 'app.data_saved_message'})
        setShowStickerArchiveModal(false)
    }

    const cancelArchive = () => {
        setIsLoadingCancelArchive(true)
        Api.patch(`/product/${product.id}/sticker/${sticker?.id}/cancel-archive`)
            .then((data) => {
                setProduct(data.data as Product)
            })
            .catch(() => {})
            .then(() => {
              setIsLoadingCancelArchive(false)
            })
    }

    return (
    <>
        <Modal show={showStickerModal} onHide={() => {
            setShowStickerModal(false)
        }}>
            <Modal.Header closeButton>
                <Modal.Title>{sticker?.code ?? 'No sticker selected'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="content">
                    {sticker !== null && sticker !== undefined &&
                        <>
                            <p>
                                <span className="fw-bold">{t('app.status')} :</span> {t('app.sticker_status.'+sticker.status)}
                                {sticker.status === 'outputted' && sticker.shoppingId && <Link className="edit-link" to={`/shopping/${sticker.shoppingId}`}>{t('app.shopping')} #{sticker.shoppingId}</Link>}
                            </p>
                            <p>
                                {sticker.archivedBecause !== null && sticker.archivedBecause !== undefined &&
                                    <>
                                        <span className="fw-bold">{t('app.archive_because')} :</span>
                                        {sticker.archivedBecause}
                                    </>
                                }
                            </p>
                            <p>
                                <span className="fw-bold">{t('app.shopping_history')} :</span>
                                {sticker?.histories?.length > 0 ?
                                    <div className="container-fluid bg-light p-1 rounded-4">
                                            <table className="table table-hover table-large mb-0">
                                            <thead>
                                                <tr>
                                                    <th>{t('app.name')}</th>
                                                    <th>{t('app.outputted_at')}</th>
                                                    <th>{t('app.outputted_by')}</th>
                                                    <th>{t('app.returned_at')}</th>
                                                    <th>{t('app.returned_by')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {sticker.histories.map((history) => (
                                                    <tr>
                                                        <td><Link className="edit-link" to={`/shopping/${history.shoppingId}`}>{t('app.shopping')} #{history.shoppingId}</Link></td>
                                                        <td>{new Date(history.outputtedAt).toLocaleDateString()}</td>
                                                        <td>{history.outputtedBy?.name}</td>
                                                        <td>{history.returnedAt !== null && history.returnedAt !== undefined ? new Date(history.returnedAt).toLocaleDateString() : ''}</td>
                                                        <td>{history.returnedBy?.name}</td>
                                                    </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    : <i> {t('app.no_history')}</i>
                                }
                            </p>
                            <p>
                                <span className="fw-bold"> {t('app.future_bookings')} :</span>
                                {sticker?.bookings?.length > 0 ?
                                <div className="container-fluid bg-light p-1 rounded-4">
                                    <table className="table table-hover table-large mb-0">
                                        <thead>
                                        <tr>
                                            <th>{t('app.name')}</th>
                                            <th>{t('app.outputted_at')}</th>
                                            <th>{t('app.outputted_by')}</th>
                                            <th>{t('app.planned_return_at')}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {sticker.bookings.map((booking) => (
                                            <tr>
                                                <td>
                                                    <a className="edit-link" href={`/shopping/${booking.shoppingId}`}>{t('app.shopping')} #{booking.shoppingId}</a>
                                                </td>
                                                <td>{new Date(booking.outputtedFor).toLocaleDateString()}</td>
                                                <td>{booking.outputtedBy?.name}</td>
                                                <td>{booking.returnedFor !== null && booking.returnedFor !== undefined ? (new Date(booking.returnedFor).toLocaleDateString()) : <i>{t('app.no_return_date')}</i>}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                                    : <i> {t('app.no_future_booking')}</i>
                                }
                            </p>
                        </>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                {sticker?.status === 'in-stock' &&
                    <Button variant="danger" onClick={() => {
                        setShowStickerModal(false)
                        setShowStickerArchiveModal(true)
                    }}>
                        {t('app.archive')}
                    </Button>
                }
                {sticker?.status === 'archived' && <LoadingButton variant="info" type="button" isLoading={isLoadingCancelArchive} onClick={cancelArchive}>{t('app.cancelArchive')}</LoadingButton>}
            </Modal.Footer>
        </Modal>
        <FormEmptyModal
            path={`/product/${product.id}/sticker/${sticker?.id}/archive`}
            method={'POST'}
            i18nTitle={t('app.archive') + ' ' + (sticker?.code ?? 'No sticker selected')}
            show={showStickerArchiveModal}
            handleClose={() => {
                setShowStickerArchiveModal(false)
                setShowStickerModal(true)
            }}
            onSuccess={onSuccess}
            onSubmit={(e) => {
                e?.preventDefault()
                e?.stopPropagation()
            }}
            i18nActionLabel="app.archive"
            actionVariant="danger"
        />
    </>
    )
}

export default ProductStickersEditModal