import React, {FC, useEffect, useState} from 'react'
import {
    Offcanvas,
    OffcanvasBody as Body,
} from 'react-bootstrap'
import Api from "../../../Vendor/Api";
import {useTranslation} from "react-i18next";
import useTranslationDataUtils from "../../../Vendor/Utils/TranslationDataUtils";
import {classUtils as c} from "Vendor/Utils/ClassUtils";
import _Notification, { NotificationTypes } from "./_Notification";
import FormEmptyModal from "../../Modal/FormEmptyModal";

import PlatformEvent from 'Entity/Notification/Platform/PlatformEvent';
import PlatformNotification from 'Entity/Notification/Platform/PlatformNotification';
import CollectionEvent from "../../../Entity/Notification/Collection/CollectionEvent";

export type PlatformEventOffCanvasProps = {
    show: boolean
    handleClose: () => void
    id?: number,
    setInfoShow: (show: boolean) => void
    toggleReload?: string
    setToggleReload?: (toggleReload: string) => void
}

const PlatformEventOffCanvas: FC<PlatformEventOffCanvasProps> = (
    {
        show,
        handleClose,
        id,
        setInfoShow,
        toggleReload,
        setToggleReload,
    }) => {

    const {t} = useTranslation()
    const {translate} = useTranslationDataUtils()
    const [showAddNotificationModal, setShowAddNotificationModal] = useState<boolean>(false)
    const [showSendTestNotificationModal, setShowSendTestNotificationModal] = useState<boolean>(false)
    const [notificationEvent, setNotificationEvent] = useState<PlatformEvent|undefined>(undefined)
    const [notification, setNotification] = useState<PlatformNotification|undefined>(undefined)

    useEffect(() => {
        setNotificationEvent(undefined)
        if (id) {
            fetchNotificationEvent(id)
        }
    }, [id, toggleReload])

    const fetchNotificationEvent = (id: number) => {
        Api.get('/admin/edition/notification/platform/' + id).then((response) => {
            setNotificationEvent(response.data)
        })
    }

    const handleDelete = (notification: NotificationTypes) => {
        Api.delete(`/admin/edition/notification/platform/notification/${notification.id}`)
            .then((response) => {
            })
            .catch(() => {})
            .then(() => {
                if (id) {
                    fetchNotificationEvent(id)
                }

                if (setToggleReload) {
                    setToggleReload(new Date().toISOString())
                }
            })
    }

    const renderTarget = (notificationEvent: PlatformEvent) => {
        let result: string = notificationEvent.platform.name
        
        return result
    }

    const renderEvent = (notificationEvent: PlatformEvent) => {
        let result: string = t('app.enum.platform.event.' + notificationEvent.platformEvent)

        return result
    }

    return (
        <>
            <Offcanvas show={show} onHide={handleClose} placement="end" className="offcanvas-large" backdropClassName="offcanvas-backdrop-large">
                <Body className="placeholder-glow">
                    <div className="header">
                        <div className="sidebar">
                            <span>{t('app.event') + ' #' + id}</span>
                            {/* <span className={c("badge-status", notificationEvent ? `status-${notificationEvent.status}` : 'status-draft placeholder')}>{notificationEvent && t(`app.item_status.${notificationEvent.status}`)}</span> */}
                        </div>
                        <div className="content"></div>
                    </div>
                    <div className="body">
                        <div className="sidebar">
                            <div className="sidebar-header">
                                <h5>{t('app.informations')}</h5>
                                <button className={c("btn btn-primary", !notificationEvent && 'placeholder')} disabled={!notificationEvent} onClick={() => {
                                    setInfoShow(true)
                                }}>
                                    <i className="fas fa-pen-to-square"></i>
                                </button>
                            </div>
                            <div className="sidebar-content">
                                <h6>{t('app.platform')}</h6>
                                <p>{notificationEvent ? <p>{renderTarget(notificationEvent)}</p> : <span className="placeholder col-4"></span>}</p>
                                <h6>{t('app.event')}</h6>
                                <p>{notificationEvent ? <p>{renderEvent(notificationEvent)}</p> : <span className="placeholder col-4"></span>}</p>
                            </div>
                        </div>
                        <div className="content">
                            <header>
                                <div className="infos">
                                    <h5>{notificationEvent ? t('app.nb_notifications', {count: notificationEvent.notifications.length}) : <span className="placeholder col-3"></span>}</h5>
                                </div>
                                <div className="actions">
                                    <button className={c("btn btn-primary", !notificationEvent && 'placeholder')} disabled={!notificationEvent} onClick={() => {
                                        setNotification(undefined)
                                        setShowAddNotificationModal(true)
                                    }}>
                                        <i className="fas fa-circle-plus fa-lg me-2"></i>
                                        {t('app.add_notification')}
                                    </button>
                                </div>
                            </header>
                            <main>
                                {notificationEvent && notificationEvent.notifications.map((notification, index) =>
                                    <_Notification
                                        key={index}
                                        notification={notification}
                                        event={notificationEvent}
                                        setNotification={setNotification}
                                        setShowAddNotificationModal={setShowAddNotificationModal}
                                        setShowSendTestNotificationModal={setShowSendTestNotificationModal}
                                        handleDelete={handleDelete}
                                    />
                                )}
                            </main>
                        </div>
                    </div>
                </Body>
            </Offcanvas>
            {notificationEvent && <>
                <FormEmptyModal
                    i18nTitle={notification?.id ? 'app.edit_notification' : 'app.add_notification'}
                    show={showAddNotificationModal}
                    handleClose={() => {
                        setShowAddNotificationModal(false)
                        setNotification(undefined)
                    }}
                    onSuccess={(data: PlatformEvent) => {
                        setNotification(undefined)
                        setNotificationEvent(data)
                        setShowAddNotificationModal(false)
                    }}
                    path={notification?.id ? `/admin/edition/notification/platform/notification/${notification.id}` : `/admin/edition/notification/platform/notification/${notificationEvent.id}/new`}
                    method={notification?.id ? 'PATCH' : 'POST'}
                    formPath={!!notification?.id}
                />
                {notification && <FormEmptyModal
                    i18nTitle='app.send_test'
                    show={showSendTestNotificationModal}
                    handleClose={() => {
                        setShowSendTestNotificationModal(false)
                    }}
                    onSuccess={(data: CollectionEvent) => {
                        setShowSendTestNotificationModal(false)
                    }}
                    path={`/admin/edition/notification/platform/notification/${notification.id}/send-test`}
                    method={'POST'}
                />}
            </>}
        </>
    )
}

export default PlatformEventOffCanvas
