import React, {FC, useContext, useState} from 'react'
import {useTranslation} from "react-i18next";
import Header from "Components/Layout/Header";
import Table from "Components/Layout/Table";
import InAppNotifTable from "Table/Admin/Edition/InAppNotifTable";
import {Button} from "react-bootstrap";
import InApp from "../../../../Entity/Notification/InApp";
import Api from "../../../../Vendor/Api";
import {UserContext} from "../../../../Vendor/Context/UserContextProvider";

const NotificationPage: FC = () => {
    const { t } = useTranslation()
    const [toggleReload, setToggleReload] = useState<string>('')
    const [inAppNotifs, setInAppNotifs] = useState<InApp[]>([])
    const { currentUser, setCurrentUser, notifs, setNotifs } = useContext(UserContext)

    const handleMarkAsRead = (inApp: InApp) => {
        Api.patch(`/notifications/${inApp.id}/mark-as-read`)
            .then((response) => {
                setNotifs(response.data)
            })
            .catch(() => {})
            .then(() => {
                setToggleReload(new Date().toISOString())
            })
    }

    const handleDelete = (inApp: InApp) => {
        Api.delete(`/notifications/${inApp.id}/delete`)
            .then((response) => {
                setNotifs(response.data)
            })
            .catch(() => {})
            .then(() => {
                setToggleReload(new Date().toISOString())
            })
    }

    const handleViewNotification = (inApp: InApp) => {
        if (!inApp.readAt) {
            handleMarkAsRead(inApp);
        }
        if (inApp.link) {
            window.open(inApp.link);
        }
    }

    const actionsRender = (inApp: InApp) => {
        if (currentUser?.previousAdmin) {
            return t('app.is_not_possible_switch_user')
        }

        const btnVariant = !inApp.readAt ? 'outline-success' : 'outline-primary'

        return (
            <>
                {inApp.link && <Button
                    variant={btnVariant}
                    size='sm'
                    className='text-center'
                    onClick={() => handleViewNotification(inApp)}>
                    <i className="fa-solid fa-eye me-2"></i>
                    {t('app.viewNotification')}
                </Button>}

                {inApp.readAt == null && <Button
                    variant={'outline-primary'}
                    size='sm'
                    className='text-center'
                    onClick={() => handleMarkAsRead(inApp)}>
                    <i className="fa-solid fa-eye me-2"></i>
                    {t('app.markAsRead')}
                </Button>}

                <Button
                    variant='outline-danger'
                    size='sm'
                    onClick={() => handleDelete(inApp)}>
                    <i className="fa-solid fa-trash me-2"></i>
                    {t('app.delete')}
                </Button>
            </>
        )
    }

    let table = InAppNotifTable;
    if (table.hasOwnProperty('actions')) {
        table.actions.render = actionsRender;
    }

    return (
        <>
            <Header title={t('app.notifications')}
            />
            <Table
                path="/notifications"
                rows={table}
                toggleReload={toggleReload}
                items={inAppNotifs}
                setItems={setInAppNotifs}
            />
        </>
    )
}

export default NotificationPage
