import {RouteObject} from "react-router-dom";
import React from "react";
import DomainPage from "Pages/Admin/Edition/MailingIdentity/DomainPage";
import EmailPage from "Pages/Admin/Edition/MailingIdentity/EmailPage";

const MailingIdentityRouting: RouteObject[] = [
    {
        path: 'email',
        element: <EmailPage />,
    },
    {
        path: 'domain',
        element: <DomainPage />,
    },
]

export default MailingIdentityRouting
