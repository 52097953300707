import React, { FC, useMemo } from 'react'
import { Pagination as BootstrapPagination } from 'react-bootstrap'

export type PaginationProps = {
    colSpan: number
    currentPage: number
    pages: number[]
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>
}

const Pagination: FC<PaginationProps> = ({colSpan, currentPage, pages, setCurrentPage,}) => {
    const handlePage = useMemo(
        () => ({
            next: () =>
                pages.findIndex((page) => page === currentPage + 1) >= 0 &&
                setCurrentPage(currentPage + 1),
            prev: () =>
                pages.findIndex((page) => page === currentPage - 1) >= 0 &&
                setCurrentPage(currentPage - 1),
            last: () => setCurrentPage(pages[pages.length - 1]),
            first: () => setCurrentPage(pages[0]),
            default: setCurrentPage,
        }),
        [setCurrentPage, currentPage, pages],
    )
    return (
        <div className="row justify-content-center mb-5 mt-3">
            <div className="col-auto">
                <nav aria-label="Pagination">
                    <BootstrapPagination>
                        {currentPage - 3 > 0 && (<BootstrapPagination.First onClick={handlePage.first} />)}
                        {currentPage > 1 && (<BootstrapPagination.Prev onClick={handlePage.prev} />)}
                        {[...pages]
                            .slice(
                                currentPage - 3 > 0
                                    ? currentPage + 3 > pages.length
                                        ? currentPage - 5 - currentPage
                                        : currentPage - 3
                                    : 0,
                                currentPage - 3 > 0
                                    ? currentPage + 2
                                    : 5 - currentPage + currentPage,
                            )
                            .map((page) => (
                                <BootstrapPagination.Item
                                    key={page}
                                    active={currentPage === page}
                                    onClick={() => handlePage.default(page)}
                                >
                                    {page}
                                </BootstrapPagination.Item>
                            ))}
                        {currentPage < pages.length && (<BootstrapPagination.Next onClick={handlePage.next} />)}
                        {(currentPage + 3) < pages.length && (<BootstrapPagination.Last onClick={handlePage.last} />)}
                    </BootstrapPagination>
                </nav>
            </div>
        </div>
    )
}

export default Pagination
