import {Rows} from "Components/Layout/Table";
import Listing from "../../../../Entity/Platform/Listing";

const ListingTable: Rows = {
    id: {
        placeholders: [
            {
                element: 'p',
                size: 1
            }
        ]
    },
    name: {
        field: 'label',
        translated: true,
        placeholders: [
            {
                element: 'p',
                size: [3],
            }
        ]
    },
    type: {
        render: (listing: Listing) => {
           return <>{listing.catalog ? 'Catalogue' : 'Bucket'}</>
        },
        placeholders: [
            {
                element: 'p',
                size: [3],
            }
        ]
    },
    actions: {
        className: 'actions',
        sortable: false,
        placeholders: [
            {
                element: 'a',
                size: 2,
            },
        ]
    },
};

export default ListingTable