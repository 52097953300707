import React, {FC, FormEvent, ReactNode, useEffect, useState} from 'react'
import {
    Offcanvas,
    OffcanvasBody as Body,
    OffcanvasHeader as Header,
    OffcanvasTitle as Title,
} from 'react-bootstrap'
import LoadingButton from "../Button/LoadingButton";
import {useTranslation} from "react-i18next";
import FormEmpty from "../../Vendor/Components/FormEmpty";
import {FormErrors} from "../../Vendor/Components/Form";
import {Method} from "axios";

export type FormEmptyOffCanvasProps = {
    i18nTitle: string
    show: boolean
    handleClose: () => void
    placement?: 'start' | 'end'
    i18nActionLabel?: string
    isLoading?: boolean
    setIsLoading?: (isLoaded: boolean) => void
    onExited?: () => void
    onSubmit?: (e?: FormEvent<HTMLFormElement>) => void | boolean
    onSuccess?: (data: any) => void
    onError?: (errors?: FormErrors) => void
    onResponse?: (response?: any) => void
    path: string
    method?: Method
    formPath?: boolean|string
    customHeader?: ReactNode
    disabled?: boolean
    appendForm?: ReactNode
    additionalData?: object
}

const FormEmptyOffCanvas: FC<FormEmptyOffCanvasProps> = ({
                                                   i18nTitle,
                                                   show,
                                                   handleClose,
                                                   placement = "end",
                                                   i18nActionLabel,
                                                   isLoading = false,
                                                   setIsLoading,
                                                   onExited,
                                                   onSubmit,
                                                   onResponse,
                                                   customHeader,
                                                   disabled = false,
                                                   appendForm,
                                                   additionalData,
                                                   ...rest
                                               }) => {
    const {t} = useTranslation()
    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const [isLocalLoading, setIsLocalLoading] = useState<boolean>(false)
    const [isValidate, setIsValidate] = useState<boolean>(false)

    useEffect(() => {
        setIsLocalLoading(isLoading)
    }, [isLoading])

    useEffect(() => {
        if (setIsLoading) {
            setIsLoading(false)
        } else {
            setIsLocalLoading(false)
        }

        setIsLoaded(false)

        setIsValidate(false)
    }, [show, setIsLoading])

    const localOnSubmit = (e?: FormEvent<HTMLFormElement>) => {
        if (setIsLoading) {
            setIsLoading(true)
        } else {
            setIsLocalLoading(true)
        }

        if (onSubmit) {
            onSubmit(e)
        }
    }

    const localOnResponse = () => {
        setIsLoading ? setIsLoading(false) : setIsLocalLoading(false)
        onResponse && onResponse()
    }

    return (
        <Offcanvas show={show} onHide={handleClose} placement={placement} onExited={onExited}>
            <Header closeButton>
                {customHeader ?? <Title>{t(i18nTitle)}</Title>}
            </Header>
            <Body>
                <FormEmpty {...rest} onResponse={localOnResponse} onSubmit={localOnSubmit} setIsLoaded={setIsLoaded} appendForm={appendForm} additionalData={additionalData}>
                    {!disabled &&
                        <div className="row justify-content-end align-items-center">
                            <div className="col-auto">
                                <LoadingButton type="submit" isLoading={isLocalLoading} disabled={!isLoaded}>
                                    {t(i18nActionLabel ? i18nActionLabel : 'app.validate')}
                                </LoadingButton>
                            </div>
                        </div>
                    }
                </FormEmpty>
            </Body>
        </Offcanvas>
    )
}

export default FormEmptyOffCanvas
