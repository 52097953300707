import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { classUtils as c } from "Vendor/Utils/ClassUtils";
import { Link, useLocation } from 'react-router-dom'

export type MenuItem = {
    baseTo?: string
    to: string
    className: string
    label: string
    index?: boolean
    muted?: boolean
    display?: boolean
}

const _MenuItem: FC<MenuItem> = ({ baseTo, to, className, label, index, muted, display }) => {
    const { t } = useTranslation()
    const currentLocation = useLocation()

    return (
        <li className={c(display && "d-none")}>
            <Link to={to} className={c("list-item", ((!index && currentLocation.pathname.startsWith(baseTo ?? to)) || (index && currentLocation.pathname === (baseTo ?? to))) && 'active')}>
                <i className={className} />
                <span className={c(muted && "text-muted fst-italic")}>{t(label)}</span>
            </Link>
        </li>
    )
}

export default _MenuItem
