import React, {
    FC,
    ReactNode,
    useMemo,
    useEffect,
    useState, useCallback,
} from 'react'
import Api from 'Vendor/Api'
import GlobalLoader from "../Components/GlobalLoader";
import User from "Entity/User";
import GlobalError from "../Components/GlobalError";
import useTranslationDataUtils from "../Utils/TranslationDataUtils";

type NotifNumber = {
    notifs: number,
    notReadNotifs: number,
}

type UserContextType = {
    currentUser?: User
    setCurrentUser: React.Dispatch<React.SetStateAction<User|undefined>>
    onLogout: boolean
    setOnLogout: React.Dispatch<React.SetStateAction<boolean>>
    init: boolean
    setInit: React.Dispatch<React.SetStateAction<boolean>>
    notifs?: NotifNumber
    setNotifs: React.Dispatch<React.SetStateAction<NotifNumber|undefined>>
    getInitialPage: () => string
}

export const UserContext = React.createContext<UserContextType>({
    currentUser: undefined,
    setCurrentUser: () => {},
    onLogout: false,
    setOnLogout: () => {},
    init: true,
    setInit: () => {},
    notifs: undefined,
    setNotifs: () => {},
    getInitialPage: () => '',
})

const UserContextProvider: FC<{ children: ReactNode }> = ({
    children,
}) => {
    const [currentUser, setCurrentUser] = useState<User|undefined>(undefined)
    const {translate} = useTranslationDataUtils()
    const [onLogout, setOnLogout] = useState<boolean>(false)
    const [notifs, setNotifs] = useState<NotifNumber|undefined>(undefined)
    const [init, setInit] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout>()

    const getInitialPage = useCallback((): string => {
        if (!currentUser) return '/login'

        if (currentUser.access === 'internal') {
            return '/admin/organization'
        } else if (currentUser.access === 'edition') {
            return '/admin/edition/bucket'
        }

        let brandMenu: string[] = []
        currentUser.currentOrganization?.buckets.filter(bucket => {
            return ((!currentUser.currentBrand && bucket.brand === null) || (currentUser.currentBrand && currentUser.currentBrand === bucket.brand?.slug)) && !bucket.simpleImport
        }).forEach(bucket => {
            let item: string = `/b/${bucket.primarySlug}`
            brandMenu.push(item)
        })

        currentUser.currentOrganization?.catalogs.filter(catalog => {
            return (!currentUser.currentBrand && catalog.brand === null) || (currentUser.currentBrand && currentUser.currentBrand === catalog.brand?.slug)
        }).forEach(catalog => {
            let item: string = `/c/${catalog.primarySlug}`
            brandMenu.push(item)
        })

        currentUser.currentOrganization?.dataCollections.filter(dataCollection => {
            return (!currentUser.currentBrand && dataCollection.brand === null) || (currentUser.currentBrand && currentUser.currentBrand === dataCollection.brand?.slug)
        }).forEach(dataCollection => {
            let item = `/d/${dataCollection.primarySlug}`
            brandMenu.push(item)
        })

        return brandMenu.length ? brandMenu[0] : '/bimbo-share'
    }, [currentUser, translate])

    const context = useMemo(
        () => ({ currentUser, setCurrentUser, onLogout, setOnLogout, init, setInit, notifs, setNotifs, getInitialPage }),
        [currentUser, onLogout, init, notifs, getInitialPage],
    )

    useEffect(() => {
        if (intervalId) {
            clearInterval(intervalId)
        }
        if (currentUser) {
            const id = setInterval(() => {
                Api.get('/login')
                    .then(({ data }: { data: User}) => {
                        setCurrentUser(data)
                    })
                    .catch(() => {
                        setCurrentUser(undefined)
                    })
            }, 1000 * 60 * 30)
            setIntervalId(id)
        }
    }, [currentUser])

    const handleInit = () => {
        setInit(false)
        setError(false)
        Api.get('/login')
            .then(({ data }: { data: User}) => {
                setCurrentUser(data)
                setInit(true)
            })
            .catch(() => {
                setCurrentUser(undefined)
                setInit(true)
                setError(true)
            })
    }
    useEffect(handleInit, [])

    useEffect(() => {
        if (onLogout) {
            Api.get('/logout')
                .then(() => {
                    setCurrentUser(undefined)
                })
                .catch(() => {

                })
                .then(() => {
                    setOnLogout(false)
                })
        }
    }, [onLogout])

    return (
        <UserContext.Provider value={context}>
            {!init || onLogout
                ? <GlobalLoader />
                : error
                    ? <GlobalError handleClick={handleInit} />
                    : children
            }
        </UserContext.Provider>
    )
}

export default UserContextProvider
