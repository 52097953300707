import React, {FC, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    Button, Modal,
    ModalBody as Body,
    ModalFooter as Footer,
    ModalHeader as Header,
    ModalTitle as Title
} from "react-bootstrap";
import GroupedAction from "Entity/Collection/ListingSetting/GroupedAction";
import LoadingButton from "../../Button/LoadingButton";
import Api from "Vendor/Api";
import Collection from "Config/Collection";
import TextType from "../../../Vendor/Components/Form/Type/TextType";
import Property from "../../../Entity/Property";

export interface _DownloadModalProps {
    collection?: Collection
    showActionModals: {[key: string]: boolean|Property}
    handleShowActionModal: (groupedAction?: GroupedAction, actionFromMain?:boolean) => void
    fromMain: boolean
    handleShowMainActionModal: () => void
    selected: number[]
    actionModalFormIsLoading: boolean
    handleActionModalOnResponse: () => void
    handleActionModalOnSuccess: () => void
    handleActionModalOnSubmit: () => void
}

const _DownloadModal: FC<_DownloadModalProps> = ({
                                                 collection,
                                                 showActionModals,
                                                 handleShowActionModal,
                                                 fromMain,
                                                 handleShowMainActionModal,
                                                 selected,
                                                 actionModalFormIsLoading,
                                                 handleActionModalOnResponse,
                                                 handleActionModalOnSuccess,
                                                 handleActionModalOnSubmit,
}) => {
    const {t} = useTranslation()
    const [fileName, setFileName] = useState<string>('')

    const onSubmit = () => {
        handleActionModalOnSubmit()
        Api.post('/grouped-action/download', {
            listing: collection,
            selected: selected,
            fileName: fileName,
        }, {
            responseType: 'blob'
        })
            .then((response ) => {
                let disposition = response.headers['content-disposition'];
                // get filename from content-disposition
                let filename = disposition?.split(';')[1].split('filename')[1].split('=')[1].trim().replace(/"/g, '');
                // create file link in browser's memory
                const href = URL.createObjectURL(response.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', filename); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);

                handleActionModalOnSuccess()
            })
            .catch(() => {
            })
            .then(() => {
                handleActionModalOnResponse()
                setFileName('')
            })
    }

    return <Modal show={showActionModals.download !== false} onHide={handleShowActionModal}>
        <Header closeButton={!fromMain}>
            <div className="modal-block-header">
                {fromMain && <i className="fa-solid fa-angle-left" onClick={handleShowMainActionModal}></i>}
                <Title>{t(`app.groupedActionTitle.download.${collection}`, {count: selected.length})}</Title>
            </div>
        </Header>
        <Body>
            <TextType required={false} fullName="fileName" id="fileName" name="fileName" value={fileName} onChange={e => setFileName(e.target.value)} i18nLabel="app.groupedActionContent.download.inputLabel" />
        </Body>
        <Footer>
            {!fromMain && <Button variant="secondary" onClick={() => {
                handleShowActionModal()
            }}>
                {t('app.cancel')}
            </Button>}
            <LoadingButton variant="primary" isLoading={actionModalFormIsLoading} onClick={onSubmit}>
                {t('app.download')}
            </LoadingButton>
        </Footer>
    </Modal>
}

export default _DownloadModal