import React, {FC, useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import Api from "../../Vendor/Api";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Header from "../../Components/Layout/Header";
import Loader from "../../Vendor/Components/Loader";
import UnderConstruction from "../../Vendor/Components/UnderConstruction";

const ShowPage: FC = () => {
    const location = useLocation()
    const params = useParams()
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [file, setFile] = useState<File|undefined>(undefined)

    const prev = () => {
        navigate(`/b/${params.slug}`)
    }

    useEffect(() => {
        if (location && location.state && typeof location.state === 'object') {
            setFile(location.state as File);
        }
        else {
            let slug = params.slug;
            let fileSlug = params.fileSlug;
            let matchBy = params.matchBy;
            // todo add matchby
            Api.get(`/file/${slug}/${fileSlug}`)
                .then((response ) => {
                    setFile(response.data as File)
                })
        }
    }, [location, params])

    if (!file) return <Loader />

    return (
        <>
            <Header
                title={file.name}
                prev={prev}
            />
            <UnderConstruction />
        </>
    )
}

export default ShowPage
