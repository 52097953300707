import React, { FC, useEffect, useState } from 'react'
import { classUtils as c } from 'Vendor/Utils/ClassUtils'
import useHumanizeFileSizeUtils from 'Vendor/Utils/HumanizeFileSizeUtils'
import {
    iconByType,
    SupportedMimesTypes,
    UploadedFile,
} from 'Vendor/UploadSystem'
import _RejectModal from './_RejectModal'
import { useTranslation } from 'react-i18next'
import {
    Button,
    Modal,
    ModalFooter as Footer,
    ModalBody as Body,
    ModalHeader as Header,
    ModalTitle as Title,
    Form,
} from 'react-bootstrap'
import TextType from 'Vendor/Components/Form/Type/TextType'
import LoadingButton from 'Components/Button/LoadingButton'
import Config from 'Entity/Import/Config'

export type FileCardProps = {
    uploadedFile: UploadedFile
    deleteFile: () => void
    retry: () => void
    retryAnalyze: () => void
    config?: Config
    renameFile: (oldName: string, newName: string) => Promise<void>
}

const _FileCard: FC<FileCardProps> = ({
    uploadedFile,
    deleteFile,
    retry,
    retryAnalyze,
    config,
    renameFile,
}) => {
    const { t } = useTranslation()
    const { humanFileSize } = useHumanizeFileSizeUtils()
    const [badgeColor, setBadgeColor] = useState<undefined | string>(undefined)
    const [showRejectModal, setShowRejectModal] = useState<boolean>(false)
    const [addShow, setAddShow] = useState<boolean>(false)
    const [file, setFile] = useState<UploadedFile>(uploadedFile)
    const [newFileName, setNewFileName] = useState<string>(uploadedFile?.name)
    const [isRenaming, setIsRenaming] = useState(false)
    const [showRenameMessage, setShowRenameMessage] = useState(false)
    const editNameAction = (file: UploadedFile) => {
        setFile({ ...file })
        setAddShow(true)
    }

    const handleRename = () => {
        setIsRenaming(true)
        renameFile(file?.name, newFileName)
            .then(() => {
                setAddShow(false)
                setShowRenameMessage(false)
            })
            .finally(() => {
                setIsRenaming(false)
            })
    }

    const displayRenameInfoOnCard = () => {
        if (config && config.rule && showRenameMessage) {
            return (
                <div className="mt-4 text-danger">
                    {t('app.file_versioning.error')}
                </div>
            )
        } else if (config && !config.rule && showRenameMessage) {
            return (
                <div className="mt-4 text-warning">
                    {t('app.file_versioning.warning')}
                </div>
            )
        }
        return null
    }

    useEffect(() => {
        if (
            uploadedFile.state === 'failed' ||
            uploadedFile.state === 'presigned-url-not-found'
        ) {
            setBadgeColor('bg-danger')
        } else if (uploadedFile.state === 'uploaded') {
            setBadgeColor('bg-success')
        } else if (
            uploadedFile.state === 'uploading' ||
            uploadedFile.state === 'presigned-url-requested' ||
            uploadedFile.state === 'ready'
        ) {
            setBadgeColor('bg-info')
        } else if (uploadedFile.state === 'pending') {
            setBadgeColor('bg-muted')
        } else {
            setBadgeColor(undefined)
        }
    }, [uploadedFile.state])

    useEffect(() => {
        if (
            uploadedFile.state === 'failed' ||
            uploadedFile.state === 'presigned-url-not-found'
        ) {
            setBadgeColor('bg-danger')
        } else if (uploadedFile.state === 'uploaded') {
            setBadgeColor('bg-success')
        } else if (
            uploadedFile.state === 'uploading' ||
            uploadedFile.state === 'presigned-url-requested' ||
            uploadedFile.state === 'ready'
        ) {
            setBadgeColor('bg-info')
        } else if (uploadedFile.state === 'pending') {
            setBadgeColor('bg-muted')
        } else {
            setBadgeColor(undefined)
        }
    }, [uploadedFile.analyzeStatus])

    useEffect(() => {
        const shouldShowRenameMessage =
            (uploadedFile.newVersion ?? 0) > 1 &&
            uploadedFile.analyzeStatus === 'accepted'
        setShowRenameMessage(shouldShowRenameMessage)
    }, [uploadedFile, uploadedFile.name])

    return (
        <>
            <Modal
                show={addShow}
                onHide={() => setAddShow(false)}
                centered
                enforceFocus={true}
            >
                <Header closeButton>
                    <Title>{t('app.edit_file')}</Title>
                </Header>
                <Body>
                    <Form>
                        <TextType
                            id="name"
                            name="name"
                            fullName="name"
                            required
                            i18nLabel="Name"
                            value={newFileName}
                            setValue={setNewFileName}
                        />
                    </Form>
                </Body>
                <Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setAddShow(false)}
                    >
                        {t('app.close')}
                    </Button>
                    <LoadingButton
                        type="submit"
                        isLoading={isRenaming}
                        onClick={handleRename}
                    >
                        {t('app.rename')}
                    </LoadingButton>
                </Footer>
            </Modal>
            <div
                className={c(
                    'card card-item uploaded-card',
                    `card-status-${uploadedFile.state}`,
                )}
            >
                <div className="card-header">
                    <div>
                        <div className="badge">
                            {SupportedMimesTypes[uploadedFile.type]}
                        </div>
                        <div
                            className={c(
                                'badge ms-2',
                                badgeColor,
                                uploadedFile.state,
                            )}
                        >
                            {t(`app.import_status.${uploadedFile.state}`)}
                            {uploadedFile.state === 'uploading' ? (
                                <>
                                    {' '}
                                    -{' '}
                                    {Math.round(
                                        (uploadedFile.uploaded /
                                            uploadedFile.size) *
                                            100,
                                    )}
                                    %
                                </>
                            ) : null}
                        </div>
                        {'uploaded' === uploadedFile.state && (
                            <>
                                {uploadedFile.analyzeStatus === 'rejected' ? (
                                    <>
                                        <button
                                            onClick={() => {
                                                setShowRejectModal(true)
                                            }}
                                            className="badge ms-2 btn bg-danger"
                                        >
                                            {t(`app.import_status.rejected`)}
                                        </button>
                                        <_RejectModal
                                            show={showRejectModal}
                                            setShow={setShowRejectModal}
                                            file={uploadedFile}
                                        />
                                    </>
                                ) : (
                                    uploadedFile.analyzeStatus &&
                                    uploadedFile.analyzeStatus !==
                                        'pending' && (
                                        <div className="badge ms-2 bg-info">
                                            {t(
                                                `app.import_analyze_status.${uploadedFile.analyzeStatus}`,
                                            )}
                                        </div>
                                    )
                                )}
                            </>
                        )}
                        {(uploadedFile.newVersion ?? 0) > 1 &&
                            uploadedFile.analyzeStatus === 'accepted' && (
                                <div className="badge ms-2 bg-primary">
                                    V{uploadedFile.newVersion}
                                </div>
                            )}
                    </div>
                    {['failed', 'presigned-url-not-found', 'uploaded'].includes(
                        uploadedFile.state,
                    ) && (
                        <a
                            href="#"
                            className="trash btn p-0"
                            onClick={deleteFile}
                        >
                            {uploadedFile.onRemove === true ? (
                                <span
                                    className="spinner-border spinner-grow-sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            ) : (
                                <i className="fa-solid fa-trash"></i>
                            )}
                        </a>
                    )}
                    {['failed', 'presigned-url-not-found'].includes(
                        uploadedFile.state,
                    ) && (
                        <a href="#" className="retry btn p-0" onClick={retry}>
                            <i className="fa-solid fa-rotate-right"></i>
                        </a>
                    )}
                    {uploadedFile.state === 'uploaded' &&
                        uploadedFile.analyzeStatus === 'failed' && (
                            <a
                                href="#"
                                className="retry btn p-0"
                                onClick={retryAnalyze}
                            >
                                <i className="fa-solid fa-rotate-right"></i>
                            </a>
                        )}
                </div>
                <div className="img">
                    {uploadedFile.previewUrl ? (
                        <img
                            src={uploadedFile.previewUrl}
                            alt={uploadedFile.name}
                        />
                    ) : (
                        <i
                            className={c(
                                'fa-solid',
                                iconByType(uploadedFile.type),
                            )}
                        ></i>
                    )}
                </div>
                <div className="card-body">
                    <div className="d-flex justify-content-center align-items-center">
                        <h5 className="card-title px-2">{newFileName}</h5>
                        {(uploadedFile.newVersion ?? 0) > 1 &&
                            uploadedFile.analyzeStatus === 'accepted' && (
                                <Button
                                    variant="outline-primary"
                                    size="sm"
                                    onClick={() => {
                                        editNameAction(uploadedFile)
                                    }}
                                >
                                    <i className="fa-solid fa-pencil"></i>
                                </Button>
                            )}
                    </div>
                    <p className="card-meta">
                        {/*mimeTypeImage.includes(file.type) && '1552x1800px • '*/}
                        {humanFileSize(uploadedFile.size)}
                    </p>
                    {displayRenameInfoOnCard()}
                </div>
            </div>
        </>
    )
}

export default _FileCard
