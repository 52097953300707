import React, {FC, useEffect, useState} from 'react'
import {Button, ButtonGroup} from "react-bootstrap";
import DropdownSelectButton, {OptionProps} from "../../Button/DropdownSelectButton";
import direction from "Config/Direction";
import SorterEnum from "Config/Collection/Sorter";
import Sorter from "Entity/Collection/ListingSetting/Sorter";

export interface _SortersProps {
    sorters: Sorter[]
    sort?: SorterEnum
    setSort: (sorter?: SorterEnum) => void
    sortDirection?: direction
    setSortDirection: (sortDirection?: direction) => void
}

const _Sorters: FC<_SortersProps> = ({
                                         sorters,
                                         sort,
                                         setSort,
                                         sortDirection,
                                         setSortDirection,
                                     }) => {
    const [sortersOptions, setSortersOptions] = useState<OptionProps[]>([])

    useEffect(() => {
        let sortersOptions:OptionProps[] = []

        sorters.forEach((sorter) => {
            let option:OptionProps = {
                label: `app.table_columns.${sorter.sorter}`,
                value: sorter.sorter,
            }

            sortersOptions.push(option)
        })

        setSortersOptions(sortersOptions)
    }, [sorters])

    return (
        <ButtonGroup>
            <DropdownSelectButton
                hasGroup={true}
                variant="light"
                i18nLabel="app.listings.sorted_by"
                options={sortersOptions}
                selected={sort ?? ''}
                setSelected={(selected) => {
                    let tempSorter = selected as SorterEnum
                    let defaultDirection: direction = 'asc'
                    sorters.forEach(sorter => {
                        if (sorter.sorter === tempSorter) {
                            defaultDirection = sorter.defaultDirection
                        }
                    })
                    setSort(tempSorter)
                    setSortDirection(defaultDirection)
                }}
            />
            <Button variant="light" onClick={() => {
                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
            }}>
                <i className={`fa-solid fa-arrow-${sortDirection === 'asc' ? 'down' : 'up'}-a-z`}></i>
            </Button>
        </ButtonGroup>
    )
}

export default _Sorters
