import React, {FC, useCallback, useEffect, useState} from "react";
import Table, {RowRender} from "../../../../Components/Layout/Table";
import FormEmptyOffCanvas from "../../../../Components/Modal/FormEmptyOffCanvas";
import DeleteModal from "../../../../Components/Modal/DeleteModal";
import ImportConfigTable from "../../../../Table/Admin/Edition/Bucket/ImportConfigTable";
import {useTranslation} from "react-i18next";
import {Button} from "react-bootstrap";
import Config from "Entity/Import/Config";
import {useOutletContext} from "react-router-dom";
import useTranslationDataUtils from "../../../../Vendor/Utils/TranslationDataUtils";
import Bucket from "../../../../Entity/Bucket";
const table = ImportConfigTable;

const ImportConfigPage: FC = () => {
    const { t } = useTranslation()
    const {translate} = useTranslationDataUtils()
    const [toggleReload, setToggleReload] = useState<string>('')
    const [configs, setConfigs] = useState<Config[]>([])
    const [config, setConfig] = useState<Config|undefined>(undefined)
    const [bucket, setBucket, show, setShow, search, setSearch, setHandleAdd] = useOutletContext<[Bucket, (bucket: Bucket) => void, boolean, (show:boolean) => void, string, (search:string) => void, (handleAdd: (() => void)|undefined) => void]>()
    const [showDelete, setShowDelete] = useState<boolean>(false)

    const handleDeleteShow = () => setShowDelete(true)
    const handleDeleteClose = (reload = false) => {
        setConfig(undefined)
        setShowDelete(false)
        if (reload) {
            setToggleReload(new Date().toISOString())
        }
    }
    const handleClose = useCallback((reload = false) => {
        setShow(false)
        if (reload) {
            setToggleReload(new Date().toISOString())
        }
    }, [setShow])

    const onSuccess = useCallback((config: Config) => {
        setConfig(config)
        setShow(false)
        setToggleReload(new Date().toISOString())
    }, [setShow])

    const editConfig = useCallback((config: Config) => {
        setConfig(config)
        setShow(true)
    }, [setShow])

    const deleteConfig = useCallback((config: Config) => {
        setConfig(config)
        handleDeleteShow()
    }, [])

    useEffect(() => {
        if (setHandleAdd && typeof setHandleAdd === 'function') {
            setHandleAdd(() => {
                setConfig(undefined)
            })
        }
    }, [setHandleAdd])

    useEffect(() => {
        if (!show) {
            setConfig(undefined);
        }
    }, [show]);

    const actionsRender:RowRender = (config: Config) => (
        <>
            {config?.isGranted?.edit && <Button variant='outline-primary' size='sm' onClick={() => {editConfig(config)}}><i className="fa-solid fa-pencil me-2"></i>{t('app.edit')}</Button>}
            {config?.isGranted?.delete && <Button variant='outline-danger' size='sm' onClick={() => {deleteConfig(config)}}><i className="fa-solid fa-trash me-2"></i>{t('app.delete')}</Button>}
        </>
    );
    if (table.hasOwnProperty('actions')) {
        table.actions.render = actionsRender;
    }

    return <>
        <Table
            path={`/admin/edition/bucket/${bucket.id}/import-config`}
            rows={table}
            toggleReload={toggleReload}
            items={configs}
            setItems={setConfigs}
            keyword={search}
            setKeyword={setSearch}
        />
        <FormEmptyOffCanvas
            i18nTitle={config?.id ? 'app.edit_import_config' : 'app.add_import_config'}
            show={show}
            handleClose={handleClose}
            onSuccess={onSuccess}
            path={`/admin/edition/bucket/${bucket.id}/import-config/${config?.id ?? 'new'}`}
            formPath={!!config?.id}
            method={config?.id ? 'patch' : 'post'}
        />
        <DeleteModal
            handleClose={handleDeleteClose}
            show={showDelete}
            path={`/admin/edition/bucket/${bucket.id}/import-config/${config?.id ?? 'error'}`}
            name={'#' + (config ? `${config.id} - ${translate(config, 'name')}` : 'error')}
        />
    </>
}

export default ImportConfigPage
