import React, {FC, useCallback, useContext, useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import TextType from "../../Vendor/Components/Form/Type/TextType";
import Tag from "../../Entity/Tag";
import {Button} from "react-bootstrap";
import {classUtils as c} from "../../Vendor/Utils/ClassUtils";
import {UserContext} from "../../Vendor/Context/UserContextProvider";
import {BannerContext} from "../../Vendor/Context/BannerContextProvider";

type _ImportModalTagsProps = {
    tags: Tag[]
    setTags: (tags: Tag[]) => void
    onLoad: boolean
    className?: string
    headingLevel?: number
    disabled?: boolean
}

const _ImportModalTags: FC<_ImportModalTagsProps> = ({tags, setTags, onLoad, className, headingLevel= 5, disabled = false}) => {
    if (headingLevel === undefined) {
        headingLevel = 5
    }
    const HeadingTag = `h${headingLevel}` as keyof JSX.IntrinsicElements;
    const {currentUser} = useContext(UserContext)
    const { setBanner } = useContext(BannerContext)
    const { t } = useTranslation()
    const [text, setText] = useState<string>('')
    const [language, setLanguage] = useState<string>(currentUser?.currentOrganization?.defaultLanguage ?? '')

    const addTag = useCallback(() => {
        if (text.length > 0) {
            let newTag: Tag = {
                data: text,
                language: language
            }
            let tempTags = [...tags]
            let alreadyExist: boolean = false
            tempTags.forEach(tempTag => {
                if (tempTag.data == newTag.data && tempTag.language === newTag.language) {
                    alreadyExist = true
                }
            })

            if (alreadyExist) {
                setBanner({type: 'error', i18nTitle: 'app.already_exist', i18nMessage: 'app.tag_already_exist'})
            } else {
                tempTags.push(newTag)
                setTags(tempTags)
                setText('')
            }
        }
    }, [text, language])

    if (!language) return <></>

    return (
        <div className={className}>
            <HeadingTag>Tags</HeadingTag>
            <div className="row mb-3">
                {tags.map((tag, key) => <div className="col-auto">
                    <h5>
                        <span className="badge bg-secondary text-primary tag" key={key}>
                            {tag.data}{currentUser && currentUser.currentOrganization && currentUser.currentOrganization.languages && currentUser.currentOrganization.languages.length > 1 &&<> ({tag.language})</>}
                            {!disabled &&
                                <i className="fa-solid fa-x ms-2" onClick={() => {
                                    let tempTags = [...tags]
                                    tempTags.splice(key, 1)
                                    setTags(tempTags)
                                }}></i>
                            }
                        </span>
                    </h5>
                </div>)}
            </div>
            <div className="input-group mb-3">
                <input value={text} disabled={onLoad || disabled} onChange={e => setText(e.target.value)} onKeyDown={e => {
                    e.stopPropagation()
                    if (['Enter', 'Tab'].includes(e.key) && !onLoad) {
                        addTag()
                    }
                }} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} className="form-control py-2" placeholder={t('app.add_tag')} />
                {currentUser && currentUser.currentOrganization && currentUser.currentOrganization.languages && currentUser.currentOrganization.languages.length > 1 &&
                    <select disabled={onLoad || disabled} value={language} onChange={e => setLanguage(e.target.value)} className="form-control" style={{width: 'auto', flex: 'inherit'}}>
                        {currentUser?.currentOrganization?.languages.map(lang => <option value={lang}>{lang}</option>)}
                    </select>
                }
                <Button disabled={onLoad || disabled} type="button" variant="light" onClick={addTag}>{t('app.add')}</Button>
            </div>
        </div>
    )
}

export default _ImportModalTags
