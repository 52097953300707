import {Choice} from "Vendor/Components/Form/Type/ChoiceType";

const ModeChoice: Choice[] = [
    {
        i18nKey: 'app.export_excel',
        value: 'excel'
    },
    {
        i18nKey: 'app.export_csv',
        value: 'csv'
    }
]

export default ModeChoice
