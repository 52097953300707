import React, {FC, useEffect, useRef, useState} from 'react'
import RepeatedInterface from "Vendor/Definition/Form/Options/RepeatedInterface";
import TextType from "./TextType";
import PasswordType from "./PasswordType";
import EmailType from "./EmailType";

export type RepeatedProps = RepeatedInterface & {
    name: string
    formName: string
    value: string
    setValue?: (value: string) => void
}

const RepeatedType: FC<RepeatedProps> = ({name, formName, value, setValue, type, first_options, second_options, disabled, required}) => {
    const [firstValue, setFirstValue] = useState<string>('')
    const [secondValue, setSecondValue] = useState<string>('')

    useEffect(() => {
        let equals = firstValue === secondValue;
        let firstInput: HTMLObjectElement = document.getElementById(`${formName}_${name}_first`) as HTMLObjectElement;
        let secondInput: HTMLObjectElement = document.getElementById(`${formName}_${name}_second`) as HTMLObjectElement;

        setValue && setValue(equals ? firstValue : '')

        if (firstInput) {
            firstInput.setCustomValidity(!equals ? 'not equals' : '')
        }
        if (secondInput) {
            secondInput.setCustomValidity(!equals ? 'not equals' : '')
        }
    }, [firstValue, secondValue])

    let FormType = undefined
    if (type === 'Password') {
        FormType = PasswordType
    } else if (type === 'Email') {
        FormType = EmailType
    } else {
        FormType = TextType
    }

    return <>
        <FormType id={`${formName}_${name}_first`} fullName={`${formName}[${name}_first]`} name={`${name}_first`} value={firstValue} setValue={setFirstValue} i18nLabels={first_options && first_options.i18nLabels} i18nLabel={first_options && first_options.i18nLabel} label={first_options && first_options.label} disabled={disabled} required={required} />
        <FormType id={`${formName}_${name}_second`} fullName={`${formName}[${name}_second]`} name={`${name}_second`} value={secondValue} setValue={setSecondValue} i18nLabels={second_options && second_options.i18nLabels} i18nLabel={second_options && second_options.i18nLabel} label={second_options && second_options.label} disabled={disabled} required={required} />
    </>
}

export default RepeatedType
