import React, {FC, useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'
import TextType from "./TextType";
import TranslationTypeType from "Vendor/Config/Form/Type/TransationType"
import {TranslationField} from "../../../../Components/Listing/Listing";
import useTranslationDataUtils from "../../../Utils/TranslationDataUtils";


export interface TranslationProps {
    id: string
    name: string
    fullName: string
    type: TranslationTypeType
    initialValue: string
    setTranslationValue: (translationValue: string) => void
    language: string
    label?: string
    i18nLabel?: string
    i18nLabels?: TranslationField
    required?: boolean
}

const TranslationType: FC<TranslationProps> = ({
    id,
    name,
    fullName,
    type,
    initialValue,
    setTranslationValue,
    language,
    label,
    i18nLabel,
    i18nLabels,
    required = true
}) => {
    const { t } = useTranslation()
    const {translateField} = useTranslationDataUtils()
    const [translateLabel] = useState<string>((label
        ? label
        : (i18nLabels
            ? translateField(i18nLabels)
            : t(i18nLabel ?? `app.${name}`)
        )
    ) + ' (' + language +  ')')

    if (type === 'Text') {
        return (
            <TextType id={id} fullName={fullName} name={name} label={translateLabel} value={initialValue} setValue={setTranslationValue} required={required} />
        )
    }

    return (<></>);
}

export default TranslationType
