import React, {FC, ReactNode, useEffect} from 'react'
import FormRow, {FormRowProps} from "./FormRow";
import FormError, {FormErrorProps} from "./FormError";
import {
    FormSkeletonChildrenInterface, FormSubChildrenSkeletonInterface
} from "Vendor/Definition/Form/FormBuilderInterface";
import ChoiceInterface from "../../Definition/Form/Options/ChoiceInterface";

export type DataType = {
    [key: string]: any
}

type ParseChildrenType = (child: React.ReactNode, index?: number) => React.ReactNode
type ParseElementType = (element: React.ReactElement, index?: number) => React.ReactElement
export type FormErrors = {
    [key: string]: string | string[]
}

export type SubFormProps = {
    children: ReactNode
    formData: DataType
    setFormData: (data: DataType) => void
    prefix: string
    childSkeleton?: FormSkeletonChildrenInterface
    setChildSkeleton?: (childSkeleton: FormSkeletonChildrenInterface) => void
}

const SubForm: FC<SubFormProps> = ({
                                 children,
                                 formData,
                                 setFormData,
                                 prefix,
                                 childSkeleton,
                                 setChildSkeleton,
                             }) => {
    let rendered: string[] = []

    useEffect(() => {
        if (childSkeleton) {
            let tempFormData = {...formData}
            let hasChanged: boolean = false

            for (const [name, child] of Object.entries(childSkeleton)) {
                if (child.type === 'Entity') {
                    let value = tempFormData[name]
                    let newValue: string | string[] | undefined = undefined
                    if (typeof value === 'object' && !Array.isArray(value) && value && value.hasOwnProperty('id') && typeof value.id === 'number') {
                        newValue = value.id
                    } else if (Array.isArray(value)) {
                        let tempValue: string[] = []
                        value.forEach(data => {
                            if (typeof data === 'string') {
                                tempValue.push(data)
                            } else if (data.hasOwnProperty('id') && data.id) {
                                tempValue.push(data.id.toString(10))
                            }
                        })
                        newValue = tempValue
                    }

                    if (JSON.stringify(value) !== JSON.stringify(newValue)) {
                        hasChanged = true
                        tempFormData[name] = newValue
                    }
                }
            }

            if (hasChanged) {
                setFormData(tempFormData)
            }
        }
    }, [childSkeleton])

    useEffect(() => {
        let tempChildSkeleton = {...childSkeleton};
        let hasChanged: boolean = false

        for (const [name, child] of Object.entries(tempChildSkeleton)) {
            let options = child.options
            if (child.type === 'Choice') {
                let choiceOptions = options as ChoiceInterface
                if (typeof choiceOptions.choicesBuilder === 'function') {
                    let oldChoices: string = JSON.stringify([...choiceOptions.choices])
                    choiceOptions.choices = choiceOptions.choicesBuilder(formData);

                    if (oldChoices !== JSON.stringify(choiceOptions.choices)) {
                        hasChanged = true
                    }
                }
            }

            if (typeof options.hasDisabled === 'function') {
                let oldDisabled = !!options.disabled
                if (prefix === 'listing-setting_filters_0_') {

                }
                options.disabled = options.hasDisabled(formData)

                if (oldDisabled !== options.disabled) {
                    hasChanged = true
                }
            }
        }

        if (hasChanged && typeof setChildSkeleton === 'function') {
            setChildSkeleton(tempChildSkeleton)
        }
    }, [formData])

    const setData = (key: string, value: any) => {
        let tempFormData = {...formData}
        tempFormData[key] = value
        setFormData(tempFormData)
    }

    const parseElement: ParseElementType = (element) => {
        if (element.type === FormRow || (typeof element.type === 'function' && element.type.hasOwnProperty('name') && element.type.name === 'FormRow')) {
            if (childSkeleton && setChildSkeleton && childSkeleton.hasOwnProperty(element.props.name) && !rendered.includes(element.props.name)) {
                let props: FormRowProps = {...element.props};
                let fieldSkeleton = childSkeleton[props.name]
                props.formName = prefix
                props.field = fieldSkeleton
                props.data = formData[props.name]
                props.setData = (data) => {
                    setData(props.name, data)
                }

                if (props.field.type === 'Collection') {
                    props.subChildrenSkeleton = childSkeleton[props.name].children
                    props.setSubChildrenSkeleton = (subChildrenSkeleton: FormSubChildrenSkeletonInterface) => {
                        let tempChildSkeleton = {...childSkeleton}
                        tempChildSkeleton[props.name].children = subChildrenSkeleton
                        setChildSkeleton(tempChildSkeleton)
                    }
                }

                rendered.push(element.props.name)

                return React.cloneElement(element, props)
            }
            if (childSkeleton && !childSkeleton.hasOwnProperty(element.props.name)) {
                return <p className="alert alert-danger">No field : {element.props.name}, remove this component or add
                    field in form</p>;
            } else if (rendered.includes(element.props.name)) {
                return <p className="alert alert-danger">Duplicate field : {element.props.name}, remove this
                    component</p>;
            }
            return <p className="alert alert-danger">An error on render field : {element.props.name}</p>;
        }
        else if (element.type === FormError || (typeof element.type === 'function' && element.type.hasOwnProperty('name') && element.type.name === 'FormError')) {
            let errors: undefined | string | string[] = undefined;
            if ("name" in element.props && childSkeleton && childSkeleton.hasOwnProperty(element.props.name)) {
                errors = childSkeleton[element.props.name].errors;
            } else if (!("name" in element.props) && childSkeleton) {
                errors = undefined;
            }

            if (typeof errors === 'string' || Array.isArray(errors)) {
                let props: FormErrorProps = {...element.props};
                if (typeof errors === 'string') {
                    errors = [errors];
                }
                props.messages = errors;
                return React.cloneElement(element, props)
            }
        }
        else if ('children' in element.props) {
            let parsedChildren = parseChildren(element.props.children)
            if (parsedChildren !== element.props.children) {
                return React.cloneElement(element, element.props, parsedChildren)
            }
        }

        return element;
    }

    const parseChildren: ParseChildrenType = (child, index) => {
        if (child && typeof child === 'object' && "type" in child) {
            return parseElement(child)
        } else if (child && Array.isArray(child)) {
            let tempChild: React.ReactNode[] = []
            child.forEach((element: React.ReactNode) => {
                tempChild.push(parseChildren(element))
            })

            if (tempChild !== child) {
                return tempChild;
            }
            return child;
        }

        return child;
    }

    return <>
        {React.Children.map(children, (child, index) => {
            return parseChildren(child, index)
        })}
    </>
}

export default SubForm
