import React, {FC, SelectHTMLAttributes, useEffect, useState} from 'react'
import { classUtils as c } from 'Vendor/Utils/ClassUtils'
import { useTranslation } from 'react-i18next'
import {TranslationField} from "../../../../Components/Listing/Listing";
import useTranslationDataUtils from "../../../Utils/TranslationDataUtils";

export type Choice = {
    i18nKey: string
    value: string | Choice[]
}

export interface ChoiceProps extends SelectHTMLAttributes<HTMLSelectElement> {
    id?: string
    name?: string
    fullName?: string
    choices: Choice[]
    translateChoiceLabels?: {
        [key: string]: TranslationField
    }
    value?: string|string[]|undefined
    setValue?: (value: string|string[]) => void
    label?: string
    i18nLabel?: string
    i18nLabels?: TranslationField
    className?: string
    required?: boolean
    disabled?: boolean
    multiple?: boolean
    i18nPlaceholder?: string
    onChange?: (data: any) => void
}

const ChoiceType: FC<ChoiceProps> = ({
    id,
    name,
    fullName,
    choices,
    translateChoiceLabels,
    value = '',
    setValue= () => {},
    className,
    label,
    i18nLabel,
    i18nLabels,
    required = true,
    disabled = false,
    multiple = false,
    i18nPlaceholder = undefined,
    onChange = undefined,
    ...rest
}) => {
    const { t } = useTranslation()
    const {translateField} = useTranslationDataUtils()
    const [textLabel] = useState<string>(label ? label : i18nLabels ? translateField(i18nLabels) : t(i18nLabel ?? `app.${name}`))

    useEffect(() => {
        if (choices.length && required && !i18nPlaceholder && !multiple && !value) {
            let value:string|undefined = undefined

            choices.forEach((choice) => {
                if (typeof choice.value === 'string') {
                    value = choice.value
                    return false;
                }
                choice.value.forEach((subChoice) => {
                    if (typeof subChoice.value === 'string') {
                        value = subChoice.value
                    }
                    return false;
                })
            })

            if (value) {
                setValue(value)
            }
        }
    }, [choices, i18nPlaceholder, multiple, required, setValue])

    return (
        <div className="form-floating mb-3">
            <select
                id={id}
                name={fullName}
                className={c('form-control', className)}
                value={value}
                onChange={(e) => {
                    let newValue: string|string[]|undefined = undefined
                    if (!disabled) {
                        if (!multiple) {
                            newValue = e.target.value
                        } else {
                            newValue = [] as string[]

                            for (let i = 0; i <= (e.target.options.length - 1); i++) {
                                if (e.target.options[i].selected) {
                                    newValue.push(e.target.options[i].value);
                                }
                            }
                        }
                    }

                    if (newValue !== undefined) {
                        setValue(newValue)
                        if (onChange) {
                            onChange(newValue)
                        }
                    }
                }}

                multiple={multiple}
                size={multiple ? choices.length : 1}
                required={required}
                disabled={disabled}
                {...rest}
            >
                {(i18nPlaceholder && !multiple)? (
                    <option value="" selected={!value}>
                        {i18nPlaceholder ? t(i18nPlaceholder) : ''}
                    </option>
                ) : (<></>)}
                {choices.map((choice) =>
                    typeof choice.value === 'string' ? (
                        <option key={choice.value} value={choice.value} selected={((multiple && value?.includes(choice.value)) || (!multiple && value === choice.value))}>
                            {translateChoiceLabels && translateChoiceLabels.hasOwnProperty(choice.value) ? translateField(translateChoiceLabels[choice.value]) : t(choice.i18nKey)}
                        </option>
                    ) : (
                        <optgroup label={t(choice.i18nKey)} key={choice.i18nKey}>
                            {choice.value.map((subChoice) =>
                                typeof subChoice.value === 'string' ? (
                                    <option
                                        key={subChoice.value}
                                        value={subChoice.value}
                                        selected={((multiple && value?.includes(subChoice.value)) || (!multiple && value === subChoice.value))}
                                    >
                                        {translateChoiceLabels && translateChoiceLabels.hasOwnProperty(subChoice.i18nKey) ? translateField(translateChoiceLabels[subChoice.i18nKey]) : t(subChoice.i18nKey)}
                                    </option>
                                ) : (
                                    <option className="text-danger">
                                        Sub-sub-child and more is not supported
                                    </option>
                                ),
                            )}
                        </optgroup>
                    ),
                )}
            </select>
            <label className="form-label" htmlFor={id}>
                {textLabel} :
            </label>
        </div>
    )
}

export default ChoiceType
