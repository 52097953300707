import React, {FC, ReactNode, useContext, useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import useTranslationDataUtils from "../../../Vendor/Utils/TranslationDataUtils";
import {classUtils as c} from "Vendor/Utils/ClassUtils";
import {UserContext} from "../../../Vendor/Context/UserContextProvider";
import {CollectionContext} from "../../../Vendor/Context/CollectionContextProvider";
import Product from "../../../Entity/Shopping/Product";
import ProductStatus from "../../../Config/Shopping/ProductStatus";
import DropdownSelect, {OptionProps} from "../../Button/DropdownSelect";
import Shopping from "../../../Entity/Shopping";
import ShoppingType from "../../../Config/Organization/Setting/ShoppingType";
import Api from "Vendor/Api";
import {Button, Modal} from "react-bootstrap";
import Sticker from "../../../Entity/Product/Sticker";
import Loader from "../../../Vendor/Components/Loader";
import {Link} from "react-router-dom";
import LoadingButton from "../../Button/LoadingButton";
import { ThumbnailModalProps } from 'Components/Listing/View/_CardGroup';
import FormEmptyModal from 'Components/Modal/FormEmptyModal';
import ShoppingProperty from 'Entity/ShoppingProperty';
import Property from "../../../Entity/Property";

export type _ProductProps = {
    shoppingId: number
    shoppingProduct: Product
    shopping: Shopping
    setShopping: (shopping: Shopping) => void
    currency?: string
    type?: ShoppingType
    defaultOpen?: boolean,
    setThumbnailModalProps?: (thumbnailPath: ThumbnailModalProps) => void
    disabled?: boolean
}

const maxQuantity = 10

type ViewProperties = {
    title: string
    value: ReactNode
}

const _Product: FC<_ProductProps> = ({shoppingId, shoppingProduct, shopping, setShopping, currency, type, defaultOpen, setThumbnailModalProps, disabled = false}) => {
    const {t, i18n} = useTranslation()
    const {currentUser} = useContext(UserContext)
    const {translate} = useTranslationDataUtils()
    const {getValue, getByType} = useContext(CollectionContext)
    const [open, setOpen] = useState<boolean>(defaultOpen ?? false)
    const [checked, setChecked] = useState<ProductStatus|undefined>(undefined)
    const [quantity, setQuantity] = useState<number|undefined>(undefined)
    const [message, setMessage] = useState<string|undefined>(undefined)
    const [selectedStickers, setSelectedStickers] = useState<string[]>([])
    const [selectedHistoryStickers, setSelectedHistoryStickers] = useState<string[]>([])
    const [quantities, setQuantities] = useState<OptionProps[]>([])
    const [isStatusDisabled, setIsStatusDisabled] = useState<boolean>(false)
    const [isQuantityLoading, setIsQuantityLoading] = useState<boolean>(false)
    const [showStickerModal, setShowStickerModal] = useState<boolean>(false)
    const [stickers, setStickers] = useState<Sticker[] | undefined>(undefined)
    const [onStickersRequest, setOnStickersRequest] = useState<boolean>(false)
    const [reloadStickerModal, setReloadStickerModal] = useState<string>('')
    const [onChangeStickers, setOnChangeStickers] = useState<boolean>(false)
    const [onChangeHistoryStickers, setOnChangeHistoryStickers] = useState<boolean>(false)
    const [onDeleteProduct, setOnDeleteProduct] = useState<boolean>(false)
    const [shoppingProperties, setShoppingProperties] = useState<ShoppingProperty[]>([])
    const [viewProperties, setViewProperties] = useState<ViewProperties[]>([])
    const [showPropertyModal, setShowPropertyModal] = useState<boolean>(false)
    const [disabledStickerButton, setDisabledStickerButton] = useState<boolean>(false)

    useEffect(() => {
        setDisabledStickerButton(
            !shopping.designedDelivery
            || ('validated' !== shoppingProduct.status
                && !('outputted' === shoppingProduct.status && currentUser?.previousInternal)))
    }, [currentUser?.previousInternal, shopping.designedDelivery, shoppingProduct.status])

    const changeStatus = (status: ProductStatus) => {
        setIsStatusDisabled(true)
        Api.patch(`/shopping/product/${shoppingProduct.id}/status`, {status: status})
            .then((response) => {
                setShopping(response.data as Shopping)
            }).catch(() => {}).then(() => {
                setIsStatusDisabled(false)
        })
    }
    const changeQuantity = (quantity: number) => {
        setIsQuantityLoading(true)
        Api.patch(`/shopping/product/${shoppingProduct.id}/quantity`, {quantity: quantity})
            .then((response) => {
                setShopping(response.data as Shopping)
            }).catch(() => {}).then(() => {
            setIsQuantityLoading(false)
        })
    }

    useEffect(() => {
        let tempViewProperties: ViewProperties[] = []
        let tempProperties: Property[] = []
        if (type === 'shopping' && currentUser?.currentOrganization?.shoppingViewProperties.length) {
            tempProperties = currentUser.currentOrganization.shoppingViewProperties
        } else if (type === 'pre-order' && currentUser?.currentOrganization?.preOrderViewProperties.length) {
            tempProperties = currentUser.currentOrganization.preOrderViewProperties
        }

        tempProperties.forEach((property) => {
            tempViewProperties.push({
                title: translate(property, 'label'),
                value: getValue(shoppingProduct.product, property)
            })
        })

        setViewProperties(tempViewProperties)
    }, [shoppingProduct, type, currentUser?.currentOrganization?.shoppingViewProperties, currentUser?.currentOrganization?.preOrderViewProperties, translate, getValue])

    useEffect(() => {
        if (currentUser?.currentOrganization) {
            if (type === 'pre-order') {
                setShoppingProperties([...currentUser?.currentOrganization?.preOrderProductProperties])
            } else {
                setShoppingProperties([...currentUser?.currentOrganization?.shoppingProductProperties])
            }
        } else {
            setShoppingProperties([])
        }
    }, [type])

    useEffect(() => {
        let tempQuantities: OptionProps[] = []
        if (type === 'pre-order' && shoppingProduct.unit_price_ht) {
            let formater = new Intl.NumberFormat(i18n.language, { style: 'currency', currency: currency});
            for (let i = 1; i <= maxQuantity; i++) {
                tempQuantities.push({value: i, label: i + ' : ' + formater.format(shoppingProduct.unit_price_ht * i / 100)})
            }
        } else {
            for (let i = 1; i <= maxQuantity; i++) {
                tempQuantities.push({value: i, label: i.toString()})
            }
        }

        setQuantities(tempQuantities)
    }, [shoppingProduct, i18n.language, currency, type])


    useEffect(() => {
        setChecked(shoppingProduct.status)
        setQuantity(shoppingProduct.quantity)
    }, [shoppingProduct])

    useEffect(() => {
        if (showStickerModal) {
            setOnStickersRequest(true)
            Api.get('/shopping/' + shoppingId + '/product/' + shoppingProduct.id + '/sticker')
                .then((response) => {
                    setStickers(response.data as Sticker[])
                })
                .catch(() => {
                    setStickers(undefined)
                })
                .then(() => {
                    setOnStickersRequest(false)
                });
        }
    }, [shoppingId, shoppingProduct.id, showStickerModal, reloadStickerModal])

    useEffect(() => {
        if (showStickerModal && shoppingProduct) {
            setSelectedStickers(shoppingProduct.stickers ?? [])
        }
    }, [shoppingProduct, showStickerModal])

    const changeStickers = (stickers: string[], history: boolean = false) => {
        setOnChangeStickers(true)
        Api.patch('/shopping/' + shoppingId + '/product/' + shoppingProduct.id + '/sticker' + (history ? '?history=1' : ''), {stickers: stickers})
            .then((response) => {
                setShopping(response.data as Shopping)
            })
            .catch(() => {})
            .then(() => {
                setSelectedStickers([])
                setSelectedHistoryStickers([])
                setStickers(undefined)
                setOnChangeStickers(false)
                setShowStickerModal(false)
            })
    }

    const deleteProduct = () => {
        setOnDeleteProduct(true)
        Api.delete(`/shopping/${shoppingId}/product/${shoppingProduct.id}`)
            .then((response) => {
                setShopping(response.data as Shopping)
            })
            .catch(() => {})
            .then(() => {
                setOnDeleteProduct(false)
            })
    }

    const propertiesList = () => {
        if (!shoppingProperties.length) return (<></>)

        return <div className="product-properties mt-2">
            <div className="row justify-content-between align-items-center">
                <div className="col-auto">
                    <p className="h5 m-O">
                        {t('app.properties')}:&nbsp;
                    </p>
                    <ul>
                        {shoppingProperties.map(shoppingProperty => <>
                            <li>
                                <h6>{translate(shoppingProperty.property, 'label')}</h6>
                                <p>{getValue(shoppingProduct, shoppingProperty.property, 'app.not_specified')}</p>
                            </li>
                        </>)}
                    </ul>
                </div>
                <div className="col-auto">
                    <button className="btn btn-outline-primary btn-sm mr-2" disabled={'validated' !== shoppingProduct.status && !('outputted' === shoppingProduct.status && currentUser?.previousInternal)} onClick={() => { setShowPropertyModal(true) }}><span className="fa fa-pencil me-2"></span>{t('app.edit')}</button>
                </div>
            </div>
        </div>
    }

    const viewPropertiesList = () => {
        if (!viewProperties.length) return (<></>)

        return <div className="product-properties">
            <div className="row justify-content-between align-items-center">
                <div className="col-auto">
                    <ul>
                        {viewProperties.map(data => <>
                            <li>
                                <h6 className="mb-0">{data.title}</h6>
                                <p className="mb-0">{data.value}</p>
                            </li>
                        </>)}
                    </ul>
                </div>
            </div>
        </div>
    }

    const propertiesModal = () => {
        return <FormEmptyModal
            path={`/shopping/product/${shoppingProduct.id}/properties`}
            method={'PATCH'}
            formPath={true}
            i18nTitle={'app.edit'}
            show={showPropertyModal}
            handleClose={() => setShowPropertyModal(false)}
            onSuccess={(response) => {
                setShopping(response as Shopping)
                setShowPropertyModal(false)
            }}
        />
    }

    if ('pre-order' === type) {
        return (
            <>
                <div className={c('product', open && 'open')}>
                    <div className="product-header">
                        <div className="product-info">
                            {shoppingProduct.product.thumbnail && <span className="img" style={{backgroundImage: 'url(' + shoppingProduct.product.thumbnail.replaceAll('__SIZING__', 'medium') + ')'}}></span>}
                            <div>
                                <p className="name">{!open && (shoppingProduct.quantity + 'x ')}{translate(shoppingProduct.product, 'name')}</p>
                                {!open && <p className="ref">
                                    {viewProperties.map((property, index) => {
                                        return (
                                            <span key={index} className="me-1">{property.value}</span>
                                        )
                                    })}
                                </p>}
                            </div>
                        </div>
                        {!disabled &&
                            <div className="product-actions">
                                {shoppingProduct.price && <span className="price">{shoppingProduct.price}</span>}
                                {!open ? <>
                                        <span className={c("badge-status", `status-${shoppingProduct.status}`)}>{t(`app.item_status.${shoppingProduct.status}`)}</span>
                                        <button className="stretched-link" onClick={() => {
                                            setOpen(!open)
                                        }}><i className="fas fa-pen-to-square"></i></button>
                                    </>
                                    : <>
                                        {!isQuantityLoading ? <DropdownSelect className="select-quantity" variant="light" i18nLabel="app.table.show_entries" options={quantities}
                                                                            selected={quantity ?? 0} label={(quantity ?? 0).toString()} setSelected={(quantity) => {
                                            if (typeof quantity !== 'number') return
                                            setQuantity(quantity)
                                            changeQuantity(quantity)
                                        }}/> : <div className="spinner-border spinner-border-sm" role="status"></div>}
                                        <LoadingButton variant="outline-light" isLoading={onDeleteProduct} onClick={deleteProduct}><i className="fas fa-trash-can"></i></LoadingButton>
                                        {!defaultOpen && <button className="stretched-link" onClick={() => {
                                            setOpen(!open)
                                        }}><i className="fas fa-pen-to-square"></i></button>}
                                    </>
                                }
                            </div>
                        }
                    </div>
                    {open && <div className="product-body">
                        <div className="product-status-line">
                            <label className={c(!shoppingProduct.actions.cancel_refuse && checked !== 'waited' && 'disabled', checked === 'waited' && 'checked')}>
                                <input type="radio" disabled={!shoppingProduct.actions.cancel_refuse || isStatusDisabled || disabled} checked={checked === 'waited'} onClick={() => {
                                    setChecked('waited')
                                    changeStatus('waited')
                                }} />
                                {t(`app.item_status.waited`)}
                            </label>
                            <label className={c(!shoppingProduct.actions.refuse && checked !== 'refused' && 'disabled', checked === 'refused' && 'checked')}>
                                <input type="radio" disabled={!shoppingProduct.actions.refuse || isStatusDisabled || disabled} checked={checked === 'refused'} onClick={() => {
                                    setChecked('refused')
                                    changeStatus('refused')
                                }} />
                                {t(`app.item_status.refused`)}
                            </label>
                            <label className={c(!shoppingProduct.actions.cancel_final_pre_order && !shoppingProduct.actions.validate && checked !== 'validated' && 'checked' && 'disabled', checked === 'validated' && 'checked')}>
                                <input type="radio" disabled={(!shoppingProduct.actions.validate && !shoppingProduct.actions.cancel_final_pre_order && checked !== 'validated') || isStatusDisabled || disabled} checked={checked === 'validated'} onClick={() => {
                                    setChecked('validated')
                                    changeStatus('validated')
                                }} />
                                {t(`app.item_status.validated`)}
                            </label>
                            <label className={c(!shoppingProduct.actions.send && (!checked || checked !== 'sent') && 'disabled', checked && 'sent' === checked && 'checked')}>
                                <input type="radio" disabled={!shoppingProduct.actions.send || isStatusDisabled || disabled} checked={checked && 'sent' === checked} onClick={() => {
                                    setChecked('sent')
                                    changeStatus('sent')
                                }} />
                                {t(`app.item_status.sent`)}
                            </label>
                            <hr/>
                        </div>
                        {viewPropertiesList()}
                        {propertiesList()}
                    </div>}
                </div>

                {propertiesModal()}
            </>
        )
    }

    return (
        <>
            <div className={c('product', open && 'open')}>
                <div className="product-header" onClick={(e) => {
                    e.stopPropagation()

                    if (defaultOpen) {
                        return
                    }

                    setOpen(!open)
                }}>
                    <div className="product-info">
                        {shoppingProduct.product.thumbnail && <span className="img" style={{ backgroundImage: 'url(' + shoppingProduct.product.thumbnail.replaceAll('__SIZING__', 'medium') + ')' }}
                            onClick={(e) => {
                                e.stopPropagation()

                                if (setThumbnailModalProps) {
                                    let path:string = ''
                                    let mimeType:string = ''
                                    if (shoppingProduct.product.view) {
                                        path = shoppingProduct.product.view
                                        mimeType = shoppingProduct.product.viewMimeType ?? ''
                                    } else {
                                        path = shoppingProduct.product.thumbnail?.replaceAll('__SIZING__', 'large') ?? ''
                                        mimeType = 'image/jpeg'
                                    }
                                    
                                    let thumbnailPath: ThumbnailModalProps = {
                                        path: path,
                                        name: translate(shoppingProduct.product, 'name'),
                                        mimeType: mimeType,
                                    }
                                    shoppingProduct.product.thumbnail && setThumbnailModalProps(thumbnailPath)
                                }
                            }}
                        ></span>}
                        <div>
                            <p className="name"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    
                                    window.open(`/c/${shoppingProduct.product.catalog?.primarySlug}/${shoppingProduct.product.id}`, '_blank')
                                }}
                            >{!open && (shoppingProduct.quantity + 'x ')}{translate(shoppingProduct.product, 'name')}</p>
                            {!open && <p className="ref">
                                {currentUser?.currentOrganization?.options.includes('sticker') && shoppingProduct.stickers && shoppingProduct.stickers.length ?
                                        shoppingProduct.stickers?.map(sticker => <span className="badge bg-primary me-1">{sticker}</span>)
                                        : <i className="text-muted">{t('app.no_sticker')}</i>
                                    }
                                {viewProperties.map((property, index) => {
                                    return (
                                        <span key={index} className="me-1">{property.value}</span>
                                    )
                                })}
                            </p>}
                        </div>
                    </div>
                    {!disabled &&
                        <div className="product-actions">
                            {!open ?
                                <>
                                    <span className={c("badge-status", `status-${shoppingProduct.status}`)}>{t(`app.item_status.${shoppingProduct.status}`)}</span>
                                    <button><i className="fas fa-pen-to-square"></i></button>
                                </> : <>
                                    {!isQuantityLoading ? <DropdownSelect className="select-quantity" variant="light" i18nLabel="app.table.show_entries" options={quantities}
                                                        selected={quantity ?? 0} label={(quantity ?? 0).toString()} setSelected={(quantity) => {
                                                        if (typeof quantity !== 'number') return
                                        setQuantity(quantity)
                                        changeQuantity(quantity)
                                    }}/> : <div className="spinner-border spinner-border-sm" role="status"></div>}
                                    <LoadingButton variant="outline-light" isLoading={onDeleteProduct} onClick={deleteProduct}><i className="fas fa-trash-can"></i></LoadingButton>
                                    {!defaultOpen && <button><i className="fas fa-pen-to-square"></i></button>}
                                </>
                            }
                        </div>
                    }
                </div>
                {open && <div className="product-body">
                    <div className="product-status-line">
                        <label className={c(!shoppingProduct.actions.cancel_refuse && checked !== 'waited' && 'disabled', checked === 'waited' && 'checked')}>
                            <input type="radio" disabled={!shoppingProduct.actions.cancel_refuse || isStatusDisabled || disabled} checked={checked === 'waited'} onClick={() => {
                                setChecked('waited')
                                changeStatus('waited')
                            }} />
                            {t(`app.item_status.waited`)}
                        </label>
                        <label className={c(!shoppingProduct.actions.refuse && checked !== 'refused' && 'disabled', checked === 'refused' && 'checked')}>
                            <input type="radio" disabled={!shoppingProduct.actions.refuse || isStatusDisabled || disabled} checked={checked === 'refused'} onClick={() => {
                                setChecked('refused')
                                changeStatus('refused')
                            }} />
                            {t(`app.item_status.refused`)}
                        </label>
                        <label className={c(!shoppingProduct.actions.validate && checked !== 'validated' && 'checked' && 'disabled', checked === 'validated' && 'checked')}>
                            <input type="radio" disabled={(!shoppingProduct.actions.validate && checked !== 'validated') || isStatusDisabled || disabled} checked={checked === 'validated'} onClick={() => {
                                setChecked('validated')
                                changeStatus('validated')
                            }} />
                            {t(`app.item_status.validated`)}
                        </label>
                        <label className={c(!shoppingProduct.actions.cancel_final_shopping && !shoppingProduct.actions.output && checked !== 'outputted' && 'disabled', checked === 'outputted' && 'checked')}>
                            <input type="radio" disabled={(!shoppingProduct.actions.output && !shoppingProduct.actions.cancel_final_shopping) || isStatusDisabled || disabled} checked={checked === 'outputted'} onClick={() => {
                                setChecked('outputted')
                                changeStatus('outputted')
                            }} />
                            {t(`app.item_status.outputted`)}
                        </label>
                        <label className={c(!shoppingProduct.actions.return && (!checked || !['returned', 'gift', 'loft'].includes(checked)) && 'disabled', checked && ['returned', 'gift', 'loft'].includes(checked) && 'checked')}>
                            <input type="radio" disabled={!shoppingProduct.actions.return || isStatusDisabled || disabled} checked={checked && ['returned', 'gift', 'loft'].includes(checked)} onClick={() => {
                                setChecked('returned')
                                changeStatus('returned')
                            }} />
                            {t(`app.item_status.returned`)}
                        </label>
                        <hr/>
                    </div>
                    {currentUser?.currentOrganization?.options.includes('sticker') ? <div className="product-stickers mt-3">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-auto">
                                <p className="h6 m-O">
                                    {t(('app.sticker' + ((shoppingProduct.stickers?.length ?? 0) > 1 ? 's' : '')))}&nbsp;:&nbsp;
                                    {shoppingProduct.stickers && shoppingProduct.stickers.length ?
                                        shoppingProduct.stickers?.map(sticker => <span className="badge bg-primary me-1">{sticker}</span>)
                                        : <i className="text-muted">{t('app.no_sticker')}</i>
                                    }
                                </p>
                            </div>
                            {!disabled &&
                                <div className="col-auto">
                                    <button className="btn btn-outline-primary btn-sm mr-2" disabled={disabledStickerButton} onClick={ () => {setShowStickerModal(true)} }><span className="fa fa-plus me-2"></span>{t('app.add')}</button>
                                    {disabledStickerButton && !shopping.designedDelivery && <i className="text-warning">{t('app.designed_delivery_required')}</i>}
                                    {!disabledStickerButton &&  <Modal show={showStickerModal} onHide={() => {setShowStickerModal(false)}} onExited={() => {
                                        setSelectedStickers([])
                                        setStickers(undefined)
                                        setOnStickersRequest(false)
                                    }}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>{t('app.add_sticker')}</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            {undefined === stickers
                                                ? (onStickersRequest
                                                       ? <Loader />
                                                       : <button className="badge bg-danger" onClick={() => {
                                                            setReloadStickerModal(new Date().toISOString())
                                                       }}><i className="fa fa-cycle"></i>Error</button>
                                                )
                                                : (
                                                    stickers.length === 0
                                                        ? <>
                                                            <p>{t('app.no_sticker')}</p>
                                                            <Link to={`/c/${shoppingProduct.product.catalog?.primarySlug}/${shoppingProduct.product.id}`} className="badge btn btn-sm btn-primary my-2 ms-2"><i className="fas fa-shopping-bag"></i> {translate(shoppingProduct.product, 'name') + ' #' + shoppingProduct.product.id}</Link>
                                                        </>
                                                        : <>
                                                            <div className="list-group my-3">
                                                                {stickers.map((sticker, index) => {
                                                                    return (
                                                                        <>
                                                                        <button className={c('list-group-item list-group-item-action', selectedStickers.includes(sticker.code) && 'active')} onClick={() => {
                                                                            if (selectedStickers.includes(sticker.code)) {
                                                                                setSelectedStickers(selectedStickers.filter((s) => s !== sticker.code))
                                                                            } else {
                                                                                setSelectedStickers([...selectedStickers, sticker.code])
                                                                            }
                                                                        }} disabled={!sticker.available && !selectedStickers.includes(sticker.code)}>
                                                                            <span className={c('fa me-2', selectedStickers.includes(sticker.code) ? 'fa-check' : 'fa-plus')}></span>
                                                                            <span className="me-2">{sticker.code}</span>
                                                                            {!sticker.available
                                                                                &&
                                                                                <>
                                                                                    {'in-stock' !== sticker.status
                                                                                        ? t(`app.sticker_status.${sticker.status}`)
                                                                                        : t('app.sticker_status.not-available-for-this-date')
                                                                                    }
                                                                                </>
                                                                            }
                                                                        </button>
                                                                    </>)
                                                                })}
                                                            </div>
                                                            {currentUser?.previousInternal &&
                                                            <>
                                                                <p className="h5">(Admin) {t('app.add_to_history')}</p>
                                                                <i>{t('app.pass_to_returned_status')}</i>
                                                                <div className="list-group my-3">
                                                                    {stickers.map((sticker, index) => {
                                                                        return (
                                                                            <>
                                                                                <button className={c('list-group-item list-group-item-action', selectedHistoryStickers.includes(sticker.code) && 'active')} onClick={() => {
                                                                                    if (selectedHistoryStickers.includes(sticker.code)) {
                                                                                        setSelectedHistoryStickers(selectedHistoryStickers.filter((s) => s !== sticker.code))
                                                                                    } else {
                                                                                        setSelectedHistoryStickers([...selectedHistoryStickers, sticker.code])
                                                                                    }
                                                                                }}>
                                                                                    <span className={c('fa me-2', selectedHistoryStickers.includes(sticker.code) ? 'fa-check' : 'fa-plus')}></span>
                                                                                    <span className="me-2">{sticker.code}</span>
                                                                                </button>
                                                                            </>)
                                                                    })}
                                                                </div>
                                                            </>
                                                            }
                                                        </>
                                                )
                                            }
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" disabled={onChangeStickers} onClick={() => {setShowStickerModal(false)}}>
                                                {t('app.cancel')}
                                            </Button>
                                            <Button variant="primary" disabled={onChangeStickers} onClick={() => {
                                                changeStickers(selectedStickers)
                                            }}>
                                                {t('app.save')}
                                            </Button>
                                            {currentUser?.previousInternal && <Button variant="info" disabled={onChangeStickers} onClick={() => {
                                                changeStickers(selectedHistoryStickers, true)
                                            }}>
                                                {t('app.save')} (History)
                                            </Button>}
                                        </Modal.Footer>
                                    </Modal>}
                                </div>
                            }
                        </div>
                    </div> : null}
                    {viewPropertiesList()}
                    {propertiesList()}
                </div>}
            </div>

            {propertiesModal()}
        </>
    )
}

export default _Product
