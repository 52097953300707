import {Choice} from "Vendor/Components/Form/Type/ChoiceType";

const ModeChoice: Choice[] = [
    {
        i18nKey: 'app.groupedActionChoice.rename.addText',
        value: 'addText',
    },
    {
        i18nKey: 'app.groupedActionChoice.rename.findAndReplace',
        value: 'findAndReplace',
    },
]

export default ModeChoice
