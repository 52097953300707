import React, {FC, useCallback, useContext, useEffect, useRef, useState} from 'react'
import {useSearchParams} from 'react-router-dom'
import ListingSetting from "Entity/Collection/ListingSetting";
import _Filters from "./_Filters";
import _Header from "./_Header";
import _Content from "./_Content";
import View from "Config/View";
import SorterEnum from "Config/Collection/Sorter";
import Sorter from "Entity/Collection/ListingSetting/Sorter";
import direction from "Config/Direction";
import Pagination from "../Pagination";
import EntityInterface from "Vendor/Definition/EntityInterface";
import Api from "Vendor/Api";
import {createArrUtils} from "Vendor/Utils/CreateArrayUtils";
import {SortWay, TableResponse, WAIT_INTERVAL} from "../Layout/Table";
import Collection from "Config/Collection";
import {useTranslation} from "react-i18next";
import GroupedAction from "Entity/Collection/ListingSetting/GroupedAction";
import MailingListType from "../../Config/MailingListType";
import {BannerContext} from "../../Vendor/Context/BannerContextProvider";
import {DataType} from "../../Vendor/Components/Form";
import {ButtonVariant} from "react-bootstrap/types";
import TableColumn from "../../Entity/Collection/ListingSetting/View/TableColumn";
import Property from 'Entity/Property';
import type {ShoppingActionModal} from "./GroupedAction/_ShoppingModal";
import {UserContext} from "../../Vendor/Context/UserContextProvider";
import {useListingContext} from 'Hooks/useListingContext';
import {
    _ArchiveStickersModal,
    _BimboShareModal,
    _DownloadModal,
    _ExportModal,
    _GenerateStickersModal,
    _GroupModal,
    _MailingListModal,
    _MainModal,
    _PrintStickersModal,
    _PropertyModal,
    _PublishModal,
    _RemoveModal,
    _RenameModal,
    _ReportingModal,
    _RestoreModal,
    _ShoppingModal,
    _TagModal,
    _TransformModal
} from './GroupedAction';

export type PaginationMode = "page" | "infinite-scroll"

export interface CustomAction {
    method: (item: any) => void
    granted?: string
    i18nLabel?: string
    icon?: string
    variant?: ButtonVariant
    isLoading?: boolean
    success?: boolean
    setSuccess?: (success: boolean) => void
}

export interface ListingItems {
    [key: number]: EntityInterface
}


export interface ListingProps {
    path: string
    entity?: Collection
    id?: number
    slug?: string
    setting: ListingSetting
    search: string
    setSearch: (search: string) => void
    toggleReload?: string
    setToggleReload?: (toggleReload: string) => void
    editAction?: (item: EntityInterface) => void
    editUri?: string
    deleteAction?: (item: EntityInterface) => void
    selectable?: boolean
    customActions?: CustomAction[]
    hideHeader?: boolean
    hideDownloadButton?: boolean
    disableMainActionButton?: boolean
    paginationMode?: PaginationMode
    selectedItems?: EntityInterface[]
    setSelectedItems?: (selected: EntityInterface[]) => void
    selectMultiple?: boolean
    initialShown?: number
    contextMode?: 'default' | 'modal'
}

const getDefaultSorter = (sorters: Sorter[]): Sorter | undefined => {
    let sorter: Sorter | undefined = undefined
    sorters.forEach((currentSorter) => {
        if (currentSorter.defaultSort) {
            sorter = currentSorter;
        }
    })

    return sorter;
}

const getDefaultVisibleColumns = (tableColumns: TableColumn[]): TableColumn[] => {
    let defaultTableColumns: TableColumn[] = []

    tableColumns.forEach(tableColumn => {
        if (tableColumn.visibleByDefault) {
            defaultTableColumns.push(tableColumn)
        }
    })

    return defaultTableColumns
}

export type RequestFilters = {
    [key: string]: string[] | FilterValues
}

export type RequestDataType = {
    keyword: string
    sortWay: SortWay
    sortName: string
    nbEntries: number
    currentPage: number
    filters: RequestFilters
    token: string
}

export type TranslationField = {
    [key: string]: {
        [key: string]: string
    } | string
}

export type FilterChoice = {
    value: string
    key: TranslationField | string
    criteria?: DataType
    type?: MailingListType
}

export type OptionType = {
    choices?: FilterChoice[]
    min?: string
    max?: string
}
export type OptionsType = {
    [key: string]: OptionType
}

export type FilterValue = TranslationField | string

export type FilterValues = {
    [key: string]: FilterValue | undefined
}

export type GlobalFilterValues = {
    [key: string]: FilterValues
}

export const getFilterChoiceKey = (value: string, choices: FilterChoice[]): TranslationField | string | undefined => {
    let key: TranslationField | string | undefined = undefined
    choices.forEach(choice => {
        if (choice.value === value) {
            key = choice.key
        }
    })

    return key
}

export const hasFilters = (globalFilterValues: GlobalFilterValues): boolean => {
    return Object.values(globalFilterValues).some(filterValues => Object.keys(filterValues).length)
}

export const getRequestFilters = (globalFilterValues: GlobalFilterValues, mailingListFilter: boolean = false): RequestFilters => {
    let requestFilters = {} as RequestFilters

    for (const [slug, filtersValues] of Object.entries(globalFilterValues)) {
        let keys = Object.keys(filtersValues)
        if (
            keys.length > 0
            && keys.length <= 2
            && (
                (keys.length === 2
                    && keys.includes('min')
                    && (Date.parse(filtersValues['min'] as string) > 0 || Number.isInteger(parseInt(filtersValues['min'] as string, 10)))
                    && keys.includes('max')
                    && (Date.parse(filtersValues['max'] as string) > 0 || Number.isInteger(parseInt(filtersValues['max'] as string, 10)))
                )
                || (
                    keys.length === 1 && ['min', 'max'].includes(keys[0])
                    && (Date.parse(filtersValues[keys[0]] as string) > 0 || Number.isInteger(parseInt(filtersValues[keys[0]] as string, 10)))
                )
            )
        ) {
            requestFilters[slug] = {...filtersValues}
            continue
        }
        if (mailingListFilter) {
            requestFilters[slug] = {...filtersValues}
            continue
        }
        let values: string[] = Object.keys(filtersValues)
        if (values.length) {
            requestFilters[slug] = values
        }
    }

    return requestFilters

}

export type MailingActionModal = undefined | 'edit' | 'newStatic' | 'newDynamic' | 'addStatic' | 'replaceDynamic'

export type TableConfig = {
    'shown': number,
    'sort': SorterEnum,
    'sortDirection': direction,
    'view': View,
    'condensed': boolean,
    token?: string
}

const Listing: FC<ListingProps> = ({
                                       path,
                                       entity,
                                       id,
                                       slug,
                                       setting,
                                       search,
                                       setSearch,
                                       toggleReload,
                                       setToggleReload,
                                       editAction,
                                       editUri,
                                       deleteAction,
                                       selectable = true,
                                       customActions,
                                       hideHeader = false,
                                       hideDownloadButton = false,
                                       disableMainActionButton = false,
                                       paginationMode = "page",
                                       selectedItems,
                                       setSelectedItems,
                                       selectMultiple = true,
                                       initialShown = 100,
                                       contextMode = "default",
                                   }) => {
    const defaultSorter = getDefaultSorter(setting.sorters)
    let initConfig: TableConfig = {
        'shown': initialShown,
        'sort': defaultSorter?.sorter ?? 'createdAt',
        'sortDirection': defaultSorter?.defaultDirection ?? 'desc',
        'view': setting.views[0] ?? 'table',
        'condensed': setting.cardCondensedByDefault
    }

    let config = localStorage.getItem('table.' + path)
    if (config) {
        let tableConfig: TableConfig = JSON.parse(config)
        if (tableConfig) {
            initConfig.shown = tableConfig.shown
            initConfig.sort = tableConfig.sort
            initConfig.sortDirection = tableConfig.sortDirection
            initConfig.view = tableConfig.view
            initConfig.condensed = tableConfig.condensed
        }
    }

    const {t} = useTranslation()
    const {currentUser} = useContext(UserContext)
    const {setBanner} = useContext(BannerContext)
    const [lastToken, setLastToken] = useState<string>('')
    const [view, setView] = useState<View>(initConfig.view)
    const [condensed, setCondensed] = useState<boolean>(initConfig.condensed)
    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const [onError, setOnError] = useState<boolean>(false)
    const [maxResult, setMaxResult] = useState<number>(0)
    const { items, setItems, currentPage, setCurrentPage, pages, setPages } = useListingContext(contextMode)
    const [shown, setShown] = useState<number>(initConfig.shown)
    const [sort, setSort] = useState<SorterEnum | undefined>(initConfig.sort)
    const [sortDirection, setSortDirection] = useState<direction | undefined>(initConfig.sortDirection)
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout>()
    const [options, setOptions] = useState<OptionsType | undefined>(undefined)
    const [globalFilterValues, setGlobalFilterValues] = useState<GlobalFilterValues>({})
    const [selected, setSelected] = useState<number[]>(() => {
        if (!selectedItems?.length) {
            return []
        }

        return selectedItems.map(item => item.id ?? 0)
    })
    const [showMainActionModal, setShowMainActionModal] = useState<boolean>(false)
    const [showActionModals, setShowActionModals] = useState<{ [key: string]: boolean | Property }>({
        download: false,
        export: false,
        remove: false,
        share: false,
        mailingList: false,
        property: false,
        publish: false,
        unPublish: false,
        bimboShare: false,
        shopping: false,
        group: false,
        generateStickers: false,
        archiveStickers: false,
        printStickers: false,
        tag: false,
        transform: false,
        withoutProduct: false,
        withoutFile:false,
        reporting: false,
        rename: false,
        restore: false,
    })
    const [mailingListActionType, setMailingListActionType] = useState<MailingActionModal>(undefined)
    const [disabledMainModalAction, setDisabledMainModalAction] = useState<boolean>(false)
    const [hasSearch, setHasSearch] = useState<boolean>(false)
    const [fromMain, setFromMain] = useState<boolean>(false)
    const [actionModalFormIsLoaded, setActionModalFormIsLoaded] = useState<boolean>(false)
    const [actionModalFormIsLoading, setActionModalFormIsLoading] = useState<boolean>(false)
    const [toggleReloadOption, setToggleReloadOption] = useState<boolean>(true)
    const defaultVisibleColumns = getDefaultVisibleColumns(setting.tableColumns)
    const [visibleColumns, setVisibleColumn] = useState<TableColumn[]>(defaultVisibleColumns)
    const [shoppingActionType, setShoppingActionType] = useState<ShoppingActionModal>(undefined)
    const [availableActions, setAvailableActions] = useState<GroupedAction[]>(setting.groupedActions)
    const loader = useRef(null);
    const [hasPlatform, setHasPlatform] = useState<boolean>(currentUser?.currentOrganization?.platforms
        ? currentUser?.currentOrganization?.platforms?.length > 0
        : false)
    const [searchParams, setSearchParams] = useSearchParams()

    const clearSelection = () => {
        setSelected([])
    }

    useEffect(() => {
        let tableConfig: TableConfig = {
            shown,
            sort: sort ?? defaultSorter?.sort ?? 'createdAt',
            sortDirection: sortDirection ?? defaultSorter?.defaultDirection ?? 'desc',
            view,
            condensed,
            token: lastToken,
        }
        localStorage.setItem('table.' + path, JSON.stringify(tableConfig))
    }, [shown, condensed, view, sort, sortDirection, path, defaultSorter?.sort, defaultSorter?.defaultDirection, lastToken])

    useEffect(() => {
        setView(setting.views[0] ?? 'table')
        setCondensed(setting.cardCondensedByDefault)
        let defaultSorter = getDefaultSorter(setting.sorters)
        setSort(defaultSorter?.sorter)
        let defaultVisibleColumns = getDefaultVisibleColumns(setting.tableColumns)
        setVisibleColumn(defaultVisibleColumns)

        let tempAvailableActions: GroupedAction[] = []
        setting.groupedActions.forEach(groupedAction => {
            if (groupedAction.action === 'export') {
                if (setting.exports?.length) {
                    tempAvailableActions.push(groupedAction)
                }
            } else if (groupedAction.action === 'publish' || groupedAction.action === 'unPublish') {
                if (hasPlatform) {
                    tempAvailableActions.push(groupedAction)
                }
            } else {
                tempAvailableActions.push(groupedAction)
            }
        })
        setAvailableActions(tempAvailableActions)

    }, [setting])

    const handleObserver = useCallback((entries: any[]) => {
        const target = entries[0];
        if (target.isIntersecting
            && isLoaded
            && currentPage < pages.length
        ) {
            setCurrentPage(currentPage + 1)
            setIsLoaded(false)
        }
    }, [currentPage, setCurrentPage, isLoaded, pages]);

    useEffect(() => {
        setGlobalFilterValues({})
    }, [entity, id])

    useEffect(() => {
        const option = {
            root: null,
            rootMargin: "20px",
            threshold: 0
        };
        const observer = new IntersectionObserver(handleObserver, option);
        if (loader.current) observer.observe(loader.current);
    }, [handleObserver]);

    useEffect(() => {
        if (!selectable) {
            clearSelection()
        }
    }, [selectable])

    useEffect(() => {
        setCurrentPage(1)
    }, [search])

    useEffect(() => {
        setSearchParams({'filters': JSON.stringify(globalFilterValues)})
    }, [globalFilterValues, setSearchParams])

    useEffect(() => {
        const filters = searchParams.get('filters')

        if (filters) {
            setGlobalFilterValues(JSON.parse(filters))
        }
    }, [])

    useEffect(() => {
        clearInterval(intervalId)
        setIsLoaded(false)
        if (paginationMode !== 'infinite-scroll') {
            setItems([])
        }

        setOnError(false)

        const postNewReq = () => {
            let tempLastToken = Math.random().toString(36)
            setLastToken(tempLastToken)
            let data: RequestDataType = {
                keyword: search,
                sortWay: sortDirection === 'asc' ? 'ascending' : 'decreasing',
                sortName: sort ?? '',
                nbEntries: shown,
                currentPage,
                filters: getRequestFilters(globalFilterValues),
                token: tempLastToken
            }
            Api.post<TableResponse<EntityInterface>>(path, data)
                .then(({data}) => {
                    // get locale storage
                    if (data.hasOwnProperty('token') && typeof data.token === 'string' && data.token.length) {
                        let lastToken = JSON.parse(localStorage.getItem('table.' + path) ?? '{}')?.token ?? ''
                        if (data.token !== lastToken) {
                            return
                        }
                    }

                    if (paginationMode === 'infinite-scroll') {
                        if (data.currentPage === currentPage) {
                            let tempItems = {...items, ...data.items}
                            setItems(tempItems)
                        }
                    } else {
                        setItems(data.items)
                    }
                    setMaxResult(data.maxResults)
                    setShown(data.length)
                    setCurrentPage(data.currentPage)
                    const pages = createArrUtils(Math.ceil(data.maxResults / data.length))
                    setPages(pages)
                })
                .catch(() => {
                    setOnError(true)
                })
                .then(() => {
                    setIsLoaded(true)
                })
        }

        setIntervalId(setTimeout(postNewReq, WAIT_INTERVAL))

        return () => {
            intervalId && clearInterval(intervalId)
        }
    }, [search, sortDirection, sort, shown, currentPage, path, globalFilterValues, toggleReload])

    useEffect(() => {
        Api.get(path)
            .then(({data: options}: { data: OptionsType }) => {
                setOptions(options)
            })
            .catch(() => {
            })
            .then(() => {
            })
    }, [path, toggleReloadOption])

    useEffect(() => {
        setMailingListActionType(undefined)
    }, [showActionModals])

    useEffect(() => {
        if (disableMainActionButton) {
            setDisabledMainModalAction(true)
        } else {
            if (showMainActionModal) {
                setDisabledMainModalAction(true)
                return
            }

            if (selected.length > 0) {
                setDisabledMainModalAction(false)
                return
            }

            setDisabledMainModalAction(!(setting.groupedActions.filter(groupedAction => !groupedAction.shortcut && groupedAction.action === 'mailingList').length > 0 && hasSearch))
        }
    }, [setting, selected, showMainActionModal, hasSearch, disableMainActionButton])

    useEffect(() => {
        if (search.length > 0 && (!globalFilterValues.hasOwnProperty('mailingLists') || Object.keys(globalFilterValues.mailingLists).length === 0)) {
            setHasSearch(true)
            return
        }

        let tempHasSearch = false
        for (const [name, values] of Object.entries(globalFilterValues)) {
            if (name !== 'mailingLists' && Object.keys(values).length > 0) {
                tempHasSearch = true
            } else if (name === 'mailingLists' && Object.keys(values).length > 0) {
                tempHasSearch = false
                break
            }
        }

        setHasSearch(tempHasSearch)
    }, [search, globalFilterValues])

    useEffect(() => {
        setActionModalFormIsLoaded(false)
        setActionModalFormIsLoading(false)
    }, [mailingListActionType, mailingListActionType])

    useEffect(() => {
        if (selectedItems !== undefined && setSelectedItems !== undefined) {
            if (selected.length) {
                let tempSelectedItems: ListingItems = {}
                selectedItems.forEach(item => {
                    if (selected.includes(item.id ?? 0)) {
                        tempSelectedItems[item.id ?? 0] = item
                    }
                })
                Object.values(items).forEach(item => {
                    if (selected.includes(item.id ?? 0)) {
                        tempSelectedItems[item.id ?? 0] = item
                    }
                })
                setSelectedItems(Object.values(tempSelectedItems))
            } else {
                if (selectedItems.length) {
                    setSelectedItems([])
                }
            }
        }
    }, [selected, items])
    const handleShowActionModal = (groupedAction?: GroupedAction, actionFromMain: boolean = true) => {
        let tempShowActionModals = {...showActionModals}

        for (const [modal] of Object.entries(tempShowActionModals)) {
            tempShowActionModals[modal] = false
        }

        if (groupedAction?.property && tempShowActionModals.hasOwnProperty('property')) {
            tempShowActionModals['property'] = groupedAction?.property ?? false
        }

        if (groupedAction?.action && tempShowActionModals.hasOwnProperty(groupedAction.action)) {
            tempShowActionModals[groupedAction.action] = true
        }

        // todo when modal for property
        setFromMain(actionFromMain)
        setShowMainActionModal(false)
        setShowActionModals(tempShowActionModals)
    }

    const handleShowMainActionModal = () => {
        handleShowActionModal()
        setShowMainActionModal(true)
    }

    const handleActionModalOnSubmit = () => {
        setActionModalFormIsLoading(true)
    }

    const handleActionModalOnResponse = () => {
        setActionModalFormIsLoading(false)
    }

    const handleActionModalOnSuccess = () => {
        handleShowActionModal()
        setShowMainActionModal(true)
        setBanner({type: 'success', i18nTitle: 'app.save', i18nMessage: 'app.data_saved_message'})
        setToggleReload && setToggleReload(new Date().toISOString())
        setToggleReloadOption(!toggleReloadOption)
    }

    const setNewSearch = (criteria: DataType) => {
        if (criteria.hasOwnProperty('keyword')) {
            setSearch(criteria.keyword)
        }
        if (criteria.hasOwnProperty('filters')) {
            setGlobalFilterValues(criteria.filters)
        }
    }

    const handleToggleReload = () => {
        setToggleReload && setToggleReload(new Date().toISOString())
    }

    return (
        <>
            <_Filters
                entity={entity}
                id={id}
                filters={setting.filters}
                options={options}
                globalFilterValues={globalFilterValues}
                setGlobalFilterValues={setGlobalFilterValues}
                setNewSearch={setNewSearch}
            />
            {!hideHeader && <_Header
                setting={setting}
                view={view}
                setView={setView}
                condensed={condensed}
                setCondensed={setCondensed}
                isLoaded={isLoaded}
                maxResult={maxResult}
                pages={pages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                shown={shown}
                setShown={setShown}
                sort={sort}
                setSort={setSort}
                sortDirection={sortDirection}
                setSortDirection={setSortDirection}
                selected={selected}
                setSelected={setSelected}
                visibleColumns={visibleColumns}
                setVisibleColumns={setVisibleColumn}
                handleShowMainActionModal={handleShowMainActionModal}
                disabledMainModalAction={disabledMainModalAction}
                hasSearch={hasSearch}
                handleShowActionModal={handleShowActionModal}
                handleToggleReload={setToggleReload && handleToggleReload}
                items={items}
                availableActions={availableActions}
            />}
            <_Content
                isLoaded={isLoaded}
                setting={setting}
                items={items}
                setItems={setItems}
                editAction={editAction}
                editUri={editUri}
                deleteAction={deleteAction}
                view={view}
                onError={onError}
                options={options}
                entity={entity}
                selectable={selectable}
                selected={selected}
                selectMultiple={selectMultiple}
                setSelected={setSelected}
                visibleColumns={visibleColumns}
                condensed={condensed}
                customActions={customActions}
                hideDownloadButton={hideDownloadButton}
                paginationMode={paginationMode ?? 'page'}
                loader={loader}
            />
            {paginationMode === "page" && view !== 'kanban' &&
                <Pagination colSpan={0} currentPage={currentPage} pages={pages} setCurrentPage={setCurrentPage}/>}
            <_MainModal
                handleShowActionModal={handleShowActionModal}
                hasSearch={hasSearch}
                selected={selected}
                clearSelection={clearSelection}
                setting={setting}
                availableActions={availableActions}
                setShowMainActionModal={setShowMainActionModal}
                showMainActionModal={showMainActionModal}
                setShoppingActionType={setShoppingActionType}
            />
            {entity === 'contact' && <_MailingListModal
                actionModalFormIsLoaded={actionModalFormIsLoaded}
                actionModalFormIsLoading={actionModalFormIsLoading}
                fromMain={fromMain}
                globalFilterValues={globalFilterValues}
                handleActionModalOnResponse={handleActionModalOnResponse}
                handleActionModalOnSubmit={handleActionModalOnSubmit}
                handleActionModalOnSuccess={handleActionModalOnSuccess}
                handleShowActionModal={handleShowActionModal}
                handleShowMainActionModal={handleShowMainActionModal}
                hasSearch={hasSearch}
                mailingListActionType={mailingListActionType}
                search={search}
                selected={selected}
                setActionModalFormIsLoaded={setActionModalFormIsLoaded}
                setMailingListActionType={setMailingListActionType}
                showActionModals={showActionModals}
            />}
            <_RemoveModal
                collection={entity}
                actionModalFormIsLoading={actionModalFormIsLoading}
                fromMain={fromMain}
                handleShowActionModal={handleShowActionModal}
                handleShowMainActionModal={handleShowMainActionModal}
                mailingListActionType={mailingListActionType}
                selected={selected}
                clearSelection={clearSelection}
                setMailingListActionType={setMailingListActionType}
                showActionModals={showActionModals}
                handleActionModalOnResponse={handleActionModalOnResponse}
                handleActionModalOnSubmit={handleActionModalOnSubmit}
                handleActionModalOnSuccess={handleActionModalOnSuccess}
            />
            <_DownloadModal
                collection={entity}
                actionModalFormIsLoading={actionModalFormIsLoading}
                fromMain={fromMain}
                handleShowActionModal={handleShowActionModal}
                handleShowMainActionModal={handleShowMainActionModal}
                selected={selected}
                showActionModals={showActionModals}
                handleActionModalOnResponse={handleActionModalOnResponse}
                handleActionModalOnSubmit={handleActionModalOnSubmit}
                handleActionModalOnSuccess={handleActionModalOnSuccess}
            />
            {hasPlatform &&
                <_PublishModal
                    collection={entity}
                    actionModalFormIsLoading={actionModalFormIsLoading}
                    fromMain={fromMain}
                    handleShowActionModal={handleShowActionModal}
                    handleShowMainActionModal={handleShowMainActionModal}
                    selected={selected}
                    showActionModals={showActionModals}
                    handleActionModalOnResponse={handleActionModalOnResponse}
                    handleActionModalOnSubmit={handleActionModalOnSubmit}
                    handleActionModalOnSuccess={handleActionModalOnSuccess}
                />}
            <_ShoppingModal
                collection={entity}
                actionModalFormIsLoading={actionModalFormIsLoading}
                fromMain={fromMain}
                actionModalFormIsLoaded={actionModalFormIsLoaded}
                setActionModalFormIsLoaded={setActionModalFormIsLoaded}
                handleShowActionModal={handleShowActionModal}
                handleShowMainActionModal={handleShowMainActionModal}
                selected={selected}
                showActionModals={showActionModals}
                handleActionModalOnResponse={handleActionModalOnResponse}
                handleActionModalOnSubmit={handleActionModalOnSubmit}
                handleActionModalOnSuccess={handleActionModalOnSuccess}
                shoppingActionType={shoppingActionType}
                setShoppingActionType={setShoppingActionType}
            />
            {(entity && ['bucket', 'catalog'].includes(entity)) && <_BimboShareModal
                actionModalFormIsLoaded={actionModalFormIsLoaded}
                setActionModalFormIsLoaded={setActionModalFormIsLoaded}
                collection={entity}
                actionModalFormIsLoading={actionModalFormIsLoading}
                fromMain={fromMain}
                handleShowActionModal={handleShowActionModal}
                handleShowMainActionModal={handleShowMainActionModal}
                selected={selected}
                showActionModals={showActionModals}
                handleActionModalOnResponse={handleActionModalOnResponse}
                handleActionModalOnSubmit={handleActionModalOnSubmit}
                handleActionModalOnSuccess={handleActionModalOnSuccess}
            />}
            {'bucket' === entity && <_TransformModal
                id={id ?? 0}
                actionModalFormIsLoading={actionModalFormIsLoading}
                fromMain={fromMain}
                handleShowActionModal={handleShowActionModal}
                handleShowMainActionModal={handleShowMainActionModal}
                selected={selected}
                showActionModals={showActionModals}
                handleActionModalOnResponse={handleActionModalOnResponse}
                handleActionModalOnSubmit={handleActionModalOnSubmit}
                handleActionModalOnSuccess={handleActionModalOnSuccess}
                actionModalFormIsLoaded={actionModalFormIsLoaded}
                setActionModalFormIsLoaded={setActionModalFormIsLoaded}
            />}
            <_ExportModal
                collection={entity}
                id={id}
                actionModalFormIsLoading={actionModalFormIsLoading}
                fromMain={fromMain}
                handleShowActionModal={handleShowActionModal}
                handleShowMainActionModal={handleShowMainActionModal}
                selected={selected}
                showActionModals={showActionModals}
                handleActionModalOnResponse={handleActionModalOnResponse}
                handleActionModalOnSubmit={handleActionModalOnSubmit}
                handleActionModalOnSuccess={handleActionModalOnSuccess}
                actionModalFormIsLoaded={actionModalFormIsLoaded}
                setActionModalFormIsLoaded={setActionModalFormIsLoaded}
                exports={setting.exports ?? []}
            />
            <_GroupModal
                collection={entity}
                id={id}
                actionModalFormIsLoading={actionModalFormIsLoading}
                fromMain={fromMain}
                handleShowActionModal={handleShowActionModal}
                handleShowMainActionModal={handleShowMainActionModal}
                selected={selected}
                showActionModals={showActionModals}
                handleActionModalOnResponse={handleActionModalOnResponse}
                handleActionModalOnSubmit={handleActionModalOnSubmit}
                handleActionModalOnSuccess={handleActionModalOnSuccess}
                actionModalFormIsLoaded={actionModalFormIsLoaded}
                setActionModalFormIsLoaded={setActionModalFormIsLoaded}
            />
            <_PropertyModal
                collection={entity}
                id={id}
                actionModalFormIsLoading={actionModalFormIsLoading}
                fromMain={fromMain}
                handleShowActionModal={handleShowActionModal}
                handleShowMainActionModal={handleShowMainActionModal}
                selected={selected}
                showActionModals={showActionModals}
                handleActionModalOnResponse={handleActionModalOnResponse}
                handleActionModalOnSubmit={handleActionModalOnSubmit}
                handleActionModalOnSuccess={handleActionModalOnSuccess}
                actionModalFormIsLoaded={actionModalFormIsLoaded}
                setActionModalFormIsLoaded={setActionModalFormIsLoaded}
            />
             <_RenameModal
                collection={entity}
                id={id}
                path={path}
                actionModalFormIsLoading={actionModalFormIsLoading}
                fromMain={fromMain}
                handleShowActionModal={handleShowActionModal}
                handleShowMainActionModal={handleShowMainActionModal}
                selected={selected}
                showActionModals={showActionModals}
                handleActionModalOnResponse={handleActionModalOnResponse}
                handleActionModalOnSubmit={handleActionModalOnSubmit}
                handleActionModalOnSuccess={handleActionModalOnSuccess}
                actionModalFormIsLoaded={actionModalFormIsLoaded}
                setActionModalFormIsLoaded={setActionModalFormIsLoaded}
            />
            <_TagModal
                collection={entity}
                id={id}
                actionModalFormIsLoading={actionModalFormIsLoading}
                fromMain={fromMain}
                handleShowActionModal={handleShowActionModal}
                handleShowMainActionModal={handleShowMainActionModal}
                selected={selected}
                showActionModals={showActionModals}
                handleActionModalOnResponse={handleActionModalOnResponse}
                handleActionModalOnSubmit={handleActionModalOnSubmit}
                handleActionModalOnSuccess={handleActionModalOnSuccess}
                actionModalFormIsLoaded={actionModalFormIsLoaded}
                setActionModalFormIsLoaded={setActionModalFormIsLoaded}
            />
            <_ReportingModal
                availableActions={availableActions}
                id={id}
                collection={entity}
                actionModalFormIsLoading={actionModalFormIsLoading}
                fromMain={fromMain}
                handleShowActionModal={handleShowActionModal}
                handleShowMainActionModal={handleShowMainActionModal}
                selected={selected}
                showActionModals={showActionModals}
                handleActionModalOnResponse={handleActionModalOnResponse}
                handleActionModalOnSubmit={handleActionModalOnSubmit}
                handleActionModalOnSuccess={handleActionModalOnSuccess}
                actionModalFormIsLoaded={actionModalFormIsLoaded}
                setActionModalFormIsLoaded={setActionModalFormIsLoaded}
            />
            <_RestoreModal
                collection={entity}
                actionModalFormIsLoading={actionModalFormIsLoading}
                fromMain={fromMain}
                handleShowActionModal={handleShowActionModal}
                handleShowMainActionModal={handleShowMainActionModal}
                mailingListActionType={mailingListActionType}
                selected={selected}
                clearSelection={clearSelection}
                setMailingListActionType={setMailingListActionType}
                showActionModals={showActionModals}
                handleActionModalOnResponse={handleActionModalOnResponse}
                handleActionModalOnSubmit={handleActionModalOnSubmit}
                handleActionModalOnSuccess={handleActionModalOnSuccess}
            />
            {entity === 'catalog' && <>
                <_GenerateStickersModal
                    id={id ?? 0}
                    actionModalFormIsLoading={actionModalFormIsLoading}
                    fromMain={fromMain}
                    handleShowActionModal={handleShowActionModal}
                    handleShowMainActionModal={handleShowMainActionModal}
                    selected={selected}
                    showActionModals={showActionModals}
                    handleActionModalOnResponse={handleActionModalOnResponse}
                    handleActionModalOnSubmit={handleActionModalOnSubmit}
                    handleActionModalOnSuccess={handleActionModalOnSuccess}
                    actionModalFormIsLoaded={actionModalFormIsLoaded}
                    setActionModalFormIsLoaded={setActionModalFormIsLoaded}
                />
                <_ArchiveStickersModal
                    id={id ?? 0}
                    actionModalFormIsLoading={actionModalFormIsLoading}
                    fromMain={fromMain}
                    handleShowActionModal={handleShowActionModal}
                    handleShowMainActionModal={handleShowMainActionModal}
                    selected={selected}
                    showActionModals={showActionModals}
                    handleActionModalOnResponse={handleActionModalOnResponse}
                    handleActionModalOnSubmit={handleActionModalOnSubmit}
                    handleActionModalOnSuccess={handleActionModalOnSuccess}
                    actionModalFormIsLoaded={actionModalFormIsLoaded}
                    setActionModalFormIsLoaded={setActionModalFormIsLoaded}
                />
                <_PrintStickersModal
                    id={id ?? 0}
                    actionModalFormIsLoading={actionModalFormIsLoading}
                    fromMain={fromMain}
                    handleShowActionModal={handleShowActionModal}
                    handleShowMainActionModal={handleShowMainActionModal}
                    selected={selected}
                    showActionModals={showActionModals}
                    handleActionModalOnResponse={handleActionModalOnResponse}
                    handleActionModalOnSubmit={handleActionModalOnSubmit}
                    handleActionModalOnSuccess={handleActionModalOnSuccess}
                    actionModalFormIsLoaded={actionModalFormIsLoaded}
                    setActionModalFormIsLoaded={setActionModalFormIsLoaded}
                />
            </>}
        </>
    )
}

export default Listing
