import React, {FC, useEffect, useState} from "react";
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import {Navbar as GlobalNavbar, NavbarItem} from "Components/Layout/Navbar";
import Header from "Components/Layout/Header";
import GlobalLoader from "Vendor/Components/GlobalLoader";
import Api from "Vendor/Api";
import Platform from "Entity/Platform";

const Navbar: FC = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    const [platform, setPlatform] = useState<Platform|undefined>(undefined)
    const [show, setShow] = useState<boolean>(false)
    const [search, setSearch] = useState<string>('')
    const [handleAdd, setHandleAdd] = useState<(() => void)|undefined>(undefined)

    useEffect(() => {
        if (location && location.state && typeof location.state === 'object') {
            setPlatform(location.state as Platform);
        }
        else {
            let id = params.id;
            Api.get('/admin/edition/platform/' + id)
                .then((response ) => {
                    setPlatform(response.data as Platform)
                })
        }
    }, [location, params])

    const items: NavbarItem[] = [
        {
            i18nLabel: 'app.main',
            path: ''
        },
        {
            i18nLabel: 'app.listings.label',
            path: 'listings'
        },
        {
            i18nLabel: 'app.platform_download_config.label',
            path: 'download-configs'
        },
        {
            i18nLabel: 'app.variables',
            path: 'variables'
        },
    ]

    return (
        <>
            {!platform
                ? <GlobalLoader />
                : <>
                    <Header
                        title={platform.name}
                        prev={() => {
                            navigate('/admin/edition/platform')
                        }}
                        externalUrl={platform.url}
                        add={() => {
                            handleAdd !== undefined && handleAdd()
                            setShow(true)
                        }}
                        search={search}
                        setSearch={setSearch}
                    />
                    <GlobalNavbar locationBase={"/admin/edition/platform/" + (platform?.id ?? '')} state={platform} items={items} />
                    <Outlet context={[platform, setPlatform, show, setShow, search, setSearch, setHandleAdd]} />
                </>
            }
        </>
    )
}

export default Navbar
