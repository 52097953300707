import {RouteObject} from "react-router-dom";
import MainPage from "../../Pages/Admin/Edition/Brand/MainPage";

const BrandRouting: RouteObject[] = [
    {
        index: true,
        element: <MainPage/>,
    },
]

export default BrandRouting