import {Choice} from "Vendor/Components/Form/Type/ChoiceType";

const AddTextModeChoice: Choice[] = [
    {
        i18nKey: 'app.groupedActionChoice.rename.prependText',
        value: 'prependText',
    },
    {
        i18nKey: 'app.groupedActionChoice.rename.appendText',
        value: 'appendText',
    },
]

export default AddTextModeChoice
