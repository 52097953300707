import React, {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    Button, Modal,
    ModalBody as Body,
    ModalFooter as Footer,
    ModalHeader as Header,
    ModalTitle as Title
} from "react-bootstrap";
import {classUtils as c} from "Vendor/Utils/ClassUtils";
import GroupedAction from "Entity/Collection/ListingSetting/GroupedAction";
import FormEmpty from "Vendor/Components/FormEmpty";
import LoadingButton from "../../Button/LoadingButton";
import {getRequestFilters, GlobalFilterValues, MailingActionModal} from "../Listing";
import Property from "../../../Entity/Property";
import MailingList from "../../../Entity/MailingList";
import Api from "../../../Vendor/Api";
import Spinner from "../../Spinner";
import useTranslationDataUtils from "../../../Vendor/Utils/TranslationDataUtils";
import FormEmptyModal from "../../Modal/FormEmptyModal";
import DeleteModal from "../../Modal/DeleteModal";

export interface _MailingListModalProps {
    showActionModals: {[key: string]: boolean|Property}
    handleShowActionModal: (groupedAction?: GroupedAction, actionFromMain?:boolean) => void
    fromMain: boolean
    mailingListActionType: MailingActionModal
    setMailingListActionType: (mailingListActionType: MailingActionModal) => void
    handleShowMainActionModal: () => void
    selected: number[]
    hasSearch: boolean
    search: string
    globalFilterValues: GlobalFilterValues
    actionModalFormIsLoading: boolean,
    actionModalFormIsLoaded: boolean,
    setActionModalFormIsLoaded: (isLoaded: boolean) => void,
    handleActionModalOnResponse: () => void
    handleActionModalOnSuccess: () => void
    handleActionModalOnSubmit: () => void
}

const _MailingListModal: FC<_MailingListModalProps> = ({
                                                           showActionModals,
                                                           handleShowActionModal,
                                                           fromMain,
                                                           mailingListActionType,
                                                           setMailingListActionType,
                                                           handleShowMainActionModal,
                                                           selected,
                                                           hasSearch,
                                                           search,
                                                           globalFilterValues,
                                                           actionModalFormIsLoading,
                                                           setActionModalFormIsLoaded,
                                                           handleActionModalOnResponse,
                                                           handleActionModalOnSuccess,
                                                           handleActionModalOnSubmit,
                                                           actionModalFormIsLoaded,
}) => {
    const {t} = useTranslation()
    const [mailingLists, setMailingLists] = useState<MailingList[]>([])
    const [isLoaded, setIsLoaded] = useState<null|boolean>(null)
    const [onLoad, setOnLoad] = useState<boolean>(false)
    const [mailingList, setMailingList] = useState<MailingList|undefined>(undefined)
    const [action, setAction] = useState<'edit'|'delete'|undefined>(undefined)
    const {translate} = useTranslationDataUtils()

    useEffect(() => {
        if ('edit' === mailingListActionType) {
            if (null === isLoaded && !onLoad) {
                setOnLoad(true)
                Api.post('/crm/mailing-list',  {
                    keyword: '',
                    sortWay: 'ascending',
                    sortName: 'createdAt',
                    nbEntries: 100,
                    currentPage: 1,
                    filters: {}
                }).then(response => {
                    setMailingLists(response.data.items as MailingList[])
                    setIsLoaded(true)
                }).catch(() => {
                    setIsLoaded(false)
                }).then(() => {
                    setOnLoad(false)
                })
            }
        } else {
            setMailingLists([])
            setIsLoaded(null)
        }
    }, [isLoaded, mailingListActionType, onLoad])

    const handleEditMailingList = (mailingList: MailingList) => {
        setMailingList(mailingList)
        setAction('edit')
    }

    const handleDeleteMailingList = (mailingList: MailingList) => {
        setMailingList(mailingList)
        setAction('delete')
    }

    if (mailingListActionType === 'edit') {
        return <>
            <Modal show={showActionModals.mailingList !== false && !action && !mailingList} onHide={handleShowActionModal}>
                <Header closeButton={!fromMain}>
                    <div className="modal-block-header">
                        {fromMain && <i className="fa-solid fa-angle-left" onClick={() => {
                            if (mailingListActionType === undefined) {
                                handleShowMainActionModal()
                            } else {
                                setMailingListActionType(undefined)
                            }
                        }}></i>}
                        <Title>
                            {t(mailingListActionType === undefined ? 'app.mailingList' : `app.groupedActionChoice.mailingList.${mailingListActionType}`, {count: selected.length})}
                        </Title>
                    </div>
                </Header>
                <Body>
                    {onLoad
                        ? <Spinner/>
                        : (!isLoaded ?
                            <i className="badge bg-danger">{t('app.error')}</i>
                                : (mailingLists.length === 0
                                        ? <div className="d-flex justify-content-center"><i>{t('app.no_result')}</i></div>
                                        :                                 <div className="container-fluid bg-light p-1 rounded-4">
                                            <table className="table table-hover table-large mb-0">
                                                <thead>
                                                <tr>
                                                    <th>{t('app.name')}</th>
                                                    <th>{t('app.type')}</th>
                                                    <th>{t('app.actions')}</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {mailingLists.map((mailingList, key) => <tr key={key}>
                                                    <td>{translate(mailingList, 'name')}</td>
                                                    <td>{t('app.' + mailingList.type)}</td>
                                                    <td>
                                                        <Button
                                                            variant='primary'
                                                            onClick={(e) => {
                                                                handleEditMailingList(mailingList)
                                                            }}>
                                                            <i className="fa-solid fa-pen-to-square me-2"></i>
                                                            {t('app.edit')}
                                                        </Button>
                                                        <Button
                                                            variant='danger'
                                                            onClick={(e) => {
                                                                handleDeleteMailingList(mailingList)
                                                            }}>
                                                            <i className="fa-solid fa-trash me-2"></i>
                                                            {t('app.archive')}
                                                        </Button>
                                                    </td>
                                                </tr>)}
                                                </tbody>
                                            </table>
                                        </div>
                                )
                        )
                    }
                </Body>
                {!fromMain && <Footer>
                    <Button variant="secondary" onClick={() => {
                        handleShowActionModal()
                    }}>
                        {t('app.cancel')}
                    </Button>
                </Footer>}
            </Modal>
            <FormEmptyModal
                path={`/crm/mailing-list/${mailingList?.id ?? '0'}`}
                formPath={true}
                method="PATCH"
                i18nTitle={'app.editMailingList'}
                show={!!(mailingList?.id && action === 'edit')}
                handleClose={() => {
                    setAction(undefined)
                    setMailingList(undefined)
                }}
                onSuccess={() => {
                    setAction(undefined)
                    setMailingList(undefined)
                    setIsLoaded(null)
                }}
            />
            <DeleteModal
                handleClose={() => {
                    setAction(undefined)
                    setMailingList(undefined)
                }}
                onSuccess={() => {
                    setAction(undefined)
                    setMailingList(undefined)
                    setIsLoaded(null)
                }}
                show={!!(mailingList?.id && action === 'delete')}
                path={`/crm/mailing-list/${mailingList?.id ?? '0'}`}
                name={mailingList ? translate(mailingList, 'name') : 'no mailing list'}
            />
        </>
    }

    return <Modal show={showActionModals.mailingList !== false} onHide={handleShowActionModal}>
        <Header closeButton={!fromMain}>
            <div className="modal-block-header">
                {fromMain && <i className="fa-solid fa-angle-left" onClick={() => {
                    if (mailingListActionType === undefined) {
                        handleShowMainActionModal()
                    } else {
                        setMailingListActionType(undefined)
                    }
                }}></i>}
                <Title>
                    {t(mailingListActionType === undefined ? 'app.mailingList' : `app.groupedActionChoice.mailingList.${mailingListActionType}`, {count: selected.length})}
                </Title>
            </div>
        </Header>
        <Body>
            {mailingListActionType === undefined ?
                <ul className="grouped-action-choices">
                    <li onClick={() => {
                        setMailingListActionType('edit')
                    }} className="btn btn-secondary">
                                <span>
                                    <i className="fa-solid fa-pen-to-square icon"></i>
                                    {t('app.groupedActionChoice.mailingList.edit', {count: selected.length})}
                                </span>
                        <i className="action-icon"></i>
                    </li>
                    <li onClick={() => {
                        setMailingListActionType('newStatic')
                    }} className={c("btn btn-secondary", selected.length === 0 && 'disabled')}>
                                <span>
                                    <i className="fa-solid fa-plus icon"></i>
                                    {t('app.groupedActionChoice.mailingList.newStatic', {count: selected.length})}
                                </span>
                        <i className="action-icon"></i>
                    </li>
                    <li onClick={() => {
                        setMailingListActionType('addStatic')
                    }} className={c("btn btn-secondary", selected.length === 0 && 'disabled')}>
                                <span>
                                    <i className="fa-solid fa-list-check icon"></i>
                                    {t('app.groupedActionChoice.mailingList.addStatic', {count: selected.length})}
                                </span>
                        <i className="action-icon"></i>
                    </li>
                    <li onClick={() => {
                        setMailingListActionType('newDynamic')
                    }} className={c("btn btn-secondary", !hasSearch && 'disabled')}>
                                <span>
                                    <i className="icon fa-solid fa-rss"></i>
                                    {t('app.groupedActionChoice.mailingList.newDynamic', {count: selected.length})}
                                </span>
                        <i className="action-icon"></i>
                    </li>
                    <li onClick={() => {
                        setMailingListActionType('replaceDynamic')
                    }} className={c("btn btn-secondary", !hasSearch && 'disabled')}>
                                <span>
                                    <i className="fa-solid fa-edit icon"></i>
                                    {t('app.groupedActionChoice.mailingList.replaceDynamic', {count: selected.length})}
                                </span>
                        <i className="action-icon"></i>
                    </li>
                </ul>
                :
                <FormEmpty
                    path={`/crm/mailing-list/${mailingListActionType.replaceAll(/[A-Z]/g, m => "-" + m.toLowerCase())}`}
                    method={mailingListActionType.startsWith('new') ? 'POST' : 'PATCH'}
                    additionalData={
                        mailingListActionType.endsWith('Static')
                            ? {contacts: [...selected]}
                            : {criteria: JSON.stringify({
                                    keyword: search,
                                    filters: getRequestFilters(globalFilterValues, true)
                                })}
                    }
                    setIsLoaded={setActionModalFormIsLoaded}
                    onResponse={handleActionModalOnResponse}
                    onSubmit={handleActionModalOnSubmit}
                    onSuccess={handleActionModalOnSuccess}
                >
                    <div className="row justify-content-end align-items-center mb-3">
                        <div className="col-auto">
                            <LoadingButton type="submit" isLoading={actionModalFormIsLoading} disabled={!actionModalFormIsLoaded}>
                                {t(
                                    mailingListActionType.startsWith('new')
                                            ? 'app.create'
                                            :  'app.add'
                                )}
                            </LoadingButton>
                        </div>
                    </div>
                </FormEmpty>
            }
        </Body>
        {!fromMain && <Footer>
            <Button variant="secondary" onClick={() => {
                handleShowActionModal()
            }}>
                {t('app.cancel')}
            </Button>
        </Footer>}
    </Modal>
}

export default _MailingListModal