import {FC, useState} from 'react'
import {useOutletContext} from "react-router-dom";
import Listing, {CustomAction} from "Components/Listing/Listing";
import {useTranslation} from "react-i18next";
import ListingSetting from "../Entity/Collection/ListingSetting";
import FormEmptyOffCanvas from "../Components/Modal/FormEmptyOffCanvas";
import DeleteModal from "../Components/Modal/DeleteModal";
import Api from "../Vendor/Api";
import EntityInterface from "../Vendor/Definition/EntityInterface";
import User from "../Entity/Platform/User";
import Platform from "../Entity/Platform";

export interface UserProps {
}

const PlatformUserPage: FC<UserProps> = () => {
    const {t} = useTranslation()
    const [toggleReload, setToggleReload] = useState<string>('')
    const [user, setUser] = useState<User>({} as User)

    const [platform, show, setShow, search, setSearch, setHandleAdd] = useOutletContext<[Platform,boolean, (show:boolean) => void, string, (search:string) => void, (handleAdd: (() => void)|undefined) => void]>()

    const [setting] = useState<ListingSetting>({
        cardCondensedByDefault: false,
        cardShowBadge: false,
        cardShowStatus: false,
        cardShowSchedule: true,
        cardShowSizeAndWeight: null,
        filters: [],
        groupedActions: [],
        kanbanDatas: [],
        cardDatas: [],
        searchable: [],
        searchableByEmail: false,
        searchableByFirstName: false,
        searchableByLastName: false,
        searchableByName: false,
        sorters: [
        ],
        tableColumns: [
            {
                columnType: "identifier",
                visibleByDefault: true,
            },
            {
                column: 'email',
                visibleByDefault: true,
                render: (user: User) => (
                    <>
                        {UserEmail({ user: user })}
                        {!user.enabled && (
                            <span className="badge bg-info ms-1">
                                {t('app.waiting_validation')}
                            </span>
                        )}
                    </>
                ),
            },
            {
                column: 'name',
                visibleByDefault: true,
            },
            {
                column: 'currency',
                visibleByDefault: true,
            }
        ],
        views: ['table']
    })


    const UserEmail = ({ user }: { user: User }) => {
        return user.email
    }

    const handleClose = (reload = false) => {
        setShow(false)
        if (reload) {
            setToggleReload(new Date().toISOString())
        }
    }

    const handleShow = () => setShow(true)
    // remove
    const [showDelete, setShowDelete] = useState<boolean>(false)
    const handleDeleteClose = (reload = false) => {
        setUser({} as User)
        setShowDelete(false)
        if (reload) {
            setToggleReload(new Date().toISOString())
        }
    }

    const handleDeleteShow = () => setShowDelete(true)

    const onSuccess = (user: User) => {
        setUser(user)
        setShow(false)
        setToggleReload(new Date().toISOString())
    }

    const onExited = () => {
        setUser({} as User)
    }

    const editUser = (user: EntityInterface) => {
        setUser(user as User)
        handleShow()
    }

    const deleteUser = (user: EntityInterface) => {
        setUser(user as User)
        handleDeleteShow()
    }

    const handleEnableUser = (user: EntityInterface) => {
        if (!user.enabled) {
            Api.patch(`/platform-setting/${platform?.id ?? 1}/user/${user.id}/enable`)
                .then(() => {})
                .catch(() => {})
                .then(() => setToggleReload(new Date().toISOString()))
        }
    }

    const handleDisableUser = (user: EntityInterface) => {
        Api.patch(`/platform-setting/${platform?.id ?? 1}/user/${user.id}/disable`)
            .then(() => {})
            .catch(() => {})
            .then(() => setToggleReload(new Date().toISOString()))
    }

    const customActions: CustomAction[] = [
        {
            method: handleEnableUser,
            granted: 'enable',
            i18nLabel: 'app.enable_user',
            variant: 'success',
        },
        {
            method: handleDisableUser,
            granted: 'disable',
            i18nLabel: 'app.disable_user',
            variant: 'danger',
        },
    ]

    return <>
        <Listing
            path={`/platform-setting/${platform?.id ?? 1}/user`}
            search={search}
            setSearch={setSearch}
            setting={setting}
            selectable={true}
            toggleReload={toggleReload}
            setToggleReload={setToggleReload}
            editAction={editUser}
            deleteAction={deleteUser}
            customActions={customActions}
        />
        <FormEmptyOffCanvas
            i18nTitle={user.id ? 'app.edit_platform_user' : 'app.add_platform_user'}
            show={show}
            handleClose={handleClose}
            onSuccess={onSuccess}
            path={`/platform-setting/${platform?.id ?? 1}/user/${user?.id ?? 'new'}`}
            formPath={!!user.id}
            method={user.id ? 'patch' : 'post'}
            onExited={onExited}
        />
        <DeleteModal
            handleClose={handleDeleteClose}
            show={showDelete}
            path={`/platform-setting/${platform?.id ?? 1}/user/` + `${user?.id ?? 'error'}`}
            name={'#' + (user ? `${user.name}` : 'error')}
            archive={true}
        />
    </>
}

export default PlatformUserPage
