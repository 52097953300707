import React, {FC, useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    Button, Modal,
    ModalBody as Body,
    ModalFooter as Footer,
    ModalHeader as Header,
    ModalTitle as Title
} from "react-bootstrap";
import GroupedAction from "Entity/Collection/ListingSetting/GroupedAction";
import LoadingButton from "../../Button/LoadingButton";
import Collection from "Config/Collection";
import Property from "../../../Entity/Property";
import {useNavigate} from "react-router-dom";
import FormEmpty from "../../../Vendor/Components/FormEmpty";
import {classUtils as c} from "../../../Vendor/Utils/ClassUtils";
import {UserContext} from "../../../Vendor/Context/UserContextProvider";

export interface _ShoppingModalProps {
    collection?: Collection
    showActionModals: {[key: string]: boolean|Property}
    handleShowActionModal: (groupedAction?: GroupedAction, actionFromMain?:boolean) => void
    fromMain: boolean
    handleShowMainActionModal: () => void
    selected: number[]
    actionModalFormIsLoading: boolean
    handleActionModalOnResponse: () => void
    handleActionModalOnSuccess: () => void
    handleActionModalOnSubmit: () => void
    actionModalFormIsLoaded: boolean,
    setActionModalFormIsLoaded: (isLoaded: boolean) => void,
    shoppingActionType: ShoppingActionModal,
    setShoppingActionType: (actionType: ShoppingActionModal) => void,
}

export type ShoppingActionModal = undefined | 'shopping' | 'preOrder'

const _ShoppingModal: FC<_ShoppingModalProps> = ({
                                                         collection,
                                                         handleShowActionModal,
                                                         fromMain,
                                                         handleShowMainActionModal,
                                                         selected,
                                                         actionModalFormIsLoading,
                                                         setActionModalFormIsLoaded,
                                                         handleActionModalOnResponse,
                                                         handleActionModalOnSuccess,
                                                         handleActionModalOnSubmit,
                                                         actionModalFormIsLoaded,
                                                         showActionModals,
                                                         shoppingActionType,
                                                         setShoppingActionType,
                                                     }) => {
    const {t} = useTranslation()
    const { currentUser } = useContext(UserContext)
    const navigate = useNavigate()
    const [additionalData, setAdditionalData] = useState<undefined|object>(undefined)

    const onSubmit = () => {
        handleActionModalOnSubmit()
    }

    useEffect(() => {
        if (collection === 'catalog') {
            setAdditionalData({
                products: selected,
            })
        } else {
            setAdditionalData(undefined)
        }
    }, [collection, selected])

    const onSuccess = (data: any) => {
        if (shoppingActionType) {
           let page:string = shoppingActionType === 'preOrder' ? 'pre-order' : 'shopping'
            navigate(`/${page}`, {
                state: {
                    shopping: data.id,
                }
            })
        }
    }

    const onHide = () => {
        handleShowActionModal()
        setShoppingActionType(undefined)
    }

    return <Modal show={showActionModals.shopping !== false} onHide={onHide} enforceFocus={false}>
        <Header closeButton={!fromMain}>
            <div className="modal-block-header">
                {fromMain && <i className="fa-solid fa-angle-left" onClick={() => {
                    if (shoppingActionType === undefined) {
                        handleShowMainActionModal()
                    } else {
                        setShoppingActionType(undefined)
                    }
                }}></i>}
                <Title>
                    {t(shoppingActionType === undefined ? 'app.shopping' : `app.groupedActionChoice.shopping.${shoppingActionType}`, {count: selected.length})}
                </Title>
            </div>
        </Header>
        <Body>
            {shoppingActionType === undefined ?
                <ul className="grouped-action-choices">
                    {currentUser?.currentOrganization?.options.includes('shopping') && currentUser.currentOrganization?.setting.shoppingTypes.includes('shopping') && <li onClick={() => {
                        setShoppingActionType('shopping')
                    }} className={c("btn btn-secondary", selected.length === 0 && 'disabled')}>
                                <span>
                                    <i className="fa-solid fa-plus icon"></i>
                                    {t('app.groupedActionChoice.shopping.new_shopping', {count: selected.length})}
                                </span>
                        <i className="action-icon"></i>
                    </li>}
                    {currentUser?.currentOrganization?.options.includes('shopping') && currentUser.currentOrganization?.setting.shoppingTypes.includes('pre-order') && <li onClick={() => {
                        setShoppingActionType('preOrder')
                    }} className={c("btn btn-secondary", selected.length === 0 && 'disabled')}>
                                <span>
                                    <i className="fa-solid fa-list-check icon"></i>
                                    {t('app.groupedActionChoice.shopping.new_preOrder', {count: selected.length})}
                                </span>
                        <i className="action-icon"></i>
                    </li>}
                </ul>
                :
                <FormEmpty
                    path={shoppingActionType === 'shopping' ? '/shopping/shopping/new?groupedAction=1' : `/shopping/pre-order/new?groupedAction=1`}
                    method='post'
                    additionalData={additionalData}
                    setIsLoaded={setActionModalFormIsLoaded}
                    onResponse={handleActionModalOnResponse}
                    onSubmit={onSubmit}
                    onSuccess={onSuccess}
                >
                    <div className="row justify-content-end align-items-center mb-3">
                        <div className="col-auto">
                            <LoadingButton type="submit" isLoading={actionModalFormIsLoading} disabled={!actionModalFormIsLoaded}>
                                {t('app.add')}
                            </LoadingButton>
                        </div>
                    </div>
                </FormEmpty>
            }
        </Body>
        {!fromMain && <Footer>
            <Button variant="secondary" onClick={onHide}>
                {t('app.cancel')}
            </Button>
        </Footer>}
    </Modal>
}

export default _ShoppingModal
