import {useCallback} from "react";
import {DataType} from "../Components/Form";

export default function useFormDataParser () {
    const parseKey = (key: string, formName?: string): string[] => {
        key = key.substring(0, key.length - 1)
        if (formName && key.startsWith(`${formName}[`)) {
            key = key.substring(formName.length + 1)
        }

        return key.split('][').filter(key => key.length)
    }


    const formDataParser = useCallback((formData: FormData, formName?: string) => {
        let data: DataType = {}

        for (const [key, value] of formData.entries()) {
            let isArray = key.substring(key.length - 2) === '[]'
            let keys = parseKey(key, formName)

            let currentData: DataType = data
            let tempData: DataType = currentData
            keys.forEach((parsedKey, index) => {
                if ((index + 1) === keys.length) {
                    if (isArray) {
                        if (tempData.hasOwnProperty(parsedKey)) {
                            tempData[parsedKey] = [...tempData[parsedKey], value]
                        } else {
                            tempData[parsedKey] = [value]
                        }
                    } else {
                        tempData[parsedKey] = value
                    }
                } else {
                    if (!tempData.hasOwnProperty(parsedKey)) {
                        tempData[parsedKey] = {}
                    }

                    tempData = tempData[parsedKey]
                }
            })
            data = {...currentData}
        }

        return data
    }, [])

    return {formDataParser, parseKey}
}
