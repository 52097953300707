import React, {FC, ReactNode, useEffect} from 'react'
import { Button, ButtonProps } from 'react-bootstrap'
import { classUtils as c } from 'Vendor/Utils/ClassUtils'
import {ButtonVariant} from "react-bootstrap/types";

type Props = {
    success?: boolean
    setSuccess?: (success: boolean) => void
    isLoading: boolean
    children: ReactNode
    disabled?: boolean
    variant?: ButtonVariant
    className?: string
}

const LoadingButton: FC<Props & ButtonProps> = ({isLoading, children, success, setSuccess, disabled, variant, className, ...rest}) => {
    useEffect(() => {
        if (success && setSuccess) {
            const timer = setTimeout(() => {
                setSuccess(false)
            }, 1500);
            return () => clearTimeout(timer);
        }
    }, [success]);

    return <Button
        variant={success ? 'success' : (variant ?? 'primary')}
        disabled={isLoading || disabled || success}
        {...rest}
        className={c((isLoading || success) && 'btn-loading', className)}
    >
        {children}
        {isLoading && <span
            className="spinner-border spinner-grow-sm"
            role="status"
            aria-hidden="true"
        />}
        {success && !isLoading && <i className="fa-solid fa-check loading-button-success"></i>}
    </Button>
}

export default LoadingButton
