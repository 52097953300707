import {Language} from "@topol.io/editor/dist/types/types/Language/Language";
import ITopolOptions from "@topol.io/editor/dist/types/types/ITopolOptions";

const TopolOptions: ITopolOptions = {
    callbacks: {},
    language: 'fr',
    id: '#topol-editor',
    api: {
        IMAGE_UPLOAD: 'TEST'
    },
    autosaveInterval: 0,
    betaFeatures: {},
    chatAI: false,
    customBlocks: [],
    customFileManager: true,
    apiAuthorizationHeader: undefined,
    colors: undefined,
    contentBlocks: {
        'text': {
            disabled: false,
            hidden: false,
        },
        'image': {
            disabled: false,
            hidden: false,
        },
        'gif': {
            disabled: false,
            hidden: false,
        },
        'button': {
            disabled: false,
            hidden: false,
        },
        'divider': {
            disabled: false,
            hidden: false,
        },
        'spacer': {
            disabled: false,
            hidden: false,
        },
        'social': {
            disabled: false,
            hidden: false,
        },
        'video': {
            disabled: false,
            hidden: false,
        },
        'html': {
            disabled: false,
            hidden: false,
        },
        'product': {
            disabled: true,
            hidden: true,
        }
    },
    customFonts: undefined,
    disableAiAssistant: true,
    disableAlerts: false,
    enableAutosaves: false,
    enableImageToTextRatio: false,
    enableTemplateSizeInfo: false,
    fontSizes: undefined,
    googleApiKey: "",
    hideSettingsTab: false,
    htmlMinified: true,
    imageCompressionOptions: {},
    imageEditor: false,
    imageMaxSize: 0,
    light: true,
    mainMenuAlign: 'right',
    mergeTags: [{
        name: 'Destinataire', items: [
            {value: '__EMAIL__', text: 'Email', label: 'Adresse email du destinataire'},
            {value: '__PRENOM__', text: 'Prénom', label: 'Prénom du destinataire (si via liste de diffusion)'},
            {value: '__NOM__', text: 'Nom', label: 'Nom du destinataire (si via liste de diffusion)'},
        ]
    }],
    mobileFirstEnabled: false,
    premadeBlocks: undefined,
    premadeTemplates: false,
    removeTopBar: false,
    role: undefined,
    showUnsavedDialogBeforeExit: false,
    smartMergeTags: {enabled: false},
    theme: undefined,
    tinyConfig: undefined,
    title: "",
    topBarOptions: [
        "undoRedo",
        "changePreview",
        "previewSize",
        "previewTestMail",
        "save",
        "saveAndClose"
    ],
    windowBar: undefined,
    authorize: {
        apiKey: process.env.REACT_APP_TOPOL_API_KEY ?? '',
        userId: '',
    }
}

export default TopolOptions;