import {Outlet, RouteObject} from "react-router-dom";
import React from "react";
import ResetPasswordRouting from "./Security/ResetPasswordRouting";
import LoginPage from "Pages/Security/LoginPage";
import ActivatePage from "../Pages/Security/ActivatePage";

const SecurityRouting: RouteObject[] = [
    {
        path: 'login',
        element: <LoginPage />,
    },
    {
        path: 'activate/:token',
        element: <ActivatePage />,
    },
    {
        path: 'activate',
        element: <ActivatePage />,
    },
    {
        path: 'reset-password',
        element: <Outlet />,
        children: ResetPasswordRouting
    },
]

export default SecurityRouting