import {Rows} from "Components/Layout/Table";

const EmailTable: Rows = {
    name: {
        sortable: true,
        placeholders: [
            {
                element: 'p',
                size: [3],
            }
        ]
    },
    email: {
        sortable: true,
        placeholders: [
            {
                element: 'p',
                size: [3],
            }
        ]
    },
    actions: {
        className: 'actions',
        sortable: false,
        placeholders: [
            {
                element: 'a',
                size: 2,
            },
        ]
    },
};

export default EmailTable
