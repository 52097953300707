import {RouteObject} from "react-router-dom";
import MainPage from "Pages/Admin/Edition/Catalog/MainPage";
import ListingPage from "Pages/Admin/Edition/Catalog/ListingPage";
import CardPage from "Pages/Admin/Edition/Catalog/CardPage";
import React from "react";
import ProductGroupPage from "../../Pages/Admin/Edition/Catalog/ProductGroupPage";
import ExportConfigPage from "../../Pages/Admin/Edition/ExportConfigPage";

const CatalogRouting: RouteObject[] = [
    {
        index: true,
        element: <MainPage/>,
    },
    {
        path: 'card',
        element: <CardPage />,
    },
    {
        path: 'listing',
        element: <ListingPage/>,
    },
    {
        path: 'export-config',
        element: <ExportConfigPage collection="catalog" />,
    },
    {
        path: 'group',
        element: <ProductGroupPage/>,
    }
]

export default CatalogRouting