import React, {FC, useEffect, useState} from 'react';
import Product from "Entity/Product";
import {useTranslation} from "react-i18next";
import Sticker from "../../../Entity/Product/Sticker";
import {Button, Modal} from "react-bootstrap";
import LoadingButton from "../../Button/LoadingButton";
import Api from "Vendor/Api";
import {Link} from "react-router-dom";
import ProductStickersEditModal from "./ProductStickersEditModal";

interface _ProductStickersProps {
    product: Product,
    setProduct: (product: Product) => void,
    canEdit: boolean
    closeProduct: () => void
}

const _ProductStickers: FC<_ProductStickersProps> = ({product, setProduct, canEdit, closeProduct}) => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [showModal, setShowModal] = useState<boolean>(false)
    const [showStickerModal, setShowStickerModal] = useState<boolean>(false)
    const [currentSticker, setCurrentSticker] = useState<Sticker | null>(null)
    const [stickers, setStickers] = useState<number>(1)
    const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
    const [createSticker, setCreateSticker] = useState<string>('')
    const [isCreateLoading, setCreateLoading] = useState<boolean>(false)

    useEffect(() => {
        if (currentSticker) {
            let tempCurrentSticker = null
            product.stickers?.forEach((stickerItem) => {
                if (stickerItem.id === currentSticker.id) {
                    tempCurrentSticker = stickerItem
                }
            })
            setCurrentSticker(tempCurrentSticker)
        }
    }, [product])

    const onSubmit = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        setIsLoading(true)

        Api.patch(`/product/${product.id}/sticker/generate`, {
            count: stickers
        })
            .then((response) => {
                setProduct(response.data as Product)
                setShowModal(false)
            })
            .catch(() => {
            })
            .then(() => {
                setIsLoading(false)
            })
    }

    const editSticker = (sticker: Sticker) => {
        Api.get(`product/${product.id}/sticker/${sticker.id}`)
            .then((response) => {
                setCurrentSticker(response.data as Sticker)
                setShowStickerModal(true)
            })
    }

    const downloadSticker = (sticker: Sticker) => {
        Api.get(`/product/${product.id}/sticker/${sticker.id}/card`, {
            responseType: 'blob'
        })
            .then((response) => {
                let disposition = response.headers['content-disposition'];
                // get filename from content-disposition
                let filename = disposition?.split(';')[1].split('filename')[1].split('=')[1].trim().replace(/"/g, '');
                // create file link in browser's memory
                const href = URL.createObjectURL(response.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', filename); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
            .catch(() => {
            })
            .then(() => {
            })
    }

    const onCreateSubmit = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        setCreateLoading(true)

        Api.patch(`/product/${product.id}/sticker/create`, {
            code: createSticker
        })
            .then((response) => {
                setProduct(response.data as Product)
                setShowCreateModal(false)
            })
            .catch(() => {
            })
            .then(() => {
                setCreateLoading(false)
            })
    }

    return <div className="row justify-content-center align-items-center mt-3">
        <div className="col">
            <h3>{t('app.stickers')}</h3>
            {canEdit &&
                <>
                    <div className="row justify-content-end align-items-center">
                        <div className="col-auto">
                            <Button variant="primary" size="sm" onClick={() => {setShowModal(true)}}><i className="fas fa-plus me-2"></i>{t('app.generate_new_stickers')}</Button>
                            <Modal show={showModal} onHide={() => setShowModal(false)}>
                                <form onSubmit={onSubmit}>
                                <Modal.Header closeButton>
                                    <Modal.Title>{t('app.generate_new_stickers')}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <input type="number" className="form-control" value={stickers} onChange={(e) => setStickers(parseInt(e.target.value))} placeholder={t('app.how_many_stickers')} />
                                </Modal.Body>
                                <Modal.Footer>
                                    <LoadingButton isLoading={isLoading} type="submit" variant="primary"><i className="fas fa-plus me-2"></i>{t('app.generate')}</LoadingButton>
                                </Modal.Footer>
                                </form>
                            </Modal>
                        </div>
                        <div className="col-auto">
                            <Button variant="primary" size="sm" onClick={() => {setShowCreateModal(true)}}><i className="fas fa-plus me-2"></i>{t('app.create_new_sticker')}</Button>
                            <Modal show={showCreateModal} onHide={() => setShowCreateModal(false)}>
                                <form onSubmit={onCreateSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>{t('app.create_new_sticker')}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <input type="text" className="form-control" value={createSticker} onChange={(e) => setCreateSticker(e.target.value)} />
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <LoadingButton isLoading={isCreateLoading} type="submit" variant="primary"><i className="fas fa-plus me-2"></i>{t('app.create')}</LoadingButton>
                                    </Modal.Footer>
                                </form>
                            </Modal>
                        </div>
                    </div>
                </>
            }
            <ul className="list-group my-3">
                {product.stickers?.map((sticker: Sticker, key) => {
                    return <li key={key} className="list-group-item">
                        <h6 className="d-inline">{sticker.code}</h6> : {t(`app.sticker_status.${sticker.status}`)}
                        {'archived' === sticker.status &&
                            <i className="ms-2 d-inline">,
                                <u>{t('app.because')}</u>: {sticker.archivedBecause}
                            </i>
                        }
                        {('outputted' === sticker.status && (sticker.outputtedAt || sticker.outputtedBy)) &&
                            <i className="ms-2 d-inline">{t('app.since')} : {sticker.outputtedAt ? (new Date(sticker.outputtedAt)).toLocaleDateString() : 'no date'}, {t('app.by')} : {sticker.outputtedBy ? sticker.outputtedBy.name : t('app.unknown')}</i>
                        }
                        {sticker.shoppingProduct?.shopping &&
                            <Link to={`/${sticker.shoppingProduct.shopping.type}/${sticker.shoppingProduct.shopping.id}`} className="badge btn btn-sm btn-primary my-2 ms-2">
                                <i className="fas fa-shopping-cart"></i> {t(`app.${sticker.shoppingProduct.shopping.type ?? 'shopping'}`) + ' #' + sticker.shoppingProduct.shopping.id}
                            </Link>
                        }
                        {canEdit &&
                            <>
                                <Button className="badge btn btn-sm btn-primary my-2 ms-2" onClick={() => {
                                    editSticker(sticker)
                                }}>{t('app.edit')}</Button>
                                <Button className="badge btn btn-sm btn-primary my-2 ms-2" onClick={() => {
                                    downloadSticker(sticker)
                                }}><i className="fas fa-file-pdf"></i>{t('app.download')}</Button>
                            </>
                        }
                    </li>
                })}
            </ul>
            <ProductStickersEditModal sticker={currentSticker}
                                      product={product}
                                      setProduct={setProduct}
                                      showStickerModal={showStickerModal}
                                      setShowStickerModal={setShowStickerModal}
            />
        </div>
    </div>
}

export default _ProductStickers
