import React, {FC, useContext, useState} from 'react'
import {useTranslation} from "react-i18next";
import Header from "Components/Layout/Header";
import Table from "Components/Layout/Table";
import DeleteModal from "../../../../../Components/Modal/DeleteModal";
import {Button} from "react-bootstrap";
import FormEmptyOffCanvas from "../../../../../Components/Modal/FormEmptyOffCanvas";
import CompanyGroup from "../../../../../Entity/Security/CompanyGroup";
import CompanyGroupTable from "../../../../../Table/Admin/Edition/Security/CompanyGroupTable";
import {useOutletContext} from "react-router-dom";

const CompanyGroupPage: FC = () => {
    const { t } = useTranslation()

    const [toggleReload, setToggleReload] = useState<boolean>(true)
    const [show, setShow, search, setSearch] = useOutletContext<[boolean, (show:boolean) => void, string, (search:string) => void]>()
    const [companyGroups, setCompanyGroups] = useState<CompanyGroup[]>([])
    const [companyGroup, setCompanyGroup] = useState<CompanyGroup>({} as CompanyGroup)
    const handleClose = (reload = false) => {
        setShow(false)
        if (reload) {
            setToggleReload(!toggleReload)
        }
    }
    const handleShow = () => setShow(true)
    // remove
    const [showDelete, setShowDelete] = useState<boolean>(false)
    const handleDeleteClose = (reload = false) => {
        setCompanyGroup({} as CompanyGroup)
        setShowDelete(false)
        if (reload) {
            setToggleReload(!toggleReload)
        }
    }
    const handleDeleteShow = () => setShowDelete(true)

    const onSuccess = (companyGroup: CompanyGroup) => {
        setCompanyGroup(companyGroup)
        setShow(false)
        setToggleReload(!toggleReload)
    }

    const onExited = () => {
        setCompanyGroup({} as CompanyGroup)
    }

    const addCompanyGroup = () => {
        setCompanyGroup({} as CompanyGroup)
        handleShow()
    }

    const editCompanyGroup = (companyGroup: CompanyGroup) => {
        setCompanyGroup(companyGroup)
        handleShow()
    }

    const deleteCompanyGroup = (companyGroup: CompanyGroup) => {
        setCompanyGroup(companyGroup)
        handleDeleteShow()
    }

    const actionsRender = (companyGroup: CompanyGroup) => (
        <>
            {companyGroup?.isGranted?.edit &&
                <Button
                    variant='outline-primary'
                    size='sm'
                    onClick={() => editCompanyGroup(companyGroup)}>
                    <i className="fa-solid fa-pencil me-2"></i>
                    {t('app.edit')}</Button>
            }
            {companyGroup?.isGranted?.delete &&
                <Button
                    variant='outline-danger'
                    size='sm'
                    onClick={() => deleteCompanyGroup(companyGroup)}>
                    <i className="fa-solid fa-trash me-2"></i>
                    {t('app.delete')}</Button>
            }
        </>
    );

    let table = CompanyGroupTable;
    if (table.hasOwnProperty('actions')) {
        table.actions.render = actionsRender;
    }

    return (
        <>
            <Table
                path="/admin/edition/company-group"
                rows={table}
                toggleReload={toggleReload}
                items={companyGroups}
                setItems={setCompanyGroups}
                keyword={search}
                setKeyword={setSearch}
            />
            <FormEmptyOffCanvas
                i18nTitle={companyGroup.id ? 'app.edit_company_group' : 'app.add_company_group'}
                show={show}
                handleClose={handleClose}
                onSuccess={onSuccess}
                onExited={onExited}
                path={`/admin/edition/company-group/${companyGroup.id ?? 'new'}`}
                formPath={!!companyGroup.id}
                method={companyGroup.id ? 'patch' : 'post'}
            />
            <DeleteModal
                handleClose={handleDeleteClose}
                show={showDelete}
                path={'/admin/edition/company-group/' + (companyGroup?.id ?? 'error')}
                name={'#' + (companyGroup ? `${companyGroup.id} - ${companyGroup.name}` : 'error')}
                archive={true}
            />
        </>
    )
}

export default CompanyGroupPage
