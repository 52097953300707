import {Choice} from "Vendor/Components/Form/Type/ChoiceType";

const AddressFieldChoice: Choice[] = [
    {
        i18nKey: 'app.enum.property.address.fields.name',
        value: 'name'
    },
    {
        i18nKey: 'app.enum.property.address.fields.row1',
        value: 'row1'
    },
    {
        i18nKey: 'app.enum.property.address.fields.row2',
        value: 'row2'
    },
    {
        i18nKey: 'app.enum.property.address.fields.postalCode',
        value: 'postalCode'
    },
    {
        i18nKey: 'app.enum.property.address.fields.city',
        value: 'city'
    },
    {
        i18nKey: 'app.enum.property.address.fields.country',
        value: 'country'
    },
]

export default AddressFieldChoice
