import React, {FC, useContext} from "react";
import {useTranslation} from "react-i18next";
import Header from "Components/Layout/Header";
import {UserContext} from "../Vendor/Context/UserContextProvider";
import {useNavigate} from "react-router-dom";


export const DashboardPage: FC = () => {
    const { t } = useTranslation()
    const { getInitialPage } = useContext(UserContext)
    const navigate = useNavigate()

    navigate(getInitialPage())

    return (
        <>
            <Header title={t('app.dashboard')}/>
        </>
    )
}
