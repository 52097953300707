import React, {FC, useContext, useState} from "react";
import BlankPage from "../../../../Components/Layout/BlankPage";
import {useOutletContext} from "react-router-dom";
import DataCollection from "../../../../Entity/DataCollection";
import ListingSetting from "Components/Form/ListingSetting";
import ListingSettingEntity from 'Entity/Collection/ListingSetting';
import FormEmpty from "../../../../Vendor/Components/FormEmpty";
import LoadingButton from "../../../../Components/Button/LoadingButton";
import {useTranslation} from "react-i18next";
import {BannerContext} from "../../../../Vendor/Context/BannerContextProvider";
import User from "../../../../Entity/User";
import {UserContext} from "../../../../Vendor/Context/UserContextProvider";

const ListingPage: FC = () => {
    const { setCurrentUser } = useContext(UserContext);
    const [dataCollection, setDataCollection] = useOutletContext<[DataCollection, (dataCollection: DataCollection) => void]>()
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)
    const { setBanner } = useContext(BannerContext);

    const setListing = (listing: ListingSettingEntity) => {
        let tempDataCollection = {...dataCollection}
        tempDataCollection.listingSetting = listing

        setDataCollection(tempDataCollection)
    }

    const onSubmit = () => {
        setIsLoading(true)
    }
    const onResponse = () => {
        setIsLoading(false)
    }
    const onSuccess = (data: User) => {
        setCurrentUser(data)
        setBanner({type: 'success', i18nTitle: 'app.save', i18nMessage: 'app.data_saved_message'})
    }
    const onError = () => {
        setSuccess(false)
    }

    return <BlankPage>
        <FormEmpty
            path={"/admin/edition/collection/listing-setting/data-collection/" + dataCollection.id}
            formPath={!!dataCollection.id}
            children={<div className="row justify-content-end align-items-center">
                <div className="col-auto">
                    <LoadingButton type="submit" isLoading={isLoading}>
                        {t( 'app.validate' )}
                    </LoadingButton>
                </div>
            </div>}
            method={dataCollection.id ? 'PATCH' : 'POST'}
            onSubmit={onSubmit}
            onResponse={onResponse}
            onSuccess={onSuccess}
            onError={onError}
        />
    </BlankPage>
}

export default ListingPage