import React, {FC, useContext, useState} from 'react'
import {useTranslation} from "react-i18next";
import FormError from "../../../../Vendor/Components/Form/FormError";
import FormRow from "../../../../Vendor/Components/Form/FormRow";
import Form from "../../../../Components/Utils/Form";
import BlankPage from "../../../../Components/Layout/BlankPage";
import LoadingButton from "../../../../Components/Button/LoadingButton";
import Setting from "../../../../Entity/Organization/Setting";
import SettingForm from "../../../../Form/Admin/Edition/SettingForm";
import {BannerContext} from "../../../../Vendor/Context/BannerContextProvider";
import {UserContext} from "../../../../Vendor/Context/UserContextProvider";
import User from "../../../../Entity/User";
import FormEmpty from "../../../../Vendor/Components/FormEmpty";

const MiscPage: FC = () => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const { currentUser, setCurrentUser } = useContext(UserContext);
    const [success, setSuccess] = useState<boolean>(false)
    const { setBanner } = useContext(BannerContext);

    const onSubmit = () => {
        setIsLoading(true)
    }
    const onResponse = () => {
        setIsLoading(false)
    }
    const onSuccess = (data: User) => {
        setSuccess(true)
        setBanner({type: 'success', i18nTitle: 'app.save', i18nMessage: 'app.data_saved_message'})
        setCurrentUser(data)
    }
    const onError = () => {
        setSuccess(false)
    }

    if (!currentUser || !currentUser.currentOrganization) return <></>

    return (
        <BlankPage>
            <FormEmpty
                path={'/admin/edition/setting/misc'}
                formPath={!!currentUser?.currentOrganization?.id}
                children={<div className="row justify-content-end align-items-center">
                    <div className="col-auto">
                        <LoadingButton type="submit" isLoading={isLoading}>
                            {t( 'app.validate' )}
                        </LoadingButton>
                    </div>
                </div>}
                method={currentUser?.currentOrganization?.id ? 'PATCH' : 'POST'}
                onSuccess={onSuccess}
                onSubmit={onSubmit}
                onResponse={onResponse}
                onError={onError}
            />
        </BlankPage>
    )
}

export default MiscPage
