import {RouteObject} from "react-router-dom";
import React from "react";
import DataCollectionPage from "Pages/DataCollectionPage";

const DataCollectionRouting: RouteObject[] = [
    {
        index: true,
        element: <DataCollectionPage />,
    },
]

export default DataCollectionRouting
