import React, { FC, FormEvent, useState } from 'react'
import {
    Form,
    Button,
    Modal,
    ModalBody as Body,
    ModalFooter as Footer,
    ModalHeader as Header,
    ModalTitle as Title,
} from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Api from "Vendor/Api";
import LoadingButton from "../Button/LoadingButton";

const DeleteModal: FC<{
    path: string
    show: boolean
    handleClose: (reload: boolean) => void
    onSuccess?: (data: any) => void
    name: string
    archive?: boolean
    title?: string
    message?: string
}> = ({
          path,
          show,
          handleClose,
          onSuccess,
          name,
          archive = false,
          title = '',
          message = '',
      }) => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIsLoading(true)

        Api.delete(path)
            .then(({ data }) => {
                handleClose(true)
                onSuccess && onSuccess(data)
            })
            .catch(() => {})
            .then(() => {
                setIsLoading(false)
            })
    }

    return (
        <Modal
            show={show}
            onHide={() => {
                handleClose(false)
            }}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Form
                name="delete"
                method="delete"
                noValidate
                onSubmit={handleSubmit}
            >
                <Header closeButton={!isLoading}>
                    <Title>
                        {title
                            ? title
                            : t(
                                archive
                                    ? 'app.archive'
                                    : 'app.delete',
                            )}
                    </Title>
                </Header>
                <Body>
                    {message
                        ? message
                        : t(
                            archive
                                ? 'app.archive_question'
                                : 'app.delete_question',
                        )}{' '}
                    <span className="text-info">{name}</span> ?
                </Body>
                <Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            handleClose(false)
                        }}
                        disabled={isLoading}
                    >
                        {t('app.cancel')}
                    </Button>
                    <LoadingButton
                        variant="danger"
                        type="submit"
                        isLoading={isLoading}
                    >
                        {t(
                            archive
                                ? 'app.archive'
                                : 'app.delete',
                        )}
                    </LoadingButton>
                </Footer>
            </Form>
        </Modal>
    )
}

export default DeleteModal
