import React, {FC, useEffect, useState} from "react";
import {ThumbnailModalProps} from "../View/_CardGroup";
import Api from "../../../Vendor/Api";

export interface _PreviewFileProps {
    thumbnailModalProps?: ThumbnailModalProps
    setThumbnailModalProps: (modalProps?: ThumbnailModalProps) => void
}

const _PreviewFile: FC<_PreviewFileProps> = ({
                                                 thumbnailModalProps,
                                                 setThumbnailModalProps,
}) => {
    const [previewUrl, setPreviewUrl] = useState<string | undefined>(undefined)
    const [thumbnailType, setThumbnailType] = useState<string | undefined>(undefined)

    useEffect(() => {
        let tempThumbnailType = undefined
        if (thumbnailModalProps && thumbnailModalProps.mimeType) {
            if (thumbnailModalProps?.mimeType.includes("image")) {
                tempThumbnailType = "image"
            } else if (thumbnailModalProps?.mimeType.includes("video")) {
                tempThumbnailType = "video"
            } else if (thumbnailModalProps?.mimeType.includes("pdf")) {
                tempThumbnailType = "pdf"
            } else if (thumbnailModalProps?.mimeType.includes("audio")) {
                tempThumbnailType = "audio"
            } else if (thumbnailModalProps?.mimeType.includes("document")) {
                tempThumbnailType = "document"

                Api.get(`${thumbnailModalProps.path}/generate-unique-token`).then((response) => {
                    setPreviewUrl(response.data.url)
                })
            }
        }
        setThumbnailType(tempThumbnailType)
    }, [thumbnailModalProps])

    return <>{
        thumbnailType && {
            'image': <img src={thumbnailModalProps && thumbnailModalProps.path} alt={thumbnailModalProps && thumbnailModalProps.name}/>,
            'pdf': <iframe src={thumbnailModalProps && thumbnailModalProps.path} width="100%" height="531.25px"></iframe>,
            'video': <video controls width="100%" height="531.25px">
                <source src={thumbnailModalProps && thumbnailModalProps.path} type={(thumbnailModalProps?.mimeType && thumbnailModalProps?.mimeType !== "video/quicktime") ? thumbnailModalProps?.mimeType : "video/mp4"}/>
            </video>,
            'document': previewUrl
                ? <iframe src={"https://view.officeapps.live.com/op/embed.aspx?src=".concat(encodeURIComponent(previewUrl))} style={{border: 0}} width="100%" height="531.25px"></iframe>
            : <p>Preview on loading...</p>,
            'audio': <audio controls>
                <source src={thumbnailModalProps && thumbnailModalProps.path} type={thumbnailModalProps?.mimeType || "audio/mpeg"}/>
            </audio>,
        }[thumbnailType]
    }
    </>
}

export default _PreviewFile