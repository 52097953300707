import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {Link, useLocation} from "react-router-dom";
import { classUtils as c } from 'Vendor/Utils/ClassUtils'
import {Container, Nav, Navbar as BootstrapNavbar} from "react-bootstrap";

export type NavbarItem = {
    i18nLabel: string
    path: string
}

type NavbarProps = {
    locationBase: string
    items: NavbarItem[]
    rightItems?: NavbarItem[]
    state?: any
}

export const Navbar: FC<NavbarProps> = ({locationBase, items, rightItems, state}) => {
    const { t } = useTranslation()

    const currentLocation = useLocation()
    return (
        <BootstrapNavbar bg="light" expand="lg" className=" p-3 mb-3 rounded-4">
            <Container>
                <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
                <BootstrapNavbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        {items.map((item, index) => (
                            <li key={index} className="nav-item">
                                <Link to={item.path} state={state} className={c('nav-link', currentLocation.pathname === (item.path ? locationBase + '/' + item.path : locationBase) && 'active')} aria-current="page">{t(item.i18nLabel)}</Link>
                            </li>
                        ))}
                    </Nav>
                    {rightItems && rightItems.length &&
                        <Nav>
                            {rightItems.map((item, index) => (
                                <li key={index} className="nav-item">
                                    <Link to={item.path} state={state} className={c('nav-link', currentLocation.pathname === (item.path ? locationBase + '/' + item.path : locationBase) && 'active')} aria-current="page">{t(item.i18nLabel)}</Link>
                                </li>
                            ))}
                        </Nav>}
                </BootstrapNavbar.Collapse>
            </Container>
        </BootstrapNavbar>
    )
}
