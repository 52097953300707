import React, { FC, useContext, useState } from 'react'
import {UserContext} from "Vendor/Context/UserContextProvider";
import Api from "Vendor/Api";
import FormModal from "../../Modal/FormModal";
import AccountForm from "../../../Form/Security/AccountForm";
import FormRow from "../../../Vendor/Components/Form/FormRow";
import User from "../../../Entity/User";
import {DataType} from "../../../Vendor/Components/Form";

type AccountEditPost = {
    firstName: string
    lastName: string
    plainPassword: {
        first?: string
        second?: string
    }
}

const _Account: FC = () => {
    const { currentUser, setCurrentUser, setOnLogout, setInit } = useContext(UserContext)
    const [show, setShow] = useState<boolean>(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const onSuccess = (user: User) => {
        setCurrentUser(user)
        setShow(false)
    }

    const exitImpersonation = () => {
        setInit(false)
        Api.get(`/switch-user?_switch_user=_exit`)
            .then(({ data }) => {
                setCurrentUser(data)
            })
            .catch((err) => {
            })
            .then(() => {
                setInit(true)
            })
    }

    if (typeof currentUser === "undefined") return <></>

    return (
        <div className="account">
            <span onClick={(currentUser.previousAdmin === undefined || currentUser.previousAdmin === false) ? handleShow : () => false}>
                <div className="icon">{
                    currentUser.firstName
                    && currentUser.firstName.length
                        ? (currentUser.firstName[0] + (currentUser.lastName && currentUser.lastName.length ? currentUser.lastName[0] : ''))
                        : currentUser.email && currentUser.email.length
                            ? currentUser.email[0]
                            : '-'
                }</div>
                <div className="name">
                    {
                        currentUser.firstName || currentUser.lastName
                            ? `${currentUser.firstName} ${currentUser.lastName}`
                            : currentUser.email
                    }
                </div>
            </span>
            <span>
                {currentUser.previousAdmin
                    ? <span onClick={exitImpersonation} className="logout">
                        <i className="fa-solid fa-person-from-portal"></i>
                    </span>
                    : <span onClick={() => setOnLogout(true)} className="logout">
                        <i className="fa-solid fa-sign-out"></i>
                    </span>
                }
            </span>
            <FormModal
                i18nTitle={'app.account_edit'}
                show={show}
                handleClose={handleClose}
                onSuccess={onSuccess}
                form={new AccountForm()}
                path='/account-edit'
                formData={currentUser}
                setFormData={(data: DataType) => {
                    let user = data as User
                    setCurrentUser(user)
                }}
            >
                <FormRow name='email' />
                <FormRow name='firstName' />
                <FormRow name='lastName' />
                <FormRow name='plainPassword' />
            </FormModal>
        </div>
    )
}

export default _Account
