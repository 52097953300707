import {Rows} from "Components/Layout/Table";

const PropertyTable: Rows = {
    id: {
        placeholders: [
            {
                element: 'p',
                size: 1
            }
        ]
    },
    name: {
        placeholders: [
            {
                element: 'p',
                size: [3],
            }
        ]
    },
    type: {
        i18nPrefix: 'app.enum.property.type',
        placeholders: [
            {
                element: 'p',
                size: [3],
            }
        ]
    },
    multiple: {
        i18nPrefix: 'app.multiple',
        i18nKey: 'app.boolean_values.',
        i18nPlaceholder: 'app.no',
        placeholders: [
            {
                element: 'p',
                size: [2],
            }
        ]
    },
    propertyGroup: {
        field: 'name',
        i18nPlaceholder: 'app.ungrouped',
        placeholders: [
            {
                element: 'p',
                size: [2],
            }
        ]
    },
    createdBy: {
        field: 'name',
        placeholders: [
            {
                element: 'p',
                size: [2],
            }
        ]
    },
    slug: {
        placeholders: [
            {
                element: 'p',
                size: [1],
            }
        ]
    },
    actions: {
        className: 'actions',
        sortable: false,
        placeholders: [
            {
                element: 'a',
                size: 2,
            },
        ]
    },
};

export default PropertyTable