import FormBuilderInterface from "Vendor/Definition/Form/FormBuilderInterface";
import FormInterface from "Vendor/Definition/Form/FormInterface";
import FormOptionsInterface from "Vendor/Definition/Form/FormOptionsInterface";

export default class LoginForm implements FormInterface {
    name = 'login'

    builder (builder: FormBuilderInterface, options: FormOptionsInterface) {
        builder
            .addText('username', {
                i18nLabel: 'app.email',
            })
            .addPassword('password')
    }
}
