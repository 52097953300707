import React, { FC, FormEvent, useState } from 'react'
import {
    Form,
    Button,
    Modal,
    ModalBody as Body,
    ModalFooter as Footer,
    ModalHeader as Header,
    ModalTitle as Title,
} from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Api from "Vendor/Api";
import LoadingButton from "../Button/LoadingButton";
import Spinner from "../Spinner";

const RefreshModal: FC<{
    show: boolean
}> = ({
          show,
      }) => {

    return (
        <Modal
            show={show}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Body>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '5rem 0',
                    }}
                >
                    <Spinner />
                </div>
            </Body>
        </Modal>
    )
}

export default RefreshModal
