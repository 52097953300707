import React, { FC } from 'react'
import {Dropdown} from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import {ButtonVariant} from "react-bootstrap/types";
import {DropdownToggleProps} from "react-bootstrap/DropdownToggle";

export type OptionProps = {
    label: string
    value: string|number
}

type DropdownSelectProps = DropdownToggleProps & {
    label?: string
    i18nLabel?: string
    options: OptionProps[]|undefined
    selected: string|number
    setSelected: (selected: string|number) => void
    variant?: ButtonVariant
}

const DropdownSelect: FC<DropdownSelectProps> = ({
                                                     label,
                                                     i18nLabel,
                                                     options,
                                                     selected = '',
                                                     setSelected = () => {},
                                                     variant= "primary",
                                                     ...rest
                                                 }) => {
    const { t } = useTranslation()
    return (
        <Dropdown>
            <Dropdown.Toggle variant={variant} className="dropdown-toggle-rounded" {...rest} disabled={!options}>
                {
                    label
                        ? label
                        : i18nLabel
                            ? t(i18nLabel, {s: selected})
                            : '--unknown--'
                }
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {options && options.map((option, i) => (
                    <Dropdown.Item key={i} onClick={() => {setSelected(option.value)}} active={selected === option.value}>{option.label}</Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default DropdownSelect
