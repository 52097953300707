import {MenuItem} from "../Components/Layout/Sidebar/_MenuItem";

const _InternalMenu: MenuItem[] = [
    /*{
        index: true,
        to: '/admin',
        className: 'fa-solid fa-building',
        label: 'app.dashboard',
    },*/
    {
        to: '/admin/organization',
        className: 'fa-solid fa-building',
        label: 'app.organizations',
    },
    {
        to: '/admin/user',
        className: 'fa-solid fa-users',
        label: 'app.internal_users',
    },
    {
        to: '/admin/mailing-identity',
        className: 'fa-solid fa-envelope',
        label: 'app.mailing_identity.title',
    }
]

export default _InternalMenu
