import FormBuilderInterface, {FormMethod, FormSkeletonInterface, FormSkeletonType} from "./FormBuilderInterface";
import ChoiceInterface from "./Options/ChoiceInterface";
import OptionsInterface from "./OptionsInterface";
import FieldType from "../../Config/Form/FieldType";
import PhoneInterface from "./Options/PhoneInterface";
import TranslationsInterface from "./Options/TranslationsInterface";
import NumberInterface from "./Options/NumberInterface";
import CheckboxInterface from "./Options/CheckboxInterface";
import EntityInterface from "./Options/EntityInterface";
import RepeatedInterface from "./Options/RepeatedInterface";
import {Choice} from "../../Components/Form/Type/ChoiceType";
import CollectionInterface from "./Options/CollectionInterface";
import ColorInterface from "./Options/ColorInterface";
import TextInterface from "./Options/TextInterface";
import {DataType} from "Vendor/Components/Form/SubForm";

export default class FormBuilder implements FormBuilderInterface {
    private skeleton: FormSkeletonType = {
        method: 'post',
        children: {},
    }

    getSkeleton(method?: FormMethod, data?: DataType):FormSkeletonType {
        this.skeleton.method = method ?? 'post'

        if (data) {
            for (const [name, skeleton] of Object.entries(this.skeleton.children)) {
                if (skeleton.type === 'Collection' && data.hasOwnProperty(name) && Array.isArray(data[name]) && data[name].length) {
                    let tempData: [] = data[name]
                    let builder: FormBuilderInterface = new FormBuilder()
                    let options = skeleton.options as CollectionInterface
                    let subChildren = this.skeleton.children[name].children
                    options.entryType.builder(builder, {})

                    if (subChildren === undefined) {
                        subChildren = {}
                    }

                    tempData.forEach((subData, index) => {
                        if (subChildren) {
                            subChildren[`child_${index}`] = {...builder.getSkeleton().children}
                        }
                    })
                }
            }
        }

        return this.skeleton
    }

    add(name: string, type: FieldType, options: OptionsInterface): this {
        let field: FormSkeletonInterface = {
            type,
            options,
        }

        if (type === 'Collection') {
            field['children'] = {}
        }

        this.skeleton.children[name] = field

        return this
    }

    addChoice(name: string, options: ChoiceInterface): this {
        this.add(name, 'Choice', options)

        return this
    }

    addText(name: string, options?: TextInterface): this {
        if (!options) {
            options = {}
        }
        this.add(name, 'Text', options)

        return this
    }

    addColor(name: string, options?: ColorInterface): this {
        if (!options) {
            options = {}
        }
        this.add(name, 'Color', options)

        return this
    }

    addPassword(name: string, options?: TextInterface): this {
        if (!options) {
            options = {}
        }
        this.add(name, 'Password', options)

        return this
    }

    addEmail(name: string, options?: TextInterface): this {
        if (!options) {
            options = {}
        }
        this.add(name, 'Email', options)

        return this
    }

    addRepeat(name: string, options: RepeatedInterface): this {
        this.add(name, 'Repeated', options)

        return this
    }


    addPhone(name:string, options?: PhoneInterface): this {
        if (!options) {
            options = {}
        }
        this.add(name, 'Phone', options)

        return this
    }

    addTextarea (name: string, options?: TextInterface): this {
        if (!options) {
            options = {}
        }
        this.add(name, 'Textarea', options)

        return this
    }

    addTranslation (name: string, options: TranslationsInterface): this {
        this.add(name, 'Translation', options)
        return this
    }

    addNumber (name: string, options?: NumberInterface): this {
        if (!options) {
            options = {}
        }
        this.add(name, 'Number', options)
        return this
    }

    addCheckbox (name: string, options?: CheckboxInterface): this {
        if (!options) {
            options = {defaultValue: ''}
        }

        this.add(name, 'Checkbox', options)

        return this
    }

    addEntity(name: string, options: EntityInterface): this {
        if (!options.hasOwnProperty('choices')) {
            options.choices = []
        }
        this.add(name, 'Entity', {...options, disabled: true})
        return this
    }

    addCollection(name: string, options: CollectionInterface): this {
        this.add(name, 'Collection', {...options})
        return this
    }

    getFirstChoice(choices: Choice[]):undefined|Choice {
        if (choices.length) {
            if (typeof choices[0].value === 'string') {
                return choices[0];
            }

            if (typeof choices[0].value[0].value === 'string') {
                return choices[0].value[0]
            }
        }

        return undefined
    }

    private choiceEqual (firstChoice: Choice, secondChoice: Choice): boolean {
        if (firstChoice.i18nKey !== secondChoice.i18nKey) {
            return false
        }

        if (typeof firstChoice.value !== typeof secondChoice.value) {
            return false
        }

        if (typeof firstChoice.value === 'string') {
            return firstChoice.value === secondChoice.value
        }

        return typeof firstChoice.value === 'object' && typeof secondChoice.value === 'object' && this.choicesEqual(firstChoice.value, secondChoice.value)
    }

    choicesEqual(firstChoices: Choice[], secondChoices: Choice[]){
        return firstChoices.length === secondChoices.length
            && firstChoices.every((o, idx) => this.choiceEqual(o, secondChoices[idx]))
    }
}