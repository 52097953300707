import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {
    Button, Modal,
    ModalBody as Body,
    ModalHeader as Header,
    ModalTitle as Title
} from "react-bootstrap";
import GroupedAction from "Entity/Collection/ListingSetting/GroupedAction";
import FormEmpty from "../../../Vendor/Components/FormEmpty";
import Property from "../../../Entity/Property";
import LoadingButton from "Components/Button/LoadingButton";

export interface _ArchiveStickersModalProps {
    id: number
    showActionModals: {[key: string]: boolean|Property}
    handleShowActionModal: (groupedAction?: GroupedAction, actionFromMain?:boolean) => void
    fromMain: boolean
    handleShowMainActionModal: () => void
    selected: number[]
    actionModalFormIsLoading: boolean
    actionModalFormIsLoaded: boolean,
    setActionModalFormIsLoaded: (isLoaded: boolean) => void,
    handleActionModalOnResponse: () => void
    handleActionModalOnSuccess: () => void
    handleActionModalOnSubmit: () => void
}

const _ArchiveStickersModal: FC<_ArchiveStickersModalProps> = ({
                                                 id,
                                                 showActionModals,
                                                 handleShowActionModal,
                                                 fromMain,
                                                 handleShowMainActionModal,
                                                 selected,
                                                 actionModalFormIsLoading,
                                                 actionModalFormIsLoaded,
                                                 setActionModalFormIsLoaded,
                                                 handleActionModalOnResponse,
                                                 handleActionModalOnSuccess,
                                                 handleActionModalOnSubmit,
}) => {
    const {t} = useTranslation()

    return <Modal show={showActionModals.archiveStickers !== false} onHide={handleShowActionModal}>
        <Header closeButton={!fromMain}>
            <div className="modal-block-header">
                {fromMain && <i className="fa-solid fa-angle-left" onClick={handleShowMainActionModal}></i>}
                <Title>{t(`app.groupedActionTitle.archive_stickers`, {count: selected.length})}</Title>
            </div>
        </Header>
        <Body>
            <FormEmpty
                path={`/grouped-action/${id}/sticker/archive`}
                additionalParams={{
                    items: selected,
                }}
                setIsLoaded={setActionModalFormIsLoaded}
                config={{
                    responseType: 'blob'
                }}
                onResponse={handleActionModalOnResponse}
                onSubmit={handleActionModalOnSubmit}
                onSuccess={handleActionModalOnSuccess}
            >
                <div className="row justify-content-end align-items-center mb-3">
                    <div className="col-auto">
                        {!fromMain &&
                            <Button variant="secondary" onClick={() => {
                                handleShowActionModal()
                            }}>
                                {t('app.cancel')}
                            </Button>
                        }
                    </div>
                </div>
            </FormEmpty>
        </Body>
    </Modal>
}

export default _ArchiveStickersModal
