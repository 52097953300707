type Collection = 'catalog' | 'bucket' | 'data-collection' | 'contact' | 'company' | 'deal' | 'shopping' | 'pre-order' | 'external-file'

export const getShortcut = (collection: Collection) => {
  switch (collection) {
    case 'catalog':
      return 'c'
    case 'bucket':
      return 'b'
    case 'data-collection':
      return 'd'
    case 'contact':
      return 'crm'
    case 'company':
      return 'crm'
    case 'deal':
      return 'crm'
    case 'shopping':
      return 'shopping'
    case 'pre-order':
      return 'shopping'
      default:
          return null
  }
}
export default Collection;
