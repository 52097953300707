import {RouteObject} from "react-router-dom";
import PlatformUserPage from "../Pages/PlatformUserPage";

const PlatformSettingRouting: RouteObject[] = [
    {
        path: ':slug',
        element: <PlatformUserPage />,
        children: [
            {
              path: ':id',
              element: <PlatformUserPage />,
            },
          ],
    },
]

export default PlatformSettingRouting