import React, {FC, InputHTMLAttributes, useEffect, useState} from 'react'
import { classUtils as c } from 'Vendor/Utils/ClassUtils'
import { useTranslation } from 'react-i18next'
import useTranslationDataUtils from "../../../Utils/TranslationDataUtils";
import {TranslationField} from "../../../../Components/Listing/Listing";

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
    id: string
    name: string
    fullName: string
    checked: boolean
    setValue?: (checked: boolean) => void
    label?: string
    i18nLabel?: string
    i18nLabels?: TranslationField
    className?: string
    required?: boolean
    disabled?: boolean
    checkedByDefault: boolean
}

const CheckboxType: FC<CheckboxProps> = ({
    id,
    name,
    fullName,
    checked,
    setValue,
    className,
    label,
    i18nLabel,
    i18nLabels,
    required = true,
    disabled = false,
    checkedByDefault ,
    ...rest
}) => {
    const { t } = useTranslation()
    const {translateField} = useTranslationDataUtils()
    const [textLabel] = useState<string>(label ? label : i18nLabels ? translateField(i18nLabels) : t(i18nLabel ?? `app.${name}`))

    useEffect(() => {
        if (checked === undefined) {
            setValue && setValue(checkedByDefault)
        }
    }, [setValue])

    return (
        <div className="form-check mb-3">
            <input
                id={id}
                name={fullName}
                className={c('form-check-input', className)}
                placeholder={t(i18nLabel ?? `app.${name}`)}
                required={required}
                checked={checked}
                onChange={(e) => !disabled && setValue && setValue(e.target.checked)}
                type="checkbox"
                disabled={disabled}
                {...rest}
            />
            <label className="form-check-label" htmlFor={id}>
                {textLabel}
            </label>
        </div>
    )
}

export default CheckboxType
