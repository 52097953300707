import React, {CSSProperties, ReactNode, useContext, useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'
import {classUtils as c} from 'Vendor/Utils/ClassUtils'
import {ButtonVariant} from "react-bootstrap/types";
import {Button} from "react-bootstrap";
import { UserContext } from 'Vendor/Context/UserContextProvider'
import Api from 'Vendor/Api'
import {createArrUtils} from "../../Vendor/Utils/CreateArrayUtils";
import {RequestLine, RequestLineProps} from "./Table/RequestLine";
import Pagination from "../Pagination";
import IsGrantedInterface from "Vendor/Definition/IsGrantedInterface";
import TranslationsInterface from "Vendor/Definition/TranslationsInterface";
import EntityInterface from "Vendor/Definition/EntityInterface";
import Property from "../../Entity/Property";
import {CustomAction, TableConfig} from "../Listing/Listing";

export const WAIT_INTERVAL:number = 500
export type SortWay = 'ascending' | 'decreasing'

export type Placeholder = {
    element: string
    size: number|number[]
    variant?: ButtonVariant
}
export type Placeholders = Placeholder[]

export type RowRender = (item: any) => ReactNode

export type RowType = {
    placeholders: Placeholders
    property?: Property
    field?: string
    translated?: boolean
    i18nKey?: string
    i18nPlaceholder?: string
    className?: string
    i18nLabel?: string
    translatedLabelValues?: EntityInterface
    translatedLabelField?: string
    render?: RowRender
    sortable?: boolean
    i18nPrefix?: string
    editField?: boolean
    statusField?: boolean
    fieldTranslated?:boolean
}

export type Rows = {
    [key: string]: RowType
}

export interface TableProps<DataType> {
    path: string
    rows: Rows
    items: DataType[]
    setItems: (items: DataType[]) => void
    toggleReload?: boolean|string
    defaultSort?: string
    keyword?: string
    setKeyword?: (keyword: string) => void
    customActions?: CustomAction[]
}

export type TableResponse<DataType> = {
    currentPage: number
    maxResults: number
    length: number
    token: string
    items: {[key: number]: DataType}
}

export type Item = {
    id?: number
    translations?: TranslationsInterface
    isGranted?: IsGrantedInterface
    [key: string]: any
}

type AdminTableConfig = {
    sortWay: SortWay
    sortName: string
    nbEntries: number
    token: string
}

const Table = <DataType extends Item>({
                                          path,
                                          rows,
                                          items,
                                          setItems,
                                          toggleReload = undefined,
                                          defaultSort = undefined,
                                          keyword = '',
                                          setKeyword = undefined,
                                          customActions,
                                      }: TableProps<DataType>) => {
    const defaultSortWay = 'ascending'
    const defaultSortName = defaultSort ?? Object.keys(rows).length ? Object.keys(rows)[0] : 'error'
    const defaultNbEntries = 25
    let initConfig: AdminTableConfig = {
        sortWay: defaultSortWay,
        sortName: defaultSortName,
        nbEntries: defaultNbEntries,
        token: '',
    }

    let config = localStorage.getItem('admin.' + path)
    if (config) {
        let tableConfig: AdminTableConfig = JSON.parse(config)
        if (tableConfig) {
            initConfig.sortWay = tableConfig.sortWay
            initConfig.sortName = tableConfig.sortName
            initConfig.nbEntries = tableConfig.nbEntries
        }
    }

    const { t, i18n } = useTranslation()
    const { currentUser } = useContext(UserContext)
    const [sortWay, setSortWay] = useState<SortWay>(initConfig.sortWay)
    const [sortName, setSortName] = useState(initConfig.sortName)
    const [nbEntries, setNbEntries] = useState(initConfig.nbEntries)
    const [maxResult, setMaxResult] = useState<number>(0)
    const [pages, setPages] = useState<number[]>([1])
    const [currentPage, setCurrentPage] = useState(1)
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout>()
    const [requestOnLoading, setRequestOnLoading] = useState(false)
    const [lastToken, setLastToken] = useState<string>('')

    useEffect(() => {
        let tableConfig: AdminTableConfig = {
            sortWay,
            sortName,
            nbEntries,
            token: lastToken,
        }
        localStorage.setItem('admin.' + path, JSON.stringify(tableConfig))
    }, [sortWay, sortName, nbEntries, path, lastToken])

    const clearFilters = () => {
        if (typeof setKeyword === 'function') {
            setKeyword('')
        }
    }

    const handleHeaderClick = (rowName: string) => {
        if (rowName === sortName) {
            setSortWay((sortWay) =>
                sortWay === 'ascending' ? 'decreasing' : 'ascending',
            )
        } else {
            setSortName(rowName)
            setSortWay('ascending')
        }
    }

    const requestLineProps: RequestLineProps = {
        filtered: true,
        isLoaded: !requestOnLoading,
        maxResult,
        pages,
        currentPage,
        setCurrentPage,
        nbEntries,
        setNbEntries,
        clearFilters,
    }

    useEffect(() => {
        clearInterval(intervalId)
        const requestBody = {
            keyword,
            sortWay,
            sortName,
            nbEntries,
            currentPage,
            token: '',
        }

        setRequestOnLoading(true)
        setItems([])

        const postNewReq = () => {
            let tempLastToken = Math.random().toString(36)
            setLastToken(tempLastToken)
            requestBody.token = tempLastToken
            Api.post<TableResponse<DataType>>(path, requestBody)
                .then(({data}) => {
                    let lastToken = JSON.parse(localStorage.getItem('admin.' + path) ?? '{}')?.token ?? ''
                    if (data.token !== lastToken) {
                        return
                    }

                    setRequestOnLoading(false)

                    setItems(Object.values(data.items))
                    setMaxResult(data.maxResults)
                    setNbEntries(data.length)
                    setCurrentPage(data.currentPage)
                    const pages = createArrUtils(Math.ceil(data.maxResults / nbEntries))

                    setPages(pages)
                    if (data.currentPage !== currentPage) {
                        setCurrentPage(data.currentPage)
                    }
                })
                .catch(() => {
                    setRequestOnLoading(false)
                })
        }

        setIntervalId(setTimeout(postNewReq, WAIT_INTERVAL))

        return () => {
            intervalId && clearInterval(intervalId)
        }
    }, [keyword, sortWay, sortName, nbEntries, currentPage, path, toggleReload])

    if (!Object.keys(rows).length) return <></>

    const getTranslation = (item: DataType | { translations?: TranslationsInterface }, key: string, language: string) => {
        if (item.translations?.[language]?.[key]) {
            return item.translations[language][key as keyof Item];
        }

        return null;
    };

    const getOrganizationDefaultLanguage = () => {
        if (!currentUser) {
            throw new Error('Current User not found');
        }

        if (!currentUser.currentOrganization) {
            throw new Error('Current Organization not found');
        }

        if (!currentUser.currentOrganization.defaultLanguage) {
            throw new Error('Default language not found');
        }

        return currentUser.currentOrganization.defaultLanguage;
    };

    const renderTranslation = (item: DataType | {translations?: TranslationsInterface }, key: string) => {
        const currentLanguage = i18n.language;
        let translation = getTranslation(item, key, currentLanguage);

        if (!translation) {
            const defaultLanguage = getOrganizationDefaultLanguage();
            translation = getTranslation(item, key, defaultLanguage);
            if (translation) {
                return `${translation} (${defaultLanguage})`;
            }
        }

        return translation || '';
    };


    const render = (row: RowType, name: string, item: DataType): ReactNode => {
        if (row.render) {
            return row.render(item)
        }

        if (row.translated) {
            let key = row.field !== undefined ? row.field : name;
            const translation = renderTranslation(item, key);
            if (translation) {
                return translation;
            }
        }

        if (item.hasOwnProperty(name) || (row.field && item.hasOwnProperty(row.field))) {
            let value: object | string | null | number | boolean | IsGrantedInterface | TranslationsInterface | undefined = undefined
            if (item.hasOwnProperty(name)) {
                value = item[name as keyof Item]
            } else {
                value = item[row.field as keyof Item]
            }

            if (typeof value === 'boolean') {
                return <span className={`badge bg-${value ? 'success' : 'danger'}`}>{t(`app.${value ? 'yes' : 'no'}`)}</span>
            }

            if (typeof value === 'object' && !Array.isArray(value)) {
                if (value === null) {
                    if (row.i18nPlaceholder) {
                        return <i>{t(row.i18nPlaceholder)}</i>
                    }

                    return '';
                }

                if(row.fieldTranslated && value.hasOwnProperty('translations') && 'translations' in value){
                    const newValue: Omit<Item, 'id' | 'isGranted'> = value
                    const translation = renderTranslation(newValue, row.field ?? name);
                    if (translation) {
                        return translation;
                    }
                }

                if (row.field !== undefined) {
                    let field = row.field
                    if (value.hasOwnProperty(field)) {
                        // @ts-ignore
                        if (!value[field]) {
                            if (row.i18nPlaceholder) {
                                return <i>{t(row.i18nPlaceholder)}</i>
                            }

                            return '';
                        }

                        // @ts-ignore
                        return value[field]
                    }
                }

                return <i className="text-danger">error</i>
            }
            else if (Array.isArray(value)) {
                if (value.length === 0) {
                    if (row.i18nPlaceholder) {
                        return <i>{t(row.i18nPlaceholder)}</i>
                    }

                    return ''
                }

                if (row.i18nKey) {
                    let text = '';

                    value.forEach((data, i, array) => {
                        text += t(row.i18nKey + data)
                        if (i < (array.length - 1)) {
                            text += ', '
                        }
                    })

                    return text
                }

                if (row.field !== undefined) {
                    let itemField = row.field
                    return value.slice(0, 5).map(item => {
                        return item[itemField]
                    }).join(', ') + (value.length > 5 ? ', ...' : '')

                    return ''
                }

                return value ? value.join(', ') : ''
            }

            if (typeof value === 'number') {
                return value
            }

            if (!value) {
                if (row.i18nPlaceholder) {
                    return <i>{t(row.i18nPlaceholder)}</i>
                }

                return ''
            }

            if (row.i18nKey) {
                return t(row.i18nKey + value)
            }

            if (row.i18nPrefix) {
                return t(row.i18nPrefix + '.' + value.charAt(0).toUpperCase() + value.slice(1))
            }

            return value
        }

        return (
            <i className="text-danger">
                {t('app.error')}
            </i>
        )
    }

    return (
        <div>
            <RequestLine {...requestLineProps} />
            <div className="container-fluid bg-light p-3 mb-3 rounded-4">
                <table className="table table-hover table-large">
                    <thead>
                    <tr>
                        {Object.keys(rows).map(key => (
                            <th
                                data-name={key}
                                onClick={typeof rows[key].sortable === 'undefined' || rows[key].sortable ? () => handleHeaderClick(key) : () => {}}
                                key={key}
                            >
                                {t(rows[key].i18nLabel ?? `app.${key}`)}
                                {(typeof rows[key].sortable === 'undefined' || rows[key].sortable) &&
                                    <i
                                        style={{
                                            marginLeft: 8,
                                            cursor: 'pointer',
                                        }}
                                        className={
                                            sortName === key
                                                ? sortWay === 'ascending'
                                                    ? 'fa-solid fa-sort-up'
                                                    : 'fa-solid fa-sort-down'
                                                : 'fa-light fa-sort'
                                        }
                                    ></i>
                                }
                            </th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {!requestOnLoading && items.length ? (
                        items.map((item) => (
                            <tr key={item.id}>
                                {Object.keys(rows).map(key => (
                                    <td
                                        className={rows[key].className}
                                        key={key}
                                    >
                                        {render(rows[key], key, item)}
                                    </td>
                                ))}
                            </tr>
                        ))
                    ) : (
                        requestOnLoading ? (
                                createArrUtils(nbEntries).map((row, i) => (
                                    <tr key={i}>
                                        {Object.keys(rows).map(key => (
                                            <td key={key} className={rows[key].className}>
                                                {rows[key].placeholders.map((placeholder, place_index, row) => (
                                                    <>
                                                        {placeholder.element === 'p' && (<div className="row placeholder-glow">
                                                            {typeof placeholder.size === 'object' && placeholder.size?.map((col, i, row) => (
                                                                <span key={i} className={c('placeholder', 'col-' + col, (i + 1 < row.length && 'me-2'))}></span>
                                                            ))}
                                                            {typeof placeholder.size === 'number' && (
                                                                <span key={i} className={c('placeholder', 'col-' + placeholder.size)}></span>
                                                            )}
                                                        </div>)}
                                                        {placeholder.element === 'a' && (
                                                            <Button key={i} size='sm' variant={placeholder.variant} href="#" tabIndex={-1} className={c('disabled placeholder', 'col-' + (placeholder.size ?? 5))}></Button>
                                                        )}
                                                    </>
                                                ))}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            )
                            : (
                                <tr key={0}>
                                    <td
                                        colSpan={Object.keys(rows).length}
                                        className="text-center"
                                        key={0}
                                    >
                                        <i>{t('app.no_result')}</i>
                                    </td>
                                </tr>
                            )
                    )}
                    </tbody>
                </table>
            </div>
            <Pagination
                colSpan={Object.keys(rows).length}
                currentPage={currentPage}
                pages={pages}
                setCurrentPage={setCurrentPage}
            />
        </div>
    )
}

export default Table
