import React, {FC, useContext, useEffect, useState} from "react";
import {Outlet, useParams} from "react-router-dom";
import {Navbar as GlobalNavbar, NavbarItem} from "Components/Layout/Navbar";
import Header from "Components/Layout/Header";
import {UserContext} from "../../Vendor/Context/UserContextProvider";
import Platform from "../../Entity/Platform";
import {unmountComponentAtNode} from "react-dom";
import {useTranslation} from "react-i18next";

const Navbar: FC = () => {
    const { currentUser } = useContext(UserContext)
    const [search, setSearch] = useState<string>('')
    const [show, setShow] = useState<boolean>(false)
    const [items, setItems] = useState<NavbarItem[]>([])
    const [disabled, setDisabled] = useState<boolean>(true)
    const [handleAdd, setHandleAdd] = useState<(() => void)|undefined>(undefined)
    const params = useParams()
    const [platform, setPlatform] = useState<Platform|undefined>(undefined)
    const { t } = useTranslation()

    useEffect(() => {
            let items: NavbarItem[] = [
            ]

            currentUser?.currentOrganization?.platforms?.map((platform) => {
                if (platform.slug === params.slug) {
                    setPlatform(platform)
                }
                items.push({
                    i18nLabel: platform.name,
                    path: platform.slug
                })
            })

        setItems(items)
    }
    , [currentUser, params])

    return (
            <>
                <Header title={items.length > 1 ? t('app.platform') + ' ' + platform?.name : platform?.name} search={search} setSearch={setSearch}
                        add={() => {
                            handleAdd !== undefined && handleAdd()
                            setShow(true)
                        }}
                />
                { items.length > 1 &&
                <GlobalNavbar locationBase='/platform-setting' items={items}/>
                }
                <Outlet context={[platform, show, setShow, search, setSearch, disabled, setDisabled, setHandleAdd]}/>
            </>
        )
}

export default Navbar
