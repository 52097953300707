import React, {FC, useContext, useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import Header from "Components/Layout/Header";
import Table from "Components/Layout/Table";
import DeleteModal from "../../../../Components/Modal/DeleteModal";
import {Button} from "react-bootstrap";
import FormEmptyOffCanvas from "../../../../Components/Modal/FormEmptyOffCanvas";
import Group from "../../../../Entity/File/Group";
import ProductGroupTable from "../../../../Table/Admin/Edition/Security/ProductGroupTable";
import {useOutletContext} from "react-router-dom";
import Catalog from "../../../../Entity/Catalog";
import FileGroupTable from "../../../../Table/Admin/Edition/Bucket/FileGroupTable";

const FileGroupPage: FC = () => {
    const { t } = useTranslation()

    const [toggleReload, setToggleReload] = useState<boolean>(true)
    const [bucket, setBucket, show, setShow, search, setSearch, setHandleAdd] = useOutletContext<[Catalog, (catalog: Catalog) => void, boolean, (show:boolean) => void, string, (search:string) => void, (handleAdd: (() => void)|undefined) => void]>()
    const [fileGroups, setFileGroups] = useState<Group[]>([])
    const [fileGroup, setFileGroup] = useState<Group>({} as Group)
    const handleClose = (reload = false) => {
        setShow(false)
        if (reload) {
            setToggleReload(!toggleReload)
        }
    }
    const handleShow = () => setShow(true)
    // remove
    const [showDelete, setShowDelete] = useState<boolean>(false)
    const handleDeleteClose = (reload = false) => {
        setFileGroup({} as Group)
        setShowDelete(false)
        if (reload) {
            setToggleReload(!toggleReload)
        }
    }
    const handleDeleteShow = () => setShowDelete(true)

    const onSuccess = (fileGroup: Group) => {
        setFileGroup(fileGroup)
        setShow(false)
        setToggleReload(!toggleReload)
    }

    const editFileGroup = (fileGroup: Group) => {
        setFileGroup(fileGroup)
        handleShow()
    }

    const deleteFileGroup = (fileGroup: Group) => {
        setFileGroup(fileGroup)
        handleDeleteShow()
    }

    const actionsRender = (fileGroup: Group) => (
        <>
            {fileGroup?.isGranted?.edit &&
                <Button
                    variant='outline-primary'
                    size='sm'
                    onClick={() => editFileGroup(fileGroup)}>
                    <i className="fa-solid fa-pencil me-2"></i>
                    {t('app.edit')}</Button>
            }
            {fileGroup?.isGranted?.delete &&
                <Button
                    variant='outline-danger'
                    size='sm'
                    onClick={() => deleteFileGroup(fileGroup)}>
                    <i className="fa-solid fa-trash me-2"></i>
                    {t('app.delete')}</Button>
            }
        </>
    );

    let table = FileGroupTable
    if (table.hasOwnProperty('actions')) {
        table.actions.render = actionsRender;
    }

    return (
        <>
            <Table
                path={`/admin/edition/bucket/${bucket.id}/file-group`}
                rows={table}
                toggleReload={toggleReload}
                items={fileGroups}
                setItems={setFileGroups}
                keyword={search}
                setKeyword={setSearch}
            />
            <FormEmptyOffCanvas
                i18nTitle={fileGroup.id ? 'app.edit_file_group' : 'app.add_file_group'}
                show={show}
                handleClose={handleClose}
                onSuccess={onSuccess}
                path={`/admin/edition/bucket/${bucket.id}/file-group/${fileGroup.id ?? 'new'}`}
                formPath={!!fileGroup.id}
                method={fileGroup.id ? 'patch' : 'post'}
            />
            <DeleteModal
                handleClose={handleDeleteClose}
                show={showDelete}
                path={`/admin/edition/bucket/${bucket.id}/file-group/${fileGroup?.id ?? 'error'}`}
                name={'#' + (fileGroup ? `${fileGroup.id} - ${fileGroup.name}` : 'error')}
                archive={true}
            />
        </>
    )
}

export default FileGroupPage
