import React, {FC} from 'react'
import {Button} from "react-bootstrap";
import View from "Config/View";

export interface _ViewsProps {
    views: View[],
    view: View
    setView: (view: View) => void
    condensed: boolean
    setCondensed: (condensed: boolean) => void
}

type viewIconsType = {
    [key in View]: string;
};

const viewIcons: viewIconsType = {
    //list: 'bars',
    table: 'table-list',
    card: 'grip',
    kanban: 'bars fa-rotate-90',
}

const _Views: FC<_ViewsProps> = ({views, view: currentView, setView: setCurrentView, condensed, setCondensed}) => {
    return (
        <>
            {views.length > 1 && <div className="col-auto">
                <div className="btn-group" role="group" aria-label="views">
                    {views.map((view) => (
                        <Button variant={view === currentView ? 'primary' : 'light'} onClick={() => {
                            view !== currentView && setCurrentView(view)
                        }}>
                            <i className={`fa-solid fa-${viewIcons[view]}`}></i>
                        </Button>
                    ))}
                </div>
            </div>}
            {['card', 'kanban'].includes(currentView) &&
                <div className="col-auto">
                    <div className="btn-group" role="group" aria-label="condensed">
                        <Button key={0} variant={!condensed ? 'primary' : 'light'} onClick={() => {
                            condensed && setCondensed(false)
                        }}><i className="fa-solid fa-maximize"></i></Button>
                        <Button key={1} variant={condensed ? 'primary' : 'light'} onClick={() => {
                            !condensed && setCondensed(true)
                        }}><i className="fa-solid fa-minimize"></i></Button>
                    </div>
                </div>
            }
        </>
    )
}

export default _Views
