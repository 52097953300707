import React, {FC} from 'react'
import useTranslationDataUtils from "Vendor/Utils/TranslationDataUtils";
import Bucket from "Entity/Bucket";
import File from "Entity/File";
import Loader from "Vendor/Components/Loader";
import _Card from "../../Listing/View/_Card";
import FileDropzone from "../../FileDropzone";
import Product from "../../../Entity/Product";
import {mimeTypeImage} from "Vendor/UploadSystem";
import ListingSetting from "../../../Entity/Collection/ListingSetting";

const _ProductFileListing: FC<{
    bucket: Bucket
    product: Product
    action: () => void
    files?: File[]
    setFiles: (bucket: string, files: File[]) => void
    setMainVisual: (file: File) => void
    onChangeMainVisual: boolean
    canEdit: boolean
    disableChangeMainVisual: boolean
}> = ({
    bucket,
    product,
    action,
    files,
    setFiles,
    setMainVisual,
    onChangeMainVisual,
    canEdit,
    disableChangeMainVisual
}) => {
    const {translate} = useTranslationDataUtils()
    const setting: ListingSetting = {
        cardCondensedByDefault: false,
        cardShowBadge: false,
        cardShowStatus: false,
        cardShowSchedule: true,
        cardShowSizeAndWeight: null,
        filters: [],
        groupedActions: [],
        kanbanDatas: [],
        cardDatas: [],
        searchable: [],
        searchableByEmail: false,
        searchableByFirstName: false,
        searchableByLastName: false,
        searchableByName: false,
        sorters: [],
        tableColumns: [],
        views: [],
    }

    return <div className="mb-3">
        <div className="row justify-content-between align-items-center">
            <div className="col-auto"><h3 className="mb-0">{translate(bucket, 'name')}</h3></div>
            <div className="col-auto">
                {!bucket.simpleImport &&
                    <button type="button" className="btn btn-outline-primary btn-icon" onClick={action} disabled={!canEdit}>
                        <i className="fa-solid fa-plus"></i>
                    </button>
                }
            </div>
        </div>
        {!bucket.simpleImport
            ? <div className="row mini-listing card-list card-list-condensed">
                {files === undefined
                    ? <Loader auto={true} />
                    : files.map((file, key) =>
                        <_Card item={file}
                               key={key}
                               selectable={false}
                               condensed={true}
                               entity="bucket"
                               isMain={!bucket.simpleImport && mimeTypeImage.includes(file.mimeType) ? product.mainVisual === file.id : undefined}
                               setMain={() => {
                                   file.id && setMainVisual(file)
                               }}
                               isMainLoading={onChangeMainVisual} setting={setting}
                               selectMultiple={true}
                               disableChangeMainVisual={disableChangeMainVisual}
                        />
                    )
                }
            </div>
            : <FileDropzone bucket={bucket} product={product} files={files ?? []} setFiles={(files: File[]) => {
                setFiles(bucket.primarySlug, files)
            }} />
        }
    </div>
}

export default _ProductFileListing
