import React, {FC, useContext, useState} from "react";
import BlankPage from "../../../../Components/Layout/BlankPage";
import {useOutletContext} from "react-router-dom";
import Bucket from "../../../../Entity/Bucket";
import ListingSettingEntity from 'Entity/Collection/ListingSetting';
import LoadingButton from "../../../../Components/Button/LoadingButton";
import FormEmpty from "../../../../Vendor/Components/FormEmpty";
import {useTranslation} from "react-i18next";
import {BannerContext} from "../../../../Vendor/Context/BannerContextProvider";
import User from "../../../../Entity/User";
import {UserContext} from "../../../../Vendor/Context/UserContextProvider";

const ListingPage: FC = () => {
    const { setCurrentUser } = useContext(UserContext);
    const [bucket, setBucket] = useOutletContext<[Bucket, (bucket: Bucket) => void]>()
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)
    const { setBanner } = useContext(BannerContext);

    const setListing = (listing: ListingSettingEntity) => {
        let tempBucket = {...bucket}
        tempBucket.listingSetting = listing

        setBucket(tempBucket)
    }

    const onSubmit = () => {
        setIsLoading(true)
    }
    const onResponse = () => {
        setIsLoading(false)
    }
    const onSuccess = (data: User) => {
        setCurrentUser(data)
        setBanner({type: 'success', i18nTitle: 'app.save', i18nMessage: 'app.data_saved_message'})
    }
    const onError = () => {
        setSuccess(false)
    }

    return <BlankPage>
        <FormEmpty
            path={"/admin/edition/collection/listing-setting/bucket/" + bucket.id}
            formPath={!!bucket.id}
            children={<div className="row justify-content-end align-items-center">
                <div className="col-auto">
                    <LoadingButton type="submit" isLoading={isLoading}>
                        {t( 'app.validate' )}
                    </LoadingButton>
                </div>
        </div>}
            method={bucket.id ? 'PATCH' : 'POST'}
            onSubmit={onSubmit}
            onResponse={onResponse}
            onSuccess={onSuccess}
            onError={onError}
        />
    </BlankPage>
}

export default ListingPage
