import {Rows} from "Components/Layout/Table";
import CollectionEvent from "Entity/Notification/Collection/CollectionEvent";
import PlatformEvent from "Entity/Notification/Platform/PlatformEvent";
import ShoppingEvent from "Entity/Notification/Shopping/ShoppingEvent";

type NotificationEventTypes = CollectionEvent|PlatformEvent|ShoppingEvent

const NotificationTable: Rows = {
    id: {
        placeholders: [
            {
                element: 'p',
                size: 1
            }
        ]
    },
    status: {
        translated: true,
        i18nKey: 'app.item_status.',
        placeholders: [
            {
                element: 'p',
                size: [2],
            }
        ]
    },
    target: {
        placeholders: [
            {
                element: 'p',
                size: [3],
            }
        ]
    },
    event: {
        placeholders: [
            {
                element: 'p',
                size: [3],
            }
        ]
    },
    notifications: {
        render: (notificationEvent: NotificationEventTypes) => {
            return notificationEvent.notifications.length
        },
        placeholders: [
            {
                element: 'p',
                size: [1],
            }
        ]
    },
    actions: {
        className: 'actions',
        sortable: false,
        placeholders: [
            {
                element: 'a',
                size: 2,
            },
        ]
    },
};

export default NotificationTable
