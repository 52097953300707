import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import resources from 'Translations/resources'
import LanguageDetector from 'i18next-browser-languagedetector';

export const languages = Object.keys(resources)
export const defaultLanguage = 'fr'

i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        fallbackLng: defaultLanguage,
        debug: false,//process.env.NODE_ENV === 'development',
        resources,
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    }).then(r => {})

export default i18n