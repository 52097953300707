import {MenuItem} from "Components/Layout/Sidebar/_MenuItem";

const _EditionMenu: MenuItem[] = [
    /*{
        to: '/admin/edition',
        className: 'fa-solid fa-building',
        label: 'Dashboard',
        index: true
    },*/
    {
        to: '/admin/edition/bucket',
        className: 'fa-solid fa-bucket',
        label: 'app.buckets',
    },
    {
        to: '/admin/edition/catalog',
        className: 'fa-solid fa-book-open',
        label: 'app.catalogs',
    },
    {
        to: '/admin/edition/data-collection',
        className: 'fa-solid fa-database',
        label: 'app.data_collections',
    },
    {
        to: '/admin/edition/brand',
        className: 'fa-solid fa-copyright',
        label: 'app.brands',
    },
    {
        to: '/admin/edition/platform',
        className: 'fa-solid fa-sitemap',
        label: 'app.platforms',
    },
    {
        to: '/admin/edition/property',
        className: 'fa-regular fa-circle-nodes',
        label: 'app.properties',
    },
    {
        to: '/admin/edition/user',
        className: 'fa-solid fa-user',
        label: 'app.users',
    },
    {
        to: '/admin/edition/reporting',
        className: 'fa-solid fa-flag',
        label: 'app.reporting',
    },
    {
        to: '/admin/edition/security-rule',
        className: 'fa-solid fa-lock',
        label: 'app.platform_security',
    },
    {
        baseTo: '/admin/edition/crm',
        to: '/admin/edition/crm/contact-group',
        className: 'fa-solid fa-puzzle-piece',
        label: 'app.crm_groups',
    },
    {
        to: '/admin/edition/security',
        className: 'fa-solid fa-shield',
        label: 'app.security',
        index:true,
    },
    {
        to: '/admin/edition/mailing-identity/email',
        baseTo: '/admin/edition/mailing-identity',
        className: 'fa-solid fa-envelope',
        label: 'app.mailing_identity.title',
    },
    {
        to: '/admin/edition/notification/collection',
        className: 'fa-solid fa-envelope',
        label: 'app.notification_types.collection',
    },
    {
        to: '/admin/edition/notification/shopping',
        className: 'fa-solid fa-envelope',
        label: 'app.notification_types.shopping',
    },
    {
        to: '/admin/edition/notification/platform',
        className: 'fa-solid fa-envelope',
        label: 'app.notification_types.platform',
    },
    {
        to: '/admin/edition/notification/mention',
        className: 'fa-solid fa-envelope',
        label: 'app.notification_types.mention',
    }
]

export default _EditionMenu
