import React, {FC, ReactNode, useEffect, useMemo, useState} from 'react'
import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'
import { useTranslation } from 'react-i18next'

export type Banner = {
    i18nTitle?: string
    i18nMessage?: string
    type?: 'error' | 'success'
}

export type BannerContextType = {
    banner?: Banner
    setBanner: (banner: Banner) => void
}

export const BannerContext = React.createContext<BannerContextType>({
    banner: undefined,
    setBanner: () => {},
})


const BannerContextProvider: FC<{ children: ReactNode }> = ({children}) => {
    const { t } = useTranslation()
    const [banner, setBanner] = useState<undefined|Banner>(undefined)

    const context = useMemo(
        () => ({ banner, setBanner }),
        [banner],
    )

    useEffect(() => {
        if (banner) {
            const timer = setTimeout(() => {
                setBanner(undefined)
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [banner]);

    return (
        <BannerContext.Provider value={context}>
            <ToastContainer position="top-end" containerPosition="fixed" className="p-3">
                {banner && (
                    <>
                        <Toast
                            onClose={() => setBanner(undefined)}
                            bg={banner.type === 'success' ? 'success' : 'danger'}
                            animation
                            show={!!banner}
                        >
                            {/*<Toast.Header>*/}
                            {/*    /!*<img*/}
                            {/*        src="holder.js/20x20?text=%20"*/}
                            {/*        className="rounded me-2"*/}
                            {/*        alt=""*/}
                            {/*    />*!/*/}
                            {/*    <strong className="me-auto">*/}
                            {/*        /!*{t( banner.i18nTitle ?? `app.${banner.type}`)}*!/*/}
                            {/*    </strong>*/}
                            {/*    <small className="text-muted"></small>*/}
                            {/*</Toast.Header>*/}
                            <Toast.Body>
                                <button
                                    type="button"
                                    className="btn-close float-end pt-0"
                                    data-bs-dismiss="toast"
                                    aria-label="Close"
                                    onClick={() => setBanner(undefined)}
                                ></button>
                                {t(banner.i18nMessage ?? `app.${banner.type}_message`)}
                            </Toast.Body>
                        </Toast>
                    </>
                )}
            </ToastContainer>
            {children}
        </BannerContext.Provider>
    )
}

export default BannerContextProvider
