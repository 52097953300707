import React, {FC, useContext, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {UserContext} from 'Vendor/Context/UserContextProvider'
import {useTranslation} from "react-i18next";
import Header from "Components/Layout/Header";
import Table, {RowRender} from "Components/Layout/Table";
import BucketTable from "../../../Table/Admin/Edition/BucketTable";
import Bucket from "../../../Entity/Bucket";
import BucketForm from "../../../Form/Admin/Edition/BucketForm";
import FormRow from "Vendor/Components/Form/FormRow";
import FormError from "../../../Vendor/Components/Form/FormError";
import DeleteModal from "../../../Components/Modal/DeleteModal";
import FormOffCanvas from "../../../Components/Modal/FormOffCanvas";
import {Button} from "react-bootstrap";
import {mimeTypeImage} from "Vendor/UploadSystem";
import FormEmptyOffCanvas from "../../../Components/Modal/FormEmptyOffCanvas";
import Api from "../../../Vendor/Api";
import LoadingButton from "../../../Components/Button/LoadingButton";


const BucketPage: FC = () => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const [toggleReload, setToggleReload] = useState<boolean>(true)
    const [show, setShow] = useState<boolean>(false)
    const [search, setSearch] = useState<string>('')
    const [buckets, setBuckets] = useState<Bucket[]>([])
    const [bucket, setBucket] = useState<Bucket>({} as Bucket)
    const [detailPanelShow, setDetailPanelShow] = useState<boolean>(false)
    const [detailShow, setDetailShow] = useState<boolean>(false)
    const [reindexLoader, setReindexLoader] = useState<undefined|number>(undefined)
    const [reIndexSuccess, setReIndexSuccess] = useState<undefined|number>(undefined)
    const handleClose = (reload = false) => {
        setShow(false)
        if (reload) {
            setToggleReload(!toggleReload)
        }
    }
    const handleShow = () => setShow(true)
    // remove
    const [showDelete, setShowDelete] = useState<boolean>(false)
    const handleDeleteClose = (reload = false) => {
        setBucket({} as Bucket)
        setShowDelete(false)
        if (reload) {
            setToggleReload(!toggleReload)
        }
    }
    const handleDeleteShow = () => setShowDelete(true)

    const onSuccess = (bucket: Bucket) => {
        setBucket(bucket)
        setShow(false)
        setToggleReload(!toggleReload)
    }

    const addBucket = () => {
        setBucket({} as Bucket)
        handleShow()
    }

    const editBucket = (bucket: Bucket) => {
        navigate(bucket.id?.toString() ?? '', {state: bucket})
    }

    const reindexBucket = (bucketId: number) => {
        setReindexLoader(bucketId)
        Api.patch('/admin/edition/bucket/' + bucketId + '/reindex')
            .then(() => {
                setReIndexSuccess(bucketId)
            }).catch(() => {
            }).then(() => {
                setReindexLoader(undefined)
            })
    }

    const deleteBucket = (bucket: Bucket) => {
        setBucket(bucket)
        handleDeleteShow()
    }

    const actionsRender: RowRender = (bucket: Bucket) => (
        <>
            {bucket?.isGranted?.edit &&
                <>
                    <Button
                    variant='outline-primary'
                    size='sm'
                    onClick={() => editBucket(bucket)}>
                        <i className="fa-solid fa-pencil me-2"></i>
                        {t('app.edit')}
                    </Button>
                    <LoadingButton variant='outline-info'
                                   isLoading={reindexLoader === bucket.id}
                                   success={reIndexSuccess === bucket.id}
                                   setSuccess={(success) => setReIndexSuccess(success ? bucket.id : undefined)}
                            size='sm'
                            onClick={() => {
                                reindexBucket(bucket.id ?? 0)
                            }}>
                        <i className="fa-solid fa-cloud-word me-2"></i>
                        {t('app.reindex')}
                    </LoadingButton>
                </>
            }
            {bucket?.isGranted?.delete &&
                <Button
                    variant='outline-danger'
                    size='sm'
                    onClick={() => deleteBucket(bucket)}>
                    <i className="fa-solid fa-trash me-2"></i>
                    {t('app.delete')}</Button>
            }
        </>
    );

    const iconRender: RowRender = (bucket: Bucket) => <i className={`fa-solid fa-${bucket.icon ?? 'file'}`}/>

    let table = BucketTable;
    if (table.hasOwnProperty('icon')) {
        table.icon.render = iconRender;
    }
    if (table.hasOwnProperty('actions')) {
        table.actions.render = actionsRender;
    }

    return (
        <>
            <Header title={t('app.buckets')}
                    add={addBucket}
                    search={search}
                    setSearch={setSearch}
            />
            <Table
                path="/admin/edition/bucket"
                rows={table}
                toggleReload={toggleReload}
                items={buckets}
                setItems={setBuckets}
                keyword={search}
                setKeyword={setSearch}
            />
            <FormEmptyOffCanvas
                i18nTitle={bucket.id ? 'app.edit_bucket' : 'app.add_bucket'}
                show={show}
                handleClose={handleClose}
                onSuccess={onSuccess}
                path={`/admin/edition/bucket/${bucket.id ?? 'new'}`}
                formPath={!!bucket.id}
                method={bucket.id ? 'patch' : 'post'}
            />
            <DeleteModal
                handleClose={handleDeleteClose}
                show={showDelete}
                path={'/admin/edition/bucket/' + (bucket?.id ?? 'error')}
                name={'#' + (bucket ? `${bucket.id} - ${bucket.translations}` : 'error')}
                archive={true}
            />
        </>
    )
}

export default BucketPage
