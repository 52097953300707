import React, {FC, useEffect} from 'react'
import Filter from "Entity/Collection/ListingSetting/Filter";
import _Filter from "./Filter/_Filter";
import {OptionsType, FilterValues, GlobalFilterValues, hasFilters, FilterValue} from "./Listing";
import {Button} from "react-bootstrap";
import useTranslationDataUtils from "../../Vendor/Utils/TranslationDataUtils";
import {DataType} from "../../Vendor/Components/Form";
import {useTranslation} from "react-i18next";
import Collection from "../../Config/Collection";

export interface _FiltersProps {
    entity?: Collection
    id?: number
    filters: Filter[]
    options: OptionsType | undefined
    globalFilterValues: GlobalFilterValues
    setGlobalFilterValues: (globalFilterValues: GlobalFilterValues) => void
    setNewSearch: (criteria: DataType) => void
}

const _Filters: FC<_FiltersProps> = ({entity, id, filters, options, globalFilterValues, setGlobalFilterValues, setNewSearch}) => {
    const {translate, translateField} = useTranslationDataUtils()
    const {i18n, t} = useTranslation()

    useEffect(() => {
        let tempGlobalFilterValues = {...globalFilterValues}

        for (const [key] of Object.keys(globalFilterValues)) {
            if (!filters.hasOwnProperty(key)) {
                delete tempGlobalFilterValues[key]
            }
        }

        filters.forEach(filter => {
            if (!tempGlobalFilterValues.hasOwnProperty(filter.slug)) {
                tempGlobalFilterValues[filter.slug] = {} as FilterValues
            }
        })

        setGlobalFilterValues(tempGlobalFilterValues)
    }, [filters, setGlobalFilterValues])

    return <>
        <div className="row" key={0}>
            {filters.map((filter, filterKey) => {
                if (
                    (options
                        && options.hasOwnProperty(filter.slug)
                        && (
                            filter.property
                            && filter.property.hasOwnProperty('type')
                            && 'date' === filter.property.type
                            && (!options[filter.slug].hasOwnProperty('min') || !options[filter.slug].min)
                            && (!options[filter.slug].hasOwnProperty('max') || !options[filter.slug].max)
                        )
                    )
                ) {
                    return ''
                }

                return (<_Filter
                        entity={entity}
                        id={id}
                        filter={filter}
                        key={filterKey}
                        choices={options && options.hasOwnProperty(filter.slug) ? options[filter.slug].choices : undefined}
                        filterValues={globalFilterValues[filter.slug]}
                        setFilterValues={(filterValues: FilterValues) => {
                            let tempGlobalFilterValues = {...globalFilterValues}
                            tempGlobalFilterValues[filter.slug] = filterValues
                            setGlobalFilterValues(tempGlobalFilterValues)
                        }}
                        setNewSearch={setNewSearch}
                    />
                )
            })}
        </div>
        {hasFilters(globalFilterValues) &&
            <div className="row" key={1}>
                {Object.keys(globalFilterValues).map((slug, index, keys) =>
                    (
                        <>
                            {Object.keys(globalFilterValues[slug]).map((filterName, filterIndex) => {
                                let key = globalFilterValues[slug][filterName] as FilterValue
                                const filter = filters.find(filter => filter.slug === slug)

                                if (filter && (filter.filters === 'createdAt' || filter.filters === 'updatedAt' || filter.filters === 'designedDelivery' || filter.filters === 'plannedReturn' || (filter.property && ['date', 'number', 'price'].includes(filter.property.type)))) {
                                    const filterValue = globalFilterValues[slug][filterName];
                                    let formattedFilterValue = filterValue;

                                    let prefix: string = ''
                                    if (filter.filters === 'createdAt') {
                                        if (filterName === 'min') {
                                            prefix = t('app.created_from')
                                        } else if (filterName === 'max') {
                                            prefix = t('app.created_from')
                                        }
                                    } else if (filter.filters === 'updatedAt') {
                                        if (filterName === 'min') {
                                            prefix = t('app.updated_from')
                                        } else if (filterName === 'max') {
                                            prefix = t('app.updated_to')
                                        }
                                    } else if (filter.filters === 'designedDelivery') {
                                        if (filterName === 'min') {
                                            prefix = t('app.designed_delivery_from')
                                        } else if (filterName === 'max') {
                                            prefix = t('app.designed_delivery_to')
                                        }
                                    } else if (filter.filters === 'plannedReturn') {
                                        if (filterName === 'min') {
                                            prefix = t('app.planned_return_from')
                                        } else if (filterName === 'max') {
                                            prefix = t('app.planned_return_to')
                                        }
                                    } else if (filter.property && filter.property.type === 'date') {
                                        prefix = translate(filter.property, 'label') + ', '
                                        if (filterName === 'min') {
                                            prefix += t('app.date_from')
                                        } else if (filterName === 'max') {
                                            prefix += t('app.date_to')
                                        }
                                    } else if (filter.property && filter.property.type === 'number') {
                                        prefix = translate(filter.property, 'label') + ', '
                                        if (filterName === 'min') {
                                            prefix += t('app.price_from')
                                        } else if (filterName === 'max') {
                                            prefix += t('app.price_to')
                                        }

                                        if (typeof formattedFilterValue === "string") {
                                            let formater = new Intl.NumberFormat(i18n.language, {style: "decimal"})
                                            formattedFilterValue = formater.format(parseInt(formattedFilterValue, 10))
                                        }
                                    } else if (filter.property && filter.property.type === 'price') {
                                        prefix = translate(filter.property, 'label') + ' '
                                        if (filterName === 'min') {
                                            prefix += t('app.price_from')
                                        } else if (filterName === 'max') {
                                            prefix += t('app.price_to')
                                        }

                                        if (typeof formattedFilterValue === "string") {
                                            let formater = new Intl.NumberFormat(i18n.language, {style:"currency", currency:filter.property.currency})
                                            formattedFilterValue = formater.format(parseInt(formattedFilterValue, 10))
                                        }
                                    }

                                    return (
                                        <div className="col-auto" key={slug + '_' + filterIndex}>
                                            <Button
                                                key={filterIndex}
                                                variant="secondary"
                                                onClick={() => {
                                                    let tempGlobalFilterValues = {...globalFilterValues};
                                                    delete tempGlobalFilterValues[slug][filterName];
                                                    setGlobalFilterValues(tempGlobalFilterValues);
                                                }}
                                            >
                                                <>
                                                    {prefix + ' ' + formattedFilterValue}
                                                    <i className="fa-solid fa-x ms-2"></i>
                                                </>
                                            </Button>
                                        </div>
                                    );
                                } else if (filter && filter.filters !== 'archived') {
                                    return (
                                        <div className="col-auto" key={slug + '_' + filterIndex}>
                                            <Button key={filterIndex} variant="secondary"
                                                    onClick={() => {
                                                        let tempGlobalFilterValues = {...globalFilterValues}
                                                        delete tempGlobalFilterValues[slug][filterName]
                                                        setGlobalFilterValues(tempGlobalFilterValues)
                                                    }}
                                            >
                                                <>
                                                    {typeof key === 'string' ? globalFilterValues[slug][filterName] : translateField(key, ['label', 'name'])}
                                                    <i className="fa-solid fa-x ms-2"></i>
                                                </>
                                            </Button>
                                        </div>
                                    )
                                }
                                return <></>
                            })}
                        </>
                    )
                )}
            </div>
        }
    </>
}

export default _Filters
