import React, {FC, useContext, useState} from 'react'
import {useTranslation} from "react-i18next";
import BlankPage from "Components/Layout/BlankPage";
import LoadingButton from "Components/Button/LoadingButton";
import {BannerContext} from "Vendor/Context/BannerContextProvider";
import {UserContext} from "Vendor/Context/UserContextProvider";
import User from "Entity/User";
import FormEmpty from "Vendor/Components/FormEmpty";
import ShoppingType from 'Config/Organization/Setting/ShoppingType';

const ShoppingPropertiesPage: FC<{shoppingType: ShoppingType}> = ({ shoppingType }) => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const { currentUser, setCurrentUser } = useContext(UserContext);
    const [success, setSuccess] = useState<boolean>(false)
    const { setBanner } = useContext(BannerContext);

    const onSubmit = () => {
        setIsLoading(true)
    }
    const onResponse = () => {
        setIsLoading(false)
    }
    const onSuccess = (data: User) => {
        setSuccess(true)
        setBanner({type: 'success', i18nTitle: 'app.save', i18nMessage: 'app.data_saved_message'})
        setCurrentUser(data)
    }
    const onError = () => {
        setSuccess(false)
    }

    if (!currentUser || !currentUser.currentOrganization) return <></>

    return (
        <BlankPage>
            <FormEmpty
                path={`/admin/edition/setting/shopping/properties/${shoppingType}`}
                formPath={!!currentUser?.currentOrganization?.id}
                children={<div className="row justify-content-end align-items-center">
                    <div className="col-auto">
                        <LoadingButton type="submit" isLoading={isLoading}>
                            {t( 'app.validate' )}
                        </LoadingButton>
                    </div>
                </div>}
                method={currentUser?.currentOrganization?.id ? 'PATCH' : 'POST'}
                onSuccess={onSuccess}
                onSubmit={onSubmit}
                onResponse={onResponse}
                onError={onError}
            />
        </BlankPage>
    )
}

export default ShoppingPropertiesPage
