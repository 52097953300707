import { useContext } from 'react';
import { ListingContext, ListingState } from 'Vendor/Context/ListingContextProvider';


export const useListingContext = (mode: 'default' | 'modal' = 'default'): ListingState => {
    const context = useContext(ListingContext);

    if (!context) {
        throw new Error('useListingContext must be used within a ListingContextProvider');
    }

    return context[mode];
};
