import {RouteObject} from "react-router-dom";
import React from "react";
import {DashboardPage} from "Pages/DashboardPage";
import CrmRouting from "./CrmRouting";
import _Navbar from "Pages/Crm/_Navbar";
import BucketRouting from "./BucketRouting";
import CatalogRouting from "./CatalogRouting";
import DataCollectionRouting from "./DataCollectionRouting";
import {BrandedRoute} from "../Components/Route/BrandedRoute";
import BimboSharePage from "../Pages/BimboSharePage";
import MailingPage from "Pages/MailingPage";
import UserPage from "Pages/UserPage";
import ReportingPage from "Pages/ReportingPage";
import EditionPage from "Pages/EditionPage";
import ShoppingPage from "../Pages/ShoppingPage";
import PreOrderPage from "../Pages/PreOrderPage";
import Navbar from "../Pages/Platform/Navbar";
import PlatformSettingRouting from "./PlatformSettingRouting";
import InAppNotifPage from "../Pages/Admin/Edition/Notification/InAppNotifPage";

const ExternalRouting: RouteObject[] = [
    {
        index: true,
        element: <DashboardPage />,
    },
    {
        path: 'crm',
        element: <_Navbar />,
        children: CrmRouting,
    },
    {
        path: 'edition',
        element: <EditionPage />,
    },
    {
        path: 'bimbo-share',
        element: <BimboSharePage />,
    },
    {
        path: 'mailing',
        element: <MailingPage />,
    },
    {
        path: 'user',
        element: <UserPage />,
    },
    {
        path: 'reporting',
        element: <ReportingPage />,
    },
    {
        path: 'b/:slug',
        element: <BrandedRoute path="b" param="slug" />,
        children: BucketRouting,
    },
    {
        path: 'c/:slug',
        element: <BrandedRoute path="c" param="slug" />,
        children: CatalogRouting,
    },
    {
        path: 'd/:slug',
        element: <BrandedRoute path="d" param="slug" />,
        children: DataCollectionRouting,
    },
    {
        path: 'platform-setting',
        element: <Navbar />,
        children: PlatformSettingRouting,
    },
    {
        path: 'shopping',
        element: <ShoppingPage />,
    },
    {
        path: 'shopping/:id',
        element: <ShoppingPage />,
    },
    {
        path: 'pre-order',
        element: <PreOrderPage />,
    },
    {
        path: 'pre-order/:id',
        element: <PreOrderPage />,
    },
    {
        path: 'notifications',
        element: <InAppNotifPage />,
    },
]

export default ExternalRouting
