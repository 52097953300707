import {RouteObject} from "react-router-dom";
import React from "react";
import ContactPage from "../Pages/Crm/ContactPage";
import CompanyPage from "../Pages/Crm/CompanyPage";
import DealPage from "../Pages/Crm/DealPage";

const CrmRouting: RouteObject[] = [
    {
        path: 'contact',
        element: <ContactPage />,
    },
    {
        path: 'company',
        element: <CompanyPage />,
    },
    {
        path: 'deal',
        element: <DealPage />,
    },
]

export default CrmRouting