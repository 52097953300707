import { AnimatePresence } from 'framer-motion'
import React, { FC, useContext, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { PublicModal } from 'Components/Modal/PublicModal'
import { Motion } from 'Components/Motion'
import { UserContext } from 'Vendor/Context/UserContextProvider'

export const PublicRoute: FC = () => {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const { currentUser } = useContext(UserContext)
    const [isGranted, setIsGranted] = useState(false)

    useEffect(() => {
        if (!currentUser) {
            setIsGranted(true)
        } else {
            navigate(currentUser.access === 'external' ? '/' : '/admin')
        }
    }, [currentUser, navigate])

    return isGranted ? (
        <AnimatePresence mode="wait" initial key={pathname + 'publicRoutes'}>
            <PublicModal>
                <Motion>
                    <Outlet />
                </Motion>
            </PublicModal>
        </AnimatePresence>
    ) : null
}
