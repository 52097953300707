import React, {FC, FormEvent, useContext, useEffect, useState} from 'react'
import {Button, Modal, ModalFooter as Footer} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'
import SearchType from "Vendor/Components/Form/Type/SearchType";
import EntityInterface from "Vendor/Definition/EntityInterface";
import Collection from "../../Config/Collection";
import Listing from "../Listing/Listing";
import ListingSetting from "Entity/Collection/ListingSetting";
import DataCollection from "../../Entity/DataCollection";
import Catalog from "../../Entity/Catalog";
import Bucket from "../../Entity/Bucket";
import {UserContext} from "../../Vendor/Context/UserContextProvider";

export interface ChooseItemsModalDataProps {
    show: boolean
    title: string
    entity?: Collection
    primarySlug?: string
    selected: EntityInterface[],
    path: string
    multiple?: boolean
    hasSearch?: boolean
    toggleReload?: string
}
export type ChooseItemsModalProps = ChooseItemsModalDataProps & {
    setShow: (show: boolean) => void
    setSelected: (selected: EntityInterface[]) => void
    refreshModalList?: () => void
    addModalShow?: () => void
    firstSearch?: string
}

const ChooseItemsModal: FC<ChooseItemsModalProps> = ({
    show,
    setShow,
    title,
    entity,
    primarySlug,
    selected,
    setSelected,
    path,
    refreshModalList,
    addModalShow,
    multiple = true,
    hasSearch = true,
    toggleReload,
    firstSearch,
}) => {
    const { t } = useTranslation()
    const { currentUser } = useContext(UserContext)
    const [search, setSearch] = useState<string>(firstSearch ?? '')
    const [id, setId] = useState<number | undefined>(undefined)
    const [setting, setSetting] = useState<ListingSetting>({
        cardCondensedByDefault: true,
        cardShowBadge: true,
        cardShowStatus: true,
        cardShowSchedule: true,
        cardShowSizeAndWeight: null,
        filters: [],
        groupedActions: [],
        searchable: [],
        searchableByEmail: false,
        searchableByFirstName: false,
        searchableByLastName: false,
        searchableByName: false,
        sorters: [
            {
                sorter: 'updatedAt',
                defaultSort: true,
                defaultDirection: 'desc'
            }
        ],
        tableColumns: [],
        kanbanDatas: [],
        cardDatas: [],
        views: ['table']
    })

    useEffect(() => {
        let collection: null | DataCollection | Catalog | Bucket = null
        let setting: undefined | ListingSetting = undefined
        let id: number | undefined = undefined

        if (entity) {
            if (['catalog', 'bucket', 'data-collection'].includes(entity)) {
                let field: string = ''
                if (entity === 'data-collection') {
                    field = 'dataCollections'
                } else {
                    field = entity + 's'
                }
                let list: null | Catalog[] | Bucket[] | DataCollection[] = null
                if (currentUser?.currentOrganization) {
                    list = currentUser.currentOrganization[field] as Catalog[] | Bucket[] | DataCollection[]
                    if (list && Array.isArray(list) && list.length) {
                        if (primarySlug) {
                            for (let i = 0; i < list.length; i++) {
                                let item = list[i] as DataCollection | Catalog | Bucket
                                if (item.primarySlug === primarySlug) {
                                    collection = item
                                    break
                                }
                            }
                        } else {
                            collection = list[0] as DataCollection | Catalog | Bucket
                        }
                    }
                    if (collection) {
                        setting = collection.listingSetting
                        id = collection.id
                    }
                }
            } else if ('external-file' === entity) {
                setting = {
                    cardCondensedByDefault: true,
                    cardShowBadge: true,
                    cardShowStatus: true,
                    cardShowSchedule: true,
                    cardShowSizeAndWeight: null,
                    filters: [],
                    groupedActions: [],
                    searchable: [],
                    searchableByEmail: false,
                    searchableByFirstName: false,
                    searchableByLastName: false,
                    searchableByName: false,
                    sorters: [],
                    tableColumns: [],
                    kanbanDatas: [],
                    cardDatas: [],
                    views: ['card']
                }
            } else if (currentUser?.currentOrganization) {
                setting = currentUser?.currentOrganization[entity + 'ListingSetting'] as ListingSetting
            }
        }

        if (id) {
            setId(id)
        }
        if (setting) {
            setSetting(setting)
        }
    }, [currentUser?.currentOrganization, entity, primarySlug])

    const hideModal = () => {
        refreshModalList && refreshModalList()
        setSearch('')
        setShow(false)
    }

    return (
        <Modal
            fullscreen
            show={show}
            onHide={hideModal}
            className="modal-choose-items"
            scrollable
        >
            <Modal.Header closeButton className={`${hasSearch ? 'modal-with-search' : ''}`}>
                <Modal.Title>{title}</Modal.Title>
                {hasSearch && <SearchType i18nLabel="app.searching" className="form-control-lg-white" floating={false}
                    fullName="search" id="search_modal" name="search_modal" value={search}
                    setValue={setSearch} />}
            </Modal.Header>
            <Modal.Body>
                {addModalShow &&
                    <Button variant="primary" className="btn-lg mb-3 w-100 text-center" onClick={addModalShow}>{t('app.new')}</Button>
                }
                <Listing
                    entity={entity}
                    id={id ?? undefined}
                    path={path}
                    search={search}
                    setSearch={setSearch}
                    setting={setting}
                    hideHeader={false}
                    hideDownloadButton={true}
                    disableMainActionButton={true}
                    selectedItems={selected}
                    setSelectedItems={setSelected}
                    selectMultiple={multiple}
                    toggleReload={toggleReload}
                    contextMode="modal"
                />
            </Modal.Body>
            <Footer>
                <Button variant="primary" onClick={() => {
                    setShow(false)
                    refreshModalList && refreshModalList()
                }}>{t(`app.${refreshModalList ? 'validate' : 'close'}`)}</Button>
            </Footer>
        </Modal>
    )
}

export default ChooseItemsModal
