import {Choice} from "Vendor/Components/Form/Type/ChoiceType";

const MatchedByFieldNoPropertyChoice: Choice[] = [
    {
        i18nKey: 'app.enum.collection.import.column.matched_by_field.identifier',
        value: 'identifier'
    },
    {
        i18nKey: 'app.enum.collection.import.column.matched_by_field.name',
        value: 'name'
    },
]

export default MatchedByFieldNoPropertyChoice
