import React, {FC, useState} from "react";
import {Outlet} from "react-router-dom";
import {Navbar as GlobalNavbar, NavbarItem} from "../../../../Components/Layout/Navbar";
import Header from "../../../../Components/Layout/Header";
import {useTranslation} from "react-i18next";

const Navbar: FC = () => {
    const {t} = useTranslation()
    const [show, setShow] = useState<boolean>(false)
    const [search, setSearch] = useState<string>('')
    const [handleAdd, setHandleAdd] = useState<(() => void)|undefined>(undefined)

    const items: NavbarItem[] = [
        {
            i18nLabel: 'app.properties',
            path: ''
        },
        {
            i18nLabel: 'app.groups',
            path: 'group'
        }
    ]

    return (
        <>
            <Header title={t('app.properties')} add={() => {
                handleAdd !== undefined && handleAdd()
                setShow(true)
            }}
            search={search}
            setSearch={setSearch}
            />
            <GlobalNavbar locationBase={"/admin/edition/property"} items={items} />
            <Outlet context={[show, setShow, search, setSearch, setHandleAdd]} />
        </>
    )
}

export default Navbar
