import {RouteObject} from "react-router-dom";
import React from "react";
import MainPage from "Pages/Admin/Edition/Platform/MainPage";
import VariablesRouting from "./Platform/VariablesRouting";
import ListingsPage from "Pages/Admin/Edition/Platform/ListingsPage";
import DownloadConfigPage from "Pages/Admin/Edition/Platform/DownloadConfigPage";

const PlatformRouting: RouteObject[] = [
    {
        index: true,
        element: <MainPage />,
    },
    {
        path: 'listings',
        element: <ListingsPage />,
    },
    {
        path: 'download-configs',
        element: <DownloadConfigPage />,
    },
    {
        path: 'variables',
        children: VariablesRouting
    },
]

export default PlatformRouting
