import {FC, ReactNode, useEffect} from "react";
import FormBuilderInterface, {FormSkeletonChildrenInterface} from "../../Definition/Form/FormBuilderInterface";
import FormInterface from "../../Definition/Form/FormInterface";
import {FormOptionsInterface} from "../Form";
import FormBuilder from "../../Definition/Form/FormBuilder";
import SubForm, {SubFormProps} from "./SubForm";

export type ChildFormProps = SubFormProps & {
    form: FormInterface
    children: ReactNode
    childSkeleton: FormSkeletonChildrenInterface
    setChildSkeleton: (childSkeleton: FormSkeletonChildrenInterface) => void
    formOptions?: FormOptionsInterface
}

const ChildForm: FC<ChildFormProps> = ({
                                 form,
                                 children,
                                 childSkeleton,
                                 setChildSkeleton,
                                 formOptions,
                                 ...rest
                             }) => {

    useEffect(() => {
        let builder: FormBuilderInterface = new FormBuilder()
        form.builder(builder, formOptions ?? {})
        setChildSkeleton({...builder.getSkeleton().children})
    }, [form, formOptions])

    return <SubForm childSkeleton={childSkeleton} setChildSkeleton={setChildSkeleton} {...rest}>
        {children}
    </SubForm>
}

export default ChildForm
