import React, {CSSProperties, FC, ReactNode, useCallback, useContext, useEffect, useRef, useState} from 'react'
import {
    Offcanvas,
    OffcanvasBody as Body,
} from 'react-bootstrap'
import Shopping from "../../../Entity/Shopping";
import Api from "../../../Vendor/Api";
import {useTranslation} from "react-i18next";
import useTranslationDataUtils from "../../../Vendor/Utils/TranslationDataUtils";
import message from "../../../Entity/Shopping/Message";
import {ButtonVariant} from "react-bootstrap/types";
import {classUtils as c} from "Vendor/Utils/ClassUtils";
import {UserContext} from "../../../Vendor/Context/UserContextProvider";
import EntityInterface from "../../../Vendor/Definition/EntityInterface";
import Property from "../../../Entity/Property";
import {CollectionContext} from "../../../Vendor/Context/CollectionContextProvider";
import Product from "../../../Entity/Shopping/Product";
import _Product from "./_Product";

export type _ContactInfoProps = {
    shopping?: Shopping
}

const _ContactInfo: FC<_ContactInfoProps> = ({
                                                   shopping,
                                                     }) => {

    const {t} = useTranslation()
    const {currentUser} = useContext(UserContext)
    const {getByType} = useContext(CollectionContext)

    return (
        <>
            <h6>{t('app.customer')}</h6>
            <b>{shopping
                ? (shopping.customer
                        ? shopping.customer.firstName + ' ' + shopping.customer.lastName
                        : <i className="text-muted">{t('app.not_specified')}</i>
                ) : <span className="placeholder col-6"></span>
            }</b>
            <p>{shopping ? shopping.customer?.email : <span className="placeholder col-4"></span>}</p>
            {shopping ? (
                shopping.customer && currentUser?.currentOrganization?.contactProperties.length && <>
                    {getByType(shopping.customer, 'phone', currentUser.currentOrganization.contactProperties).map((phoneValue: ReactNode) =>
                        <p>{phoneValue}</p>
                    )}
                </>
            ) : <span className="placeholder col-3"></span>}
        </>
    )
}

export default _ContactInfo
