import React, {FC} from 'react'
import {useTranslation} from "react-i18next";
import {FilterValues} from "../Listing";

export type RangeType = 'date' | 'number'

export interface _RangeFilterProps {
    type: RangeType
    filterValues?: FilterValues
    setFilterValues: (filterValues: FilterValues) => void

    suffix?: string
}

const _RangeFilter: FC<_RangeFilterProps> = ({type, filterValues, setFilterValues, suffix = undefined}) => {
    const {t} = useTranslation()

    return <div className="d-flex flex-column">
        <div className="d-flex justify-content-between">
            <div className="d-flex flex-column">
                <label htmlFor={type === 'date' ? 'fromDate' : 'fromNumber'}>{t('app.from')}</label>
                <div className="input-group">
                    <input
                        id={type === 'date' ? 'fromDate' : 'fromNumber'}
                        type={type}
                        className="form-control me-2"
                        value={filterValues && filterValues['min'] !== undefined ? filterValues['min'].toString() : ''}
                        onChange={(e) => {
                            const value = e.target.value;
                            if (value) {
                                setFilterValues({...filterValues, 'min': value});
                            } else {
                                let tempFilterValues = {...filterValues};
                                delete tempFilterValues['min'];
                                setFilterValues(tempFilterValues);
                            }
                        }}
                    />
                    {suffix && <div className="input-group-append">{suffix}</div>}
                </div>
            </div>
            <div className="d-flex flex-column">
                <label htmlFor={type === 'date' ? 'toDate' : 'toNumber'}>{t('app.to')}</label>
                <div className="input-group">
                    <input
                        id={type === 'date' ? 'toDate' : 'toNumber'}
                        type={type}
                        className="form-control"
                        value={filterValues && filterValues['max'] !== undefined ? filterValues['max'].toString() : ''}
                        onChange={(e) => {
                            const value = e.target.value;
                            if (value) {
                                setFilterValues({...filterValues, 'max': value});
                            } else {
                                let tempFilterValues = {...filterValues};
                                delete tempFilterValues['max'];
                                setFilterValues(tempFilterValues);
                            }
                        }}
                    />
                    {suffix && <div className="input-group-append">{suffix}</div>}
                </div>
            </div>
        </div>
        <div className="d-flex justify-content-end">
            <button
                type="button"
                className="btn btn-outline-primary btn-sm mt-2"
                onClick={() => {
                    setFilterValues({});
                }}
            >
                {t('app.reset')}
            </button>
        </div>
    </div>
}

export default _RangeFilter
