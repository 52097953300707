import React, {FC, useContext, useState} from 'react'
import {useNavigate, useOutletContext} from 'react-router-dom'
import { UserContext } from 'Vendor/Context/UserContextProvider'
import {useTranslation} from "react-i18next";
import Header from "Components/Layout/Header";
import Table from "Components/Layout/Table";
import Api from "Vendor/Api";
import ContactGroupTable from "../../../../../Table/Admin/Edition/ContactGroupTable";
import ContactGroup from "../../../../../Entity/ContactGroup";
import FormRow from "../../../../../Vendor/Components/Form/FormRow";
import FormModal from "../../../../../Components/Modal/FormModal";
import ContactGroupForm from "../../../../../Form/Admin/Edition/ContactGroupForm";
import DeleteModal from "../../../../../Components/Modal/DeleteModal";
import FormError from "../../../../../Vendor/Components/Form/FormError";
import Catalog from "../../../../../Entity/Catalog";
import {Button} from "react-bootstrap";
import FormOffCanvas from "../../../../../Components/Modal/FormOffCanvas";
import FormEmptyOffCanvas from "../../../../../Components/Modal/FormEmptyOffCanvas";

const ContactGroupPage: FC = () => {
    const { currentUser, setCurrentUser, setInit } = useContext(UserContext)
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [toggleReload, setToggleReload] = useState<boolean>(true)
    const [show, setShow, search, setSearch] = useOutletContext<[boolean, (show:boolean) => void, string, (search:string) => void]>()
    const [contactGroups, setContactGroups] = useState<ContactGroup[]>([])
    const [contactGroup, setContactGroup] = useState<ContactGroup>({} as ContactGroup)
    const handleClose = (reload = false) => {
        setShow(false)
        if (reload) {
            setToggleReload(!toggleReload)
        }
    }
    const handleShow = () => setShow(true)
    // remove
    const [showDelete, setShowDelete] = useState<boolean>(false)
    const handleDeleteClose = (reload = false) => {
        setContactGroup({} as ContactGroup)
        setShowDelete(false)
        if (reload) {
            setToggleReload(!toggleReload)
        }
    }
    const handleDeleteShow = () => setShowDelete(true)

    const onSuccess = (contactGroup: ContactGroup) => {
        setContactGroup(contactGroup)
        setShow(false)
        setToggleReload(!toggleReload)
    }

    const onExited = () => {
        setContactGroup({} as ContactGroup)
    }

    const addContactGroup = () => {
        setContactGroup({} as ContactGroup)
        handleShow()
    }

    const editContactGroup = (contactGroup: ContactGroup) => {
        setContactGroup(contactGroup)
        handleShow()
    }

    const deleteContactGroup = (contactGroup: ContactGroup) => {
        setContactGroup(contactGroup)
        handleDeleteShow()
    }

    const actionsRender = (contactGroup: ContactGroup) => (
        <>
            {contactGroup?.isGranted?.edit &&
            <Button
                variant='outline-primary'
                size='sm'
                onClick={() => editContactGroup(contactGroup)}>
                <i className="fa-solid fa-pencil me-2"></i>
                {t('app.edit')}</Button>
            }
            {contactGroup?.isGranted?.delete &&
            <Button
                variant='outline-danger'
                size='sm'
                onClick={() => deleteContactGroup(contactGroup)}>
                <i className="fa-solid fa-trash me-2"></i>
                {t('app.delete')}</Button>
            }
        </>
    );

    let table = ContactGroupTable;
    if (table.hasOwnProperty('actions')) {
        table.actions.render = actionsRender;
    }

    return (
        <>
            <Table
                path="/admin/edition/contact-group"
                rows={table}
                toggleReload={toggleReload}
                items={contactGroups}
                setItems={setContactGroups}
                keyword={search}
                setKeyword={setSearch}
            />
            <FormEmptyOffCanvas
                i18nTitle={contactGroup.id ? 'app.edit_contact_group' : 'app.add_contact_group'}
                show={show}
                handleClose={handleClose}
                onSuccess={onSuccess}
                onExited={onExited}
                path={`/admin/edition/contact-group/${contactGroup.id ?? 'new'}`}
                formPath={contactGroup.id ? true : false}
                method={contactGroup.id ? 'patch' : 'post'}
            />
            <DeleteModal
                handleClose={handleDeleteClose}
                show={showDelete}
                path={'/admin/edition/contact-group/' + (contactGroup?.id ?? 'error')}
                name={'#' + (contactGroup ? `${contactGroup.id} - ${contactGroup.name}` : 'error')}
                archive={true}
            />
        </>
    )
}

export default ContactGroupPage
