import React, {FC, useContext, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useTranslation} from "react-i18next";
import Header from "Components/Layout/Header";
import Table from "Components/Layout/Table";
import CatalogTable from "../../../Table/Admin/Edition/CatalogTable";
import Catalog from "../../../Entity/Catalog";
import FormRow from "Vendor/Components/Form/FormRow";
import FormModal from "../../../Components/Modal/FormModal";
import CatalogForm from "../../../Form/Admin/Edition/CatalogForm";
import DeleteModal from "../../../Components/Modal/DeleteModal";
import FormError from "../../../Vendor/Components/Form/FormError";
import {Button} from "react-bootstrap";
import FormOffCanvas from "../../../Components/Modal/FormOffCanvas";
import FormEmptyOffCanvas from "../../../Components/Modal/FormEmptyOffCanvas";
import Api from "../../../Vendor/Api";
import LoadingButton from "../../../Components/Button/LoadingButton";
import FormEmptyModal from "../../../Components/Modal/FormEmptyModal";

const CatalogPage: FC = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [toggleReload, setToggleReload] = useState<boolean>(true)
    const [show, setShow] = useState<boolean>(false)
    const [showMatching, setShowMatching] = useState<boolean>(false)
    const [search, setSearch] = useState<string>('')
    const [catalogs, setCatalogs] = useState<Catalog[]>([])
    const [catalog, setCatalog] = useState<Catalog>({} as Catalog)
    const [reindexLoader, setReindexLoader] = useState<undefined|number>(undefined)
    const [reIndexSuccess, setReIndexSuccess] = useState<undefined|number>(undefined)
    const [matchingLoader, setMatchingLoader] = useState<undefined|number>(undefined)
    const [matchingSuccess, setMatchingSuccess] = useState<undefined|number>(undefined)

    const handleClose = (reload = false) => {
        setShow(false)
        if (reload) {
            setToggleReload(!toggleReload)
        }
    }
    const handleShow = () => setShow(true)
    // remove
    const [showDelete, setShowDelete] = useState<boolean>(false)
    const handleDeleteClose = (reload = false) => {
        setCatalog({} as Catalog)
        setShowDelete(false)
        if (reload) {
            setToggleReload(!toggleReload)
        }
    }
    const handleDeleteShow = () => setShowDelete(true)

    const onSuccess = (catalog: Catalog) => {
        setCatalog(catalog)
        setShow(false)
        setToggleReload(!toggleReload)
    }

    const addCatalog = () => {
        setCatalog({} as Catalog)
        handleShow()
    }

    const editCatalog = (catalog: Catalog) => {
        navigate(catalog.id?.toString() ?? '', {state: catalog})
    }

    const reindexCatalog = (catalogId: number) => {
        setReindexLoader(catalogId)
        Api.patch('/admin/edition/catalog/' + catalogId + '/reindex')
            .then(() => {
                setReIndexSuccess(catalogId)
            }).catch(() => {
        }).then(() => {
            setReindexLoader(undefined)
        })
    }

    const matchingCatalog = (catalog?: Catalog) => {
        if (catalog) {
            setCatalog(catalog)
            setShowMatching(true)
        } else {
            setCatalog({} as Catalog)
            setShowMatching(false)
        }
    }

    const deleteCatalog = (catalog: Catalog) => {
        setCatalog(catalog)
        handleDeleteShow()
    }

    const actionsRender = (catalog: Catalog) => (
        <>
            {catalog?.isGranted?.edit &&
            <>
                <Button
                variant='outline-primary'
                size='sm'
                onClick={() => editCatalog(catalog)}>
                <i className="fa-solid fa-pencil me-2"></i>
                {t('app.edit')}</Button>
                <LoadingButton variant='outline-info'
                               isLoading={reindexLoader === catalog.id}
                               success={reIndexSuccess === catalog.id}
                               setSuccess={(success) => setReIndexSuccess(success ? catalog.id : undefined)}
                               size='sm'
                               onClick={() => {
                                   reindexCatalog(catalog?.id ?? 0)
                               }}>
                    <i className="fa-solid fa-cloud-word me-2"></i>
                    {t('app.reindex')}
                </LoadingButton>
                <LoadingButton variant='outline-info'
                               isLoading={matchingLoader === catalog.id}
                               success={matchingSuccess === catalog.id}
                               setSuccess={(success) => setMatchingSuccess(success ? catalog.id : undefined)}
                               size='sm'
                               onClick={() => {
                                   matchingCatalog(catalog)
                               }}>
                    <i className="fa-solid fa-image me-2"></i>
                    {t('app.matching_files')}
                </LoadingButton>
                </>
            }
            {catalog?.isGranted?.delete &&
            <Button
                variant='outline-danger'
                size='sm'
                onClick={() => deleteCatalog(catalog)}>
                <i className="fa-solid fa-trash me-2"></i>
                {t('app.delete')}</Button>
            }
        </>
    );

    let table = CatalogTable;
    if (table.hasOwnProperty('actions')) {
        table.actions.render = actionsRender;
    }

    return (
        <>
            <Header title={t('app.catalogs')}
                    add={addCatalog}
                    search={search}
                    setSearch={setSearch}
            />
            <Table
                path="/admin/edition/catalog"
                rows={table}
                toggleReload={toggleReload}
                items={catalogs}
                setItems={setCatalogs}
                keyword={search}
                setKeyword={setSearch}
            />
            <FormEmptyOffCanvas
                i18nTitle={catalog.id ? 'app.edit_catalog' : 'app.add_catalog'}
                show={show}
                handleClose={handleClose}
                onSuccess={onSuccess}
                path={`/admin/edition/catalog/${catalog.id ?? 'new'}`}
                formPath={catalog.id ? true : false}
                method={catalog.id ? 'patch' : 'post'}
            />
            <FormEmptyModal
                i18nTitle={catalog.id ? 'app.edit_catalog' : 'app.add_catalog'}
                show={showMatching}
                handleClose={() => setShowMatching(false)}
                path={`/admin/edition/catalog/${catalog.id ?? '0'}/matching`}
            />
            <DeleteModal
                handleClose={handleDeleteClose}
                show={showDelete}
                path={'/admin/edition/catalog/' + (catalog?.id ?? 'error')}
                name={'#' + (catalog ? `${catalog.id} - ${catalog.translations}` : 'error')}
                archive={true}
            />
        </>
    )
}

export default CatalogPage
