import React, { FC, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Button,
    Modal,
    ModalBody as Body,
    ModalFooter as Footer,
    ModalHeader as Header,
    ModalTitle as Title,
} from 'react-bootstrap'
import GroupedAction from 'Entity/Collection/ListingSetting/GroupedAction'
import LoadingButton from '../../Button/LoadingButton'
import Api from 'Vendor/Api'
import Collection from 'Config/Collection'
import TextType from '../../../Vendor/Components/Form/Type/TextType'
import Property from '../../../Entity/Property'
import ChoiceType, { Choice } from 'Vendor/Components/Form/Type/ChoiceType'
import { UserContext } from 'Vendor/Context/UserContextProvider'

export interface _ReportingModalProps {
    availableActions: GroupedAction[]
    collection?: Collection
    id?: number
    showActionModals: { [key: string]: boolean | Property }
    handleShowActionModal: (
        groupedAction?: GroupedAction,
        actionFromMain?: boolean,
    ) => void
    fromMain: boolean
    handleShowMainActionModal: () => void
    selected: number[]
    actionModalFormIsLoading: boolean
    actionModalFormIsLoaded: boolean
    setActionModalFormIsLoaded: (isLoaded: boolean) => void
    handleActionModalOnResponse: () => void
    handleActionModalOnSuccess: () => void
    handleActionModalOnSubmit: () => void
}

const _ReportingModal: FC<_ReportingModalProps> = ({
    id,
    availableActions,
    collection,
    showActionModals,
    handleShowActionModal,
    fromMain,
    handleShowMainActionModal,
    selected,
    actionModalFormIsLoading,
    handleActionModalOnResponse,
    handleActionModalOnSuccess,
    handleActionModalOnSubmit,
}) => {
    const { t } = useTranslation()
    const [title, setTitle] = useState<string>('')
    const [intermediateChoice, setIntermediateChoice] = useState<string>('')
    const { currentUser } = useContext(UserContext)
    const userLanguage = currentUser?.currentOrganization
        ?.defaultLanguage as string

    const choiceActions = availableActions.filter(
        (item) => item.property && item.property.type === 'choice',
    )

    const intermediateChoices: Choice[] = choiceActions.map((item) => ({
        i18nKey: (item?.property?.translations?.[userLanguage].label ?? ''),
        value: (item?.property?.id ?? '').toString(),
    }))

    const onSubmit = () => {
        if (title.length > 1) {
            handleActionModalOnSubmit()
            Api.post(`/grouped-action/reporting/${collection}${id ? '/' + id : ''}`, {
                data: {
                    listing: collection,
                    selected: selected,
                    title,
                    intermediateChoice: parseInt(intermediateChoice),
                },
            }, {
                responseType: 'blob'
            })
                .then((response ) => {
                    let disposition = response.headers['content-disposition'];
                    // get filename from content-disposition
                    let filename = disposition?.split(';')[1].split('filename')[1].split('=')[1].trim().replace(/"/g, '');
                    // create file link in browser's memory
                    const href = URL.createObjectURL(response.data);
    
                    // create "a" HTML element with href to file & click
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', filename); //or any other extension
                    document.body.appendChild(link);
                    link.click();
    
                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);

                    handleActionModalOnSuccess()
                })
                .catch(() => {})
                .then(() => {
                    handleActionModalOnResponse()
                })
        }
    }

    return (
        <Modal
            show={showActionModals.reporting !== false}
            onHide={handleShowActionModal}
        >
            <Header closeButton={!fromMain}>
                <div className="modal-block-header">
                    {fromMain && (
                        <i
                            className="fa-solid fa-angle-left"
                            onClick={handleShowMainActionModal}
                        ></i>
                    )}
                    <Title>
                        {t(`app.groupedActionTitle.reporting.${collection}`, {
                            count: selected.length,
                        })}
                    </Title>
                </div>
            </Header>
            <form>
                <Body>
                    <TextType
                        id="title"
                        name="title"
                        fullName="title"
                        value={title}
                        setValue={setTitle}
                        required
                        i18nLabel="app.groupedActionContent.reporting.inputLabel"
                    />
                    <ChoiceType
                        i18nLabel="app.groupedActionContent.reporting.choiceLabel"
                        choices={[
                            { i18nKey: '', value: '' },
                            ...intermediateChoices,
                        ]}
                        required={true}
                        value={intermediateChoice}
                        setValue={(data) =>
                            setIntermediateChoice(
                                Array.isArray(data) ? data[0] : data,
                            )
                        }
                    />
                </Body>
                <Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            handleShowActionModal()
                        }}
                    >
                        {t('app.cancel')}
                    </Button>
                    <LoadingButton
                        variant="primary"
                        isLoading={actionModalFormIsLoading}
                        onClick={onSubmit}
                    >
                        {t('app.save')}
                    </LoadingButton>
                </Footer>
            </form>
        </Modal>
    )
}

export default _ReportingModal
