import React, {FC, useEffect, useState} from 'react'
import Filter from "Entity/Collection/ListingSetting/Filter";
import useTranslationDataUtils from "Vendor/Utils/TranslationDataUtils";
import {useTranslation} from "react-i18next";
import {Dropdown} from "react-bootstrap";
import {FilterChoice, FilterValues} from "../Listing";
import {DataType} from "Vendor/Components/Form";
import _RangeFilter from "./_RangeFilter";
import _ChoiceFilter from "./_ChoiceFilter";
import _ToggleFilter from './_ToggleFilter'
import Collection from "../../../Config/Collection";
import { classUtils as c } from "Vendor/Utils/ClassUtils";

export interface _FilterProps {
    entity?: Collection
    id?: number
    filter: Filter
    choices: FilterChoice[]|undefined
    filterValues?: FilterValues
    setFilterValues: (filterValues: FilterValues) => void
    setNewSearch: (criteria: DataType) => void
}

const _Filter: FC<_FilterProps> = ({entity, id, filter, choices, filterValues, setFilterValues, setNewSearch}) => {
    const {t} = useTranslation()
    const { translate } = useTranslationDataUtils()
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)
    const [isSelect2Choice, setIsSelect2Choice] = useState<boolean>(false)

    useEffect(() => {
        if (
            (filter.filters && ['createdAt', 'updatedAt', 'designedDelivery', 'plannedReturn'].includes(filter.filters))
            || (filter.property && filter.property.type === "date")
            || (filter.property && ['number', 'price'].includes(filter.property.type))
        ) {
            setIsSelect2Choice(false)
        } else {
            setIsSelect2Choice(true)
        }
    }, [filter])

    const handleDropdownToggle = (nextShow: boolean) => {
        setDropdownOpen(nextShow)
    }

    return (
        choices === undefined || choices.length > 0 ? (
            <div className="col-auto mb-2">
                {(filter.filters && ['shoppingLate', 'archived', 'withoutProduct', 'withoutFile'].includes(filter.filters))
                    ? <_ToggleFilter filter={filter} choices={choices} setFilterValues={setFilterValues} filterValues={filterValues}/>
                    : 
                        <Dropdown autoClose={false} onToggle={handleDropdownToggle} show={dropdownOpen}>
                            <Dropdown.Toggle
                                variant="secondary"
                                disabled={!choices && !((filter.filters && ['createdAt', 'updatedAt', 'designedDelivery', 'plannedReturn', 'shoppingLate', 'archived'].includes(filter.filters)) || (filter.property && ["date", "number", "price"].includes(filter.property.type)))}
                            >
                                {
                                    filter.property
                                        ? translate(filter.property, 'label')
                                        : t(`app.${filter.filters}`)
                                }
                            </Dropdown.Toggle>
                            <Dropdown.Menu className={c(isSelect2Choice && "dropdown-select2")}>
                                {(filter.filters && ['createdAt', 'updatedAt', 'designedDelivery', 'plannedReturn'].includes(filter.filters)) || (filter.property && filter.property.type === "date")
                                    ? <_RangeFilter type="date" setFilterValues={setFilterValues} filterValues={filterValues} />
                                    : (filter.property && ['number', 'price'].includes(filter.property.type))
                                        ? <_RangeFilter type="number" setFilterValues={setFilterValues} filterValues={filterValues} suffix={filter.property.type === 'price' ? Number().toLocaleString('en', {style:"currency", currency:filter?.property?.currency ?? 'EUR'}).slice(0, -4).trim() : undefined} />
                                    : <_ChoiceFilter entity={entity} id={id} filter={filter} choices={choices} setFilterValues={setFilterValues} filterValues={filterValues} setNewSearch={setNewSearch} setDropdownOpen={setDropdownOpen} isOpen={dropdownOpen} />
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                }
            </div>
        ) : <></>
    )
}

export default _Filter
