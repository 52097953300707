import { motion } from 'framer-motion'
import React, { cloneElement, FC, isValidElement, ReactNode } from 'react'

type Props = {
    children: ReactNode
    type?: MotionType
}

type MotionType = 'fade' | 'slide'

const motionProps: {
    [key: string]: { initial?: object; animate?: object; exit?: object }
} = {
    slide: {
        initial: { top: '-100vh' },
        animate: { top: 0 },
        exit: { x: window.innerWidth, transition: { duration: 0.1 } },
    },
    fade: {
        initial: { opacity: 0, width: '100%' },
        animate: { opacity: 1, width: '100%' },
        exit: { opacity: 0, transition: { duration: 0.05 }, width: '100%' },
    },
}

export const Motion: FC<Props> = ({ children, type = 'fade', ...props }) =>
    isValidElement(children) ? (
        <motion.div {...motionProps[type]}>
            {cloneElement(children, props)}
        </motion.div>
    ) : null
