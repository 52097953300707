import React, {FC, FormEvent, ReactNode, useEffect, useState} from 'react'
import BaseForm, {FormProps as BaseFormProps} from "Vendor/Components/Form";

export type FormProps = BaseFormProps & {
    onSubmit?: (e?: FormEvent<HTMLFormElement>) => void
    children: ReactNode
    isValidate?: boolean
    setIsValidate?: (validated: boolean) => void
    isLoading?: boolean
    setIsLoading?: (isLoaded: boolean) => void
    onResponse?: (response?: any) => void
}

const Form: FC<FormProps> = ({onSubmit, children, isLoading, isValidate, setIsValidate, setIsLoading, onResponse, ...rest}) => {
    const [localIsValidate, setLocalIsValidate] = useState<boolean>(isValidate ?? false)

    useEffect(() => {
        setLocalIsValidate(isValidate ?? false)
    }, [isValidate])

    const localOnSubmit = (e?: FormEvent<HTMLFormElement>) => {
        if (e) {
            e.preventDefault()
            const form = e.currentTarget
            setIsLoading && setIsLoading(true)

            const validity = form.checkValidity()
            setIsValidate ? setIsValidate(true) : setLocalIsValidate(true)

            if (!validity) {
                setIsLoading && setIsLoading(false)
            } else {
                onSubmit && onSubmit(e)
            }

            return validity
        }

        return true
    }

    const localOnResponse = () => {
        setIsValidate ? setIsValidate(false) : setLocalIsValidate(false)
        onResponse && onResponse()
    }

    return (
        <BaseForm className={localIsValidate ? 'was-validated' : ''} onSubmit={localOnSubmit} onResponse={localOnResponse} {...rest}>
            {children}
        </BaseForm>
    )
}

export default Form
