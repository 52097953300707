import React, {FC, useContext, useEffect, useState} from "react";
import {classUtils as c} from "Vendor/Utils/ClassUtils";
import useHumanizeFileSizeUtils from "Vendor/Utils/HumanizeFileSizeUtils";
import {useTranslation} from "react-i18next";
import {mimeTypeArchive, mimeTypeAudio, mimeTypeImage, mimeTypeVideo, SupportedMimesTypes, UploadedFile} from "Vendor/UploadSystem";
import {UserContext} from "../../Vendor/Context/UserContextProvider";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "react-bootstrap";

export type RejectModalProps = {
    show: boolean,
    setShow: (show: boolean) => void
    file: UploadedFile
}

const _RejectModal: FC<RejectModalProps> = ({show, setShow, file}) => {
    const {t} = useTranslation()
    const [rejectReasons, setRejectReasons] = useState<string[]>([])

    useEffect(() => {
        let tempRejectReasons: string[] = []

        if (file.rejectReason) {
            if (file.rejectReason & 1) {
                tempRejectReasons.push('mime_type')
            }

            if (file.rejectReason & 2) {
                tempRejectReasons.push('size')
            }

            if (file.rejectReason & 4) {
                tempRejectReasons.push('min_width')
            }

            if (file.rejectReason & 8) {
                tempRejectReasons.push('max_width')
            }

            if (file.rejectReason & 16) {
                tempRejectReasons.push('min_height')
            }

            if (file.rejectReason & 32) {
                tempRejectReasons.push('max_height')
            }

            if (file.rejectReason & 64) {
                tempRejectReasons.push('min_width_cm')
            }

            if (file.rejectReason & 128) {
                tempRejectReasons.push('max_width_cm')
            }

            if (file.rejectReason & 256) {
                tempRejectReasons.push('min_height_cm')
            }

            if (file.rejectReason & 512) {
                tempRejectReasons.push('max_height_cm')
            }

            if (file.rejectReason & 1024) {
                tempRejectReasons.push('min_dpi')
            }

            if (file.rejectReason & 2048) {
                tempRejectReasons.push('max_dpi')
            }

            if (file.rejectReason & 4096) {
                tempRejectReasons.push('color')
            }

            if (file.rejectReason & 8192) {
                tempRejectReasons.push('layout')
            }

            if (file.rejectReason & 16384) {
                tempRejectReasons.push('ratio')
            }

            if (file.rejectReason & 32768) {
                tempRejectReasons.push('resolution_units')
            }

            if (file.rejectReason & 65536) {
                tempRejectReasons.push('name_already_exists')
            }

            if (file.rejectReason & 131072) {
                tempRejectReasons.push('file_already_exists')
            }

            if (file.rejectReason & 262144) {
                tempRejectReasons.push('file_does_not_exist')
            }

            if (file.rejectReason & 524288) {
                tempRejectReasons.push('back_and_white')
            }
        }

        setRejectReasons(tempRejectReasons)
    }, [file.rejectReason])

    return <Modal show={show}>
        <ModalHeader>{t('app.reject_file')}</ModalHeader>
        <ModalBody>
            <ul className="list-group">
                {rejectReasons.map((reason, index) => (
                    <li className="list-group-item" key={index}>{t(`app.upload_system.reject_reason.${reason}`)}</li>
                ))}
            </ul>
        </ModalBody>
        <ModalFooter>
            <button className="btn btn-primary" onClick={() => setShow(false)}>{t('app.close')}</button>
        </ModalFooter>
    </Modal>
}

export default _RejectModal
