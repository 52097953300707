import {RouteObject} from "react-router-dom";
import React from "react";
import CompanyGroupPage from "../../Pages/Admin/Edition/Security/CRM/CompanyGroupPage";
import DealGroupPage from "../../Pages/Admin/Edition/Security/CRM/DealGroupPage";
import ContactGroupPage from "../../Pages/Admin/Edition/Security/CRM/ContactGroupPage";

const CrmGroupsRouting: RouteObject[] = [
    {
        path: 'contact-group',
        element: <ContactGroupPage />,
    },
    {
        path: 'company-group',
        element: <CompanyGroupPage />,
    },
    {
        path: 'deal-group',
        element: <DealGroupPage />,
    },
]

export default CrmGroupsRouting