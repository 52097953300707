import React, { FC, useContext, useEffect, useState } from 'react'
import {Outlet, useLocation, useNavigate} from 'react-router-dom'
import { UserContext } from 'Vendor/Context/UserContextProvider'
import { classUtils as c } from 'Vendor/Utils/ClassUtils'
import Access from "Config/Access";

export const ProtectedRoute: FC<{
    granted: Access
}> = ({ granted }) => {
    const navigate = useNavigate()
    const [isGranted, setIsGranted] = useState(false)
    const [isHidden, setIsHidden] = useState<boolean>(true)
    const { currentUser, getInitialPage } = useContext(UserContext)

    useEffect(() => {
        if (currentUser) {
            if (granted === currentUser.access) {
                setIsGranted(true)
            } else {
                navigate(getInitialPage())
            }
        } else {
            setIsGranted(false)
            navigate('/login')
        }

        const idTimeout = setTimeout(() => {
            setIsHidden(false)
        }, 10)

        return () => {
            clearTimeout(idTimeout)
        }
    }, [currentUser, granted])

    return isGranted ? (
        <main className={c(isHidden && 'hidden')}>
            <Outlet />
        </main>
    ) : null
}
