import {RouteObject} from "react-router-dom";
import React from "react";
import CatalogPage from "../Pages/CatalogPage";

const CatalogRouting: RouteObject[] = [
    {
        index: true,
        element: <CatalogPage />,
    },
    {
        path: ':id',
        element: <CatalogPage />
    },
]

export default CatalogRouting
