import {RouteObject} from "react-router-dom";
import React from "react";
import ShoppingListingPage from "Pages/Admin/Edition/Setting/ShoppingListingPage";
import DeliveryPaperPage from "Pages/Admin/Edition/Setting/Shopping/DeliveryPaperPage";
import ExportConfigPage from "Pages/Admin/Edition/ExportConfigPage";
import ShoppingPropertiesPage from "Pages/Admin/Edition/Setting/Shopping/ShoppingPropertiesPage";
import ShoppingViewPropertiesPage from "../../../Pages/Admin/Edition/Setting/Shopping/ShoppingViewPropertiesPage";

const ShoppingRouting: RouteObject[] = [
    {
        index: true,
        element: <ShoppingListingPage />,
    },
    {
        path: 'delivery-paper',
        element: <DeliveryPaperPage />,
    },
    {
        path: 'export-config',
        element: <ExportConfigPage collection="shopping" />,
    },
    {
        path: 'properties',
        element: <ShoppingPropertiesPage shoppingType="shopping" />
    },
    {
        path: 'view-properties',
        element: <ShoppingViewPropertiesPage shoppingType="shopping" />
    }
]

export default ShoppingRouting
