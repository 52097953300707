import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {
    Button, Modal,
    ModalBody as Body,
    ModalFooter as Footer,
    ModalHeader as Header,
    ModalTitle as Title
} from "react-bootstrap";
import {classUtils as c} from "Vendor/Utils/ClassUtils";
import {GroupedActionIcon} from "Config/Collection/GroupedAction";
import ListingSetting from "Entity/Collection/ListingSetting";
import GroupedAction from "Entity/Collection/ListingSetting/GroupedAction";
import useTranslationDataUtils from "../../../Vendor/Utils/TranslationDataUtils";
import {ShoppingActionModal} from "./_ShoppingModal";

export interface _MainModalProps {
    showMainActionModal: boolean
    setShowMainActionModal: (show: boolean) => void
    selected: number[]
    clearSelection: () => void,
    setting: ListingSetting
    availableActions: GroupedAction[]
    hasSearch: boolean
    handleShowActionModal: (groupedAction?: GroupedAction, actionFromMain?:boolean) => void
    setShoppingActionType: (actionType: ShoppingActionModal) => void,
}

const _MainModal: FC<_MainModalProps> = ({showMainActionModal, setShowMainActionModal, selected, clearSelection, setting, availableActions, hasSearch, handleShowActionModal, setShoppingActionType}) => {
    const {t} = useTranslation()
    const {translate} = useTranslationDataUtils()

    return <Modal show={showMainActionModal} onHide={() => {
        setShowMainActionModal(false)
    }} className="grouped-action-modal">
        <Header>
            <Title>{t('app.groupedActionTitle.file', {count: selected.length})}</Title>
            <div className="action-shortcut">
                {availableActions.filter(groupedAction => groupedAction.shortcut).length > 0 &&
                    availableActions.filter(groupedAction => groupedAction.shortcut).map((groupedAction, key) =>
                        <button className={c("btn btn-icon btn-icon-only btn-icon-without-padding", 'btn-outline-' + (groupedAction.action === 'remove' ? 'danger' : 'primary'))}
                                disabled={groupedAction.action !== 'mailingList' && selected.length === 0}
                                onClick={() => {
                                    handleShowActionModal(groupedAction)
                                }}
                        ><i className={c("fa-solid", groupedAction.action !== undefined ? "fa-" + GroupedActionIcon[groupedAction.action] : 'fa-circle-nodes')} key={key}></i></button>
                    )
                }
            </div>
        </Header>
        <Body>
            {/* Basically actions */}
            {availableActions.filter(groupedAction => !groupedAction.shortcut && !groupedAction.property && groupedAction.action).length > 0 && (
                <div className="mb-3">
                    {availableActions.filter(groupedAction => !groupedAction.shortcut && groupedAction.action).map((groupedAction, key) =>
                        <Button
                            className="me-2 mb-2"
                            variant={groupedAction.action === 'remove' ? 'danger' : 'secondary'}
                            disabled={groupedAction.action !== 'mailingList' && selected.length === 0}
                            onClick={() => {
                                handleShowActionModal(groupedAction)
                                if (groupedAction.action === 'shopping' || groupedAction.action === 'preOrder') {
                                    setShoppingActionType(groupedAction.action)
                                } else {
                                    setShoppingActionType(undefined)
                                }
                            }}>
                            <i className={c("fa-solid", groupedAction.action !== undefined ? "fa-" + GroupedActionIcon[groupedAction.action] : 'fa-circle-nodes')} key={key}></i> {t('app.listing_setting.grouped_action.'+groupedAction.action)}
                        </Button>
                    )}
                </div>
            )}
            {/* Properties actions */}
            {availableActions.filter(groupedAction => !groupedAction.shortcut && groupedAction.property).length > 0 && (
                <div className="mb-3">
                    <h5>{t('app.properties')}</h5>
                    {availableActions.filter(groupedAction => !groupedAction.shortcut && groupedAction.property).map((groupedAction, key) =>
                        <Button
                            className="me-2 mb-2"
                            variant="secondary"
                            onClick={() => {
                                handleShowActionModal(groupedAction)
                            }}>
                            {translate(groupedAction.property ?? {}, 'label')}
                        </Button>
                    )}
                </div>
            )}
        </Body>
        <Footer>
            <Button variant="secondary" onClick={() => {
                setShowMainActionModal(false)
            }}>
                {t('app.close')}
            </Button>
            <Button variant="warning" onClick={() => {
                clearSelection()
                setShowMainActionModal(false)
            }}>
                {t('app.unselect_and_close')}
            </Button>
        </Footer>
    </Modal>
}

export default _MainModal
