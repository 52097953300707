import React, {FC, TextareaHTMLAttributes, useState} from 'react'
import { classUtils as c } from 'Vendor/Utils/ClassUtils'
import { useTranslation } from 'react-i18next'
import {TranslationField} from "../../../../Components/Listing/Listing";
import useTranslationDataUtils from "../../../Utils/TranslationDataUtils";

export interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    id: string
    name: string
    fullName: string
    value: string
    setValue?: (value: string) => void
    label?: string
    i18nLabel?: string
    i18nLabels?: TranslationField
    className?: string
    required?: boolean
    disabled?: boolean
    help?: string
}

const TextareaType: FC<TextareaProps> = ({
    id,
    name,
    fullName,
    value,
    setValue,
    className,
    label,
    i18nLabel,
    i18nLabels,
    required = true,
    disabled = false,
    help = null,
    ...rest
}) => {
    const { t } = useTranslation()
    const {translateField} = useTranslationDataUtils()
    const [textLabel] = useState<string>(label ? label : i18nLabels ? translateField(i18nLabels) : t(i18nLabel ?? `app.${name}`))

    return (
        <div className="form-floating mb-3">
            <textarea
                id={id}
                name={fullName}
                className={c('form-control', className)}
                placeholder={textLabel}
                required={required}
                value={value ?? ''}
                onChange={(e) => !disabled && setValue && setValue(e.target.value)}
                disabled={disabled}
                rows={5}
                {...rest}
            />
            <label htmlFor={id}>
                {textLabel} :
            </label>
            {help &&
                <p
                    id={fullName + '_help'}
                    className="form-text mb-0 help-text"
                >
                    {help}
                </p>
            }
        </div>
    )
}

export default TextareaType
