import React, {FC, useContext, useEffect, useState} from "react";
import {Outlet} from "react-router-dom";
import {Navbar as GlobalNavbar, NavbarItem} from "Components/Layout/Navbar";
import Header from "Components/Layout/Header";
import {UserContext} from "../../Vendor/Context/UserContextProvider";
import {Button, Modal} from "react-bootstrap";
import {EditableProperty} from "../../Entity/Organization";
import LoadingButton from "../../Components/Button/LoadingButton";

const _Navbar: FC = () => {
    const { currentUser } = useContext(UserContext)
    const [search, setSearch] = useState<string>('')
    const [show, setShow] = useState<boolean>(false)
    const [items, setItems] = useState<NavbarItem[]>([])
    const [disabled, setDisabled] = useState<boolean>(true)
    const [handleAdd, setHandleAdd] = useState<(() => void)|undefined>(undefined)
    const [matchingProperties, setMatchingProperties] = useState<EditableProperty[]>([])
    const [showModal, setShowModal] = useState(false);

    const handleSetting = () => {
        setShowModal(true);
    }

    useEffect(() => {
        let items: NavbarItem[] = [
            {
                i18nLabel: 'app.contacts',
                path: 'contact'
            },
            {
                i18nLabel: 'app.companies',
                path: 'company'
            },
        ]

        if (currentUser?.currentOrganization?.options?.includes('sales')) {
            items.push({
                i18nLabel: 'app.deals',
                path: 'deal'
            })
        }

        setItems(items)
    }, [currentUser?.currentOrganization?.options])

    return (
        <>
            <Header title={'CRM'} search={search} setSearch={setSearch} add={() => {
                handleAdd !== undefined && handleAdd()
                setShow(true)
            }} disabled={disabled}
                    onLoad={true}
                    setting={handleSetting}
                    showSetting={!!matchingProperties.length}
            />
            <GlobalNavbar locationBase='/crm' items={items} />
            <Outlet context={[show, setShow, search, setSearch, disabled, setDisabled, setHandleAdd, setMatchingProperties]} />
        </>
    )
}

export default _Navbar
