import { FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Button,
    Modal,
    ModalBody as Body,
    ModalFooter as Footer,
    ModalHeader as Header,
    ModalTitle as Title,
    Row,
    Col,
} from 'react-bootstrap'
import GroupedAction from 'Entity/Collection/ListingSetting/GroupedAction'
import LoadingButton from '../../Button/LoadingButton'
import Api from 'Vendor/Api'
import Collection from 'Config/Collection'
import TextType from '../../../Vendor/Components/Form/Type/TextType'
import Property from '../../../Entity/Property'
import ChoiceType from 'Vendor/Components/Form/Type/ChoiceType'
import CheckboxType from 'Vendor/Components/Form/Type/CheckboxType'
import { UserContext } from 'Vendor/Context/UserContextProvider'
import ModeChoice from '../../../Form/Choice/Collection/Rename/ModeChoice'
import AddTextModeChoice from '../../../Form/Choice/Collection/Rename/AddTextModeChoice'

type Payload = {
    mode: string
    prepend?: string
    append?: string
    findText?: string
    replaceText?: string
    locales?: string[]
    selected: number[]
}

type ProductItem = {
    id: number
    name: string
    locale: string
}

export interface _RenameModalProps {
    collection?: Collection
    id?: number
    path: string
    showActionModals: { [key: string]: boolean | Property }
    handleShowActionModal: (
        groupedAction?: GroupedAction,
        actionFromMain?: boolean,
    ) => void
    fromMain: boolean
    handleShowMainActionModal: () => void
    selected: number[]
    actionModalFormIsLoading: boolean
    actionModalFormIsLoaded: boolean
    setActionModalFormIsLoaded: (isLoaded: boolean) => void
    handleActionModalOnResponse: () => void
    handleActionModalOnSuccess: () => void
    handleActionModalOnSubmit: () => void
}

const _RenameModal: FC<_RenameModalProps> = ({
    collection,
    id,
    path,
    showActionModals,
    handleShowActionModal,
    fromMain,
    handleShowMainActionModal,
    selected,
    actionModalFormIsLoading,
    handleActionModalOnResponse,
    handleActionModalOnSuccess,
    handleActionModalOnSubmit,
}) => {
    const { t } = useTranslation()
    const { currentUser } = useContext(UserContext)
    const [mode, setMode] = useState<string>('addText')
    const [addTextMode, setAddTextMode] = useState<string>('prependText')
    const [textToReplace, setTextToReplace] = useState<string>('')
    const [textToSearch, setTextToSearch] = useState<string>('')
    const [prependText, setPrependText] = useState<string>('')
    const [appendText, setAppendText] = useState<string>('')
    const [locales, setLocales] = useState<{
        [key: string]: boolean
    }>({})
    const [data, setData] = useState<any[]>([])

    const handleCheckboxChange = (language: string, isChecked: boolean) => {
        setLocales((prevState) => ({
            ...prevState,
            [language]: isChecked,
        }))
    }

    function onSubmit() {
        const payload: Payload = {
            mode,
            selected: selected.slice(0, 20),
        }

        if (mode === 'addText') {
            payload.prepend = prependText
            payload.append = appendText
        } else if (mode === 'findAndReplace') {
            payload.findText = textToSearch
            payload.replaceText = textToReplace
        }

        if (collection === 'catalog') {
            payload.locales = Object.keys(locales).filter(
                (key) => locales[key] === true,
            )
        }

        handleActionModalOnSubmit()

        Api.post(
            `/grouped-action/rename/${collection}${id ? '/' + id : ''}`,
            payload,
        )
            .then(() => {
                handleActionModalOnSuccess()
            })
            .catch(() => {})
            .then(() => {
                handleActionModalOnResponse()
                resetInputs()
            })
    }

    const resetInputs = () => {
        setTextToSearch('')
        setTextToReplace('')
        setPrependText('')
        setAppendText('')
        setAddTextMode('prependText')
    }

    useEffect(() => {
        resetInputs()
    }, [mode])

    useEffect(() => {
        if (currentUser) {
            const initialLanguage =
                currentUser?.currentOrganization?.languages[0]
            setLocales({ [initialLanguage as string]: true })
        }
    }, [currentUser])

    useEffect(() => {
        if (showActionModals.rename) {
            const subpath = collection === 'catalog' ? 'products' : 'files'

            Api.get(`${path}/${subpath}-by-ids`, {
                params: { ids: selected.slice(0, 5).join(',') },
            }).then(({ data }) => {
                setData(data)
            })
        }
    }, [showActionModals.rename, id, collection, path, selected])

    const modifyName = (name: string): string => {
        switch (mode) {
            case 'addText':
                return addTextPrefixAndPostfix(name)
            case 'findAndReplace':
                return findAndReplaceText(name)
            default:
                return name
        }
    }

    const splitNameAndExtension = (
        filename: string,
    ): { name: string; extension: string } => {
        const lastDotIndex = filename.lastIndexOf('.')
        if (lastDotIndex === -1) {
            return { name: filename, extension: '' }
        } else {
            const name = filename.substring(0, lastDotIndex)
            const extension = filename.substring(lastDotIndex)

            return { name, extension }
        }
    }

    const addTextPrefixAndPostfix = (itemName: string): string => {
        let result = ''
        if (collection === 'bucket') {
            const { name, extension } = splitNameAndExtension(itemName)
            result = prependText + name + appendText + extension
        }

        if (!result) {
            return prependText + itemName + appendText
        }

        return result
    }

    const findAndReplaceText = (itemName: string): string => {
        let result = ''
        if (collection === 'bucket') {
            const { name, extension } = splitNameAndExtension(itemName)
            result = name.replace(textToSearch, textToReplace) + extension
        }

        if (!result) {
            return itemName.replace(textToSearch, textToReplace)
        }

        return result
    }

    const displayTranslationIdentifier = (language: string) => {
        const activeTranslationsLength = Object.values(locales).filter(
            (value) => value === true,
        ).length

        if (activeTranslationsLength >= 2) {
            return (
                <span>
                    {t(`app.groupedActionContent.rename.nameLabel`, {
                        language,
                    })}
                    :{' '}
                </span>
            )
        }
    }

    return (
        <Modal
            size="lg"
            show={showActionModals.rename !== false}
            onHide={handleShowActionModal}
        >
            <Header closeButton={!fromMain}>
                <div className="modal-block-header">
                    {fromMain && (
                        <i
                            className="fa-solid fa-angle-left"
                            onClick={handleShowMainActionModal}
                        ></i>
                    )}
                    <Title>
                        {t(`app.groupedActionTitle.rename.${collection}`, {
                            count: selected.length,
                        })}
                    </Title>
                </div>
            </Header>
            <form>
                <Body>
                    <Row>
                        <Col sm={3}>
                            <ChoiceType
                                choices={ModeChoice}
                                i18nLabel="app.groupedActionChoice.rename.mode"
                                value={mode}
                                setValue={(data) =>
                                    setMode(
                                        Array.isArray(data) ? data[0] : data,
                                    )
                                }
                            />
                        </Col>
                        {mode === 'addText' && (
                            <>
                                <Col sm={6}>
                                    {addTextMode === 'prependText' && (
                                        <TextType
                                            id="prepend"
                                            name="prepend"
                                            fullName="prepend"
                                            value={prependText}
                                            setValue={setPrependText}
                                            required
                                            i18nLabel="app.groupedActionChoice.rename.prependText"
                                        />
                                    )}
                                    {addTextMode === 'appendText' && (
                                        <TextType
                                            id="append"
                                            name="append"
                                            fullName="append"
                                            value={appendText}
                                            setValue={setAppendText}
                                            required
                                            i18nLabel="app.groupedActionChoice.rename.appendText"
                                        />
                                    )}
                                </Col>
                                <Col sm={3}>
                                    <ChoiceType
                                        choices={AddTextModeChoice}
                                        value={addTextMode}
                                        setValue={(data) =>
                                            setAddTextMode(
                                                Array.isArray(data)
                                                    ? data[0]
                                                    : data,
                                            )
                                        }
                                    />
                                </Col>
                            </>
                        )}
                        {mode === 'findAndReplace' && (
                            <>
                                <Col>
                                    <TextType
                                        id="search"
                                        name="search"
                                        fullName="search"
                                        value={textToSearch}
                                        setValue={setTextToSearch}
                                        i18nLabel="app.groupedActionChoice.rename.search"
                                    />
                                </Col>
                                <Col>
                                    <TextType
                                        id="replace"
                                        name="replace"
                                        fullName="replace"
                                        value={textToReplace}
                                        setValue={setTextToReplace}
                                        required
                                        i18nLabel="app.groupedActionChoice.rename.replace"
                                    />
                                </Col>
                            </>
                        )}
                    </Row>
                    <Row>
                        <Col sm={3}>
                            {collection === 'catalog' &&
                                currentUser?.currentOrganization?.languages.map(
                                    (language, index) => (
                                        <CheckboxType
                                            key={index}
                                            id={`item-${language}`}
                                            name={`item-${language}`}
                                            fullName="language"
                                            required={false}
                                            checked={locales[language] || false}
                                            setValue={(isChecked) =>
                                                handleCheckboxChange(
                                                    language,
                                                    isChecked,
                                                )
                                            }
                                            checkedByDefault={index === 0}
                                            i18nLabel={`Nom (${language})`}
                                        />
                                    ),
                                )}
                        </Col>

                        <Col>
                            <h5 className="border border-bottom-2">
                                {t(
                                    `app.groupedActionContent.rename.displayLabel`,
                                )}
                            </h5>
                            {collection === 'catalog'
                                ? data.map((item: ProductItem) => (
                                      <>
                                          {locales.hasOwnProperty(
                                              item.locale,
                                          ) &&
                                              locales[item.locale] && (
                                                  <div
                                                      className="py-2"
                                                      key={`${item.id}`}
                                                  >
                                                      <div key={item.id}>
                                                          {displayTranslationIdentifier(
                                                              item.locale,
                                                          )}

                                                          {modifyName(
                                                              item.name,
                                                          )}
                                                      </div>
                                                  </div>
                                              )}
                                      </>
                                  ))
                                : data.map((item, index) => (
                                      <div key={index} className="py-2">
                                          {modifyName(item.name)}
                                      </div>
                                  ))}
                        </Col>
                    </Row>
                </Body>
                <Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            handleShowActionModal()
                        }}
                    >
                        {t('app.cancel')}
                    </Button>
                    <LoadingButton
                        disabled={data.length <= 0}
                        variant="primary"
                        isLoading={actionModalFormIsLoading}
                        onClick={onSubmit}
                    >
                        {t('app.rename')}
                    </LoadingButton>
                </Footer>
            </form>
        </Modal>
    )
}

export default _RenameModal
