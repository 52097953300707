import {RouteObject} from "react-router-dom";
import React from "react";
import CompanyListingPage from "../../Pages/Admin/Edition/Setting/CompanyListingPage";
import DealListingPage from "../../Pages/Admin/Edition/Setting/DealListingPage";
import ContactListingPage from "../../Pages/Admin/Edition/Setting/ContactListingPage";
import OrganizationPage from "../../Pages/Admin/Edition/Setting/MainPage";
import MiscPage from "../../Pages/Admin/Edition/Setting/MiscPage";
import PreOrderListingPage from "../../Pages/Admin/Edition/Setting/PreOrderListingPage";
import ShoppingRouting from "./Setting/ShoppingRouting";
import PreOrderRouting from "./Setting/PreOrderRouting";
import ExportConfigPage from "../../Pages/Admin/Edition/ExportConfigPage";

const SettingRouting: RouteObject[] = [
    {
        index: true,
        element: <OrganizationPage />,
    },
    {
        path: 'misc',
        element: <MiscPage />,
    },
    {
        path: 'company',
        element: <CompanyListingPage />,
    },
    {
        path: 'company/export-config',
        element: <ExportConfigPage collection="company" />,
    },
    {
        path: 'deal',
        element: <DealListingPage />,
    },
    {
        path: 'deal/export-config',
        element: <ExportConfigPage collection="deal" />,
    },
    {
        path: 'contact',
        element: <ContactListingPage />,
    },
    {
        path: 'contact/export-config',
        element: <ExportConfigPage collection="contact" />,
    },
    {
        path: 'shopping',
        children: ShoppingRouting,
    },
    {
        path: 'pre-order',
        children: PreOrderRouting,
    },
]

export default SettingRouting