import React, {FC, useEffect, useState} from "react";
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import {Navbar as GlobalNavbar, NavbarItem} from "../../../../Components/Layout/Navbar";
import Header from "../../../../Components/Layout/Header";
import GlobalLoader from "Vendor/Components/GlobalLoader";
import Brand from "../../../../Entity/Brand";
import useTranslationData from "Vendor/Utils/TranslationDataUtils";
import Api from "Vendor/Api";

const Navbar: FC = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    const {translate} = useTranslationData()
    const [brand, setBrand] = useState<Brand|undefined>(undefined)

    useEffect(() => {
        if (location && location.state && typeof location.state === 'object') {
            let brand = location.state as Brand
            setBrand(brand);
        }
        else {
            let id = params.id;
            Api.get('/admin/edition/brand/' + id)
                .then((response ) => {
                    let brand = response.data as Brand
                    setBrand(brand)
                })
        }
    }, [location, params])

    const items: NavbarItem[] = [
        {
            i18nLabel: 'app.main',
            path: ''
        },
    ]

    return (
        <>
            {!brand
                ? <GlobalLoader />
                : <>
                    <Header title={brand.name} prev={() => {
                        navigate('/admin/edition/brand')
                    }} />
                    <GlobalNavbar locationBase={"/admin/edition/brand/" + (brand?.id ?? '')} state={brand} items={items} />
                    <Outlet context={[brand, setBrand]} />
                </>
            }
        </>
    )
}

export default Navbar
