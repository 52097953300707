import React, {FC, useContext, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import { UserContext } from 'Vendor/Context/UserContextProvider'
import Form, {DataType} from "Vendor/Components/Form";
import User from "Entity/User";
import LoginForm from "Form/Security/LoginForm";
import FormRow from "../../Vendor/Components/Form/FormRow";
import LoadingButton from "../../Components/Button/LoadingButton";
import {useTranslation} from "react-i18next";
import FormError from "Vendor/Components/Form/FormError";
import {MenuItem} from "../../Components/Layout/Sidebar/_MenuItem";
import useTranslationDataUtils from "../../Vendor/Utils/TranslationDataUtils";

const LoginPage: FC = () => {
    const { setCurrentUser, getInitialPage } = useContext(UserContext)
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [loginData, setLoginData] = useState<DataType>({})
    const onSubmit = () => {
        setIsLoading(true)
    }

    const onSuccess = (user: User) => {
        setCurrentUser(user)
        /* todo Browser History last page */
        navigate(getInitialPage())
    }

    const onResponse = () => {
        setIsLoading(false)
    }

    return (
        <div className="modal-content">
            <Form onSuccess={onSuccess} onSubmit={onSubmit} onResponse={onResponse} form={new LoginForm()} path="/login" setFormData={setLoginData} formData={loginData}>
                <div className="modal-header">
                    <h5 className="modal-title" id="loginModalLabel">
                        {t('app.login')}
                    </h5>
                </div>
                <div className="modal-body">
                    <FormError key={0} />
                    <FormRow name="username" key={1} />
                    <FormRow name="password" key={2} />
                    <div className="d-flex justify-content-end">
                        <Link to="/reset-password">{t('app.forgot_password')}</Link>
                    </div>
                </div>
                <div className="modal-footer">
                    <LoadingButton type="submit" isLoading={isLoading}>
                        {t('app.login')}
                    </LoadingButton>
                </div>
            </Form>
        </div>
    )
}

export default LoginPage
