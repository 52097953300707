import {RouteObject} from "react-router-dom";
import React from "react";
import ExportConfigPage from "Pages/Admin/Edition/ExportConfigPage";
import PreOrderListingPage from "../../../Pages/Admin/Edition/Setting/PreOrderListingPage";
import ShoppingPropertiesPage from "Pages/Admin/Edition/Setting/Shopping/ShoppingPropertiesPage";
import ShoppingViewPropertiesPage from "../../../Pages/Admin/Edition/Setting/Shopping/ShoppingViewPropertiesPage";

const PreOrderRouting: RouteObject[] = [
    {
        index: true,
        element: <PreOrderListingPage />,
    },
    {
        path: 'export-config',
        element: <ExportConfigPage collection="pre-order" />,
    },
    {
        path: 'properties',
        element: <ShoppingPropertiesPage shoppingType="pre-order" />
    },
    {
        path: 'view-properties',
        element: <ShoppingViewPropertiesPage shoppingType="pre-order" />
    }
]

export default PreOrderRouting
