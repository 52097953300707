import React, {FC, useCallback, useContext, useEffect, useRef, useState} from 'react'
import Header from "../Layout/Header";
import {useTranslation} from "react-i18next";
import {Accordion, Button} from "react-bootstrap";
import LoadingButton from "../Button/LoadingButton";
import FormEmpty, {FormResponseType} from "Vendor/Components/FormEmpty";
import {UserContext} from "Vendor/Context/UserContextProvider";
import useTranslationDataUtils from "Vendor/Utils/TranslationDataUtils";
import { classUtils as c } from 'Vendor/Utils/ClassUtils'
import Product from "Entity/Product";
import File from "Entity/File";
import Api from "Vendor/Api";
import ChooseItemsModal from "./ChooseItemsModal";
import useHumanizeFileSizeUtils from "Vendor/Utils/HumanizeFileSizeUtils";
import {BannerContext} from "../../Vendor/Context/BannerContextProvider";
import CheckboxType from "../../Vendor/Components/Form/Type/CheckboxType";
import Collection from "../../Config/Collection";
import { ExternalFiles } from 'Config/ExternalFiles';

type BimboShareModalProps = {
    defaultCollection?: Collection
    defaultSelected?: number[]
    handleClose: (refresh?: boolean) => void
    currentBimboShare?: string
    setCurrentBimboShare?: (currentBimboShare?: string) => void
}

interface Items {
    buckets: {
        [key: string]: File[]
    }

    catalogs: {
        [key: string]: Product[]
    }
}

interface BimboShareAdditionalData {
    files: number[]
    products: number[]
    save?: boolean
    send?: boolean
    productWithPDF?: number
    productOriginalFile?: number
    productUniqueDirectory?: number
}

type FormAction = "save" | "send"

interface EditResponse {
    uuid: string
    items: Items,
    externalFiles: ExternalFiles,
    formType: FormResponseType,
    productUniqueDirectory: boolean,
    productOriginalFile: boolean,
    productWithPDF: boolean,
}

const BimboShareModal: FC<BimboShareModalProps> = ({
                                                         handleClose,
                                                         currentBimboShare,
                                                         setCurrentBimboShare,
                                                         defaultCollection,
                                                         defaultSelected,
}) => {
    const { setBanner } = useContext(BannerContext);
    const bimboSharePath = `/bimbo-share/${currentBimboShare ?? 'new'}`
    const {t} = useTranslation()
    const {currentUser} = useContext(UserContext)
    const {translate} = useTranslationDataUtils()
    const [formType, setFormType] = useState<boolean | FormResponseType>(!!currentBimboShare)
    const [externalFiles, setExternalFiles] = useState<ExternalFiles>({})
    const [items, setItems] = useState<Items|undefined>(() => {
        if (currentBimboShare) {
            Api.get(bimboSharePath)
                .then(({data}: {data: EditResponse}) => {
                    setItems(data.items)
                    setExternalFiles(data.externalFiles)
                    setFormType(data.formType)
                    setProductUniqueDirectory(data.productUniqueDirectory)
                    setProductOriginalFile(data.productOriginalFile)
                    setProductWithPDF(data.productWithPDF)
                })
            return undefined
        }

        if (defaultCollection) {
            Api.post('/bimbo-share/defaultItems', {
                defaultCollection,
                defaultSelected,
            })
            .then(({data}: {data: EditResponse}) => {
                setItems(data.items)
                setProductUniqueDirectory(data.productUniqueDirectory)
                setProductOriginalFile(data.productOriginalFile)
                setProductWithPDF(data.productWithPDF)
            })
            return undefined
        }

        let tempItems: Items = {
            buckets: {},
            catalogs: {},
        }

        currentUser?.currentOrganization?.buckets.forEach(bucket => {
            tempItems.buckets[bucket.primarySlug] = []

        })
        currentUser?.currentOrganization?.catalogs.forEach(catalog => {
            tempItems.catalogs[catalog.primarySlug] = []
        })

        return tempItems;
    })
    const [showModal, setShowModal] = useState<string|undefined>(undefined)
    const {humanFileSize} = useHumanizeFileSizeUtils()
    const [additionalData, setAdditionalData] = useState<BimboShareAdditionalData|undefined>(undefined)
    const handleShowModal = useCallback((slug: string, show: boolean = false) => {
        if (show) {
            setShowModal(slug)
        } else if (showModal === slug) {
            setShowModal(undefined)
        }
    }, [showModal, setShowModal])
    const [formAction, setFormAction] = useState<FormAction>("save")
    const [onLoading, setOnLoading] = useState<boolean>(false)
    const [productWithPDF, setProductWithPDF] = useState<boolean>(false)
    const [productOriginalFile, setProductOriginalFile] = useState<boolean>(false)
    const [productUniqueDirectory, setProductUniqueDirectory] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)
    const [hasEdited, setHasEdited] = useState<boolean>(false)
    const inputFileRef = useRef(null);

    const handleSuccess = (data: EditResponse) => {
        setBanner({type: 'success', i18nTitle: 'app.save', i18nMessage: 'app.data_saved_message'})
        setItems(data.items)
        let tempExternalFiles: ExternalFiles = {...externalFiles, ...data.externalFiles}
        setExternalFiles(tempExternalFiles)
        setFormType(data.formType)
        setSuccess(true)
        setCurrentBimboShare && setCurrentBimboShare(data.uuid)
        setHasEdited(true)
        setProductUniqueDirectory(data.productUniqueDirectory)
        setProductOriginalFile(data.productOriginalFile)
        setProductWithPDF(data.productWithPDF)
    }

    const handleOpenFileInput = () => {
        if (inputFileRef) {
            // @ts-ignore
            inputFileRef.current.click()
        }
    }

    const handleUploadExternalFiles = useCallback((files: any) => {
        let tempExternalFiles: ExternalFiles = {...externalFiles}
        for (let i = 0; i < files.length; i++) {
            tempExternalFiles[files[i].name] = {
                file_size: files[i].size,
                path: files[i].name,
                state: 'pending',
                data: files[i],
            }
        }
        setExternalFiles(tempExternalFiles)
    }, [externalFiles])

    const nameFilePath = (filePath: string) => {
        const segments = filePath.split('/')

        return segments[segments.length - 1]
    }

    useEffect(() => {
        if (currentBimboShare) {
            const pendingFiles = Object.entries(externalFiles).filter(
                ([, externalFile]) => externalFile.state === 'pending'
            )

            if (pendingFiles.length === 0) return;

            pendingFiles.forEach(([key, externalFile]) => {
                let formData = new FormData()
                formData.append('files[]', externalFile.data)
                setExternalFiles(prevExternalFiles => ({
                    ...prevExternalFiles,
                    [externalFile.path]: {
                        ...externalFile,
                        state: 'uploading',
                    }
                }))

                Api.post(`/bimbo-share/${currentBimboShare}/external-files/upload`, formData, {
                    baseURL: `https://s1-upload.${window.location.host.split(':')[0]}${process.env.REACT_APP_BACKEND_PORT ? ':' + process.env.REACT_APP_BACKEND_PORT: ''}`,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent;
                        let percent = Math.min(100, Math.floor((loaded * 100) / total));

                        setExternalFiles(prevExternalFiles => ({
                            ...prevExternalFiles,
                            [externalFile.path]: {
                                ...prevExternalFiles[externalFile.path],
                                uploadProgress: percent,
                            }
                        }));
                    }
                }).then(({ data }) => {
                    setExternalFiles(prevExternalFiles => ({
                        ...prevExternalFiles,
                        [externalFile.path]: {
                            ...data.externalFiles[externalFile.path],
                            state: 'uploaded'
                        }
                    }));
                }).catch(() => {
                    setExternalFiles(prevExternalFiles => ({
                        ...prevExternalFiles,
                        [externalFile.path]: {
                            ...externalFile,
                            state: 'error',
                        }
                    }))
                })
            })
        }
    }, [currentBimboShare, externalFiles])

    return (
        <div id="bimboShareModal">
            <Header
                title={t(currentBimboShare ? 'app.editBimboShare' : 'app.newBimboShare')}
                prevRotate={true}
                prevDisabled={onLoading}
                prev={() => {
                    handleClose(hasEdited)
                }}
            />
            <div className="content">
                <div className="selection">
                    <Accordion>
                        {currentUser?.currentOrganization?.buckets.map((bucket, key) =>
                        bucket.hidden ? null :
                            <>
                                <Accordion.Item eventKey={`bucket_${bucket.primarySlug}`}>
                                    <Accordion.Header>
                                        <span className="title">
                                            <i className={`fa-solid fa-${bucket.icon ?? 'file'}`}></i>
                                            {translate(bucket, 'name')}
                                            <span className={c('brand', !bucket.brand && 'brand-corporate')}>
                                               ({bucket.brand?.name ?? t('app.corporate')})
                                            </span>
                                        </span>
                                        <div>
                                            <span className="text-primary">{items !== undefined ? items?.buckets[bucket.primarySlug]?.length ?? 0 : <i className="fa-solid fa-circle-notch fa-spin disabled"></i>}</span>
                                            <i className={c("fa-solid text-primary", items !== undefined ? 'fa-plus' : 'fa-circle-notch fa-spin disabled')}
                                               onClick={e => {
                                                   e.stopPropagation()
                                                   if (items !== undefined) {
                                                       handleShowModal(`bucket/${bucket.primarySlug}`, true)
                                                   }
                                               }}
                                            ></i>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {items?.buckets[bucket.primarySlug]?.length ?
                                            <ul>
                                                {items?.buckets[bucket.primarySlug]?.map((file, key) =>
                                                    <li key={key}>
                                                        <div>
                                                            <span className="icon">
                                                                <img src={file.thumbnail?.replaceAll('__SIZING__', 'small')}  alt={file.name ?? file.id}/>
                                                            </span>
                                                            <span className="name">{file.name}</span>
                                                        </div>
                                                        <div>
                                                            <span className="size">{file.__size ? humanFileSize(file.__size) : t('app.error')}</span>
                                                            <button className="remove"
                                                                onClick={() => {
                                                                    let tempItems = {...items}
                                                                    tempItems.buckets[bucket.primarySlug] = tempItems.buckets[bucket.primarySlug].filter(comparedFile => comparedFile.id !== file.id)
                                                                    setItems(tempItems)
                                                                }}
                                                            >
                                                                <i className="fa-solid fa-x"></i>
                                                            </button>
                                                        </div>
                                                    </li>
                                                )}
                                            </ul>
                                            : <div className="text-center">{t('app.emptySelection')}</div>
                                        }
                                    </Accordion.Body>
                                </Accordion.Item>
                                {items && <ChooseItemsModal
                                    show={showModal === `bucket/${bucket.primarySlug}`}
                                    setShow={(show: boolean = false) => {
                                        handleShowModal(`bucket/${bucket.primarySlug}`, show)
                                    }}
                                    selected={items.buckets[bucket.primarySlug] ?? []}
                                    setSelected={(selected) => {
                                        let tempItems = {...items}
                                        tempItems.buckets[bucket.primarySlug] = selected as File[]

                                        setItems(tempItems)
                                    }}
                                    title={translate(bucket, 'name')}
                                    path={`/b/${bucket.primarySlug}`}
                                    entity="bucket"
                                    multiple={true}
                                />}
                            </>
                        )}
                        {currentUser?.currentOrganization?.catalogs.map((catalog, key) =>
                            <>
                                <Accordion.Item eventKey={`catalog_${catalog.primarySlug}`}>
                                    <Accordion.Header>
                                        <span className="title">
                                            <i className="fa-solid fa-bag-shopping"></i>
                                            {translate(catalog, 'name')}
                                            <span className={c('brand', !catalog.brand && 'brand-corporate')}>
                                               ({catalog.brand?.name ?? t('app.corporate')})
                                            </span>
                                        </span>
                                        <div>
                                            <span className="text-primary">{items !== undefined ? items?.catalogs[catalog.primarySlug]?.length ?? 0 : <i className="fa-solid fa-circle-notch fa-spin disabled"></i>}</span>
                                            <i className={c("fa-solid  text-primary", items !== undefined ? 'fa-plus' : 'fa-circle-notch fa-spin disabled')}
                                               onClick={e => {
                                                   e.stopPropagation()
                                                   if (items !== undefined) {
                                                       handleShowModal(`catalog/${catalog.primarySlug}`, true)
                                                   }
                                               }}
                                            ></i>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {items?.catalogs[catalog.primarySlug]?.length ?
                                            <>
                                                <div key={0}>
                                                    <CheckboxType id="productWithPDF" name="productWithPDF" fullName="productWithPDF" required={false} checked={productWithPDF} setValue={setProductWithPDF} checkedByDefault={false} i18nLabel="app.bimboShareForm.productWithPDF" />
                                                    <CheckboxType id="productOriginalFile" name="productOriginalFile" fullName="productOriginalFile" required={false} checked={productOriginalFile} setValue={setProductOriginalFile} checkedByDefault={false} i18nLabel="app.bimboShareForm.productOriginalFile" />
                                                    <CheckboxType id="productUniqueDirectory" name="productUniqueDirectory" fullName="productUniqueDirectory" required={false} checked={productUniqueDirectory} setValue={setProductUniqueDirectory} checkedByDefault={false} i18nLabel="app.bimboShareForm.productUniqueDirectory" />
                                                </div>
                                                <ul key={1}>
                                                    {items?.catalogs[catalog.primarySlug]?.map((product, key) =>
                                                        <li key={key}>
                                                            <div>
                                                                <span className="icon">
                                                                    <img src={product.thumbnail?.replaceAll('__SIZING__', 'small')}  alt={product.name ?? product.id}/>
                                                                </span>
                                                                <span className="name">{translate(product, 'name')}</span>
                                                            </div>
                                                            <div>
                                                                <button className="remove"
                                                                        onClick={() => {
                                                                            let tempItems = {...items}
                                                                            tempItems.catalogs[catalog.primarySlug] = tempItems.catalogs[catalog.primarySlug].filter(comparedProduct => comparedProduct.id !== product.id)
                                                                            setItems(tempItems)
                                                                        }}
                                                                >
                                                                    <i className="fa-solid fa-x"></i>
                                                                </button>
                                                            </div>
                                                        </li>
                                                    )}
                                                </ul>
                                            </>
                                            : <div className="text-center">{t('app.emptySelection')}</div>
                                        }
                                    </Accordion.Body>
                                </Accordion.Item>
                                {items && <ChooseItemsModal
                                    show={showModal === `catalog/${catalog.primarySlug}`}
                                    setShow={(show: boolean = false) => {
                                        handleShowModal(`catalog/${catalog.primarySlug}`, show)
                                    }}
                                    selected={items.catalogs[catalog.primarySlug] ?? []}
                                    setSelected={(selected) => {
                                        let tempItems = {...items}
                                        tempItems.catalogs[catalog.primarySlug] = selected as Product[]
                                        setItems(tempItems)
                                    }}
                                    title={translate(catalog, 'name')}
                                    path={`/c/${catalog.primarySlug}`}
                                    entity="catalog"
                                    multiple={true}
                                />}
                            </>
                        )}
                        <Accordion.Item eventKey={`externalFiles`}>
                            <Accordion.Header>
                                <span className="title">
                                    <i className="fa-solid fa-file-import"></i>
                                    {t('app.external_files.label')}
                                </span>
                                <div>
                                    <span className="text-primary">{Object.values(externalFiles).length ?? 0}</span>
                                    <label className="fa-solid fa-plus text-primary"
                                        onClick={e => {
                                            e.stopPropagation()
                                        }}
                                    >
                                        <input ref={inputFileRef} type="file" id="external-files-input" className="d-none" multiple onChange={(e) => {
                                            if (e.target.files && e.target.files.length) {
                                                e.stopPropagation()
                                                handleUploadExternalFiles(e.target.files)
                                            }
                                        }}/>
                                    </label>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                {Object.values(externalFiles).length ?
                                    <ul>
                                        {Object.values(externalFiles).map((externalFile, key) =>
                                            <li key={key}>
                                                <div>
                                                    {/* <span className="icon"></span> */}
                                                    <span className="name">{nameFilePath(externalFile.path)}</span>
                                                    <span className="ms-2">- {t('app.external_files.state.' + externalFile.state)}{externalFile.uploadProgress && externalFile.uploadProgress && <span>{' - ' + externalFile.uploadProgress + ' %'}{100 === externalFile.uploadProgress && <>{t('app.pending_validation')}</>}</span>}</span>
                                                </div>
                                                <div>
                                                    <span className="size">{humanFileSize(externalFile.file_size)}</span>
                                                    <button className="remove"
                                                        onClick={() => {
                                                            if (currentBimboShare && externalFile.state === 'uploaded') {
                                                                Api.delete(`/bimbo-share/${currentBimboShare}/external-files/remove`, {
                                                                    data: {
                                                                        'filePath': externalFile.path,
                                                                    }
                                                                }).then(() => {
                                                                    let tempExternalFiles: ExternalFiles = {...externalFiles}
                                                                    delete tempExternalFiles[externalFile.path.split('/').reverse()[0]]
                                                                    setExternalFiles(tempExternalFiles)
                                                                }).catch(() => {
                                                                })
                                                            } else {
                                                                let tempExternalFiles: ExternalFiles = {...externalFiles}
                                                                delete tempExternalFiles[externalFile.path.split('/').reverse()[0]]
                                                                setExternalFiles(tempExternalFiles)
                                                            }
                                                        }}
                                                    >
                                                        <i className="fa-solid fa-x"></i>
                                                    </button>
                                                </div>
                                            </li>
                                        )}
                                    </ul>
                                    : <div className="text-center">{t('app.emptySelection')}</div>
                                }
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>

                <div className="form">
                    <FormEmpty
                        formContent={{className: 'form-content'}}
                        formSubmit={{className: "form-actions"}}
                        method={currentBimboShare ? 'PATCH' : 'POST'}
                        path={bimboSharePath}
                        delocateInitForm={formType}
                        additionalData={additionalData}
                        onSubmit={() => {
                            setOnLoading(true)
                        }}
                        onResponse={() => {
                            setOnLoading(false)
                        }}
                        onSuccess={handleSuccess}
                    >
                        <Button variant="secondary" disabled={onLoading} onClick={() => {
                            handleClose(hasEdited)
                        }}>{t(currentBimboShare ? 'app.close' : 'app.cancel')}</Button>
                        <LoadingButton
                            type="submit"
                            variant="outline-primary"
                            isLoading={onLoading && formAction === "save"}
                            disabled={onLoading}
                            success={success && formAction === "save"}
                            setSuccess={setSuccess}
                            onClick={(e) => {
                                let tempAdditionalData: BimboShareAdditionalData = {
                                    files: [],
                                    products: [],
                                    send: false,
                                }

                                if (items?.buckets) {
                                    for (const [index, bucket] of Object.entries(items.buckets)) {
                                        bucket.forEach(file => {
                                            if (file.id) {
                                                tempAdditionalData.files.push(file.id)
                                            }
                                        })
                                    }
                                }

                                if (items?.catalogs) {
                                    for (const [index, catalog] of Object.entries(items.catalogs)) {
                                        catalog.forEach(product => {
                                            if (product.id) {
                                                tempAdditionalData.products.push(product.id)
                                            }
                                        })
                                    }

                                    if (tempAdditionalData.products.length) {
                                        tempAdditionalData.productWithPDF = productWithPDF ? 1 : undefined
                                        tempAdditionalData.productOriginalFile = productOriginalFile ? 1 : undefined
                                        tempAdditionalData.productUniqueDirectory = productUniqueDirectory ? 1 : undefined
                                    } else {
                                        tempAdditionalData.productWithPDF = undefined
                                        tempAdditionalData.productOriginalFile = undefined
                                        tempAdditionalData.productUniqueDirectory = undefined
                                    }
                                }

                                tempAdditionalData.send = false;
                                setAdditionalData(tempAdditionalData)
                                setFormAction("save")
                            }}
                        >
                            {t('app.save')}
                            <i className="fa-solid fa-floppy-disk ms-2"></i>
                        </LoadingButton>
                        <LoadingButton
                            type="submit"
                            variant="primary"
                            isLoading={onLoading && formAction === "send"}
                            success={success && formAction === "send"}
                            setSuccess={setSuccess}
                            disabled={onLoading || !currentBimboShare}
                            onClick={(e) => {
                                let tempAdditionalData: BimboShareAdditionalData = {
                                    files: [],
                                    products: [],
                                    send: false,
                                }

                                if (items?.buckets) {
                                    for (const [index, bucket] of Object.entries(items.buckets)) {
                                        bucket.forEach(file => {
                                            if (file.id) {
                                                tempAdditionalData.files.push(file.id)
                                            }
                                        })
                                    }
                                }

                                if (items?.catalogs) {
                                    for (const [index, catalog] of Object.entries(items.catalogs)) {
                                        catalog.forEach(product => {
                                            if (product.id) {
                                                tempAdditionalData.products.push(product.id)
                                            }
                                        })
                                    }

                                    if (tempAdditionalData.products.length) {
                                        tempAdditionalData.productWithPDF = productWithPDF ? 1 : undefined
                                        tempAdditionalData.productOriginalFile = productOriginalFile ? 1 : undefined
                                        tempAdditionalData.productUniqueDirectory = productUniqueDirectory ? 1 : undefined
                                    } else {
                                        tempAdditionalData.productWithPDF = undefined
                                        tempAdditionalData.productOriginalFile = undefined
                                        tempAdditionalData.productUniqueDirectory = undefined
                                    }
                                }

                                tempAdditionalData.send = true;
                                setAdditionalData(tempAdditionalData)
                                setFormAction("send")
                            }}
                        >
                            {t('app.send')}
                            <i className="fa-solid fa-paper-plane ms-2"></i>
                        </LoadingButton>
                    </FormEmpty>
                </div>
            </div>
        </div>
    )
}

export default BimboShareModal
