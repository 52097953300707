import React, {FC, HTMLInputTypeAttribute, useEffect, useState} from 'react'
import PhoneInput, {Value} from "react-phone-number-input/input";
import { classUtils as c } from 'Vendor/Utils/ClassUtils'
import {DefaultInputComponentProps} from "react-phone-number-input/index";
import {useTranslation} from "react-i18next";
import {TranslationField} from "../../../../Components/Listing/Listing";
import useTranslationDataUtils from "../../../Utils/TranslationDataUtils";

export interface PhoneProps extends DefaultInputComponentProps {
    id: string
    name: string
    fullName: string
    value: string
    setValue?: (value: string) => void
    floating?: boolean
    label?: string
    i18nLabel?: string
    i18nLabels?: TranslationField
    className?: string
    required?: boolean
    disabled?: boolean
    help?: string
}

const PhoneType: FC<PhoneProps> = ({
    id,
    name,
    fullName,
    value,
    setValue,
    floating = true,
    label,
    i18nLabel,
    i18nLabels,
    className,
    required = true,
    disabled = false,
    help = null,
    ...rest
}) => {
    const { t } = useTranslation()
    const {translateField} = useTranslationDataUtils()
    const [textLabel] = useState<string>(label ? label : i18nLabels ? translateField(i18nLabels) : t(i18nLabel ?? `app.${name}`))
    const [phoneNumber, setPhoneNumber] = useState<Value|undefined>(undefined)

    useEffect(() => {
        if (phoneNumber && setValue) {
            setValue(phoneNumber)
        }
    }, [phoneNumber])

    if (floating) {
        return (
            <div className="form-floating mb-3">
                <PhoneInput
                    id={id}
                    name={fullName}
                    className={c('form-control', className)}
                    placeholder={textLabel}
                    required={required}
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                    defaultCountry="FR"
                    disabled={disabled}
                    {...rest}
                />
                <label htmlFor={id}>
                    {textLabel} :
                </label>
                {help &&
                    <p
                        id={id + '_help'}
                        className="form-text mb-0 help-text"
                    >
                        {help}
                    </p>
                }
            </div>
        )
    }

    return (<></>)
}

export default PhoneType
