import React, {FC, useState} from 'react'
import Api from 'Vendor/Api'
import {classUtils as c} from 'Vendor/Utils/ClassUtils'
import {useTranslation} from "react-i18next";
import LoadingButton from "./Button/LoadingButton";
import {Button} from "react-bootstrap";
import File from "Entity/File";
import Loader from "../Vendor/Components/Loader";
import Bucket from "../Entity/Bucket";
import _Card from "./Listing/View/_Card";
import Product from "../Entity/Product";
import ListingSetting from "../Entity/Collection/ListingSetting";

const FileDropzone: FC<{
    bucket: Bucket
    product: Product
    files?: File[]
    setFiles: (files: File[]) => void
    disabled?: boolean
    i18nPlaceholder?: string
    onSuccess?: (data: any) => void
}> = ({bucket, product, disabled = false, files, setFiles, i18nPlaceholder, onSuccess}) => {
    const [onDrag, setOnDrag] = useState<boolean>(false)
    const [onLoad, setOnLoad] = useState<string[]>([])
    const [onDelete, setOnDelete] = useState<boolean>(false)
    const hiddenFileInput = React.useRef<HTMLInputElement>(null);
    const { t } = useTranslation()

    const setting: ListingSetting = {
        cardShowSchedule: true,
        cardShowSizeAndWeight: null,
        cardCondensedByDefault: false,
        cardShowBadge: false,
        cardShowStatus: false,
        filters: [],
        groupedActions: [],
        kanbanDatas: [],
        cardDatas: [],
        searchable: [],
        searchableByEmail: false,
        searchableByFirstName: false,
        searchableByLastName: false,
        searchableByName: false,
        sorters: [],
        tableColumns: [],
        views: []
    }

    const upload = (files: FileList) => {
        let formData = new FormData()
        for (let i = 0; i < files.length; i++) {
            formData.append('files[]', files[i])
        }

        setOnLoad(onLoad)
        Api.post(`/${bucket.primarySlug}/${product.id}/simple`, formData, {
                baseURL: `https://s1-upload.${window.location.host.split(':')[0]}${process.env.REACT_APP_BACKEND_PORT ? ':' + process.env.REACT_APP_BACKEND_PORT: ''}`,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
            setFiles([...response.data] as File[])
        }).catch(() => {}).then(() => {
            if (hiddenFileInput.current !== null) {
                hiddenFileInput.current.value = '';
            }
        })
    }

    return <div
        className={c('dropzone dropzone-multiple', files && files.length > 0 && 'dropzone-has-file', onDrag && !disabled && 'dropzone-drag', disabled && 'dropzone-disabled')}

        onDragEnter={(e) => {
            e.preventDefault();
            e.stopPropagation()
            setOnDrag(true)
        }}

        onDragOver={(e) => {
            e.preventDefault();
            e.stopPropagation()
            setOnDrag(true)
        }}

        onDragLeave={(e) => {
            e.preventDefault();
            e.stopPropagation()
            setOnDrag(false)
        }}

        onDrop={(e) => {
            e.preventDefault();
            e.stopPropagation()
            setOnDrag(false)
            if (!disabled) {
                if (e.dataTransfer.files.length) {
                    upload(e.dataTransfer.files)
                }
            }
        }}
    >
        <div className="dropzone-container row mini-listing card-list card-list-condensed">
        {files === undefined
            ? <Loader auto={true} />
            : files.length === 0
                ? <div className="dropzone-placeholder">
                    {i18nPlaceholder ? t(i18nPlaceholder) : t('app.dropzone_placeholder')}
                </div>
                : files.map((file, key) => (
                    <_Card item={file} key={key} selectable={false} condensed={true} entity="bucket" setting={setting} selectMultiple={true} />
                ))
        }
        </div>
        <div className="row justify-content-end mt-3">
            <div className="col-auto">
                <input type="file" multiple={true} className="d-none" ref={hiddenFileInput} onChange={(e) => {
                    if (e.target.files) {
                        if (e.target.files.length) {
                            upload(e.target.files)
                        }
                    }
                }} />
                <Button variant="outline-primary" disabled={onDelete} onClick={() => {
                    hiddenFileInput.current?.click();
                }}>
                    <i className="fa-solid fa-upload me-2" />{t('app.upload')}
                </Button>
            </div>
        </div>
    </div>
}

export default FileDropzone
