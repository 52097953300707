import React, {FC, useEffect, useState} from "react";
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import {Navbar as GlobalNavbar, NavbarItem} from "../../../../Components/Layout/Navbar";
import Header from "../../../../Components/Layout/Header";
import GlobalLoader from "Vendor/Components/GlobalLoader";
import Bucket from "../../../../Entity/Bucket";
import useTranslationData from "Vendor/Utils/TranslationDataUtils";
import Api from "Vendor/Api";

const Navbar: FC = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    const {translate} = useTranslationData()
    const [bucket, setBucket] = useState<Bucket|undefined>(undefined)
    const [disabled, setDisabled] = useState<boolean>(true)
    const [show, setShow] = useState<boolean>(false)
    const [search, setSearch] = useState<string>('')
    const [handleAdd, setHandleAdd] = useState<(() => void)|undefined>(undefined)
    const [items, setItems] = useState<NavbarItem[]>([])
    const [showAddButton, setShowAddButton] = useState<boolean>(true)
    const [showSearch, setShowSearch] = useState<boolean>(true)

    useEffect(() => {
        if (location && location.state && typeof location.state === 'object') {
            let bucket = location.state as Bucket
            setBucket(bucket);
        }
        else {
            let id = params.id;
            Api.get('/admin/edition/bucket/' + id)
                .then((response ) => {
                    let bucket = response.data as Bucket
                    setBucket(bucket)
                })
        }
    }, [location, params])

    useEffect(() => {
        let tempItems: NavbarItem[] = []
        if (bucket) {
            tempItems.push({
                i18nLabel: 'app.main',
                path: ''
            })
            if (!bucket.simpleImport) {
                tempItems.push({
                    i18nLabel: 'app.importConfig',
                    path: 'import-config'
                })
                tempItems.push({
                    i18nLabel: 'app.export_config',
                    path: 'export-config'
                })
            }
            tempItems.push({
                i18nLabel: 'app.listing',
                path: 'listing'
            })
            tempItems.push({
                i18nLabel: 'app.file_groups',
                path: 'group',
            })
            tempItems.push({
                i18nLabel: 'app.transforms',
                path: 'transform',
            })
        }
        setItems(tempItems)
    }, [bucket])

    return (
        <>
            {!bucket
                ? <GlobalLoader />
                : <>
                    <Header title={bucket ? translate(bucket, 'name') : ''}
                            prev={() => {
                                navigate('/admin/edition/bucket')
                            }}
                            search={showSearch ? search : undefined}
                            setSearch={showSearch ? setSearch : undefined}
                            add={showAddButton ? () => {
                                setShow(true)
                            } : undefined}
                    />
                    <GlobalNavbar locationBase={"/admin/edition/bucket/" + (bucket?.id ?? '')} state={bucket} items={items} />
                    {location.pathname.endsWith('/export-config')
                        ? <Outlet context={[show, setShow, search, setSearch, setShowAddButton, setShowSearch, bucket.id]} />
                        : <Outlet context={[bucket, setBucket, show, setShow, search, setSearch, disabled, setDisabled, setHandleAdd]} />
                    }
                </>
            }
        </>
    )
}

export default Navbar
