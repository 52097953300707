import React, {CSSProperties, FC, ReactNode, useCallback, useContext, useEffect, useRef, useState} from 'react'
import {
    Offcanvas,
    OffcanvasBody as Body,
} from 'react-bootstrap'
import Shopping from "../../../Entity/Shopping";
import Api from "../../../Vendor/Api";
import {useTranslation} from "react-i18next";
import useTranslationDataUtils from "../../../Vendor/Utils/TranslationDataUtils";
import message from "../../../Entity/Shopping/Message";
import {ButtonVariant} from "react-bootstrap/types";
import {classUtils as c} from "Vendor/Utils/ClassUtils";
import {UserContext} from "../../../Vendor/Context/UserContextProvider";
import EntityInterface from "../../../Vendor/Definition/EntityInterface";
import Property from "../../../Entity/Property";
import {CollectionContext} from "../../../Vendor/Context/CollectionContextProvider";
import Product from "../../../Entity/Shopping/Product";
import _Product from "./_Product";

export type _CompanyInfoProps = {
    shopping: Shopping
}

const _CompanyInfo: FC<_CompanyInfoProps> = ({
                                                   shopping,
                                                     }) => {

    const {t} = useTranslation()
    const {currentUser} = useContext(UserContext)
    const {getByType} = useContext(CollectionContext)

    if (!shopping.company) return null

    return (
        <>
            <h6>{t('app.company')}</h6>
            <b>{shopping.company
                    ? shopping.company.name
                    : (
                        shopping.customer
                            ? shopping.customer.firstName + ' ' + shopping.customer.lastName
                            : <i className="text-muted">{t('app.not_specified')}</i>
                    )
            }</b>
            {currentUser?.currentOrganization && getByType(shopping.company, 'email', currentUser.currentOrganization.companyProperties).map((emailValue: ReactNode) =>
                <p>{emailValue}</p>
            )}
            {currentUser?.currentOrganization && getByType(shopping.company, 'phone', currentUser.currentOrganization.companyProperties).map((phoneValue: ReactNode) =>
                <p>{phoneValue}</p>
            )}
        </>
    )
}

export default _CompanyInfo
