import React, {FC, useContext, useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import BlankPage from "Components/Layout/BlankPage";
import LoadingButton from "Components/Button/LoadingButton";
import {BannerContext} from "Vendor/Context/BannerContextProvider";
import FormEmpty from "Vendor/Components/FormEmpty";
import Header from "../../../../Components/Layout/Header";


const SecurityPage: FC = () => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)
    const { setBanner } = useContext(BannerContext);

    const onSubmit = () => {
        setIsLoading(true)
    }
    const onResponse = () => {
        setIsLoading(false)
    }
    const onSuccess = () => {
        setSuccess(true)
        setBanner({type: 'success', i18nTitle: 'app.save', i18nMessage: 'app.data_saved_message'})
    }
    const onError = () => {
        setSuccess(false)
    }

    return (
        <>
            <Header title={t('app.security')}
            />
        <BlankPage>
            <FormEmpty
                path={'/admin/edition/security'}
                children={<div className="row justify-content-end align-items-center">
                    <div className="col-auto">
                        <LoadingButton type="submit" isLoading={isLoading}>
                            {t( 'app.validate' )}
                        </LoadingButton>
                    </div>
                </div>}
                method={'POST'}
                onSuccess={onSuccess}
                onSubmit={onSubmit}
                onResponse={onResponse}
                onError={onError}
            />
        </BlankPage>
        </>
    )
}

export default SecurityPage
