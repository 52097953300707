import React, {FC, ReactNode, useCallback, useContext, useEffect, useState,} from 'react'
import {classUtils as c} from "Vendor/Utils/ClassUtils";
import Api from "Vendor/Api";
import {UserContext} from "Vendor/Context/UserContextProvider";
import Import from "Entity/Import";
import {useTranslation} from "react-i18next";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "react-bootstrap"
import _FileCard from "../Components/Import/_FileCard";
import useHumanizeFileSizeUtils from "./Utils/HumanizeFileSizeUtils";
import Config from "../Entity/Import/Config";
import Bucket from "../Entity/Bucket";
import LoadingButton from "../Components/Button/LoadingButton";
import FormEmpty, {FormResponseType} from "./Components/FormEmpty";
import useTranslationDataUtils from "./Utils/TranslationDataUtils";
import _ImportModalTags from "../Components/Modal/_ImportModalTags";
import Tag from "../Entity/Tag";
import Collection from "../Config/Collection";
import {DataType} from "./Components/Form";
import useFormDataParser from "./Utils/FormDataParserUtils";
import CollectionImportModal from "../Components/Modal/CollectionImportModal";
import EntityInterface from "./Definition/EntityInterface";
import Type from "../Config/Collection/Import/Column/Type";
import MatchedBy from "../Config/Collection/Import/Column/MatchedBy";
import Separator from "../Config/Collection/Import/Column/Separator";
import CollectionImport, {Group} from "Entity/Collection/Import";

type UploadSystemProps = {
    children: ReactNode
}

export const SupportedMimesTypes: { [key: string]: string } = {
    'image/png': 'png',
    'image/jpeg': 'jpg',
    'image/jpg': 'jpg',
    'image/gif': 'gif',
    'application/illustrator': 'ai',
    'image/vnd.adobe.photoshop': 'psd',
    'application/vnd.3gpp.pic-bw-small': 'psb',
    'image/tiff': 'tiff',
    'image/jp2': 'jp2',
    'application/pdf': 'pdf',
    'application/msword': 'doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
    'application/vnd.ms-excel': 'xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'application/vnd.ms-powerpoint': 'ppt',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
    'video/avi': 'avi',
    'video/mp4': 'mp4',
    'video/x-flv': 'flv',
    'video/quicktime': 'mov',
    'video/x-m4v': 'm4v',
    'video/webm': 'webm',
    'application/zip': 'zip',
    'application/x-rar-compressed': 'rar',
    'application/gzip': 'gz',
    'application/x-tar': 'tar',
    'audio/mpeg': 'mp3',
    'application/eps': 'eps',
    'application/x-eps': 'eps',
    'image/eps': 'eps',
    'image/x-eps': 'eps',
    'image/heic': 'heic',
    'image/heif': 'heic',
    'application/vnd.adobe.indesign' : 'indd',
    'application/vnd.adobe.indesign-idml-package' : 'idml',
}

export const ImageExtensions: { [key: string]: string } = {
    'png': 'image/png',
    'jpg': 'image/jpg',
}

export const mimeTypeImage = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/tiff', 'image/jp2']
export const mimeTypeVideo = ['video/avi', 'video/mp4', 'video/x-flv', 'video/quicktime', 'video/x-m4v', 'video/webm']
export const mimeTypeAudio = ['audio/mpeg', 'audio/mp3', 'audio/ogg', 'audio/wav', 'audio/x-wav', 'audio/x-m4a']
export const mimeTypeDocument = ['application/vnd.adobe.indesign', 'application/vnd.adobe.indesign-idml-package', 'application/pdf', 'application/msword', 'vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation']
export const mimeTypeArchive = ['application/zip', 'application/x-rar-compressed', 'application/gzip', 'application/x-tar']
export const mimeTypeWithoutView = ['application/illustrator', 'image/vnd.adobe.photoshop']
export const SupportedImportDataMimesTypes = ['text/csv', 'text/plain', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']

export const iconByType = (type: string): string => {
    if (mimeTypeImage.includes(type)) {
        return 'fa-image'
    } else if (mimeTypeVideo.includes(type)) {
        return 'fa-video'
    } else if (mimeTypeAudio.includes(type)) {
        return 'fa-music'
    } else if (mimeTypeArchive.includes(type)) {
        return 'fa-archive'
    } else if (mimeTypeDocument.includes(type)) {
        if ('application/pdf' === type) {
            return 'fa-file-pdf'
        } else if ('application/msword' === type || 'vnd.openxmlformats-officedocument.wordprocessingml.document' === type) {
            return 'fa-file-word'
        } else if ('application/vnd.ms-excel' === type || 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' === type) {
            return 'fa-file-excel'
        } else if ('application/vnd.ms-powerpoint' === type || 'application/vnd.openxmlformats-officedocument.presentationml.presentation' === type) {
            return 'fa-file-powerpoint'
        }
    }

    return 'fa-file'
}

export type Queue = {
    [key: string]: UploadedFile
}

export type UploadedFileState =
    'pending'
    | 'presigned-url-requested'
    | 'presigned-url-not-found'
    | 'ready'
    | 'uploading'
    | 'failed'
    | 'uploaded'

export type UploadedFileAnalyzeState = 'pending' | 'analyzing' | 'failed' | 'accepted' | 'rejected'

export type UploadedFile = {
    name: string,
    data: File,
    state: UploadedFileState
    uploaded: number
    size: number
    type: string
    presignedUrl?: string
    onRemove?: boolean
    previewUrl?: string
    analyzeStatus?: UploadedFileAnalyzeState
    newVersion?: number
    rejectReason?: number
}

type AvailableCollection = {
    collection: Collection,
    id?: number,
    name: string
    entity?: EntityInterface
}
const UploadSystem: FC<UploadSystemProps> = ({children,}) => {
    const {t} = useTranslation()
    const {translate} = useTranslationDataUtils()
    const {formDataParser} = useFormDataParser()
    const {humanFileSize} = useHumanizeFileSizeUtils()
    const {currentUser, setCurrentUser} = useContext(UserContext)
    const [onDrag, setOnDrag] = useState<boolean>(false)
    const [isDroppable, setIsDroppable] = useState<boolean>(true)
    const [show, setShow] = useState<boolean>(false)
    const [showCancelModal, setShowCancelModal] = useState<boolean>(false)
    const [onCancel, setOnCancel] = useState<boolean>(false)
    const [notDroppableReason, setNotDroppableReason] = useState<string | undefined>(undefined)
    const [importState, setImportState] = useState<'pending' | 'uploading' | 'uploaded' | 'success' | 'error'>('pending')
    const [queueSize, setQueueSize] = useState<number>(0)
    const [queueUploaded, setQueueUploaded] = useState<number>(0)
    const [queueUploadedPercent, setQueueUploadedPercent] = useState<number>(0)
    const [filesOnError, setFilesOnError] = useState<number>(0)
    const [sizeOnError, setSizeOnError] = useState<number>(0)
    const [availableCollection, setAvailableCollection] = useState<AvailableCollection | undefined>(undefined)
    const [tags, setTags] = useState<Tag[]>([])
    const [onChangeConfig, setOnChangeConfig] = useState<number | null>(null)
    const [bucket, setBucket] = useState<Bucket | undefined>(undefined)
    const [config, setConfig] = useState<Config | undefined>(undefined)
    const [importForm, setImportForm] = useState<boolean | FormResponseType>(true)
    const [availableBuckets, setAvailableBuckets] = useState<Bucket[]>([])
    const [availableCollections, setAvailableCollections] = useState<AvailableCollection[]>([])
    const [availableForDataImport, setAvailableForDataImport] = useState<boolean>(false)
    const [isValidable, setIsValidable] = useState<boolean>(false)
    const [isValidate, setIsValidate] = useState<boolean>(false)
    const [onValidate, setOnValidate] = useState<boolean>(false)
    const [onAnalyze, setOnAnalyze] = useState<boolean>(false)
    const [analyzable, setAnalyzable] = useState<boolean>(false)

    // Import Data
    const [showImportModal, setShowImportModal] = useState<boolean>(false)
    const [currentImport, setCurrentImport] = useState<CollectionImport | undefined>(undefined)
    const [onWaitStatus, setOnWaitStatus] = useState<boolean>(false)
    const [canDetail, setCanDetail] = useState<boolean>(false)
    const [canImport, setCanImport] = useState<boolean>(false)
    const [onRequest, setOnRequest] = useState<boolean>(false)

    const openImportModal = useCallback(() => {
        setShow(true)
    }, [setShow])

    const closeImportModal = useCallback(() => {
        setShow(false)
    }, [setShow])

    const onParamsChange = (currentImport?: CollectionImport) => {
        if (currentImport && ['uploaded', 'analyzed', 'wait-for-analyze', 'on-analyze'].includes(currentImport.status) && currentImport.uuid) {
            Api.post(`/collection/import/${currentImport.uuid}`, currentImport)
                .then(response => {
                    setCurrentImport({...response.data} as CollectionImport)
                }).catch(() => {
                //todo show error message
            }).then(() => {
                setOnWaitStatus(false)
            })
        }
    }

    const onDetail = () => {
        if (currentImport && currentImport.uuid && canDetail) {
            let postData: DataType = {
                columns: [],
            }
            postData['columns'] = currentImport.columns.map(column => {
                let columnData: {
                    type: Type
                    bucket?: string
                    property?: number
                    language?: string
                    addressField?: string
                    matchedBy?: MatchedBy
                    formatDate?: string
                    propertyMatcher?: number
                    separator?: Separator
                } = {
                    type: column.type ?? 'ignored',
                    bucket: undefined,
                    property: undefined,
                    language: undefined,
                    addressField: undefined,
                    matchedBy: undefined,
                    formatDate: undefined,
                    propertyMatcher: undefined,
                    separator: undefined,
                }

                if (column.type === 'bucket') {
                    columnData.bucket = column.bucket
                } else if (column.type === 'property') {
                    columnData.property = column.property?.id

                    if (column.property?.type === 'address') {
                        columnData.addressField = column.addressField
                    }

                    if (column.property?.translate
                        || (
                            column.property?.type === 'address'
                            && column.addressField === 'country'
                            && column.matchedBy === 'name'
                        )
                        || (
                            column.property?.type === 'phone'
                        )
                    ) {
                        columnData.language = column.language
                    }

                    if (column.property &&
                        (column.property.type === 'collection'
                            || (column.property.type === 'address'
                                && column.addressField === 'country')
                        )
                    ) {
                        columnData.matchedBy = column.matchedBy
                    }

                    if ('date' === column.property?.type) {
                        columnData.formatDate = column.formatDate
                    }

                    if (column.separator) {
                        columnData.separator = column.separator;
                    }
                } else if (column.type === 'name'
                    && currentImport
                    && ['catalog', 'data-collection'].includes(currentImport.collection)
                ) {
                    columnData.language = column.language
                }

                if (column.type && ['bucket', 'mailingList'].includes(column.type)) {
                    columnData.matchedBy = column.matchedBy
                    columnData.separator = column.separator;
                }

                if (column.type === 'company') {
                    columnData.matchedBy = column.matchedBy;
                    if (currentUser?.currentOrganization?.setting.relationContactCompany === 'many') {
                        columnData.separator = column.separator;
                    }
                }

                if (column.type === 'group') {
                    columnData.matchedBy = column.matchedBy
                }

                if (columnData.matchedBy) {
                    columnData.propertyMatcher = column.propertyMatcher?.id
                }

                return columnData
            })
            setOnRequest(true)
            Api.post(`/collection/import/${currentImport.uuid}/analyze`, postData)
                .then(response => {
                    setCurrentImport({...response.data} as CollectionImport)
                }).catch(() => {
                //todo show error message
            }).then(() => {
                setOnRequest(false)
            })
        }
    }

    const onImport = () => {
        if (currentImport && currentImport.uuid && canImport) {
            setOnRequest(true)
            Api.post(`/collection/import/${currentImport.uuid}/validate`)
                .then(response => {
                    setCurrentImport(undefined)
                    setShowImportModal(false)
                    setShow(false)
                    setAvailableCollection(undefined)
                    setCurrentUser(currentUser ? {
                        ...currentUser,
                        queue: undefined
                    } : undefined)
                }).catch(() => {
                //todo show error message
            }).then(() => {
                setOnRequest(false)
            })
        }
    }

    // Use Effects

    useEffect(() => {
        let tempCanDetail = false
        let tempCanImport = false

        if (currentImport && currentImport.uuid) {
            if (currentImport.status === 'analyzed' || currentImport.status === 'detail') {
                tempCanDetail = currentImport.columns.every(column => {
                    return 'wait' !== column.state
                })
            }

            tempCanImport = currentImport.status === 'accepted'
        }

        setCanDetail(tempCanDetail)
        setCanImport(tempCanImport)
    }, [currentImport])

    useEffect(() => {
        if (availableCollection) {
            setShowImportModal(true)

            if (!currentImport) {
                setCurrentImport({
                    buckets: [],
                    collection: availableCollection.collection,
                    collectionId: availableCollection.id,
                    columns: [],
                    createdAt: "",
                    createdBy: undefined,
                    csvSeparator: undefined,
                    form: {
                        types: [],
                    },
                    id: 0,
                    ignoredFirstRow: true,
                    isCsv: false,
                    isGranted: undefined,
                    originalFileName: (Object.values(currentUser?.queue ?? {}).length) ? Object.values(currentUser?.queue ?? {})[0].name : '',
                    preview: [],
                    primarySlug: availableCollection.entity?.primarySlug,
                    rows: 0,
                    status: 'on-init',
                    updatedAt: "",
                    updatedBy: undefined,
                    uuid: ""
                })
                Api.get(`/collection/import/${availableCollection.collection}${availableCollection.entity?.primarySlug ? '/' + availableCollection.entity.primarySlug : ''}`)
                    .then(({data}) => {
                        setCurrentImport({...data} as CollectionImport)
                    }).catch(() => {
                        //todo show error message
                    }).then(() => {
                    })
            }
        } else {
            setShowImportModal(false)
        }
    }, [availableCollection, currentImport, currentUser?.queue])

    useEffect(() => {
        if (showImportModal) {
            setShow(false)
            document.body.classList.add('showImportCollectionModal')
        } else {
            document.body.classList.remove('showImportCollectionModal')
        }
    }, [showImportModal])

    useEffect(() => {
        if (currentImport && ['wait-for-analyze', 'on-analyze'].includes(currentImport.status) && !onWaitStatus) {
            const timer = setTimeout(() => {
                setOnWaitStatus(true)
                Api.get(`/collection/import/${currentImport.uuid}`)
                    .then(response => {
                        setCurrentImport({...response.data} as CollectionImport)
                    }).catch(() => {
                    //todo show error message
                }).then(() => {
                    setOnWaitStatus(false)
                })
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [currentImport, onWaitStatus]);

    const reverseKeyValue = (obj: { [key: string]: string }): { [key: string]: string } => {
        const reversedObj: { [key: string]: string } = {};
        for (const key in obj) {
            const value = obj[key];
            reversedObj[value] = key;
        }

        return reversedObj;
    };

    const mapFileType = (fileType?: string) => {
        const reversedSupportedMimesTypes = reverseKeyValue(SupportedMimesTypes);

        if(!fileType){
            return
        }
        const mappedType = reversedSupportedMimesTypes[fileType];

        return mappedType ? mappedType : '';
    };

    const addFiles = (files: FileList) => {
        if (currentUser) {
            let tempQueue: Queue = {...currentUser?.queue}
            for (let i = 0; i < files.length; i++) {
                let file = files[i]
                const key = file.name;
                let mimeType = file.type;

                const extension = file.name.split(".").pop()?.toLowerCase();

                if (!mimeType) {
                    mimeType = mapFileType(extension) || '';
                }
                tempQueue[key] = {
                    data: file,
                    state: 'pending',
                    uploaded: 0,
                    name: file.name,
                    size: file.size,
                    type: mimeType,
                    presignedUrl: undefined,
                    previewUrl: undefined,
                }
            }
            setCurrentUser({...currentUser, queue: tempQueue})
        }
    }

    const cancelImport = useCallback(() => {
        setOnCancel(true)
        Api.delete('/upload/remove')
            .then(() => {
                setCurrentUser(currentUser ? {
                    ...currentUser,
                    queue: undefined,
                    currentImport: undefined,
                    currentCollectionImport: undefined
                } : undefined)
                setBucket(undefined)
                setConfig(undefined)
                setShow(false)
                setShowCancelModal(false)
            }).catch(() => {
        })
            .then(() => {
                setOnCancel(false)
            })
    }, [currentUser, setCurrentUser])

    const deleteFile = useCallback((key: string) => {
        if (currentUser) {
            let tempQueue: Queue = {...currentUser?.queue}
            if (['failed', 'presigned-url-not-found'].includes(tempQueue[key].state)) {
                delete tempQueue[key]
                setCurrentUser({...currentUser, queue: tempQueue})
            } else {
                tempQueue[key].onRemove = true
                setCurrentUser({...currentUser, queue: tempQueue})
                Api.delete('/upload/remove', {
                    params: {
                        name: key,
                    }
                }).then(() => {
                    let tempQueue: Queue = {...currentUser?.queue}
                    delete tempQueue[key]
                    setCurrentUser({...currentUser, queue: tempQueue})
                }).catch((error) => {
                    let tempQueue: Queue = {...currentUser?.queue}
                    tempQueue[key].onRemove = false
                    setCurrentUser({...currentUser, queue: tempQueue})
                })
            }
        }
    }, [currentUser, setCurrentUser])

    const retry = useCallback((key: string) => {
        if (currentUser) {
            let tempQueue: Queue = {...currentUser?.queue}
            tempQueue[key].state = 'pending'
            tempQueue[key].uploaded = 0
            setCurrentUser({...currentUser, queue: tempQueue})
        }
    }, [currentUser, setCurrentUser])

    const retryAnalyze = useCallback((key: string) => {
        if (currentUser) {
            let tempQueue: Queue = {...currentUser?.queue}
            tempQueue[key].analyzeStatus = 'pending'
            setCurrentUser({...currentUser, queue: tempQueue})
        }
    }, [currentUser, setCurrentUser])

    const changeConfig = useCallback((config: Config | null) => {
        if (config && config.id && bucket && currentUser) {
            setOnChangeConfig(config.id)
            Api.get('/upload/' + bucket.id + '/' + config.id + '/config')
                .then(({data}: { data: { currentImport: Import, form: FormResponseType, analyzeQueue: Queue } }) => {
                    let tempQueue = {...currentUser.queue}
                    let analyzedKeys = Object.keys(data.analyzeQueue);
                    for (const [key, tempFile] of Object.entries(data.analyzeQueue)) {
                        if (tempQueue.hasOwnProperty(key)) {
                            tempQueue[key] = {...tempQueue[key], ...tempFile}
                        }
                    }
                    for (const [key, tempFile] of Object.entries(tempQueue)) {
                        if (!analyzedKeys.includes(key)) {
                            let state = {
                                analyzeStatus: 'pending',
                                newVersion: undefined,
                                rejectReason: undefined,
                            }
                            tempQueue[key] = {...tempFile, ...state} as UploadedFile
                        }
                    }
                    setImportForm(data.form)
                    setOnChangeConfig(null)
                    if (data.currentImport.config) {
                        setConfig({...data.currentImport.config})
                    }
                }).catch(error => {
                    setOnChangeConfig(null)
                })
        } else {
            setOnChangeConfig(null)
            setConfig(undefined)
        }
    }, [bucket?.id, currentUser, setCurrentUser])

    const validateImport = useCallback(() => {
        let form = document.getElementById(process.env.REACT_APP_FORM_DATA_IMPORT_ID as string) as null | HTMLFormElement
        if (currentUser && bucket && config && config.id && form) {
            setOnValidate(true)
            let formData = new FormData(form)
            let data: DataType = {tags: tags, ...formDataParser(formData, typeof importForm === 'object' ? importForm.formName : '')}

            if (importState !== 'uploaded') {
                setCurrentUser({...currentUser, queue: {...currentUser.queue}})
            }

            Api.patch('/upload/' + bucket.id + '/' + config.id + '/config', data).then((response) => {
                let data: { form: FormResponseType, currentImport: Import } = response.data
                setImportForm(data.form)
                setTags([...data.currentImport.tags])
                setIsValidate(true)
                setShow(false)
            }).catch((error) => {
                setIsValidate(false)
            }).then(() => {
                setOnValidate(false)
            })
        }
    }, [bucket, config, currentUser, formDataParser, importForm, importState, setCurrentUser, tags])

    useEffect(() => {
        if (isValidate && importState === 'uploaded') {
            Api.get('/upload/validate').then((response) => {
                if (currentUser) {
                    setCurrentUser({...currentUser, currentImport: undefined, queue: undefined})
                    setBucket(undefined)
                    setConfig(undefined)
                }
                setImportState('success')
            }).catch(() => {
                setImportState('error')
            }).then(() => {
                setIsValidate(false)
            })
        }
    }, [isValidate, importState, currentUser, setCurrentUser])

    useEffect(() => {
        let tempAnalyzable = false;

        if (currentUser
            && bucket?.id
            && config?.id
            && currentUser.queue && Object.keys(currentUser.queue).length
        ) {
            for (const [key, file] of Object.entries(currentUser.queue)) {
                if (file.state === 'uploaded' && (!file.analyzeStatus || 'failed' !== file.analyzeStatus)) {
                    tempAnalyzable = true;
                    break;
                }
            }
        }

        setAnalyzable(tempAnalyzable)

    }, [currentUser, bucket, config])

    useEffect(() => {
        let tempValidable = false;

        if (currentUser
            && bucket?.id
            && config?.id
            && currentUser.queue
            && Object.keys(currentUser.queue).length
        ) {
            tempValidable = true;
            for (const [key, file] of Object.entries(currentUser.queue)) {
                if (file.state !== 'uploaded'
                    || !file.analyzeStatus
                    || ['pending', 'failed', 'analyzing'].includes(file.analyzeStatus)
                ) {
                    tempValidable = false;
                    break;
                }
            }
        }

        setIsValidable(tempValidable)

    }, [bucket?.id, config?.id, currentUser, currentUser?.queue])

    useEffect(() => {
        setAvailableForDataImport(!!(
            !currentUser?.queue
            || !Object.keys(currentUser.queue).length
            || (
                Object.keys(currentUser.queue).length === 1
                && SupportedImportDataMimesTypes.includes(Object.values(currentUser.queue)[0].type)
            )
        ))
    }, [currentUser?.queue])

    useEffect(() => {
        let tempAvailableBuckets: Bucket[] = []

        for (const [key, bucket] of Object.entries(currentUser?.currentOrganization?.buckets ?? {})) {
            if (!bucket.simpleImport && bucket.importConfigs?.length) {
                tempAvailableBuckets.push(bucket)
            }
        }

        setAvailableBuckets(tempAvailableBuckets)
    }, [currentUser?.currentOrganization?.buckets])

    useEffect(() => {
        let tempAvailableCollections: AvailableCollection[] = []

        for (const [key, catalog] of Object.entries(currentUser?.currentOrganization?.catalogs ?? {})) {
            tempAvailableCollections.push({
                collection: 'catalog',
                id: catalog.id,
                name: translate(catalog, 'name'),
                entity: catalog,
            })
        }

        for (const [key, dataCollection] of Object.entries(currentUser?.currentOrganization?.dataCollections ?? {})) {
            tempAvailableCollections.push({
                collection: 'data-collection',
                id: dataCollection.id,
                name: translate(dataCollection, 'name'),
                entity: dataCollection,
            })
        }

        tempAvailableCollections.push({
            collection: 'contact',
            name: t('app.contacts'),
        })

        tempAvailableCollections.push({
            collection: 'company',
            name: t('app.companies'),
        })

        if (currentUser?.currentOrganization?.options.includes('sales')) {
            tempAvailableCollections.push({
                collection: 'deal',
                name: t('app.deals'),
            })
        }

        if (currentUser?.currentOrganization?.options.includes('shopping')) {
            if (currentUser.currentOrganization.setting.shoppingTypes.includes('shopping')) {
                tempAvailableCollections.push({
                    collection: 'shopping',
                    name: t('app.shoppings'),
                })
            }

            if (currentUser.currentOrganization.setting.shoppingTypes.includes('pre-order')) {
                tempAvailableCollections.push({
                    collection: 'shopping',
                    name: t('app.shoppings'),
                })
            }
        }

        setAvailableCollections(tempAvailableCollections)
    }, [currentUser?.currentOrganization, t, translate])

    useEffect(() => {
        let size = 0
        let uploaded = 0
        let tempFilesOnError = 0
        let tempSizeOnError = 0
        for (const [key, uploadedFile] of Object.entries(currentUser?.queue ?? {})) {
            if (uploadedFile.state === 'failed' || uploadedFile.state === 'presigned-url-not-found') {
                tempFilesOnError++
                tempSizeOnError += uploadedFile.size
            } else {
                size += uploadedFile.size
                uploaded += uploadedFile.uploaded
            }
        }

        if (size === uploaded) {
            setImportState('uploaded')
        } else if (uploaded > 0 && uploaded < size) {
            setImportState('uploading')
        } else if (size === 0) {
            setImportState('pending')
            uploaded = 0
        }

        // round to 0 decimal
        setQueueUploadedPercent(Math.round((uploaded / size) * 100))
        setQueueSize(size)
        setQueueUploaded(uploaded)
        setFilesOnError(tempFilesOnError)
        setSizeOnError(tempSizeOnError)
    }, [currentUser?.queue])

    useEffect(() => {
        if (onDrag) {
            document.body.classList.add('on-drag')
        } else {
            document.body.classList.remove('on-drag')
        }
    }, [onDrag])

    const changeState = useCallback((keys: string[] | string, state: UploadedFileState, moreData: {} | null = null) => {
        if (currentUser) {
            let tempQueue: Queue = {...currentUser?.queue}
            if (typeof keys === 'string') {
                keys = [keys]
            }
            keys.forEach(key => {
                if (tempQueue.hasOwnProperty(key)) {
                    let file = tempQueue[key];
                    file.state = state

                    if (null !== moreData && typeof moreData === 'object' && Object.keys(moreData).length) {
                        for (const [dataKey, value] of Object.entries(moreData)) {
                            // @ts-ignore
                            file[dataKey] = value
                        }
                    }
                }
            })
            setCurrentUser({...currentUser, queue: tempQueue})
        }
    }, [currentUser, setCurrentUser])

    useEffect(() => {
        const maxFilesInQueue = 1
        if (currentUser) {
            var fileInQueue = 0
            for (const [key, uploadedFile] of Object.entries(currentUser?.queue ?? {})) {
                if (['presigned-url-requested', 'ready', 'uploading'].includes(uploadedFile.state)) {
                    fileInQueue++
                }
            }
            for (const [key, uploadedFile] of Object.entries(currentUser?.queue ?? {})) {
                if (uploadedFile.state === 'pending') {
                    if (fileInQueue < maxFilesInQueue) {
                        changeState(key, 'presigned-url-requested')
                        fileInQueue++

                        if (fileInQueue >= maxFilesInQueue) {
                            break;
                        }
                    }
                } else if (uploadedFile.state === 'presigned-url-requested') {
                    Api.get('/upload/presigned-url', {
                        params: {
                            name: uploadedFile.name,
                            size: uploadedFile.size,
                            type: uploadedFile.type,
                        }
                    }).then(response => {
                        changeState(key, 'ready', {presignedUrl: response.data.presignedUrl})

                    }).catch(error => {
                        changeState(key, 'presigned-url-not-found', {presignedUrl: undefined})
                    })
                } else if (uploadedFile.state === 'ready') {
                    changeState(key, 'uploading')

                    if (uploadedFile.presignedUrl) {
                        let urlObject = new URL(uploadedFile.presignedUrl);
                        let uri = urlObject.pathname
                        let baseUrl = urlObject.origin
                        Api.put(uri, uploadedFile.data, {
                            params: Object.fromEntries(urlObject.searchParams),
                            baseURL: baseUrl,
                            withCredentials: true,
                            headers: {
                                'Content-Type': uploadedFile.type
                            },
                            onUploadProgress: (progressEvent) => {
                                changeState(key, 'uploading', {uploaded: progressEvent.loaded})
                            }
                        }).then((response) => {
                            changeState(key, 'uploaded', {uploaded: uploadedFile.size})

                        }).catch((error) => {
                            changeState(key, 'failed', {uploaded: uploadedFile.size})
                        })
                    } else {
                        changeState(key, 'failed', {uploaded: uploadedFile.size})
                    }
                }
            }
        }
    }, [changeState, currentUser])

    // Analyze
    const analyzeImport = useCallback(() => {
        if (bucket?.id && config?.id && currentUser) {
            setOnAnalyze(true)

            Api.get(`/upload/${bucket.id}/${config.id}/analyze`)
                .then(({data}: { data: { analyzeQueue: Queue } }) => {
                    if (currentUser.queue) {
                        let tempQueue = {...currentUser.queue}
                        let analyzedKeys = Object.keys(data.analyzeQueue);
                        for (const [key, tempFile] of Object.entries(data.analyzeQueue)) {
                            if (tempQueue.hasOwnProperty(key)) {
                                tempQueue[key] = {...tempQueue[key], ...tempFile}
                            }
                        }
                        for (const [key, tempFile] of Object.entries(tempQueue)) {
                            if (!analyzedKeys.includes(key)) {
                                let state = {
                                    analyzeStatus: 'pending',
                                    newVersion: undefined,
                                    rejectReason: undefined,
                                }
                                tempQueue[key] = {...tempFile, ...state} as UploadedFile
                            }
                        }
                        setCurrentUser({...currentUser, queue: tempQueue})
                    }
                })
                .catch(() => {
                })
                .then(() => {
                    setOnAnalyze(false)
                })
        }
    }, [bucket?.id, config?.id, currentUser, setCurrentUser])

    const renameFile = useCallback(
        (oldName: string, newName: string): Promise<void> => {
            return new Promise((resolve, reject) => {
                if (currentUser) {
                    let tempQueue: Queue = { ...currentUser.queue }
                    if (tempQueue[oldName]) {
                        const oldFileData = tempQueue[oldName].data

                        //File data is readonly
                        const newFile = new File([oldFileData], newName, {
                            type: oldFileData.type,
                            lastModified: oldFileData.lastModified,
                        })

                        tempQueue[newName] = {
                            ...tempQueue[oldName],
                            name: newName,
                            data: newFile,
                        }

                        if (
                            tempQueue[oldName] &&
                            tempQueue[oldName].presignedUrl
                        ) {
                            const newPresignedUrl = tempQueue[
                                oldName
                            ].presignedUrl?.replace(oldName, newName)
                            tempQueue[newName].presignedUrl = newPresignedUrl
                        }

                        delete tempQueue[oldName]
                        setCurrentUser({ ...currentUser, queue: tempQueue })

                        Api.post('/upload/rename-file', { newName, oldName })
                            .then(() => {
                                setIsValidable(false)
                                resolve()
                            })
                            .catch((error) => {
                                reject(error)
                            })
                    } else {
                        reject(new Error('File not found in the queue'))
                    }
                } else {
                    reject(new Error('User not authenticated'))
                }
            })
        },
        [currentUser, setCurrentUser],
    )

    return (
        <div id="upload-system"
             onDragEnter={(e) => {
                 e.preventDefault();
                 e.stopPropagation()
                 setOnDrag(true)
             }}

             onDragOver={(e) => {
                 e.preventDefault();
                 e.stopPropagation()
                 setOnDrag(true)
             }}

             onDragLeave={(e) => {
                 e.preventDefault();
                 e.stopPropagation()
                 setOnDrag(false)
             }}

             onDrop={(e) => {
                 e.preventDefault();
                 e.stopPropagation()
                 setOnDrag(false)
                 if (isDroppable) {
                     if (e.dataTransfer.files.length) {
                         openImportModal()
                         addFiles(e.dataTransfer.files)
                     }
                 }
             }}
        >
            {children}
            <Modal fullscreen show={show} onHide={closeImportModal} id="upload-modal">
                <ModalHeader closeButton>
                    <Modal.Title>{t('app.import')}</Modal.Title>
                </ModalHeader>
                <ModalBody>
                    <div className="content">
                        <div
                            className={c('area', onDrag && 'onDrag', Object.keys(currentUser?.queue ?? {}).length === 0 ? 'showPlaceholder' : undefined)}
                            onDragEnter={() => {
                                setOnDrag(true)
                            }}

                            onDragOver={() => {
                                setOnDrag(true)
                            }}

                            onDragLeave={() => {
                                setOnDrag(false)
                            }}

                            onDrop={() => {
                                setOnDrag(false)
                            }}
                        >
                            <div className="row files">
                                {Object.values(currentUser?.queue ?? {}).map((uploadedFile, key) => (
                                    <div className="col-3 mb-3" key={key}>
                                        <_FileCard
                                            config={config}
                                            uploadedFile={uploadedFile}
                                            renameFile={renameFile}
                                            deleteFile={() => {
                                                deleteFile(uploadedFile.name)
                                            }}
                                            retry={() => {
                                                retry(uploadedFile.name)
                                            }}
                                            retryAnalyze={() => {
                                                retryAnalyze(uploadedFile.name)
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                            <div className="import-placeholder">
                                <i className="fa-solid fa-folder-plus"></i>
                                <span>{t('app.drop_your_files')}</span>
                                <label htmlFor="import-input">{t('app.or_import')}</label>
                                <input type="file" id="import-input" multiple onChange={(e) => {
                                    if (e.target.files && e.target.files.length) {
                                        addFiles(e.target.files)
                                    }
                                }}/>
                            </div>
                        </div>
                        <div className="form">
                            <div className="form-content">
                                {(!bucket && !availableCollection)
                                    ? <div className="row justify-content-start align-items-center">
                                        {availableForDataImport && availableBuckets.length && availableCollections.length &&
                                            <div className="col-12 mb-3">
                                                <h3 className="mb-0">{t('app.files_import')}</h3>
                                            </div>}
                                        {availableBuckets?.map((bucket, key) =>
                                            <div className="col-6 mb-2" key={key}>
                                                <Button variant="secondary" className="btn-large btn-import"
                                                        onClick={() => {
                                                            setBucket(bucket)
                                                            setImportForm(true)
                                                        }}>{translate(bucket, 'name')}</Button>
                                            </div>
                                        )}
                                        {availableForDataImport && availableBuckets.length && availableCollections.length &&
                                            <div className="col-12 mb-3">
                                                <h3 className="mb-0">{t('app.data_import')}</h3>
                                            </div>}
                                        {availableForDataImport && availableCollections?.map((availableCollection, key) =>
                                            <div className="col-6 mb-2" key={key}>
                                                <Button
                                                    variant="secondary"
                                                    className="btn-large btn-import"
                                                    disabled={!(!currentUser?.queue || (Object.keys(currentUser.queue).length === 1 && SupportedImportDataMimesTypes.includes(Object.values(currentUser.queue)[0].type)))}
                                                    onClick={() => {
                                                        setAvailableCollection(availableCollection)
                                                    }}>{availableCollection.name}</Button>
                                            </div>
                                        )}
                                    </div>
                                    : (bucket && !config
                                            ? <div className="row justify-content-start align-items-center">
                                                <div className="col-12 mb-3">
                                                    <div className="row justify-content-start align-items-center">
                                                        {((availableForDataImport && availableCollections.length) || availableBuckets.length > 1) &&
                                                            <div className="col-auto">
                                                                <Button variant="secondary" className="btn-icon"
                                                                        onClick={() => {
                                                                            setBucket(undefined)
                                                                        }}
                                                                ><i className="fa-solid fa-angle-left"></i></Button>
                                                            </div>}
                                                        <div className="col">
                                                            <h3 className="mb-0">{translate(bucket, 'name')}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                {bucket.importConfigs?.map((config, key) =>
                                                    <div className="col-6 mb-2" key={key}>
                                                        <LoadingButton isLoading={onChangeConfig === config.id}
                                                                       disabled={onChangeConfig !== null}
                                                                       variant="secondary"
                                                                       className="btn-large btn-import" onClick={() => {
                                                            changeConfig(config)
                                                        }}>{translate(config, 'name')}</LoadingButton>
                                                    </div>
                                                )}
                                            </div>
                                            : (config && bucket ? <>
                                                    <div className="row align-items-center mb-3">
                                                        {bucket.importConfigs?.length > 1 ?
                                                            <div className="col-auto">
                                                                <Button
                                                                    variant="secondary"
                                                                    className="btn-icon"
                                                                    onClick={() => {
                                                                        changeConfig(null)
                                                                    }}
                                                                ><i className="fa-solid fa-angle-left"></i></Button>
                                                            </div>
                                                            : (availableBuckets.length > 1 ? <div className="col-auto">
                                                                    <Button
                                                                        variant="secondary"
                                                                        className="btn-icon"
                                                                        onClick={() => {
                                                                            setBucket(undefined)
                                                                        }}
                                                                    ><i className="fa-solid fa-angle-left"></i></Button>
                                                                </div>
                                                                : undefined)}
                                                        <div className="col-auto">
                                                            <h3 className="mb-0">{translate(config, 'name')}</h3>
                                                        </div>
                                                    </div>
                                                    <FormEmpty
                                                        path='/import/form'
                                                        delocateInitForm={importForm}
                                                        formId={process.env.REACT_APP_FORM_DATA_IMPORT_ID}
                                                        appendForm={config.tags &&
                                                            <_ImportModalTags tags={tags} setTags={setTags}
                                                                              onLoad={false}
                                                                              className="bg-light p-3 rounded-1 border"/>}
                                                    />
                                                </> : (availableCollection ?
                                                    <>
                                                        <div className="row align-items-center mb-3">
                                                            <div className="col-auto">
                                                                <Button
                                                                    variant="secondary"
                                                                    className="btn-icon"
                                                                    onClick={() => {
                                                                        setAvailableCollection(undefined)
                                                                    }}
                                                                ><i className="fa-solid fa-angle-left"></i>
                                                                </Button>
                                                            </div>
                                                            <div className="col-auto">
                                                                <h3 className="mb-0">{t('app.import')}</h3>
                                                            </div>
                                                        </div>
                                                    </>
                                                    : <span
                                                        className="text-danger">{'Error. Bucket: ' + bucket?.name + ', Config: ' + config?.name + ', Data import :' + availableCollection}</span>)
                                            )
                                    )
                                }
                            </div>
                            <div className="form-actions">
                                <Button variant="secondary" onClick={() => {
                                    setShowCancelModal(true)
                                }} disabled={onValidate}>{t('app.cancel')}</Button>
                                {!isValidable && <LoadingButton variant="outline-primary" disabled={!analyzable}
                                                                isLoading={onAnalyze}
                                                                onClick={analyzeImport}>{t('app.analyze')}</LoadingButton>}
                                {isValidable && <LoadingButton variant="primary" isLoading={onValidate}
                                                               disabled={onValidate || !config || !isValidable}
                                                               onClick={() => {
                                                                   validateImport()
                                                               }}>{t('app.validate')}</LoadingButton>}
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <Modal show={showCancelModal} onHide={() => {
                setShowCancelModal(false)
            }}>
                <ModalHeader closeButton>
                    <Modal.Title>{t('app.importer.modal.cancel.title')}</Modal.Title>
                </ModalHeader>
                <ModalBody>
                    <div className="content">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-auto"><i className="fa-light fa-exclamation-circle text-danger"></i>
                            </div>
                            <div className="col">{t('app.importer.modal.cancel.content')}</div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button variant="secondary" onClick={() => {setShowCancelModal(false)}}>{t('app.cancel')}</Button>
                    <LoadingButton variant="danger" isLoading={onCancel} onClick={cancelImport}>{t('app.importer.modal.cancel.confirm')}</LoadingButton>
                </ModalFooter>
            </Modal>
            <CollectionImportModal
                collection={availableCollection?.collection ?? 'bucket'}
                entity={availableCollection?.entity}
                closeImportModal={() => {
                    setShowImportModal(false)
                    setShow(true)
                    setCurrentImport(undefined)
                    setAvailableCollection(undefined)
                }}
                currentImport={currentImport}
                setCurrentImport={setCurrentImport}
                onParamsChange={onParamsChange}
                onDetail={onDetail}
                onImport={onImport}
                canDetail={canDetail}
                canImport={canImport}
                onRequest={onRequest}
            />
            <div id="upload-message"
                 className={c(importState, Object.keys(currentUser?.queue ?? {}).length ? 'on-upload' : undefined, isDroppable ? 'drop-possible' : 'drop-not-possible')}>
                <div className="uploading" onClick={!show ? openImportModal : closeImportModal}>
                    <i className="fa-light fa-cloud-arrow-up me-2"></i>
                    {t('app.importer.modal.uploading.title')} - {humanFileSize(queueUploaded, true, 0)} / {humanFileSize(queueSize, true, 0)} - {queueUploadedPercent}%
                    {isValidate && <> - {t('app.importer.modal.uploading.is-validate')}</>}
                </div>
                <div className={c("uploaded", filesOnError > 0 && 'on-error')}
                     onClick={!show ? openImportModal : closeImportModal}>
                    <i className={c("fa-light me-2", filesOnError > 0 ? 'fa-cloud-exclamation' : 'fa-cloud-check')}></i>
                    {t('app.importer.modal.uploaded.title')} - {humanFileSize(queueUploaded, true, 0)}
                    {filesOnError > 0
                        ? (<> - {t('app.importer.modal.uploaded.error', {count: filesOnError})} ({humanFileSize(sizeOnError, true, 0)})</>)
                        : undefined
                    }
                </div>
                <div className="success"><i
                    className="fa-light fa-check me-2"></i>{t('app.importer.modal.success.title')}</div>
                <div className="error" onClick={!show ? openImportModal : closeImportModal}><i
                    className="fa-light fa-cloud-xmark me-2"></i>{t('app.importer.modal.error.title')}</div>
                <div className="drop-possible"><i
                    className="fa-light fa-cloud-arrow-up me-2"></i>{t('app.importer.modal.drop-possible.title')}</div>
                <div className="drop-not-possible"><i
                    className="fa-solid fa-hexagon-exclamation me-2"></i>{t('app.importer.modal.drop-not-possible.title')}
                </div>
            </div>
        </div>
    )
}

export default UploadSystem
