import React, {FC, useEffect, useState} from 'react'
import {classUtils as c} from 'Vendor/Utils/ClassUtils'
import ChooseItemsModal, {ChooseItemsModalProps} from "./ChooseItemsModal";


export type ChooseItemsInputProps = ChooseItemsModalProps & {}

const ChooseItemsInput: FC<ChooseItemsInputProps> = ({
                                                         title,
                                                         multiple,
                                                         selected,
                                                         setSelected,
                                                         setShow,
                                                         ...modalProps
                                                     }
) => {
    const [selectedLabel, setSelectedLabel] = useState<string[]>([])
    useEffect(() => {
        let tempSelectedLabel: string[] = []

        selected.forEach((item) => {
          if (item.hasOwnProperty('name')) {
                tempSelectedLabel.push(item.name)
          }  else if (item.hasOwnProperty('title')) {
                tempSelectedLabel.push(item.title)
          }  else if (item.hasOwnProperty('label')) {
                tempSelectedLabel.push(item.label)
          } else if (item.hasOwnProperty('value')) {
                tempSelectedLabel.push(item.value)
          } else {
              tempSelectedLabel.push(item.toString())
          }
        })

        setSelectedLabel(tempSelectedLabel)
    }, [selected])

    return <>
        <div className="form-floating mb-3" style={{width: '300px'}}>
            <div className={c('form-control form-collection-entity', multiple && 'form-collection-entity-multiple')} onClick={() => {setShow(true)}}>
                {(selectedLabel.length && selected.length && selectedLabel.length === selected.length)
                    ? <>
                        {multiple ?
                            <>{selected.map((item, index) =>
                                <button type="button" className="form-collection-entity-multiple-item" onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    let tempSelected = [...selected]
                                    tempSelected.splice(index, 1)
                                    setSelected(tempSelected)
                                }}>{selectedLabel[index]}</button>
                            )}</>
                    : selectedLabel[0]}</>
                    : ''
                }
            </div>
            <label className="form-label">{title}</label>
        </div>
        <ChooseItemsModal selected={selected} setSelected={setSelected} title={title} multiple={multiple} setShow={setShow} {...modalProps} />
    </>
}

export default ChooseItemsInput
