import {RouteObject} from "react-router-dom";
import React from "react";
import GroupPage from "../../Pages/Admin/Edition/User/GroupPage";
import UserPage from "../../Pages/Admin/Edition/UserPage";

const UserRouting: RouteObject[] = [
    {
        index: true,
        element: <UserPage />,
    },
    {
        path: 'group',
        element: <GroupPage />,
    },
]

export default UserRouting