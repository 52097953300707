import React, { FC, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import Api from "Vendor/Api";
import {BannerContext} from "../../../Vendor/Context/BannerContextProvider";
import LoadingButton from "../../../Components/Button/LoadingButton";
import EmailType from "../../../Vendor/Components/Form/Type/EmailType";

const IndexPage: FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { setBanner } = useContext(BannerContext);

    const [isLoading, setIsLoading] = useState(false)
    const [email, setEmail] = useState('')

    const onSubmit = () => {
        if (!email) return

        setIsLoading(true)

        Api.post('reset-password', { email })
            .then(({ data }) => {
                setIsLoading(false)
                setBanner({type: 'success', i18nMessage: 'ok !'})
                navigate('/reset-password/check-email')
            })
            .catch((err) => {
                setIsLoading(false)
                setBanner({type: 'error', i18nMessage: err?.response?.data?.message ?? 'error.server'
            })
            })
    }

    return (
        <form
            name="reset_password_request_form"
            method="post"
            onClick={(e) => e.preventDefault()}
        >
            <div className="modal-header">
                <h5 className="modal-title" id="loginModalLabel">
                    {t('app.reset_your_password')}
                </h5>
            </div>
            <div className="modal-body">
                <EmailType
                    id="reset_password_request_form_email"
                    name="email"
                    autoComplete="email"
                    placeholder={t('common.app.email')}
                    aria-describedby="reset_password_request_form_email_help"
                    className="form-control"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    i18nHelp={'app.reset_password_help'}
                    required
                    fullName="email"
                />
                <Link to="/login">{t('app.login')}</Link>
            </div>
            <div className="modal-footer">
                <LoadingButton
                    type="submit"
                    isLoading={isLoading}
                    disabled={!email}
                    onClick={onSubmit}
                >
                    {t('app.send_password_reset_email')}
                </LoadingButton>
            </div>
        </form>
    )
}

export default IndexPage
