import React, {FC, useContext, useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import Header from "Components/Layout/Header";
import Table from "Components/Layout/Table";
import DeleteModal from "../../../../Components/Modal/DeleteModal";
import {Button} from "react-bootstrap";
import FormEmptyOffCanvas from "../../../../Components/Modal/FormEmptyOffCanvas";
import Group from "../../../../Entity/Product/Group";
import ProductGroupTable from "../../../../Table/Admin/Edition/Security/ProductGroupTable";
import {useOutletContext} from "react-router-dom";
import Catalog from "../../../../Entity/Catalog";

const ProductGroupPage: FC = () => {
    const { t } = useTranslation()

    const [toggleReload, setToggleReload] = useState<boolean>(true)
    const [catalog, setCatalog, show, setShow, search, setSearch, setHandleAdd] = useOutletContext<[Catalog, (catalog: Catalog) => void, boolean, (show:boolean) => void, string, (search:string) => void, (handleAdd: (() => void)|undefined) => void]>()
    const [productGroups, setProductGroups] = useState<Group[]>([])
    const [productGroup, setProductGroup] = useState<Group|undefined>(undefined)
    const handleClose = (reload = false) => {
        setProductGroup(undefined)
        setShow(false)
        if (reload) {
            setToggleReload(!toggleReload)
        }
    }
    const handleShow = () => setShow(true)
    // remove
    const [showDelete, setShowDelete] = useState<boolean>(false)
    const handleDeleteClose = (reload = false) => {
        setProductGroup({} as Group)
        setShowDelete(false)
        if (reload) {
            setToggleReload(!toggleReload)
        }
    }
    const handleDeleteShow = () => setShowDelete(true)

    const onSuccess = (productGroup: Group) => {
        setProductGroup(undefined)
        setShow(false)
        setToggleReload(!toggleReload)
    }

    const editProductGroup = (productGroup: Group) => {
        setProductGroup(productGroup)
        handleShow()
    }

    const deleteProductGroup = (productGroup: Group) => {
        setProductGroup(productGroup)
        handleDeleteShow()
    }

    const actionsRender = (productGroup: Group) => (
        <>
            {productGroup?.isGranted?.edit &&
                <Button
                    variant='outline-primary'
                    size='sm'
                    onClick={() => editProductGroup(productGroup)}>
                    <i className="fa-solid fa-pencil me-2"></i>
                    {t('app.edit')}</Button>
            }
            {productGroup?.isGranted?.delete &&
                <Button
                    variant='outline-danger'
                    size='sm'
                    onClick={() => deleteProductGroup(productGroup)}>
                    <i className="fa-solid fa-trash me-2"></i>
                    {t('app.delete')}</Button>
            }
        </>
    );

    let table = ProductGroupTable;
    if (table.hasOwnProperty('actions')) {
        table.actions.render = actionsRender;
    }

    return (
        <>
            <Table
                path={`/admin/edition/catalog/${catalog.id}/product-group`}
                rows={table}
                toggleReload={toggleReload}
                items={productGroups}
                setItems={setProductGroups}
                keyword={search}
                setKeyword={setSearch}
            />
            <FormEmptyOffCanvas
                i18nTitle={productGroup ? 'app.edit_product_group' : 'app.add_product_group'}
                show={show}
                handleClose={handleClose}
                onSuccess={onSuccess}
                path={`/admin/edition/catalog/${catalog.id}/product-group/${productGroup?.id ?? 'new'}`}
                formPath={!!productGroup}
                method={productGroup ? 'patch' : 'post'}
            />
            {productGroup && <DeleteModal
                handleClose={handleDeleteClose}
                show={showDelete}
                path={`/admin/edition/catalog/${catalog.id}/product-group/${productGroup?.id ?? 'error'}`}
                name={`#${productGroup.id} - ${productGroup.translations}`}
                archive={true}
            />}
        </>
    )
}

export default ProductGroupPage
