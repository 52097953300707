import React, {FC, useEffect, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import SearchType from "Vendor/Components/Form/Type/SearchType";
import {useTranslation} from "react-i18next";
import {classUtils as c} from "Vendor/Utils/ClassUtils";

export type HeaderProps = {
    title?: string,
    add?: () => void
    search?: string
    setSearch?: (value: string) => void
    prev?: () => void
    setting?: () => void
    showSetting?: boolean
    prevRotate?: boolean
    disabled?: boolean
    onLoad?: boolean
    importer?: () => void
    showImport?: boolean
    prevDisabled?: boolean
    externalUrl?: string
}

const Header: FC<HeaderProps> = ({
                                     title,
                                     add,
                                     search = undefined,
                                     setSearch = undefined,
                                     prev = undefined,
                                     setting = undefined,
                                     showSetting = undefined,
                                     prevRotate = false,
                                     prevDisabled = false,
                                     disabled = false,
                                     onLoad = false,
                                     importer,
                                     showImport = undefined,
                                     externalUrl = undefined,
                                 }) => {
    const {t} = useTranslation()

    return (
        <header className="container-fluid">
            <div className="row align-items-center">
                <div className={(typeof search !== 'string' || !setSearch) && !add ? 'col' : 'col-3'}>
                    <div className="row justify-content-start align-items-center">
                        {prev && (
                            <div className="col-auto">
                                <Button onClick={prev} disabled={prevDisabled} variant="secondary"
                                        className={c('btn-icon btn-icon-only', prevRotate && 'btn-icon-rotate-hover')}>
                                    <i className="fa-solid fa-angle-left"></i>
                                </Button>
                            </div>
                        )}
                        <div className="col">
                            <h1>{title}</h1>
                        </div>
                    </div>
                </div>
                {(typeof search === 'string' && setSearch) &&
                    <div className="col position-relative">
                        <SearchType i18nLabel="app.searching" className="form-control-lg-white" name="search" fullName="search" id="search" value={search} setValue={setSearch} floating={false}/>
                        {!search &&<i className="fa-solid fa-magnifying-glass fa-xl" onClick={() => {
                            document.getElementById('search')?.focus()
                        }}></i>}
                    </div>
                }
                <div className="col-3">
                    <div className="row justify-content-end align-items-center">
                        <div className="col-auto header-right">
                            {add && <Button variant="primary" className="btn-icon btn-icon-only" onClick={add}
                                            disabled={disabled}>
                                {(onLoad && disabled)
                                    ? <i className="fa-solid fa-circle-notch fa-spin"></i>
                                    : <i className="fa-solid fa-plus"></i>
                                }
                            </Button>}
                            {importer && showImport !== false &&
                                <Button variant="primary"
                                        className={c('btn-icon btn-import', disabled && 'btn-disabled')}
                                        onClick={importer}
                                        >
                                    {(onLoad && disabled)
                                        ? <i className="fa-solid fa-circle-notch fa-spin"></i>
                                        : <i className="fa-solid fa-plus"></i>
                                    }
                                    {t('app.import')}
                                </Button>}
                            {setting && <Button variant="primary" size="lg" className="btn-icon btn-icon-only" onClick={setting}><i className="fa-solid fa-cog"></i></Button>}
                            {externalUrl && <Button variant="primary" size="lg" className="btn-icon btn-icon-only" href={externalUrl} target="_blank"><i className="fa-solid fa-arrow-up-right-from-square"></i></Button>}
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header
