import {RouteObject} from "react-router-dom";
import MainPage from "Pages/Admin/Edition/Bucket/MainPage";
import ListingPage from "Pages/Admin/Edition/Bucket/ListingPage";
import ImportConfigPage from "Pages/Admin/Edition/Bucket/ImportConfigPage";
import FileGroupPage from "Pages/Admin/Edition/Bucket/FileGroupPage";
import ExportConfigPage from "Pages/Admin/Edition/ExportConfigPage";
import React from "react";
import TransformPage from "../../Pages/Admin/Edition/Bucket/TransformPage";

const BucketRouting: RouteObject[] = [
    {
        index: true,
        element: <MainPage/>,
    },
    {
        path: 'listing',
        element: <ListingPage/>,
    },
    {
        path: 'import-config',
        element: <ImportConfigPage />,
    },
    {
        path: 'export-config',
        element: <ExportConfigPage collection="bucket" />,
    },
    {
        path: 'group',
        element: <FileGroupPage/>,
    },
    {
        path: 'transform',
        element: <TransformPage />,
    }
]

export default BucketRouting