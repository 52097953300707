const app = {
    typesChoices: {
        text: 'Texte',
        textarea: 'Zone de texte',
        email: 'Email',
        link: 'Lien',
        status: 'Statut',
        phone: 'Téléphone',
        date: 'Date',
        choice: 'Choix',
        number: 'Nombre',
        address: 'Adresse',
        checkbox: 'Case à cocher',
        price: 'Prix',
        currency: 'Devise',
    },
    unknown: 'Inconnu',
    by: 'par',
    icon: 'Icône',
    reset: 'Réinitialiser',
    show_fontawesome: 'Voir sur le site Fontawesome pour trouve un icône',
    relation_contact_company: 'Les contacts peuvent avoir plusieurs entreprises ?',
    user_groups: 'Groupes d\'utilisateurs',
    property_groups: 'Groupes de propriétés',
    main: 'Général',
    translate: 'Traduire',
    deal: 'Deal',
    description: 'Description',
    phone: 'Téléphone',
    company: 'Entreprise',
    contact: 'Contact',
    minWidth: 'Largeur minimale',
    maxWidth: 'Largeur maximale',
    minHeight: 'Hauteur minimale',
    maxHeight: 'Hauteur maximale',
    minDpi: 'DPI minimale',
    maxDpi: 'DPI maximale',
    minWidthCm: 'Largeur minimale (cm)',
    maxWidthCm: 'Largeur maximale (cm)',
    minHeightCm: 'Hauteur minimale (cm)',
    maxHeightCm: 'Hauteur maximale (cm)',
    layoutChoices: {
        HORIZONTAL: 'Horizontal',
        VERTICAL: 'Vertical',
    },
    label: 'Label',
    link_to: 'Liée à',
    maxSize: 'Taille maximale',
    firstName: 'Prénom',
    lastName: 'Nom',
    login: 'Connexion',
    logout: 'Déconnexion',
    email: 'E-mail',
    password: 'Mot de passe ',
    plainPassword: 'Confirmation du mot de passe ',
    forgot_password: 'Mot de passe oublié ?',
    group: 'Groupe',
    dashboard: 'Dashboard',
    leave_support: "Quitter l'assistance",
    organizations: 'Organisations',
    organization: 'Organisation',
    organization_setting: {
        relation_contact_company:
            'Les contacts peuvent avoir plusieurs entreprises ?',
    },
    organization_option: {
        bimbo_share: 'Bimbo Share',
        studio: 'Studio',
        sales: 'Sales',
        shopping: 'Shopping',
        mailing: 'Mailing',
        data_visualization: 'Data visualisation',
    },
    mime_types: 'Types de fichiers',
    mimeTypes: {
        JPEG: 'JPEG',
        PNG: 'PNG',
        PDF: 'PDF',
        DOC: 'DOC',
        DOCX: 'DOCX',
        XLS: 'XLS',
        XLSX: 'XLSX',
        PPT: 'PPT',
        PPTX: 'PPTX',
        GIF: 'GIF',
        JPG: 'JPG',
        AI: 'AI',
        PSD: 'PSD',
        ZIP: 'ZIP',
        RAR: 'RAR',
        MP4: 'MP4',
        AVI: 'AVI',
        MOV: 'MOV',
        PSB: 'PSB',
        TIFF: 'TIFF',
        JP2: 'JP2',
        FLV: 'FLV',
        M4V: 'M4V',
        WEBM: 'WEBM',
        GZ: 'GZ',
        TAR: 'TAR',
        MP3: 'MP3',
        EPS: 'EPS',
        HEIC: 'HEIC',
        INDD: 'INDD',
        IDML: 'IDML',
    },
    mimes: {
        'text/csv': 'CSV',
        'image/png': 'PNG',
        'image/jpeg': 'JPG',
        'application/pdf': 'PDF',
        'application/msword': 'DOC',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
        'application/vnd.ms-excel': 'XLS',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX',
        'application/vnd.ms-powerpoint': 'PPT',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPTX',
        'image/gif': 'GIF',
        'image/jpg': 'JPG',
        'application/illustrator': 'AI',
        'image/vnd.adobe.photoshop': 'PSD',
        'application/zip': 'ZIP',
        'application/x-rar-compressed': 'RAR',
        'video/mp4': 'MP4',
        'video/avi': 'AVI',
        'video/quicktime': 'MOV',
        'application/vnd.3gpp.pic-bw-small': 'PSB',
        'image/tiff': 'TIFF',
        'image/jp2': 'JP2',
        'video/x-flv': 'FLV',
        'video/x-m4v': 'M4V',
        'video/webm': 'WEBM',
        'application/gzip': 'GZ',
        'application/x-tar': 'TAR',
        'audio/mpeg': 'MP3',
        'application/eps': 'EPS',
        'application/x-eps': 'EPS',
        'image/eps': 'EPS',
        'image/x-eps': 'EPS',
        'image/heic': 'HEIC',
        'image/heif': 'HEIC',
        'application/vnd.adobe.indesign' : 'INDD',
        'application/vnd.adobe.indesign-idml-package' : 'IDML',
        'application/postscript': 'AI',
    },
    all_files: 'Tous les types',
    file: {
        'publicLink': 'Lien public',
        'copyPublicLink': 'Copier',
        'generatePublicLink': 'Générer',
        'regeneratePublicLink': 'Regénérer',
        'deletePublicLink': 'Supprimer',
    },
    internal_users: 'Utilisateurs interne',
    internal_user: 'Utilisateur interne',
    languages: 'Langues',
    lang: {
        en: 'Anglais',
        fr: 'Français',
    },
    defaultLanguage: 'Langue par défaut',
    show_more: 'Afficher plus',
    show_less: 'Afficher moins',
    contact_informations: 'Informations du contact',
    language: 'Langue',
    brands: 'Marques',
    brand: 'Marque',
    listing: 'Listing',
    listings: {
        label: 'Listings',
        edit: 'Éditer le listing',
        add: 'Ajouter un listing',
        sorted_by: 'Trier par : {{s}}'
    },
    members: 'Membres',
    id: '#',
    name: 'Nom',
    slug: 'Slug',
    actions: 'Actions',
    sendAt: 'Envoyé le',
    totalQty: 'Quantité totale',
    designedDelivery: 'Livraison prévue',
    plannedReturn: 'Retour prévu',
    deliveryAddress: 'Adresse de livraison',
    total: 'Total',
    show: 'Voir',
    edit: 'Éditer',
    delete: 'Supprimer',
    because: 'Raison',
    archive: 'Archiver',
    restore: 'Désarchiver',
    update: 'Mettre à jour',
    no_result: 'Aucun résultat',
    remaining_result: '{{ count }} résultats restants',
    back_to_list: 'Retour à la liste',
    close: 'Fermer',
    cancel: 'Annuler',
    archive_question: 'Êtes-vous sûr de vouloir archiver : ',
    delete_question: 'Êtes-vous sûr de vouloir supprimer : ',
    new: 'Ajouter',
    save: 'Sauvegarder',
    save_and_close: 'Sauvegarder et fermer',
    unselect_and_close: 'Désélectionner et fermer',
    saved: 'Sauvé',
    groups: 'Groupes',
    data_saved_message: 'Sauvegardé !',
    success_message: 'Action réussi !',
    validate: 'Valider',
    create: 'Créer',
    filter: 'Filtrer',
    role: 'Rôle',
    mainRole: 'Rôle',
    roles: {
        ROLE_SUPER_ADMIN: 'Super administrateur',
        ROLE_ADMIN: 'Administrateur',
        ROLE_INTERNAL: 'Interne',
        ROLE_OPERATION: 'Opération',
        ROLE_OPERATION_DIRECTOR: 'Directeur(-trice) des opérations',
        ROLE_COMMERCIAL: 'Commercial(e)',
        ROLE_COMMERCIAL_DIRECTOR: 'Directeur(-trice) commercial(e)',
        ROLE_MARKETING: 'Marketing',
        ROLE_MARKETING_DIRECTOR: 'Directeur(-trice) marketing',
        ROLE_DIRECTOR: 'Directeur(-trice)',
        ROLE_EXTERNAL: 'Utilisateur',
    },
    sorter: 'Trier par',
    table_columns: {
        sendAt: 'Envoyé le',
        designedDelivery: 'Livraison prévue',
        plannedReturn: 'Retour prévu',
        name: 'Nom',
        firstName: 'Prénom',
        lastName: 'Nom',
        email: 'E-mail',
        createdAt: 'Créé le',
        createdBy: 'Créé par',
        lastUpdatedAt: 'Dernière mise à jour',
        lastUpdatedBy: 'Dernière mise à jour par',
        identifier: 'Identifiant',
        updatedAt: 'Modifié le',
        updatedBy: 'Modifié par',
        contact: 'Contact',
        company: 'Entreprise',
        deal: 'Transaction',
        brand: 'Marque',
        group: 'Groupe',
        status: 'Statut',
        totalShoppings: 'Shoppings',
        currentShoppings: 'Shoppings en cours',
        totalPreOrders: 'Pré-commandes',
        currentPreOrders: 'Pré-commandes en cours',
        sticker_name: 'Étiquette',
        sticker_status: 'État de l‘étiquette'
    },
    createdAt: 'Créé le',
    upload_success_message: 'Le fichier a bien été uploadé !',
    lastUpdatedAt: 'Dernière mise à jour',
    lastUpdatedBy: 'Dernière mise à jour par',
    identifier: 'Identifiant',
    updatedAt: 'Dernière mise à jour',
    updatedBy: 'Dernière mise à jour par',
    countDeals: 'Nombre de deals',
    property: 'Propriété',
    visibleByDefault: 'Visible par défaut',
    kanban_view: 'Vue Kanban',
    kanban_max_result: 'L\'affichage est limité aux {{ count }} premiers résultats',
    defaultDirection: 'Ordre par défaut',
    direction: {
        asc: 'Croissant',
        desc: 'Décroissant',
    },
    remove: 'Supprimer',
    views: 'Vues',
    view: {
        card: 'Card',
        table: 'Table',
        kanban: 'Kanban',
    },
    card_view: 'Vue card',
    cardShowStatus: 'Afficher le statut',
    cardShowBadge: 'Afficher le badge',
    cardCondensedByDefault: 'Condensé par défaut',
    table_view: 'Vue table',
    columnType: 'Type de colonne',
    defaultSort: 'Tri par défaut',
    search: 'Recherche',
    followed_by: 'Suivi par',
    searchableByName: 'Recherche par nom',
    searchableByFirstName: 'Recherche par prénom',
    searchableByLastName: 'Recherche par nom',
    searchableByEmail: 'Recherche par e-mail',
    searchableByProperty: 'Recherche par propriété',
    quantity: 'Quantité',
    quantityGenerate: 'Quantité à générer',
    send_test: 'Envoyer un test',
    listing_setting: {
        grouped_action: {
            shopping: 'Shopping',
            preOrder: 'Pré-commande',
            remove: 'Supprimer',
            download: 'Télécharger',
            unPublish: 'Dépublier',
            publish: 'Publier',
            share: 'BimboShare',
            export: 'Exporter',
            mailingList: 'Liste de diffusion',
            property: 'Propriété',
            group: 'Groupe',
            designedDelivery: 'Livraison prévue',
            plannedReturn: 'Retour prévu',
            generateStickers: 'Générer des étiquettes',
            archiveStickers: 'Archiver des étiquettes',
            printStickers: 'Imprimer des étiquettes',
            tag:'Tag',
            transform: 'Transformer des images',
            rename: 'Modifier du texte',
            reporting: 'Reporting',
            restore: 'Désarchiver'
        }
    },
    listing_sorters: 'Tris',
    listing_filters: 'Filtres',
    acronym: 'Acronyme',
    are_you_sure_to_delete:
        'Êtes-vous sûr de bien vouloir supprimer cet élément ?',
    are_you_sure_to_archive:
        'Êtes-vous sûr de bien vouloir archiver cet élément ?',
    leave_edition: "Quitter l'édition",
    settings: 'Paramètres',
    options: 'Options',
    ungrouped: 'Sans groupe',
    new_user_group: "Nouveau groupe d'utilisateur",
    new_user: 'Nouveau utilisateur',
    no_user_for_this_group: 'Aucun utilisateur pour ce groupe',
    user_group: "Groupe d'utilisateur",
    add_user_group: "Ajouter un groupe d'utilisateur",
    add_shopping: 'Ajouter un shopping',
    edit_shopping: 'Editer un shopping',
    select_separator: 'Sélectionner un séparateur',
    nb_bags_label: 'Nombre de sacs',
    price: 'Prix',
    shopping: 'Shopping',
    delivery_paper: 'Fiche de sortie',
    none_male: 'Aucun',
    none_female: 'Aucune',
    pre_order: 'Pré-commande',
    'pre-order': 'Pré-commande',
    edit_pre_order: 'Editer une pré-commande',
    add_pre_order: 'Ajouter une pré-commande',
    add_platform_user: 'Ajouter un utilisateur plateforme',
    currency: 'Devise',
    edit_user_group: "Editer un groupe d'utilisateur",
    edit_platform_user: 'Editer un utilisateur plateforme',
    add_product: 'Ajouter un produit',
    message: 'Message',
    messages: 'Messages',
    edit_product: 'Editer un produit',
    add_new_column: 'Ajouter une nouvelle colonne',
    filters: 'Filtres',
    yes: 'Oui',
    no: 'Non',
    empty: 'Vide',
    nothing: 'Aucune',
    not_specified: 'Non spécifié',
    not_specified_date_alert: 'Attention aucune date spécifié',
    designed_delivery_required: 'La date de livraison est requise',
    domain: 'Domaine',
    password_reset: 'Mot de passe réinitialisé',
    reset_your_password: 'Réinitialisez votre mot de passe',
    reset_password: 'Réinitialisez le mot de passe',
    reset_password_help:
        'Entrez votre adresse e-mail et nous vous enverrons un lien pour réinitialiser votre mot de passe.',
    send_password_reset_email:
        'Envoyer un e-mail de réinitialisation du mot de passe',
    new_password: 'Nouveau mot de passe',
    please_reset_your_password: 'Please enter a password',
    repeat_password: 'Répéter le mot de passe',
    password_reset_email_sent: 'Password Reset Email Sent',
    platforms: 'Plateformes',
    app_users: 'Utilisateurs App',
    platform_users: 'Utilisateurs Plateformes',
    edition: 'Édition',
    add: 'Ajouter',
    back: 'Retour',
    add_organization: 'Ajouter une organisation',
    add_brand: 'Ajouter une marque',
    add_user: 'Ajouter un utilisateur',
    edit_user: 'Editer un utilisateur',
    add_platform: 'Ajouter une plateforme',
    add_bucket: 'Ajouter un bucket',
    add_report: 'Ajouter un report',
    edit_report: 'Editer un report',
    show_help_tooltip: "Cacher les tooltips d'aides",
    miscellaneous: 'Divers',
    general: 'Général',
    loading: 'En cours de chargement...',
    first_name: 'Prénom',
    last_name: 'Nom',
    organizations_users: 'Utilisateurs multi-orga',
    contactByBrand: 'Contacts par marque',
    contactByProperty: 'Contacts par propriété',
    buckets: 'Buckets',
    types: 'Types',
    filesNumber: 'Nombre de fichiers',
    min_width: 'Min_width',
    max_width: 'Max_width',
    min_height: 'Min_height',
    max_height: 'Max_height',
    min_dpi: 'Min_dpi',
    max_dpi: 'Max_dpi',
    min_width_cm: 'Min_width_cm',
    max_width_cm: 'Max_width_cm',
    min_height_cm: 'Min_height_cm',
    max_height_cm: 'Max_height_cm',
    color: 'Couleur',
    colors: {
        no_restricted: 'Aucune restriction',
        color_only: 'Couleur uniquement',
        black_and_white_only: 'Noir et blanc uniquement',
    },
    layout: 'Layout',
    change_group: 'Changer de groupe',
    ratio: 'Ratio',
    max_size: 'Max_size',
    catalog: 'Catalog',
    catalogs: 'Catalogues',
    add_catalog: 'Ajouter un catalogue',
    edit_catalog: 'Editer un catalogue',
    platform_user_groups: 'Groupes Utilisateurs Plateformes',
    add_platform_user_group: "Ajouter un groupe d'utilisateurs",
    registration: 'Le groupe peut être inscrit sur la plateforme',
    select_platform: 'Sélectionner une plateforme',
    data_collections: 'Collections de Données',
    data_collection: 'Collection de Données',
    dataCollections: 'Collections de Données',
    add_data_collection: 'Ajouter une collection de données',
    edit_data_collection: 'Editer une collection de données',
    properties: 'Propriétés',
    properties_groups: 'Groupes de propriétés',
    add_property: 'Ajouter une propriété',
    filter_property: 'Filtrer la propriété',
    linked_to: 'Relié à',
    edit_property_group: 'Editer un groupe de propriétés',
    propertyGroup: 'Groupe de propriétés',
    propertyCount: 'Nombre de propriétés',
    add_property_group: 'Ajouter un groupe de propriétés',
    add_contact_group: 'Ajouter un groupe de contacts',
    edit_contact_group: 'Editer un groupe de contacts',
    contact_groups: 'Groupes de contacts',
    company_groups: 'Groupes d\'entreprises',
    deal_groups: 'Groupes de transactions',
    item_groups: 'Groupes d\'items',
    product_groups: 'Groupes de produits',
    customer: 'Client',
    enable_user: 'Activer l\'utilisateur',
    disable_user: 'Désactiver l\'utilisateur',
    file_groups: 'Groupes de fichiers',
    transforms: 'Transformations',
    edit_file_group: 'Editer un groupe de fichiers',
    add_file_group: 'Ajouter un groupe de fichiers',
    add_product_group: 'Ajouter un groupe de produits',
    edit_product_group: 'Editer un groupe de produits',
    add_item_group: 'Ajouter un groupe d\'items',
    edit_item_group: 'Editer un groupe d\'items',
    add_deal_group: 'Ajouter un groupe de transactions',
    edit_deal_group: 'Editer un groupe de transactions',
    add_company_group: 'Ajouter un groupe d\'entreprises',
    edit_company_group: 'Editer un groupe d\'entreprises',
    edit_transform: 'Editer une transformation',
    add_transform: 'Ajouter une transformation',
    add_company: 'Ajouter une entreprise',
    edit_company: 'Editer une entreprise',
    contactGroup: 'Groupe de contacts',
    add_contact: 'Ajouter un contact',
    edit_contact: 'Editer un contact',
    add_deal: 'Ajouter une transaction',
    edit_deal: 'Editer une transaction',
    crm: 'CRM',
    security: 'Sécurité',
    crm_groups: 'Groupes de CRM',
    errors: {
        positive_number: 'Le nombre doit être positif',
        can_not_use_email:
            'Vous ne pouvez pas utiliser cette adresse email',
        email_already_exists: 'Cette email existe déjà',
        app_user_exists:
            'Un utilisateur App existe déjà avec cette adresse email',
    },
    undefined: 'Non défini',
    error: 'Erreur',
    reload: 'Recharger',
    without_group: 'Sans groupe',
    directors: 'Directeurs',
    admins: 'Administrateurs',
    user: 'Utilisateur',
    users: 'Utilisateurs',
    platform: 'Plateforme',
    platform_download_config: {
        label: 'Formulaire de téléchargement',
        add: 'Créer une configuration',
        edit: 'Éditer la configuration',
        delete: 'Supprimer la configuration',
    },
    platform_security: 'Plateforme Sécurité',
    add_security_rule: 'Ajouter un rule',
    edit_security_rule: 'Editer un rule',
    user_activated: "L'utilisateur est activé",
    activate_account: 'Activer votre compte',
    activate: 'Activer',
    type: 'Type',
    createdBy: 'Créer par',
    linkedTo: 'Lié à',
    multiple: 'Multiple',
    required: 'Requis',
    translatable: 'Traduisible',
    choices_list: 'Liste de choix',
    deals: 'Transactions',
    companies: 'Entreprises',
    contacts: 'Contacts',
    expanded: 'Étendu',
    select_bucket: 'Sélectionner un bucket',
    select_language: 'Sélectionner une langue',
    day_first_letter: 'J',
    enum: {
        mimeType: {
            category: {
                image: 'Image',
                video: 'Vidéo',
                audio: 'Audio',
                document: 'Document',
                archive: 'Archive',
                spreadsheet: 'Feuille de calcul',
            }
        },
        file_size: {
            lt1m: 'Moins de 1 Mb',
            lt10m: 'Entre 1Mb et 10 Mb',
            lt100m: 'Entre 10 Mb et 100 Mb',
            lt500m: 'Entre 100 Mb et 500 Mb',
            lt1g: 'Entre 500 Mb et 1 Go',
            gte1g: 'Plus de 1 Go',
        },
        property: {
            type: {
                Text: 'Texte',
                Textarea: 'Zone de texte',
                Email: 'Email',
                Link: 'URL',
                Status: 'Statut',
                Phone: 'Téléphone',
                Date: 'Date',
                Choice: 'Liste de choix',
                Number: 'Nombre',
                Address: 'Adresse',
                Checkbox: 'Case à cocher',
                Price: 'Prix',
                Currency: 'Devise',
                Collection: 'Collection',
                Calculate: 'Calcul',
                Combined: 'Combiné',
                Mention: 'Mention',
            },
            collection: {
                Catalog: 'Catalogues',
                DataCollection: 'Collections de Données',
                Bucket: 'Buckets',
                Contact: 'Contacts',
                Deal: 'Transactions',
                Company: 'Entreprises',
                Preorder: 'Pré commande',
                Shopping: 'Shopping',
            },
            address: {
                fields: {
                    name: 'Nom',
                    row1: 'Ligne 1',
                    row2: 'Ligne 2',
                    city: 'Ville',
                    postalCode: 'Code postal',
                    country: 'Pays',
                }
            },
            separator: {
                fields: {
                    none: 'Aucun, une seule valeur dans cette colonne',
                    prefix: 'Préfixe',
                    suffix: 'Suffixe',
                    comma: 'Virgule ,',
                    semicolon: 'Point-virgule ;',
                    colon: 'Deux-points :',
                    pipe: 'Pipe |',
                    dash: 'Tiret -',
                    underscore: 'Underscore _',
                    space: 'Espace',
                    new_line: 'Saut de ligne',
                    dot: 'Point .',
                    tab: 'Tabulation',
                }
            },
            mention: {
                Catalog: 'Catalogues',
                Bucket: 'Buckets',
            },
        },
        collection: {
            import: {
                column: {
                    matched_by_field: {
                        alpha2: 'Code ISO 3166-1 alpha-2',
                        alpha3: 'Code ISO 3166-1 alpha-3',
                        name: 'Nom',
                        identifier: 'Identifiant',
                        property: 'Propriété',
                    },
                },
            },
            event: {
                Create: 'Créer',
                Update: 'Mettre à jour',
                Delete: 'Supprimer',
                Date: 'Arriver à échéance',
                State: 'Changement d\'état',
            }
        },
        platform: {
            event: {
                RegisterUser: 'Enregistrement d\'un utilisateur',
                ValidateUser: 'Validation d\'un utilisateur',
                DownloadFile: 'Download d\'un fichier',
                UploadFile: 'Upload d\'un fichier',
            }
        },
        shopping: {
            event: {
                State: 'Changement d\'état',
                shoppingDateType: {
                  'designed-delivery': 'Livraison prévue',
                    'planned-return': 'Retour prévu',
                },
            }
        },
        notification: {
            collection: {
                scheduled: {
                    label: 'Planifié',
                    Instant: 'Instantané',
                    Daily: 'Quotidien',
                    Weekly: 'Hebdomadaire',
                },
                days: {
                    Monday: 'Lundi',
                    Tuesday: 'Mardi',
                    Wednesday: 'Mercredi',
                    Thursday: 'Jeudi',
                    Friday: 'Vendredi',
                    Saturday: 'Samedi',
                    Sunday: 'Dimanche',
                }
            },
        },
    },
    phoneType: {
        mobile: 'Mobile',
        fixed_line: 'Fixe',
        any: 'Tous',
        pager: 'Pager',
        personal_number: 'Numéro personnel',
        shared_cost: 'Coût partagé',
        toll_free: 'Numéro vert',
        UAN: 'UAN',
        Voip: 'Voip',
        Voicemail: 'Messagerie vocale',
    },

    edit_brand: 'Editer une marque',
    edit_bucket: 'Editer un bucket',
    edit_platform: 'Editer une plateforme',
    edit_property: 'Editer une propriété',
    phone_type: 'Type de téléphone',
    account_edit: 'Édition du compte',
    change_password: 'Changer de mot de passe',
    administrator: 'Administrateur',
    renewal_request: 'Renouveller la demande',
    password_reset_text:
        'If an account matching your email exists, then an email was just sent that contains a link that you can use to reset your password. This link will expire in',
    password_reset_message_try_again:
        "If you don't receive an email please check your spam folder or",
    try_again: 'try again',
    waiting_validation: 'En attente de validation',
    searching: 'Recherche...',
    table: {
        show_entries: 'Voir {{s}} lignes',
    },
    platform_registrations: 'Disponible à l’inscription sur',
    availableForRegistrationGroups: 'Groupe de contact disponible à l’inscription',
    import: 'Importer',
    files_import: 'Import de fichiers',
    data_import: 'Import de données',
    importer: {
        modal: {
            uploading: {
                title: 'En cours de téléchargement',
                'is-validate': 'Formulaire validé - l`import sera automatiquement validé à la fin du téléchargement',
            },
            uploaded: {
                title: 'Téléchargement terminé',
                subtitle: 'Vous pouvez valider l\'import',
                error_zero: 'Aucune erreur',
                error_one: 'Un fichier en erreur',
                error_other: '{{count}} fichiers en erreur',
            },
            success: {
                title: 'Import terminée avec succès',
            },
            error: {
                title: 'Une erreur est survenue',
            },
            'drop-possible': {
                title: 'Déposez votre fichier ici',
            },
            'drop-not-possible': {
                title: 'Vous ne pouvez pas déposer de fichier pour le moment',
            },
            cancel: {
                title: 'Annuler l\'import en cours',
                content: 'Êtes-vous sûr de vouloir annuler l\'import en cours ? \n Cela va supprimer tous les fichiers télécharger et les données enregistrées dans les formulaires',
                confirm: 'Oui, annuler',
            }
        }
    },
    corporate: 'Corporate',
    modal: {
        choose_brand: {
            title: 'Choisissez une marque',
        },
        choose_platform: {
            title: 'Choisissez une plateforme',
        },
    },
    reporting: 'Reporting',
    managePlatform: 'Gestion plateforme',
    attachmentFileSizeLimitExceeded: 'Taille limite dépassée (max 9.99mb)',
    mailingList: 'Liste de diffusion',
    mailingLists: 'Listes de diffusion',
    nbContacts: 'Nombre de contacts',
    addMailingList: 'Ajouter une liste de diffusion',
    editMailingList: 'Éditer une liste de diffusion',
    groupedAction: 'Action groupée',
    unselectAll: 'Tout déselectionner',
    selectAll: 'Tout sélectionner',
    groupedActionTitle: {
        contact_zero: 'Action groupée sur les contacts',
        contact_one: 'Action groupée sur un contact',
        contact_other: 'Action groupée sur {{count}} contacts',
        file: 'Action groupée sur {{count}} fichiers',
        archive_stickers_zero: 'Archiver des stickers',
        archive_stickers_one: 'Archiver les étiquettes d\'un produit',
        archive_stickers_other: 'Archiver les étiquettes de {{count}} produits',
        generate_stickers_zero: 'Générer des stickers',
        generate_stickers_one: 'Générer des étiquettes pour un produit',
        generate_stickers_other: 'Générer des étiquettes pour {{count}} produits',
        print_stickers_zero: 'Imprimer des stickers',
        print_stickers_one: 'Imprimer les étiquettes d\'un produit',
        print_stickers_other: 'Imprimer les étiquettes de {{count}} produits',
        transform: 'Transformer',
        transform_zero: 'Transformer des images',
        transform_one: 'Transformer une image',
        transform_others: 'Transformer {{count}} images',
        remove: {
            contact_zero: 'Archiver des contacts',
            contact_one: 'Archiver un contact',
            contact_other: 'Archiver {{count}} contacts',
            company_zero: 'Archiver des entreprises',
            company_one: 'Archiver une entreprise',
            company_other: 'Archiver {{count}} entreprises',
            deal_zero: 'Archiver des transactions',
            deal_one: 'Archiver une transaction',
            deal_other: 'Archiver {{count}} transactions',
            bucket_zero: 'Archiver des fichiers',
            bucket_one: 'Archiver un fichier',
            bucket_other: 'Archiver {{count}} fichiers',
            catalog_zero: 'Archiver des produits',
            catalog_one: 'Archiver un produit',
            catalog_other: 'Archiver {{count}} produits',
            dataCollection_zero: 'Archiver ces objets',
            dataCollection_one: 'Archiver un objet',
            dataCollection_other: 'Archiver {{count}} objets',
            shopping_zero: 'Archiver des shoppings',
            shopping_one: 'Archiver un shopping',
            shopping_other: 'Archiver {{count}} shoppings',
            preOrder_zero: 'Archiver des pré-commandes',
            preOrder_one: 'Archiver une pré-commande',
            preOrder_other: 'Archiver {{count}} pré-commandes',
        },
        download: {
            contact_zero: 'Télécharger des contacts',
            contact_one: 'Télécharger un contact',
            contact_other: 'Télécharger {{count}} contacts',
            company_zero: 'Télécharger des entreprises',
            company_one: 'Télécharger une entreprise',
            company_other: 'Télécharger {{count}} entreprises',
            deal_zero: 'Télécharger des transactions',
            deal_one: 'Télécharger une transaction',
            deal_other: 'Télécharger {{count}} transactions',
            bucket_zero: 'Télécharger des fichiers',
            bucket_one: 'Télécharger un fichier',
            bucket_other: 'Télécharger {{count}} fichiers',
            catalog_zero: 'Télécharger des produits',
            catalog_one: 'Télécharger un produit',
            catalog_other: 'Télécharger {{count}} produits',
            dataCollection_zero: 'Télécharger ces objets',
            dataCollection_one: 'Télécharger un objet',
            dataCollection_other: 'Télécharger {{count}} objets',
            shopping_zero: 'Télécharger des shoppings',
            shopping_one: 'Télécharger un shopping',
            shopping_other: 'Télécharger {{count}} shoppings',
            preOrder_zero: 'Télécharger des pré-commandes',
            preOrder_one: 'Télécharger une pré-commande',
            preOrder_other: 'Télécharger {{count}} pré-commandes',
        },
        export: {
            contact_zero: 'Exporter des contacts',
            contact_one: 'Exporter un contact',
            contact_other: 'Exporter {{count}} contacts',
            company_zero: 'Exporter des entreprises',
            company_one: 'Exporter une entreprise',
            company_other: 'Exporter {{count}} entreprises',
            deal_zero: 'Exporter des transactions',
            deal_one: 'Exporter une transaction',
            deal_other: 'Exporter {{count}} transactions',
            bucket_zero: 'Exporter des fichiers',
            bucket_one: 'Exporter un fichier',
            bucket_other: 'Exporter {{count}} fichiers',
            catalog_zero: 'Exporter des produits',
            catalog_one: 'Exporter un produit',
            catalog_other: 'Exporter {{count}} produits',
            dataCollection_zero: 'Exporter ces objets',
            dataCollection_one: 'Exporter un objet',
            dataCollection_other: 'Exporter {{count}} objets',
            shopping_zero: 'Exporter des shoppings',
            shopping_one: 'Exporter un shopping',
            shopping_other: 'Exporter {{count}} shoppings',
            preOrder_zero: 'Exporter des pré-commandes',
            preOrder_one: 'Exporter une pré-commande',
            preOrder_other: 'Exporter {{count}} pré-commandes',
        },
        group: {
            contact_zero: 'Action groupée sur les contacts',
            contact_one: 'Associer un groupe au contact sélectionné',
            contact_other: 'Associer un groupe aux {{count}} contacts sélectionnés',
            company_zero: 'Action groupée sur les entreprises',
            company_one: 'Associer un groupe à l\'entreprise sélectionnée',
            company_other: 'Associer un groupe aux {{count}} entreprises sélectionnées',
            deal_zero: 'Action groupée sur les transactions',
            deal_one: 'Associer un groupe à la transaction sélectionnée',
            deal_other: 'Associer un groupe aux {{count}} transactions sélectionnées',
            bucket_zero: 'Action groupée sur les fichiers',
            bucket_one: 'Associer un groupe au fichier sélectionné',
            bucket_other: 'Associer un groupe aux {{count}} fichiers sélectionnés',
            catalog_zero: 'Action groupée sur les produits',
            catalog_one: 'Associer un groupe au produit sélectionné',
            catalog_other: 'Associer un groupe aux {{count}} produits sélectionnés',
            dataCollection_zero: 'Action groupée sur les objets',
            dataCollection_one: 'Associer un groupe à l\'objet sélectionné',
            dataCollection_other: 'Associer un groupe aux {{count}} objets sélectionnés',
        },
        tag: {
            bucket_zero: 'Ajouter des tags sur les fichiers',
            bucket_one: 'Ajouter des tags sur le fichier sélectionné',
            bucket_other: 'Ajouter des tags sur les {{count}} fichiers sélectionnées',
            catalog_zero: 'Ajouter des tags sur les produits',
            catalog_one: 'Ajouter des tags sur le produit sélectionné',
            catalog_other: 'Ajouter des tags sur les {{count}} produits sélectionnés',
            company_zero: 'Ajouter des tags sur les entreprises',
            company_one: 'Ajouter des tags sur l\'entreprises sélectionnée',
            company_other: 'Ajouter des tags sur les {{count}} entreprises sélectionnées',
            contact_zero: 'Ajouter des tags sur les contacts',
            contact_one: 'Ajouter des tags sur le contact sélectionné',
            contact_other: 'Ajouter des tags sur les {{count}} contacts sélectionnés',
        },
        property: {
            contact_zero: ' Éditer des contacts',
            contact_one: 'Éditer un contact',
            contact_other: 'Éditer {{count}} contacts',
            company_zero: 'Éditer des entreprises',
            company_one: 'Éditer une entreprise',
            company_other: 'Éditer {{count}} entreprises',
            deal_zero: 'Éditer des transactions',
            deal_one: 'Éditer une transaction',
            deal_other: 'Éditer {{count}} transactions',
            bucket_zero: 'Éditer des fichiers',
            bucket_one: 'Éditer un fichier',
            bucket_other: 'Éditer {{count}} fichiers',
            catalog_zero: 'Éditer des produits',
            catalog_one: 'Éditer un produit',
            catalog_other: 'Éditer {{count}} produits',
            dataCollection_zero: 'Éditer ces objets',
            dataCollection_one: 'Éditer un objet',
            dataCollection_other: 'Éditer {{count}} objets',
            shopping_zero: 'Éditer des shoppings',
            shopping_one: 'Éditer un shopping',
            shopping_other: 'Éditer {{count}} shoppings',
            preOrder_zero: 'Éditer des pré-commandes',
            preOrder_one: 'Éditer une pré-commande',
            preOrder_other: 'Éditer {{count}} pré-commandes',
        },
        publish: {
            bucket_zero: 'Publier des fichiers',
            bucket_one: 'Publier un fichier',
            bucket_other: 'Publier {{count}} fichiers',
            catalog_zero: 'Publier des produits',
            catalog_one: 'Publier un produit',
            catalog_other: 'Publier {{count}} produits',
        },
        unPublish: {
            bucket_zero: 'Dépublier des fichiers',
            bucket_one: 'v un fichier',
            bucket_other: 'Dépublier {{count}} fichiers',
            catalog_zero: 'Dépublier des produits',
            catalog_one: 'Dépublier un produit',
            catalog_other: 'Dépublier {{count}} produits',
        },
        rename: {
            contact_zero: 'Modifier du texte des contacts',
            contact_one: 'Modifier du texte un contact',
            contact_other: 'Modifier du texte {{count}} contacts',
            company_zero: 'Modifier du texte des entreprises',
            company_one: 'Modifier du texte une entreprise',
            company_other: 'Modifier du texte {{count}} entreprises',
            deal_zero: 'Modifier du texte des transactions',
            deal_one: 'Modifier du texte une transaction',
            deal_other: 'Modifier du texte {{count}} transactions',
            bucket_zero: 'Modifier du texte des fichiers',
            bucket_one: 'Modifier du texte un fichier',
            bucket_other: 'Modifier du texte {{count}} fichiers',
            catalog_zero: 'Modifier du texte des produits',
            catalog_one: 'Modifier du texte un produit',
            catalog_other: 'Modifier du texte {{count}} produits',
            dataCollection_zero: 'Modifier du texte ces objets',
            dataCollection_one: 'Modifier du texte un objet',
            dataCollection_other: 'Modifier du texte {{count}} objets',
            shopping_zero: 'Modifier du texte des shoppings',
            shopping_one: 'Modifier du texte un shopping',
            shopping_other: 'Modifier du texte {{count}} shoppings',
            preOrder_zero: 'Modifier du texte des pré-commandes',
            preOrder_one: 'Modifier du texte une pré-commande',
            preOrder_other: 'Modifier du texte {{count}} pré-commandes',
        },
        reporting: {
            contact_zero: 'Reporting des contacts',
            contact_one: 'Reporting un contact',
            contact_other: 'Reporting {{count}} contacts',
            company_zero: 'Reporting des entreprises',
            company_one: 'Reporting une entreprise',
            company_other: 'Reporting {{count}} entreprises',
            deal_zero: 'Reporting des transactions',
            deal_one: 'Reporting une transaction',
            deal_other: 'Reporting {{count}} transactions',
            bucket_zero: 'Reporting des fichiers',
            bucket_one: 'Reporting un fichier',
            bucket_other: 'Reporting {{count}} fichiers',
            catalog_zero: 'Reporting des produits',
            catalog_one: 'Reporting un produit',
            catalog_other: 'Reporting {{count}} produits',
            dataCollection_zero: 'Reporting ces objets',
            dataCollection_one: 'Reporting un objet',
            dataCollection_other: 'Reporting {{count}} objets',
            shopping_zero: 'Reporting des shoppings',
            shopping_one: 'Reporting un shopping',
            shopping_other: 'Reporting {{count}} shoppings',
            preOrder_zero: 'Reporting des pré-commandes',
            preOrder_one: 'Reporting une pré-commande',
            preOrder_other: 'Reporting {{count}} pré-commandes',
            pre_order: 'PreOrder',
            dataCollection: 'Data collection',
        },
        restore: {
            bucket_zero: 'Désarchiver des fichiers',
            bucket_one: 'Désarchiver un fichier',
            bucket_other: 'Désarchiver {{count}} fichiers',
            catalog_zero: 'Désarchiver des produits',
            catalog_one: 'Désarchiver un produit',
            catalog_other: 'Désarchiver {{count}} produits',
        },
    },
    nb_products_zero: 'Aucun produit',
    nb_products_one: '1 produit',
    nb_products_other: '{{count}} produits',
    groupedActionContent: {
        printStickers: {
            inputLabel: 'Nom de l\'archive',
        },
        remove: {
            contact_zero: 'Êtes-vous sûr de vouloir archiver ?',
            contact_one: 'Êtes-vous sûr de vouloir archiver ce contant ?',
            contact_other: 'Êtes-vous sûr de vouloir archiver ces {{count}} contacts ?',
            company_zero: 'Êtes-vous sûr de vouloir archiver ?',
            company_one: 'Êtes-vous sûr de vouloir archiver cette entreprise ?',
            company_other: 'Êtes-vous sûr de vouloir archiver ces {{count}} entreprises ?',
            deal_zero: 'Êtes-vous sûr de vouloir archiver ?',
            deal_one: 'Êtes-vous sûr de vouloir archiver cette transaction ?',
            deal_other: 'Êtes-vous sûr de vouloir archiver ces {{count}} transactions ?',
            bucket_zero: 'Êtes-vous sûr de vouloir archiver ?',
            bucket_one: 'Êtes-vous sûr de vouloir archiver ce fichier ?',
            bucket_other: 'Êtes-vous sûr de vouloir archiver ces {{count}} fichiers ?',
            catalog_zero: 'Êtes-vous sûr de vouloir archiver ?',
            catalog_one: 'Êtes-vous sûr de vouloir archiver ce produit ?',
            catalog_other: 'Êtes-vous sûr de vouloir archiver ces {{count}} produits ?',
            dataCollection_zero: 'Êtes-vous sûr de vouloir archiver ?',
            dataCollection_one: 'Êtes-vous sûr de vouloir archiver cet objet ?',
            dataCollection_other: 'Êtes-vous sûr de vouloir archiver ces {{count}} objets ?',
            shopping_zero: 'Êtes-vous sûr de vouloir archiver ?',
            shopping_one: 'Êtes-vous sûr de vouloir archiver ce shopping ?',
            shopping_other: 'Êtes-vous sûr de vouloir archiver ces {{count}} shoppings ?',
            preOrder_zero: 'Êtes-vous sûr de vouloir archiver ?',
            preOrder_one: 'Êtes-vous sûr de vouloir archiver cette pré-commande ?',
            preOrder_other: 'Êtes-vous sûr de vouloir archiver ces {{count}} pré-commandes ?',
        },
        publish: {
            bucket_zero: 'Êtes-vous sûr de vouloir publier ?',
            bucket_one: 'Êtes-vous sûr de vouloir publier ce fichier ?',
            bucket_other: 'Êtes-vous sûr de vouloir publier ces {{count}} fichiers ?',
            catalog_zero: 'Êtes-vous sûr de vouloir publier ?',
            catalog_one: 'Êtes-vous sûr de vouloir publier ce produit ?',
            catalog_other: 'Êtes-vous sûr de vouloir publier ces {{count}} produits ?',
        },
        unPublish: {
            bucket_zero: 'Êtes-vous sûr de vouloir dépublier ?',
            bucket_one: 'Êtes-vous sûr de vouloir dépublier ce fichier ?',
            bucket_other: 'Êtes-vous sûr de vouloir dépublier ces {{count}} fichiers ?',
            catalog_zero: 'Êtes-vous sûr de vouloir dépublier ?',
            catalog_one: 'Êtes-vous sûr de vouloir dépublier ce produit ?',
            catalog_other: 'Êtes-vous sûr de vouloir dépublier ces {{count}} produits ?',
        },
        download: {
            inputLabel: 'Nom de l\'archive',
        },
        export: {
            inputLabel: 'Nom de l\'export',
        },
        rename: {
            displayLabel: 'Extrait de la sélection:',
            nameLabel: 'Nom ({{language}})'
        },
        reporting: {
            inputLabel: 'Titre du reporting',
            choiceLabel: 'Valeur de séparation',
        },
        restore: {
            bucket_zero: 'Êtes-vous sûr de vouloir désarchiver ?',
            bucket_one: 'Êtes-vous sûr de vouloir désarchiver ce fichier ?',
            bucket_other: 'Êtes-vous sûr de vouloir désarchiver ces {{count}} fichiers ?',
            catalog_zero: 'Êtes-vous sûr de vouloir désarchiver ?',
            catalog_one: 'Êtes-vous sûr de vouloir désarchiver ce produit ?',
            catalog_other: 'Êtes-vous sûr de vouloir désarchiver ces {{count}} produits ?',
        },
    },
    groupedActionChoice: {
        mailingList: {
            edit: 'Gérer les listes de diffusion',
            newStatic: 'Nouvelle liste de diffusion statique',
            addStatic_one: 'Ajouter le contact sélectionné à une liste existante',
            addStatic_other: 'Ajouter les contacts {{count}} sélectionnés  à une liste existante',
            newDynamic: 'Nouvelle liste de diffusion dynamique',
            replaceDynamic: 'Modifier une liste de diffusion dynamique',
        },
        bimboShare: {
            new: 'Nouveau BimboShare',
            addItems: 'Ajouter a un BimboShare',
        },
        shopping: {
            shopping: 'Shopping',
            preOrder: 'Pré-commande',
            new_shopping: 'Nouveau shopping',
            new_preOrder: 'Nouvelle pré-commande',
        },
        rename: {
            addText: 'Ajouter du texte',
            findAndReplace: 'Remplacer du texte',
            prependText: 'Ajouter au début',
            appendText: 'Ajouter à la fin',
            mode: 'Mode',
            search: 'Rechercher',
            replace: 'Remplacer par',
        },
    },
    edit_file: 'Éditer le fichier',
    dynamic: 'Dynamique',
    static: 'Statique',
    publish: 'Publier',
    unPublish: 'Dépublier',
    bimboShare: 'Bimbo Share',
    newBimboShare: 'Nouveau Bimbo Share',
    editBimboShare: 'Editer un Bimbo Share',
    status: 'Statut',
    expiredAt: 'Expire le',
    download: 'Télécharger',
    downloading: 'Téléchargement',
    send: 'Envoyer',
    duplicate: 'Dupliquer',
    emptySelection: 'Selection vide',
    bimboShareForm: {
        'productWithPDF': 'Joindre les versions PDF de chaque produit présent',
        'productOriginalFile': 'Joindre les fichiers sources de chaque produit présent',
        'productUniqueDirectory': 'Placer tous les fichiers dans un dossier unique',
    },
    pending_validation: 'En attente de validation',
    external_files: {
        label: 'Fichiers externes',
        state: {
            pending: 'En attente',
            uploading: 'En cours d\'envoi',
            uploaded: 'Envoyé',
            error: 'Erreur',
        },
    },
    copy_to_clipboard: 'Copier dans le presse-papier',
    importConfig: 'Configuration des imports',
    add_import_config: 'Ajouter un nouvel import',
    edit_import_config: 'Editer un import',
    timestamp: {
        createdAt: 'Créé le',
        updatedAt: 'modifié le',
        by: 'par',
        and: 'et',
    },
    add_tag: 'Ajouter un tag',
    item_status: {
        draft: 'Brouillon',
        published: 'Publié',
        unpublished: 'Non publié',
        archived: 'Archivé',
        finalized: 'Finalisé',
        validated: 'Validé',
        rejected: 'Rejeté',
        outputted: 'Sorti',
        online: 'En ligne',
        generating: 'Génération en cours',
        expired: 'Expiré',
        offline: 'Hors ligne',
        sent: 'Envoyé',
        refused: 'Refusé',
        returned: 'Retourné',
        waited: 'En attente',
        gift: 'Offert',
        lost: 'Perdu',
        empty: 'Vide',
        error: 'Erreur',
        planned: 'Programmé',
    },
    import_status: {
        failed: 'Echec',
        pending: 'En attente',
        uploading: 'En cours',
        uploaded: 'Uploadé',
        'presigned-url-not-found': 'Erreur de chargement',
        'presigned-url-requested': 'Demande de lien sécurisé',
        rejected: 'Rejeté',
        ready: 'Prêt à être uploadé',
        accepted: 'Accepté',
    },
    import_analyze_status: {
        failed: 'Echec de l\’analyze',
        analyzing: 'Analyse en cours',
        success: 'Accepté',
        pending: 'En attente',
        'in-progress': 'En cours',
        uploaded: 'Uploadé',
        processing: 'En cours',
        finalized: 'Finalisé',
        rejected: 'Rejeté',
        accepted: 'Accepté',
    },
    file_versioning: {
        error: 'Erreur, un fichier en base porte déjà ce nom',
        warning: 'Un fichiers en base porte déjà ce nom, cliquez sur l’icône pour le renommer'
    },
    sticker_status: {
      'in-stock': 'En stock',
        outputted: 'Sorti',
        archived: 'Archivé',
        'not-available-for-this-date': 'Non disponible pour ces dates',
    },
    reject_file: 'Fichier rejeté',
    since: 'Depuis le',
    from: 'de',
    to: 'à',
    created_from: 'Créé à partir du',
    created_to: 'Créé jusqu\'au',
    updated_from: 'Mis à jour à partir du',
    updated_to: 'Mis à jour jusqu\'au',
    date_from: 'à partir du',
    price_from: 'à partir de',
    price_to: 'jusqu\'à',
    number_from: 'à partir de',
    number_to: 'jusqu\'à',
    designed_delivery_from: 'Livraison prévu à partir du',
    designed_delivery_to: 'Conçu pour la livraison jusqu\'au',
    planned_return_from: 'Retour prévu à partir du',
    planned_return_to: 'Retour prévu jusqu\'au',
    date_to: 'jusqu\'au',
    tags: 'Tags',
    comments: 'Commentaires',
    comments_placeholder: 'Ajouter un commentaire',
    dropzone_placeholder: 'Glisser-déposer des fichiers ici',
    upload: 'Déposer',
    product_card: 'Fiche produit PDF',
    products: 'Produits',
    export: 'Exporter',
    rename: 'Modifier du texte',
    variables: 'Variables',
    link_variables: 'Liaisons des variables',
    reindex: 'Réindexer',
    matching_files: 'Fichiers correspondants',
    columns: 'Colonnes',
    or_import: 'ou importer',
    drop_your_files: 'Glissez ici vos fichiers',
    bucket: 'Bucket',
    resume: 'Reprendre',
    new_import: 'Nouvel import',
    choose_import: 'Choisir un import',
    choose_an_value: 'Choisir une valeur',
    address_field: 'Champ d\'adresse',
    replacedBy: 'Remplacé par',
    code: 'Code',
    sticker: 'Étiquette',
    stickers: 'Étiquettes',
    generate_new_stickers: 'Générer de nouvelles étiquettes',
    create_new_sticker: 'Créer de nouvelles étiquettes',
    add_sticker: 'Ajouter une étiquette',
    no_sticker: 'Aucune étiquette',
    generate: 'Générer',
    offline: 'Hors ligne',
    online: 'En ligne',
    pdf: 'PDF',
    delivery_paper_requested: 'Génération de la fiche de suivie en attente de traitement',
    delivery_paper_in_progress: 'Génération de la fiche de suivie en cours',
    delivery_paper_send_request: 'Génération de la fiche de suivie en cours d\'envoi',
    request_regenerate: 'Demander une nouvelle génération',
    request_status: 'Statut de la demande',
    separator: 'Séparateur',
    shoppings: 'Shoppings',
    collection_import: {
        start: 'Déposez votre fichier pour commencer l\'import en masse',
        modal: {
            bucket: {
              title: 'Import',
            },
            contact: {
                title: 'Importer des contacts en masse',
            },
            company: {
                title: 'Importer des sociétés en masse',
            },
            deal: {
                title: 'Importer des transactions en masse',
            },
            catalog: {
                title: 'Importer des produits en masse',
            },
            dataCollection: {
                title: 'Importer des données en masse',
            },
            drop: 'Glissez ici votre fichier xls(x) ou csv',
            notDroppable: {
                many_files: 'Vous ne pouvez uploader qu\'un seul fichier en même temps',
                file_not_found: 'Aucune fichier trouvé',
                bad_mime_type: 'Seul les fichiers excel ou CSV sont accépté pour l\'instant',
            },
            loading: {
                init: 'Initialisation',
                'on-init': 'Initialisation en cours',
                title: 'Chargement',
                'on-upload': 'En cours d\'envoi',
                uploaded: 'En attente d\'analyze',
                'on-analyze': 'Analyze en cours',
                analyzed: 'Analyze terminé',
                'wait-for-analyze': 'En attente d\'analyze',
            }
        },
        form: {
            title: 'Paramétrage de l\'import',
            first_line: {
                label: 'Ignorer la 1er ligne',
            },
            csv_separator: {
                label: 'Délimiteur',
            },
        }
    },
    and_more_line_one: 'et une autre ligne...',
    and_more_line_other: 'et {{count}} autres lignes...',
    ignored: 'Ignorée',
    analyze: 'Analyser',
    platform_access_one: 'Accès à la plateforme',
    platform_access_other: 'Accès aux plateformes',
    filter_collections: 'Filtrer la liste de collections',
    choice_properties: 'Propriétés de type choix',
    checkbox_properties: 'Propriétés de type case à cocher',
    boolean_values: {
        true: 'Oui',
        false: 'Non',
    },
    select_type: 'Choisir un type de colonne',
    select_property: 'Choisir une propriété',
    select_identifier: 'Choisir un identifiant',
    select_address_field: 'Choisir un champ d\'adresse',
    select_company: 'Choisir une entreprise',
    ignore: 'Ignorer',
    ignore_all: 'Tout ignorer',
    clear_all: 'Tout effacer',
    append: 'Ajouter',
    old_versions: 'Versions antérieures',
    informations: 'Informations',
    visible_on_platform: 'Visible sur la plateforme',
    target: 'Cible',
    notifications: 'Notifications',
    notification_types: {
        collection: 'Collection notifications',
        shopping: 'Shopping notifications',
        platform: 'Plateforme notifications',
        mention: 'Mention notifications'
    },
    add_event: 'Ajouter un événement',
    edit_event: 'Éditer un événement',
    edit_notification: 'Éditer une notification',
    add_notification: 'Ajouter une notification',
    add_item: 'Ajouter un objet',
    edit_item: 'Modifier un objet',
    collection: 'Collection',
    event: 'Évènement',
    entity: 'Nom de la collection',
    nb_notifications_zero: 'Aucune notification',
    nb_notifications_one: 'Une notification',
    nb_notifications_other: '{{count}} notifications',
    at: 'à',
    recipients: 'Destinataires',
    mimeTypeCategory: 'Type de fichier',
    transformed: 'Transformé',
    fileSize: 'Taille du fichier',
    markAsRead: 'Marquer comme lu',
    viewNotification: 'Afficher la notification',
    subject: 'Sujet',
    how_many_stickers: 'Combien de nouvelles étiquettes voulez-vous générer ?',
    send_register_mail: 'Renvoyer le mail d\'inscription',
    send_reset_password: 'Renvoyer le mail de réinitialisation du mot de passe',
    print: 'Imprimer',
    add_to_history: 'Ajouter à l\'historique',
    pass_to_returned_status: 'Passe directement au statut retourné',
    validate_all_products: 'Valider tous les produits',
    output_all_products: 'Sortir tous les produits',
    return_all_products: 'Retourner tous les produits',
    archive_because: 'Raison de l\'archivage',
    shopping_history: 'Historique des shoppings',
    future_bookings: 'Futures réservations',
    outputted_at: 'Date de sortie',
    outputted_by: 'Sortie par',
    returned_at: 'Date de retour',
    planned_return_at: 'Date de retour prévue le',
    returned_by: 'Retour par',
    no_future_booking: 'Aucune réservation future',
    no_history: 'Aucun historique',
    no_return_date: 'Aucune date de retour',
    cancelArchive: 'Annuler l\'archivage',
    shopping_export: 'Config des export shoppings',
    shopping_properties: 'Shopping propriétés',
    shopping_view_properties: 'Affichage des propriétés dans les shoppings',
    pre_order_export: 'Config des export des pré-commandes',
    pre_order_properties: 'Pré-commande propriétés',
    pre_order_view_properties: 'Affichage des propriétés dans les pré-commandes',
    contact_export: 'Config des export des contacts',
    company_export: 'Config des export des entreprises',
    deal_export: 'Config des export des transactions',
    add_export_config: 'Ajouter une config d\'export',
    edit_export_config: 'Éditer une config d\'export',
    delete_export_config: 'Supprimer une config d\'export',
    export_config: 'Config d\'export',
    select_export: 'Choisir un export',
    export_excel: 'Excel (.xlsx)',
    export_csv: 'CSV (.csv)',
    export_mode: 'Mode d\'export',
    shoppingLate: 'Shoppings en retard',
    withoutProduct: 'Fichier sans produit',
    withoutFile:'Produit sans visuel',
    hasSticker: 'Étiquettes',
    product_with_sticker: 'Produit avec étiquette(s)',
    product_with_archived_sticker: 'Produit avec étiquette(s) archivée(s)',
    product_with_no_sticker: 'Produit sans étiquette',
    product_outputted: 'Produit sorti',
    product_available:'Produit disponible',
    published: 'Publié',
    unpublished: 'Non Publié',
    mailings: 'Mailings',
    mailing: {
        add: 'Créer un mailing',
        edit: 'Éditer un mailing',
        send: 'Envoyer un mailing',
        send_a: 'Envoyer le mailing',
        template: 'Template',
        saved_block: {
            form: {
                name: 'Entrez le nom du bloc personnalisé'
            },
            notification: {
                create: {
                    title: 'Création',
                    text: 'Le bloc a bien été enregistré.',
                },
                update: {
                    title: 'Modification',
                    text: 'Le bloc a bien été enregistré.',
                },
                delete: {
                    title: 'Suppression',
                    text: 'Le bloc a bien été supprimé.',
                }
            }
        },
        campaign: 'Campagne du {{ sendAt }}',
        statistics: 'Statistiques',
        statistics_campaign: 'Statistiques de la campagne du {{ sendAt }}',
        external_files: {
            label: 'Fichiers externes',
            add: 'Ajouter un fichier externe',
        },
        external_attachments: {
            label: 'Pièces jointes',
            add: 'Ajouter une pièce jointe',
        },
        preview: 'Aperçu',
        recipients: 'Destinataires',
        domains: 'Domaines',
        links: 'Liens',
        unsubscribes: 'Désabonnements',
        follow: 'Suivi',
        column: {
            domain: 'Nom de domaine',
            email: 'Adresse email',
            eventAt: 'Date'
        },
        event: {
            prefix: 'Mails',
            types: {
                Send_one: 'Soumis',
                Send_other: 'Soumis',
                RenderingFailure: 'RenderingFailure',
                Reject: 'Reject',
                Delivery_one: 'Valide',
                Delivery_other: 'Valides',
                Bounce_one: 'Erreur de destinataire',
                Bounce_other: 'Erreurs de destinataire',
                Complaint: 'Complaint',
                DeliveryDelay: 'DeliveryDelay',
                Subscription_one: 'Désabonnement',
                Subscription_other: 'Désabonnements',
                Open_one: 'Lu',
                Open_other: 'Lus',
                Click_one: 'Cliqué',
                Click_other: 'Cliqués',
            }
        }
    },
    mailing_identity: {
        title: 'Identités de mail',
        add: 'Ajouter un domaine',
        email: {
            add: 'Ajouter un email',
            edit: 'Modifier un email',
            verify: 'Verification de l\'email',
        },
    },
    verification: 'Vérification',
    SendingEnabled: 'Envoi possible',
    VerificationStatus: 'Status de vérification DNS',
    dns: {
        params: 'Paramètres DNS',
    },
    value: 'Valeur',
    domains: 'Domaines',
    emails: 'Emails',
    verify: 'Vérifier',
    verified: 'Vérifié',
    choose_bucket: 'Choisir un répertoire',
    archived: 'Archivé',
    display_only_not_returned: 'Produits non retournés uniquement',
    delivery_paper_automatically_generated: 'La fiche de sortie sera automatiquement régénérée',
    totalPreOrders: 'Pré-commandes',
    currentPreOrders: 'Pré-commandes en cours',
    totalShoppings: 'Shoppings',
    currentShoppings: 'Shoppings en cours',
    planned_send: 'Envoi programmé',
    upload_system: {
        reject_reason: {
            'mime_type': 'Le type de fichier n\'est pas autorisé',
            'size': 'Le fichier est trop volumineux',
            'min_width': 'La largeur de l\'image est trop petite',
            'max_width': 'La largeur de l\'image est trop grande',
            'min_height': 'La hauteur de l\'image est trop petite',
            'max_height': 'La hauteur de l\'image est trop grande',
            'min_width_cm': 'La largeur de l\'image est trop petite par rapport à sa résolution (en cm)',
            'max_width_cm': 'La largeur de l\'image est trop grande par rapport à sa résolution (en cm)',
            'min_height_cm': 'La hauteur de l\'image est trop petite par rapport à sa résolution (en cm)',
            'max_height_cm': 'La hauteur de l\'image est trop grande par rapport à sa résolution (en cm)',
            'min_dpi': 'La résolution de l\'image est trop faible',
            'max_dpi': 'La résolution de l\'image est trop élevée',
            'color': 'L\'image doit être en couleur',
            'black_and_white': 'L\'image doit être en noir et blanc',
            'layout': 'Le format de l\'image n\'est pas autorisé (portrait ou paysage)',
            'resolution_units': 'L\'unité de résolution n\'est pas autorisée',
            'ratio': 'Le ratio de l\'image n\'est pas autorisé',
            'name_already_exists': 'Un fichier avec ce nom existe déjà',
            'file_already_exists': 'Un fichier avec ce nom existe déjà',
            'file_does_not_exist': 'Le fichier est introuvable',
        },
    },
    choose_a_company: 'Choisir une entreprise',
    choose_a_file: 'Choisir un fichier',
    choose_a_mailing_list: 'Choisir une liste de diffusion',
    choose_a_group: 'Choisir un groupe',
    is_not_possible_switch_user: 'Cette action n\'est pas possible en switch user',
}

export default app
