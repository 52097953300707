import React, { FC, useContext, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import BlankPage from 'Components/Layout/BlankPage'
import LoadingButton from 'Components/Button/LoadingButton'
import { BannerContext } from 'Vendor/Context/BannerContextProvider'
import FormEmpty from 'Vendor/Components/FormEmpty'

const MainPage: FC = () => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [rule, setRule] = useOutletContext<[any, (rule: any) => void]>()
    const [success, setSuccess] = useState<boolean>(false)
    const { setBanner } = useContext(BannerContext)
    const onSubmit = () => {
        setIsLoading(true)
    }
    const onResponse = () => {
        setIsLoading(false)
    }
    const onSuccess = () => {
        setSuccess(true)
        setBanner({
            type: 'success',
            i18nTitle: 'app.save',
            i18nMessage: 'app.data_saved_message',
        })
    }

    const onUploadSuccess = (rule: any) => {
        setBanner({
            type: 'success',
            i18nTitle: 'app.save',
            i18nMessage: 'app.upload_success_message',
        })
        setRule({ ...rule })
    }

    const onError = () => {
        setSuccess(false)
    }

    return (
        <BlankPage>
            <FormEmpty
                path={'/admin/edition/security-rule/' + rule.id}
                method="PATCH"
                formPath={true}
                onSubmit={onSubmit}
                onResponse={onResponse}
                onSuccess={onSuccess}
                onError={onError}
            >
                <div className="row justify-content-end align-items-center">
                    <div className="col-auto">
                        <LoadingButton type="submit" isLoading={isLoading}>
                            {t('app.validate')}
                        </LoadingButton>
                    </div>
                </div>
            </FormEmpty>
        </BlankPage>
    )
}

export default MainPage
