import { Rows } from 'Components/Layout/Table'

const PlatformSecurityTable: Rows = {
    id: {
        placeholders: [
            {
                element: 'p',
                size: 1
            }
        ]
    },
    platform: {
        field:'name',
        placeholders: [
            {
                element: 'p',
                size: [3],
            },
        ],
    },
    contacts: {
        field:'name',
        placeholders: [
            {
                element: 'p',
                size: [3, 1],
            }
        ]
    },
    groups: {
        field:'name',
        placeholders: [
            {
                element: 'p',
                size: [3, 1],
            }
        ]
    },
    bucket: {
        field: 'name',
        fieldTranslated: true,
        placeholders: [
            {
                element: 'p',
                size: [3, 1],
            }
        ]
    },
    catalog: {
        field: 'name',
        fieldTranslated: true,
        placeholders: [
            {
                element: 'p',
                size: [3, 1],
            }
        ]
    },
    actions: {
        className: 'actions',
        sortable: false,
        placeholders: [
            {
                element: 'a',
                size: 2,
            },
        ],
    },
}

export default PlatformSecurityTable
