import React, { FC, useContext, useEffect, useState } from 'react'
import { UserContext } from 'Vendor/Context/UserContextProvider'
import { classUtils as c } from 'Vendor/Utils/ClassUtils'
import _MenuItem, { MenuItem } from './Sidebar/_MenuItem'
import _Account from './Sidebar/_Account'
import _HeaderExternal from "./Sidebar/_HeaderExternal";
import _HeaderEdition from "./Sidebar/_HeaderEdition";
import AppMenu from "Menu/AppMenu";
import useTranslationDataUtils from "Vendor/Utils/TranslationDataUtils";
import Platform from "../../Entity/Platform";

export const Sidebar: FC = () => {
    const {currentUser} = useContext(UserContext)
    const [isHidden, setIsHidden] = useState<boolean>(true)
    const [isNavOpen, setIsNavOpen] = useState<boolean>(true)
    const {translate} = useTranslationDataUtils()

    useEffect(() => {
        const idTimeout = setTimeout(() => {
            setIsHidden(false)
        }, 300)

        return () => {
            clearTimeout(idTimeout)
        }
    }, [])

    if (typeof currentUser === 'undefined') return <></>

    let globalMenu: MenuItem[][] = [];

    if (currentUser.access && AppMenu.hasOwnProperty(currentUser.access)) {
        if (currentUser.access === 'external') {
            let menu = AppMenu[currentUser.access];
            //globalMenu.push(menu.slice(0, 1))
            let endMenu = [...menu]//.slice(1)

            let brandMenu: MenuItem[] = []
            currentUser.currentOrganization?.buckets.filter(bucket => {
                return ((!currentUser.currentBrand && bucket.brand === null) || (currentUser.currentBrand && currentUser.currentBrand === bucket.brand?.slug)) && !bucket.simpleImport
            }).forEach(bucket => {
                let item: MenuItem = {
                    to: `/b/${bucket.primarySlug}`,
                    className: `fa-solid fa-${bucket.icon ?? (bucket.hidden ? 'eye-slash' : 'file')}`,
                    label: translate(bucket, 'name'),
                    muted: bucket.hidden
                }
                brandMenu.push(item)
            })

            currentUser.currentOrganization?.catalogs.filter(catalog => {
                return (!currentUser.currentBrand && catalog.brand === null) || (currentUser.currentBrand && currentUser.currentBrand === catalog.brand?.slug)
            }).forEach(catalog => {
                let item: MenuItem = {
                    to: `/c/${catalog.primarySlug}`,
                    className: 'fa-solid fa-bag-shopping',
                    label: translate(catalog, 'name'),
                }
                brandMenu.push(item)
            })

            currentUser.currentOrganization?.dataCollections.filter(dataCollection => {
                return (!currentUser.currentBrand && dataCollection.brand === null) || (currentUser.currentBrand && currentUser.currentBrand === dataCollection.brand?.slug)
            }).forEach(dataCollection => {
                let item: MenuItem = {
                    to: `/d/${dataCollection.primarySlug}`,
                    className: 'fa-solid fa-database',
                    label: translate(dataCollection, 'name'),
                }
                brandMenu.push(item)
            })

            globalMenu.push(brandMenu)

            if (currentUser.currentOrganization?.options.includes('studio')) {
                /*endMenu.push({
                    to: '/edition',
                    className: 'fa-solid fa-pen-to-square',
                    label: 'app.edition',
                })*/
            }

            if (currentUser.currentOrganization?.options.includes('bimbo_share')) {
                endMenu.push({
                    to: '/bimbo-share',
                    className: 'fa-solid fa-share-nodes',
                    label: 'Bimbo Share',
                })
            }

            if (currentUser.currentOrganization?.options.includes('mailing')) {
                endMenu.push({
                    to: '/mailing',
                    className: 'fa-solid fa-envelope',
                    label: 'app.mailings',
                })
            }

            if (!currentUser.group?.restrictedAccess || currentUser.group.allowedCRM) {
                endMenu.push({
                    baseTo: '/crm',
                    to: '/crm/contact',
                    className: 'fa-solid fa-puzzle-piece',
                    label: 'CRM',
                })
            }

            if (!currentUser.group?.restrictedAccess || currentUser.group.allowedUser) {
                endMenu.push({
                    to: '/user',
                    className: 'fa-solid fa-users',
                    label: 'app.users',
                })
            }

            if ((currentUser.currentOrganization?.platforms?.length ?? 0) > 0 && currentUser.administrator) {
                let platform: undefined|Platform = currentUser.currentOrganization?.platforms[0] ?? undefined
                endMenu.push({
                        to: '/platform-setting/' + platform?.slug,
                        className: 'fa-solid fa-sliders',
                        label: 'app.managePlatform',
                })
            }
            if (currentUser.currentOrganization?.options.includes('shopping')
                && currentUser.currentOrganization?.setting.shoppingTypes.includes('shopping')
                && (!currentUser.group?.restrictedAccess
                    || currentUser.group.allowedShopping)
            ) {
                endMenu.push({
                        to: '/shopping',
                        className: 'fa-solid fa-shopping-cart',
                        label: 'app.shopping',
                    }
                )
            }
            if (currentUser.currentOrganization?.options.includes('shopping')
                && currentUser.currentOrganization?.setting.shoppingTypes.includes('pre-order')
                && (!currentUser.group?.restrictedAccess
                    || currentUser.group.allowedPreOrder)
            ) {
                endMenu.push({
                        to: '/pre-order',
                        className: 'fa-solid fa-shopping-cart',
                        label: 'app.pre_order',
                    }
                )
            }
            globalMenu.push(endMenu)
        }
        else {
            globalMenu.push(AppMenu[currentUser.access]);
        }
    }

    return (
        <header
            id="main-menu"
            className={c(!isNavOpen && 'minify', isHidden && 'hidden')}
        >
            <div className="menu">
                <nav>
                    <>
                        {
                        currentUser.access === 'external' ? <_HeaderExternal />
                            : currentUser.access === 'edition' ? <_HeaderEdition />
                            : <></>
                        }
                    </>
                    <ul className="menu-list">
                        {globalMenu.map((menus, menuKey) => (
                            <div key={menuKey}>
                                {menus.map((item, i) => {
                                    const isMaif = currentUser?.currentOrganization?.name === 'Maif';
                                    const isCRM = item.to === "/crm/contact" && isMaif;

                                    return (
                                        <_MenuItem
                                            {...{
                                                ...item,
                                                to: item.to ? item.to : '',
                                                display: isCRM
                                            }}
                                            key={i}
                                        />
                                    );
                                })}
                                {(menuKey + 1) < globalMenu.length && currentUser?.currentOrganization?.brands?.length ? (
                                    <li className="separator"></li>
                                ) : (
                                    ''
                                )}
                            </div>
                        ))}
                    </ul>

                    <_Account />
                </nav>
                <button id="menu-reduce" onClick={() => setIsNavOpen((isOpen) => !isOpen)}>
                    <svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5' viewBox='0 0 20 20' fill='currentColor' width='100' height='100'>
                        <path
                                fill='currentColor'
                                fillRule='evenodd'
                              d='M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z'
                              clipRule='evenodd'
                              stroke="var(--bs-secondary)"
                              strokeWidth="1"
                        />
                    </svg>
                </button>
            </div>
        </header>
    )
}

export default Sidebar