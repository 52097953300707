import React, {FC, useContext, useEffect, useState} from 'react'
import Product from "Entity/Product";
import Catalog from "Entity/Catalog";
import {UserContext} from "Vendor/Context/UserContextProvider";
import _ProductFileListing from "./_ProductFileListing";
import Bucket from "Entity/Bucket";
import User from "Entity/User";
import File from "../../../Entity/File";

const getBuckets = (catalog: Catalog, position: 'prepend'|'append', user?: User): Bucket[] => {
    let buckets: Bucket[] = []
    catalog.buckets.forEach(bucketSlug => {
        user?.currentOrganization?.buckets.forEach(bucket => {
            if (bucket.primarySlug === bucketSlug && ((position === 'prepend' && !bucket.simpleImport) || (position === 'append' && bucket.simpleImport))) {
                buckets.push(bucket)
            }
        })
    })

    return buckets
}

const _ProductEdit: FC<{
    product: Product
    catalog: Catalog
    position: 'prepend'|'append'
    setShowSelectFilesModal: (show: undefined|string) => void
    setFiles: (bucket: string, files: File[]) => void
    setMainVisual: (file: File) => void
    onChangeMainVisual: boolean
    canEdit: boolean
    disableChangeMainVisual: boolean
}> = ({product, catalog, position, setShowSelectFilesModal, setFiles, setMainVisual, onChangeMainVisual, canEdit, disableChangeMainVisual}) => {
    const {currentUser} = useContext(UserContext)
    const [buckets, setBuckets] = useState<Bucket[]>(() => {
        return getBuckets (catalog, position, currentUser)
    })

    useEffect(() => {
        setBuckets(getBuckets (catalog, position, currentUser))
    }, [catalog, position, currentUser])

    return (
        <>
            {buckets.map(bucket =>
                <_ProductFileListing
                    key={bucket.primarySlug}
                    bucket={bucket}
                    product={product}
                    action={((): void => {
                     setShowSelectFilesModal(bucket.primarySlug);
                    })}
                    files={product?.files?.hasOwnProperty(bucket.primarySlug)
                     ? product.files[bucket.primarySlug] : []}
                    setFiles={setFiles}
                    setMainVisual={setMainVisual}
                    onChangeMainVisual={onChangeMainVisual}
                    canEdit={canEdit}
                    disableChangeMainVisual={disableChangeMainVisual}
                />
            )}
        </>
    )
}

export default _ProductEdit
