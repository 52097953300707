import React, {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    Button, Modal,
    ModalBody as Body,
    ModalFooter as Footer,
    ModalHeader as Header,
    ModalTitle as Title
} from "react-bootstrap";
import GroupedAction from "Entity/Collection/ListingSetting/GroupedAction";
import LoadingButton from "../../Button/LoadingButton";
import Collection from "Config/Collection";
import Property from "../../../Entity/Property";
import {useNavigate} from "react-router-dom";
import FormEmpty from "../../../Vendor/Components/FormEmpty";
import {classUtils as c} from "../../../Vendor/Utils/ClassUtils";

export interface _BimboShareModalProps {
    collection?: Collection
    showActionModals: {[key: string]: boolean|Property}
    handleShowActionModal: (groupedAction?: GroupedAction, actionFromMain?:boolean) => void
    fromMain: boolean
    handleShowMainActionModal: () => void
    selected: number[]
    actionModalFormIsLoading: boolean
    handleActionModalOnResponse: () => void
    handleActionModalOnSuccess: () => void
    handleActionModalOnSubmit: () => void
    actionModalFormIsLoaded: boolean,
    setActionModalFormIsLoaded: (isLoaded: boolean) => void,
}

export type BimboShareActionModal = undefined | 'new' | 'addItems'

const _BimboShareModal: FC<_BimboShareModalProps> = ({
                                                   collection,
                                                   handleShowActionModal,
                                                   fromMain,
                                                   handleShowMainActionModal,
                                                   selected,
                                                   actionModalFormIsLoading,
                                                   setActionModalFormIsLoaded,
                                                   handleActionModalOnResponse,
                                                   handleActionModalOnSuccess,
                                                   handleActionModalOnSubmit,
                                                   actionModalFormIsLoaded,
                                                   showActionModals,
                                               }) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [additionalData, setAdditionalData] = useState<undefined|object>(undefined)
    const [bimboShareActionType, setBimboShareActionType] = useState<BimboShareActionModal>(undefined)

    const onSubmit = () => {
        handleActionModalOnSubmit()
    }

    useEffect(() => {
        if (collection === 'catalog') {
            setAdditionalData({
                products: selected,
            })
        } else if (collection === 'bucket') {
            setAdditionalData({
                files: selected,
            })
        } else {
            setAdditionalData(undefined)
        }
    }, [collection, selected])

    const onSuccess = (data: any) => {
        navigate('/bimbo-share/', {state: {
            bimboShare: data.uuid
        }})
    }

    const onHide = () => {
        handleShowActionModal()
        setBimboShareActionType(undefined)
    }

    return <Modal show={showActionModals.share !== false} onHide={onHide}>
        <Header closeButton={!fromMain}>
            <div className="modal-block-header">
                {fromMain && <i className="fa-solid fa-angle-left" onClick={() => {
                    if (bimboShareActionType === undefined) {
                        handleShowMainActionModal()
                    } else {
                        setBimboShareActionType(undefined)
                    }
                }}></i>}
                <Title>
                    {t(bimboShareActionType === undefined ? 'app.bimboShare' : `app.groupedActionChoice.bimboShare.${bimboShareActionType}`, {count: selected.length})}
                </Title>
            </div>
        </Header>
        <Body>
            {bimboShareActionType === undefined ?
                <ul className="grouped-action-choices">
                    <li onClick={() => {
                        setBimboShareActionType('new')
                    }} className={c("btn btn-secondary", selected.length === 0 && 'disabled')}>
                                <span>
                                    <i className="fa-solid fa-plus icon"></i>
                                    {t('app.groupedActionChoice.bimboShare.new', {count: selected.length})}
                                </span>
                        <i className="action-icon"></i>
                    </li>
                    <li onClick={() => {
                        setBimboShareActionType('addItems')
                    }} className={c("btn btn-secondary", selected.length === 0 && 'disabled')}>
                                <span>
                                    <i className="fa-solid fa-list-check icon"></i>
                                    {t('app.groupedActionChoice.bimboShare.addItems', {count: selected.length})}
                                </span>
                        <i className="action-icon"></i>
                    </li>
                </ul>
                :
                <FormEmpty
                    path={bimboShareActionType === 'new' ? '/bimbo-share/new/1' : `/bimbo-share/add-items`}
                    method='post'
                    additionalData={additionalData}
                    setIsLoaded={setActionModalFormIsLoaded}
                    onResponse={handleActionModalOnResponse}
                    onSubmit={onSubmit}
                    onSuccess={onSuccess}
                >
                    <div className="row justify-content-end align-items-center mb-3">
                        <div className="col-auto">
                            <LoadingButton type="submit" isLoading={actionModalFormIsLoading} disabled={!actionModalFormIsLoaded}>
                                {t('app.add')}
                            </LoadingButton>
                        </div>
                    </div>
                </FormEmpty>
            }
        </Body>
        {!fromMain && <Footer>
            <Button variant="secondary" onClick={onHide}>
                {t('app.cancel')}
            </Button>
        </Footer>}
    </Modal>
}

export default _BimboShareModal
