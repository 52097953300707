import {RouteObject} from "react-router-dom";
import React from "react";
import VariablesPage from "Pages/Admin/Edition/Platform/VariablesPage";

const VariablesRouting: RouteObject[] = [
    {
        index: true,
        element: <VariablesPage />,
    },
]

export default VariablesRouting