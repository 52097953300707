import React, {FC, FormEvent, ReactElement, ReactNode, useCallback, useContext, useEffect, useState} from 'react'
import ReactDomServer from 'react-dom/server';
import {DataType, FormErrors} from "./Form";
import Loader from "./Loader";
import Api from "../Api";
import {Alert, Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Method} from "axios";
import {classUtils as c} from 'Vendor/Utils/ClassUtils'
import useFormDataParser from "../Utils/FormDataParserUtils";
import ChooseItemsModal, {ChooseItemsModalDataProps,} from "../../Components/Modal/ChooseItemsModal";
import EntityInterface from "../Definition/EntityInterface";
import Collection from "../../Config/Collection";
import DataCollection from "../../Entity/DataCollection";
import Bucket from "../../Entity/Bucket";
import Catalog from "../../Entity/Catalog";
import {UserContext} from "../Context/UserContextProvider";
import useTranslationDataUtils from "../Utils/TranslationDataUtils";

export type FormResponseType = {
    formName: string
    form: string
    script?: string
}

export type FormEmptyProps = {
    path: string
    formPath?: boolean | string
    method?: Method
    children?: ReactNode
    onSubmit?: (e?: FormEvent<HTMLFormElement>) => void | boolean
    onSuccess?: (data: any) => void
    onError?: (errors?: FormErrors) => void
    onResponse?: (response?: any) => void
    setIsLoaded?: (loaded: boolean) => void
    additionalData?: object
    additionalParams?: object
    formContent?: {
        className?: string
    }
    formSubmit?: {
        className?: string
    }
    delocateInitForm?: boolean | FormResponseType
    className?: string
    appendForm?: ReactNode
    prependForm?: ReactNode
    formId?: string
    disabled?: boolean
    config?: object
    successReturnResponse?: boolean
}

const FormEmpty: FC<FormEmptyProps> = ({
    path,
    formPath,
    method= 'post',
    children,
    onSubmit,
    onSuccess,
    onError,
    onResponse,
    setIsLoaded,
    additionalData,
    additionalParams,
    formContent,
    formSubmit,
    delocateInitForm = false,
    className,
    appendForm,
    prependForm,
    formId,
    disabled,
    config,
    successReturnResponse = false,
}) => {
    const {t} = useTranslation()
    const {translate} = useTranslationDataUtils()
    const { currentUser } = useContext(UserContext)
    const {formDataParser} = useFormDataParser()
    const [init, setInit] = useState<boolean>(false)
    const [validate, setValidate] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)
    const [formName, setFormName] = useState<string|undefined>(undefined)
    const [htmlForm, setHtmlForm] = useState<{__html: string}|undefined>(undefined)
    const [scriptForm, setScriptForm] = useState<string|undefined>(undefined)
    // modal target element
    const [target, setTarget] = useState<HTMLDivElement|null>(null)
    const [onLoad, setOnLoad] = useState<boolean>(false)
    const [profilerTokenURL, setProfilerTokenURL] = useState<string|undefined>(undefined)

    const setShow = (tempShow: boolean) => {
        if (!tempShow) {
            closeChooseItemsModal()
        }
    }

    const generateItem = (item: EntityInterface, fullName: string): ReactElement => {
        return <>
            {item.label}
            <input type="hidden" name={fullName} value={item.id} data-label={item.label} />
        </>
    }

    const refreshModalList = (): void => {
        if (target) {
            let content: ReactElement = <></>

            if (chooseItemsModalProps.selected.length > 0) {
                let fullName: string = target.dataset.fullName as string
                if (chooseItemsModalProps.multiple) {
                    fullName += '[]'
                    let elements = []
                    for (let i = 0; i < chooseItemsModalProps.selected.length; i++) {
                        let item = chooseItemsModalProps.selected[i]
                        elements.push(<button key={i} type="button" className="form-collection-entity-multiple-item">{generateItem(item, fullName)}</button>)
                    }
                    content = <>{elements}</>
                } else {
                    let item = chooseItemsModalProps.selected[0]
                    content = generateItem(item, fullName)
                }
            }

            target.innerHTML = ReactDomServer.renderToString(content)
        }
    }

    const openChooseItemsModal = (entity: Collection, primarySlug: undefined|string, path: string, selected: EntityInterface[], title: string, multiple: boolean): void => {
        let tempProps = {...chooseItemsModalProps}
        tempProps.entity = entity
        tempProps.primarySlug = primarySlug
        tempProps.path = path
        tempProps.selected = selected
        tempProps.title = title
        tempProps.show = true
        tempProps.multiple = multiple
        setChooseItemsModalProps(tempProps)
    }

    const closeChooseItemsModal = (): void => {
        let tempProps = {...chooseItemsModalProps}
        tempProps.entity = undefined
        tempProps.primarySlug = undefined
        tempProps.path = ''
        tempProps.selected = []
        tempProps.title = ''
        tempProps.show = false
        tempProps.multiple = false
        setChooseItemsModalProps(tempProps)
    }

    const [chooseItemsModalProps, setChooseItemsModalProps] = useState<ChooseItemsModalDataProps>({
        entity: undefined,
        primarySlug: undefined,
        path: "",
        selected: [],
        title: "",
        show: false,
        multiple: false,
    })

    const setForm = useCallback((form: FormResponseType) =>
    {
        setHtmlForm({
            __html: form.form
        })

        setFormName(form.formName)
        setScriptForm(form.script)

        setIsLoaded && setIsLoaded (true)
    }, [setIsLoaded])

    useEffect(() => {
        setInit(false)
    }, [path])

    useEffect(() => {
        if (path && !init && !delocateInitForm && !onLoad) {
            setError(false)
            setOnLoad(true)
            Api.get(typeof formPath === 'string' ? formPath : path + ((formPath === true) ? '/form' : ''), {params: additionalParams})
                .then(({data}: {data: FormResponseType}) => {
                    setForm(data)
                    setInit(true)
                    setOnLoad(false)
                })
                .catch((err) => {
                    setError(true)
                    setInit(true)
                    setOnLoad(false)
                    if (err.response.headers.hasOwnProperty('x-debug-token-link')) {
                        setProfilerTokenURL(err.response.headers['x-debug-token-link'])
                    }
                })
        }
        else if (delocateInitForm) {
            if (typeof delocateInitForm === 'object') {
                setForm(delocateInitForm)
                setInit(true)
            } else {
                setInit(false)
                setForm({form: "", formName: ""})
            }
        }
    }, [init, path, delocateInitForm, formPath, setForm, onLoad])

    useEffect(() => {
        if (scriptForm) {
            const executeScript = new Function(scriptForm)
            executeScript()
        }

        if (htmlForm) {
            $(".form-select[multiple], .form-select.form-select2").select2({theme: "bootstrap-5"})
        }
    }, [htmlForm, scriptForm])

    const setSelected = (selected: EntityInterface[]): void => {
        let tempProps = {...chooseItemsModalProps}
        let getSelectedItem = (id: number): EntityInterface|null => {
            let selectedItem: EntityInterface|null = null
            tempProps.selected.forEach((item) => {
                if (item.id === id) {
                    selectedItem = item
                }
            })

            return selectedItem;
        }

        let editSelected: EntityInterface[] = []
        for (let i = 0; i < selected.length; i++) {
            let item = selected[i]
            let label = ''
            let selectedItem: EntityInterface|null = getSelectedItem(item.id ?? 0)
            if (selectedItem !== null) {
                editSelected.push(selectedItem)
                continue
            }

            if (chooseItemsModalProps.entity) {
                if (['bucket', 'company', 'deal'].includes(chooseItemsModalProps.entity)) {
                    label = item.name
                } else if (['catalog', 'data-collection'].includes(chooseItemsModalProps.entity)) {
                    label = translate(item, 'name')
                } else if ('contact' === chooseItemsModalProps.entity) {
                    if (item.firstName && item.lastName) {
                        label = item.firstName + ' ' + item.lastName
                    } else if (item.firstName) {
                        label = item.firstName
                    } else if (item.lastName) {
                        label = item.lastName
                    } else if (item.email) {
                        label = item.email
                    } else {
                        label = "#" + item.id
                    }
                } else {
                    if (item.hasOwnProperty('name')) {
                        label = item.name
                    } else if (item.hasOwnProperty('label')) {
                        label = item.label
                    } else if (item.hasOwnProperty('id')) {
                        label = "#" + item.id
                    } else {
                        label = 'error : unknown entity'
                    }
                }
            } else {
                label = 'error : no entity'
            }

            editSelected.push({
                id: item.id,
                label: label,
            })
        }

        tempProps.selected = editSelected
        setChooseItemsModalProps(tempProps)
    }

    const handleClick = (e: any) => {
        if (!disabled) {
            let event = e as MouseEvent
            let target = event.target as HTMLElement

            if (target.tagName === 'BUTTON' && target.classList.contains('form-collection-entity-multiple-item')) {
                target.remove()
                return
            }

            if (!(target.tagName === 'DIV' && target.classList.contains('form-collection-entity'))) return
            setTarget(target as HTMLDivElement)

            let collection = target.dataset.collection as Collection
            let primarySlug: null|string = null
            let tempMultiple: boolean = target.dataset.multiple === 'multiple'

            let entity: null|DataCollection|Catalog|Bucket = null
            if (target.dataset.hasOwnProperty('primarySlug')) {
                primarySlug = target.dataset.primarySlug as string

                let field:string = ''
                if (collection === 'data-collection') {
                    field = 'dataCollections'
                } else {
                    field = collection + 's'
                }

                let list: null|Catalog[]|Bucket[]|DataCollection[] = null
                if (currentUser?.currentOrganization) {
                    list = currentUser.currentOrganization[field] as Catalog[]|Bucket[]|DataCollection[]
                    for (let i = 0; i < list.length; i++) {
                        let item = list[i] as DataCollection | Catalog | Bucket
                        if (item.primarySlug === primarySlug) {
                            entity = item
                            break
                        }
                    }
                }
            }

            let title: string = entity ? translate(entity, 'name') : t(`app.${collection}`)

            let path: string = ''
            if (currentUser?.access === 'edition') {
                path = '/admin/edition'
            } else if (currentUser?.access === 'internal') {
                path = '/admin'
            }
            path += '/collection/choose-items'

            if (target.dataset.property !== 'false') {
                let property = parseInt(target.dataset.property as string, 10)
                path += '/' + property
            } else {
                path += '?collection=' + collection
                if (primarySlug) {
                    path += '&primarySlug=' + primarySlug
                }
            }

            let selected: EntityInterface[] = []
            let inputs = target.querySelectorAll('input[type="hidden"]')
            for (let i = 0; i < inputs.length; i++) {
                let input = inputs[i] as HTMLInputElement
                if (input.value !== '') {
                    let data: EntityInterface = {
                        id: parseInt(input.value, 10),
                        label: input.dataset.label as string,
                    }
                    selected.push(data)
                }
            }

            openChooseItemsModal(collection, primarySlug ?? undefined, path, selected, title, tempMultiple)
        }
    }

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (disabled) return

        let form:HTMLFormElement = e.target as HTMLFormElement

        if (!form.checkValidity()) {
            form.reportValidity()
            return
        }

        if (onSubmit) {
            onSubmit(e)
        }

        let formData = new FormData(form)

        let data: DataType = formDataParser(formData, formName)

        if (additionalData !== undefined) {
            data = {...data, ...additionalData}
        }

        Api.request({
            ...config,
            params: additionalParams,
            url: path,
            method,
            data,
        })
            .then(response => {
                onSuccess && response && (successReturnResponse ? onSuccess(response) : onSuccess(response.data))
            })
            .catch(error => {
                let form = error.response.data as FormResponseType
                setForm(form)
            })
            .then(response => {
                onResponse && onResponse(response)
            })
    }

    return (
        <form noValidate onSubmit={handleSubmit} id={formId} aria-disabled={disabled} className={c(className, validate ? 'was-validated' : undefined)} onClick={handleClick}>
            <div key={0} className={formContent?.className}>
                {prependForm}
                {!init ?
                    <Loader auto={true} /> :
                    error ? <>
                            <Alert variant="danger">
                                {t('error.view_error')}
                                {profilerTokenURL && <a href={profilerTokenURL} target="_blank" className="mx-3" rel="noreferrer">dev: profiler</a>}
                                {<Button onClick={() => { setOnLoad(false); setInit(false) }}><i className="fas fa-arrows-rotate"></i></Button>}
                            </Alert>
                        </>
                        : <div dangerouslySetInnerHTML={htmlForm} />
                }
                {appendForm}
            </div>
            <div key={1} className={formSubmit?.className}>
                {children}
            </div>
            {chooseItemsModalProps.entity && <ChooseItemsModal {...chooseItemsModalProps} setShow={setShow} setSelected={setSelected} refreshModalList={refreshModalList} />}
        </form>
    )
}

export default FormEmpty
