import {FC, ReactNode, useEffect, useState} from "react";
import Form, {DataType, FormEntitiesFieldInterface} from "../../Form";
import FormInterface from "Vendor/Definition/Form/FormInterface";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {classUtils as c} from "Vendor/Utils/ClassUtils";
import useTranslationDataUtils from "../../../Utils/TranslationDataUtils";
import {TranslationField} from "../../../../Components/Listing/Listing";
import FormOptionsInterface from "../../../Definition/Form/FormOptionsInterface";
import {
    FormSkeletonChildrenInterface,
    FormSubChildrenSkeletonInterface
} from "../../../Definition/Form/FormBuilderInterface";
import ChildForm from "../ChildForm";

export interface CollectionProps {
    id: string
    name: string
    fullName: string
    path: string
    entryType: FormInterface
    entryOptions?: FormOptionsInterface
    label?: string
    i18nLabel?: string
    i18nLabels?: TranslationField
    i18nAddLabel?: string
    className?: string
    required?: boolean
    values: DataType[]
    setValues?: (values: DataType[]) => void
    children: ReactNode,
    entitiesChoices?: FormEntitiesFieldInterface
    subChildrenSkeleton: FormSubChildrenSkeletonInterface
    setSubChildrenSkeleton: (subChildrenSkeleton: FormSubChildrenSkeletonInterface) => void
    col?: string
    min?: number
    max?: number
}

const CollectionType: FC<CollectionProps> = ({
                                                 id,
                                                 name,
                                                 fullName,
                                                 path,
                                                 entryType,
                                                 entryOptions,
                                                 label,
                                                 i18nLabel,
                                                 i18nLabels,
                                                 i18nAddLabel,
                                                 values,
                                                 setValues,
                                                 children,
                                                 col,
                                                 min,
                                                 max,
                                                 subChildrenSkeleton,
                                                 setSubChildrenSkeleton,
                                             }) => {
    const {t} = useTranslation()
    const {translateField} = useTranslationDataUtils()
    const [textLabel] = useState<string>(label ? label : i18nLabels ? translateField(i18nLabels) : t(i18nLabel ?? `app.${name}`))

    const setValue = (index: number, value: DataType) => {
        let tempCollectionValues: DataType[] = [...values]
        tempCollectionValues[index] = value
        setValues && setValues(tempCollectionValues)
    }

    const add = () => {
        let tempCollectionValues: DataType[] = [...values]
        tempCollectionValues.push({})
        setValues && setValues(tempCollectionValues)
    }

    useEffect(() => {
        if (typeof min === 'number' && min > 0) {
            let length:number = values?.length ?? 0;
            if (length < min) {
                for (let newData = 1; newData <= (min - values?.length); newData++) {
                    add()
                }
            }
        }
    }, [])

    return <>
        <div className="row justify-content-between">
            <div className="col-auto">
                <h4>{textLabel}</h4>
            </div>
            <div className="col-auto">
                <Button variant="outline-primary" disabled={!!(max && values?.length === max)} onClick={add}>{t(i18nAddLabel ?? 'app.add')}</Button>
            </div>
        </div>
        <div className="row my-3">
            {values && values.map((value, index) => {
                return (
                    <div className={c('mb-3', col ?? 'col-3')} key={index}>
                        <div className="card">
                            <div className="card-body">
                                <ChildForm formData={value} setFormData={(data) => {
                                    setValue(index, data)
                                }} prefix={`${id}_${index}_`}
                                    childSkeleton={subChildrenSkeleton[`child_${index}`]} setChildSkeleton={(childSkeleton: FormSkeletonChildrenInterface) => {
                                        let tempSubChildrenSkeleton = {...subChildrenSkeleton}
                                        tempSubChildrenSkeleton[`child_${index}`] = childSkeleton;
                                        setSubChildrenSkeleton(tempSubChildrenSkeleton)
                                }}
                                 form={entryType} formOptions={entryOptions}>
                                    {children}
                                </ChildForm>
                            </div>
                            {(!min || ((values?.length ?? 0) > min)) &&
                                <div className="card-footer">
                                    <div className="row justify-content-center">
                                        <div className="col-auto">
                                            <Button variant="outline-danger" onClick={() => {
                                                let tempCollectionValues: DataType[] = [...values]
                                                tempCollectionValues.splice(index, 1)
                                                setValues && setValues(tempCollectionValues)
                                            }}>{t('app.remove')}</Button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                )
            })}
        </div>
    </>
}

export default CollectionType