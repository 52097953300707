import React, {
    FC,
    ReactNode,
    useMemo,
    useCallback,
} from 'react'
import useTranslationDataUtils from "../Utils/TranslationDataUtils";
import EntityInterface from "../Definition/EntityInterface";
import Property from "../../Entity/Property";
import {useTranslation} from "react-i18next";
import Choice from "../../Entity/Property/Choice";
import Type from "../../Config/Property/Type";
import {DataType} from "../Components/Form";
const PNF = require('google-libphonenumber').PhoneNumberFormat;
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

type CollectionContextType = {
    getValue: (item: EntityInterface, property: Property, i18nPlaceholder?: string) => ReactNode
    getByType: (item: EntityInterface, type: Type, properties: Property[]) => ReactNode[]
}

export const CollectionContext = React.createContext<CollectionContextType>({
    getValue: () => <></>,
    getByType: () => [],
})

const CollectionContextProvider: FC<{ children: ReactNode }> = ({
                                                                    children,
                                                                }) => {
    const {i18n, t} = useTranslation()
    const {translate} = useTranslationDataUtils()

    const getPriceValue = (value: {currency: string, amount: string}) => {
        if (undefined === value) return ''

        let formater = new Intl.NumberFormat(i18n.language, { style: 'currency', currency: value.currency ?? 'EUR'});
        return formater.format(Number(value.amount) / 100)
    }

    const getNumberValue = (value: number) => {
        let formater = new Intl.NumberFormat(i18n.language, { style: 'decimal'});
        return formater.format(value)
    }

    const getCollectionName = (value: DataType, property: Property) => {
        if (property.collection === 'company' || property.collection === 'bucket') {
            return value.name
        } else if (property.collection === 'contact') {
            return value.firstName + ' ' + value.lastName
        } else if (property.collection === 'catalog' || property.collection === 'data-collection') {
            return translate(value, 'name')
        }

        return ''
    }

    const val = useCallback( (value: any, property: Property, i18nPlaceholder: undefined|string = undefined, forceMultiple: undefined|boolean = undefined): undefined|ReactNode => {
        const currentLanguage = i18n.language
        const multiple = forceMultiple ?? property.multiple  

        if (value === null) {
            return i18nPlaceholder ? <i className="text-muted">{t(i18nPlaceholder)}</i> : undefined
        } else if (property && property.type === 'checkbox') {
            if (!value && value !== false) {
                return i18nPlaceholder ? <i className="text-muted">{t(i18nPlaceholder)}</i> : undefined
            }

            return t(`app.${value ? 'yes' : 'no'}`)
        } else if (property.type === 'choice') {
            if (value) {
                let tempValue: undefined | Choice = undefined
                if (typeof value === 'object' && value.hasOwnProperty('translations')) {
                    tempValue = value as Choice
                } else if ((typeof value === 'string' || (typeof value === 'object' && value.hasOwnProperty('value'))) && property.choices && Array.isArray(property.choices)) {
                    if (typeof value === 'object' && value.hasOwnProperty('translations')) {
                        value = value.value
                    }

                    for (let i = 0; i < property.choices.length; i++) {
                        const choice: Choice = property.choices[i]
                        if (choice.value === value) {
                            tempValue = choice
                            break;
                        }
                    }
                } else if (Array.isArray(value)) {
                    let listOfChoices = '';
                    for (let i = 0; i < value.length; i++) {
                        listOfChoices += translate(value[i], 'label')
                        if((i+1) < value.length) {
                            listOfChoices += ' & '
                        }
                    }
                    return listOfChoices
                }

                if (tempValue) {
                    return translate(tempValue, 'label')
                }
            }

            return i18nPlaceholder ? <i className="text-muted">{t(i18nPlaceholder)}</i> : undefined
        } else if (property.type === 'phone') {
            if (!value && value !== false) {
                return i18nPlaceholder ? <i className="text-muted">{t(i18nPlaceholder)}</i> : undefined
            }

            const number = phoneUtil.parseAndKeepRawInput(value, currentLanguage.toUpperCase())
            return phoneUtil.format(number, PNF.NATIONAL)
        } else if (property.type === 'price') {
            let price = value as {currency: string, amount: string}
            return getPriceValue(price)
        } else if (property.type === 'date') {
            return (new Date(value)).toLocaleDateString()
        } else if (property.type === 'number') {
            return getNumberValue(value)
        } else if (property.type === 'calculate') {
            if (typeof value === 'object') {
                if (value.hasOwnProperty('currency') && value.hasOwnProperty('amount')) {
                    let price = value as {currency: string, amount: string}
                    return getPriceValue(price)
                }
            } else if (typeof value === "number") {
                return getNumberValue(value)
            }

            return <i className="text-danger">
                {t('app.malformed_data')}
            </i>
        } else if (property.type === "collection") {
            if (multiple) {
                if (Array.isArray(value) && null !== value && value.length > 0) {
                    let names: string[] = []
                    for (let i = 0; i < value.length; i++) {
                        names.push(getCollectionName(value[i], property))
                    }

                    if (names.length > 1) {
                        return names.slice(0, -1).join(', ') + ' & ' + names.slice(-1)
                    }

                    return names[0]
                }
            } else {
                return getCollectionName(value, property)
            }
        }

        if (typeof value === 'string') {
            return value
        }

        if (undefined === value) {
            return ''
        }

        return <i className="text-danger">
            {t('app.error')}
        </i>
    },  [i18n, t, translate])

    const getValue = useCallback( (item: EntityInterface, property: Property, i18nPlaceholder: undefined|string = undefined, forceMultiple: undefined|boolean = undefined): undefined|ReactNode => {
        let value: any = undefined
        if (item.hasOwnProperty(property.slug)) {
            value = item[property.slug]
        }

        const multiple = forceMultiple ?? property.multiple  
        if (multiple) {
            if (Array.isArray(value) && null !== value && value.length > 0) {
                const values = value.map((item) => val(item, property, i18nPlaceholder))
                return values.join(', ')
            }

            return i18nPlaceholder ? <i className="text-muted">{t(i18nPlaceholder)}</i> : undefined
        }

        return val(value, property, i18nPlaceholder, multiple)
    }, [t, val])

    const getValues = useCallback( (item: EntityInterface, property: Property): ReactNode[] => {
        let values: any[] = []
        if (item.hasOwnProperty(property.slug) && Array.isArray(item[property.slug])) {
            values = item[property.slug]
        }

        let valuesToReturn: ReactNode[] = []
        for (let i = 0; i < values.length; i++) {
            valuesToReturn.push(
                val(values[i], property, undefined)
            )
        }

        return valuesToReturn
    }, [val])

    const getByType = useCallback( (item: EntityInterface, type: Type, properties: Property[]): ReactNode[] => {
        let values: ReactNode[] = []

        for (let i = 0; i < properties.length; i++) {
            const property: Property = properties[i]
            if (property.type === type) {
                if (property.multiple) {
                    let tempValues = getValues(item, property);
                    values = values.concat(tempValues)
                } else {
                    values.push(getValue(item, property, undefined))
                }
            }
        }

        return values
    }, [getValue])

    const context = useMemo(
        () => ({getValue, getByType}),
        [getValue, getByType],
    )

    return (
        <CollectionContext.Provider value={context}>
            {children}
        </CollectionContext.Provider>
    )
}

export default CollectionContextProvider
