import {Rows} from "Components/Layout/Table";

const InAppNotifTable: Rows = {
    id: {
        placeholders: [
            {
                element: 'p',
                size: 1
            }
        ]
    },
    subject: {
        placeholders: [
            {
                element: 'p',
                size: [3],
            }
        ]
    },
    message: {
        placeholders: [
            {
                element: 'p',
                size: [3],
            }
        ]
    },
    actions: {
        className: 'actions',
        sortable: false,
        placeholders: [
            {
                element: 'a',
                size: 2,
            },
        ]
    },
};

export default InAppNotifTable
