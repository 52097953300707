import { useState, useCallback, useEffect, useContext, Dispatch, SetStateAction, Fragment } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Mention as Mentions, MentionsInput } from 'react-mentions';
import { UserContext } from 'Vendor/Context/UserContextProvider';
import Api from "Vendor/Api";
import Product from 'Entity/Product';
import Mention from 'Entity/Mention';
import File from "Entity/File";

interface User {
    email: string;
    displayName: string;
}

type _ModalMentionCommentsProps = {
    entity: File | Product
    entityType: 'file' | 'product'
    comments: Mention[]
    setComments: Dispatch<SetStateAction<Mention[]>>
    disabled?: boolean
}

const _ModalMentionComments = ({ entity, entityType, comments, setComments, disabled = false }: _ModalMentionCommentsProps) => {
    const { t } = useTranslation();
    const { currentUser } = useContext(UserContext);
    const [text, setText] = useState<string>('');
    const [editIndex, setEditIndex] = useState<number | null>(null);
    const [editText, setEditText] = useState<string>('');
    const [users, setUsers] = useState<User[]>([]);

    useEffect(() => {
        const collectionUrl = entityType === "product" ? `c/${entity.catalogPrimarySlug}` : `b/${entity.bucketPrimarySlug}`

        if (!collectionUrl) return;

        Api.post(`/${collectionUrl}/users`, {
            "sortWay": "ascending",
            "sortName": "email",
            "nbEntries": 25,
        })
            .then((res) => {
                const filteredUsers = res.data.items.filter((user: User) => user?.displayName?.trim() !== '');
                setUsers(filteredUsers);
            });
    }, [entity.id]);

    const renderUserSuggestion = (entry: any) => (
        <div className="user-suggestion">
            @{entry.display}
        </div>
    );

    //TODO
    const mentionStyle = {
        border: 0,
        outline: 0,
        fontWeight: 'bolder',
        zIndex: '10',
        position: 'relative',
        backgroundColor: 'red',
        color: 'white',
        textAlign: 'center',
    };

    const defaultInputStyle = {
        '&multiLine': {
            control: {
                fontWeight: '600',
                minHeight: '63px',
                lineHeight: '1.5',
                border: '0',
            },
            highlighter: {
                fontSize: '0.75rem',
                border: 0,
            },
            input: {
                padding: '10px',
                border: '0',
                borderRadius: '6px',
                fontSize: '0.75rem',
                fontWeight: '600',
                outline: 'none',
                color: '#323746',
                transition: 'border-color 0.2s, box-shadow 0.2s',
                '&:focus': {
                    borderColor: '#66afe9',
                    boxShadow: '0 0 5px rgba(102, 175, 233, 0.6)',
                },
            },
        },
        suggestions: {
            zIndex: '10',
            margin: '8px',
            list: {
                backgroundColor: 'white',
                border: '1px solid rgba(0, 0, 0, 0.15)',
                fontSize: '14px',
                borderRadius: '6px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                maxHeight: '200px',
                overflowY: 'auto',
            },
            item: {
                fontSize: '0.75rem',
                padding: '8px 14px',
                borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
                cursor: 'pointer',
                '&focused': {
                    backgroundColor: '#323746',
                    color: 'white',
                },
                '&:last-child': {
                    borderBottom: 'none',
                },
            },
        },
    };

    const formatDateTime = (dateTimeStr: string): string => {
        const date = new Date(dateTimeStr);

        if (!isNaN(date.getTime())) {
            const formattedDate = date.toLocaleDateString('en-GB');
            const formattedTime = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });

            return `${formattedDate} at ${formattedTime}`;
        }

        return dateTimeStr;
    };

    const addComment = useCallback(() => {
        if (text.length > 0) {
            const displayName = currentUser?.firstName && currentUser?.lastName
                ? `${currentUser.firstName} ${currentUser.lastName}`
                : 'N/A';

            const newComment: Mention = {
                commentedBy: { email: currentUser?.email as string, name: displayName },
                commentedAt: 'Just Now',
                comment: text,
            };
            setComments((prevComments: Mention[]) => [...prevComments, newComment]);
            setText('');
        }
    }, [text, currentUser]);

    const deleteComment = useCallback((index: number) => {
        setComments((prevComments: Mention[]) => prevComments.filter((_, i) => i !== index));
    }, []);

    const editComment = useCallback((index: number) => {
        setEditIndex(index);
        setEditText(comments[index].comment);
    }, [comments]);

    const saveEditComment = useCallback(() => {
        if (editIndex !== null) {
            setComments((prevComments: Mention[]) =>
                prevComments.map((comment, i) =>
                    i === editIndex ? { ...comment, comment: editText } : comment
                )
            );
            setEditIndex(null);
            setEditText('');
        };
    }, [editIndex, editText]);

    const discardEditComment = useCallback(() => {
        setEditIndex(null);
        setEditText('');
    }, []);

    const renderComment = (comment: Mention) => {
        const mentionRegex = /@\[(.+?)\]\(([^)]+)\)/g;
        const parts = comment.comment.split(mentionRegex);

        return parts.map((part, index) => {
            if (index % 3 === 1) {
                const email = parts[index + 1];
                const className = currentUser?.email === email ? 'user-mention' : 'mention';

                return (
                    <>
                        <span key={index} className={className}>
                            @{part}
                        </span>
                        {' '}
                    </>
                );
            }

            if (index % 3 === 0 && part !== '') {
                return (
                    <span key={index} className="plain-text">
                        {part.split(' ').map((text, i) => (
                            <Fragment key={i}>
                                {text}
                                {i < part.split(' ').length - 1 && '\u00A0'}
                            </Fragment>
                        ))}
                    </span>
                );
            }

            return null;
        }).filter(Boolean);
    };

    return (
        <div>
            <h3>{t('app.comments')}</h3>
            <div className="row mb-3">
                {comments?.map((comment, index) => (
                    <div key={index} className="col-12 mb-2">
                        <Card>
                            <Card.Body>
                                <div className="d-flex justify-content-start align-items-center mb-2">
                                    <span className="card-subtitle text-black">{comment?.commentedBy?.name}</span>
                                    <span className="card-subtitle text-muted mx-2">{formatDateTime(comment?.commentedAt)}</span>
                                </div>
                                {editIndex === index ? (
                                    <div className="d-flex align-items-center">
                                        <MentionsInput
                                            className="form-control me-2"
                                            value={editText}
                                            onChange={(e, newValue) => setEditText(newValue)}
                                            style={defaultInputStyle}
                                            placeholder="Edit your comment"
                                        >
                                            <Mentions
                                                trigger="@"
                                                data={users.map(user => ({ id: user.email, display: user.displayName }))}
                                                renderSuggestion={renderUserSuggestion}
                                            />
                                        </MentionsInput>
                                        <Button variant="success" className="me-2" onClick={saveEditComment}>
                                            Save
                                        </Button>
                                        <Button variant="secondary" onClick={discardEditComment}>
                                            Discard
                                        </Button>
                                    </div>
                                ) : (
                                    <div className="d-flex justify-content-between align-items-center">
                                        <p className="comment">{renderComment(comment)}</p>
                                        {comment?.commentedBy?.email === currentUser?.email && (
                                            <div className='d-none'>
                                                <Button variant="secondary" className="me-2" onClick={() => { }}>
                                                    Edit
                                                </Button>
                                                <Button variant="danger" onClick={() => { }}>
                                                    Delete
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </Card.Body>
                        </Card>
                    </div>
                ))}
            </div>
            <div className="input-group mb-3">
                <MentionsInput
                    className="form-control py-2"
                    value={text}
                    onChange={(e, newValue) => setText(newValue)}
                    style={defaultInputStyle}
                    placeholder={t("app.comments_placeholder")}
                    disabled={disabled}
                >
                    <Mentions
                        trigger="@"
                        data={users.map(user => ({ id: user.email, display: user.displayName }))}
                        displayTransform={(id, display) => `@${display} `}
                        renderSuggestion={renderUserSuggestion}
                    />
                </MentionsInput>
                <Button disabled={false} type="button" variant="light" onClick={addComment}>
                    {t('app.add')}
                </Button>
            </div>
        </div>
    );
};

export default _ModalMentionComments;
