import React, {FC} from 'react'
import {classUtils as c} from "Vendor/Utils/ClassUtils";

const Spinner: FC<{show?: boolean}> = ({show = true}) => (
    <div className={c('spinner-border spinner-grow-sm p-3', !show && 'd-none')} role="status">
        <span className="visually-hidden">Loading...</span>
    </div>
)

export default Spinner
