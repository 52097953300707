import React, {FC, FormEvent, useCallback, useContext, useEffect, useState} from 'react'
import {Button, Modal, ModalFooter as Footer} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'
import Column from "../../Entity/Collection/Import/Column";
import ChoiceType, {Choice} from "../../Vendor/Components/Form/Type/ChoiceType";
import {CollectionImporterContext} from "../../Vendor/Context/CollectionImporterContextProvider";
import Type from "../../Config/Collection/Import/Column/Type";
import EntityType, {EntityChoice} from "../../Vendor/Components/Form/Type/EntityType";
import useTranslationDataUtils from "../../Vendor/Utils/TranslationDataUtils";
import AddressFieldChoice from "../../Form/Choice/Collection/Import/Column/AddressFieldChoice";
import AddressField from 'Config/Collection/Import/Column/AddressField';
import MatchedByFieldChoice from "../../Form/Choice/Collection/Import/Column/MatchedByFieldChoice";
import MatchedByFieldNoPropertyChoice from "../../Form/Choice/Collection/Import/Column/MatchedByFieldNoPropertyChoice";
import MatchedBy from "../../Config/Collection/Import/Column/MatchedBy";
import Property from "../../Entity/Property";
import TextType from "../../Vendor/Components/Form/Type/TextType";
import MatchedByFieldCountryChoice from "../../Form/Choice/Collection/Import/Column/MatchedByFieldCountryChoice";
import {UserContext} from "../../Vendor/Context/UserContextProvider";
import Separator from "../../Config/Collection/Import/Column/Separator";
import SeparatorChoice from "../../Form/Choice/Collection/Import/Column/SeparatorChoice";
import Import from "../../Entity/Collection/Import";

interface _ColumnSubModalProps {
    show: boolean
    setShow: (show: boolean) => void
    id?: number
    currentImport?: Import
    setCurrentImport: (currentImport?: Import) => void
}

const _ColumnSubModal: FC<_ColumnSubModalProps> = ({
                                                         show,
                                                         setShow,
                                                         id,
                                                            currentImport,
                                                            setCurrentImport,
                                                     }) => {
    const {t} = useTranslation()
    const {translate} = useTranslationDataUtils()
    const { currentUser } = useContext(UserContext)
    const [column, setColumn] = useState<Column|undefined>(undefined)

    const handleSetColumn = (column: Column) => {
        let tempColumn = {...column}

        if (tempColumn.type === 'null'
            || (tempColumn.type === 'property' && !tempColumn.property)
            || (tempColumn.type === 'property' && tempColumn.property && tempColumn.property.type === "address" && !tempColumn.addressField)
        ) {
            tempColumn.state = 'wait'
        } else {
            tempColumn.state = 'set'
        }

        setColumn(tempColumn)
    }

    useEffect(() => {
        if (currentImport && show && id) {
            let tempColumn = currentImport.columns.find(c => c.id === id)
            if (tempColumn) {
                if (!tempColumn.type) {
                    tempColumn.type = 'null'
                }

                if (tempColumn.type === 'property' && tempColumn.property) {
                    tempColumn.property = {...tempColumn.property}
                }

                tempColumn = {...tempColumn}
            }
            setColumn(tempColumn ?? undefined)
        }
    }, [currentImport, id, setColumn, show])

    const getTypeChoices = useCallback((): Choice[] => {
        let choices: Choice[] = []

        if (currentImport && currentImport.form && currentImport.form.types) {
            choices = currentImport.form.types.map((type: string) => {
                return {
                    value: type,
                    i18nKey: 'app.' + type,
                }
            })
        }

        return choices
    }, [currentImport])

    const getPropertyChoices = useCallback((): EntityChoice[] => {
        let choices: EntityChoice[] = []

        if (currentImport && currentImport.form.types) {
            for (const property of currentImport.form.properties ?? []) {
                if (property.id) {
                    choices.push({
                        value: property.id,
                        i18nKey: (translate(property, 'label') ?? (t('app.property') + ' #' + property.id)) + ' (' + t('app.enum.property.type.' + property.type.charAt(0).toUpperCase() + property.type.slice(1)) + ')'
                    })
                }
            }
        }

        return choices
    }, [currentImport])

    const getBucketChoices = useCallback((): Choice[] => {
        let choices: Choice[] = []

        if (currentImport && currentImport.buckets) {
            for (const bucket of currentImport.buckets) {
                choices.push({
                    value: bucket.primarySlug,
                    i18nKey: translate(bucket, 'name')
                })
            }
        }

        return choices
    }, [currentImport])

    const getGroupChoices = useCallback((): Choice[] => {
        let choices: Choice[] = []

        if (currentImport && currentImport.form && currentImport.form.groups) {
            choices = currentImport.form.groups.map((group) => {
                return {
                    value: group.id?.toString() ?? '',
                    i18nKey: group.name,
                }
            })
        }

        return choices
    }, [currentImport])

    const getPropertyMatcherChoices = useCallback((): EntityChoice[] => {
        let choices: EntityChoice[] = []
        let properties: Property[] = []

        if (currentImport && currentImport.form && column) {
            if (column.type === 'bucket' && column.bucket && currentImport.buckets?.length) {
                properties = currentImport.buckets.find(b => b.slug === column.bucket)?.properties ?? [] as Property[]
            } else if (column.type === 'company') {
                properties = currentImport.form.companyProperties ?? [] as Property[]
            } else if (column.type === 'property' && column.property?.type === 'collection') {
                properties = currentImport.property.collectionProperties ?? [] as Property[]
            }
        }

        for (const property of properties) {
            if (property.id) {
                choices.push({
                    value: property.id,
                    i18nKey: (translate(property, 'label') ?? (t('app.property') + ' #' + property.id)) + ' (' + t('app.enum.property.type.' + property.type.charAt(0).toUpperCase() + property.type.slice(1)) + ')'
                })
            }
        }

        return choices
    }, [column, currentImport])

    const getMatchedByChoices = useCallback((): Choice[] => {
        if (column) {
            if (column.type && (
                ['group', 'mailingList'].includes(column.type)
                || ('company' === column.type
                    && (currentImport?.form.companyProperties?.length ?? 0) === 0)
            )) {
                return MatchedByFieldNoPropertyChoice;
            } else if (column.type === 'property' && column.property?.type === 'address' && column.addressField === 'country') {
                return MatchedByFieldCountryChoice;
            } else {
                return MatchedByFieldChoice;
            }
        }

        return []
    }, [column, currentImport])

    const getLanguagesChoices = useCallback((): Choice[] => {
        let choices: Choice[] = []

        currentUser?.currentOrganization?.languages.forEach((language: string) => {
            choices.push({
                value: language,
                i18nKey: 'app.lang.' + language,
            })
        })

        return choices;
    }, [currentUser])

    const handleValidate = (cancel: boolean = true) => {
        if (!cancel && column && currentImport) {
            let tempCurrentImport = {...currentImport}
            let tempColumn = {...column}
            tempCurrentImport.columns = tempCurrentImport.columns.map(c => {
                if (c.id === tempColumn.id) {
                    return tempColumn
                }

                return c
            })
            setCurrentImport(tempCurrentImport)
        }

        setShow(false)
    }

    return (
        <Modal
            show={show}
            onHide={handleValidate}
        >
            <Modal.Header closeButton>
                <Modal.Title>{column?.name ?? 'app.column'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {column && <>
                    <ChoiceType choices={getTypeChoices()} i18nLabel='app.type' value={column.type}
                        setValue={(type) => {
                            let tempColumn = {...column}
                            tempColumn.type = type as Type
                            handleSetColumn(tempColumn)
                        }}
                                i18nPlaceholder="app.select_type"
                    />
                    {column.type === 'bucket' &&
                        <ChoiceType
                            i18nLabel='app.bucket'
                            choices={getBucketChoices()}
                            value={column.bucket}
                            setValue={(bucketSlug) => {
                                let tempColumn = {...column}
                                if (typeof bucketSlug === "string") {
                                    tempColumn.bucket = bucketSlug
                                } else {
                                    tempColumn.bucket = undefined
                                }
                                handleSetColumn(tempColumn)
                            }}
                            required={true}
                            i18nPlaceholder="app.select_bucket"
                        />
                    }
                    {column.type === 'property' &&
                        <EntityType
                            i18nLabel='app.property'
                            choices={getPropertyChoices()}
                            value={column.property?.id?.toString() ?? ''}
                            setValue={(propertyId) => {
                                let tempColumn = {...column}
                                let id = typeof propertyId === "string" ? parseInt(propertyId, 10) : undefined
                                if (id) {
                                    let tempProperty = currentImport?.form.properties?.find(p => p.id === id)
                                    if (tempProperty) {
                                        tempColumn.property = {...tempProperty}
                                    } else {
                                        tempColumn.property = undefined
                                    }
                                } else {
                                    tempColumn.property = undefined
                                }
                                handleSetColumn(tempColumn)
                            }}
                            required={true}
                            i18nPlaceholder="app.select_property"
                        />
                    }
                    {(('bucket' === column.type) || ('mailingList' === column.type) || (column.type === 'property' && (column.property?.multiple || column.property?.externalIdentifier)) || (column.type === 'company' && currentUser?.currentOrganization?.setting.relationContactCompany === 'many')) &&
                        <ChoiceType choices={SeparatorChoice} i18nLabel='app.separator' value={column.separator}
                                    setValue={(separator) => {
                                        let tempColumn = {...column}
                                        tempColumn.separator = separator as Separator
                                        handleSetColumn(tempColumn)
                                    }}
                                    i18nPlaceholder="app.select_separator"
                        />
                    }
                    {column.type === 'property' && column.property && column.property.type === 'address' &&
                        <ChoiceType choices={AddressFieldChoice} i18nLabel='app.address_field' value={column.addressField}
                                    setValue={(addressField) => {
                                        let tempColumn = {...column}
                                        tempColumn.addressField = addressField as AddressField
                                        handleSetColumn(tempColumn)
                                    }}
                                    i18nPlaceholder="app.select_address_field"
                        />
                    }
                    {(
                        ['bucket', 'company', 'group', 'mailingList'].includes(column.type ?? '')
                            || (column.type === 'property'
                                && column.property
                                && column.property.type === 'collection'
                            )
                            || (column.type === 'property'
                                && column.property?.type === 'address'
                                && column.addressField === 'country'
                            )
                        ) &&
                        <ChoiceType choices={getMatchedByChoices()} i18nLabel='app.identifier' value={column.matchedBy}
                                    setValue={(matchedBy) => {
                                        let tempColumn = {...column}
                                        tempColumn.matchedBy = matchedBy as MatchedBy
                                        handleSetColumn(tempColumn)
                                    }}
                                    i18nPlaceholder="app.select_identifier"
                        />
                    }
                    {column.matchedBy === 'property' &&
                        <EntityType
                            i18nLabel='app.property'
                            choices={getPropertyMatcherChoices()}
                            value={column.propertyMatcher?.id?.toString() ?? ''}
                            setValue={(propertyId) => {
                                let tempColumn = {...column} as Column
                                let id = typeof propertyId === "string" ? parseInt(propertyId, 10) : undefined
                                let tempProperty = undefined
                                if (id) {
                                    if (tempColumn.bucket) {
                                        let bucket = currentImport?.buckets?.find(b => b.slug === tempColumn.bucket)
                                        if (bucket) {
                                            tempProperty = bucket.properties.find(p => p.id === id) as Property
                                        }
                                    }

                                    if (tempProperty) {
                                        tempColumn.propertyMatcher = {...tempProperty}
                                    } else {
                                        tempColumn.propertyMatcher = undefined
                                    }
                                } else {
                                    tempColumn.propertyMatcher = undefined
                                }
                                handleSetColumn(tempColumn)
                            }}
                            required={true}
                            i18nPlaceholder="app.select_property"
                        />
                    }
                    {(
                        (column.type === 'property'
                            && column.property
                            && (
                                column.property.translate
                                || (column.property.type === 'address'
                                    && column.addressField === 'country'
                                    && 'name' === column.matchedBy
                                )
                                || (
                                    column.property.type === 'phone'
                                )
                            )
                        )
                        || (
                            column.type === 'name'
                            && currentImport
                            && ['catalog', 'data-collection'].includes(currentImport.collection)
                        )) && <ChoiceType
                        choices={getLanguagesChoices()}
                        i18nLabel='app.language'
                        value={column.language}
                        setValue={(language) => {
                            let tempColumn = {...column}
                            tempColumn.language = language as string
                            handleSetColumn(tempColumn)
                        }}
                        i18nPlaceholder="app.select_language"
                    />}
                    {'property' === column.type && 'date' === column.property?.type &&
                        <TextType id="" name="" fullName="" value={column.formatDate ?? ''} setValue={value => {
                            let tempColumn = {...column}
                            tempColumn.formatDate = value
                            handleSetColumn(tempColumn)
                        }} />
                    }
                </>}
            </Modal.Body>
            <Footer>
                <Button variant="primary" onClick={() => {handleValidate(false)}}>{t('app.validate')}</Button>
            </Footer>
        </Modal>
    )
}

export default _ColumnSubModal
