import React, {FC, useContext, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import { UserContext } from 'Vendor/Context/UserContextProvider'
import {useTranslation} from "react-i18next";
import Header from "Components/Layout/Header";
import Table from "Components/Layout/Table";
import DataCollectionTable from "../../../Table/Admin/Edition/DataCollectionTable";
import DataCollection from "../../../Entity/DataCollection";
import FormRow from "../../../Vendor/Components/Form/FormRow";
import FormModal from "../../../Components/Modal/FormModal";
import DataCollectionForm from "../../../Form/Admin/Edition/DataCollectionForm";
import DeleteModal from "../../../Components/Modal/DeleteModal";
import FormError from "../../../Vendor/Components/Form/FormError";
import {Button} from "react-bootstrap";
import FormOffCanvas from "../../../Components/Modal/FormOffCanvas";
import FormEmptyOffCanvas from "../../../Components/Modal/FormEmptyOffCanvas";
import Api from "../../../Vendor/Api";
import LoadingButton from "../../../Components/Button/LoadingButton";

const DataCollectionPage: FC = () => {
    const { currentUser, setCurrentUser, setInit } = useContext(UserContext)
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [toggleReload, setToggleReload] = useState<boolean>(true)
    const [show, setShow] = useState<boolean>(false)
    const [keyword, setKeyword] = useState<string>('')
    const [search, setSearch] = useState<string>('')
    const [dataCollections, setDataCollections] = useState<DataCollection[]>([])
    const [dataCollection, setDataCollection] = useState<DataCollection>({} as DataCollection)
    const [reindexLoader, setReindexLoader] = useState<undefined|number>(undefined)
    const [reIndexSuccess, setReIndexSuccess] = useState<undefined|number>(undefined)

    const handleClose = (reload = false) => {
        setShow(false)
        if (reload) {
            setToggleReload(!toggleReload)
        }
    }
    const handleShow = () => setShow(true)
    // remove
    const [showDelete, setShowDelete] = useState<boolean>(false)
    const handleDeleteClose = (reload = false) => {
        setDataCollection({} as DataCollection)
        setShowDelete(false)
        if (reload) {
            setToggleReload(!toggleReload)
        }
    }
    const handleDeleteShow = () => setShowDelete(true)

    const onSuccess = (dataCollection: DataCollection) => {
        setDataCollection(dataCollection)
        setShow(false)
        setToggleReload(!toggleReload)
    }

    const addDataCollection = () => {
        setDataCollection({} as DataCollection)
        handleShow()
    }

    const editDataCollection = (dataCollection: DataCollection) => {
        navigate(dataCollection.id?.toString() ?? '', {state: dataCollection})
    }

    const reindexDataCollecion = (dataCollectionId: number) => {
        setReindexLoader(dataCollectionId)
        Api.patch('/admin/edition/data-collection/' + dataCollectionId + '/reindex')
            .then(() => {
                setReIndexSuccess(dataCollectionId)
            }).catch(() => {
        }).then(() => {
            setReindexLoader(undefined)
        })
    }

    const deleteDataCollection = (dataCollection: DataCollection) => {
        setDataCollection(dataCollection)
        handleDeleteShow()
    }

    const actionsRender = (dataCollection: DataCollection) => (
        <>
            {dataCollection?.isGranted?.edit &&
                <><Button
                variant='outline-primary'
                size='sm'
                onClick={() => editDataCollection(dataCollection)}>
                <i className="fa-solid fa-pencil me-2"></i>
                {t('app.edit')}</Button>
                    <LoadingButton variant='outline-info'
                                   isLoading={reindexLoader === dataCollection.id}
                                   success={reIndexSuccess === dataCollection.id}
                                   setSuccess={(success) => setReIndexSuccess(success ? dataCollection.id : undefined)}
                                   size='sm'
                                   onClick={() => {
                                       reindexDataCollecion(dataCollection.id ?? 0)
                                   }}>
                        <i className="fa-solid fa-cloud-word me-2"></i>
                        {t('app.reindex')}
                    </LoadingButton>
                </>
            }
            {dataCollection?.isGranted?.delete &&
            <Button
                variant='outline-danger'
                size='sm'
                onClick={() => deleteDataCollection(dataCollection)}>
                <i className="fa-solid fa-trash me-2"></i>
                {t('app.delete')}</Button>
            }
        </>
    );

    let table = DataCollectionTable;
    if (table.hasOwnProperty('actions')) {
        table.actions.render = actionsRender;
    }

    return (
        <>
            <Header title={t('app.data_collections')}
                    add={addDataCollection}
                    search={search}
                    setSearch={setSearch}
            />
            <Table
                path="/admin/edition/data-collection"
                rows={table}
                toggleReload={toggleReload}
                items={dataCollections}
                setItems={setDataCollections}
                keyword={search}
                setKeyword={setSearch}
            />
            <FormEmptyOffCanvas
                i18nTitle={dataCollection.id ? 'app.edit_data_collection' : 'app.add_data_collection'}
                show={show}
                handleClose={handleClose}
                onSuccess={onSuccess}
                path={`/admin/edition/data-collection/${dataCollection.id ?? 'new'}`}
                formPath={dataCollection.id ? true : false}
                method={dataCollection.id ? 'patch' : 'post'}
            />
            <DeleteModal
                handleClose={handleDeleteClose}
                show={showDelete}
                path={'/admin/edition/data-collection/' + (dataCollection?.id ?? 'error')}
                name={'#' + (dataCollection ? `${dataCollection.id} - ${dataCollection.translations}` : 'error')}
                archive={true}
            />
        </>
    )
}

export default DataCollectionPage
