import React, {FC} from 'react'
import useTranslationDataUtils from "Vendor/Utils/TranslationDataUtils";
import {useTranslation} from "react-i18next";
import {Dropdown} from "react-bootstrap";
import TableColumn from "../../../Entity/Collection/ListingSetting/View/TableColumn";
import {useState} from "react";

export interface _FilterColumnProps {
    columns: TableColumn[]
    visibleColumns: TableColumn[]
    setVisibleColumns: (column: TableColumn[]) => void
}

const _FilterColumn: FC<_FilterColumnProps> = ({columns, visibleColumns, setVisibleColumns}) => {
    const {t} = useTranslation()
    const {translate, translateField} = useTranslationDataUtils()

    const addOrRemoveSelectedColumn = (column: TableColumn) => {
        const index = visibleColumns.findIndex((item) => item.columnType === column.columnType && item.property?.id === column.property?.id);
        if (index > -1) {
            const newVisibleColumns = [...visibleColumns];
            newVisibleColumns.splice(index, 1);
            setVisibleColumns([...newVisibleColumns]);
        } else {
            visibleColumns.push(column);
            setVisibleColumns([...visibleColumns]);

            let orderedColumns : TableColumn[] = [];
            for (let itemColumn of columns) {
                if((visibleColumns.findIndex((item) => item.columnType === itemColumn.columnType)) > -1) {
                    orderedColumns = [...orderedColumns, itemColumn];
                }
            }
            setVisibleColumns([...orderedColumns]);
        }
    }

    return (
        <div className="col-auto mb-2">
            <Dropdown autoClose="outside">
                <Dropdown.Toggle variant="secondary">
                    {t('app.columns')}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {(columns && columns.length)
                        ? columns.map((column, key) => (
                            <Dropdown.Item key={key} onClick={() => addOrRemoveSelectedColumn(column)}
                                           active={visibleColumns.indexOf(column) !== -1}
                                           className="d-flex justify-content-between"
                            >
                                <span>{null === column.columnType ? translate(column.property ?? {}, 'label') : t(`app.${column.columnType}`)}</span>
                            </Dropdown.Item>
                        ))
                        : <Dropdown.Item key={0} disabled className="text-center">{t('app.no_result')}</Dropdown.Item>
                    }
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default _FilterColumn
