import React, { FC, useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import Product from "Entity/Product";
import { Button, Offcanvas } from "react-bootstrap";
import Timestamp from "../../Timestamp";
import FormEmpty, { FormResponseType } from "Vendor/Components/FormEmpty";
import _ProductEdit from "./_ProductEdit";
import LoadingButton from "../../Button/LoadingButton";
import useTranslationDataUtils from "Vendor/Utils/TranslationDataUtils";
import Catalog from "Entity/Catalog";
import File from "Entity/File";
import { UserContext } from "Vendor/Context/UserContextProvider";
import { BannerContext } from "Vendor/Context/BannerContextProvider";
import ChooseItemsModal from "Components/Modal/ChooseItemsModal";
import Api from "Vendor/Api";
import _ProductStickers from "./_ProductStickers";
import _ImportModalTags from "../../Modal/_ImportModalTags";
import Tag from "../../../Entity/Tag";
import { useKeyboardShortcut } from "Hooks/useKeyboardShortcut";
import { useListingContext } from "Hooks/useListingContext";
import _ModalComments from '../../Modal/_ModalMentionComments';
import MentionComment from 'Entity/Mention';

interface ProductOffCanvasProps {
    product: Product
    catalog: Catalog
    show: boolean
    handleClose: () => void
    onExited: () => void
    onSuccess?: (data: any) => void
    editAction: (product: Product) => void
    editNameAction: (product: Product) => void
    changeStateOnEdit?: (status: boolean) => void
}

const ProductOffCanvas: FC<ProductOffCanvasProps> = ({ product, catalog, show, handleClose, onExited, onSuccess, editAction, editNameAction, changeStateOnEdit }) => {
    const { t } = useTranslation()
    const { currentUser } = useContext(UserContext)
    const { setBanner } = useContext(BannerContext)
    const { translate } = useTranslationDataUtils()
    const [isFormLoading, setIsFormLoading] = useState<boolean>(false)
    const [isFormSuccess, setIsFormSuccess] = useState<boolean>(false)
    const [publishAction, setPublishAction] = useState<number | undefined>(undefined)
    const [unPublishAction, setUnPublishAction] = useState<number | undefined>(undefined)
    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const [path, setPath] = useState<string>('')
    const [delocateInitForm, setDelocateInitForm] = useState<boolean | FormResponseType>(true)
    const [showSelectFilesModal, setShowSelectFilesModal] = useState<undefined | string>(undefined)
    const [selectFilesModalTitle, setSelectFilesModalTitle] = useState<string>('')
    const [localProduct, setLocalProduct] = useState<Product>({ ...product })
    const [localTags, setLocalTags] = useState<Tag[]>([])
    const [localComments, setLocalComments] = useState<MentionComment[]>([])
    const [formFiles, setFormFiles] = useState<number[]>([])
    const [onChangeMainVisual, setOnChangeMainVisual] = useState<boolean>(false)
    const [canEdit, setCanEdit] = useState<boolean>(false)
    const [canDelete, setCanDelete] = useState<boolean>(false)
    const [hasPlatform, setHasPlatform] = useState<boolean>(currentUser?.currentOrganization?.platforms
        ? currentUser?.currentOrganization?.platforms?.length > 0
        : false)
    const [disableChangeMainVisual, setDisableChangeMainVisual] = useState<boolean>(false)
    const [canRestore, setCanRestore] = useState<boolean>(false)
    const mentionSlug = product?.mentionPropertyEnabled.slug

    const { items, currentPage, setCurrentPage, pages } = useListingContext()
    const [currentIndex, setCurrentIndex] = useState<number>(0)
    const itemsArray = Object.values(items)

    const prevItem = currentIndex > 0 ? itemsArray[currentIndex - 1] : null
    const nextItem = currentIndex < itemsArray.length - 1 ? itemsArray[currentIndex + 1] : null

    const isLeftEnabled = isLoaded && (prevItem || pages.includes(currentPage - 1))
    const isRightEnabled = isLoaded && (nextItem || pages.includes(currentPage + 1))

    const handleRightClick = () => {
        if (nextItem) {
            setLocalProduct({ ...nextItem })
            setPath(`/product/${nextItem.id}`)
            editAction(nextItem)
            setIsLoaded(false)
        } else {
            goToNextPage()
        }
    }

    const handleLeftClick = () => {
        if (prevItem) {
            setLocalProduct({ ...prevItem })
            setPath(`/product/${prevItem.id}`)
            editAction(prevItem)
            setIsLoaded(false)
        } else {
            goToPreviousPage()
        }
    }

    const goToNextPage = () => {
        if (pageExists(currentPage + 1)) {
            onExited()
            setCurrentPage(currentPage + 1)
        }
    }

    const goToPreviousPage = () => {
        if (pageExists(currentPage - 1)) {
            onExited()
            setCurrentPage(currentPage - 1)
        }
    }

    const pageExists = (page: number) => {
        return pages.includes(page)
    }

    useKeyboardShortcut({
        key: "ArrowLeft",
        onKeyPressed: () => handleLeftClick(),
        isEnabled: isLeftEnabled,
    })

    useKeyboardShortcut({
        key: "ArrowRight",
        onKeyPressed: () => handleRightClick(),
        isEnabled: isRightEnabled,
    })

    useEffect(() => {
        const index = itemsArray.findIndex((item) => item.id === localProduct.id);
        setCurrentIndex(index);
    }, [itemsArray, localProduct]);

    useEffect(() => {
        const isDeleted = product.deletedAt !== null && product.deletedAt !== undefined;
        const securities = catalog.listingSetting.securities;

        const setPermissions = (canModify: boolean, canDelete: boolean) => {
            setCanEdit(canModify);
            setCanDelete(canDelete);
        };

        if (currentUser && securities !== false && ((currentUser.administrator && catalog.listingSetting.securities === true)
            || (product.group === null || product.group === undefined))) {

            if (isDeleted) {
                setCanRestore(true);
                return
            }
            setPermissions(true, true)

            return;
        }

        if (typeof securities === 'object' && securities !== null && product.group !== null
            && product.group !== undefined
            && typeof product.group.id === 'number'
            && product.group.id > 0) {
            const groupSecurities = securities[product.group.id];
            if (groupSecurities) {
                setPermissions(groupSecurities.canModify, groupSecurities.canDelete);

                return;
            }
        }

        if (isDeleted) {
            setDisableChangeMainVisual(true);
        }

        setPermissions(false, false)
    }, [currentUser, product, catalog]);

    const setMainVisual = useCallback((file: File) => {
        if (!onChangeMainVisual) {
            let tempLocalProduct = { ...localProduct }
            let oldMainVisual = tempLocalProduct.mainVisual
            if (tempLocalProduct.mainVisual === file) {
                tempLocalProduct.mainVisual = undefined
            } else {
                tempLocalProduct.mainVisual = file
            }

            setLocalProduct(tempLocalProduct)
            setOnChangeMainVisual(true)
            Api.patch(`/product/${tempLocalProduct.id}/main-visual`, { file })
                .then((response) => {
                    tempLocalProduct.mainVisual = response.data.mainVisual
                    setLocalProduct(tempLocalProduct)
                })
                .catch(() => {
                    tempLocalProduct.mainVisual = oldMainVisual
                    setLocalProduct(tempLocalProduct)
                })
                .then(() => {
                    setOnChangeMainVisual(false)
                })
        }
    }, [localProduct, onChangeMainVisual])

    useEffect(() => {
        let title = ''
        if (showSelectFilesModal) {
            currentUser?.currentOrganization?.buckets.forEach(bucket => {
                if (bucket.primarySlug === showSelectFilesModal) {
                    title = translate(bucket, 'name')
                }
            })
        }

        setSelectFilesModalTitle(title)
    }, [currentUser, showSelectFilesModal, translate])

    useEffect(() => {
        setLocalProduct({ ...product })
        setLocalTags([...product.tags])
        if (Array.isArray(product[mentionSlug])) {
            setLocalComments([...product[mentionSlug]]);
        } else {
            //Fix for null value of mention
            setLocalComments([])
        }
    }, [product])

    useEffect(() => {
        let tempFormFiles: number[] = []
        if (typeof localProduct === "object" && Object.keys(localProduct).length) {
            for (const [bucketSlug, files] of Object.entries(localProduct.files)) {
                files.forEach(file => {
                    file.id && tempFormFiles.push(file.id)
                })
            }
        }
        setFormFiles(tempFormFiles)
    }, [localProduct])

    useEffect(() => {
        setPath(`/product/${localProduct.id}`)
    }, [localProduct])

    return <Offcanvas show={show} onHide={handleClose} placement="end" onExited={onExited} className="offcanvas-width-xl offcanvas-collection">
        <Offcanvas.Header closeButton>
            <div className="row justify-content-between align-items-center w-100">
                <div className="col-auto">
                    <h2 className="h3">
                        {translate(localProduct, 'name')}
                        {localProduct.group &&
                            <span>({translate(localProduct.group, 'name')})</span>
                        }
                        {canEdit &&
                            <Button variant="outline-primary" onClick={() => {
                                editNameAction(localProduct)
                            }}>
                                <i className="fa-solid fa-pencil"></i>
                            </Button>
                        }
                    </h2>
                    <Timestamp item={localProduct} blamable withTime />
                </div>
                <div className="col-auto">
                    <h3 className="m-0">V{localProduct.currentVersion}</h3>
                </div>
            </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
            <div className="controls">
                <Button onClick={handleLeftClick} className="left-button" disabled={!isLeftEnabled}>
                    <i className="fa-solid fa-chevron-left"></i>
                </Button>
                <Button onClick={handleRightClick} className="right-button" disabled={!isRightEnabled}>
                    <i className="fa-solid fa-chevron-right"></i>
                </Button>
            </div>
            <div className="row mb-3 justify-content-center align-items-center">
                <div className="col-auto">
                    <Button size="lg" variant="secondary" href={`https://api.${window.location.host.split(':')[0]}${process.env.REACT_APP_BACKEND_PORT ? ':' + process.env.REACT_APP_BACKEND_PORT : ''}/cdn/product/${product.id}/card`} target="_blank"><i className="fa-solid fa-external-link me-2"></i>{t('app.product_card')}</Button>
                </div>
            </div>
            <FormEmpty
                disabled={!canRestore && !canEdit}
                path={path}
                formPath={!!localProduct.id}
                method={localProduct.id ? 'patch' : 'post'}
                onSubmit={(e) => {
                    setIsFormLoading(true)
                }}
                formSubmit={{ className: 'footer' }}
                setIsLoaded={setIsLoaded}
                onSuccess={(data) => {
                    setIsFormSuccess(true)
                    setBanner({ type: 'success', i18nTitle: 'app.save', i18nMessage: 'app.data_saved_message' })
                    setLocalProduct(data as Product)
                    onSuccess && onSuccess(data)
                    changeStateOnEdit && changeStateOnEdit(true)
                }}
                onResponse={() => {
                    setIsFormLoading(false)
                }}
                prependForm={<_ProductEdit
                    position="prepend"
                    catalog={catalog}
                    product={localProduct}
                    setShowSelectFilesModal={setShowSelectFilesModal}
                    setFiles={(bucket: string, files: File[]) => {
                        let tempLocalProduct = { ...localProduct };
                        tempLocalProduct.files[bucket] = [...files];
                        setLocalProduct(tempLocalProduct);
                    }}
                    setMainVisual={setMainVisual}
                    onChangeMainVisual={onChangeMainVisual}
                    canEdit={canEdit}
                    disableChangeMainVisual={disableChangeMainVisual}
                />}
                appendForm={<div className="mb-3">
                    {currentUser?.currentOrganization?.options.includes('sticker')
                        && <_ProductStickers product={localProduct} setProduct={setLocalProduct} canEdit={canEdit} closeProduct={handleClose} />
                    }
                    <_ProductEdit
                        position="append"
                        catalog={catalog}
                        product={localProduct}
                        setShowSelectFilesModal={setShowSelectFilesModal}
                        setFiles={(bucket: string, files: File[]) => {
                            let tempLocalProduct = { ...localProduct }
                            tempLocalProduct.files[bucket] = [...files]
                            setLocalProduct(tempLocalProduct)
                        }}
                        setMainVisual={setMainVisual}
                        onChangeMainVisual={onChangeMainVisual}
                        canEdit={canEdit}
                        disableChangeMainVisual={disableChangeMainVisual}
                    />
                    {product && product.enableTags && <_ImportModalTags tags={localTags} setTags={setLocalTags} onLoad={!isLoaded || isFormLoading} headingLevel={3} disabled={!canEdit} />}
                    {product && product.mentionPropertyEnabled?.enabled && <_ModalComments comments={localComments} setComments={setLocalComments} entity={product} entityType='product' disabled={!canEdit} />}
                </div>
                }
                additionalData={canRestore ? {
                    restore: true,
                } : hasPlatform
                    ? {
                        tags: localTags,
                        files: formFiles,
                        publish: publishAction,
                        unPublish: unPublishAction,
                        ...(mentionSlug ? { [mentionSlug]: localComments.map(comment => comment.comment) } : [])
                    }
                    : {
                        files: formFiles,
                        tags: localTags,
                        ...(mentionSlug ? { [mentionSlug]: localComments.map(comment => comment.comment) } : [])
                    }
                }
            >
                <div className="row justify-content-center align-items-center mt-3">
                    {canEdit && <div className="col-auto">
                        {localProduct.isGranted?.publish && hasPlatform && <LoadingButton
                            size="lg"
                            variant="success"
                            className="me-3"
                            type="submit"
                            onClick={() => {
                                setPublishAction(1)
                                setUnPublishAction(undefined)
                                setIsFormSuccess(false)
                            }}
                            success={publishAction === 1 && isFormSuccess}
                            setSuccess={setIsFormSuccess}
                            isLoading={isFormLoading && publishAction === 1}
                            disabled={!isLoaded || isFormLoading}>
                            {t('app.publish')}
                        </LoadingButton>}
                        {localProduct.isGranted?.unPublish && hasPlatform && <LoadingButton
                            size="lg"
                            variant="danger"
                            className="me-3"
                            type="submit"
                            onClick={() => {
                                setPublishAction(undefined)
                                setUnPublishAction(1)
                                setIsFormSuccess(false)
                            }}
                            success={unPublishAction === 1 && isFormSuccess}
                            setSuccess={setIsFormSuccess}
                            isLoading={isFormLoading && unPublishAction === 1}
                            disabled={!isLoaded || isFormLoading}>
                            {t('app.unPublish')}
                        </LoadingButton>}
                        <LoadingButton
                            size="lg"
                            type="submit"
                            variant="tertiary"
                            isLoading={isFormLoading && publishAction !== 1 && unPublishAction !== 1}
                            disabled={!isLoaded || isFormLoading}
                            success={publishAction !== 1 && unPublishAction !== 1 && isFormSuccess}
                            setSuccess={setIsFormSuccess}
                            onClick={() => {
                                setPublishAction(undefined)
                                setUnPublishAction(undefined)
                                setIsFormSuccess(false)
                            }}
                        >
                            {t('app.save')}
                        </LoadingButton>
                    </div>}
                    {!canEdit && canRestore && <div className="col-auto">
                        <LoadingButton
                            size="lg"
                            type="submit"
                            variant="warning"
                            isLoading={isFormLoading}
                            disabled={!isLoaded || isFormLoading}
                            success={isFormSuccess}
                            setSuccess={setIsFormSuccess}
                            onClick={() => {
                                setIsFormSuccess(false)
                            }}
                        >
                            {t('app.restore')}
                        </LoadingButton>
                    </div>}
                </div>
            </FormEmpty>
            {catalog.buckets.length && showSelectFilesModal !== undefined ? <ChooseItemsModal
                show={true}
                setShow={(show) => {
                    setShowSelectFilesModal(undefined)
                }}
                selected={showSelectFilesModal && localProduct.files.hasOwnProperty(showSelectFilesModal) ? localProduct.files[showSelectFilesModal] : []}
                setSelected={(selected) => {
                    if (showSelectFilesModal) {
                        let tempLocalProduct = { ...localProduct }
                        tempLocalProduct.files[showSelectFilesModal] = selected as File[]
                        setLocalProduct(tempLocalProduct)
                    }
                }}
                title={selectFilesModalTitle}
                path={`/b/${showSelectFilesModal}`}
                entity="bucket"
                primarySlug={''}
            /> : ''}
        </Offcanvas.Body>
    </Offcanvas>
}

export default ProductOffCanvas
