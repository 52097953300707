import React, {FC, FormEvent, useCallback, useContext, useEffect, useState} from 'react'
import Shopping from "../../../Entity/Shopping";
import Api from "../../../Vendor/Api";
import {useTranslation} from "react-i18next";
import useTranslationDataUtils from "../../../Vendor/Utils/TranslationDataUtils";
import {classUtils as c} from "Vendor/Utils/ClassUtils";
import {UserContext} from "../../../Vendor/Context/UserContextProvider";
import {CollectionContext} from "../../../Vendor/Context/CollectionContextProvider";
import Product from "../../../Entity/Product";
import _Product from "./_Product";
import _ContactInfo from "./_ContactInfo";
import _CompanyInfo from "./_CompanyInfo";
import ChooseItemsModal from "../../Modal/ChooseItemsModal";
import Property from "../../../Entity/Property";
import LoadingButton from "../../Button/LoadingButton";
import {Button, Modal} from "react-bootstrap";
import NumberType from "../../../Vendor/Components/Form/Type/NumberType";
import EntityInterface from 'Vendor/Definition/EntityInterface';
import { ThumbnailModalProps } from 'Components/Listing/View/_CardGroup';
import CheckboxType from "../../../Vendor/Components/Form/Type/CheckboxType";

export type _ShowProps = {
    shopping?: Shopping
    setShopping: (shopping: Shopping) => void
    setInfoShow: (show: boolean) => void
    setThumbnailModalProps?: (thumbnailPath: ThumbnailModalProps) => void
    editProductAction?: (item: EntityInterface) => void
}

type DeliveryPaperStatus = 'send-request'|'requested'|'in_progress'|null|false|undefined

const _Show: FC<_ShowProps> = ({
    shopping,
    setShopping,
    setInfoShow,
    setThumbnailModalProps,
}) => {
    const {t, i18n} = useTranslation()
    const {currentUser} = useContext(UserContext)
    const {translate} = useTranslationDataUtils()
    const {getValue} = useContext(CollectionContext)
    const [showProductModal, setShowProductModal] = useState(false)
    const [productsModal, setProductsModal] = useState<Product[]>([])
    const [productsPath, setProductsPath] = useState<string|undefined>(undefined)
    const [properties, setProperties] = useState<Property[]>([])
    const [onDownloadDeliveryPaper, setOnDownloadDeliveryPaper] = useState<boolean>(false)
    const [showDeliveryPaperModal, setShowDeliveryPaperModal] = useState<boolean>(false)
    const [nbBags, setNbBags] = useState<number>(1)
    const [generateDeliveryPaperStatus, setGenerateDeliveryPaperStatus] = useState<DeliveryPaperStatus>(undefined)
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout>()
    const [notReturnedProducts, setNotReturnedProducts] = useState<boolean>(false)
    const [existNotReturnedShoppingProduct, setExistNotReturnedShoppingProduct] = useState<boolean>(false)

    useEffect(() => {
        if (shopping) {
            setProductsModal([...shopping.shoppingProducts.map((sp) => sp.product)])
            setProductsPath(`/shopping/${shopping.id}/products`)
            setExistNotReturnedShoppingProduct(shopping.shoppingProducts.some(product => product.status === "returned"))
        } else {
            setProductsModal([])
            setProductsPath(undefined)
            setExistNotReturnedShoppingProduct(false)
        }
    }, [shopping])

    useEffect(() => {
        if (shopping && currentUser?.currentOrganization) {
            if (shopping.type === 'pre-order') {
                setProperties([...currentUser?.currentOrganization?.preOrderProperties])
            } else {
                setProperties([...currentUser?.currentOrganization?.shoppingProperties])
            }
        } else {
            setProperties([])
        }
    }, [shopping?.type])

    const showShoppingPlatform = useCallback((): string => {
        let platform = ''

        if (!shopping) return ''

        if (!shopping?.platform) {
            return t('app.none_female')
        }

        currentUser?.currentOrganization?.platforms?.forEach((p) => {
            if (p.slug === shopping.platform) {
                platform = p.name
            }
        })

        return platform
    }, [currentUser?.currentOrganization?.platforms, shopping, t])

    const refreshModalList = () => {
        if (!shopping || !productsPath) return
        let oldIds = shopping.shoppingProducts.map((sp) => sp.product.id)
        let ids = productsModal.map((p) => p.id)

        if (JSON.stringify(oldIds) === JSON.stringify(ids)) return

        let newProducts = productsModal.filter((p) => !oldIds.includes(p.id))
        let deletedProducts = shopping.shoppingProducts.filter((sp) => !ids.includes(sp.product.id))

        Api.patch(productsPath, {
            add: newProducts.map((p) => p.id),
            delete: deletedProducts.map((sp) => sp.product.id),
        }).then(response => {
            setShopping(response.data as Shopping)
        })
    }

    useEffect(() => {
        setGenerateDeliveryPaperStatus(undefined)
        if (showDeliveryPaperModal) {
            Api.get(`/shopping/${shopping?.id}/delivery-paper/status`)
                .then((response) => {
                    setGenerateDeliveryPaperStatus(response.data.status as DeliveryPaperStatus)
                })
                .catch(() => {})
                .then(() => {})
        }
    }, [shopping?.id, showDeliveryPaperModal])

    useEffect(() => {
        if (null !== generateDeliveryPaperStatus && showDeliveryPaperModal) {
            const id = setInterval(() => {
                getStatus(shopping?.id ?? 0)
            }, 5000)
            setIntervalId(id)
        } else {
            intervalId && clearInterval(intervalId)
        }

        return () => {
            intervalId && clearInterval(intervalId)
        }
    }, [generateDeliveryPaperStatus, showDeliveryPaperModal, shopping?.id])

    const getStatus = (shoppingId: number) => {
        if (shoppingId > 0) {
            Api.get(`/shopping/${shoppingId}/delivery-paper/status`)
                .then((response) => {
                    setGenerateDeliveryPaperStatus(response.data.status as DeliveryPaperStatus)
                })
                .catch(() => {
                })
                .then(() => {
                })
        }
    }

    useEffect(() => {
        if (generateDeliveryPaperStatus === false) {
            sendRegenerate(shopping?.id ?? 0)
        }
    }, [generateDeliveryPaperStatus, shopping?.id])

    const sendRegenerate = (shoppingId: number) => {
        if (shoppingId > 0) {
            setGenerateDeliveryPaperStatus('send-request')
            Api.get(`/shopping/${shoppingId}/delivery-paper/regenerate`)
                .then((response) => {
                    setGenerateDeliveryPaperStatus(response.data.status as DeliveryPaperStatus)
                })
                .catch(() => {
                })
                .then(() => {
                })
        }
    }

    const [onChangeStatusAllProducts, setOnChangeStatusAllProducts] = useState<null|string>(null)

    const changeStatusAllProducts = (shoppingId: number, transition: string) => {
        setOnChangeStatusAllProducts(transition)
        Api.patch(`/shopping/${shoppingId}/${transition}-all-products`)
            .then((response) => {
                setShopping(response.data as Shopping)
            })
            .catch(() => {
            })
            .then(() => {
                setOnChangeStatusAllProducts(null)
            })
    }

    const handleDownloadDeliveryPaper = (e?: FormEvent<HTMLFormElement>) => {
        e?.preventDefault();

        let onSuccess = (response: any) => {
            let disposition = response.headers['content-disposition'];
            // get filename from content-disposition
            let filename = disposition?.split(';')[1].split('filename')[1].split('=')[1].trim().replace(/"/g, '');
            const href = URL.createObjectURL(response.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);

            setShowDeliveryPaperModal(false)
            setNotReturnedProducts(false)
        }
        setOnDownloadDeliveryPaper(true)
        let url = new URL(`/shopping/${shopping?.id}/delivery-paper`, window.location.origin);
        url.searchParams.append('nbBags', nbBags.toString())
        url.searchParams.append('notReturnedProducts', notReturnedProducts.toString())
        Api.get(url.href.slice(window.location.origin.length), {
            responseType: 'blob'
        })
            .then(onSuccess)
            .catch((error) => {
                setGenerateDeliveryPaperStatus(error.response.status)
            })
            .then(() => {
                setOnDownloadDeliveryPaper(false)
            })
    }

    return (
        <>
            <div className="sidebar">
                <div className="sidebar-header">
                    <h5>{t('app.informations')}</h5>
                    <button className={c("btn btn-primary", !shopping && 'placeholder')} disabled={!shopping || shopping.deletedAt} onClick={() => {
                        setInfoShow(true)
                    }}>
                        <i className="fas fa-pen-to-square"></i>
                    </button>
                </div>
                <div className="sidebar-content">
                    {shopping && <>
                        {shopping.customerType === 'contact' ?
                            <>
                                <_ContactInfo shopping={shopping} />
                                <_CompanyInfo shopping={shopping} />
                            </>
                            :
                            <>
                                <_CompanyInfo shopping={shopping} />
                                <_ContactInfo shopping={shopping} />
                            </>
                        }
                    </>}
                    { shopping && shopping.type !== 'pre-order' &&
                        <>
                            <h6>{t('app.designedDelivery')}</h6>
                            <p>{shopping ? (shopping.designedDelivery ? (new Date(shopping.designedDelivery)).toLocaleDateString(i18n.language, {
                                weekday: 'long',
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                            }) : <b className="text-danger"><i className="fas fa-alert"></i>{t('app.not_specified_date_alert')}</b>) : <span className="placeholder col-4"></span>}</p>
                            <h6>{t('app.plannedReturn')}</h6>
                            <p>{shopping ? (shopping.plannedReturn ? (new Date(shopping.plannedReturn)).toLocaleDateString(i18n.language, {
                                weekday: 'long',
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                            }) : <i className="text-muted">{t('app.not_specified')}</i>) : <span className="placeholder col-4"></span>}</p>
                        </>
                    }
                        
                    <h6>{t('app.deliveryAddress')}</h6>
                    {shopping
                        ? (
                            shopping.address ?
                                <>
                                    {shopping.address.row1 && <p>{shopping.address.row1}</p>}
                                    {shopping.address.row2 && <p>{shopping.address.row2}</p>}
                                    {(shopping.address.postalCode || shopping.address.city)
                                        && <p>{shopping.address.postalCode && (shopping.address.postalCode + (shopping.address.city && ' '))}{shopping.address.city}</p>
                                    }
                                    {shopping.address.country && <p>{((new Intl.DisplayNames(['en'], { type: 'region' })).of(shopping.address.country))}</p>}
                                </>
                                : <i className="text-muted">{t('app.not_specified')}</i>
                        )
                        : <>
                            <p><span className="placeholder col-6"></span></p>
                            <p>
                                <span className="placeholder col-2 me-1"></span>
                                <span className="placeholder col-3"></span>
                            </p>
                            <p><span className="placeholder col-5"></span></p>
                        </>
                    }
                    {(currentUser?.currentOrganization?.platforms.length ?? -1) > 0 && <>
                        <h6>{t('app.visible_on_platform')}</h6>
                        {shopping
                            ? <p>{currentUser?.currentOrganization?.platforms.length === 1
                                ? (shopping.platform ? t('app.yes') : t('app.no'))
                                : showShoppingPlatform()}</p>
                            : <p><span className={c('placeholder', currentUser?.currentOrganization?.platforms.length === 1 ? 'col-3' : 'col-6')}></span></p>}
                    </>}
                    {properties.map(property => <>
                        <h6>{translate(property, 'label')}</h6>
                        {shopping ? <p>{getValue(shopping, property, 'app.not_specified')}</p> : <p><span className="placeholder col-6"></span></p>}
                    </>)}
                </div>
            </div>
            <div className="content">
                <header>
                    <div className="infos">
                        <h5>{shopping ? t('app.nb_products', {count: shopping.productNumber ?? 0}) : <span className="placeholder col-3"></span>}</h5>
                        {shopping?.type === 'pre-order' && <><h6>· {shopping.price}</h6></>}
                    </div>
                    {!shopping?.deletedAt &&
                        <div className="actions">
                            {/*<button className={c("btn btn-light me-3", !shopping && 'placeholder')} disabled={!shopping}>
                                <i className="fas fa-arrow-down-to-line"></i>
                            </button>*/}
                            <Button className={c("btn btn-light me-3", !shopping && 'placeholder')} disabled={!shopping || shopping.productNumber < 1}
                                onClick={() =>{
                                    setNbBags(1)
                                    setShowDeliveryPaperModal(true)
                                }}
                            >
                                {t('app.delivery_paper')}
                            </Button>
                            {shopping?.status === "draft" && <LoadingButton isLoading={onChangeStatusAllProducts === 'validate'} className="btn btn-light me-3" onClick={() => {
                                changeStatusAllProducts(shopping?.id ?? 0, 'validate')
                            }}>{t('app.validate_all_products')}</LoadingButton>}
                            {shopping?.type !== 'pre-order' ? <>
                                {shopping?.status === "validated" && <LoadingButton isLoading={onChangeStatusAllProducts === 'output'} className="btn btn-light me-3" onClick={() => {
                                    changeStatusAllProducts(shopping?.id ?? 0, 'output')
                                }}>{t('app.output_all_products')}</LoadingButton>}
                            </> : null}
                            <Modal show={showDeliveryPaperModal} onHide={() => { setShowDeliveryPaperModal(false) }}>
                                <form onSubmit={handleDownloadDeliveryPaper}>
                                <Modal.Header closeButton>
                                    <Modal.Title>{t('app.delivery_paper')}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {generateDeliveryPaperStatus === undefined && <p>{t('app.delivery_paper_send_request')}...</p>}
                                    {generateDeliveryPaperStatus === 'requested' && <p>{t('app.delivery_paper_requested')}...</p>}
                                    {generateDeliveryPaperStatus === 'in_progress' && <p>{t('app.delivery_paper_in_progress')}...</p>}
                                    {generateDeliveryPaperStatus === 'send-request' && <p>{t('app.delivery_paper_send_request')}...</p>}
                                    {null === generateDeliveryPaperStatus ?
                                        <NumberType
                                            required
                                            fullName="delivery_paper[nbBags]"
                                            id="delivery_paper-nb_bags"
                                            name="nb_bags"
                                            value={nbBags.toString()}
                                            onChange={e => setNbBags(parseInt(e.target.value, 10))}
                                            i18nLabel="app.nb_bags_label"
                                            min={0}
                                        />
                                        : <LoadingButton type="button" variant="primary" isLoading={'send-request' === generateDeliveryPaperStatus} disabled={'send-request' === generateDeliveryPaperStatus} onClick={() => {
                                            getStatus(shopping?.id ?? 0)
                                        }}>
                                            {t('app.request_status')}
                                        </LoadingButton>
                                    }
                                    {existNotReturnedShoppingProduct &&
                                    <>
                                        <CheckboxType id="notReturnedProducts"
                                                      name="notReturnedProducts"
                                                      fullName="delivery_paper[notReturnedProducts]"
                                                      checked={notReturnedProducts}
                                                      setValue={setNotReturnedProducts}
                                                      checkedByDefault={false}
                                                      i18nLabel="app.display_only_not_returned"
                                                      required={false}
                                                      />
                                        {notReturnedProducts &&
                                            <div className="fst-italic fw-light text-danger">
                                                {t('app.delivery_paper_automatically_generated')}
                                            </div>
                                        }
                                    </>}
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => {
                                        setNbBags(1)
                                        setShowDeliveryPaperModal(false)
                                    }}>
                                        {t('app.cancel')}
                                    </Button>
                                    {!notReturnedProducts &&
                                    <LoadingButton type="button" variant="primary" isLoading={'send-request' === generateDeliveryPaperStatus} disabled={'send-request' === generateDeliveryPaperStatus} onClick={() => {
                                        sendRegenerate(shopping?.id ?? 0)
                                    }}>
                                        {t('app.request_regenerate')}
                                    </LoadingButton>
                                }
                                {null === generateDeliveryPaperStatus && <LoadingButton type="submit" variant="primary" isLoading={onDownloadDeliveryPaper}>
                                    {t('app.download')}
                                </LoadingButton>}
                                </Modal.Footer>
                                </form>
                            </Modal>
                            <button className={c("btn btn-primary", !shopping && 'placeholder')} disabled={!shopping} onClick={() => {
                                setShowProductModal(true)
                            }}>
                                <i className="fas fa-circle-plus fa-lg me-2"></i>
                                {t('app.add')}
                            </button>
                            {shopping && <ChooseItemsModal
                                show={showProductModal}
                                setShow={setShowProductModal}
                                selected={productsModal}
                                setSelected={(selected) => {
                                    setProductsModal([...selected as Product[]])
                                }}
                                title={t(`app.${shopping.type ?? 'shopping'}`) + ' #' + shopping.id}
                                path={productsPath ?? ''}
                                entity="catalog"
                                refreshModalList={refreshModalList}
                            />}
                        </div>
                    }
                </header>
                <main>
                    {shopping?.shoppingProducts?.map((shoppingProduct) =>
                        <_Product
                            shoppingId={shopping.id}
                            shoppingProduct={shoppingProduct}
                            shopping={shopping}
                            setShopping={setShopping}
                            currency={shopping.currency}
                            type={shopping?.type}
                            defaultOpen={shopping.shoppingProducts.length <= 10}
                            setThumbnailModalProps={setThumbnailModalProps}
                            disabled={shopping?.deletedAt}
                        />
                    )}
                </main>
            </div>
        </>
    )
}

export default _Show
