import React, {FC, useEffect, useState} from 'react'
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import DropdownSelect, {OptionProps} from "../../Button/DropdownSelect";

export type RequestLineProps = {
    filtered: boolean
    isLoaded: boolean
    maxResult: number
    pages: number[]
    currentPage: number
    setCurrentPage: (currentPage: number) => void
    nbEntries: number
    setNbEntries: (nbEntries: number) => void
    clearFilters: () => void
}

export const RequestLine: FC<RequestLineProps> = ({filtered, isLoaded, maxResult, pages, currentPage, setCurrentPage, nbEntries, setNbEntries, clearFilters,}) => {
    const { t } = useTranslation()
    const [start, setStart] = useState<number>(0)
    const [end, setEnd] = useState<number>(0)

    useEffect(() => {
        setStart((nbEntries * (currentPage - 1)) + 1)
    }, [currentPage, nbEntries, maxResult])

    useEffect(() => {
        if ((start + nbEntries) > maxResult) {
            setEnd(maxResult)
        }
        else {
            setEnd( start + nbEntries - 1 )
        }
    }, [maxResult, nbEntries, start])


    let options: OptionProps[] = [
        {
            label: '10',
            value: 10,
        },
        {
            label: '25',
            value: 25,
        },
        {
            label: '50',
            value: 50,
        },
        {
            label: '100',
            value: 100,
        },
    ]

    return (
        <div className="row justify-content-between mb-3">
            <div className="col-auto"></div>
            <div className="col-auto">
                <div className="row justify-content-end align-items-center">
                    <div className="col-auto">
                        <DropdownSelect variant="light" i18nLabel="app.table.show_entries" options={options} selected={nbEntries} setSelected={(selected) => {if (typeof selected === 'number') {setNbEntries(selected)}}} />
                    </div>
                    <div className="col-auto">
                        <span id="result-infos">{isLoaded ? maxResult ? ((start < end ? start + ' à ' + end : start) + ' sur ' + maxResult) : t('app.no_result') : ''}</span>
                    </div>
                    <div className="col-auto">
                        <Button variant="light" disabled={currentPage === 1 || !pages.length} onClick={() => {
                            currentPage > 1 && setCurrentPage(currentPage - 1)
                        }}><i className="fa-solid fa-angle-left"></i></Button>
                        <Button variant="light" disabled={currentPage === pages.length || !pages.length} onClick={() => {
                            currentPage < pages.length && setCurrentPage(currentPage + 1)
                        }}><i className="fa-solid fa-angle-right"></i></Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
