import React, {FC, useContext, useEffect, useState} from 'react'
import Listing, {CustomAction} from "Components/Listing/Listing";
import Header from "../Components/Layout/Header";
import {useTranslation} from "react-i18next";
import ListingSetting from "../Entity/Collection/ListingSetting";
import DeleteModal from "../Components/Modal/DeleteModal";
import Api from "../Vendor/Api";
import EntityInterface from "../Vendor/Definition/EntityInterface";
import Shopping from "../Entity/Shopping";
import {UserContext} from "../Vendor/Context/UserContextProvider";
import ShoppingOffCanvas, {ShoppingCurrentMenu} from "../Components/Offcanvas/Shopping/ShoppingOffCanvas";
import FormEmptyModal from "../Components/Modal/FormEmptyModal";
import {useNavigate, useParams} from "react-router-dom";

export interface ShoppingProps {
}

const PreOrderPage: FC<ShoppingProps> = () => {
    const {t} = useTranslation()
    const { currentUser } = useContext(UserContext)
    const [toggleReload, setToggleReload] = useState<string>('')
    const [search, setSearch] = useState<string>('')
    const [show, setShow] = useState<boolean>(false)
    const [setting, setSetting] = useState<ListingSetting|undefined>(undefined)
    const [shopping, setShopping] = useState<undefined|Shopping>(undefined)
    const [currentMenu, setCurrentMenu] = useState<ShoppingCurrentMenu>('show')
    const [editShow, setEditShow] = useState<boolean>(false)
    const params = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if (params.id) {
            Api.get('/shopping/' + params.id)
                .then((response) => {
                setShopping(response.data as Shopping)
                    handleEditShow()
            })
        } else {
            setShopping(undefined)
            setEditShow(false)
            navigate('/pre-order')
        }
    }, [navigate, params.id])

    useEffect(() => {
        setSetting({...currentUser?.currentOrganization?.preOrderListingSetting, shoppingType: 'pre-order'} as ListingSetting)
    }, [currentUser])

    const handleShow = () => {
        setShopping(undefined)
        setShow(true)
    }
    const handleEditShow = () => setEditShow(true)
    // remove
    const [showDelete, setShowDelete] = useState<boolean>(false)
    const handleDeleteClose = (reload = false) => {
        setShopping(undefined)
        setShowDelete(false)
        if (reload) {
            setToggleReload(new Date().toISOString())
        }
    }

    const handleDeleteShow = () => setShowDelete(true)

    const onSuccess = (shopping: Shopping) => {
        setShopping(undefined)
        setShopping({...shopping})
        setShow(false)
        setEditShow(true)
        setToggleReload(new Date().toISOString())
    }

    const onExited = () => {
        setShopping(undefined)
    }

    const editShopping = (shopping: EntityInterface) => {
        setShopping({...shopping as Shopping})
        handleEditShow()
    }

    const deleteShopping = (shopping: EntityInterface) => {
        setShopping({...shopping as Shopping})
        handleDeleteShow()
    }

    const handleReindex = (shopping: Shopping) => {
        setReindexLoading(true)
        Api.get(`/shopping/${shopping.id}/reindex`)
            .then(() => {
                setReindexSuccess(true)
            })
            .catch(() => {})
            .then(() => {
                setReindexLoading(false)
            })
    }

    const baseCustomActions: CustomAction[] = []
    const [customActions, setCustomActions] = useState<CustomAction[]>([])
    const [reindexLoading, setReindexLoading] = useState<boolean>(false)
    const [reindexSuccess, setReindexSuccess] = useState<boolean>(false)

    useEffect(() => {
        if (currentUser?.previousAdmin) {
            let customActionsTemp: CustomAction[] = [...baseCustomActions]
            customActionsTemp.push({
                method: handleReindex,
                i18nLabel: 'app.reindex',
                variant: 'info',
                isLoading: reindexLoading,
                setSuccess: setReindexSuccess,
                success: reindexSuccess,
            })

            setCustomActions(customActionsTemp)
        }
    }, [currentUser?.previousAdmin, reindexLoading, reindexSuccess])

    if (!setting) return <></>

    return <>
        <Header
            title={t('app.pre_order')}
            search={search}
            setSearch={setSearch}
            add={handleShow}
        />
        <Listing
            path="/shopping/pre-order"
            search={search}
            setSearch={setSearch}
            setting={setting}
            entity='pre-order'
            selectable={true}
            toggleReload={toggleReload}
            setToggleReload={setToggleReload}
            editAction={editShopping}
            editUri='/pre-order'
            deleteAction={deleteShopping}
            customActions={customActions}
            initialShown={25}
        />
        <ShoppingOffCanvas
            show={editShow}
            handleClose={() => {
                setEditShow(false)
                if (params.id) {
                    navigate('/pre-order')
                }
            }}
            shopping={shopping}
            setShopping={setShopping}
            currentMenu={currentMenu}
            setCurrentMenu={setCurrentMenu}
            setInfoShow={setShow}
        />
        <FormEmptyModal
            path={"/shopping/" + (shopping?.id ?? 'pre-order/new')}
            method={shopping?.id ? 'PATCH' : 'POST'}
            formPath={!!shopping?.id}
            i18nTitle={shopping?.id ? 'app.edit' : 'app.add_pre_order'}
            show={show}
            handleClose={() => setShow(false)}
            onSuccess={onSuccess}
        />
        <DeleteModal
            handleClose={handleDeleteClose}
            show={showDelete}
            path={'/shopping/' + (shopping?.id ?? 'error')}
            name={'#' + (shopping ? `${shopping.id}` : 'error')}
            archive={true}
        />
    </>
}

export default PreOrderPage
