import React, {FC, useContext, useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import Table from "Components/Layout/Table";
import {Button} from "react-bootstrap";
import DownloadConfigTable from "Table/Admin/Edition/Platform/DownloadConfigTable";
import FormEmptyOffCanvas from "Components/Modal/FormEmptyOffCanvas";
import DeleteModal from "../../../../Components/Modal/DeleteModal";
import useTranslationDataUtils from "Vendor/Utils/TranslationDataUtils";
import {useOutletContext} from "react-router-dom";
import Platform from "Entity/Platform";
import DownloadConfig from 'Entity/Platform/DownloadConfig';

const DownloadConfigPage: FC = () => {
    const [platform, setPlatform, show, setShow, search, setSearch, setHandleAdd] = useOutletContext<[Platform, (platform: Platform) => void, boolean, (show:boolean) => void, string, (search:string) => void, (handleAdd: (() => void)|undefined) => void]>()

    const {translate} = useTranslationDataUtils()
    const { t } = useTranslation()
    const [downloadConfigs, setDownloadConfigs] = useState<DownloadConfig[]>([])
    const [downloadConfig, setDownloadConfig] = useState<DownloadConfig | undefined>(undefined)
    const [showDelete, setShowDelete] = useState<boolean>(false)

    const onSuccess = () => {
        setShow(false)
    }
  
    const handleClose = (reload = false) => {
        setShow(false)
    }
    const handleShow = () => {
        setShow(true)
    }

    const handleDeleteClose = (reload = false) => {
        setDownloadConfig(undefined)
        setShowDelete(false)
    }
    const handleDeleteShow = () => {
      setShowDelete(true)
    }

    const editEntity = (entity: DownloadConfig) => {
        setDownloadConfig(entity)
        handleShow()
    }

    const deleteEntity = (entity: DownloadConfig) => {
        setDownloadConfig(entity)
        handleDeleteShow()
    }

    const actionsRender = (entity: DownloadConfig) => (
        <>
            <Button
                variant='outline-primary'
                size='sm'
                onClick={() => editEntity(entity)}>
                <i className="fa-solid fa-pencil me-2"></i>
                {t('app.edit')}</Button>
            <Button
                variant='outline-danger'
                size='sm'
                onClick={() => deleteEntity(entity)}>
                <i className="fa-solid fa-trash me-2"></i>
                {t('app.delete')}</Button>
        </>
    );

    let table = DownloadConfigTable;
    if (table.hasOwnProperty('actions')) {
        table.actions.render = actionsRender;
    }

    return (
      <>
        <Table
          path={`/admin/edition/platform/${platform.id}/download-config`}
          rows={table}
          items={downloadConfigs}
          setItems={setDownloadConfigs}
          keyword={search}
          setKeyword={setSearch}
        />
        <FormEmptyOffCanvas
          i18nTitle={downloadConfig ? 'app.platform_download_config.edit' : 'app.platform_download_config.add'}
          show={show}
          handleClose={handleClose}
          onSuccess={onSuccess}
          path={`/admin/edition/platform/${platform.id}/download-config/${downloadConfig?.id ?? 'new'}`}
          formPath={!!downloadConfig}
          method={downloadConfig ? 'patch' : 'post'}
        />
        <DeleteModal
          title={t('app.platform_download_config.delete')}
          handleClose={handleDeleteClose}
          show={showDelete}
          path={`/admin/edition/platform/${platform.id}/download-config/${downloadConfig?.id ?? 'error'}`}
          name={'#' + (downloadConfig ? `${downloadConfig.id} - ${translate(downloadConfig, 'label')}` : 'error')}
        />
      </>
    )
}

export default DownloadConfigPage
