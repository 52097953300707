import {Choice} from "Vendor/Components/Form/Type/ChoiceType";

const MatchedByFieldCountryChoice: Choice[] = [
    {
        i18nKey: 'app.enum.collection.import.column.matched_by_field.alpha2',
        value: 'alpha2'
    },
    {
        i18nKey: 'app.enum.collection.import.column.matched_by_field.alpha3',
        value: 'alpha3'
    },
    {
        i18nKey: 'app.enum.collection.import.column.matched_by_field.name',
        value: 'name'
    },
]

export default MatchedByFieldCountryChoice
