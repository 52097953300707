import {RouteObject} from "react-router-dom";
import React from "react";
import GroupPage from "Pages/Admin/Edition/Property/GroupPage";
import PropertyPage from "Pages/Admin/Edition/PropertyPage";

const PropertyRouting: RouteObject[] = [
    {
        index: true,
        element: <PropertyPage />,
    },
    {
        path: 'group',
        element: <GroupPage />,
    },
]

export default PropertyRouting