import React, {FC, useContext} from 'react'
import {Link, useLocation, useNavigate} from "react-router-dom";
import {classUtils as c} from "Vendor/Utils/ClassUtils";
import {UserContext} from "Vendor/Context/UserContextProvider";
import Api from "Vendor/Api";

const _HeaderEdition: FC = () => {
    const { currentUser, setCurrentUser, setInit } = useContext(UserContext)
    const currentLocation = useLocation()
    const navigate = useNavigate()

    const handleClear = () => {
        setInit(false)
        Api.get('/admin/edition/clear')
            .then(({ data }) => {
                if (currentUser) {
                    currentUser.currentOrganization = undefined
                    currentUser.access = 'internal'
                    setCurrentUser(currentUser)
                    navigate('/admin/organization')
                }
            })
            .catch((err) => {
            })
            .then(() => {
                setInit(true)
            })
    }

    const organizationLogo = (currentUser?.currentOrganization?.logo)

    return (
        <div className="organization">
            <Link to="/">
                {(organizationLogo !== null) &&
                    <div className="icon">
                        <div
                            className="logo"
                            style={{
                                backgroundImage: "url('"+ `${organizationLogo}` + "')",
                            }}
                        />
                    </div>
                }
            </Link>
            <div className="data">
                <span className="name">{currentUser?.currentOrganization && ('#' + currentUser.currentOrganization.id + ' - ' + currentUser.currentOrganization.name)}</span>
                <div className="links">
                    <>
                        <Link to="#" onClick={handleClear}>
                            <i className="fa-solid fa-building-circle-xmark"></i>
                        </Link>
                        <Link to="/admin/edition/setting" className={c('settings', currentLocation.pathname.slice(0, 22) === '/admin/edition/setting') && 'active'}>
                            <i className="fa-solid fa-gear"></i>
                        </Link>
                    </>
                </div>
            </div>
        </div>
    )
}

export default _HeaderEdition
