import React, {FC, useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import Table from "Components/Layout/Table";
import FormEmptyOffCanvas from "Components/Modal/FormEmptyOffCanvas";
import DeleteModal from "Components/Modal/DeleteModal";
import ExportConfigTable from "Table/Admin/Edition/ExportConfigTable";
import {Button} from "react-bootstrap";
import ExportConfig from "Entity/ExportConfig";
import Collection from "Config/Collection";
import useTranslationDataUtils from "Vendor/Utils/TranslationDataUtils";
import {useOutletContext} from "react-router-dom";

const ExportConfigPage: FC<{collection: Collection}> = ({collection}) => {
    const { t } = useTranslation()
    const {translate} = useTranslationDataUtils()
    const [toggleReload, setToggleReload] = useState<string>('')
    const [exportConfigs, setExportConfigs] = useState<ExportConfig[]>([])
    const [exportConfig, setExportConfig] = useState<ExportConfig|undefined>(undefined)
    const [show, setShow, search, setSearch, setShowAddButton, setShowSearch, id] = useOutletContext<[boolean, (show:boolean) => void, string, (search:string) => void, (show: boolean) => void, (show: boolean) => void, number|undefined]>()
    const handleShow = () => setShow(true)

    useEffect(() => {
        setShowAddButton(true)
        setShowSearch(true)

        return () => {
            setShowAddButton(false)
            setShowSearch(false)
        }
    }, [setShowAddButton, setShowSearch])

    const handleClose = (reload = false) => {
        setShow(false)
        setExportConfig(undefined)
        if (reload) {
            setToggleReload(new Date().toISOString())
        }
    }
    // remove
    const [showDelete, setShowDelete] = useState<boolean>(false)
    const handleDeleteClose = (reload = false) => {
        setExportConfig(undefined)
        setShowDelete(false)
        if (reload) {
            setToggleReload(new Date().toISOString())
        }
    }
    const handleDeleteShow = () => setShowDelete(true)

    const onSuccess = () => {
        setExportConfig(undefined)
        setShow(false)
        setToggleReload(new Date().toISOString())
    }

    const editExportConfig = (exportConfig: ExportConfig) => {
        setExportConfig(exportConfig)
        handleShow()
    }

    const deleteExportConfig = (exportConfig: ExportConfig) => {
        setExportConfig(exportConfig)
        handleDeleteShow()
    }

    const actionsRender = (exportConfig: ExportConfig) => (
        <>
            <Button
                variant='outline-primary'
                size='sm'
                onClick={() => editExportConfig(exportConfig)}>
                <i className="fa-solid fa-pencil me-2"></i>
                {t('app.edit')}</Button>
            <Button
                variant='outline-danger'
                size='sm'
                onClick={() => deleteExportConfig(exportConfig)}>
                <i className="fa-solid fa-trash me-2"></i>
                {t('app.delete')}</Button>
        </>
    );

    let table = ExportConfigTable;
    if (table.hasOwnProperty('actions')) {
        table.actions.render = actionsRender;
    }

    return <>
        <Table
            path={`/admin/edition/collection/export-config/${collection}/${id ?? '0'}`}
            rows={table}
            toggleReload={toggleReload}
            items={exportConfigs}
            setItems={setExportConfigs}
            keyword={search}
            setKeyword={setSearch}
        />
        <FormEmptyOffCanvas
            i18nTitle={exportConfig ? 'app.edit_export_config' : 'app.add_export_config'}
            show={show}
            handleClose={handleClose}
            onSuccess={onSuccess}
            path={`/admin/edition/collection/export-config/${collection}/${id ?? '0'}/${exportConfig?.id ?? 'new'}`}
            method={exportConfig ? 'patch' : 'post'}
        />
        <DeleteModal
            handleClose={handleDeleteClose}
            show={showDelete}
            path={`/admin/edition/collection/export-config/${collection}/${id ?? '0'}/${exportConfig?.id ?? 'error'}`}
            name={translate(exportConfig ?? {}, 'name')}
            archive={true}
        />
    </>
}

export default ExportConfigPage
