import { RouteObject } from "react-router-dom";
import React from "react";
import BrandRouting from "./Edition/BrandRouting";
import BucketRouting from "./Edition/BucketRouting";
import BrandPage from "../Pages/Admin/Edition/BrandPage";
import BucketPage from "../Pages/Admin/Edition/BucketPage";
import CatalogRouting from "./Edition/CatalogRouting";
import CatalogPage from "../Pages/Admin/Edition/CatalogPage";
import DataCollectionRouting from "./Edition/DataCollectionRouting";
import DataCollectionPage from "../Pages/Admin/Edition/DataCollectionPage";
import PlatformPage from "../Pages/Admin/Edition/PlatformPage";
import BucketNavbar from "../Pages/Admin/Edition/Bucket/Navbar";
import CatalogNavbar from "../Pages/Admin/Edition/Catalog/Navbar";
import DataCollectionNavbar from "../Pages/Admin/Edition/DataCollection/Navbar";
import BrandNavbar from "../Pages/Admin/Edition/Brand/Navbar";
import PropertyNavbar from "../Pages/Admin/Edition/Property/Navbar";
import PlatformNavbar from "../Pages/Admin/Edition/Platform/Navbar";
import PlatformSecurityNavbar from "../Pages/Admin/Edition/PlatformSecurity/Navbar";
import PropertyRouting from "./Edition/PropertyRouting";
import UserNavbar from "../Pages/Admin/Edition/User/Navbar";
import UserRouting from "./Edition/UserRouting";
import SettingNavbar from "../Pages/Admin/Edition/Setting/Navbar";
import SettingRouting from "./Edition/SettingRouting";
import PlatformRouting from "./Edition/PlatformRouting";
import ItemGroupPage from "../Pages/Admin/Edition/DataCollection/ItemGroupPage";
import Navbar from "../Pages/Admin/Edition/Security/CRM/Navbar";
import CrmGroupsRouting from "./Edition/CrmGroupsRouting";
import SecurityPage from "../Pages/Admin/Edition/Security/SecurityPage";
import MailingIdentityNavBar from "Pages/Admin/Edition/MailingIdentity/_Navbar";
import MailingIdentityRouting from "./Edition/MailingIdentityRouting";
import NotificationCollectionPage from "Pages/Admin/Edition/Notification/NotificationCollectionPage";
import NotificationShoppingPage from "Pages/Admin/Edition/Notification/NotificationShoppingPage";
import NotificationPlatformPage from "Pages/Admin/Edition/Notification/NotificationPlatformPage";
import ReportingPage from "Pages/Admin/Edition/ReportingPage";
import PlatformSecurityPage from "Pages/Admin/Edition/PlatformSecurityPage";
import PlatformSecurityRouting from "./Edition/PlatformSecurityRouting";
import NotificationMentionPage from "Pages/Admin/Edition/Notification/NotificationMentionPage";

const EditionRouting: RouteObject[] = [
    {
        index: true,
        element: <></>,
    },
    {
        path: 'brand/:id',
        element: <BrandNavbar />,//BrandNavBar
        children: BrandRouting
    },
    {
        path: 'brand',
        element: <BrandPage />,
    },
    {
        path: 'bucket/:id',
        element: <BucketNavbar />,
        children: BucketRouting
    },
    {
        path: 'bucket',
        element: <BucketPage />,
    },
    {
        path: 'catalog/:id',
        element: <CatalogNavbar />,
        children: CatalogRouting
    },
    {
        path: 'catalog',
        element: <CatalogPage />,
    },
    {
        path: 'data-collection/:id',
        element: <DataCollectionNavbar />,
        children: DataCollectionRouting
    },
    {
        path: 'data-collection',
        element: <DataCollectionPage />,
    },
    {
        path: 'platform',
        element: <PlatformPage />,
    },
    {
        path: 'platform/:id',
        element: <PlatformNavbar />,
        children: PlatformRouting
    },
    {
        path: 'property',
        element: <PropertyNavbar />,
        children: PropertyRouting
    },
    {
        path: 'user',
        element: <UserNavbar />,
        children: UserRouting
    },
    {
        path: 'setting',
        element: <SettingNavbar />,
        children: SettingRouting
    },
    {
        path: 'item-group',
        element: <ItemGroupPage />,
    },
    {
        path: 'reporting',
        element: <ReportingPage />,
    },
    {
        path: 'crm',
        element: <Navbar />,
        children: CrmGroupsRouting
    },
    {
        path: 'security',
        element: <SecurityPage />,
    },
    {
        path: 'security-rule',
        element: <PlatformSecurityPage />,
    },
    {
        path: 'security-rule/:id',
        element: <PlatformSecurityNavbar />,
        children: PlatformSecurityRouting
    },
    {
        path: 'notification/collection',
        element: <NotificationCollectionPage />,
    },
    {
        path: 'notification/shopping',
        element: <NotificationShoppingPage />,
    },
    {
        path: 'notification/platform',
        element: <NotificationPlatformPage />,
    },
    {
        path: 'notification/mention',
        element: <NotificationMentionPage />,
    },
    {
        path: 'mailing-identity',
        element: <MailingIdentityNavBar />,
        children: MailingIdentityRouting
    },
]

export default EditionRouting
