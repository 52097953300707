import React, {FC, useContext, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import { UserContext } from 'Vendor/Context/UserContextProvider'
import {useTranslation} from "react-i18next";
import Header from "Components/Layout/Header";
import Table from "Components/Layout/Table";
import PlatformTable from "../../../Table/Admin/Edition/PlatformTable";
import Platform from "../../../Entity/Platform";
import FormRow from "../../../Vendor/Components/Form/FormRow";
import PlatformForm from "../../../Form/Admin/Edition/PlatformForm";
import DeleteModal from "../../../Components/Modal/DeleteModal";
import FormError from "../../../Vendor/Components/Form/FormError";
import FormOffCanvas from "../../../Components/Modal/FormOffCanvas";
import {Button} from "react-bootstrap";
import User from "../../../Entity/User";
import {BannerContext} from "../../../Vendor/Context/BannerContextProvider";
import FormEmptyOffCanvas from "../../../Components/Modal/FormEmptyOffCanvas";

const PlatformPage: FC = () => {
    const { setCurrentUser } = useContext(UserContext)
    const { setBanner } = useContext(BannerContext);
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [toggleReload, setToggleReload] = useState<boolean>(true)
    const [show, setShow] = useState<boolean>(false)
    const [search, setSearch] = useState<string>('')
    const [platforms, setPlatforms] = useState<Platform[]>([])
    const [platform, setPlatform] = useState<Platform>({} as Platform)
    const handleClose = (reload = false) => {
        setShow(false)
        if (reload) {
            setToggleReload(!toggleReload)
        }
    }
    const handleShow = () => setShow(true)
    // remove
    const [showDelete, setShowDelete] = useState<boolean>(false)
    const handleDeleteClose = (reload = false) => {
        setPlatform({} as Platform)
        setShowDelete(false)
        if (reload) {
            setToggleReload(!toggleReload)
        }
    }
    const handleDeleteShow = () => setShowDelete(true)

    const onSuccess = (data: User) => {
        setCurrentUser(data)
        setShow(false)
        setToggleReload(!toggleReload)
    }

    const addPlatform = () => {
        setPlatform({} as Platform)
        handleShow()
    }

    const editPlatform = (platform: Platform) => {
        navigate(platform.id?.toString() ?? '', {state: platform})
    }

    const deletePlatform = (platform: Platform) => {
        setPlatform(platform)
        handleDeleteShow()
    }

    const actionsRender = (platform: Platform) => (
        <>
            <Button variant='primary' href={platform.url} target="_blank"><i className="fa-solid fa-arrow-up-right-from-square"></i></Button>
            {platform?.isGranted?.edit &&
                <Button
                    variant='outline-primary'
                    size='sm'
                    onClick={() => editPlatform(platform)}>
                    <i className="fa-solid fa-pencil me-2"></i>
                    {t('app.edit')}</Button>
            }
            {platform?.isGranted?.delete &&
            <Button
                variant='outline-danger'
                size='sm'
                onClick={() => deletePlatform(platform)}>
                <i className="fa-solid fa-trash me-2"></i>
                {t('app.delete')}</Button>
            }
        </>
    );

    let table = PlatformTable;
    if (table.hasOwnProperty('actions')) {
        table.actions.render = actionsRender;
    }

    return (
        <>
            <Header title={t('app.platforms')}
                    add={addPlatform}
                    search={search}
                    setSearch={setSearch}
            />
            <Table
                path="/admin/edition/platform"
                rows={table}
                toggleReload={toggleReload}
                items={platforms}
                setItems={setPlatforms}
                keyword={search}
                setKeyword={setSearch}
            />
            <FormEmptyOffCanvas
                i18nTitle={platform.id ? 'app.edit_platform' : 'app.add_platform'}
                show={show}
                handleClose={handleClose}
                onSuccess={onSuccess}
                path={`/admin/edition/platform/${platform.id ?? 'new'}`}
                formPath={platform.id ? true : false}
                method={platform.id ? 'patch' : 'post'}
            />
            <DeleteModal
                handleClose={handleDeleteClose}
                show={showDelete}
                path={'/admin/edition/platform/' + (platform?.id ?? 'error')}
                name={'#' + (platform ? `${platform.id} - ${platform.name}` : 'error')}
                archive={true}
            />
        </>
    )
}

export default PlatformPage
