import {MenuItem} from "Components/Layout/Sidebar/_MenuItem";
import _EditionMenu from "./_EditionMenu";
import _ExternalMenu from "./_ExternalMenu";
import _InternalMenu from "./_InternalMenu";

const AppMenu: {
    [key: string]: MenuItem[]
} = {
    edition: _EditionMenu,
    external: _ExternalMenu,
    internal: _InternalMenu,
}

export default AppMenu
