import React, {FC, useContext, useEffect, useState, useCallback, useRef} from 'react'
import Listing, {CustomAction} from "Components/Listing/Listing";
import Header from "../Components/Layout/Header";
import {useTranslation} from "react-i18next";
import ListingSetting from "../Entity/Collection/ListingSetting";
import FormEmptyModal from "../Components/Modal/FormEmptyModal";
import DeleteModal from "../Components/Modal/DeleteModal";
import EntityInterface from "../Vendor/Definition/EntityInterface";
import Mailing from 'Entity/Mailing';
import Api from "../Vendor/Api";
import { classUtils as c } from "Vendor/Utils/ClassUtils";
import {Button, Modal} from 'react-bootstrap';
import EmptyModal from '../Components/Modal/EmptyModal';
import {ITopolOptions} from "@topol.io/editor";
import {UserContext} from "../Vendor/Context/UserContextProvider";
import {Language} from "@topol.io/editor/dist/types/types/Language/Language";
import File from "../Entity/File";
import ChooseItemsModal from "../Components/Modal/ChooseItemsModal";
import Bucket from "../Entity/Bucket";
import useTranslationDataUtils from "../Vendor/Utils/TranslationDataUtils";
import MailingCampaign from 'Entity/MailingCampaign';
import ScheduledDeliveryOn from "../Entity/Mailing/ScheduledDeliveryOn";
import LoadingButton from "../Components/Button/LoadingButton";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { ExternalFiles } from 'Config/ExternalFiles';
import useHumanizeFileSizeUtils from 'Vendor/Utils/HumanizeFileSizeUtils';
import { ISavedBlock } from '@topol.io/editor-react';
import TopolOptions from "../Config/Mailing/TopolOptions";
import IMergeTagGroup from "@topol.io/editor/dist/types/types/MergeTag/IMergeTagGroup";
import {TableResponse} from "../Components/Layout/Table";
import {ExternalFile} from "../Config/ExternalFile";

export interface MailingPageProps {
}

const MailingPage: FC<MailingPageProps> = () => {
    const scriptId = 'topol-script'
    const {t, i18n} = useTranslation()
    const currentLanguage = i18n.language
    const maxAttachmentFileSize = 9990000
    const { translate } = useTranslationDataUtils()
    const {humanFileSize} = useHumanizeFileSizeUtils()
    const {currentUser} = useContext(UserContext)
    const [toggleReload, setToggleReload] = useState<string>('')
    const [search, setSearch] = useState<string>('')
    const [show, setShow] = useState<boolean>(false)
    const [templateShow, setTemplateShow] = useState<boolean>(false)
    const [externalFilesShow, setExternalFilesShow] = useState<boolean>(false)
    const [externalFilesDirectory, setExternalFilesDirectory] = useState<null|string>(null)
    const [externalFiles, setExternalFiles] = useState<ExternalFiles>({})
    const [campaignStatisticsShow, setCampaignStatisticsShow] = useState<boolean>(false)
    const [campaigns, setCampaigns] = useState<undefined | MailingCampaign[]>(undefined)
    const [campaign, setCampaign] = useState<undefined | MailingCampaign>(undefined)
    const [setting] = useState<ListingSetting>({
        cardCondensedByDefault: false,
        cardShowBadge: false,
        cardShowStatus: false,
        cardShowSchedule: true,
        cardShowSizeAndWeight: null,
        filters: [],
        groupedActions: [],
        kanbanDatas: [],
        cardDatas: [],
        searchable: [],
        searchableByEmail: false,
        searchableByFirstName: false,
        searchableByLastName: false,
        searchableByName: false,
        sorters: [
            {
                sorter: 'createdAt',
                defaultSort: true,
                defaultDirection: 'desc'
            },
            {
                sorter: 'updatedAt',
                defaultSort: false,
                defaultDirection: 'desc'
            },
            {
                sorter: 'name',
                defaultSort: false,
                defaultDirection: 'asc'
            }
        ],
        tableColumns: [
            {
                columnType: 'name',
                visibleByDefault: true,
            },
            {
                column: 'mailing.template',
                visibleByDefault: true,
                render: (mailing: Mailing) => (
                    <Button variant="light" onClick={() => {
                        const translationKeys = currentUser?.currentOrganization?.languages ?? ['fr']

                        if (translationKeys.length === 1) {
                            setMailing(mailing)
                            setLocale(translationKeys[0])
                            setEditShow(true)
                            setTemplateShow(false)
                        } else {
                            handleTemplateShow(mailing)
                        }
                    }}>
                        {t('app.create')}
                    </Button>
                )
            },
            {
                column: 'status',
                visibleByDefault: true,
                render: (mailing: Mailing) => {
                    let status = 'draft'

                    if (mailing.sendAt) {
                        status = 'sent'
                    } else if (mailing.hasScheduledDelivery) {
                        status = 'planned'
                    }

                    if (mailing.hasScheduledDelivery) {
                        return <button className={`badge-status status-${status}`} onClick={() => {
                            setMailing(mailing)
                            setShowPlannedMailing(true)
                        }}>
                        {t(`app.item_status.${status}`)}
                    </button>
                    }

                    return <span className={`badge-status status-${status}`}>
                        {t(`app.item_status.${status}`)}
                    </span>
                },
            },
            {
                column: 'mailing.statistics',
                visibleByDefault: true,
                render: (mailing: Mailing) => (
                    <Button variant="light" onClick={() => {
                        handleCampaignStatisticsShow(mailing)
                    }}>
                        <i className="fa-solid fa-chart-simple"></i>
                    </Button>
                )
            },
            {
                column: 'mailing.external_attachments.label',
                visibleByDefault: true,
                render: (mailing: Mailing) => (
                    <Button variant="light" onClick={() => {
                        handleExternalFilesShow(mailing, 'attachments')
                    }}>
                        <i className="fa-solid fa-file"></i>
                    </Button>
                )
            },
        ],
        views: ['table']
    })
    const [mailing, setMailing] = useState<undefined | Mailing>(undefined)
    const [scheduledDeliveryOns, setScheduledDeliveryOns] = useState<ScheduledDeliveryOn[]>([])
    const [locale, setLocale] = useState<undefined|string>(undefined)
    const [editShow, setEditShow] = useState<boolean>(false)
    const [topolScriptLoaded, setTopolScriptLoaded] = useState<boolean>(false)
    const [editOnLoad, setEditOnLoad] = useState<boolean>(false)
    const [editOnError, setEditOnError] = useState<undefined|string>(undefined)
    const [fileUploading, setFileUploading] = useState<boolean>(false)
    const [topolOptions, setTopolOptions] = useState<ITopolOptions|undefined>(undefined)
    const [topolTemplate, setTopolTemplate] = useState<undefined|object>(undefined)
    const [showChooseItemsModal, setShowChooseItemsModal] = useState<boolean>(false)
    const [currentBucket, setCurrentBucket] = useState<undefined|Bucket>(undefined)
    const [showChooseBucketModal, setShowChooseBucketModal] = useState<boolean>(false)
    const [usableBuckets, setUsableBuckets] = useState<Bucket[]>([])
    const [showPlannedMailing, setShowPlannedMailing] = useState<boolean>(false)
    const [onScheduledDeliveryOnLoading, setOnScheduledDeliveryOnLoading] = useState<undefined | number>(undefined)
    const [showChooseExternalFilesModal, setShowChooseExternalFilesModal] = useState<boolean>(false)
    const [savedBlocks, setSavedBlocks] = useState<ISavedBlock[]>([])
    const [savedBlockModal, setSavedBlockModal] = useState<boolean>(false)
    const [savedBlockName, setSavedBlockName] = useState<undefined | string>(undefined)
    const [savedBlockJson, setSavedBlockJson] = useState<undefined | string>(undefined)
    const [savedBlockId, setSavedBlockId] = useState<undefined | number>(undefined)
    const [maxAttachmentFileSizeError, setMaxAttachmentFileSizeError] = useState<boolean>(false)

    const inputFileRef = useRef(null)

    const handleOpenFileInput = () => {
        if (inputFileRef) {
            // @ts-ignore
            inputFileRef.current.click()
        }
    }

    useEffect(() => {
        setSavedBlocks(currentUser?.currentOrganization?.mailingSavedBlocks)
    }, [currentUser?.currentOrganization?.mailingSavedBlocks])

    useEffect(() => {
        if ((currentUser?.currentOrganization?.buckets.length ?? -1) > 0) {
            setUsableBuckets([...currentUser?.currentOrganization?.buckets.filter((bucket: Bucket) => {
                return !bucket.simpleImport
            }) as Bucket[]])
        }
    }, [currentUser?.currentOrganization?.buckets])

    useEffect(() => {
        if (!document.getElementById(scriptId)) {
            let script = document.createElement('script')
            script.async = true
            script.src = 'https://d5aoblv5p04cg.cloudfront.net/editor-3/loader/build.js'
            script.id = scriptId
            document.body.appendChild(script)
        }

    }, [])

    useEffect(() => {
        setEditOnLoad(false)
        setEditOnError(undefined)
        if (editShow) {
            if (mailing && locale) {
                setEditOnLoad(true)
                Api.get('/mailing/' + mailing.id + '/editor/' + locale + '/template.json')
                    .then(({data}: {data: object}) => {
                        setEditOnLoad(false)
                        setTopolTemplate(data)
                    })
                    .catch((error) => {
                        setEditOnError(error.message)
                        setEditOnLoad(false)
                    })
            } else {
                setEditOnError('app.error.missing_mailing_or_locale')
            }
        }
    }, [editShow, locale, mailing, topolOptions])

    useEffect(() => {
        if (!editOnLoad && !editOnError && topolTemplate && editShow) {
            let topolOptions: ITopolOptions = {...TopolOptions}

            topolOptions.savedBlocks = savedBlocks;
            topolOptions.callbacks = {
                onSave: (json, html) => {
                    if (mailing && locale) {
                        Api.patch('/mailing/' + mailing.id + '/editor/' + locale +'/save', {
                            json: json,
                            html: html
                        })
                            .then(() => {
                            })
                            .catch((error) => {
                                setEditOnError(error.message)
                            })
                    }
                },
                onSaveAndClose: (json, html) => {
                    if (mailing && locale) {
                        Api.patch('/mailing/' + mailing.id + '/editor/' + locale +'/save', {
                            json: json,
                            html: html
                        })
                            .then(() => {
                                setEditShow(false)
                                setToggleReload(new Date().toISOString())
                            })
                            .catch((error) => {
                                setEditOnError(error.message)
                            })
                    }
                },
                onOpenFileManager: () => {
                    if (usableBuckets.length === 0) {
                        console.error('No bucket found')
                    } else if (usableBuckets.length === 1) {
                        setCurrentBucket(usableBuckets[0])
                        setShowChooseItemsModal(true)
                    } else {
                        setShowChooseBucketModal(true)
                    }
                },
                onBlockSave: (json) => {
                    if (mailing) {
                        setSavedBlockModal(true)
                        setSavedBlockJson(JSON.stringify(json.definition))
                    }
                },
                onBlockEdit: (id) => {
                    if (mailing) {
                        setSavedBlockModal(true)
                        setSavedBlockId(id)

                        const savedBlock = savedBlocks.find((savedBlock: ISavedBlock) => savedBlock.id === id)

                        if (savedBlock) {
                            setSavedBlockName(savedBlock.name)
                        }
                    }
                },
                onBlockRemove: (id) => {
                    if (mailing) {
                        Api.delete('/mailing/' + mailing.id + '/saved-block/' + id + '/delete')
                            .then((response) => {
                                window.TopolPlugin.setSavedBlocks(response.data as any)
                                window.TopolPlugin.createNotification({
                                    title: t('app.mailing.saved_block.notification.delete.title'),
                                    text: t('app.mailing.saved_block.notification.delete.text'),
                                    type: 'success',
                                })
                            })
                            .catch((error) => {
                                setEditOnError(error.message)
                            })
                    }
                },
                onTestSend: function(email, json, html){
                    if (mailing) {
                        Api.post(`/mailing/${mailing.id}/send-test`, {
                            'emails': [email],
                            'html': html
                        })
                            .then(res => {
                                window.TopolPlugin.createNotification({
                                    'title': 'Envoyé',
                                    'text': 'Test envoyé',
                                    'type': 'success'
                                });
                            })
                            .catch((error) => {
                                window.TopolPlugin.createNotification({
                                    'title': 'Error',
                                    'text': (error.message ? error.message : 'Une erreur est survenue.'),
                                    'type': 'error'
                                });
                            })
                        ;
                    }
                }
            }
            topolOptions.language = i18n.language as Language;
            topolOptions.authorize.userId = currentUser?.currentOrganization?.id ?? 0;

            // TODO: remove this line. 8 = Catherine Miran
            if (currentUser?.currentOrganization?.id === 8)
            {
                if (topolOptions.mergeTags?.length) {
                    let contact: IMergeTagGroup = topolOptions.mergeTags[0] as IMergeTagGroup
                    contact.items.push({value: '__SIEGE__', text: 'Siège', label: 'Numéro de siège'})
                    topolOptions.mergeTags[0] = contact
                }
            }

            window.TopolPlugin.init(topolOptions)
            window.TopolPlugin.load(JSON.stringify(topolTemplate))
        } else {
            window.TopolPlugin?.destroy()
        }
    }, [currentUser?.currentOrganization?.id, editOnError, editOnLoad, i18n.language, locale, mailing, savedBlocks, t, topolTemplate, usableBuckets, editShow])

    useEffect(() => {
        if (mailing && showPlannedMailing) {
            Api.get('/mailing/' + mailing.id + '/scheduled-delivery-on')
                .then(({data}: {data: ScheduledDeliveryOn[]}) => {
                    setScheduledDeliveryOns(data)
                })
                .catch(() => {})
        }
    }, [showPlannedMailing, mailing])

    const handleTemplateShow = (mailing: Mailing) => {
        setMailing(mailing)
        setTemplateShow(true)
    }

    const handleTemplateClose= () => {
        setMailing(undefined)
        setTemplateShow(false)
    }

    const handleCampaignStatisticsShow = (mailing: Mailing) => {
        setMailing(mailing)
        setCampaignStatisticsShow(true)
        setCampaigns(undefined)

        Api.get('/mailing/' + mailing.id + '/campaigns')
            .then((response : {data: MailingCampaign[]}) => {
                setCampaigns(response.data)
                if (response.data.length === 1) {
                    setCampaign(response.data[0])
                }
            })
    }

    const handleCampaignStatisticsClose= () => {
        setMailing(undefined)
        setCampaign(undefined)
        setCampaignStatisticsShow(false)
    }

    const handleSavedBlockSave = () => {
        if (mailing && savedBlockName) {
            if (savedBlockJson) {
                Api.post('/mailing/' + mailing.id + '/saved-block/create', {
                    name: savedBlockName,
                    json: savedBlockJson
                })
                    .then((response) => {
                        window.TopolPlugin.setSavedBlocks(response.data as ISavedBlock[])
                        window.TopolPlugin.createNotification({
                            title: t('app.mailing.saved_block.notification.create.title'),
                            text: t('app.mailing.saved_block.notification.create.text'),
                            type: 'success',
                        })

                        setSavedBlocks(response.data)
                    })
                    .catch((error) => {
                        setEditOnError(error.message)
                    })
            } else if (savedBlockId) {
                Api.patch('/mailing/' + mailing.id + '/saved-block/' + savedBlockId + '/update', {
                    name: savedBlockName
                })
                    .then((response) => {
                        window.TopolPlugin.setSavedBlocks(response.data as ISavedBlock[])
                        window.TopolPlugin.createNotification({
                            title: t('app.mailing.saved_block.notification.update.title'),
                            text: t('app.mailing.saved_block.notification.update.text'),
                            type: 'success',
                        })

                        setSavedBlocks(response.data)
                    })
                    .catch((error) => {
                        setEditOnError(error.message)
                    })
            }
        }

        handleSavedBlockClose()
    }

    const handleSavedBlockClose = () => {
        setSavedBlockModal(false)
        setSavedBlockName(undefined)
        setSavedBlockJson(undefined)
        setSavedBlockId(undefined)
    }

    const handleExternalFilesShow = (mailing: Mailing, directory: string) => {
        setMailing(mailing)
        setExternalFilesShow(true)
        setExternalFilesDirectory(directory)

        Api.get('/mailing/' + mailing.id + '/external-files', {params: {'subdirectory': directory}})
            .then((response : {data: TableResponse<ExternalFile>}) => {
                setExternalFiles(response.data.items)
            })
    }

    const handleExternalFilesClose = () => {
        setExternalFiles({})
        setExternalFilesShow(false)
        setMaxAttachmentFileSizeError(false)
        setToggleReload(new Date().toISOString())

        if (externalFilesDirectory === 'attachments') {
            setMailing(undefined)
        }
    }

    const handleShow = () => {
        setMailing(undefined)
        setShow(true)
    }

    // remove
    const [showDelete, setShowDelete] = useState<boolean>(false)
    const [showSendModal, setShowSendModal] = useState<boolean>(false)
    const handleDeleteClose = (reload = false) => {
        setMailing(undefined)
        setShowDelete(false)
        if (reload) {
            setToggleReload(new Date().toISOString())
        }
    }
    const handleDeleteShow = () => setShowDelete(true)

    const onSuccess = (mailing: Mailing) => {
        setMailing(mailing)
        setShow(false)
        setToggleReload(new Date().toISOString())
    }

    const editMailing = (mailing: EntityInterface) => {
        setMailing(mailing as Mailing)
        setShow(true)
    }

    const deleteMailing = (mailing: EntityInterface) => {
        setMailing(mailing as Mailing)
        handleDeleteShow()
    }

    const handleSend = (mailing: Mailing) => {
        setMailing(mailing as Mailing)
        setShowSendModal(true)
    }

    const handleDuplicate = (mailing: Mailing) => {
        Api.get(`/mailing/${mailing.id}/duplicate`)
            .then(() => {
                setToggleReload(new Date().toISOString())
            })
            .catch(() => {})
    }

    const handleUploadExternalFiles = useCallback((files: any) => {
        let tempExternalFiles: ExternalFiles = {...externalFiles}
        let attachmentsSize = 0

        if (externalFilesDirectory === 'attachments') {
            //Calculate file size of existing files
            for (const [key, externalFile] of Object.entries(
                tempExternalFiles,
            )) {
                if (externalFile.state !== 'pending') {
                    attachmentsSize += externalFile.file_size
                }
            }

            for (let i = 0; i < files.length; i++) {
                attachmentsSize += files[i].size
            }

            if (attachmentsSize > maxAttachmentFileSize) {
                setMaxAttachmentFileSizeError(true)
                return
            }
        }

        for (let i = 0; i < files.length; i++) {
            tempExternalFiles[files[i].name] = {
                file_size: files[i].size,
                path: files[i].name,
                state: 'pending',
                data: files[i],
            }
        }

        setExternalFiles(tempExternalFiles)
    }, [mailing, externalFiles])

    useEffect(() => {
        if (mailing) {
            for (const [key, externalFile] of Object.entries(externalFiles)) {
                if (externalFile.state === 'pending') {
                    let formData = new FormData()
                    formData.append('files[]', externalFile.data)

                    if (externalFilesDirectory) {
                        formData.append('subdirectory', externalFilesDirectory)
                    }

                    let tempExternalFiles: ExternalFiles = {...externalFiles}
                    tempExternalFiles[externalFile.path] = {
                        file_size: externalFile.file_size,
                        path: externalFile.path,
                        state: 'uploading',
                    }
                    setExternalFiles(tempExternalFiles)
                    setFileUploading(true)

                    Api.post(`/mailing/${mailing.id}/external-files/upload`, formData, {
                        baseURL: `https://s1-upload.${window.location.host.split(':')[0]}${process.env.REACT_APP_BACKEND_PORT ? ':' + process.env.REACT_APP_BACKEND_PORT: ''}`,
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        onUploadProgress: (progressEvent) => {
                            const { loaded, total } = progressEvent;
                            let percent = Math.min(100, Math.floor((loaded * 100) / total));
                            let tempExternalFiles: ExternalFiles = {...externalFiles}
                            tempExternalFiles[externalFile.path] = {
                                file_size: externalFile.file_size,
                                path: externalFile.path,
                                state: 'uploading',
                                uploadProgress: percent,
                            }
                            setExternalFiles(tempExternalFiles)
                        }
                    }).then(({ data }) => {
                        setFileUploading(false)
                        setExternalFiles({ ...data })
                    }).catch(() => {
                        let tempExternalFiles: ExternalFiles = {...externalFiles}
                        tempExternalFiles[externalFile.path] = {
                            file_size: externalFile.file_size,
                            path: externalFile.path,
                            state: 'error',
                        }
                        setExternalFiles(tempExternalFiles)
                    })
                }
            }
        }
    }, [mailing, externalFiles, handleUploadExternalFiles]);

    const nameFilePath = (filePath: string) => {
        const segments = filePath.split('/')

        return segments[segments.length - 1]
    }

    const customActions: CustomAction[] = [
        {
            method: handleSend,
            icon: 'paper-plane',
            i18nLabel: 'app.send',
            variant: 'info',
        },
        {
            method: handleDuplicate,
            icon: 'files',
            i18nLabel: 'app.duplicate',
            variant: 'warning',
        },
    ]

    const removeScheduledDeliveryOn = (mailingId: number, scheduledDeliveryOnId: number) => {
        setOnScheduledDeliveryOnLoading(scheduledDeliveryOnId)
        Api.delete(`/mailing/${mailingId}/scheduled-delivery-on/${scheduledDeliveryOnId}`)
            .then(({data}: {data: Mailing}) => {
                setMailing(data)

                if (data?.scheduledDeliveryOns?.length === 0 ?? true) {
                    setShowPlannedMailing(false)
                }
                setToggleReload(new Date().toISOString())
            })
            .catch(() => {})
            .then(() => {
                setOnScheduledDeliveryOnLoading(undefined)
            })
    }

    const campaignStatisticTabs = (campaign: MailingCampaign) => {
        const campaignSendCount     = campaign.statistics.Send ?? 0;
        const campaignDeliveryCount = campaign.statistics.Delivery ?? 0;
        const campaignOpenCount     = campaign.statistics.Open ?? 0;
        const campaignClickCount    = campaign.statistics.Click ?? 0;
        const campaignBounceCount   = campaign.statistics.Bounce ?? 0;
        const campaignOpenRate   = campaignDeliveryCount > 0 ? ((campaignOpenCount / campaignDeliveryCount) * 100) : 0;
        const campaignClickRate  = campaignDeliveryCount > 0 ? ((campaignClickCount / campaignDeliveryCount) * 100) : 0;
        const campaignBounceRate = campaignSendCount > 0 ? ((campaignBounceCount / campaignSendCount) * 100) : 0;

        return (
            <Tabs
                defaultActiveKey="preview"
                className="mb-3"
                justify
            >
                <Tab eventKey="preview" title={t('app.mailing.preview')}>
                    <table className="table table-striped table-collapse mb-3">
                        <tbody>
                            <tr key={'Send'}>
                                <td>{t('app.mailing.event.prefix')} {t(`app.mailing.event.types.Send`, { count: campaignSendCount }).toLowerCase()}</td>
                                <td>{campaignSendCount}</td>
                            </tr>
                            <tr key={'Delivery'}>
                                <td>{t('app.mailing.event.prefix')} {t(`app.mailing.event.types.Delivery`, { count: campaignDeliveryCount }).toLowerCase()}</td>
                                <td>{campaignDeliveryCount}</td>
                            </tr>
                            <tr key={'Open'}>
                                <td>{t('app.mailing.event.prefix')} {t(`app.mailing.event.types.Open`, { count: campaignOpenCount }).toLowerCase()}</td>
                                <td>{campaignOpenCount} ({ campaignOpenRate.toFixed(0) } %)</td>
                            </tr>
                            <tr key={'Click'}>
                                <td>{t('app.mailing.event.prefix')} {t(`app.mailing.event.types.Click`, { count: campaignClickCount }).toLowerCase()}</td>
                                <td>{campaignClickCount} ({ campaignClickRate.toFixed(0) } %)</td>
                            </tr>
                            <tr key={'Bounce'}>
                                <td>{t(`app.mailing.event.types.Bounce`, { count: campaignBounceCount })}</td>
                                <td>{campaignBounceCount} ({ campaignBounceRate.toFixed(0) } %)</td>
                            </tr>
                        </tbody>
                    </table>
                </Tab>
                <Tab eventKey="recipients" title={t('app.mailing.recipients')}>
                    <table className="table table-striped table-collapse mb-3">
                        <thead>
                            <tr>
                                <th>{t('app.mailing.column.email')}</th>
                                <th>{t('app.mailing.event.types.Open', { count: 1 })}</th>
                                <th>{t('app.mailing.event.types.Click', { count: 1 })}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(campaign.recipientStatistics).map((key: string) => (
                                <tr key={key}>
                                    <td>{key}</td>
                                    <td><i className={`fa-solid fa-${campaign.recipientStatistics[key].Open ? 'check' : 'xmark'}`}></i></td>
                                    <td><i className={`fa-solid fa-${campaign.recipientStatistics[key].Click ? 'check' : 'xmark'}`}></i></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Tab>
                <Tab eventKey="domains" title={t('app.mailing.domains')}>
                    <table className="table table-striped table-collapse mb-3">
                        <thead>
                            <tr>
                                <th>{t('app.mailing.column.domain')}</th>
                                <th>{t('app.mailing.event.types.Bounce', { count: 2 })}</th>
                                <th>{t('app.mailing.event.types.Delivery', { count: 2 })}</th>
                                <th>{t('app.mailing.event.types.Open', { count: 2 })}</th>
                                <th>{t('app.mailing.event.types.Click', { count: 2 })}</th>
                                <th>{t('app.mailing.event.types.Subscription', { count: 2 })}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(campaign.domainStatistics).map((key: string) => (
                                <tr key={key}>
                                    <td>{key}</td>
                                    <td>{campaign.domainStatistics[key].Bounce ?? '-'}</td>
                                    <td>{campaign.domainStatistics[key].Delivery ?? '-'}</td>
                                    <td>{campaign.domainStatistics[key].Open ?? '-'}</td>
                                    <td>{campaign.domainStatistics[key].Click ?? '-'}</td>
                                    <td>{campaign.domainStatistics[key].Subscription ?? '-'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Tab>
                {/* <Tab eventKey="links" title={t('app.mailing.links')}>
                </Tab> */}
                <Tab eventKey="unsubscribes" title={t('app.mailing.unsubscribes')}>
                    <table className="table table-striped table-collapse mb-3">
                        <thead>
                            <tr>
                                <th>{t('app.mailing.column.email')}</th>
                                <th>{t('app.mailing.column.eventAt')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(campaign.recipientStatistics).map((key: string) => (
                                <>
                                    {campaign.recipientStatistics[key].Subscription ?
                                        <tr key={key}>
                                            <td>{key}</td>
                                            <td>{(new Date(campaign.recipientStatistics[key].Subscription)).toLocaleDateString(currentLanguage, {
                                                weekday: 'long',
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                                hour: 'numeric',
                                                minute: 'numeric',
                                            })}</td>
                                        </tr>
                                    : null}
                                </>

                            ))}
                        </tbody>
                    </table>
                </Tab>
                {/* <Tab eventKey="follow" title={t('app.mailing.follow')}>
                </Tab> */}
            </Tabs>
        )
    }

    const getEmailSentTime=(sentAt:Date)=>{
        const dateObject= new Date(sentAt)
        const hours = dateObject.getHours();
        const minutes = dateObject.getMinutes();

        return `${hours}:${minutes}`
    }

    return <>
        <Header
            title={t('app.mailings')}
            search={search}
            setSearch={setSearch}
            add={handleShow}
        />
        <Listing
            path="/mailing"
            search={search}
            setSearch={setSearch}
            setting={setting}
            selectable={false}
            toggleReload={toggleReload}
            setToggleReload={setToggleReload}
            editAction={editMailing}
            deleteAction={deleteMailing}
            customActions={customActions}
        />
        <FormEmptyModal
            path={`/mailing/${mailing?.id ?? 'new'}`}
            method={mailing?.id ? 'PATCH' : 'POST'}
            formPath={!!mailing?.id}
            i18nTitle={mailing?.id ? 'app.mailing.edit' : 'app.mailing.add'}
            show={show}
            handleClose={() => setShow(false)}
            onSuccess={onSuccess}
        />
        <DeleteModal
            handleClose={handleDeleteClose}
            show={showDelete}
            path={'/mailing/' + (mailing?.id ?? 'error')}
            name={'#' + (mailing ? `${mailing.id} - ${mailing.name}` : 'error')}
            archive={true}
        />
        {mailing && <>
            <FormEmptyModal
                i18nTitle={t('app.mailing.send_a') + ': ' + mailing.name}
                show={showSendModal}
                handleClose={() => {setShowSendModal(false)}}
                path={`/mailing/${mailing.id}/send`}
                onSuccess={() => {
                    setToggleReload(new Date().toISOString())
                    setShowSendModal(false)
                }}
            />
            <EmptyModal show={templateShow} onClose={handleTemplateClose} title={t('app.mailing.template')}>
                <ul className="grouped-action-choices">
                    {(currentUser?.currentOrganization?.languages ?? ['fr']).map((lang: string, key,) => (
                        <li key={key} className={c("btn btn-secondary")} onClick={() => {
                            setMailing(mailing)
                            setLocale(lang)
                            setEditShow(true)
                            setTemplateShow(false)
                        }}>
                            <span>{t(`app.lang.${lang}`)}</span>
                        </li>
                    ))}
                </ul>
            </EmptyModal>
            <EmptyModal show={savedBlockModal} onClose={handleSavedBlockClose} title={t('app.mailing.saved_block.form.name')}>
                <div className="pb-3">
                    <h3>{savedBlockName ?? t('app.not_specified')}</h3>
                    <div className="input-group">
                        <input type="text" className="form-control" value={savedBlockName} onChange={(e) => setSavedBlockName(e.target.value)}/>
                        <Button variant="primary" onClick={handleSavedBlockSave}>
                            {t('app.save')}
                        </Button>
                        <Button variant="secondary" onClick={handleSavedBlockClose}>
                            {t('app.cancel')}
                        </Button>
                    </div>
                </div>
            </EmptyModal>
            <Modal show={campaignStatisticsShow} onHide={handleCampaignStatisticsClose} size="lg">
                <Modal.Header closeButton={true}>
                    <div className="modal-block-header">
                        {campaign && <i className="fa-solid fa-angle-left" onClick={() => {
                            setCampaign(undefined)
                        }}></i>}
                        <Modal.Title>
                            {t('app.mailing.statistics', { context: campaign ? 'campaign' : '', sendAt: campaign ? (new Date(campaign.sendAt)).toLocaleDateString() : null })}
                        </Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {campaign ?
                        <>{campaignStatisticTabs(campaign)}</>
                        :
                        <ul className="grouped-action-choices">
                            {campaigns?.map((campaign: MailingCampaign, index: number) => (
                                <li key={campaign.id} className={c("btn btn-secondary")} onClick={() => {
                                    setCampaign(campaign)
                                }}>
                                    <span>#{index + 1 } {t('app.mailing.campaign', { sendAt: (new Date(campaign.sendAt)).toLocaleDateString() })}</span>
                                    <div className='d-flex gap-2'>
                                        <span>{getEmailSentTime(campaign.sendAt)}</span>
                                        <div className='d-flex gap-2'>
                                            <i className="fa fa-users"/>
                                            <span>{campaign.statistics.Send ?? 0}</span>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>}
                </Modal.Body>
            </Modal>
            <EmptyModal show={externalFilesShow} onClose={handleExternalFilesClose} title={t(`app.mailing.external_${externalFilesDirectory ?? 'files' }.label`)}>
                <ul className="grouped-action-choices">
                    <li className={c("btn btn-secondary")} onClick={() => handleOpenFileInput()}>
                        <div>
                            <label htmlFor="external-files-input" onClick={e => {
                                e.stopPropagation()
                            }}>
                                {t(`app.mailing.external_${externalFilesDirectory ?? 'files' }.add`)}
                            </label>
                            <input ref={inputFileRef} type="file" id="external-files-input" className="d-none" multiple onChange={(e) => {
                                if (e.target.files && e.target.files.length) {
                                    e.stopPropagation()
                                    handleUploadExternalFiles(e.target.files)
                                }
                            }}/>
                        </div>
                    </li>
                    {Object.values(externalFiles).map((externalFile, key) =>
                        <li key={key}>
                            <div>
                                <span className="name">{nameFilePath(externalFile.path)}</span>
                            </div>
                            <div>
                                <span className="size">{humanFileSize(externalFile.file_size)}</span>
                                <button className="btn remove"
                                    onClick={() => {
                                        if (externalFile.state === 'uploaded') {
                                            Api.delete(`/mailing/${mailing.id}/external-files/remove`, {
                                                baseURL: `https://s1-upload.${window.location.host.split(':')[0]}${process.env.REACT_APP_BACKEND_PORT ? ':' + process.env.REACT_APP_BACKEND_PORT: ''}`,
                                                data: {
                                                    'filePath': (externalFile.bucketPath ?? externalFile.path),
                                                }
                                            }).then(() => {
                                                let tempExternalFiles: ExternalFiles = {...externalFiles}
                                                let key = (externalFile.bucketPath ?? externalFile.path).split('/').reverse()[0]
                                                delete tempExternalFiles[key]
                                                setExternalFiles(tempExternalFiles)
                                            }).catch(() => {
                                            })
                                        } else {
                                            let tempExternalFiles: ExternalFiles = {...externalFiles}
                                            delete tempExternalFiles[(externalFile.bucketPath ?? externalFile.path).split('/').reverse()[0]]
                                            setExternalFiles(tempExternalFiles)
                                        }
                                    }}
                                >
                                    <i className="fa-solid fa-x"></i>
                                </button>
                            </div>
                        </li>
                    )}
                </ul>
                {maxAttachmentFileSizeError &&
                    <div className="alert alert-danger" role="alert">
                        {t('app.attachmentFileSizeLimitExceeded')}
                    </div>}
                {Object.values(externalFiles).length > 0 &&
                    <div className="d-flex justify-content-end pb-4">
                        <LoadingButton isLoading={fileUploading}
                        variant="primary" onClick={() => handleExternalFilesClose()}>
                            {t('app.save_and_close')}
                        </LoadingButton>
                    </div>}
            </EmptyModal>
            <Modal fullscreen show={editShow} onHide={() => { setEditShow(false) }}>
                <Modal.Body className="d-flex">
                    <div id="topol-editor" style={{width: '100vw', height: '100vh'}} />
                </Modal.Body>
            </Modal>
            <EmptyModal show={showChooseBucketModal} onClose={() => { setShowChooseBucketModal(false) }} title={t('app.choose_bucket')}>
                <ul className="grouped-action-choices">
                    {usableBuckets.map((bucket: Bucket, key) => (
                        <li key={key} className={c("btn btn-secondary")} onClick={() => {
                            setCurrentBucket(bucket)
                            setShowChooseBucketModal(false)
                            setShowChooseItemsModal(true)
                        }}>
                            <span>{translate(bucket, 'name')}</span>
                        </li>
                    ))}

                    <li key={'external-files'} className={c("btn btn-secondary")} onClick={() => {
                        setShowChooseBucketModal(false)
                        setShowChooseItemsModal(false)
                        setShowChooseExternalFilesModal(true)
                    }}>
                        <span>{t('app.external_files.label')}</span>
                    </li>
                </ul>
            </EmptyModal>
            <ChooseItemsModal
                show={showChooseItemsModal}
                setShow={(show: boolean = false) => {
                    setShowChooseItemsModal(show)
                }}
                selected={[]}
                setSelected={(selected) => {
                    if (selected.length > 0) {
                        const file = selected[0] as File
                        Api.patch(`/file/${file.id}/mailingId/generate`)
                            .then(({data}) => {
                                window.TopolPlugin?.chooseFile(data.mailingPath)
                            });
                    }
                }}
                title={currentBucket ? translate(currentBucket, 'name') : 'error'}
                path={`/b/${currentBucket?.primarySlug ?? 'error'}`}
                entity="bucket"
                multiple={false}
            />
            <ChooseItemsModal
                show={showChooseExternalFilesModal}
                setShow={(show: boolean = false) => {
                    setShowChooseExternalFilesModal(show)
                }}
                selected={[]}
                setSelected={(selected) => {
                    if (selected.length > 0) {
                        const externalFile = selected[0]
                        window.TopolPlugin?.chooseFile(externalFile.path)
                    }
                }}
                title={t('app.external_files.label')}
                path={`/mailing/${mailing.id}/external-files`}
                entity="external-file"
                multiple={false}
                hasSearch={false}
                addModalShow={() => {
                    setExternalFilesShow(true)
                    setExternalFilesDirectory(null)
                }}
                toggleReload={toggleReload}
            />
            <EmptyModal show={showPlannedMailing} onClose={() => {
                setShowPlannedMailing(false)
                setMailing(undefined)
            }} title={t('app.planned_send')}>
                <ul className="list-group list-group-border mb-3" style={{borderWidth: '1px'}}>
                    {scheduledDeliveryOns.map((scheduledDeliveryOn: ScheduledDeliveryOn, key: number) => {
                        let date = new Date(scheduledDeliveryOn.plannedAt)

                        return <li key={key} className="list-group-item">
                            <div className="d-flex justify-content-between align-items-center">
                                <span>
                                    {date.toLocaleDateString(currentLanguage, {
                                        weekday: 'long',
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                        hour: 'numeric',
                                        minute: 'numeric',
                                    })}
                                </span>
                                <span>
                                    <LoadingButton isLoading={onScheduledDeliveryOnLoading === scheduledDeliveryOn.id} className="btn btn-danger" onClick={() => { removeScheduledDeliveryOn(mailing.id, scheduledDeliveryOn.id) }}><i className="fa-solid fa-trash"></i></LoadingButton>
                                </span>
                            </div>
                        </li>
                    })}
                </ul>
            </EmptyModal>
        </>}
    </>
}

export default MailingPage
