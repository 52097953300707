import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Header from 'Components/Layout/Header'
import Table from 'Components/Layout/Table'
import DeleteModal from '../../../Components/Modal/DeleteModal'
import { Button } from 'react-bootstrap'
import FormEmptyOffCanvas from '../../../Components/Modal/FormEmptyOffCanvas'
import PlatformSecurityTable from 'Table/Admin/Edition/PlatformSecurityTable'
import { useNavigate } from 'react-router-dom'
import SecurityRule from 'Entity/SecurityRule'

const PlatformSecurityPage: FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [toggleReload, setToggleReload] = useState<boolean>(true)
    const [show, setShow] = useState<boolean>(false)
    const [search, setSearch] = useState<string>('')
    const [rules, setRules] = useState<SecurityRule[]>([])
    const [rule, setRule] = useState<SecurityRule>({} as SecurityRule)
    const handleClose = (reload = false) => {
        setShow(false)
        if (reload) {
            setToggleReload(!toggleReload)
        }
    }
    const handleShow = () => setShow(true)
    const [showDelete, setShowDelete] = useState<boolean>(false)
    const handleDeleteClose = (reload = false) => {
        setRule({} as SecurityRule)
        setShowDelete(false)
        if (reload) {
            setToggleReload(!toggleReload)
        }
    }

    const handleDeleteShow = () => setShowDelete(true)

    const onSuccess = (rule: SecurityRule) => {
        setRule(rule)
        setShow(false)
        setToggleReload(!toggleReload)
    }

    const addRule = () => {
        setRule({} as SecurityRule)
        handleShow()
    }

    const editRule = (rule: SecurityRule) => {
        navigate(rule.id?.toString() ?? '', {state: rule})
    }

    const deleteRule = (rule: SecurityRule) => {
        setRule(rule)
        handleDeleteShow()
    }

    const actionsRender = (rule: SecurityRule) => (
        <>
            {rule?.isGranted?.edit && (
                <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={() => editRule(rule)}
                >
                    <i className="fa-solid fa-pencil me-2"></i>
                    {t('app.edit')}
                </Button>
            )}
            {rule?.isGranted?.delete && (
                <Button
                    variant="outline-danger"
                    size="sm"
                    onClick={() => deleteRule(rule)}
                >
                    <i className="fa-solid fa-trash me-2"></i>
                    {t('app.delete')}
                </Button>
            )}
        </>
    )

    let table = PlatformSecurityTable
    if (table.hasOwnProperty('actions')) {
        table.actions.render = actionsRender
    }

    return (
        <>
            <Header
                title={t('app.platform_security')}
                add={addRule}
                search={search}
                setSearch={setSearch}
            />
            <Table
                path="/admin/edition/security-rule"
                rows={table}
                toggleReload={toggleReload}
                items={rules}
                setItems={setRules}
                keyword={search}
                setKeyword={setSearch}
            />
            <FormEmptyOffCanvas
                i18nTitle={rule.id ? 'app.edit_security_rule' : 'app.add_security_rule'}
                show={show}
                handleClose={handleClose}
                onSuccess={onSuccess}
                path={`/admin/edition/security-rule/${
                    rule.id ?? 'new'
                }`}
                formPath={rule.id ? true : false}
                method={rule.id ? 'patch' : 'post'}
            />
            <DeleteModal
                handleClose={handleDeleteClose}
                show={showDelete}
                path={
                    '/admin/edition/security-rule/' +
                    (rule?.id ?? 'error')
                }
                name={
                    '#' + (rule ? `${rule.id} - ${rule.name}` : 'error')
                }
                archive={true}
            />
        </>
    )
}

export default PlatformSecurityPage
