import React, {FC, useState} from 'react'
import Api from 'Vendor/Api'
import {classUtils as c} from 'Vendor/Utils/ClassUtils'
import {useTranslation} from "react-i18next";
import LoadingButton from "./Button/LoadingButton";
import {Button} from "react-bootstrap";

const Dropzone: FC<{
    uploadPath: string
    deletePath?: string
    multiple: boolean
    files: string[]
    setFiles: (files: string[]) => void
    disabled?: boolean
    i18nPlaceholder?: string
    onSuccess?: (data: any) => void
}> = ({uploadPath, deletePath, multiple, disabled = false, files, setFiles, i18nPlaceholder, onSuccess}) => {
    const [onDrag, setOnDrag] = useState<boolean>(false)
    const [onLoad, setOnLoad] = useState<string[]>([])
    const [onDelete, setOnDelete] = useState<boolean>(false)
    const hiddenFileInput = React.useRef<HTMLInputElement>(null);
    const { t } = useTranslation()

    const upload = (files: FileList) => {
        let formData = new FormData()
        for (let i = 0; i < files.length; i++) {
            formData.append(`file${multiple ? 's[]' : ''}`, files[i])
        }
        setOnLoad(onLoad)
        Api.post(uploadPath, formData).then((response) => {
            setOnLoad([])
            onSuccess && onSuccess(response.data)
        })
    }

    return <div
        className={c('dropzone', files.length > 0 && 'dropzone-has-file', multiple && 'dropzone-multiple', onDrag && 'dropzone-drag', disabled && 'dropzone-disabled')}

        onDragEnter={(e) => {
            e.preventDefault();
            e.stopPropagation()
            setOnDrag(true)
        }}

        onDragOver={(e) => {
            e.preventDefault();
            e.stopPropagation()
            setOnDrag(true)
        }}

        onDragLeave={(e) => {
            e.preventDefault();
            e.stopPropagation()
            setOnDrag(false)
        }}

        onDrop={(e) => {
            e.preventDefault();
            e.stopPropagation()
            setOnDrag(false)
            if (!disabled) {
                if (e.dataTransfer.files.length && (multiple || e.dataTransfer.files.length === 1)) {
                    upload(e.dataTransfer.files)
                }

            }
        }
        }
    >
        <div className="dropzone-container">
        {files.length === 0
            ? <div className="dropzone-placeholder">
                {i18nPlaceholder ? t(i18nPlaceholder) : t('app.dropzone_placeholder')}
            </div>
            : files.map((file, key) => (
                <div className="dropzone-file" key={key}>
                    <img src={file} alt="" />
                </div>
            ))
        }
        </div>
        {!multiple ? <div className="row justify-content-end mt-3">
            <div className="col-auto">
                <input type="file" multiple={multiple} className="d-none" ref={hiddenFileInput} onChange={(e) => {
                    if (e.target.files) {
                        if (e.target.files.length) {
                            upload(e.target.files)
                            if (hiddenFileInput.current !== null) {
                                hiddenFileInput.current.value = '';
                            }
                        }
                    }
                }} />
                <Button variant="outline-primary" disabled={onDelete} onClick={() => {
                    hiddenFileInput.current?.click();
                }}>
                    <i className="fa-solid fa-upload me-2" />{t('app.upload')}
                </Button>
                {deletePath && <LoadingButton isLoading={onDelete} onClick={() => {
                    setOnDelete(true)
                    Api.delete(deletePath).then((response) => {
                        setFiles([])
                    }).catch(() => {
                    }).then(() => {
                        setOnDelete(false)
                    })
                }} variant="danger" disabled={!files.length || onDelete} className="ms-2">
                    <i className="fa-solid fa-trash me-2" />{t('app.delete')}
                </LoadingButton>}
                 <Button
                        disabled={!files.length || onDelete}
                        href={files[0] + '?download'}
                        target="_blank"
                        className={deletePath && 'ms-2'}
                    >
                        <i className="fa-solid fa-download me-2" />{t('app.download')}
                    </Button>
            </div>
        </div> : <></>}
    </div>
}

export default Dropzone
