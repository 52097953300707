import {Choice} from "Vendor/Components/Form/Type/ChoiceType";

const SeparatorChoice: Choice[] = [
    {
        i18nKey: 'app.enum.property.separator.fields.none',
        value: 'none'
    },
    {
        i18nKey: 'app.enum.property.separator.fields.prefix',
        value: 'prefix'
    },
    {
        i18nKey: 'app.enum.property.separator.fields.suffix',
        value: 'suffix'
    },
    {
        i18nKey: 'app.enum.property.separator.fields.comma',
        value: 'comma'
    },
    {
        i18nKey: 'app.enum.property.separator.fields.semicolon',
        value: 'semicolon'
    },
    {
        i18nKey: 'app.enum.property.separator.fields.colon',
        value: 'colon'
    },
    {
        i18nKey: 'app.enum.property.separator.fields.tab',
        value: 'tab'
    },
    {
        i18nKey: 'app.enum.property.separator.fields.dot',
        value: 'dot'
    },
    {
        i18nKey: 'app.enum.property.separator.fields.dash',
        value: 'dash'
    },
    {
        i18nKey: 'app.enum.property.separator.fields.underscore',
        value: 'underscore'
    },
    {
        i18nKey: 'app.enum.property.separator.fields.new_line',
        value: 'new_line'
    },
    {
        i18nKey: 'app.enum.property.separator.fields.pipe',
        value: 'pipe'
    },
    {
        i18nKey: 'app.enum.property.separator.fields.space',
        value: 'space'
    },
]

export default SeparatorChoice
