import {Rows} from "Components/Layout/Table";

const BucketTable: Rows = {
    id: {
        placeholders: [
            {
                element: 'p',
                size: 1
            },
        ]
    },
    icon: {
        sortable: false,
        placeholders: [
            {
                element: 'p',
                size: 1
            },
        ]
    },
    name: {
        translated: true,
        placeholders: [
            {
                element: 'p',
                size: [3, 1],
            }
        ]
    },
    brand: {
        field: 'name',
        i18nPlaceholder: 'app.corporate',
        placeholders: [
            {
                element: 'p',
                size: [3, 1],
            }
        ]
    },
    mimeTypes: {
        i18nLabel: 'app.mime_types', i18nKey: 'app.mimes.', i18nPlaceholder: 'app.all_files',
        placeholders: [
            {
                element: 'p',
                size: [3],
            }
        ]
    },
    actions: {
        className: 'actions',
        sortable: false,
        placeholders: [
            {
                element: 'a',
                size: 2,
            },
        ]
    },
};

export default BucketTable