import {RouteObject} from "react-router-dom";
import SecurityRouting from "./SecurityRouting";
import {PublicRoute} from "Components/Route/PublicRoute";
import {ProtectedRoute} from "Components/Route/ProtectedRoute";
import AdminRouting from "./AdminRouting";
import EditionRouting from "./EditionRouting";
import ExternalRouting from "./ExternalRouting";

const AppRouting: RouteObject[] = [
    {
        path: '/admin/edition',
        element: <ProtectedRoute granted="edition" />,
        children: EditionRouting,
    },
    {
        path: '/admin',
        element: <ProtectedRoute granted="internal" />,
        children: AdminRouting,
    },
    {
        path: '/',
        element: <ProtectedRoute granted="external" />,
        children: ExternalRouting,
    },
    {
        path: '/',
        element: <PublicRoute />,
        children: SecurityRouting,
    },
    {
        path: '*',
        element: <></>,
    }
]

export default AppRouting