import React, {FC, useEffect, useState} from 'react'
import Spinner from "./Spinner";
import {useTranslation} from "react-i18next";

type LoaderProps = {
    auto?: boolean
    title?: string
    message?: string
}

const Loader: FC<LoaderProps> = ({
                                     auto= false,
    title,
    message,
}) => {
    const {t} = useTranslation()
    const [show, setShow] = useState<boolean>(false)

    useEffect(() => {
        const idTimeout = setTimeout(() => {
            setShow(true)
        }, 850)

        return () => {
            clearTimeout(idTimeout)
        }
    }, [])

    return (
        <div
            style={{
                height: auto ? 'auto' : '100%',
                width: '100%',
                display: 'grid',
                placeItems: 'center',
            }}
        >
            <div
                className="d-flex justify-content-center flex-column align-items-center"
            >
                <Spinner show={show} />
                {title && <h3 className="mt-3">{t(title)}</h3>}
                {message && <p>{t(message)}</p>}
            </div>
        </div>
    )
}

export default Loader
