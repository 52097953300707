import React, {FC, useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import Table from "Components/Layout/Table";
import PropertyGroupTable from "Table/Admin/Edition/PropertyGroupTable";
import Group from "Entity/Property/Group";
import FormRow from "Vendor/Components/Form/FormRow";
import FormModal from "Components/Modal/FormModal";
import PropertyGroupForm from "Form/Admin/Edition/PropertyGroupForm";
import DeleteModal from "Components/Modal/DeleteModal";
import FormError from "Vendor/Components/Form/FormError";
import {useOutletContext} from "react-router-dom";
import {Button} from "react-bootstrap";
import FormEmptyOffCanvas from "../../../../Components/Modal/FormEmptyOffCanvas";

const GroupPage: FC = () => {
    const { t } = useTranslation()
    const [toggleReload, setToggleReload] = useState<boolean>(true)
    const [show, setShow, search, setSearch, setHandleAdd] = useOutletContext<[boolean, (show:boolean) => void, string, (search:string) => void, (handleAdd: (() => void)|undefined) => void]>()
    const [propertyGroups, setPropertyGroups] = useState<Group[]>([])
    const [propertyGroup, setPropertyGroup] = useState<Group>({} as Group)

    useEffect(() => {
        setHandleAdd(() => {
            setPropertyGroup({} as Group)
        })
    }, [setHandleAdd])

    const handleClose = (reload = false) => {
        setShow(false)
        if (reload) {
            setToggleReload(!toggleReload)
        }
    }
    // remove
    const [showDelete, setShowDelete] = useState<boolean>(false)
    const handleDeleteClose = (reload = false) => {
        setPropertyGroup({} as Group)
        setShowDelete(false)
        if (reload) {
            setToggleReload(!toggleReload)
        }
    }
    const handleDeleteShow = () => setShowDelete(true)

    const onExited = () => {
        setPropertyGroup({} as Group)
    }

    const onSuccess = (propertyGroup: Group) => {
        setPropertyGroup(propertyGroup)
        setShow(false)
        setToggleReload(!toggleReload)
    }

    const editPropertyGroup = (propertyGroup: Group) => {
        setPropertyGroup(propertyGroup)
        setShow(true)
    }

    const deletePropertyGroup = (propertyGroup: Group) => {
        setPropertyGroup(propertyGroup)
        handleDeleteShow()
    }

    const actionsRender = (propertyGroup: Group) => (
        <>
            {propertyGroup?.isGranted?.edit &&
            <Button
                variant='outline-primary'
                size='sm'
                onClick={() => editPropertyGroup(propertyGroup)}>
                <i className="fa-solid fa-pencil me-2"></i>
                {t('app.edit')}</Button>
            }
            {propertyGroup?.isGranted?.delete &&
            <Button
                variant='outline-danger'
                size='sm'
                onClick={() => deletePropertyGroup(propertyGroup)}>
                <i className="fa-solid fa-trash me-2"></i>
                {t('app.delete')}</Button>
            }
        </>
    );

    let table = PropertyGroupTable;
    if (table.hasOwnProperty('actions')) {
        table.actions.render = actionsRender;
    }

    return (
        <>
            <Table
                path="/admin/edition/property/group"
                rows={table}
                toggleReload={toggleReload}
                items={propertyGroups}
                setItems={setPropertyGroups}
                keyword={search}
                setKeyword={setSearch}
            />
            <FormEmptyOffCanvas
                i18nTitle={propertyGroup.id ? 'app.edit_property_group' : 'app.add_property_group'}
                show={show}
                handleClose={handleClose}
                onSuccess={onSuccess}
                path={`/admin/edition/property/group/${propertyGroup.id ?? 'new'}`}
                formPath={!!propertyGroup.id}
                method={propertyGroup.id ? 'patch' : 'post'}
                onExited={onExited}
            />
            <DeleteModal
                handleClose={handleDeleteClose}
                show={showDelete}
                path={'/admin/edition/property/group/' + (propertyGroup?.id ?? 'error')}
                name={'#' + (propertyGroup ? `${propertyGroup.id} - ${propertyGroup.name}` : 'error')}
                archive={true}
            />
        </>
    )
}

export default GroupPage
