import React, {FC} from 'react'
import {useTranslation} from "react-i18next";
import {Button} from "react-bootstrap";

type GlobalErrorProps = {
    handleClick?: () => void
}

const GlobalError: FC<GlobalErrorProps> = ({handleClick}) => {
    const {t} = useTranslation()

    return (
        <div className="container">
            <div className="row justify-content-center align-items-center">
                <div className="col">
                    <div
                        className="modal position-static d-block"
                        id="loginModal"
                        aria-labelledby="loginModalLabel"
                        aria-hidden="false"
                    >
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">
                                            {t('app.error')}
                                        </h5>
                                    </div>
                                    <div className="modal-body">
                                        {t('app.error')}
                                    </div>
                                    <div className="modal-footer">
                                        <Button onClick={() => {
                                            handleClick && handleClick()
                                        }}>
                                            {t('app.reload')}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GlobalError
