import React, {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    Button, Modal,
    ModalBody as Body,
    ModalFooter as Footer,
    ModalHeader as Header,
    ModalTitle as Title
} from "react-bootstrap";
import GroupedAction from "Entity/Collection/ListingSetting/GroupedAction";
import LoadingButton from "../../Button/LoadingButton";
import {getRequestFilters, MailingActionModal} from "../Listing";
import Api from "Vendor/Api";
import Collection from "Config/Collection";
import {createArrUtils} from "../../../Vendor/Utils/CreateArrayUtils";
import TextType from "../../../Vendor/Components/Form/Type/TextType";
import FormEmpty from "../../../Vendor/Components/FormEmpty";
import Property from "../../../Entity/Property";
import ExportConfig from "../../../Entity/ExportConfig";
import EntityType, {EntityChoice} from "../../../Vendor/Components/Form/Type/EntityType";
import useTranslationDataUtils from "../../../Vendor/Utils/TranslationDataUtils";
import ChoiceType from "../../../Vendor/Components/Form/Type/ChoiceType";
import SeparatorChoice from "../../../Form/Choice/Collection/Import/Column/SeparatorChoice";
import Separator from "../../../Config/Collection/Import/Column/Separator";
import ModeChoice from "../../../Form/Choice/Collection/Export/ModeChoice";

export interface _ExportModalProps {
    collection?: Collection
    id?: number
    exports: ExportConfig[]
    showActionModals: {[key: string]: boolean|Property}
    handleShowActionModal: (groupedAction?: GroupedAction, actionFromMain?:boolean) => void
    fromMain: boolean
    handleShowMainActionModal: () => void
    selected: number[]
    actionModalFormIsLoading: boolean
    actionModalFormIsLoaded: boolean,
    setActionModalFormIsLoaded: (isLoaded: boolean) => void,
    handleActionModalOnResponse: () => void
    handleActionModalOnSuccess: () => void
    handleActionModalOnSubmit: () => void
}

const _ExportModal: FC<_ExportModalProps> = ({
                                                 collection,
                                                 id,
                                                 exports,
                                                 showActionModals,
                                                 handleShowActionModal,
                                                 fromMain,
                                                 handleShowMainActionModal,
                                                 selected,
                                                 actionModalFormIsLoading,
                                                 actionModalFormIsLoaded,
                                                 setActionModalFormIsLoaded,
                                                 handleActionModalOnResponse,
                                                 handleActionModalOnSuccess,
                                                 handleActionModalOnSubmit,
}) => {
    const {t} = useTranslation()
    const {translate} = useTranslationDataUtils()
    const [exportId, setExportId] = useState<number|undefined>(undefined)
    const [exportChoices, setExportChoices] = useState<EntityChoice[]>([])
    const [fileName, setFileName] = useState<string>('')
    const [mode, setMode] = useState<string|undefined>('excel')

    useEffect(() => {
        if (exports.length === 1) {
            setExportId(exports[0].id)
        } else if (exports.length > 1) {
            let tempExportChoices: EntityChoice[] = []
            exports.forEach(exportConfig => {
                tempExportChoices.push({
                    i18nKey: translate(exportConfig, 'name'),
                    value: exportConfig.id ?? 0
                })
            })
            setExportChoices(tempExportChoices)
        }
    }, [exports, translate])

    const onSubmit = () => {
        if (exportId && mode && collection && selected.length > 0) {
            handleActionModalOnSubmit()
            Api.post(`/grouped-action/export/${collection}${id ? '/' + id : ''}`, {
                items: selected,
                export: exportId,
                fileName: fileName,
                mode: mode,
            }, {
                responseType: 'blob'
            })
                .then((response) => {
                    let disposition = response.headers['content-disposition'];
                    // get filename from content-disposition
                    let filename = disposition?.split(';')[1].split('filename')[1].split('=')[1].trim().replace(/"/g, '');
                    // create file link in browser's memory
                    const href = URL.createObjectURL(response.data);

                    // create "a" HTML element with href to file & click
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', filename); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);

                    handleActionModalOnSuccess()
                })
                .catch(() => {
                })
                .then(() => {
                    handleActionModalOnResponse()
                    setFileName('')
                })
        }
    }

    if (exports.length === 0) return <></>

    return <Modal show={showActionModals.export !== false} onHide={handleShowActionModal}>
        <Header closeButton={!fromMain}>
            <div className="modal-block-header">
                {fromMain && <i className="fa-solid fa-angle-left" onClick={handleShowMainActionModal}></i>}
                <Title>{t(`app.groupedActionTitle.export.${collection}`, {count: selected.length})}</Title>
            </div>
        </Header>
        <Body>
            {exports.length > 1 && <EntityType choices={exportChoices}
                                               value={exportId?.toString()}
                                               setValue={value => {
                                                   let data: undefined|number = undefined
                                                   if (typeof value === 'string') {
                                                       if (!value.length) {
                                                           data = undefined
                                                       } else {
                                                           data = parseInt(value, 10)
                                                       }
                                                   } else if (value.length) {
                                                       data = parseInt(value[0], 10)
                                                   }
                                                   setExportId(data)
                                               }}
                                               i18nPlaceholder="app.select_export"
            />}
            <ChoiceType choices={ModeChoice} i18nLabel='app.export_mode' value={mode} setValue={(data) => {
                if (typeof data === 'string') { setMode(data) } else if (data.length) { setMode(data[0]) } else { setMode(undefined) }
            }} />
            <TextType required={false} fullName="fileName" id="fileName" name="fileName" value={fileName} onChange={e => setFileName(e.target.value)} i18nLabel="app.groupedActionContent.export.inputLabel" />
        </Body>
        <Footer>
            {!fromMain && <Button variant="secondary" onClick={() => {
                handleShowActionModal()
            }}>
                {t('app.cancel')}
            </Button>}
            <LoadingButton variant="primary" isLoading={actionModalFormIsLoading} onClick={onSubmit}>
                {t('app.export')}
            </LoadingButton>
        </Footer>
    </Modal>
}

export default _ExportModal
