import FormBuilderInterface from "Vendor/Definition/Form/FormBuilderInterface";
import FormInterface from "Vendor/Definition/Form/FormInterface";
import FormOptionsInterface from "Vendor/Definition/Form/FormOptionsInterface";

export default class AccountForm implements FormInterface {
    name = 'account'

    builder (builder: FormBuilderInterface, options: FormOptionsInterface) {

        builder
            .addText('email', {
                disabled: true
            })
            .addText('firstName')
            .addText('lastName')
            .addRepeat('plainPassword', {
                type: 'Password',
                defaultValue: '',
                required: false,
                first_options: {
                    i18nLabel: 'app.change_password'
                },
                second_options: {
                    i18nLabel: 'app.repeat_password'
                }
            })
    }
}
