import {RouteObject, useRoutes} from "react-router-dom";
import {FC, useContext} from "react";
import Sidebar from "Components/Layout/Sidebar";
import {UserContext} from "../Context/UserContextProvider";

type RoutingProps = {
    routes: RouteObject[]
}

const Routing: FC<RoutingProps> = ({routes}) => {
    const { currentUser } = useContext(UserContext)

    return (
        <>
            {currentUser && <Sidebar />}
            {useRoutes(routes)}
        </>
    );
}

export default Routing;