import { useEffect } from 'react'

interface UseKeyboardShortcutArgs {
    key: string
    onKeyPressed: () => void
    isEnabled: boolean
}

export const useKeyboardShortcut = ({
    key,
    onKeyPressed,
    isEnabled
}: UseKeyboardShortcutArgs) => {
    useEffect(() => {
        function keyDownHandler(e: globalThis.KeyboardEvent) {
            if (e.key === key && isEnabled) {
                e.preventDefault();
                onKeyPressed();
            }
        }

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [key, onKeyPressed, isEnabled]);
}
