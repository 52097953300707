import React, {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    Button, Modal,
    ModalBody as Body,
    ModalFooter as Footer,
    ModalHeader as Header,
    ModalTitle as Title
} from "react-bootstrap";
import GroupedAction from "Entity/Collection/ListingSetting/GroupedAction";
import LoadingButton from "../../Button/LoadingButton";
import {MailingActionModal} from "../Listing";
import Api from "Vendor/Api";
import Collection from "Config/Collection";
import Property from "../../../Entity/Property";

export interface _PublishModalProps {
    collection?: Collection
    showActionModals: {[key: string]: boolean|Property}
    handleShowActionModal: (groupedAction?: GroupedAction, actionFromMain?:boolean) => void
    fromMain: boolean
    handleShowMainActionModal: () => void
    selected: number[]
    actionModalFormIsLoading: boolean
    handleActionModalOnResponse: () => void
    handleActionModalOnSuccess: () => void
    handleActionModalOnSubmit: () => void
}

const _PublishModal: FC<_PublishModalProps> = ({
                                                 collection,
                                                 showActionModals,
                                                 handleShowActionModal,
                                                 fromMain,
                                                 handleShowMainActionModal,
                                                 selected,
                                                 actionModalFormIsLoading,
                                                 handleActionModalOnResponse,
                                                 handleActionModalOnSuccess,
                                                 handleActionModalOnSubmit,
}) => {
    const {t} = useTranslation()
    const [action, setAction] = useState<string|undefined>(undefined)

    useEffect(() => {
        let tempAction: string|undefined = undefined
        if (showActionModals.unPublish) {
            tempAction = 'unPublish'
        } else if (showActionModals.publish) {
            tempAction = 'publish'
        }
        setAction(tempAction)
   }, [showActionModals])

    const onSubmit = () => {
        handleActionModalOnSubmit()
        Api.post('/grouped-action/change-status', {
            listing: collection,
            selected: selected,
            status: action === 'unPublish' ? 'unpublished' : 'published',
        })
            .then((response ) => {
                handleActionModalOnSuccess()
            })
            .catch(() => {
            })
            .then(() => {
                handleActionModalOnResponse()
            })
    }

    return <Modal show={action !== undefined} onHide={handleShowActionModal}>
        <Header closeButton={!fromMain}>
            <div className="modal-block-header">
                {fromMain && <i className="fa-solid fa-angle-left" onClick={handleShowMainActionModal}></i>}
                <Title>{t(`app.groupedActionTitle.${action}.${collection}`, {count: selected.length})}</Title>
            </div>
        </Header>
        <Body>
            {t(`app.groupedActionContent.${action}.${collection}`, {count: selected.length})}
        </Body>
        <Footer>
            {!fromMain && <Button variant="secondary" onClick={() => {
                handleShowActionModal()
            }}>
                {t('app.cancel')}
            </Button>}
            <LoadingButton variant={action === 'unPublish' ? 'danger' : 'success'} isLoading={actionModalFormIsLoading} onClick={onSubmit}>
                {t(`app.${action}`)}
            </LoadingButton>
        </Footer>
    </Modal>
}

export default _PublishModal
