import React, {FC, useState} from 'react'
import {useTranslation} from "react-i18next";
import Header from "Components/Layout/Header";
import Table from "Components/Layout/Table";
import UserTable from "../../Table/Admin/UserTable";
import User from "../../Entity/User";
import DeleteModal from "../../Components/Modal/DeleteModal";
import FormEmptyOffCanvas from "../../Components/Modal/FormEmptyOffCanvas";

const UserPage: FC = () => {
    const { t } = useTranslation()

    const [toggleReload, setToggleReload] = useState<boolean>(true)
    const [show, setShow] = useState<boolean>(false)
    const [keyword, setKeyword] = useState<string>('')
    const [users, setUsers] = useState<User[]>([])
    const [user, setUser] = useState<User>({} as User)
    const handleClose = (reload = false) => {
        setShow(false)
        if (reload === true) {
            setToggleReload(!toggleReload)
        }
    }
    const handleShow = () => setShow(true)
    // remove
    const [showDelete, setShowDelete] = useState<boolean>(false)
    const handleDeleteClose = (reload = false) => {
        setUser({} as User)
        setShowDelete(false)
        if (reload) {
            setToggleReload(!toggleReload)
        }
    }
    const handleDeleteShow = () => setShowDelete(true)

    const onSuccess = (user: User) => {
        setUser(user)
        setShow(false)
        setToggleReload(!toggleReload)
    }

    const addUser = () => {
        setUser({} as User)
        handleShow()
    }

    const editUser = (user: User) => {
        setUser(user)
        handleShow()
    }

    const deleteUser = (user: User) => {
        setUser(user)
        handleDeleteShow()
    }

    const actionsRender = (user: User) => (
        <>
            {user?.isGranted?.edit &&
                <button
                    className="btn btn-sm btn-outline-primary"
                    onClick={() => editUser(user)}
                >
                    <i className="fa-solid fa-pencil me-2"></i>
                    {t('app.edit')}
                </button>
            }
            {user?.isGranted?.delete &&
                <button
                    className="btn btn-sm btn-outline-danger"
                    onClick={() => {
                        deleteUser(user)
                    }}
                >
                    <i className="fa-solid fa-trash me-2"></i>
                    {t('app.delete')}
                </button>
            }
        </>
    );

    const UserName = ({ user }: { user: User }) => {
        return user.firstName || user.lastName ? (
            `${user.firstName} ${user.lastName}`
        ) : (
            <i>{t('app.undefined')}</i>
        )
    }

    const nameRender = (user: User) => (
        <>
            {UserName({ user: user })}
            {!user.active && (
                <span className="badge bg-info ms-1">{t('app.waiting_validation')}</span>
            )}
        </>
    );

    const roleRender = (user: User) => t('app.roles.' + user.role)

    let table = UserTable;
    if (table.hasOwnProperty('actions')) {
        table.actions.render = actionsRender
    }
    if (table.hasOwnProperty('name')) {
        table.name.render = nameRender
    }
    if (table.hasOwnProperty('role')) {
        table.role.render = roleRender
    }

    return (
        <>
            <Header title={t('app.users')}
                    add={addUser}
                    search={keyword}
                    setSearch={setKeyword}
            />
            <Table
                path="/admin/user"
                rows={table}
                toggleReload={toggleReload}
                items={users}
                setItems={setUsers}
                keyword={keyword}
                setKeyword={setKeyword}
            />
            <FormEmptyOffCanvas
                i18nTitle={user.id ? 'app.edit_user' : 'app.add_user'}
                show={show}
                handleClose={handleClose}
                onSuccess={onSuccess}
                path={`/admin/user/${user.id ?? 'new'}`}
                formPath={user.id ? true : false}
                method={user.id ? 'patch' : 'post'}
            />
            <DeleteModal
                handleClose={handleDeleteClose}
                show={showDelete}
                path={'/admin/user/' + (user?.id ?? 'error')}
                name={'#' + (user ? `${user.id} - ${user.email}` : 'error')}
                archive={true}
            />
        </>
    )
}

export default UserPage
