import React, {FC, useContext, useEffect, useState} from 'react'
import {Link, useLocation, useNavigate, useOutletContext, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Bucket from "../../../../Entity/Bucket";
import BlankPage from "../../../../Components/Layout/BlankPage";
import LoadingButton from "../../../../Components/Button/LoadingButton";
import {BannerContext} from "../../../../Vendor/Context/BannerContextProvider";
import {mimeTypeImage} from "Vendor/UploadSystem";
import FormEmpty from "../../../../Vendor/Components/FormEmpty";

const MainPage: FC = () => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [bucket, setBucket] = useOutletContext<[Bucket, (bucket: Bucket) => void]>()
    const [success, setSuccess] = useState<boolean>(false)
    const { setBanner } = useContext(BannerContext);

    const onSubmit = () => {
        setIsLoading(true)
    }
    const onResponse = () => {
        setIsLoading(false)
    }
    const onSuccess = () => {
        setSuccess(true)
        setBanner({type: 'success', i18nTitle: 'app.save', i18nMessage: 'app.data_saved_message'})
    }
    const onError = () => {
        setSuccess(false)
    }

    return (
        <BlankPage>
            <FormEmpty
                path={'/admin/edition/bucket/' + bucket.id}
                formPath={!!bucket.id}
                method="patch"
                children={<div className="row justify-content-end align-items-center">
                    <div className="col-auto">
                        <LoadingButton type="submit" isLoading={isLoading}>
                            {t( 'app.validate' )}
                        </LoadingButton>
                    </div>
                </div>}
                onSubmit={onSubmit}
                onResponse={onResponse}
                onSuccess={onSuccess}
                onError={onError}
                />
        </BlankPage>
    )
}

export default MainPage
