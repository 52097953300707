import React, { FC, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Api from "Vendor/Api";
import {BannerContext} from "../../../Vendor/Context/BannerContextProvider";
import LoadingButton from "../../../Components/Button/LoadingButton";

const ResetPage: FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const { token } = useParams()
    const { setBanner } = useContext(BannerContext);

    const [isLoading, setIsLoading] = useState(false)
    const [password, setPassword] = useState('')
    const [hiddenToken, setHiddenToken] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')

    React.useEffect(() => {
        token && navigate('/reset-password/reset/', { state: token })
        location.state && setHiddenToken(location.state as string)
    }, [location, navigate, token])

    const onSubmit = () => {
        setIsLoading(true)
        Api.post('reset-password/reset/' + hiddenToken, { plainPassword: password })
            .then(({ data }) => {
                setIsLoading(false)
                setBanner({type: 'success', i18nMessage: 'app.password_reset'})
                navigate('/login')
            })
            .catch((err) => {
                setIsLoading(false)
                setBanner({type: 'error', i18nMessage: err?.response?.data?.message ?? 'error.server'})
            })
    }

    return (
        <form
            name="change_password_form"
            method="post"
            onSubmit={(e) => e.preventDefault()}
        >
            <div className="modal-header">
                <h5 className="modal-title" id="loginModalLabel">
                    {t('app.reset_your_password')}
                </h5>
            </div>
            <div className="modal-body">
                <div className="form-floating mb-3">
                    <input
                        type="password"
                        id="change_password_form_plainPassword_first"
                        name="change_password_form[plainPassword][first]"
                        autoComplete="new-password"
                        placeholder={t('app.new_password')}
                        className="form-control"
                        aria-autocomplete="list"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        required
                    />
                    <label
                        htmlFor="change_password_form_plainPassword_first"
                        className="form-label required"
                    >
                        {t('app.new_password')}
                    </label>
                </div>
                <div className="form-floating mb-3">
                    <input
                        type="password"
                        id="change_password_form_plainPassword_second"
                        name="change_password_form[plainPassword][second]"
                        autoComplete="new-password"
                        placeholder={t('app.repeat_password')}
                        className="form-control"
                        onChange={(e) => setRepeatPassword(e.target.value)}
                        value={repeatPassword}
                        required
                    />
                    <label
                        htmlFor="change_password_form_plainPassword_second"
                        className="form-label required"
                    >
                        {t('app.repeat_password')}
                    </label>
                </div>
            </div>
            <div className="modal-footer">
                <LoadingButton
                    type="submit"
                    disabled={password !== repeatPassword}
                    isLoading={isLoading}
                    onClick={onSubmit}
                >
                    {t('app.reset_password')}
                </LoadingButton>
            </div>
        </form>
    )
}

export default ResetPage
