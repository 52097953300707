import React, {FC, useContext, useState} from 'react'
import {useTranslation} from "react-i18next";
import Header from "Components/Layout/Header";
import Table from "Components/Layout/Table";
import DeleteModal from "Components/Modal/DeleteModal";
import NotificationTable from "Table/Admin/Edition/NotificationTable";
import {Button} from "react-bootstrap";
import useTranslationDataUtils from "../../../../Vendor/Utils/TranslationDataUtils";
import Api from "../../../../Vendor/Api";
import {UserContext} from "../../../../Vendor/Context/UserContextProvider";
import RefreshModal from "../../../../Components/Modal/RefreshModal";
import FormEmptyModal from "../../../../Components/Modal/FormEmptyModal";

import CollectionEvent from "../../../../Entity/Notification/Collection/CollectionEvent";
import CollectionEventOffCanvas from "../../../../Components/Offcanvas/Notification/CollectionEventOffCanvas";

const NotificationCollectionPage: FC = () => {
    const { currentUser } = useContext(UserContext)
    const { t } = useTranslation()
    const {translate} = useTranslationDataUtils()
    const [toggleReload, setToggleReload] = useState<string>('')
    const [modalShow, setModalShow] = useState<boolean>(false)
    const [offCanvasShow, setOffCanvasShow] = useState<boolean>(false)
    const [search, setSearch] = useState<string>('')
    const [collectionEvents, setCollectionEvents] = useState<CollectionEvent[]>([])
    const [collectionEvent, setCollectionEvent] = useState<CollectionEvent|undefined>(undefined)
    const [showRefreshModal, setShowRefreshModal] = useState<boolean>(false)
    const [hasPlatform, setHasPlatform] = useState<boolean>(currentUser?.currentOrganization?.platforms
        ? currentUser?.currentOrganization?.platforms?.length > 0
        : false)
    const handleModalClose = (reload = false) => {
        setModalShow(false)
        if (reload) {
            setToggleReload(new Date().toISOString())
        }
    }
    const handleModalShow = () => setModalShow(true)
    const handleOffCanvasShow = () => setOffCanvasShow(true)
    // remove
    const [showDelete, setShowDelete] = useState<boolean>(false)
    const handleDeleteClose = (reload = false) => {
        setCollectionEvent(undefined)
        setShowDelete(false)
        if (reload) {
            setToggleReload(new Date().toISOString())
        }
    }
    const handleDeleteShow = () => setShowDelete(true)

    const onSuccess = (collectionEvent: CollectionEvent) => {
        setCollectionEvent(collectionEvent)
        setModalShow(false)
        setToggleReload(new Date().toISOString())
    }

    const addCollectionEvent = () => {
        setCollectionEvent(undefined)
        handleModalShow()
    }

    const editCollectionEvent = (collectionEvent: CollectionEvent) => {
        setCollectionEvent(collectionEvent)
        handleOffCanvasShow()
    }

    const deleteCollectionEvent = (collectionEvent: CollectionEvent) => {
        setCollectionEvent(collectionEvent)
        handleDeleteShow()
    }

    const handlePublish = (collectionEvent: CollectionEvent) => {
        setShowRefreshModal(true)
        Api.patch(`/admin/edition/notification/collection/${collectionEvent.id}/change-status/online`)
            .then(() => {})
            .catch(() => {})
            .then(() => {
                setToggleReload(new Date().toISOString())
                setShowRefreshModal(false)
            })
    }

    const handleUnPublish = (collectionEvent: CollectionEvent) => {
        setShowRefreshModal(true)
        Api.patch(`/admin/edition/notification/collection/${collectionEvent.id}/change-status/offline`)
            .then(() => {})
            .catch(() => {})
            .then(() => {
                setToggleReload(new Date().toISOString())
                setShowRefreshModal(false)
            })
    }

    const actionsRender = (collectionEvent: CollectionEvent) => (
        <>
            {collectionEvent?.isGranted?.edit &&
                <Button
                    variant='outline-primary'
                    size='sm'
                    onClick={() => editCollectionEvent(collectionEvent)}>
                    <i className="fa-solid fa-eye me-2"></i>
                    {t('app.show')}</Button>
            }
            {collectionEvent?.isGranted?.publish && hasPlatform &&
                <Button
                    variant='outline-success'
                    size='sm'
                    onClick={() => handlePublish(collectionEvent)}>
                    <i className="fa-solid fa-check me-2"></i>
                    {t('app.publish')}</Button>
            }
            {collectionEvent?.isGranted?.unPublish && hasPlatform &&
                <Button
                    variant='outline-danger'
                    size='sm'
                    onClick={() => handleUnPublish(collectionEvent)}>
                    <i className="fa-solid fa-times me-2"></i>
                    {t('app.unPublish')}</Button>
            }
            {collectionEvent?.isGranted?.delete &&
                <Button
                    variant='outline-danger'
                    size='sm'
                    onClick={() => deleteCollectionEvent(collectionEvent)}>
                    <i className="fa-solid fa-trash me-2"></i>
                    {t('app.delete')}</Button>
            }
        </>
    );

    const renderTarget = (collectionEvent: CollectionEvent) => {
        if (!collectionEvent.collection) {
            return ''
        }

        let result: string = t('app.enum.property.collection.' + collectionEvent.collection.charAt(0).toUpperCase() + collectionEvent.collection.slice(1).replace("-", ""))
        if (collectionEvent.collection === 'bucket' && collectionEvent.entity) {
            result += ' (' + translate(collectionEvent.entity, 'name') + ')'
        }

        return result
    }

    const renderEvent = (collectionEvent: CollectionEvent) => {
        let result: string = t('app.enum.collection.event.' + collectionEvent.collectionEvent)
        if (collectionEvent.collectionEvent === 'Date') {
            if (collectionEvent.property) {
                result += ' (' + translate(collectionEvent.property, 'label') + ')'
            }
            if (collectionEvent.shoppingDateType) {
                result += ' (' + t('app.enum.shopping.event.shoppingDateType.' + collectionEvent.shoppingDateType) + ')'
            }
        }

        return result
    }

    let table = NotificationTable;
    if (table.hasOwnProperty('target')) {
        table.target.render = (collectionEvent: CollectionEvent) => renderTarget(collectionEvent)
    }
    if (table.hasOwnProperty('event')) {
        table.event.render = (collectionEvent: CollectionEvent) => renderEvent(collectionEvent)
    }
    if (table.hasOwnProperty('actions')) {
        table.actions.render = actionsRender;
    }

    return (
        <>
            <Header title={t('app.notification_types.collection')}
                    add={addCollectionEvent}
                    search={search}
                    setSearch={setSearch}
            />
            <Table
                path="/admin/edition/notification/collection"
                rows={table}
                toggleReload={toggleReload}
                items={collectionEvents}
                setItems={setCollectionEvents}
                keyword={search}
                setKeyword={setSearch}
            />
            <CollectionEventOffCanvas
                show={offCanvasShow}
                handleClose={() => setOffCanvasShow(false)}
                id={collectionEvent?.id}
                setInfoShow={setModalShow}
                setToggleReload={setToggleReload}
            />
            <FormEmptyModal
                i18nTitle={collectionEvent?.id ? 'app.edit_event' : 'app.add_event'}
                show={modalShow}
                handleClose={handleModalClose}
                onSuccess={onSuccess}
                path={`/admin/edition/notification/collection/${collectionEvent?.id ?? 'new'}`}
                formPath={collectionEvent?.id !== undefined ? true : false}
                method={collectionEvent?.id !== undefined ? 'patch' : 'post'}
            />
            <DeleteModal
                handleClose={handleDeleteClose}
                show={showDelete}
                path={'/admin/edition/notification/collection/' + (collectionEvent?.id ?? 'error')}
                name={'#' + (collectionEvent ? `${collectionEvent.id} - ${collectionEvent.translations}` : 'error')}
            />
            <RefreshModal show={showRefreshModal} />
        </>
    )
}

export default NotificationCollectionPage
