import React, { FC, ReactNode, useState } from 'react';
import EntityInterface from 'Vendor/Definition/EntityInterface';


type ListingContextProviderProps = {
    children: ReactNode
}

export interface ListingItems {
    [key: number]: EntityInterface
}

export type ListingState = {
    items: ListingItems,
    setItems: React.Dispatch<React.SetStateAction<ListingItems>>,
    currentPage: number,
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>,
    pages: number[],
    setPages: React.Dispatch<React.SetStateAction<number[]>>
}

export type ListingContextType = {
    default: ListingState,
    modal: ListingState
}

export const ListingContext = React.createContext<ListingContextType>({
    default: {
        items: {},
        setItems: () => { },
        currentPage: 1,
        setCurrentPage: () => { },
        pages: [],
        setPages: () => { }
    },
    modal: {
        items: {},
        setItems: () => { },
        currentPage: 1,
        setCurrentPage: () => { },
        pages: [],
        setPages: () => { }
    }
})

const ListingContextProvider: FC<ListingContextProviderProps> = ({ children }) => {
    const [defaultItems, setDefaultItems] = useState<ListingItems>({});
    const [defaultCurrentPage, setDefaultCurrentPage] = useState<number>(1);
    const [defaultPages, setDefaultPages] = useState<number[]>([]);

    const [modalItems, setModalItems] = useState<ListingItems>({});
    const [modalCurrentPage, setModalCurrentPage] = useState<number>(1);
    const [modalPages, setModalPages] = useState<number[]>([]);

    return (
        <ListingContext.Provider value={{
            default: {
                items: defaultItems,
                setItems: setDefaultItems,
                currentPage: defaultCurrentPage,
                setCurrentPage: setDefaultCurrentPage,
                pages: defaultPages,
                setPages: setDefaultPages
            },
            modal: {
                items: modalItems,
                setItems: setModalItems,
                currentPage: modalCurrentPage,
                setCurrentPage: setModalCurrentPage,
                pages: modalPages,
                setPages: setModalPages
            }
        }}>
            {children}
        </ListingContext.Provider>
    )
}

export default ListingContextProvider;
