import React, {FC, MutableRefObject} from 'react'
import { classUtils as c } from 'Vendor/Utils/ClassUtils'

const Spinner: FC<{
    show?: boolean,
    loader?: MutableRefObject<null>,
}> = ({show = true, loader,}) =>
    <div className={c('spinner-border spinner-grow-sm p-3', !show && 'd-none')} role="status" ref={loader}>
        <span className="visually-hidden">Loading...</span>
    </div>

export default Spinner
