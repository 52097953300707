import React, {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    Button, Modal,
    ModalBody as Body,
    ModalFooter as Footer,
    ModalHeader as Header,
    ModalTitle as Title
} from "react-bootstrap";
import GroupedAction from "Entity/Collection/ListingSetting/GroupedAction";
import LoadingButton from "../../Button/LoadingButton";
import {getRequestFilters, MailingActionModal} from "../Listing";
import Api from "Vendor/Api";
import Collection from "Config/Collection";
import {createArrUtils} from "../../../Vendor/Utils/CreateArrayUtils";
import TextType from "../../../Vendor/Components/Form/Type/TextType";
import FormEmpty from "../../../Vendor/Components/FormEmpty";
import useTranslationDataUtils from "../../../Vendor/Utils/TranslationDataUtils";
import Property from "../../../Entity/Property";

export interface _PropertyModalProps {
    collection?: Collection
    id?: number
    showActionModals: {[key: string]: boolean|Property}
    handleShowActionModal: (groupedAction?: GroupedAction, actionFromMain?:boolean) => void
    fromMain: boolean
    handleShowMainActionModal: () => void
    selected: number[]
    actionModalFormIsLoading: boolean
    actionModalFormIsLoaded: boolean,
    setActionModalFormIsLoaded: (isLoaded: boolean) => void,
    handleActionModalOnResponse: () => void
    handleActionModalOnSuccess: () => void
    handleActionModalOnSubmit: () => void
}

const _PropertyModal: FC<_PropertyModalProps> = ({
                                                 collection,
                                                 id,
                                                 showActionModals,
                                                 handleShowActionModal,
                                                 fromMain,
                                                 handleShowMainActionModal,
                                                 selected,
                                                 actionModalFormIsLoading,
                                                 actionModalFormIsLoaded,
                                                 setActionModalFormIsLoaded,
                                                 handleActionModalOnResponse,
                                                 handleActionModalOnSuccess,
                                                 handleActionModalOnSubmit,
}) => {
    const {t} = useTranslation()
    const {translate} = useTranslationDataUtils()

    return <Modal show={showActionModals.property !== false} onHide={handleShowActionModal}>
        <Header closeButton={!fromMain}>
            <div className="modal-block-header">
                {fromMain && <i className="fa-solid fa-angle-left" onClick={handleShowMainActionModal}></i>}
                <Title>{t(`app.groupedActionTitle.property.${collection}`, {count: selected.length})} : {translate(typeof showActionModals.property === 'object' ? showActionModals.property : {}, 'label')}</Title>
            </div>
        </Header>
        <Body>
            <FormEmpty
                path={
                    id ? `/grouped-action/property/${collection}/${id}/${typeof showActionModals.property === 'object' ? showActionModals.property.id : 0}`
                        : `/grouped-action/property/${collection}/${typeof showActionModals.property === 'object' ? showActionModals.property.id : 0}`
            }
                additionalData={{
                    items: selected,
                }}
                setIsLoaded={setActionModalFormIsLoaded}
                config={{
                    responseType: 'blob'
                }}
                onResponse={handleActionModalOnResponse}
                onSubmit={handleActionModalOnSubmit}
                onSuccess={handleActionModalOnSuccess}
            >
                <div className="row justify-content-end align-items-center mb-3">
                    <div className="col-auto">
                        {!fromMain && <Button variant="secondary" onClick={() => {
                            handleShowActionModal()
                        }}>
                            {t('app.cancel')}
                        </Button>}
                        <LoadingButton type="submit" isLoading={actionModalFormIsLoading} disabled={!actionModalFormIsLoaded}>
                            {t('app.edit')}
                        </LoadingButton>
                    </div>
                </div>
            </FormEmpty>
        </Body>
    </Modal>
}

export default _PropertyModal
