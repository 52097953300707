import React, {FC, MutableRefObject, ReactNode, useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import useTranslationDataUtils from "../../../Vendor/Utils/TranslationDataUtils";
import {UserContext} from "../../../Vendor/Context/UserContextProvider";
import EntityInterface from "Vendor/Definition/EntityInterface";
import Spinner from "../../Spinner";
import _Card from "./_Card";
import Collection from "Config/Collection";
import { classUtils as c } from 'Vendor/Utils/ClassUtils'
import {ListingItems, PaginationMode} from "../Listing";
import Property from "../../../Entity/Property";
import ListingSetting from "../../../Entity/Collection/ListingSetting";

export interface _CardGroupProps {
    entity?: Collection
    isLoaded: boolean
    onError: boolean
    items: ListingItems
    editAction?: (item: EntityInterface) => void
    selectable: boolean
    selected: number[]
    setSelected: (selected: number[]) => void
    selectMultiple: boolean
    condensed: boolean
    setThumbnailModalProps: (thumbnailPath: ThumbnailModalProps) => void
    hideDownloadButton?: boolean
    paginationMode: PaginationMode
    loader: MutableRefObject<null>
    setting: ListingSetting
}

export interface ThumbnailModalProps {
    path: string
    name: string
    mimeType: string
}

const _CardGroup: FC<_CardGroupProps> = ({entity,
                                             isLoaded,
                                             onError,
                                             items,
                                             editAction,
                                             selected,
                                             setSelected,
                                             selectable,
                                             selectMultiple,
                                             condensed,
                                             setThumbnailModalProps,
                                             hideDownloadButton,
                                             paginationMode,
                                             loader,
                                             setting
}) => {
    return (
        <>
            <div className={c('row card-list', condensed && 'card-list-condensed', !isLoaded && 'justify-content-center', selectable && 'card-list-selectable')} key={0}>
                {(isLoaded || (paginationMode === 'infinite-scroll' && Object.keys(items).length > 0)) && <>
                    {Object.values(items).map((item, key) => (
                        <_Card
                            key={key}
                            item={item}
                            entity={entity}
                            setThumbnailModalProps={setThumbnailModalProps}
                            selected={selected}
                            selectable={selectable}
                            setSelected={setSelected}
                            selectMultiple={selectMultiple}
                            condensed={condensed}
                            hideDownloadButton={hideDownloadButton}
                            editAction={editAction}
                            setting={setting}
                        />
                    ))}
                </>}
                {!isLoaded ?
                    <Spinner />
                    : (paginationMode === 'infinite-scroll'
                        && <div ref={loader} />
                    )
                }
            </div>
        </>
    )
}

export default _CardGroup