import React, {FC, useContext, useState} from 'react'
import {useOutletContext} from 'react-router-dom'
import {useTranslation} from "react-i18next";
import Table from "Components/Layout/Table";
import UserTable from "../../../Table/Admin/Edition/UserTable";
import User from "../../../Entity/User";
import DeleteModal from "../../../Components/Modal/DeleteModal";
import {Button} from "react-bootstrap";
import Api from "Vendor/Api";
import {UserContext} from "../../../Vendor/Context/UserContextProvider";
import FormEmptyOffCanvas from "../../../Components/Modal/FormEmptyOffCanvas";
import LoadingButton from "../../../Components/Button/LoadingButton";

const UserPage: FC = () => {
    const { t } = useTranslation()
    const { setCurrentUser, setInit } = useContext(UserContext)
    const [toggleReload, setToggleReload] = useState<boolean>(true)
    const [show, setShow, search, setSearch] = useOutletContext<[boolean, (show:boolean) => void, string, (search:string) => void]>()
    const [users, setUsers] = useState<User[]>([])
    const [user, setUser] = useState<User>({} as User)
    const [loadingMail, setLoadingMail] = useState<number | null>(null)
    const [successMail, setSuccessMail] = useState<number | null>(null)
    const [loadingResetPassword, setLoadingResetPassword] = useState<number | null>(null)
    const [successResetPassword, setSuccessResetPassword] = useState<number | null>(null)

    const handleClose = (reload = false) => {
        setShow(false)
        if (reload) {
            setToggleReload(!toggleReload)
        }
    }
    const handleShow = () => setShow(true)
    // remove
    const [showDelete, setShowDelete] = useState<boolean>(false)
    const handleDeleteClose = (reload = false) => {
        setUser({} as User)
        setShowDelete(false)
        if (reload) {
            setToggleReload(!toggleReload)
        }
    }
    const handleDeleteShow = () => setShowDelete(true)

    const onSuccess = (user: User) => {
        setUser(user)
        setShow(false)
        setToggleReload(!toggleReload)
    }

    const onExited = () => {
        setUser({} as User)
    }

    const editUser = (user: User) => {
        setUser(user)
        handleShow()
    }

    const sendRegisterMail = (user: User) => {
        setLoadingMail(user?.id ?? null)
        Api.patch(`/admin/edition/user/${user.id}/send-register-mail`)
            .then(() => {
                setSuccessMail(user?.id ?? null)
            })
            .catch(() => {})
            .then(() => {
                setLoadingMail(null)
            })
    }

    const sendResetPassword = (user: User) => {
        setLoadingResetPassword(user?.id ?? null)
        Api.patch(`/admin/edition/user/${user.id}/send-reset-password`)
            .then(() => {
                setSuccessResetPassword(user?.id ?? null)
            })
            .catch(() => {})
            .then(() => {
                setLoadingResetPassword(null)
            })
    }

    const deleteUser = (user: User) => {
        setUser(user)
        handleDeleteShow()
    }

    const handleSwitch = (user: User) => {
        setInit(false)
        Api.get(`/switch-user?_switch_user=${encodeURIComponent(user.email)}`)
            .then(({ data }) => {
                setCurrentUser(data)
            })
            .catch((err) => {
            })
            .then(() => {
                setInit(true)
            })
    }

    const UserName = ({ user }: { user: User }) => {
        return user.firstName || user.lastName ? (
            `${user.firstName} ${user.lastName}`
        ) : (
            <i>{t('app.undefined')}</i>
        )
    }

    const nameRender = (user: User) => (
        <>
            {UserName({ user: user })}
            {!user.active && (
                <span className="badge bg-info ms-1">
                    {t('app.waiting_validation')}
                </span>
            )}
            {user.administrator && (
                <span className="badge bg-warning ms-1">
                    {t('app.administrator')}
                </span>
            )}
        </>
    )

    const actionsRender = (user: User) => {
        return (
            <>
                {user.isGranted?.CAN_SWITCH_USER && <Button
                    variant='primary' size='sm'
                    onClick={() => handleSwitch(user)}
                ><i className="fa-solid fa-person-to-portal"></i></Button>}
                {user.isGranted?.send_register_mail && <LoadingButton
                    isLoading={loadingMail === user.id}
                    success={successMail === user.id}
                    setSuccess={() => setSuccessMail(null)}
                    variant='outline-primary'
                    size='sm'
                    onClick={() => sendRegisterMail(user)}>
                    <i className="fa-solid fa-envelope me-2"></i>
                    {t('app.send_register_mail')}</LoadingButton>}
                {user.isGranted?.edit && <LoadingButton
                    isLoading={loadingResetPassword === user.id}
                    success={successResetPassword === user.id}
                    setSuccess={() => setSuccessResetPassword(null)}
                    variant='outline-primary'
                    size='sm'
                    onClick={() => sendResetPassword(user)}>
                    <i className="fa-solid fa-lock me-2"></i>
                    {t('app.send_reset_password')}</LoadingButton>}
                {user.isGranted?.edit && <Button
                    variant='outline-primary'
                    size='sm'
                    onClick={() => editUser(user)}>
                    <i className="fa-solid fa-pencil me-2"></i>
                    {t('app.edit')}</Button>}
                {user.isGranted?.delete && <Button
                    variant='outline-danger'
                    size='sm'
                    onClick={() => deleteUser(user)}>
                    <i className="fa-solid fa-trash me-2"></i>
                    {t('app.delete')}</Button>}
            </>
        );
    }

    let table = UserTable;
    if (table.hasOwnProperty('name')) {
        table.name.render = nameRender;
    }
    if (table.hasOwnProperty('actions')) {
        table.actions.render = actionsRender;
    }

    return (
        <>
            <Table
                path="/admin/edition/user"
                rows={table}
                toggleReload={toggleReload}
                items={users}
                setItems={setUsers}
                keyword={search}
                setKeyword={setSearch}
            />
            <FormEmptyOffCanvas
                i18nTitle={user.id ? 'app.edit_user' : 'app.add_user'}
                show={show}
                handleClose={handleClose}
                onSuccess={onSuccess}
                path={`/admin/edition/user/${user.id ?? 'new'}`}
                formPath={!!user.id}
                method={user.id ? 'patch' : 'post'}
                onExited={onExited}
            />
            <DeleteModal
                handleClose={handleDeleteClose}
                show={showDelete}
                path={'/admin/edition/user/' + (user?.id ?? 'error')}
                name={'#' + (user ? `${user.id} - ${user.email}` : 'error')}
                archive={true}
            />
        </>
    )
}

export default UserPage
