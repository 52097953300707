import {useTranslation} from "react-i18next";
import {useCallback, useContext} from "react";
import {DataType} from "Vendor/Components/Form";
import {UserContext} from "Vendor/Context/UserContextProvider";
import {defaultLanguage as GlobalDefaultLanguage} from "./I18nUtils";
import {TranslationField} from "Components/Listing/Listing";

export default function useTranslationDataUtils () {
    const { i18n } = useTranslation()
    const { currentUser } = useContext(UserContext)
    const currentLanguage = i18n.language
    const defaultLanguage = currentUser?.currentOrganization?.defaultLanguage ?? GlobalDefaultLanguage

    const translate = useCallback( (data: DataType, key: string, defaultLang = false): string => {
        if (!defaultLang && data.hasOwnProperty('translations') && data.translations && data.translations.hasOwnProperty(currentLanguage) && data.translations[currentLanguage].hasOwnProperty(key)) {
            return data.translations[currentLanguage][key];
        }

        if (data.hasOwnProperty('translations') && data.translations && data.translations.hasOwnProperty(defaultLanguage) && data.translations[defaultLanguage].hasOwnProperty(key)) {
            return data.translations[defaultLanguage][key];
        }

        if (defaultLanguage !== GlobalDefaultLanguage && data.hasOwnProperty('translations') && data.translations.hasOwnProperty(GlobalDefaultLanguage) && data.translations[GlobalDefaultLanguage].hasOwnProperty(key)) {
            return data.translations[GlobalDefaultLanguage][key];
        }

        return '--unknown--'
    }, [currentLanguage, defaultLanguage])

    const translateField = useCallback((field: TranslationField, key?: string|Array<string>): string => {
        if (field === undefined) {
            return '--unknown--'
        }
        if (typeof key !== 'undefined') {

            let localeField: {[key: string]: string}|undefined = undefined
            if (field.hasOwnProperty(currentLanguage) && typeof field[currentLanguage] === 'object' && field[currentLanguage]) {
                localeField = field[currentLanguage] as {[key: string]: string}
                if (typeof key === "string") {
                    if (!localeField.hasOwnProperty(key)) {
                        localeField = undefined
                    }
                } else {
                    if (key.every(testKey => localeField !== undefined && !localeField.hasOwnProperty(testKey))) {
                        localeField = undefined
                    }
                }
            }

            if (!localeField && field.hasOwnProperty(defaultLanguage) && typeof field[defaultLanguage] === 'object') {
                localeField = field[defaultLanguage] as {[key: string]: string}
                if (typeof key === "string") {
                    if (!localeField.hasOwnProperty(key)) {
                        localeField = undefined
                    }
                } else {
                    if (key.every(testKey => localeField !== undefined && !localeField.hasOwnProperty(testKey))) {
                        localeField = undefined
                    }
                }
            }

            if (!localeField && defaultLanguage !== GlobalDefaultLanguage && field.hasOwnProperty(GlobalDefaultLanguage) && typeof field[GlobalDefaultLanguage] === 'object') {
                localeField = field[GlobalDefaultLanguage] as {[key: string]: string}
                if (typeof key === "string") {
                    if (!localeField.hasOwnProperty(key)) {
                        localeField = undefined
                    }
                } else {
                    if (key.every(testKey => localeField !== undefined && !localeField.hasOwnProperty(testKey))) {
                        localeField = undefined
                    }
                }
            }

            if (localeField) {
                if (typeof key === "string") {
                    return localeField[key]
                } else {
                    if (key.every(testKey => localeField !== undefined && !localeField.hasOwnProperty(testKey))) {
                        localeField = undefined
                    }
                    let value: string|undefined = undefined
                    key.forEach(testKey => {
                        if (value === undefined && localeField && localeField.hasOwnProperty(testKey)) {
                            value = localeField[testKey]
                        }
                    })

                    return value ?? '--Error--'
                }
            }
        }

        if (field.hasOwnProperty(currentLanguage) && typeof field[currentLanguage] === 'string') {
            return field[currentLanguage] as string
        }

        if (field.hasOwnProperty(defaultLanguage) && typeof field[defaultLanguage] === 'string') {
            return field[defaultLanguage] as string
        }

        if (defaultLanguage !== GlobalDefaultLanguage && field.hasOwnProperty(GlobalDefaultLanguage) && typeof field[GlobalDefaultLanguage] === 'string') {
            return field[GlobalDefaultLanguage] as string
        }

        return '--unknown--'
    }, [currentLanguage, defaultLanguage])

    return {translate, translateField}
}
