import React, {FC, useContext, useState} from 'react'
import {useOutletContext} from "react-router-dom";
import {useTranslation} from "react-i18next";
import CatalogForm from "../../../../Form/Admin/Edition/CatalogForm";
import Catalog from "../../../../Entity/Catalog";
import FormError from "../../../../Vendor/Components/Form/FormError";
import FormRow from "../../../../Vendor/Components/Form/FormRow";
import Form from "../../../../Components/Utils/Form";
import BlankPage from "../../../../Components/Layout/BlankPage";
import LoadingButton from "../../../../Components/Button/LoadingButton";
import {BannerContext} from "../../../../Vendor/Context/BannerContextProvider";
import FormEmpty from "../../../../Vendor/Components/FormEmpty";

const MainPage: FC = () => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [catalog, setCatalog] = useOutletContext<[Catalog, (catalog: Catalog) => void]>()
    const [success, setSuccess] = useState<boolean>(false)
    const { setBanner } = useContext(BannerContext);

    const onSubmit = () => {
        setIsLoading(true)
    }
    const onResponse = () => {
        setIsLoading(false)
    }
    const onSuccess = () => {
        setSuccess(true)
        setBanner({type: 'success', i18nTitle: 'app.save', i18nMessage: 'app.data_saved_message'})
    }
    const onError = () => {
        setSuccess(false)
    }

    return (
        <BlankPage>
            <FormEmpty
                path={`/admin/edition/catalog/${catalog.id}`}
                method="patch"
                formPath={true}
                children={<div className="row justify-content-end align-items-center">
                    <div className="col-auto">
                        <LoadingButton type="submit" isLoading={isLoading}>
                            {t( 'app.validate' )}
                        </LoadingButton>
                    </div>
                </div>}
                onSubmit={onSubmit}
                onResponse={onResponse}
                onSuccess={onSuccess}
                onError={onError}
            />
        </BlankPage>
    )
}

export default MainPage
