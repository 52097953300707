import React, {FC, useEffect, useState} from 'react'
import { useOutletContext} from 'react-router-dom'
import {useTranslation} from "react-i18next";
import Table from "Components/Layout/Table";
import PropertyTable from "../../../Table/Admin/Edition/PropertyTable";
import Property from "../../../Entity/Property";
import FormRow from "../../../Vendor/Components/Form/FormRow";
import PropertyForm from "../../../Form/Admin/Edition/PropertyForm";
import DeleteModal from "../../../Components/Modal/DeleteModal";
import FormError from "../../../Vendor/Components/Form/FormError";
import FormOffCanvas from "../../../Components/Modal/FormOffCanvas";
import {Button} from "react-bootstrap";
import FormEmptyOffCanvas from "../../../Components/Modal/FormEmptyOffCanvas";
import {CustomAction} from "../../../Components/Listing/Listing";

const PropertyPage: FC = () => {
    const {t} = useTranslation()
    const [show, setShow, search, setSearch, setHandleAdd] = useOutletContext<[boolean, (show:boolean) => void, string, (search:string) => void, (handleAdd: (() => void)|undefined) => void]>()
    const [showFilterCollections, setShowFilterCollections] = useState<boolean>(false)
    const [showConditionedCollections, setShowConditionedCollections] = useState<boolean>(false)
    const [toggleReload, setToggleReload] = useState<boolean>(true)
    const [properties, setProperties] = useState<Property[]>([])
    const [property, setProperty] = useState<Property|undefined>(undefined)

    useEffect(() => {
        setHandleAdd(() => {
            setProperty(undefined)
        })
    }, [setHandleAdd])

    const handleClose = (reload = false) => {
        setShow(false)
        if (reload) {
            setToggleReload(!toggleReload)
        }
    }

    const handleCloseFilterCollections = (reload = false) => {
        setShowFilterCollections(false)
        if (reload) {
            setToggleReload(!toggleReload)
        }
    }

    const handleCloseConditionedCollections = (reload = false) => {
        setShowConditionedCollections(false)
        if (reload) {
            setToggleReload(!toggleReload)
        }
    }

    // remove
    const [showDelete, setShowDelete] = useState<boolean>(false)
    const handleDeleteClose = (reload = false) => {
        setProperty(undefined)
        setShowDelete(false)
        if (reload) {
            setToggleReload(!toggleReload)
        }
    }
    const handleDeleteShow = () => setShowDelete(true)

    const onExited = () => {
        setProperty(undefined)
    }

    const onSuccess = (property: Property) => {
        setProperty(property)
        setShow(false)
        setToggleReload(!toggleReload)
    }

    const onSuccessFilterCollections = (property: Property) => {
        setProperty(undefined)
        setShowFilterCollections(false)
        setToggleReload(!toggleReload)
    }

    const onSuccessConditionedCollections = (property: Property) => {
        setProperty(undefined)
        setShowConditionedCollections(false)
        setToggleReload(!toggleReload)
    }

    const editProperty = (property: Property) => {
        setProperty(property)
        setShow(true)
    }

    const filterProperty = (property: Property) => {
        setProperty(property)
        setShowFilterCollections(true)
    }

    const conditionedFiler = (property: Property) => {
        setProperty(property)
        setShowConditionedCollections(true)
    }

    const deleteProperty = (property: Property) => {
        setProperty(property)
        handleDeleteShow()
    }

    const actionsRender = (property: Property) => (
        <>
            {property?.isGranted?.edit &&
                <Button
                    variant='outline-primary'
                    size='sm'
                    onClick={() => editProperty(property)}>
                    <i className="fa-solid fa-pencil me-2"></i>
                    {t('app.edit')}</Button>
            }
            {property?.isGranted?.filter &&
                <Button
                    variant='outline-primary'
                    size='sm'
                    onClick={() => filterProperty(property)}>
                    <i className="fa-solid fa-filter me-2"></i>
                    Filtrer</Button>
            }
            <Button
                variant='outline-primary'
                size='sm'
                onClick={() => conditionedFiler(property)}>
                <i className="fa-solid fa-filter me-2"></i>
                {t('app.filter')}
            </Button>
            {property?.isGranted?.delete &&
                <Button
                    variant='outline-danger'
                    size='sm'
                    onClick={() => deleteProperty(property)}>
                    <i className="fa-solid fa-trash me-2"></i>
                    {t('app.delete')}</Button>
            }
        </>
    );

    let table = PropertyTable;
    if (table.hasOwnProperty('actions')) {
        table.actions.render = actionsRender;
    }

    if(table.hasOwnProperty('type')) {
        table.type.render = (property: Property) => (
            <>
                {property.type === 'collection' ?
                    <>
                        {t('app.enum.property.type.' + property.type.charAt(0).toUpperCase() + property.type.slice(1))}
                        <i className="ms-1 text-muted">(
                            {property.collection === 'data-collection' ?
                                <>{t('app.enum.property.collection.DataCollection')}</>
                                : <>{t('app.enum.property.collection.' + property.collection?.charAt(0).toUpperCase() + property.collection?.slice(1))}</>
                            }
                        )</i>
                    </>
                : <>{t('app.enum.property.type.' + property.type.charAt(0).toUpperCase() + property.type.slice(1))}</>
                }

                {property.collection === 'bucket' &&
                    <>: {property.bucket?.name}</>
                }
                {property.collection === 'catalog' &&
                    <>: {property.catalog?.name}</>
                }
                {property.collection === 'data-collection' &&
                    <>: {property.dataCollection?.name}</>
                }
            </>
        );
    }

    return (
        <>
            <Table
                path="/admin/edition/property"
                rows={table}
                toggleReload={toggleReload}
                items={properties}
                setItems={setProperties}
                keyword={search}
                setKeyword={setSearch}
            />
            <FormEmptyOffCanvas
                i18nTitle={property?.id ? 'app.edit_property' : 'app.add_property'}
                show={show}
                handleClose={handleClose}
                onSuccess={onSuccess}
                path={`/admin/edition/property/${property?.id ?? 'new'}`}
                formPath={!!property?.id}
                method={property?.id ? 'patch' : 'post'}
                onExited={onExited}
            />
            <FormEmptyOffCanvas
                i18nTitle='app.filter_collections'
                show={showFilterCollections}
                handleClose={handleCloseFilterCollections}
                onSuccess={onSuccessFilterCollections}
                path={`/admin/edition/property/filter/${property?.id}`}
                formPath={!!property?.id}
                method={property?.id ? 'patch' : 'post'}  /* TODO : si on a un filtre créé, en patch, si il faut créér un filtre, en post */
                onExited={onExited}
            />
            <FormEmptyOffCanvas
                i18nTitle='app.filter_property'
                show={showConditionedCollections}
                handleClose={handleCloseConditionedCollections}
                onSuccess={onSuccessConditionedCollections}
                path={`/admin/edition/property/conditioned/${property?.id}`}
                method={'patch'}
                onExited={onExited}
            />
            <DeleteModal
                handleClose={handleDeleteClose}
                show={showDelete}
                path={'/admin/edition/property/' + (property?.id ?? 'error')}
                name={'#' + (property ? `${property.id} - ${property.name}` : 'error')}
                archive={true}
            />
        </>
    )
}

export default PropertyPage
