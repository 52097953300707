import React, {FC, useContext, useEffect, useState} from 'react'
import Listing, {CustomAction} from "Components/Listing/Listing";
import Header from "../Components/Layout/Header";
import EntityInterface from "../Vendor/Definition/EntityInterface";
import DeleteModal from "../Components/Modal/DeleteModal";
import useTranslationDataUtils from "../Vendor/Utils/TranslationDataUtils";
import Api from "../Vendor/Api";
import {useParams} from "react-router-dom";
import GlobalLoader from "../Vendor/Components/GlobalLoader";
import {UserContext} from "../Vendor/Context/UserContextProvider";
import {useTranslation} from "react-i18next";
import ProductOffCanvas from "../Components/Offcanvas/product/ProductOffcanvas";
import FormModal from "../Components/Modal/FormModal";
import FormEmptyModal from "../Components/Modal/FormEmptyModal";
import RefreshModal from "../Components/Modal/RefreshModal";
import DataCollection from "../Entity/DataCollection";
import Item from "../Entity/Item";
import FormEmptyOffCanvas from "../Components/Modal/FormEmptyOffCanvas";

export interface DataCollectionProps {
}

const DataCollectionPage: FC<DataCollectionProps> = () => {
    const { currentUser } = useContext(UserContext)
    const params = useParams()
    const {translate} = useTranslationDataUtils()
    const [isSearchable, setSearchable] = useState<boolean>(false)
    const [show, setShow] = useState<boolean>(false)
    const [addShow, setAddShow] = useState<boolean>(false)
    const [search, setSearch] = useState<string>('')
    const [dataCollection, setDataCollection] = useState<DataCollection|undefined>()
    const [item, setItem] = useState<Item|undefined>(undefined)
    const [toggleReload, setToggleReload] = useState<string>('')
    const [showRefreshModal, setShowRefreshModal] = useState<boolean>(false)

    // remove
    const [showDelete, setShowDelete] = useState<boolean>(false)

    const handleDeleteClose = (reload = false) => {
        setItem(undefined)
        setShowDelete(false)
        if (reload) {
            setToggleReload(new Date().toISOString())
        }
    }
    const handleDeleteShow = () => setShowDelete(true)

    const deleteAction = (item: EntityInterface) => {
        setItem(item as Item)
        handleDeleteShow()
    }

    const editAction = (item: EntityInterface) => {
        setItem({...item} as Item)
        setShow(true)
    }

    const addItem = () => {
        setShow(true)
        setItem(undefined)
    }

    const handleClose = (reload = false) => {
        setShow(false)
        if (reload) {
            setToggleReload(new Date().toISOString())
        }
    }

    const onExited = () => {
        setItem(undefined)
        setToggleReload(new Date().toISOString())
    }

    const onSuccess = (item: Item) => {
        setItem(item)
        setShow(false)
        setToggleReload(new Date().toISOString())
    }

    useEffect(() => {
        setSearchable(!!(dataCollection && (dataCollection.listingSetting.searchable.length || dataCollection.listingSetting.searchableByName)));
    }, [dataCollection])

    useEffect(() => {
        let currentSlug: string = params.slug as string;
        let tempDataCollection:DataCollection|undefined = dataCollection

        if (currentUser?.currentOrganization?.dataCollections.length) {
            currentUser.currentOrganization.dataCollections.forEach(dataCollection => {
                if (translate(dataCollection, 'slug') === currentSlug) {
                    tempDataCollection = dataCollection
                }
            })
        }

        if (!tempDataCollection) {
            Api.get(`/d/${currentSlug}/init`)
                .then((response) => {
                    setDataCollection(response.data as DataCollection)
                })
        }
        else {
            setDataCollection(tempDataCollection)
        }
    }, [currentUser, params])

    if (!dataCollection) return <GlobalLoader />

    return <>
        <Header title={(currentUser?.previousInternal ? '#' + dataCollection.id + ' - ': '') + translate(dataCollection, 'name')}
                search={isSearchable ? search : undefined}
                setSearch={isSearchable ? setSearch : undefined}
                add={addItem}
        />
        <Listing
            setting={dataCollection.listingSetting}
            search={search}
            setSearch={setSearch}
            path={`/d/${dataCollection.primarySlug}`}
            entity='data-collection'
            id={dataCollection.id}
            slug={translate(dataCollection, 'slug', true)}
            toggleReload={toggleReload}
            setToggleReload={setToggleReload}
            editAction={editAction}
            deleteAction={deleteAction}
        />
        {/*{product && <ProductOffCanvas*/}
        {/*    product={product}*/}
        {/*    catalog={catalog}*/}
        {/*    show={show}*/}
        {/*    handleClose={handleClose}*/}
        {/*    onExited={onExited}*/}
        {/*    editAction={editNameAction}*/}
        {/*/>}*/}
        <FormEmptyOffCanvas
            i18nTitle={item ? 'app.edit_item' : 'app.add_item'}
            show={show}
            path={item?.id ? `/item/${item.id}` : `/d/${dataCollection.primarySlug}/new`}
            formPath={!!item?.id}
            method={item ? 'patch' : 'post'}
            handleClose={() => {
                setShow(false)
            }}
            onSuccess={(data) => {
                setItem(data as Item)
                setShow(false)
                setToggleReload(new Date().toISOString())
            }}
        />
        <DeleteModal
            handleClose={handleDeleteClose}
            show={showDelete}
            path={`/item/${item?.id}`}
            name={`#${item?.id} - ${item && translate(item, 'name')}`}
            archive={true}
        />
        <RefreshModal show={showRefreshModal} />
    </>
}

export default DataCollectionPage
