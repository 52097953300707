import React, {FC, useContext, useState} from 'react'
import {useTranslation} from "react-i18next";
import Header from "Components/Layout/Header";
import Table from "Components/Layout/Table";
import DeleteModal from "../../../../../Components/Modal/DeleteModal";
import {Button} from "react-bootstrap";
import FormEmptyOffCanvas from "../../../../../Components/Modal/FormEmptyOffCanvas";
import DealGroup from "../../../../../Entity/Security/DealGroup";
import DealGroupTable from "../../../../../Table/Admin/Edition/Security/DealGroupTable";
import {useOutletContext} from "react-router-dom";

const DealGroupPage: FC = () => {
    const { t } = useTranslation()

    const [toggleReload, setToggleReload] = useState<boolean>(true)
    const [show, setShow, search, setSearch] = useOutletContext<[boolean, (show:boolean) => void, string, (search:string) => void]>()
    const [dealGroups, setDealGroups] = useState<DealGroup[]>([])
    const [dealGroup, setDealGroup] = useState<DealGroup>({} as DealGroup)
    const handleClose = (reload = false) => {
        setShow(false)
        if (reload) {
            setToggleReload(!toggleReload)
        }
    }
    const handleShow = () => setShow(true)
    // remove
    const [showDelete, setShowDelete] = useState<boolean>(false)
    const handleDeleteClose = (reload = false) => {
        setDealGroup({} as DealGroup)
        setShowDelete(false)
        if (reload) {
            setToggleReload(!toggleReload)
        }
    }
    const handleDeleteShow = () => setShowDelete(true)

    const onSuccess = (dealGroup: DealGroup) => {
        setDealGroup(dealGroup)
        setShow(false)
        setToggleReload(!toggleReload)
    }

    const onExited = () => {
        setDealGroup({} as DealGroup)
    }

    const addDealGroup = () => {
        setDealGroup({} as DealGroup)
        handleShow()
    }

    const editDealGroup = (dealGroup: DealGroup) => {
        setDealGroup(dealGroup)
        handleShow()
    }

    const deleteDealGroup = (dealGroup: DealGroup) => {
        setDealGroup(dealGroup)
        handleDeleteShow()
    }

    const actionsRender = (dealGroup: DealGroup) => (
        <>
            {dealGroup?.isGranted?.edit &&
                <Button
                    variant='outline-primary'
                    size='sm'
                    onClick={() => editDealGroup(dealGroup)}>
                    <i className="fa-solid fa-pencil me-2"></i>
                    {t('app.edit')}</Button>
            }
            {dealGroup?.isGranted?.delete &&
                <Button
                    variant='outline-danger'
                    size='sm'
                    onClick={() => deleteDealGroup(dealGroup)}>
                    <i className="fa-solid fa-trash me-2"></i>
                    {t('app.delete')}</Button>
            }
        </>
    );

    let table = DealGroupTable;
    if (table.hasOwnProperty('actions')) {
        table.actions.render = actionsRender;
    }

    return (
        <>
            <Table
                path="/admin/edition/deal-group"
                rows={table}
                toggleReload={toggleReload}
                items={dealGroups}
                setItems={setDealGroups}
                keyword={search}
                setKeyword={setSearch}
            />
            <FormEmptyOffCanvas
                i18nTitle={dealGroup.id ? 'app.edit_deal_group' : 'app.add_deal_group'}
                show={show}
                handleClose={handleClose}
                onSuccess={onSuccess}
                onExited={onExited}
                path={`/admin/edition/deal-group/${dealGroup.id ?? 'new'}`}
                formPath={!!dealGroup.id}
                method={dealGroup.id ? 'patch' : 'post'}
            />
            <DeleteModal
                handleClose={handleDeleteClose}
                show={showDelete}
                path={'/admin/edition/deal-group/' + (dealGroup?.id ?? 'error')}
                name={'#' + (dealGroup ? `${dealGroup.id} - ${dealGroup.name}` : 'error')}
                archive={true}
            />
        </>
    )
}

export default DealGroupPage
