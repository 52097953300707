import {MenuItem} from "Components/Layout/Sidebar/_MenuItem";

const _ExternalMenu: MenuItem[] = [
    /*{
        to: '/',
        className: 'fa-solid fa-building',
        label: 'Dashboard',
        index: true,
    },*/
    /*{
        to: '/reporting',
        className: 'fa-solid fa-bullhorn',
        label: 'app.reporting',
    },*/
]

export default _ExternalMenu
