import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {
    Button, Modal,
    ModalBody as Body,
    ModalFooter as Footer,
    ModalHeader as Header,
    ModalTitle as Title
} from "react-bootstrap";
import GroupedAction from "Entity/Collection/ListingSetting/GroupedAction";
import LoadingButton from "../../Button/LoadingButton";
import {MailingActionModal} from "../Listing";
import Api from "Vendor/Api";
import Collection from "Config/Collection";
import Property from "../../../Entity/Property";

export interface _RestoreModalProps {
    collection?: Collection
    showActionModals: {[key: string]: boolean|Property}
    handleShowActionModal: (groupedAction?: GroupedAction, actionFromMain?:boolean) => void
    fromMain: boolean
    mailingListActionType: MailingActionModal
    setMailingListActionType: (mailingListActionType: MailingActionModal) => void
    handleShowMainActionModal: () => void
    selected: number[]
    clearSelection: () => void
    actionModalFormIsLoading: boolean
    handleActionModalOnResponse: () => void
    handleActionModalOnSuccess: () => void
    handleActionModalOnSubmit: () => void
}

const _RestoreModal: FC<_RestoreModalProps> = ({
                                                 collection,
                                                 showActionModals,
                                                 handleShowActionModal,
                                                 fromMain,
                                                 mailingListActionType,
                                                 setMailingListActionType,
                                                 handleShowMainActionModal,
                                                 selected,
                                                 clearSelection,
                                                 actionModalFormIsLoading,
                                                 handleActionModalOnResponse,
                                                 handleActionModalOnSuccess,
                                                 handleActionModalOnSubmit,
}) => {
    const {t} = useTranslation()

    const onSubmit = () => {
        handleActionModalOnSubmit()
        Api.post('/grouped-action/restore', {
            listing: collection,
            selected: selected,
        })
            .then(({data}) => {
                clearSelection()
                handleActionModalOnSuccess()
            })
            .catch(() => {
            })
            .then(() => {
                handleActionModalOnResponse()
            })
    }

    return <Modal show={showActionModals.restore !== false} onHide={handleShowActionModal}>
        <Header closeButton={!fromMain}>
            <div className="modal-block-header">
                {fromMain && <i className="fa-solid fa-angle-left" onClick={() => {
                    if (mailingListActionType === undefined) {
                        handleShowMainActionModal()
                    } else {
                        setMailingListActionType(undefined)
                    }
                }}></i>}
                <Title>{t(`app.groupedActionTitle.restore.${collection}`, {count: selected.length})}</Title>
            </div>
        </Header>
        <Body>
            {t(`app.groupedActionContent.restore.${collection}`, {count: selected.length})}
        </Body>
        <Footer>
            {!fromMain && <Button variant="secondary" onClick={() => {
                handleShowActionModal()
            }}>
                {t('app.cancel')}
            </Button>}
            <LoadingButton variant="danger" isLoading={actionModalFormIsLoading} onClick={onSubmit}>
                {t('app.restore')}
            </LoadingButton>
        </Footer>
    </Modal>
}

export default _RestoreModal
