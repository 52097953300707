import React, {FC, useContext, useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Api from "Vendor/Api";
import {BannerContext} from "Vendor/Context/BannerContextProvider";
import TextType from "../../Vendor/Components/Form/Type/TextType";
import LoadingButton from "../../Components/Button/LoadingButton";
import PasswordType from "../../Vendor/Components/Form/Type/PasswordType";
import {UserContext} from "../../Vendor/Context/UserContextProvider";
import User from "../../Entity/User";

const ActivatePage: FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { token } = useParams()
    const location = useLocation()
    const { setBanner } = useContext(BannerContext);
    const { setCurrentUser } = useContext(UserContext)

    const [hiddenToken, setHiddenToken] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [plainPassword, setPlainPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')

    useEffect(() => {
        token && navigate('/activate', { state: token })
        location.state && setHiddenToken(location.state as string)
    }, [location, navigate, token])

    const onSubmit = () => {
        setIsLoading(true)
        Api.post('activate/' + hiddenToken, {
            plainPassword,
            firstName,
            lastName,
        })
            .then(({data}) => {
                setIsLoading(false)
                setBanner({type: 'success', i18nMessage: 'app.user_activated'})
                setCurrentUser(data as User)
            })
            .catch((err) => {
                setIsLoading(false)
                setBanner({type: 'error', i18nMessage: err?.response?.data?.message})
            })
    }

    return (
        <form name="user" method="post" onSubmit={(e) => e.preventDefault()}>
            <div className="modal-header">
                <h5 className="modal-title" id="loginModalLabel">
                    {t('app.activate_account')}
                </h5>
            </div>
            <div className="modal-body">
                <div id="user">
                    <TextType
                        name="firstName"
                        required
                        setValue={setFirstName}
                        value={firstName}
                        fullName='firstName'
                        id='firstName'
                    />
                    <TextType
                        name="lastName"
                        required
                        setValue={setLastName}
                        value={lastName}
                        fullName='lastName'
                        id='lastName'
                    />
                    <PasswordType
                        name="password"
                        required
                        setValue={setPlainPassword}
                        value={plainPassword}
                        id='password'
                        fullName='password'
                    />
                    <PasswordType
                        name="plainPassword"
                        required
                        setValue={setRepeatPassword}
                        value={repeatPassword}
                        id='plainPassword'
                        fullName='plainPassword'
                    />
                </div>
            </div>
            <div className="modal-footer">
                <LoadingButton
                    type="submit"
                    isLoading={isLoading}
                    disabled={
                        isLoading
                        || !firstName
                        || !lastName
                        || !plainPassword
                        || plainPassword.length < 6
                        || !repeatPassword
                        || plainPassword !== repeatPassword
                    }
                    onClick={onSubmit}
                >
                    {t('app.activate')}
                </LoadingButton>
            </div>
        </form>
    )
}

export default ActivatePage
