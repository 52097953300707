import React, {FC, useContext, useEffect, useState} from 'react'
import Listing from "Components/Listing/Listing";
import Header from "../Components/Layout/Header";
import DataCollection from "Entity/DataCollection";
import Item from "Entity/Item";
import EntityInterface from "../Vendor/Definition/EntityInterface";
import DeleteModal from "../Components/Modal/DeleteModal";
import useTranslationDataUtils from "../Vendor/Utils/TranslationDataUtils";
import Api from "../Vendor/Api";
import {useParams} from "react-router-dom";
import GlobalLoader from "../Vendor/Components/GlobalLoader";
import {UserContext} from "../Vendor/Context/UserContextProvider";
import Loader from "../Vendor/Components/Loader";
import UnderConstruction from "../Vendor/Components/UnderConstruction";

export interface ReportingProps {
}

const ReportingPage: FC<ReportingProps> = () => {

    return <UnderConstruction />
}

export default ReportingPage
