import React, {FC, useContext, useEffect, useState} from 'react'
import Listing, {CustomAction} from "Components/Listing/Listing";
import Header from "../Components/Layout/Header";
import Catalog from "Entity/Catalog";
import Product from "Entity/Product";
import EntityInterface from "../Vendor/Definition/EntityInterface";
import DeleteModal from "../Components/Modal/DeleteModal";
import useTranslationDataUtils from "../Vendor/Utils/TranslationDataUtils";
import Api from "../Vendor/Api";
import {useNavigate, useParams} from "react-router-dom";
import GlobalLoader from "../Vendor/Components/GlobalLoader";
import {UserContext} from "../Vendor/Context/UserContextProvider";
import ProductOffCanvas from "../Components/Offcanvas/product/ProductOffcanvas";
import FormEmptyModal from "../Components/Modal/FormEmptyModal";
import RefreshModal from "../Components/Modal/RefreshModal";

export interface CatalogProps {
}

const CatalogPage: FC<CatalogProps> = () => {
    const { currentUser } = useContext(UserContext)
    const params = useParams()
    const navigate = useNavigate()
    const {translate} = useTranslationDataUtils()
    const [isSearchable, setSearchable] = useState<boolean>(false)
    const [show, setShow] = useState<boolean>(false)
    const [addShow, setAddShow] = useState<boolean>(false)
    const [search, setSearch] = useState<string>('')
    const [catalog, setCatalog] = useState<Catalog|undefined>()
    const [product, setProduct] = useState<Product|undefined>(undefined)
    const [toggleReload, setToggleReload] = useState<string>('')
    const [showRefreshModal, setShowRefreshModal] = useState<boolean>(false)
    const [hasPlatform, setHasPlatform] = useState<boolean>(currentUser?.currentOrganization?.platforms
        ? currentUser?.currentOrganization?.platforms?.length > 0
        : false)
    const [isEdited, setIsEdited] = useState<boolean>(false)

    const handlePublish = (product: Product) => {
        setShowRefreshModal(true)
        Api.patch(`/product/${product.id}/change-status/published`)
            .then(() => {})
            .catch(() => {})
            .then(() => {
                setToggleReload(new Date().toISOString())
                setShowRefreshModal(false)
            })
    }

    const handleUnPublish = (product: Product) => {
        setShowRefreshModal(true)
        Api.patch(`/product/${product.id}/change-status/unpublished`)
            .then(() => {})
            .catch(() => {})
            .then(() => {
                setToggleReload(new Date().toISOString())
                setShowRefreshModal(false)
            })
    }
    const [customActions, setCustomActions] = useState<CustomAction[]>(
        hasPlatform ? [
            {
                method: handlePublish,
                granted: 'publish',
                i18nLabel: 'app.publish',
                variant: 'success',
            },
            {
                method: handleUnPublish,
                granted: 'unPublish',
                i18nLabel: 'app.unPublish',
                variant: 'danger',
            },
        ] : []
    )

    // remove
    const [showDelete, setShowDelete] = useState<boolean>(false)

    useEffect(() => {
        const fetchProduct = async () => {
            if (params.hasOwnProperty('id')) {
                const id = parseInt(params.id ?? '0', 10);
                if (id > 0) {
                    const response = await Api.get(`/product/${id}`);
                    setProduct(response.data as Product);
                    setShow(true);
                }
            }
        }
        const timeoutId = setTimeout(fetchProduct, 300);

        return () => clearTimeout(timeoutId);
    }, [params.id]);

    const handleDeleteClose = (reload = false) => {
        setProduct(undefined)
        setShowDelete(false)
        if (reload) {
            setToggleReload(new Date().toISOString())
        }
    }
    const handleDeleteShow = () => setShowDelete(true)

    const deleteAction = (product: EntityInterface) => {
        setProduct(product as Product)
        handleDeleteShow()
    }

    const editAction = (product: EntityInterface) => {
        Api.get(`/product/${product.id}`)
            .then((response) => {
                setProduct(response.data as Product)
                setShow(true)
            })
    }

    const editNameAction = (product: Product) => {
        setProduct({...product})
        setAddShow(true)
    }

    const addProduct = () => {
        setAddShow(true)
    }

    const handleClose = (reload = false) => {
        setShow(false)
        if (params.id) {
            navigate('/c/' + params.slug)
        }
        if (reload) {
            setToggleReload(new Date().toISOString())
        }
    }

    const onExited = () => {
        setProduct(undefined)
        if(isEdited){
            setToggleReload(new Date().toISOString())
            setIsEdited(false)
        }
    }

    const onSuccess = (product: Product) => {
        setProduct(product)
        setShow(false)
        setToggleReload(new Date().toISOString())
    }

    useEffect(() => {
        setSearchable(!!(catalog && (catalog.listingSetting.searchable.length || catalog.listingSetting.searchableByName)));
    }, [catalog])

    useEffect(() => {
        let currentSlug: string = params.slug as string;
        let tempCatalog:Catalog|undefined = catalog

        if (currentUser?.currentOrganization?.catalogs.length) {
            currentUser.currentOrganization.catalogs.forEach(catalog => {
                if (translate(catalog, 'slug') === currentSlug) {
                    tempCatalog = catalog
                }
            })
        }

        if (!tempCatalog) {
            Api.get(`/c/${currentSlug}/init`)
                .then((response) => {
                    setCatalog(response.data as Catalog)
                })
        }
        else {
            setCatalog(tempCatalog)
        }
    }, [currentUser, params])

    const changeStateOnEdit = (status:boolean) => {
        setIsEdited(status)
    }

    if (!catalog) return <GlobalLoader />

    return <>
        <Header title={(currentUser?.previousInternal ? '#' + catalog.id + ' - ': '') + translate(catalog, 'name')}
                search={isSearchable ? search : undefined}
                setSearch={isSearchable ? setSearch : undefined}
                add={addProduct}
        />
        <Listing
            setting={catalog.listingSetting}
            customActions={customActions}
            search={search}
            setSearch={setSearch}
            path={`/c/${catalog.primarySlug}`}
            entity='catalog'
            id={catalog.id}
            slug={translate(catalog, 'slug', true)}
            toggleReload={toggleReload}
            setToggleReload={setToggleReload}
            editAction={editAction}
            deleteAction={deleteAction}
        />
        {product && <ProductOffCanvas
            product={product}
            catalog={catalog}
            show={show}
            handleClose={handleClose}
            onExited={onExited}
            editAction={editAction}
            editNameAction={editNameAction}
            changeStateOnEdit={changeStateOnEdit}
        />}
        <FormEmptyModal
            i18nTitle={product ? 'app.edit_product' : 'app.add_product'}
            show={addShow}
            path={product ? `/product/${product.id}/edit-name` : `/c/${catalog.primarySlug}/new`}
            method={product ? 'patch' : 'post'}
            handleClose={() => {
                setAddShow(false)
            }}
            onSuccess={(data) => {
                setProduct(data as Product)
                setAddShow(false)
                changeStateOnEdit(true)
                if (!show) {
                    setShow(true)
                    setToggleReload(new Date().toISOString())
                }
            }}
        />
        <DeleteModal
            handleClose={handleDeleteClose}
            show={showDelete}
            path={`/product/${product?.id}`}
            name={`#${product?.id} - ${product && translate(product, 'name')}`}
            archive={true}
        />
        <RefreshModal show={showRefreshModal} />
    </>
}

export default CatalogPage
