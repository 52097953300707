import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const CheckEmailPage: FC<{ expirationMessage: string }> = ({
    expirationMessage,
}) => {
    const { t } = useTranslation()

    return (
        <>
            <div className="modal-header">
                <h5 className="modal-title" id="loginModalLabel">
                    {t('app.password_reset_email_sent')}
                </h5>
            </div>
            <div className="modal-body">
                <p>
                    {t('app.password_reset_text')} {expirationMessage}.
                </p>
                <p>
                    {t('app.password_reset_message_try_again')}{' '}
                    <Link to="/reset-password">
                        {t('app.try_again')}
                    </Link>
                </p>
            </div>
        </>
    )
}

export default CheckEmailPage
