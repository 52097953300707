import React, {FC, useContext, useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import Table from "Components/Layout/Table";
import {Button} from "react-bootstrap";
import {BannerContext} from "Vendor/Context/BannerContextProvider";
import Listing from "Entity/Platform/Listing";
import ListingTable from "Table/Admin/Edition/Platform/ListingTable";
import FormEmptyOffCanvas from "Components/Modal/FormEmptyOffCanvas";
import DeleteModal from "../../../../Components/Modal/DeleteModal";
import useTranslationDataUtils from "Vendor/Utils/TranslationDataUtils";
import {useOutletContext} from "react-router-dom";
import Platform from "Entity/Platform";
import Bucket from "../../../../Entity/Bucket";

const ListingPage: FC = () => {
    const { setBanner } = useContext(BannerContext);
    const [platform, setPlatform, show, setShow, search, setSearch, setHandleAdd] = useOutletContext<[Platform, (platform: Platform) => void, boolean, (show:boolean) => void, string, (search:string) => void, (handleAdd: (() => void)|undefined) => void]>()

    const {translate} = useTranslationDataUtils()
    const { t } = useTranslation()
    const [listings, setListings] = useState<Listing[]>([])
    const [listing, setListing] = useState<Listing|undefined>(undefined)
    const [settingShow, setSettingShow] = useState<boolean>(false)

    const addListing = () => {
        setListing(undefined)
        handleShow()
    }

    useEffect(() => {
        if (setHandleAdd && typeof setHandleAdd === 'function') {
            setHandleAdd(() => {
                setListing(undefined)
            })
        }

        return () => {
            if (setHandleAdd && typeof setHandleAdd === 'function') {
                setHandleAdd(undefined)
            }
        }
    }, [setHandleAdd])
    const handleClose = (reload = false) => {
        setShow(false)
    }
    const handleShow = () => {
        setShow(true)
    }

    const handleSettingShow = () => {
        setSettingShow(true)
    }
    const handleSettingClose = (reload = false) => {
        setSettingShow(false)
    }
    // remove
    const [showDelete, setShowDelete] = useState<boolean>(false)
    const handleDeleteClose = (reload = false) => {
        setListing(undefined)
        setShowDelete(false)
    }
    const handleDeleteShow = () => setShowDelete(true)

    const onSuccess = () => {
        setShow(false)
    }

    const editListing = (listing: Listing) => {
        setListing(listing)
        handleShow()
    }

    const editListingSetting = (listing: Listing) => {
        setListing(listing)
        handleSettingShow()
    }

    const onSettingSuccess = () => {
        setListing(undefined)
        handleSettingClose()
    }

    const deleteListing = (listing: Listing) => {
        setListing(listing)
        handleDeleteShow()
    }

    const actionsRender = (listing: Listing) => (
        <>
            <Button
                variant='outline-primary'
                size='sm'
                onClick={() => editListing(listing)}>
                <i className="fa-solid fa-pencil me-2"></i>
                {t('app.edit')}</Button>
            <Button
                variant='outline-primary'
                size='sm'
                onClick={() => editListingSetting(listing)}>
                <i className="fa-solid fa-pencil me-2"></i>
                {t('app.listing')}</Button>
            <Button
                variant='outline-danger'
                size='sm'
                onClick={() => deleteListing(listing)}>
                <i className="fa-solid fa-trash me-2"></i>
                {t('app.delete')}</Button>
        </>
    );

    let table = ListingTable;
    if (table.hasOwnProperty('actions')) {
        table.actions.render = actionsRender;
    }

    return (
        <>
            <Table
                path={`/admin/edition/platform/${platform.id}/listing`}
                rows={table}
                items={listings}
                setItems={setListings}
                keyword={search}
                setKeyword={setSearch}
            />
            <FormEmptyOffCanvas
                i18nTitle={listing ? 'app.listings.edit' : 'app.listings.add'}
                show={show}
                handleClose={handleClose}
                onSuccess={onSuccess}
                path={`/admin/edition/platform/${platform.id}/listing/${listing?.id ?? 'new'}`}
                formPath={!!listing}
                method={listing ? 'patch' : 'post'}
            />
            <FormEmptyOffCanvas
                i18nTitle={listing ? 'app.listings.edit' : 'app.listings.add'}
                show={settingShow}
                handleClose={handleSettingClose}
                onSuccess={onSettingSuccess}
                path={`/admin/edition/platform/${platform.id}/listing/${listing?.id ?? 0}/setting`}
                method={'patch'}
            />
            <DeleteModal
                handleClose={handleDeleteClose}
                show={showDelete}
                path={`/admin/edition/platform/${platform.id}/listing/${listing?.id ?? 'error'}`}
                name={'#' + (listing ? `${listing.id} - ${translate(listing, 'label')}` : 'error')}
                archive={true}
            />
        </>
    )
}

export default ListingPage
