import {Rows} from "Components/Layout/Table";

const MailingIdentityTable: Rows = {
    name: {
        sortable: false,
        field: 'IdentityName',
        placeholders: [
            {
                element: 'p',
                size: [4],
            }
        ]
    },
    type : {
        field: 'IdentityType',
        sortable: false,
        placeholders: [
            {
                element: 'p',
                size: [3],
            }
        ]
    },
    SendingEnabled : {
        sortable: false,
        placeholders: [
            {
                element: 'p',
                size: [2],
            }
        ]
    },
    VerificationStatus : {
        sortable: false,
        placeholders: [
            {
                element: 'p',
                size: [2],
            }
        ],
        render: (value: {VerificationStatus: string}) => {
            let val = value.VerificationStatus.toLowerCase();
            let bg = 'secondary';

            if (['success', 'danger', 'warning', 'info'].includes(val)) {
                bg = val;
            }

            return <span className={`badge bg-${val}`}>{val}</span>
        }
    },
    actions: {
        className: 'actions',
        sortable: false,
        placeholders: [
            {
                element: 'a',
                size: 2,
            },
        ]
    },
};

export default MailingIdentityTable
