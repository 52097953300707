import React, {FC, useContext, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import { UserContext } from 'Vendor/Context/UserContextProvider'
import {useTranslation} from "react-i18next";
import Organization from "../../Entity/Organization";
import Header from "Components/Layout/Header";
import Table from "Components/Layout/Table";
import Api from "Vendor/Api";
import OrganizationTable from "Table/Admin/OrganizationTable";
import {Button} from "react-bootstrap";
import OrganizationForm from "../../Form/Admin/OrganizationForm";
import FormRow from "../../Vendor/Components/Form/FormRow";
import FormOffCanvas from "../../Components/Modal/FormOffCanvas";
import FormEmptyOffCanvas from "../../Components/Modal/FormEmptyOffCanvas";
import Status from "../../Config/Status";
import LoadingButton from "../../Components/Button/LoadingButton";

const OrganizationPage: FC = () => {
    const { currentUser, setCurrentUser, setInit } = useContext(UserContext)
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [toggleReload, setToggleReload] = useState<boolean>(true)
    const [show, setShow] = useState<boolean>(false)
    const [keyword, setKeyword] = useState<string>('')
    const [organizations, setOrganizations] = useState<Organization[]>([])
    const [organization, setOrganization] = useState<Organization>({} as Organization)
    const [onChangeStatus, setOnChangeStatus] = useState<string | undefined>(undefined)
    const handleClose = (reload = false) => {
        setShow(false)
        if (reload) {
            setToggleReload(!toggleReload)
        }
    }
    const onSuccess = () => {
        handleClose(true)
    }
    const handleShow = () => setShow(true)

    const addOrganization = () => {
        setOrganization({} as Organization)
        handleShow()
    }

    const switchToEdition = (organization: Organization) => {
        setInit(false)
        Api.get(`/switch-to-session/${organization.id}`)
            .then(({ data }) => {
                if (currentUser) {
                    currentUser.currentOrganization = organization
                    currentUser.access = 'edition'
                    setCurrentUser(currentUser)
                    navigate('/admin/edition')
                }
            })
            .catch((err) => {
                if (currentUser) {
                    currentUser.currentOrganization = undefined;
                    setCurrentUser(currentUser)
                }
            })
            .then(() => {
                setInit(true)
            })
    }

    const changeStatus = (id: number, status: Status) => {
        setOnChangeStatus(':' + id + ':' + status)
        Api.patch(`/admin/organization/${id}/change-status`, { status })
            .then(({ data }) => {
                setToggleReload(!toggleReload)
            })
            .catch((err) => {

            })
            .then(() => {
                setOnChangeStatus(undefined)
            })
    }

    const actionsRender = (organization: Organization) => (
        <>
            <Button
                variant='secondary'
                className="stretched-link"
                onClick={() => switchToEdition(organization)}
            >
                <i className="fa-solid fa-pencil me-2"></i>
                {t('app.edition')}
            </Button>
            {('draft' === organization.status || 'offline' === organization.status) &&<LoadingButton isLoading={onChangeStatus === ':' + organization.id + ':online'} variant="outline-success" onClick={() => {changeStatus(organization.id ?? 0, 'online')}}>
                <i className="fa-solid fa-lightbulb-on me-2"></i>
                {t('app.online')}
            </LoadingButton>}
            {'online' === organization.status &&<LoadingButton isLoading={onChangeStatus === ':' + organization.id + ':offline'} variant="outline-danger" onClick={() => {changeStatus(organization.id ?? 0, 'offline')}}>
                <i className="fa-solid fa-lightbulb me-2"></i>
                {t('app.offline')}
            </LoadingButton>}
        </>
    );

    let table = OrganizationTable;
    if (table.hasOwnProperty('actions')) {
        table.actions.render = actionsRender;
    }

    return (
        <>
            <Header title={t('app.organizations')}
                add={addOrganization}
                search={keyword}
                setSearch={setKeyword}
            />
            <Table
                path="/admin/organization"
                rows={table}
                toggleReload={toggleReload}
                items={organizations}
                setItems={setOrganizations}
                keyword={keyword}
                setKeyword={setKeyword}
            />
            <FormEmptyOffCanvas
                path='/admin/organization/new'
                i18nTitle={'app.add_organization'}
                show={show}
                handleClose={handleClose}
                onSuccess={onSuccess}
            />
        </>
    )
}

export default OrganizationPage
