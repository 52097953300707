import React, {FC, useContext, useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import Header from "Components/Layout/Header";
import Table from "Components/Layout/Table";
import DeleteModal from "../../../../Components/Modal/DeleteModal";
import {Button} from "react-bootstrap";
import FormEmptyOffCanvas from "../../../../Components/Modal/FormEmptyOffCanvas";
import ItemGroup from "../../../../Entity/Security/ItemGroup";
import ItemGroupTable from "../../../../Table/Admin/Edition/Security/ItemGroupTable";
import {useOutletContext} from "react-router-dom";
import DataCollection from "../../../../Entity/DataCollection";

const ItemGroupPage: FC = () => {
    const { t } = useTranslation()

    const [toggleReload, setToggleReload] = useState<boolean>(true)
    const [itemGroups, setItemGroups] = useState<ItemGroup[]>([])
    const [itemGroup, setItemGroup] = useState<ItemGroup>({} as ItemGroup)
    const [dataCollection, setDataCollection, show, setShow, search, setSearch, setHandleAdd] = useOutletContext<[DataCollection, (dataCollection: DataCollection) => void, boolean, (show:boolean) => void, string, (search:string) => void, (handleAdd: (() => void)|undefined) => void]>()

    const handleClose = (reload = false) => {
        setShow(false)
        if (reload) {
            setToggleReload(!toggleReload)
        }
    }
    const handleShow = () => setShow(true)
    // remove
    const [showDelete, setShowDelete] = useState<boolean>(false)
    const handleDeleteClose = (reload = false) => {
        setItemGroup({} as ItemGroup)
        setShowDelete(false)
        if (reload) {
            setToggleReload(!toggleReload)
        }
    }
    const handleDeleteShow = () => setShowDelete(true)

    const onSuccess = (itemGroup: ItemGroup) => {
        setItemGroup(itemGroup)
        setShow(false)
        setToggleReload(!toggleReload)
    }

    const addItemGroup = () => {
        setItemGroup({} as ItemGroup)
        handleShow()
    }

    useEffect(() => {
        setHandleAdd(() => {
            setItemGroup({} as ItemGroup)
        })
    }, [setHandleAdd])

    const editItemGroup = (itemGroup: ItemGroup) => {
        setItemGroup(itemGroup)
        handleShow()
    }

    const deleteItemGroup = (itemGroup: ItemGroup) => {
        setItemGroup(itemGroup)
        handleDeleteShow()
    }

    const actionsRender = (itemGroup: ItemGroup) => (
        <>
            {itemGroup?.isGranted?.edit &&
                <Button
                    variant='outline-primary'
                    size='sm'
                    onClick={() => editItemGroup(itemGroup)}>
                    <i className="fa-solid fa-pencil me-2"></i>
                    {t('app.edit')}</Button>
            }
            {itemGroup?.isGranted?.delete &&
                <Button
                    variant='outline-danger'
                    size='sm'
                    onClick={() => deleteItemGroup(itemGroup)}>
                    <i className="fa-solid fa-trash me-2"></i>
                    {t('app.delete')}</Button>
            }
        </>
    );

    let table = ItemGroupTable;
    if (table.hasOwnProperty('actions')) {
        table.actions.render = actionsRender;
    }

    return (
        <>
            <Table
                path={`/admin/edition/data-collection/${dataCollection.id}/item-group`}
                rows={table}
                toggleReload={toggleReload}
                items={itemGroups}
                setItems={setItemGroups}
                keyword={search}
                setKeyword={setSearch}
            />
            <FormEmptyOffCanvas
                i18nTitle={itemGroup.id ? 'app.edit_item_group' : 'app.add_item_group'}
                show={show}
                handleClose={handleClose}
                onSuccess={onSuccess}
                path={`/admin/edition/data-collection/${dataCollection.id}/item-group/${itemGroup.id ?? 'new'}`}
                formPath={!!itemGroup.id}
                method={itemGroup.id ? 'patch' : 'post'}
            />
            <DeleteModal
                handleClose={handleDeleteClose}
                show={showDelete}
                path={`/admin/edition/data-collection/${dataCollection.id}/item-group/${itemGroup?.id ?? 'error'}`}
                name={'#' + (itemGroup ? `${itemGroup.id} - ${itemGroup.name}` : 'error')}
                archive={true}
            />
        </>
    )
}

export default ItemGroupPage
