import React from 'react'
import ReactDOM from 'react-dom/client'
import './Assets/styles/App.scss'
import reportWebVitals from './reportWebVitals'
import App from './Vendor/App'
import 'Vendor/Utils/I18nUtils'
import jQuery from 'jquery'

// @ts-ignore
global.$ = global.jQuery = jQuery
// @ts-ignore
global.select2 = require('select2')

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
