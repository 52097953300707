import React, { FC, ReactNode } from "react";
import {
  Modal,
  ModalBody as Body,
  ModalHeader as Header,
  ModalTitle as Title
} from "react-bootstrap";
import {ModalProps} from "react-bootstrap/Modal";

export interface EmptyModalProps {
    children: ReactNode,
    onClose: () => void,
    show: boolean,
    size?: 'sm' | 'lg' | 'xl',
    title?: string
}

const EmptyModal: FC<EmptyModalProps> = ({ onClose, title, children, show, size }) => {
  return (
    <Modal onHide={onClose} show={show} size={size}>
      {title &&
        <Header closeButton={true}>
          <div className="modal-block-header">
            <Title>
              {title}
            </Title>
          </div>
        </Header>
      }
      <Body>
        {children}
      </Body>
    </Modal>
  )
}

export default EmptyModal
