import React, {FC, useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import Header from "Components/Layout/Header";
import Table from "Components/Layout/Table";
import MailingIdentityTable from "Table/Admin/MailingIdentityTable";
import {Button} from "react-bootstrap";
import Api from "Vendor/Api";
import EmptyModal from "Components/Modal/EmptyModal";
import Spinner from "Components/Spinner";
import FormEmptyModal from "Components/Modal/FormEmptyModal";
import {Identity, IdentityDetails} from "Config/MailingIdentity";
import {useOutletContext} from "react-router-dom";

const DomainPage: FC = () => {
    const { t } = useTranslation()
    const [show, setShow, search, setSearch, disabled, setDisabled] = useOutletContext<[boolean, (show:boolean) => void, string, (search:string) => void, boolean, (disabled: boolean) => void]>()
    const [toggleReload, setToggleReload] = useState<string>('init')
    const [identities, setIdentities] = useState<Identity[]>([])
    const [identityDetails, setIdentityDetails] = useState<IdentityDetails | null>(null)
    const [identityDetailsOnLoad, setIdentityDetailsOnLoad] = useState<boolean>(false)
    const [identityDetailsOnError, setIdentityDetailsOnError] = useState<boolean>(false)
    const [showModal, setShowModal] = useState<boolean>(false)

    useEffect(() => {
        setDisabled(process.env.NODE_ENV === 'development')

        return () => {
            setDisabled(true)
        }
    }, [setDisabled])

    const showIdentity = (identity: Identity) => {
        setShowModal(true)
        setIdentityDetailsOnLoad(true)

        let details = {IdentityName: identity.IdentityName} as IdentityDetails
        setIdentityDetails(details)

        Api.get('/admin/edition/mailing-identity/domain/' + identity.IdentityName)
            .then((response) => {
                let details = response.data as IdentityDetails
                details.IdentityName = identity.IdentityName
                setIdentityDetails(details)
            })
            .catch(() => {
                setIdentityDetailsOnError(true)
            })
            .then(() => {
                setIdentityDetailsOnLoad(false)
            })
    }

    const deleteIdentity = (identity: Identity) => {

    }

    let table = MailingIdentityTable;

    const actionsRender = (identity: Identity) => (
        <>
            <Button variant="primary" size="sm" onClick={() => showIdentity(identity)}>
                <i className="fa-solid fa-eye me-2"></i>
                {t('app.show')}
            </Button>
            {/*<Button variant="danger" size="sm" onClick={() => {deleteIdentity(identity)}}>
                <i className="fa-solid fa-trash me-2"></i>
                {t('app.delete')}
            </Button>*/}
        </>
    );

    if (table.hasOwnProperty('actions')) {
        table.actions.render = actionsRender
    }

    if (process.env.NODE_ENV === 'development') {
        return <div
            style={{
                height: '100%',
                width: '100%',
                display: 'grid',
                placeItems: 'center',
                fontSize: '2rem',
                color: 'var(--bs-primary)'
            }}
        >
            Not available in dev mode
        </div>
    }

    const copyToClipboard = () => {
        if (identityDetails) {
            navigator.clipboard.writeText(
                identityDetails.DkimAttributes.Tokens.map(token =>
                    `CNAME\t${token}._domainkey.${identityDetails.IdentityName}.\t${token}.dkim.amazonses.com`
                ).join('\n')
            )
        }
    }

    return (
        <>
            <Table path="/admin/edition/mailing-identity/domain" rows={table}
                toggleReload={toggleReload}
                items={identities}
                setItems={setIdentities}
                keyword={search}
                setKeyword={setSearch}
            />
            <EmptyModal show={showModal} size="lg" onClose={() => { setShowModal(false) }} title={identityDetails?.IdentityName}>
                {identityDetailsOnLoad
                    ? <Spinner />
                    : (identityDetailsOnError
                            ? <p className="text-danger">{t('app.error')}</p>
                            : <>
                                <h5>{t('app.dns.params')}</h5>
                                <div className="row justify-content-end">
                                    <div className="col-auto">
                                        <button className="btn btn-primary" onClick={copyToClipboard}>{t('app.copy_to_clipboard')}</button>
                                    </div>
                                </div>
                                <div className="overflow-auto">
                                    <table className="table table-bordered table-large">
                                        <thead>
                                            <tr>
                                                <th>{t('app.type')}</th>
                                                <th>{t('app.name')}</th>
                                                <th>{t('app.value')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {identityDetails?.DkimAttributes.Tokens.map(token =>
                                            <tr>
                                                <td>CNAME</td>
                                                <td>{token}._domainkey.{identityDetails.IdentityName}</td>
                                                <td>{token}.dkim.amazonses.com</td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                    )
                }
            </EmptyModal>
            <FormEmptyModal
                path={`/admin/edition/mailing-identity/domain/add`}
                i18nTitle={'app.mailing_identity.add'}
                show={show}
                handleClose={() => {
                    setShow(false)
                }}
                onSuccess={() => {
                    setToggleReload(new Date().toISOString())
                    setShow(false)
                }}
            />
        </>
    )
}

export default DomainPage
