import React, {FC} from 'react'
import {useTranslation} from "react-i18next";
import {FilterChoice, FilterValues} from "../Listing";
import Filter from "../../../Entity/Collection/ListingSetting/Filter";
import ToggleButton from 'react-bootstrap/ToggleButton'

export interface _ToggleFilterProps {
    filter: Filter
    choices: FilterChoice[]|undefined
    filterValues?: FilterValues
    setFilterValues: (filterValues: FilterValues) => void
}

const _ToggleFilter: FC<_ToggleFilterProps> = ({filter, choices, filterValues, setFilterValues}) => {
    const {t} = useTranslation()

    const getButtonVariant = () => {
        switch(filter.filters){
            case 'archived':
              return 'outline-danger'
    
            case 'withoutFile':
            case 'withoutProduct':
                return 'warning'
            
            default:
                return 'outline-warning'
        }
    }

    return <>
        <ToggleButton
            id={filter.filters}
            type="checkbox"
            variant={getButtonVariant()}
            className={filter.filters === 'archived' ? ' btn-dashed' : undefined}
            checked={filter.filters && filterValues?.hasOwnProperty(filter.filters)}
            value={t(`app.${filter.filters}`)}
            onChange={(e) => {
                e.preventDefault()
                e.stopPropagation()
                const value = e.target.value as string;
                let filterName = filter.filters as string;

                if(filter.filters && !filterValues?.hasOwnProperty(filter.filters)) {
                    setFilterValues({[filterName] : value});
                } else {
                    setFilterValues({})
                }
            }}
        >
            {(filter.filters === 'shoppingLate') && <><i className="fa-solid fa-circle-exclamation"></i>&nbsp;</>}
            {t(`app.${filter.filters}`)}
        </ToggleButton>
    </>
}

export default _ToggleFilter
