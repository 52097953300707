import React, {FC, ReactNode} from "react";
import PasswordType from "./Type/PasswordType";
import EmailType from "./Type/EmailType";
import TextType from "./Type/TextType";
import RepeatedType from "./Type/RepeatedType";
import ChoiceType from "./Type/ChoiceType";
import RepeatedInterface from "../../Definition/Form/Options/RepeatedInterface";
import TextInterface from "../../Definition/Form/Options/TextInterface";
import ChoiceInterface from "../../Definition/Form/Options/ChoiceInterface";
import CheckboxInterface from "../../Definition/Form/Options/CheckboxInterface";
import CheckboxType from "./Type/CheckboxType";
import ColorType from "./Type/ColorType";
import TextareaType from "./Type/TextareaType";
import SearchType from "./Type/SearchType";
import EntityInterface from "Vendor/Definition/Form/Options/EntityInterface";
import EntityType from "./Type/EntityType";
import PhoneType from "./Type/PhoneType";
import TranslationsInterface from "../../Definition/Form/Options/TranslationsInterface";
import TranslationsType from "./Type/TranslationsType";
import CollectionInterface from "../../Definition/Form/Options/CollectionInterface";
import NumberInterface from "../../Definition/Form/Options/NumberInterface";
import NumberType from "./Type/NumberType";
import CollectionType from "./Type/CollectionType";
import {FormSkeletonInterface, FormSubChildrenSkeletonInterface} from "../../Definition/Form/FormBuilderInterface";

export type FormRowProps = {
    name: string
    formName?: string
    field?: FormSkeletonInterface
    data?: any
    setData?: (data: any) => void
    onChange?: (data: any) => void
    children?: ReactNode
    path?: string
    subChildrenSkeleton?: FormSubChildrenSkeletonInterface
    setSubChildrenSkeleton?: (subChildrenSkeleton: FormSubChildrenSkeletonInterface) => void
}

const FormRow: FC<FormRowProps> = ({name, formName, field, data, setData, onChange, children, path, subChildrenSkeleton, setSubChildrenSkeleton}) => {
    if (field) {
        if (['Password', 'Email', 'Text', 'Search', 'Color', 'Phone'].includes(field.type)) {
            let options = field.options as TextInterface
            let FormType = TextType
            if (field.type === 'Password') {
                FormType = PasswordType
            } else if (field.type === 'Email') {
                FormType = EmailType
            } else if (field.type === 'Color') {
                FormType = ColorType
            } else if (field.type === 'Search') {
                FormType = SearchType
            } else if (field.type === 'Phone') {
                FormType = PhoneType
            }

            return <FormType id={`${formName}_${name}`} fullName={`${formName}[${name}]`} name={name} value={data} setValue={setData} {...options} />
        } else if (field.type === 'Number') {
            let options = field.options as NumberInterface
            return <NumberType id={`${formName}_${name}`} fullName={`${formName}[${name}]`} name={name} value={data} setValue={setData} {...options} />
        } else if (field.type === 'Textarea') {
            let options = field.options as TextInterface
            return <TextareaType id={`${formName}_${name}`} fullName={`${formName}[${name}]`} name={name} value={data} setValue={setData} {...options} />
        } else if (field.type === 'Repeated') {
            let options = field.options as RepeatedInterface
            return <RepeatedType formName={formName ?? ''} name={name} value={data} setValue={setData} {...options} />
        } else if (field.type === 'Choice') {
            let options = field.options as ChoiceInterface
            return <ChoiceType id={`${formName}_${name}`} fullName={`${formName}[${name}]`} value={data} setValue={setData} name={name} onChange={onChange} {...options} />
        } else if (field.type === 'Checkbox') {
            let options = field.options as CheckboxInterface
            return <CheckboxType id={`${formName}_${name}`} fullName={`${formName}[${name}]`} checked={data} setValue={setData} name={name} checkedByDefault={options.checkedByDefault ?? false} {...options} />
        } else if (field.type === 'Entity') {
            let options = field.options as EntityInterface
            return <EntityType id={`${formName}_${name}`} fullName={`${formName}[${name}]`} value={data} setValue={setData} name={name} choices={[]} {...options} />
        } else if (field.type === 'Translation') {
            let options = field.options as TranslationsInterface
            return <TranslationsType id={`${formName}_${name}`} fullName={`${formName}[${name}]`} values={data} setValues={setData} name={name} {...options} />
        } else if (field.type === 'Collection') {
            let options = field.options as CollectionInterface
            return <CollectionType subChildrenSkeleton={subChildrenSkeleton ?? {}}
                                   setSubChildrenSkeleton={setSubChildrenSkeleton ?? (() => {})}
                                   path={path ?? 'error'} id={`${formName}_${name}`}
                                   fullName={`${formName}[${name}]`} values={data} setValues={setData}
                                   name={name} {...options}>{children}</CollectionType>
        }

        return (
            <p className="text-alert">Field type unknown : {field.type} for field : {name}</p>
        )
    }

    return (
        <p className="text-alert">No field</p>
    )
}

export default FormRow