import React, {FC, useState} from 'react'
import {useTranslation} from "react-i18next";
import useTranslationDataUtils from "../../../Vendor/Utils/TranslationDataUtils";
import {classUtils as c} from "Vendor/Utils/ClassUtils";

import CollectionNotification from 'Entity/Notification/Collection/CollectionNotification';
import PlatformNotification from 'Entity/Notification/Platform/PlatformNotification';
import ShoppingNotification from 'Entity/Notification/Shopping/ShoppingNotification';
import CollectionEvent from "../../../Entity/Notification/Collection/CollectionEvent";
import PlatformEvent from "../../../Entity/Notification/Platform/PlatformEvent";
import ShoppingEvent from "../../../Entity/Notification/Shopping/ShoppingEvent";
import MentionNotification from 'Entity/Notification/Mention/MentionNotification';
import MentionEvent from 'Entity/Notification/Mention/MentionEvent';

export type NotificationTypes = CollectionNotification|PlatformNotification|ShoppingNotification|MentionNotification

export type _NotificationProps = {
    event: CollectionEvent|PlatformEvent|ShoppingEvent|MentionEvent
    notification: NotificationTypes
    setNotification: (notification?: any) => void
    setShowAddNotificationModal: (show: boolean) => void
    setShowSendTestNotificationModal: (show: boolean) => void
    handleDelete: (notification: NotificationTypes) => void
}

const _Notification: FC<_NotificationProps> = ({event, notification, setNotification, setShowAddNotificationModal, setShowSendTestNotificationModal, handleDelete}) => {
    const {t} = useTranslation()
    const {translate} = useTranslationDataUtils()
    const [open, setOpen] = useState<boolean>(false)
    const [onDelete, setOnDelete] = useState<boolean>(false)

    const renderRecipientSection = () => {
        if (
            notification.administrators !== undefined ||
            (Array.isArray(notification.userGroups) && notification.userGroups.length > 0) ||
            (Array.isArray(notification.users) && notification.users.length > 0) ||
            ['shopping', 'pre-order'].includes(event.collection)
        ) {
            return <h5 className="mb-2">{t('app.recipients')}</h5>
        }
        return null;
    }

    return (
        <>
            <div className={c('notification', open && 'open', onDelete && 'd-none')}>
                <div className="notification-header">
                    <div className="notification-info">
                        {event.collectionEvent === 'Date'
                            ? <>
                                {t('app.day_first_letter')}{((notification.interval &&  notification.interval < 0) ? '-' : '+') + Math.abs(notification.interval ?? 0)}
                            </> : <>
                                {t('app.enum.notification.collection.scheduled.' + notification.scheduled)}
                                {(notification.scheduled === 'Daily' || notification.scheduled === 'Weekly') &&
                                    <> ({notification.days?.map(day => t('app.enum.notification.collection.days.' + day))} {t('app.at')} {notification.time})</>
                                }
                            </>
                        }
                        <span>&nbsp;- {notification.channels.join(', ')}</span>
                    </div>
                    <div className="notification-actions">
                        <button className="stretched-link" onClick={() => {
                            setOpen(!open)
                        }}><i className="fas fa-chevron-down"></i></button>
                    </div>
                </div>
                {open && <div className="notification-body">
                    <div className="d-flex justify-content-end align-items-center">
                        <button className="btn btn-primary me-1" onClick={() => {
                            setNotification(notification)
                            setShowSendTestNotificationModal(true)
                        }}><i className="fas fa-envelope"></i></button>
                        <button className="btn btn-primary me-1" onClick={() => {
                            setNotification(notification)
                            setShowAddNotificationModal(true)
                        }}><i className="fas fa-edit"></i></button>
                        <button className="btn btn-danger" onClick={() => {
                            setOnDelete(true)
                            handleDelete(notification)
                        }}><i className="fa-solid fa-trash"></i></button>
                    </div>
                    {renderRecipientSection()}
                    {['shopping', 'pre-order'].includes(event.collection) &&
                        <><b>{t('app.customer')} {t('app.' + event.collection)}:</b> {notification.shoppingCustomer ? 'Oui' : 'Non'}<br /></>
                    }
                    {notification.administrators !== undefined && <span>
                        <strong>Administateur :</strong> {notification.administrator ? 'Oui' : 'Non'}  <br />
                    </span>}
                    {Array.isArray(notification.userGroups) && notification.userGroups.length > 0 && <span>
                        <strong>Groupes :</strong> {notification.userGroups?.join(', ')}  <br />
                    </span>}
                    {Array.isArray(notification.users) && notification.users.length > 0 && <span>
                        <strong>Utilisateurs : </strong> {notification.users?.join(', ')}  <br />
                    </span>}
                    <h5 className="mb-2 mt-3">Sujet</h5>
                    {translate(notification, 'subject')}
                    <h5 className="my-2">Message</h5>
                    {translate(notification, 'message')}
                </div>}
            </div>
        </>
    )
}

export default _Notification
