import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Header from 'Components/Layout/Header'
import Table from 'Components/Layout/Table'
import ReportingTable from '../../../Table/Admin/Edition/ReportingTable'
import DeleteModal from '../../../Components/Modal/DeleteModal'
import { Button } from 'react-bootstrap'
import FormEmptyOffCanvas from '../../../Components/Modal/FormEmptyOffCanvas'
import Report from 'Entity/Report'

const ReportingPage: FC = () => {
    const { t } = useTranslation()

    const [toggleReload, setToggleReload] = useState<boolean>(true)
    const [show, setShow] = useState<boolean>(false)
    const [search, setSearch] = useState<string>('')
    const [reports, setReports] = useState<Report[]>([])
    const [report, setReport] = useState<Report>({} as Report)
    const handleClose = (reload = false) => {
        setShow(false)
        if (reload) {
            setToggleReload(!toggleReload)
        }
    }
    const handleShow = () => setShow(true)
    const [showDelete, setShowDelete] = useState<boolean>(false)
    const handleDeleteClose = (reload = false) => {
        setReport({} as Report)
        setShowDelete(false)
        if (reload) {
            setToggleReload(!toggleReload)
        }
    }
    const handleDeleteShow = () => setShowDelete(true)

    const onSuccess = (report: Report) => {
        setReport(report)
        setShow(false)
        setToggleReload(!toggleReload)
    }

    const addReport = () => {
        setReport({} as Report)
        handleShow()
    }

    const editReport = (report: Report) => {
        setReport(report)
        setShow(true)
    }

    const deleteReport = (report: Report) => {
        setReport(report)
        handleDeleteShow()
    }

    const actionsRender = (report: Report) => (
        <>
            {report?.isGranted?.edit && (
                <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={() => editReport(report)}
                >
                    <i className="fa-solid fa-pencil me-2"></i>
                    {t('app.edit')}
                </Button>
            )}
            {report?.isGranted?.delete && (
                <Button
                    variant="outline-danger"
                    size="sm"
                    onClick={() => deleteReport(report)}
                >
                    <i className="fa-solid fa-trash me-2"></i>
                    {t('app.delete')}
                </Button>
            )}
        </>
    )

    let table = ReportingTable
    if (table.hasOwnProperty('actions')) {
        table.actions.render = actionsRender
    }

    return (
        <>
            <Header
                title={t('app.reporting')}
                add={addReport}
                search={search}
                setSearch={setSearch}
            />
            <Table
                path="/admin/edition/collection/report-definition"
                rows={table}
                toggleReload={toggleReload}
                items={reports}
                setItems={setReports}
                keyword={search}
                setKeyword={setSearch}
            />
            <FormEmptyOffCanvas
                i18nTitle={report.id ? 'app.edit_report' : 'app.add_report'}
                show={show}
                handleClose={handleClose}
                onSuccess={onSuccess}
                path={`/admin/edition/collection/report-definition/${
                    report.id ?? 'new'
                }`}
                formPath={report.id ? true : false}
                method={report.id ? 'patch' : 'post'}
            />
            <DeleteModal
                handleClose={handleDeleteClose}
                show={showDelete}
                path={'/admin/edition/collection/report-definition/' + (report?.id ?? 'error')}
                name={
                    '#' + (report ? `${report.id} - ${report.name}` : 'error')
                }
                archive={true}
            />
        </>
    )
}

export default ReportingPage
