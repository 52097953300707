import React, {FC, ReactNode} from 'react'
import {ButtonGroup, Dropdown, DropdownButton} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'
import {ButtonVariant} from "react-bootstrap/types";

export type OptionProps = {
    label: string
    value: string | number
}

type DropdownSelectButtonProps = {
    hasGroup: boolean
    i18nLabel?: string
    label?: ReactNode
    options: OptionProps[]
    selected: string | number
    setSelected: (selected: string | number) => void
    variant?: ButtonVariant
    className?: string
}

const DropdownSelectButton: FC<DropdownSelectButtonProps> = ({
     hasGroup,
     label,
     i18nLabel,
     options,
     selected = '',
     setSelected = () => {},
     variant,
     className,
}) => {
    const {t} = useTranslation()

    const getLabel = (value: string|number): string => {
        let label:string = ''

        options.forEach(option => {
            if (option.value === value) {
                label = option.label
            }
        });

        return label
    }

    return (
        <DropdownButton as={hasGroup ? ButtonGroup : undefined} title={
            label ? label : i18nLabel ? t(i18nLabel, {s: typeof selected === 'string' ? t(getLabel(selected)) : selected}) : '--Dropdown--'
        } variant={variant} className={className}>
            {options.map((option, i) => (
                <Dropdown.Item key={i} onClick={() => {
                    setSelected(option.value)
                }} active={selected === option.value}>
                    {t(option.label)}
                </Dropdown.Item>
            ))}
        </DropdownButton>
    )
}

export default DropdownSelectButton
