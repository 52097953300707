import React, {FC, MutableRefObject} from 'react'
import { classUtils as c } from 'Vendor/Utils/ClassUtils'
import EntityInterface from "../Vendor/Definition/EntityInterface";
import {useTranslation} from "react-i18next";

const Timestamp: FC<{
    item: EntityInterface,
    blamable: boolean,
    prepend?: string
    append?: string
    withTime?: boolean
}> = ({item , blamable= true, append, prepend, withTime = false}) => {
    const {t} = useTranslation()
    if (!item.createdAt) {
        return null;
    }

    return <p className="timestamp">
        {prepend && <>{prepend}</>}
        {t('app.timestamp.createdAt')} {(new Date(item.createdAt)).toLocaleDateString()}{withTime && ' ' + (new Date(item.createdAt)).toLocaleTimeString()}
        {item.createdBy && blamable &&
            <>
                {' '} {t('app.timestamp.by')} {item.createdBy.name}
            </>
        }
        {
            item.updatedAt && item.updatedAt != item.createdAt &&
            <>
                {' '} {t('app.timestamp.and')} {t('app.timestamp.updatedAt')} {(new Date(item.updatedAt)).toLocaleDateString()}{withTime && ' ' + (new Date(item.updatedAt)).toLocaleTimeString()}
                {item.updatedBy && blamable &&
                    <>
                        {' '} {t('app.timestamp.by')} {item.updatedBy.name}
                    </>
                }
            </>
        }
        {append && <>{append}</>}
    </p>
}

export default Timestamp
