import React, {FC, useContext, useEffect, useState} from 'react'
import {Link, useLocation, useNavigate, useOutletContext, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Brand from "../../../../Entity/Brand";
import BlankPage from "../../../../Components/Layout/BlankPage";
import LoadingButton from "../../../../Components/Button/LoadingButton";
import {BannerContext} from "../../../../Vendor/Context/BannerContextProvider";
import FormEmpty from "../../../../Vendor/Components/FormEmpty";
import Dropzone from "../../../../Components/Dropzone";


const MainPage: FC = () => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [brand, setBrand] = useOutletContext<[Brand, (brand: Brand) => void]>()
    const [success, setSuccess] = useState<boolean>(false)
    const { setBanner } = useContext(BannerContext);
    const [files, setFiles] = useState<string[]>([])

    useEffect(() => {
        if (brand.logo) {
            setFiles([brand.logo])
        } else {
            setFiles([])
        }
    }, [brand])

    const onSubmit = () => {
        setIsLoading(true)
    }
    const onResponse = () => {
        setIsLoading(false)
    }
    const onSuccess = () => {
        setSuccess(true)
        setBanner({type: 'success', i18nTitle: 'app.save', i18nMessage: 'app.data_saved_message'})
    }

    const onUploadSuccess = (brand: Brand) => {
        setBanner({type: 'success', i18nTitle: 'app.save', i18nMessage: 'app.upload_success_message'})
        setBrand({...brand})
    }

    const onError = () => {
        setSuccess(false)
    }

    return (
        <BlankPage>
            <Dropzone
                uploadPath={`/admin/edition/brand/${brand.id}/upload-logo`}
                deletePath={`/admin/edition/brand/${brand.id}/remove-logo`}
                files={files}
                setFiles={setFiles}
                multiple={false}
                onSuccess={onUploadSuccess}
            />
            <FormEmpty
                path={'/admin/edition/brand/' + brand.id}
                method={brand.id ? 'PATCH' : 'POST'}
                formPath={!!brand.id}
                onSubmit={onSubmit}
                onResponse={onResponse}
                onSuccess={onSuccess}
                onError={onError}
            >
                <div className="row justify-content-end align-items-center">
                    <div className="col-auto">
                        <LoadingButton type="submit" isLoading={isLoading}>
                            {t( 'app.validate' )}
                        </LoadingButton>
                    </div>
                </div>
            </FormEmpty>
        </BlankPage>
    )
}

export default MainPage
