import React, {FC, useState} from "react";
import {Outlet} from "react-router-dom";
import {Navbar as GlobalNavbar} from "Components/Layout/Navbar";
import Header from "Components/Layout/Header";
import {useTranslation} from "react-i18next";

const _Navbar: FC = () => {
    const { t } = useTranslation()
    const [search, setSearch] = useState<string>('')
    const [show, setShow] = useState<boolean>(false)
    const [disabled, setDisabled] = useState<boolean>(true)
    const [handleAdd, setHandleAdd] = useState<(() => void)|undefined>(undefined)

    return (
        <>
            <Header
                title={t('app.mailing_identity.title')}
                search={search}
                setSearch={setSearch}
                add={() => {
                    handleAdd !== undefined && handleAdd()
                    setShow(true)
                }}
                disabled={disabled}
            />
            <GlobalNavbar locationBase='/admin/edition/mailing-identity' items={[
                {
                    i18nLabel: 'app.emails',
                    path: 'email'
                },
                {
                    i18nLabel: 'app.domains',
                    path: 'domain'
                },
            ]} />
            <Outlet context={[show, setShow, search, setSearch, disabled, setDisabled, setHandleAdd]} />
        </>
    )
}

export default _Navbar
