import React, {FC, useState} from 'react'
import {useTranslation} from "react-i18next";
import Table from "Components/Layout/Table";
import UserGroupTable from "Table/Admin/Edition/UserGroupTable";
import UserGroup from "Entity/Organization/UserGroup";
import DeleteModal from "Components/Modal/DeleteModal";
import {useOutletContext} from "react-router-dom";
import {Button} from "react-bootstrap";
import FormEmptyOffCanvas from "Components/Modal/FormEmptyOffCanvas";

const GroupPage: FC = () => {
    const { t } = useTranslation()
    const [toggleReload, setToggleReload] = useState<boolean>(true)
    const [show, setShow, search, setSearch] = useOutletContext<[boolean, (show:boolean) => void, string, (search:string) => void]>()
    const [groups, setGroups] = useState<UserGroup[]>([])
    const [group, setGroup] = useState<UserGroup>({} as UserGroup)
    const handleClose = (reload = false) => {
        setShow(false)
        if (reload) {
            setToggleReload(!toggleReload)
        }
    }
    const handleShow = () => setShow(true)
    // remove
    const [showDelete, setShowDelete] = useState<boolean>(false)
    const handleDeleteClose = (reload = false) => {
        setGroup({} as UserGroup)
        setShowDelete(false)
        if (reload) {
            setToggleReload(!toggleReload)
        }
    }
    const handleDeleteShow = () => setShowDelete(true)

    const onSuccess = (group: UserGroup) => {
        setGroup(group)
        setShow(false)
        setToggleReload(!toggleReload)
    }

    const onExited = () => {
        setGroup({} as UserGroup)
    }

    const editGroup = (group: UserGroup) => {
        setGroup(group)
        handleShow()
    }

    const deleteGroup = (group: UserGroup) => {
        setGroup(group)
        handleDeleteShow()
    }

    const actionsRender = (group: UserGroup) => (
        <>
            {group?.isGranted?.edit &&
            <Button
                variant='outline-primary'
                size='sm'
                onClick={() => editGroup(group)}>
                <i className="fa-solid fa-pencil me-2"></i>
                {t('app.edit')}</Button>
            }
            {group?.isGranted?.delete &&
            <Button
                variant='outline-danger'
                size='sm'
                onClick={() => deleteGroup(group)}>
                <i className="fa-solid fa-trash me-2"></i>
                {t('app.delete')}</Button>
            }
        </>
    );

    let table = UserGroupTable;
    if (table.hasOwnProperty('actions')) {
        table.actions.render = actionsRender;
    }

    return (
        <>
            <Table
                path="/admin/edition/user/group"
                rows={table}
                toggleReload={toggleReload}
                items={groups}
                setItems={setGroups}
                keyword={search}
                setKeyword={setSearch}
            />
            <FormEmptyOffCanvas
                i18nTitle={group.id ? 'app.edit_user_group' : 'app.add_user_group'}
                show={show}
                handleClose={handleClose}
                onSuccess={onSuccess}
                path={`/admin/edition/user/group/${group.id ?? 'new'}`}
                formPath={!!group.id}
                method={group.id ? 'patch' : 'post'}
                onExited={onExited}
            />
            <DeleteModal
                handleClose={handleDeleteClose}
                show={showDelete}
                path={'/admin/edition/user/group/' + (group?.id ?? 'error')}
                name={'#' + (group ? `${group.id} - ${group.name}` : 'error')}
                archive={true}
            />
        </>
    )
}

export default GroupPage
