import React, {FC, useEffect, useRef, useState} from 'react'
import {useTranslation} from "react-i18next";
import {FilterChoice, FilterValues} from "../Listing";
import {Dropdown} from "react-bootstrap";
import useTranslationDataUtils from "../../../Vendor/Utils/TranslationDataUtils";
import {DataType} from "../../../Vendor/Components/Form";
import Filter from "../../../Entity/Collection/ListingSetting/Filter";
import Collection, {getShortcut} from "../../../Config/Collection";
import collection from "../../../Config/Collection";
import Choice from "../../../Entity/Property/Choice";

export type RangeType = 'date' | 'number'

export interface _ChoiceFilterProps {
    entity?: Collection
    id?: number
    filter: Filter
    choices: FilterChoice[]|undefined
    filterValues?: FilterValues
    setFilterValues: (filterValues: FilterValues) => void
    setNewSearch: (criteria: DataType) => void
    setDropdownOpen: (isOpen: boolean) => void
    isOpen: boolean
}

const _ChoiceFilter: FC<_ChoiceFilterProps> = ({entity, id, filter, choices, filterValues, setFilterValues, setNewSearch, setDropdownOpen, isOpen}) => {
    const {t} = useTranslation()
    const { translate, translateField } = useTranslationDataUtils()
    const select2Ref = useRef<HTMLSelectElement | null>(null)
    const [options, setOptions] = useState<number>(0)
    const [totalOptions, setTotalOptions] = useState<number>(0)

    useEffect(() => {
        if (select2Ref.current && entity) {
            const $select = $(select2Ref.current)
            const apiBasePath = 'https://api.' + window.location.host.split(':')[0] + (process.env.REACT_APP_BACKEND_PORT ? ':' + process.env.REACT_APP_BACKEND_PORT: '')

            // /listing/b/1/filter/ratio/choices

            let shortcut = getShortcut(entity)
            if (!shortcut) {
                return;
            }
            let slug = ['crm', 'shopping'].includes(shortcut) ? entity : id;


            let url = `/listing/${shortcut}/${slug}/filter/${filter.slug}/choices`

            $select.select2({
                theme: "bootstrap-5",
                language: {
                    searching: function () {
                        return t('app.loading')
                    },
                    noResults: function () {
                        return t('app.no_result')
                    }
                },
                // closeOnSelect: false,
                ajax: {
                    url: apiBasePath + url,
                    dataType: 'json',
                    xhrFields: {
                        withCredentials: true
                    },
                    processResults: function (data: any) {
                        let length = 0
                        if (typeof data.choices !== 'undefined') {
                            length = data.choices.length
                        }
                        setOptions(length)
                        let totalChoices = 0
                        if (typeof data.totalChoices !== 'undefined') {
                            totalChoices = data.totalChoices
                        }
                        setTotalOptions(totalChoices)

                        let results = []
                        if (typeof data.choices !== 'undefined') {
                            results = data.choices.map((choice: Choice) => ({
                                id: choice.value,
                                text: typeof choice.key === 'string'? (choice.key.includes('.') ? t(choice.key) : choice.key) : (choice.key ? translateField(choice.key, 'label') : choice.value),
                            }));
                        }

                        return { results: results}
                    }
                }
            })

            // INITIALIZE SELECTED OPTIONS
            if (filterValues) {
                const selectedKeys = Object.keys(filterValues)
                $select.val(selectedKeys).trigger('change')
            }

            $select.on('select2:select', (event) => {
                const selectedValue = event.params.data.id
                const selectedLabel = event.params.data.text
                const selectedOption = event.params.data.element

                // @TODO
                // const criteriaValue = $(selectedOption).data('criteria')
                // if (criteriaValue) {
                //     setNewSearch(criteriaValue)
                //     return
                // }

                let tempFilterValues = { ...filterValues }
                let key = ''
                if (filter.property?.type === 'checkbox') {
                    key += translate(filter.property, 'label') + ': '
                }
                key += selectedLabel
                tempFilterValues[selectedValue] = key

                setFilterValues(tempFilterValues)
            })

            $select.on('select2:unselect', (event) => {
                const selectedValue = event.params.data.id

                let tempFilterValues = { ...filterValues }
                if (tempFilterValues.hasOwnProperty(selectedValue)) {
                    delete tempFilterValues[selectedValue]
                }

                setFilterValues(tempFilterValues)
            })

            $select.on('select2:close', (event) => {
                setDropdownOpen(false)
            })

            return () => {
                $select.off('select')
                $select.off('unselect')
                $select.off('close')
                $select.select2('destroy')
            }
        }
    }, [entity, filter.property, filter.slug, filterValues, id, setDropdownOpen, setFilterValues, t, translate, translateField])

    useEffect(() => {
        if (isOpen) {
            // TRIGGER SELECT2 OPEN
            setTimeout(() => {
                if (select2Ref.current) {
                    $(select2Ref.current).select2('open')
                }
            }, 10)
        }
    }, [isOpen])

    return <>
        <Dropdown.Item key={0} className="choice-filter">
            <select ref={select2Ref} className="form-select d-flex justify-content-between" multiple></select>
        </Dropdown.Item>
        <p className="mt-2 mb-0">{totalOptions > options ? t('app.remaining_result', { count: totalOptions - options }) : ''}</p>
    </>
}

export default _ChoiceFilter
