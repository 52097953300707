import React, {ClassAttributes, FC, HTMLInputTypeAttribute, InputHTMLAttributes, useEffect, useState} from 'react'
import { classUtils as c } from 'Vendor/Utils/ClassUtils'
import { useTranslation } from 'react-i18next'
import useTranslationDataUtils from "../../../Utils/TranslationDataUtils";
import {TranslationField} from "../../../../Components/Listing/Listing";

export interface TextProps extends InputHTMLAttributes<HTMLInputElement>, ClassAttributes<HTMLInputElement> {
    id: string
    name: string
    fullName: string
    value: string
    setValue?: (value: string) => void
    floating?: boolean
    label?: string
    i18nLabel?: string
    i18nLabels?: TranslationField
    className?: string
    required?: boolean
    disabled?: boolean
    type?: HTMLInputTypeAttribute
    i18nHelp?: string
}

const TextType: FC<TextProps> = ({
    id,
    name,
    fullName,
    value,
    setValue,
    floating = true,
    label,
    i18nLabel,
    i18nLabels,
    className,
    type = 'text',
    required = true,
    disabled = false,
    i18nHelp = null,
    ...rest
}) => {
    const { t } = useTranslation()
    const {translateField} = useTranslationDataUtils()
    const [textLabel] = useState<string>(label ? label : i18nLabels ? translateField(i18nLabels) : t(i18nLabel ?? `app.${name}`))

    if (!floating) {
        return (
            <input
                id={id}
                className={c('form-control', className)}
                name={fullName}
                placeholder={textLabel}
                required={required}
                value={value ?? ''}
                onChange={(e) => !disabled && setValue && setValue(e.target.value)}
                type={type}
                disabled={disabled}
                {...rest}
            />
        )
    }

    if (type === 'number') {
        return (
            <div className="form-floating mb-3">
                <input
                    id={id}
                    className={c('form-control', className)}
                    name={fullName}
                    placeholder={textLabel}
                    required={required}
                    value={value ?? ''}
                    onChange={(e) => !disabled && setValue && setValue(e.target.value)}
                    type={type}
                    disabled={disabled}
                    {...rest}
                />
                <label htmlFor={id}>
                    {textLabel} :
                </label>
            </div>
        )
    }

    return (
        <div className="form-floating mb-3">
            <input
                id={id}
                name={fullName}
                className={c('form-control', className)}
                placeholder={textLabel}
                required={required}
                value={value ?? ''}
                onChange={(e) => !disabled && setValue && setValue(e.target.value)}
                type={type}
                disabled={disabled}
                {...rest}
            />
            <label htmlFor={id}>
                {textLabel} :
            </label>
            {i18nHelp &&
                <p
                    id={name + '_help'}
                    className="form-text mb-0 help-text"
                >
                    {t(i18nHelp)}
                </p>
            }
        </div>
    )
}

export default TextType
