import React, {FC, useEffect, useState} from 'react'
import Spinner from "./Spinner";

const UnderConstruction: FC = () =>
<div
    style={{
        height: '100%',
        width: '100%',
        display: 'grid',
        placeItems: 'center',
        fontSize: '25vh',
        color: 'var(--bs-secondary)'
    }}
>
    <i className="fa-regular fa-triangle-person-digging"></i>
</div>

export default UnderConstruction
