import {RouteObject} from "react-router-dom";
import IndexPage from "../../Pages/Security/ResetPassword/IndexPage";
import CheckEmailPage from "../../Pages/Security/ResetPassword/CheckEmailPage";
import ResetPage from "../../Pages/Security/ResetPassword/ResetPage";

const ResetPasswordRouting: RouteObject[] = [
    {
        index: true,
        element: <IndexPage />,
    },
    {
        path: 'check-email',
        element: <CheckEmailPage expirationMessage={'app.security'} />,
    },
    {
        path: 'reset/:token',
        element: <ResetPage />,
    },
    {
        path: 'reset',
        element: <ResetPage />,
    },
]

export default ResetPasswordRouting