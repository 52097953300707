import React, {FC} from "react";
import {useTranslation} from "react-i18next";

export type FormErrorProps = {
    name?: string
    messages?: string[]
}

const FormError: FC<FormErrorProps> = ({name, messages}) => {
    const { t } = useTranslation()

    return <>
        {messages && messages.length && (
            <div className="alert alert-danger">
                {messages.length > 1 ? (
                    <ul>
                        {messages.map(message => (
                            <li>{t(message)}</li>
                        ))}
                    </ul>
                ) : <>{t(messages[0])}</>}
            </div>
        )}
    </>
}

export default FormError