import appEn from "./app.en";
import appFr from "./app.fr";
import errorFr from "./error.fr";
import errorEn from "./error.en";

const resources = {
    en: { translation: { app: appEn, error: errorEn } },
    fr: { translation: { app: appFr, error: errorFr } },
}

export default resources