import React, {FC, useState} from 'react'
import {useTranslation} from "react-i18next";
import Header from "Components/Layout/Header";
import Table from "Components/Layout/Table";
import DeleteModal from "Components/Modal/DeleteModal";
import NotificationTable from "Table/Admin/Edition/NotificationTable";
import {Button} from "react-bootstrap";
import RefreshModal from "../../../../Components/Modal/RefreshModal";
import FormEmptyModal from "../../../../Components/Modal/FormEmptyModal";

import ShoppingEvent from "../../../../Entity/Notification/Shopping/ShoppingEvent";
import ShoppingEventOffCanvas from 'Components/Offcanvas/Notification/ShoppingEventOffCanvas';

const NotificationShoppingPage: FC = () => {
    const { t } = useTranslation()
    const [toggleReload, setToggleReload] = useState<string>('')
    const [modalShow, setModalShow] = useState<boolean>(false)
    const [offCanvasShow, setOffCanvasShow] = useState<boolean>(false)
    const [search, setSearch] = useState<string>('')
    const [showRefreshModal, setShowRefreshModal] = useState<boolean>(false)
    
    const [notificationEvents, setNotificationEvents] = useState<ShoppingEvent[]>([])
    const [notificationEvent, setNotificationEvent] = useState<ShoppingEvent|undefined>(undefined)
    
    const handleModalClose = (reload = false) => {
        setModalShow(false)
        if (reload) {
            setToggleReload(new Date().toISOString())
        }
    }
    const handleModalShow = () => setModalShow(true)
    const handleOffCanvasShow = () => setOffCanvasShow(true)
    // remove
    const [showDelete, setShowDelete] = useState<boolean>(false)
    const handleDeleteClose = (reload = false) => {
        setNotificationEvent(undefined)
        setShowDelete(false)
        if (reload) {
            setToggleReload(new Date().toISOString())
        }
    }
    const handleDeleteShow = () => setShowDelete(true)

    const onSuccess = (notificationEvent: ShoppingEvent) => {
        setNotificationEvent(notificationEvent)
        setModalShow(false)
        setToggleReload(new Date().toISOString())
    }

    const addNotificationEvent = () => {
        setNotificationEvent(undefined)
        handleModalShow()
    }

    const editNotificationEvent = (notificationEvent: ShoppingEvent) => {
        setNotificationEvent(notificationEvent)
        handleOffCanvasShow()
    }

    const deleteNotificationEvent = (notificationEvent: ShoppingEvent) => {
        setNotificationEvent(notificationEvent)
        handleDeleteShow()
    }

    const actionsRender = (notificationEvent: ShoppingEvent) => (
        <>
            {notificationEvent?.isGranted?.edit &&
                <Button
                    variant='outline-primary'
                    size='sm'
                    onClick={() => editNotificationEvent(notificationEvent)}>
                    <i className="fa-solid fa-eye me-2"></i>
                    {t('app.show')}</Button>
            }
            {notificationEvent?.isGranted?.delete &&
                <Button
                    variant='outline-danger'
                    size='sm'
                    onClick={() => deleteNotificationEvent(notificationEvent)}>
                    <i className="fa-solid fa-trash me-2"></i>
                    {t('app.delete')}</Button>
            }
        </>
    );

    const renderStatus = (notificationEvent: ShoppingEvent) => {
        let result: string = t('app.item_status.online') // @TODO
        
        return result
    }

    const renderTarget = (notificationEvent: ShoppingEvent) => {
        // let result: string = notificationEvent.shopping.name // @TODO
        
        return ''
    }

    const renderEvent = (notificationEvent: ShoppingEvent) => {
        let result: string = t('app.enum.shopping.event.' + notificationEvent.shoppingEvent)

        return result
    }

    let table = NotificationTable;
    if (table.hasOwnProperty('status')) {
        table.status.render = (notificationEvent: ShoppingEvent) => renderStatus(notificationEvent)
    }
    if (table.hasOwnProperty('target')) {
        table.target.render = (notificationEvent: ShoppingEvent) => renderTarget(notificationEvent)
    }
    if (table.hasOwnProperty('event')) {
        table.event.render = (notificationEvent: ShoppingEvent) => renderEvent(notificationEvent)
    }
    if (table.hasOwnProperty('actions')) {
        table.actions.render = actionsRender;
    }

    return (
        <>
            <Header title={t('app.notification_types.shopping')}
                    add={addNotificationEvent}
                    search={search}
                    setSearch={setSearch}
            />
            <Table
                path="/admin/edition/notification/shopping"
                rows={table}
                toggleReload={toggleReload}
                items={notificationEvents}
                setItems={setNotificationEvents}
                keyword={search}
                setKeyword={setSearch}
            />
            <ShoppingEventOffCanvas
                show={offCanvasShow}
                handleClose={() => setOffCanvasShow(false)}
                id={notificationEvent?.id}
                setInfoShow={setModalShow}
                setToggleReload={setToggleReload}
            />
            <FormEmptyModal
                i18nTitle={notificationEvent?.id ? 'app.edit_event' : 'app.add_event'}
                show={modalShow}
                handleClose={handleModalClose}
                onSuccess={onSuccess}
                path={`/admin/edition/notification/shopping/${notificationEvent?.id ?? 'new'}`}
                formPath={notificationEvent?.id !== undefined ? true : false}
                method={notificationEvent?.id !== undefined ? 'patch' : 'post'}
            />
            <DeleteModal
                handleClose={handleDeleteClose}
                show={showDelete}
                path={'/admin/edition/notification/shopping/' + (notificationEvent?.id ?? 'error')}
                name={'#' + (notificationEvent ? `${notificationEvent.id} - ${notificationEvent.translations}` : 'error')}
            />
            <RefreshModal show={showRefreshModal} />
        </>
    )
}

export default NotificationShoppingPage
