import React, {FC, useContext, useEffect, useState} from 'react'
import {FormOptionsInterface, FormPropertiesFieldInterface} from "Vendor/Components/Form";
import Company from "Entity/Company";
import {UserContext} from "../../Vendor/Context/UserContextProvider";
import {useOutletContext} from "react-router-dom";
import ListingSetting from "../../Entity/Collection/ListingSetting";
import EntityInterface from "../../Vendor/Definition/EntityInterface";
import Api from "../../Vendor/Api";
import Listing from "../../Components/Listing/Listing";
import DeleteModal from "../../Components/Modal/DeleteModal";
import FormEmptyOffCanvas from "../../Components/Modal/FormEmptyOffCanvas";
import _ImportModalTags from "../../Components/Modal/_ImportModalTags";
import Tag from 'Entity/Tag';

const CompanyPage: FC = () => {
    const { currentUser } = useContext(UserContext)
    const [show, setShow, search, setSearch, disabled, setDisabled, setHandleAdd] = useOutletContext<[boolean, (show:boolean) => void, string, (search:string) => void, boolean, (disabled: boolean) => void, (handleAdd: (() => void)|undefined) => void]>()
    const [setting] = useState<ListingSetting|undefined>(currentUser?.currentOrganization?.companyListingSetting)
    const [company, setCompany] = useState<Company>({} as Company)
    const [toggleReload, setToggleReload] = useState<string>('')
    const [propertyFields, setPropertyFields] = useState<FormPropertiesFieldInterface|undefined>(undefined)
    const [formOptions, setFormOptions] = useState<FormOptionsInterface|undefined>(undefined)
    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const [localTags, setLocalTags] = useState<Tag[]>([])

    useEffect(() => {
        setHandleAdd(() => {
            setCompany({} as Company)
        })
    }, [setHandleAdd])

    useEffect(() => {
        if(company.tags){
            setLocalTags([...company.tags])
        }
    }, [company])

    const handleClose = (reload = false) => {
        setShow(false)
        if (reload) {
            setToggleReload(new Date().toISOString())
        }
    }

    const onExited = () => {
        setCompany({} as Company)
    }

    const onSuccess = (company: Company) => {
        setCompany(company)
        setShow(false)
        setToggleReload(new Date().toISOString())
    }

    const editAction = (company: EntityInterface) => {
        setCompany(company as Company)
        setShow(true)
    }

    const deleteAction = (company: EntityInterface) => {
        setCompany(company as Company)
        handleDeleteShow()
    }

    // remove
    const [showDelete, setShowDelete] = useState<boolean>(false)
    const handleDeleteClose = (reload = false) => {
        setCompany({} as Company)
        setShowDelete(false)
        if (reload) {
            setToggleReload(new Date().toISOString())
        }
    }
    const handleDeleteShow = () => setShowDelete(true)

    useEffect(() => {
        setDisabled(true)
        Api.get(`/crm/company/${company.id ?? 'new'}`).then(response => {
            const formOptions: FormOptionsInterface = response.data
            setFormOptions(formOptions)
            setPropertyFields(formOptions.propertyFields)
            setDisabled(false)
        })
    }, [])

    if (!setting) return <></>

    return (
        <>
            <Listing setting={setting} search={search} setSearch={setSearch} path='/crm/company' entity='company' toggleReload={toggleReload} setToggleReload={setToggleReload} editAction={editAction} deleteAction={deleteAction} />
            <FormEmptyOffCanvas
                i18nTitle={company.id ? 'app.edit_company' : 'app.add_company'}
                show={show}
                handleClose={handleClose}
                onSuccess={onSuccess}
                path={`/crm/company/${company.id ?? 'new'}`}
                formPath={!!company.id}
                method={company.id ? 'patch' : 'post'}
                onExited={onExited}
                disabled={!company.id ? false : company.deletedAt !== null}
                isLoading={isLoaded}
                setIsLoading={setIsLoaded}
                additionalData={{
                    tags: localTags,
                }}
                appendForm={company && company.enableTags && <_ImportModalTags tags={localTags} setTags={setLocalTags} onLoad={isLoaded} headingLevel={3}/>}
            />
            <DeleteModal
                handleClose={handleDeleteClose}
                show={showDelete}
                path={`/crm/company/${company.id}`}
                name={`#${company.id} - ${company.name}`}
                archive={true}
            />
        </>
    )
}

export default CompanyPage
