import React, {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    Alert,
    Button, Modal,
    ModalBody as Body,
    ModalFooter as Footer,
    ModalHeader as Header,
    ModalTitle as Title
} from "react-bootstrap";
import GroupedAction from "Entity/Collection/ListingSetting/GroupedAction";
import LoadingButton from "../../Button/LoadingButton";
import {getRequestFilters, MailingActionModal} from "../Listing";
import Api from "Vendor/Api";
import Collection from "Config/Collection";
import {createArrUtils} from "../../../Vendor/Utils/CreateArrayUtils";
import TextType from "../../../Vendor/Components/Form/Type/TextType";
import FormEmpty from "../../../Vendor/Components/FormEmpty";
import Property from "../../../Entity/Property";
import {classUtils as c} from "../../../Vendor/Utils/ClassUtils";
import Loader from "../../../Vendor/Components/Loader";

export interface _GenerateStickersModalProps {
    id: number
    showActionModals: {[key: string]: boolean|Property}
    handleShowActionModal: (groupedAction?: GroupedAction, actionFromMain?:boolean) => void
    fromMain: boolean
    handleShowMainActionModal: () => void
    selected: number[]
    actionModalFormIsLoading: boolean
    actionModalFormIsLoaded: boolean,
    setActionModalFormIsLoaded: (isLoaded: boolean) => void,
    handleActionModalOnResponse: () => void
    handleActionModalOnSuccess: () => void
    handleActionModalOnSubmit: () => void
}

const _GenerateStickersModal: FC<_GenerateStickersModalProps> = ({
                                                 id,
                                                 showActionModals,
                                                 handleShowActionModal,
                                                 fromMain,
                                                 handleShowMainActionModal,
                                                 selected,
                                                 actionModalFormIsLoading,
                                                 actionModalFormIsLoaded,
                                                 setActionModalFormIsLoaded,
                                                 handleActionModalOnResponse,
                                                 handleActionModalOnSuccess,
                                                 handleActionModalOnSubmit,
}) => {
    const {t} = useTranslation()
    const [quantity, setQuantity] = useState<number>(0)

    useEffect(() => {
        setActionModalFormIsLoaded(true)
        return () => {
            setQuantity(0)
        }
    }, [setActionModalFormIsLoaded])

    const handleSubmit = (e: any) => {
        e.preventDefault()
        Api.post(`/grouped-action/${id}/sticker/generate`, {
            quantity: quantity,
            items: selected
        }).then(response => {
            handleActionModalOnSuccess()
            setQuantity(0)
        }).catch(error => {}).then(e => {
            handleActionModalOnResponse()
        })

        handleActionModalOnSubmit()
    }

    return <Modal show={showActionModals.generateStickers !== false} onHide={handleShowActionModal}>
        <Header closeButton={!fromMain}>
            <div className="modal-block-header">
                {fromMain && <i className="fa-solid fa-angle-left" onClick={handleShowMainActionModal}></i>}
                <Title>{t(`app.groupedActionTitle.generate_stickers`, {count: selected.length})}</Title>
            </div>
        </Header>
        <Body>
            <form noValidate onSubmit={handleSubmit} aria-disabled={false}>
                <div>
                    <div className="form-floating mb-3">
                        <input
                            type="number"
                            className="form-control"
                            id="generate-sticker-quantity"
                            min={0}
                            onChange={e => setQuantity(Number(e.target.value))}
                            value={quantity}
                            required
                        />
                        <label
                            htmlFor="generate-sticker-quantity"
                            className="form-label required"
                        >
                            {t('app.quantityGenerate')}
                        </label>
                    </div>
                </div>
                <div className="row justify-content-end align-items-center mb-3">
                    <div className="col-auto">
                        {!fromMain && <Button variant="secondary" onClick={() => {
                            handleShowActionModal()
                        }}>
                            {t('app.cancel')}
                        </Button>}
                        <LoadingButton type="submit" isLoading={actionModalFormIsLoading} disabled={quantity < 1}>
                            {t('app.generate')}
                        </LoadingButton>
                    </div>
                </div>
            </form>
        </Body>
    </Modal>
}

export default _GenerateStickersModal
