import React, {FC, useContext, useState} from 'react'
import Listing, {CustomAction} from "Components/Listing/Listing";
import Header from "../Components/Layout/Header";
import {useTranslation} from "react-i18next";
import ListingSetting from "../Entity/Collection/ListingSetting";
import SorterEnum from "../Config/Collection/Sorter";
import direction from "../Config/Direction";
import User from "../Entity/User";
import {Button} from "react-bootstrap";
import FormEmptyOffCanvas from "../Components/Modal/FormEmptyOffCanvas";
import DeleteModal from "../Components/Modal/DeleteModal";
import Api from "../Vendor/Api";
import {UserContext} from "../Vendor/Context/UserContextProvider";
import EntityInterface from "../Vendor/Definition/EntityInterface";

export interface UserProps {
}

const UserPage: FC<UserProps> = () => {
    const {t} = useTranslation()
    const { currentUser, setCurrentUser, setInit } = useContext(UserContext)
    const [toggleReload, setToggleReload] = useState<string>('')
    const [search, setSearch] = useState<string>('')
    const [show, setShow] = useState<boolean>(false)
    const [setting] = useState<ListingSetting>({
        cardCondensedByDefault: false,
        cardShowBadge: false,
        cardShowStatus: false,
        cardShowSchedule: true,
        cardShowSizeAndWeight: null,
        filters: [],
        groupedActions: [],
        kanbanDatas: [],
        cardDatas: [],
        searchable: [],
        searchableByEmail: false,
        searchableByFirstName: false,
        searchableByLastName: false,
        searchableByName: false,
        sorters: [
            {
                sorter: 'updatedAt',
                defaultSort: true,
                defaultDirection: 'desc'
            }
        ],
        tableColumns: [
            {
                columnType: 'name',
                visibleByDefault: true,
                render: (user: User) => (
                    <>
                        {UserName({ user: user })}
                        {!user.active && (
                            <span className="badge bg-info ms-1">
                                {t('app.waiting_validation')}
                            </span>
                        )}
                        {user.administrator && (
                            <span className="badge bg-warning ms-1">
                                {t('app.administrator')}
                            </span>
                        )}
                    </>
                ),
            },
            {
                columnType: 'email',
                visibleByDefault: true,
            },
            {
                columnType: 'group',
                visibleByDefault: true,
                field: 'name',
                i18nPlaceholder: 'app.ungrouped',
            },
        ],
        views: ['table']
    })
    const [user, setUser] = useState<User>({} as User)

    const handleClose = (reload = false) => {
        setShow(false)
        if (reload) {
            setToggleReload(new Date().toISOString())
        }
    }

    const handleShow = () => setShow(true)
    // remove
    const [showDelete, setShowDelete] = useState<boolean>(false)
    const handleDeleteClose = (reload = false) => {
        setUser({} as User)
        setShowDelete(false)
        if (reload) {
            setToggleReload(new Date().toISOString())
        }
    }
    const handleDeleteShow = () => setShowDelete(true)

    const onSuccess = (user: User) => {
        setUser(user)
        setShow(false)
        setToggleReload(new Date().toISOString())
    }

    const onExited = () => {
        setUser({} as User)
    }

    const editUser = (user: EntityInterface) => {
        setUser(user as User)
        handleShow()
    }

    const deleteUser = (user: EntityInterface) => {
        setUser(user as User)
        handleDeleteShow()
    }

    const handleSwitch = (user: User) => {
        setInit(false)
        Api.get(`/switch-user?_switch_user=${encodeURIComponent(user.email)}`)
            .then(({ data }) => {
                setCurrentUser(data)
            })
            .catch((err) => {
            })
            .then(() => {
                setInit(true)
            })
    }

    const UserName = ({ user }: { user: User }) => {
        return user.firstName || user.lastName ? (
            `${user.firstName} ${user.lastName}`
        ) : (
            <i>{t('app.undefined')}</i>
        )
    }

    const customActions: CustomAction[] = [
        {
            method: handleSwitch,
            granted: 'CAN_SWITCH_USER',
            icon: 'person-to-portal',
            variant: 'primary',
        }
    ]

    return <>
        <Header
            title={t('app.users')}
            search={search}
            setSearch={setSearch}
            add={currentUser?.administrator ? (() => {
                setUser({} as User)
                handleShow()
            }) : undefined}
        />
        <Listing
            path="/user"
            search={search}
            setSearch={setSearch}
            setting={setting}
            selectable={false}
            toggleReload={toggleReload}
            setToggleReload={setToggleReload}
            editAction={editUser}
            deleteAction={deleteUser}
            customActions={customActions}
        />
        <FormEmptyOffCanvas
            i18nTitle={user.id ? 'app.edit_user' : 'app.add_user'}
            show={show}
            handleClose={handleClose}
            onSuccess={onSuccess}
            path={`/user/${user.id ?? 'new'}`}
            formPath={!!user.id}
            method={user.id ? 'patch' : 'post'}
            onExited={onExited}
        />
        <DeleteModal
            handleClose={handleDeleteClose}
            show={showDelete}
            path={'/user/' + (user?.id ?? 'error')}
            name={'#' + (user ? `${user.id} - ${user.email}` : 'error')}
            archive={true}
        />
    </>
}

export default UserPage
