type GroupedAction = 'download' | 'export' | 'remove' | 'share' | 'mailingList' | 'publish' | 'unPublish' | 'shopping' | 'preOrder' | 'generateStickers' | 'archiveStickers' | 'printStickers' | 'group' | 'tag' | 'transform' | 'reporting' | 'rename'

export default GroupedAction

export const GroupedActionIcon = {
    download: 'cloud-arrow-down',
    export: 'file-spreadsheet',
    remove: 'trash-can',
    share: 'share-nodes',
    mailingList: 'envelopes-bulk',
    publish: 'signal-bars',
    unPublish: 'signal-slash',
    shopping: 'shopping-cart',
    preOrder: 'shopping-cart',
    generateStickers: 'plus',
    archiveStickers: 'box-archive',
    printStickers: 'print',
    group: 'layer-group',
    tag: 'tags',
    transform: 'wand-magic-sparkles',
    rename: 'i-cursor',
    reporting : 'flag',
    restore: 'trash-can-undo'
}
