import {RouteObject} from "react-router-dom";
import React from "react";
import {DashboardPage} from "Pages/Admin/DashboardPage";
import OrganizationPage from "Pages/Admin/OrganizationPage";
import UserPage from "Pages/Admin/UserPage";
import MailingIdentityPage from "../Pages/Admin/MailingIdentityPage";

const AdminRouting: RouteObject[] = [
    {
        index: true,
        element: <DashboardPage />,
    },
    {
        path: 'organization',
        element: <OrganizationPage />,
    },
    {
        path: 'user',
        element: <UserPage />,
    },
    {
        path: 'mailing-identity',
        element: <MailingIdentityPage />,
    }
]

export default AdminRouting
