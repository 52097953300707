import React, { FC, ReactElement } from 'react'
import logo from 'Assets/images/logo.svg'

type Props = {
    children: ReactElement
}

export const PublicModal: FC<Props> = ({ children }) => {
    return (
        <div className="container">
            <div className="row justify-content-center align-items-center">
                <div className="col">
                    <div
                        className="modal position-static d-block"
                        id="loginModal"
                        aria-labelledby="loginModalLabel"
                        aria-hidden="false"
                    >
                        <div className="modal-dialog">
                            <div className="logo mb-4 text-center">
                                <img src={logo} alt="Daryus" />
                            </div>
                            <div className="modal-content">
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
