import {Rows} from "Components/Layout/Table";

const BrandTable: Rows = {
    id: {
        placeholders: [
            {
                element: 'p',
                size: 1
            }
        ]
    },
    code: {
        placeholders: [
            {
                element: 'p',
                size: [2],
            }
        ]
    },
    name: {
        placeholders: [
            {
                element: 'p',
                size: [3, 1],
            }
        ]
    },
    actions: {
        className: 'actions',
        sortable: false,
        placeholders: [
            {
                element: 'a',
                size: 2,
            },
        ]
    },
};

export default BrandTable