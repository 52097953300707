import React, {FC, useEffect, useState} from "react";
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import {Navbar as GlobalNavbar, NavbarItem} from "../../../../Components/Layout/Navbar";
import Header from "../../../../Components/Layout/Header";
import GlobalLoader from "Vendor/Components/GlobalLoader";
import useTranslationData from "Vendor/Utils/TranslationDataUtils";
import Api from "Vendor/Api";
import DataCollection from "../../../../Entity/DataCollection";

const Navbar: FC = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    const {translate} = useTranslationData()
    const [dataCollection, setDataCollection] = useState<DataCollection|undefined>(undefined)
    const [show, setShow] = useState<boolean>(false)
    const [search, setSearch] = useState<string>('')
    const [handleAdd, setHandleAdd] = useState<(() => void)|undefined>(undefined)
    const [items, setItems] = useState<NavbarItem[]>([])
    const [showAddButton, setShowAddButton] = useState<boolean>(true)
    const [showSearch, setShowSearch] = useState<boolean>(true)

    useEffect(() => {
        if (location && location.state && typeof location.state === 'object') {
            let dataCollection = location.state as DataCollection
            setDataCollection(dataCollection);
        }
        else {
            let id = params.id;
            Api.get('/admin/edition/data-collection/' + id)
                .then((response ) => {
                    let dataCollection = response.data as DataCollection
                    setDataCollection(dataCollection)
                })
        }
    }, [location, params])

    useEffect(() => {
        let tempItems: NavbarItem[] = []
        if (dataCollection) {
            tempItems.push({
                i18nLabel: 'app.main',
                path: ''
            })
            tempItems.push({
                i18nLabel: 'app.listing',
                path: 'listing'
            })
            tempItems.push({
                i18nLabel: 'app.export_config',
                path: 'export-config'
            })
            tempItems.push({
                i18nLabel: 'app.item_groups',
                path: 'group'
            })
        }
        setItems(tempItems)
    }, [dataCollection])

    return (
        <>
            {!dataCollection
                ? <GlobalLoader />
                : <>
                    <Header title={dataCollection ? translate(dataCollection, 'name') : ''} prev={() => {
                        navigate('/admin/edition/data-collection')
                    }}  search={showSearch ? search : undefined}
                            setSearch={showSearch ? setSearch : undefined}
                            add={showAddButton ? () => {
                                setShow(true)
                            } : undefined}
                    />
                    <GlobalNavbar locationBase={"/admin/edition/data-collection/" + (dataCollection?.id ?? '')} state={dataCollection} items={items} />
                    {location.pathname.endsWith('/export-config')
                        ? <Outlet context={[show, setShow, search, setSearch, setShowAddButton, setShowSearch, dataCollection.id]} />
                        : <Outlet context={[dataCollection, setDataCollection, show, setShow, search, setSearch, setHandleAdd]} />
                    }
                </>
            }
        </>
    )
}

export default Navbar
