import React, {FC, useState} from 'react'
import {useTranslation} from "react-i18next";
import Header from "Components/Layout/Header";
import Table from "Components/Layout/Table";
import MailingIdentityTable from "Table/Admin/MailingIdentityTable";
import {Button} from "react-bootstrap";
import Api from "Vendor/Api";
import EmptyModal from "../../Components/Modal/EmptyModal";
import Spinner from "Components/Spinner";
import FormEmptyModal from "Components/Modal/FormEmptyModal";
import {Identity, IdentityDetails} from "Config/MailingIdentity";

const MailingIdentityPage: FC = () => {
    const { t } = useTranslation()
    const [toggleReload, setToggleReload] = useState<string>('init')
    const [show, setShow] = useState<boolean>(false)
    const [keyword, setKeyword] = useState<string>('')
    const [identities, setIdentities] = useState<Identity[]>([])
    const [identityDetails, setIdentityDetails] = useState<IdentityDetails | null>(null)
    const [identityDetailsOnLoad, setIdentityDetailsOnLoad] = useState<boolean>(false)
    const [identityDetailsOnError, setIdentityDetailsOnError] = useState<boolean>(false)
    const [showNewModal, setShowNewModal] = useState<boolean>(false)

    const addIdentity = () => {
        setShowNewModal(true)
    }

    const showIdentity = (identity: Identity) => {
        setShow(true)
        setIdentityDetailsOnLoad(true)

        let details = {IdentityName: identity.IdentityName} as IdentityDetails
        setIdentityDetails(details)

        Api.get('/admin/mailing-identity/domain/' + identity.IdentityName)
            .then((response) => {
                let details = response.data as IdentityDetails
                details.IdentityName = identity.IdentityName
                setIdentityDetails(details)
            })
            .catch(() => {
                setIdentityDetailsOnError(true)
            })
            .then(() => {
                setIdentityDetailsOnLoad(false)
            })
    }

    const deleteIdentity = (identity: Identity) => {

    }

    let table = MailingIdentityTable;

    const actionsRender = (identity: Identity) => (
        <>
            <Button variant="primary" size="sm" onClick={() => showIdentity(identity)}>
                <i className="fa-solid fa-eye me-2"></i>
                {t('app.show')}
            </Button>
            {/*<Button variant="danger" size="sm" onClick={() => {deleteIdentity(identity)}}>
                <i className="fa-solid fa-trash me-2"></i>
                {t('app.delete')}
            </Button>*/}
        </>
    );

    if (table.hasOwnProperty('actions')) {
        table.actions.render = actionsRender
    }

    if (process.env.NODE_ENV === 'development') {
        return <div
            style={{
                height: '100%',
                width: '100%',
                display: 'grid',
                placeItems: 'center',
                fontSize: '2rem',
                color: 'var(--bs-primary)'
            }}
        >
            Not available in dev mode
        </div>
    }

    return (
        <>
            <Header title={t('app.mailing_identity.title')} add={process.env.NODE_ENV === 'production' ? addIdentity : undefined} search={keyword} setSearch={setKeyword} />
            <Table path="/admin/mailing-identity/domain" rows={table}
                toggleReload={toggleReload}
                items={identities}
                setItems={setIdentities}
                keyword={keyword}
                setKeyword={setKeyword}
            />
            <EmptyModal show={show} size="lg" onClose={() => { setShow(false) }} title={identityDetails?.IdentityName}>
                {identityDetailsOnLoad
                    ? <Spinner />
                    : (identityDetailsOnError
                            ? <p className="text-danger">{t('app.error')}</p>
                            : <>
                                <h5>{t('app.dns.params')}</h5>
                                <div className="overflow-auto">
                                    <table className="table table-bordered">
                                        <thead>
                                            <th>{t('app.type')}</th>
                                            <th>{t('app.name')}</th>
                                            <th>{t('app.value')}</th>
                                        </thead>
                                        <tbody>
                                        {identityDetails?.DkimAttributes.Tokens.map(token =>
                                            <tr>
                                                <td>CNAME</td>
                                                <td>{token}._domainkey.bimbosoftware.com</td>
                                                <td>{token}.dkim.amazonses.com</td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                    )
                }
            </EmptyModal>
            <FormEmptyModal
                path={`/admin/mailing-identity/domain/add`}
                i18nTitle={'app.mailing_identity.add'}
                show={showNewModal}
                handleClose={() => {
                    setShowNewModal(false)
                }}
                onSuccess={() => {
                    setToggleReload(new Date().toISOString())
                    setShowNewModal(false)
                }}
            />
        </>
    )
}

export default MailingIdentityPage
