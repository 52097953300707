import {RouteObject} from "react-router-dom";
import MainPage from "../../Pages/Admin/Edition/DataCollection/MainPage";
import ListingPage from "../../Pages/Admin/Edition/DataCollection/ListingPage";
import ItemGroupPage from "../../Pages/Admin/Edition/DataCollection/ItemGroupPage";
import ExportConfigPage from "../../Pages/Admin/Edition/ExportConfigPage";

const DataCollectionRouting: RouteObject[] = [
    {
        index: true,
        element: <MainPage/>,
    },
    {
        path: 'listing',
        element: <ListingPage/>,
    },
    {
        path: 'export-config',
        element: <ExportConfigPage collection="data-collection" />,
    },
    {
        path: 'group',
        element: <ItemGroupPage/>,
    }
]

export default DataCollectionRouting