import React, {CSSProperties, FC, ReactNode, useContext, useEffect, useState} from "react";
import EntityInterface from "Vendor/Definition/EntityInterface";
import {useTranslation} from "react-i18next";
import Collection from "Config/Collection";
import File from "Entity/File";
import useHumanizeFileSizeUtils from "Vendor/Utils/HumanizeFileSizeUtils";
import {ThumbnailModalProps} from "./_CardGroup";
import { classUtils as c } from 'Vendor/Utils/ClassUtils';
import Product from "../../../Entity/Product";
import useTranslationDataUtils from "../../../Vendor/Utils/TranslationDataUtils";
import {
    mimeTypeAudio,
    mimeTypeDocument,
    mimeTypeImage,
    mimeTypeVideo,
    mimeTypeWithoutView,
    ImageExtensions
} from "Vendor/UploadSystem";
import Choice from "../../../Entity/Property/Choice";
import ListingSetting from "../../../Entity/Collection/ListingSetting";
import {UserContext} from "../../../Vendor/Context/UserContextProvider";
import {CollectionContext} from "../../../Vendor/Context/CollectionContextProvider";
import Brand from "../../../Entity/Brand";
import {AdditionnalData, CardData} from "./_Card";
import KanbanData from "../../../Entity/Collection/ListingSetting/View/KanbanData";

export interface _KanbanCardProps {
    entity?: Collection
    item: EntityInterface
    setThumbnailModalProps?: (thumbnailPath: ThumbnailModalProps) => void
    selectable: boolean
    selected?: number[]
    setSelected?: (selected: number[]) => void
    condensed: boolean
    hideDownloadButton?: boolean

    selectMultiple: boolean
    editAction?: (item: EntityInterface) => void
    isMain?: boolean
    setMain?: () => void
    isMainLoading?: boolean
    setting: ListingSetting
}

const _KanbanCard: FC<_KanbanCardProps> = ({
                                               entity,
                                               item,
                                               setThumbnailModalProps,
                                               selected,
                                               selectable,
                                               setSelected,
                                               condensed,
                                               hideDownloadButton = false,
                                               selectMultiple,
                                               editAction,
                                               isMain,
                                               isMainLoading,
                                               setMain,
                                               setting,
                                           }) => {
    const {t} = useTranslation()
    const {currentUser} = useContext(UserContext)
    const [data, setData] = useState<CardData|undefined>(undefined)
    const {humanFileSize} = useHumanizeFileSizeUtils()
    const {translate} = useTranslationDataUtils()
    const [canEdit, setCanEdit] = useState<boolean>(false)
    const [canDelete, setCanDelete] = useState<boolean>(false)
    const [properties, setProperties] = useState<AdditionnalData[]>([])
    const {getValue} = useContext(CollectionContext)
    const [hasPlatform, setHasPlatform] = useState<boolean>(currentUser?.currentOrganization?.platforms
        ? currentUser?.currentOrganization?.platforms?.length > 0
        : false)

    useEffect(() => {
        if (
            currentUser
            && setting.securities !== false
            && (
                (currentUser.administrator && setting.securities === true)
                || (item.group === null || item.group === undefined)
            )
            && (item.deletedAt === null || item.deletedAt === undefined)
        ) {
            setCanEdit(true)
            setCanDelete(true)
            return;
        } else if (
            typeof setting.securities === 'object'
            && null !== setting.securities
            && item.group !== null
            && item.group !== undefined
            && typeof item.group.id === 'number'
            && item.group.id > 0
            && setting.securities.hasOwnProperty(item.group.id)
        ) {
            setCanEdit(setting.securities[item.group.id].canModify)
            setCanDelete(setting.securities[item.group.id].canDelete)
            return;
        }

        setCanEdit(false)
        setCanDelete(false)
    }, [currentUser, item, setting])

    useEffect(() => {
        let tempData:CardData = {states: []}

        if (item.hasOwnProperty('createdAt') && item.createdAt) {
            tempData.createdAt = new Date(item.createdAt)
        }

        if (item.hasOwnProperty('updatedAt') && item.updatedAt) {
            tempData.updatedAt = new Date(item.updatedAt)
        }

        let tempAdditionnalData:AdditionnalData[] = [];
        setting.kanbanDatas.forEach((value: KanbanData) => {
            if(value.property) {
                tempAdditionnalData.push({
                    label: translate(value.property, 'label'),
                    value: getValue(item, value.property)
                })
            } else if (value.columnType) {
                switch(value.columnType) {
                    case "identifier":
                        if(item.id) {
                            tempAdditionnalData.push({
                                label: t(`app.table_columns.${value.columnType}`),
                                value: item.id
                            })
                        }
                        break;
                    case "createdBy":
                        if (item.createdBy) {
                            tempAdditionnalData.push({
                                label: t(`app.table_columns.${value.columnType}`),
                                value: item.createdBy.name
                            })
                        }
                        break;
                    case "createdAt":
                        if (item.createdAt) {
                            tempAdditionnalData.push({
                                label: t(`app.table_columns.${value.columnType}`),
                                value: item.createdAt
                            })
                        }
                        break;
                    case "updatedBy":
                        if (item.updatedBy) {
                            tempAdditionnalData.push({
                                label: t(`app.table_columns.${value.columnType}`),
                                value: item.updatedBy.name
                            })
                        }
                        break;
                    case "updatedAt":
                        if (item.updatedAt) {
                            tempAdditionnalData.push({
                                label: t(`app.table_columns.${value.columnType}`),
                                value: item.updatedAt
                            })
                        }
                        break;
                    case "group":
                        if (item.group) {
                            tempAdditionnalData.push({
                                label: t(`app.table_columns.${value.columnType}`),
                                value: item.group.name
                            })
                        }
                        break;
                    case "status":
                        if (item.status) {
                            tempAdditionnalData.push({
                                label: t(`app.table_columns.${value.columnType}`),
                                value: t(`app.item_status.${item.status}`)
                            })
                        }
                        break;
                    case "price":
                        if (item.price) {
                            tempAdditionnalData.push({
                                label: t(`app.table_columns.${value.columnType}`),
                                value: item.price
                            })
                        }
                        break;
                }
            }
        })
        setProperties(tempAdditionnalData)

        // States
        if (setting.states && setting.states.length) {
            setting.states.forEach(state => {
                if (state.slug === setting.kanbanTo?.slug) {
                    return
                }
                if (item[state.slug]) {
                    let choice: Choice | undefined = state.choices?.find((choice: Choice) => choice.id === item[state.slug].id)
                    if (choice) {
                        tempData.states.push({
                            name: translate(choice, 'label'),
                            color: choice.color ?? 'gray-light'
                        })
                    }
                }
            })
        }

        if (entity === 'bucket') {
            let file = item as File

            tempData.toState = file
            tempData.name = file.name
            tempData.withView = false

            if (file.mimeType) {
                if (file.mimeType === 'application/postscript') {
                    //use extension
                    let extension = file.name.split('.').pop()
                    tempData.badge = extension?.toUpperCase() ?? 'PS'
                } else {
                    tempData.badge = t(`app.mimes.${file.mimeType}`)
                }
                tempData.mimeType = file.mimeType
            }

            // States
            if (file.status) {
                let tempVariant = 'd-none'
                if (hasPlatform) {
                    if (file.status === 'published') {
                        tempVariant = 'success'
                    } else if (file.status === 'unpublished') {
                        tempVariant = 'danger'
                    }
                }

                tempData.states.push({
                    name: t(`app.item_status.${file.status}`),
                    variant: tempVariant
                })
            }

            if (file.__format || file.__size) {
                let tempMeta: string = '';
                if (file.__format) {
                    tempMeta += file.__format
                    if (file.__size) {
                        tempMeta += ' • '
                    }
                }
                if (file.__size) {
                    tempMeta += humanFileSize(file.__size)
                }
                tempData.meta = tempMeta
            }

            if (file.thumbnail) {
                tempData.imageStyle = {
                    large: {
                        'backgroundImage': `url('${file.thumbnail.replaceAll('__SIZING__', 'large')}')`
                    },
                    medium: {
                        'backgroundImage': `url('${file.thumbnail.replaceAll('__SIZING__', 'medium')}')`
                    },
                    small: {
                        'backgroundImage': `url('${file.thumbnail.replaceAll('__SIZING__', 'small')}')`
                    },
                    'extra-large': {
                        'backgroundImage': `url('${file.thumbnail.replaceAll('__SIZING__', 'extra-large')}')`
                    },
                }

                // todo preview
                tempData.thumbnail = file.thumbnail.replaceAll('__SIZING__', 'extra-large')
            }

            if (file.view) {
                tempData.view = file.view
                tempData.withView = true
            }

            if (tempData.thumbnail && tempData.mimeType && mimeTypeWithoutView.includes(tempData.mimeType)) {
                // @ts-ignore
                tempData.mimeType = ImageExtensions[tempData.thumbnail.split('.').pop()]
                tempData.withView = false
            }

            // todo add matchBy
            tempData.to = `/b/${file.bucketPrimarySlug}/${file.slug}`
        }
        else if (entity === 'catalog') {
            let product = item as Product

            tempData.toState = product
            tempData.name = translate(product, 'name')
            tempData.withView = false

            // States
            if (product.status) {
                let tempVariant = 'd-none'
                if (hasPlatform) {
                    if (product.status === 'published') {
                        tempVariant = 'success'
                    } else if (product.status === 'unpublished') {
                        tempVariant = 'danger'
                    }
                }

                tempData.states.push({
                    name: t(`app.item_status.${product.status}`),
                    variant: tempVariant
                })
            }

            if (product.thumbnail) {
                tempData.imageStyle = {
                    large: {
                        'backgroundImage': `url('${product.thumbnail.replaceAll('__SIZING__', 'large')}')`
                    },
                    medium: {
                        'backgroundImage': `url('${product.thumbnail.replaceAll('__SIZING__', 'medium')}')`
                    },
                    small: {
                        'backgroundImage': `url('${product.thumbnail.replaceAll('__SIZING__', 'small')}')`
                    },
                    'extra-large': {
                        'backgroundImage': `url('${product.thumbnail.replaceAll('__SIZING__', 'extra-large')}')`
                    },
                }

                // todo preview
                tempData.thumbnail = product.thumbnail.replaceAll('__SIZING__', 'extra-large')
            } else {
                let thumbnailSetted: boolean = false
                if (currentUser?.currentBrand && currentUser.brands?.filter(brand => brand.slug === currentUser.currentBrand).length) {
                    let brands = currentUser.brands?.filter(brand => brand.slug === currentUser.currentBrand);
                    let brand:Brand|null = null
                    if (brands && brands.length) {
                        brand = brands[0]
                    }
                    if (brand && brand.logo) {
                        tempData.thumbnail = brand.logo
                        thumbnailSetted = true
                    }
                }

                if (!thumbnailSetted && currentUser?.currentOrganization?.logo) {
                    tempData.thumbnail = currentUser?.currentOrganization?.logo
                }

                if (tempData.thumbnail) {
                    tempData.imageStyle = {
                        large: {
                            'backgroundImage': `url('${tempData.thumbnail}')`
                        },
                        medium: {
                            'backgroundImage': `url('${tempData.thumbnail}')`
                        },
                        small: {
                            'backgroundImage': `url('${tempData.thumbnail}')`
                        },
                        'extra-large': {
                            'backgroundImage': `url('${tempData.thumbnail}')`
                        },
                    }
                }
            }

            if (product.view) {
                tempData.view = product.view
                tempData.withView = true
            }

            if (product.viewMimeType) {
                tempData.mimeType = product.viewMimeType
            }

            if (tempData.thumbnail && tempData.mimeType && mimeTypeWithoutView.includes(tempData.mimeType)) {
                // @ts-ignore
                tempData.mimeType = ImageExtensions[tempData.thumbnail.split('.').pop()]
                tempData.withView = false
            }

            // todo add matchBy
            tempData.to = `/c/${product.catalogPrimarySlug}/${product.slug}`
        }

        setData(tempData)
    }, [entity, humanFileSize, item, t, setting.states])


    const handleDrag = (e: any) => {
        const column = e.target.closest("[data-column-id]")

        const columnId = column?.dataset?.columnId ?? null
        const itemId = e.target.dataset.itemId

        if (!itemId) {
            return
        }

        e.dataTransfer.setData('columnId', columnId)
        e.dataTransfer.setData('itemId', itemId)
    }

    if (!data) {
        return null
    }

    return (
        <div className={c('card card-item', selectable && item.id && selected && selected.includes(item.id) ? 'selected' : undefined)} data-item-id={item.id} draggable={true} onDragStart={handleDrag}>
            <div className="card-header">
                <div className="states">
                    {setting.cardShowBadge && condensed && data.badge && <span className="badge bg-gray-light">{data.badge}</span>}
                    {setting.cardShowStatus && data.states && data.states.length > 0 &&
                        <>
                            {data.states.map((state, key) => (<div className={c('badge', state.variant && `bg-${state.variant}`)} style={state.color ? {backgroundColor: state.color} : {}} key={key}>{state.name}</div>))}
                        </>
                    }
                </div>
                <div className="actions">
                    {selectable &&
                        <button
                            className="btn btn-icon btn-icon-only btn-icon-without-padding select-card stretched-link more-actions"
                            onClick={() => {
                                if (selectable && selected && setSelected && item.id) {
                                    let tempSelected = [...selected]
                                    if (tempSelected.includes(item.id)) {
                                        let index = tempSelected.indexOf(item.id)
                                        tempSelected.splice(index, 1)
                                    } else {
                                        if (!selectMultiple) {
                                            tempSelected = [item.id]
                                        } else {
                                            tempSelected.push(item.id)
                                        }
                                    }
                                    setSelected(tempSelected)
                                }
                            }}
                        >
                            <i className={c('fa-solid', `fa-${item.id && selected && selected.includes(item.id) ? 'check' : 'plus'}`)}></i>
                        </button>
                    }
                    {/*{!hideDownloadButton && data.view && <a href={data.view} target="_blank" className="download-file" rel="noreferrer"><i className="fa-solid fa-cloud-arrow-down"></i></a>}*/}
                    {!hideDownloadButton && data.view && <a href={`${data.view}?download=1`} target="_blank" className="download-file text-primary" rel="noreferrer"><i className="fa-solid fa-cloud-arrow-down"></i></a>}
                    {data.actions?.length && <a href="#" className="more-actions"><i className="fa-solid fa-ellipsis-vertical"></i></a>}
                    {isMain !== undefined &&
                        <button
                            className={c("main-action", isMain ? 'text-warning' : 'text-muted')}
                            onClick={() => {
                                setMain && setMain()
                            }}
                            type="button"
                            disabled={isMainLoading}
                        >
                            {isMainLoading
                                ? <i className="fa-solid fa-circle-notch fa-spin"></i>
                                : <i className={c("fa-star", isMain ? 'fa-solid' : 'fa-regular')}></i>
                            }
                        </button>
                    }
                </div>
            </div>
            {!condensed && data.thumbnail ?
                <div className="card-body">
                    <a href="#" onClick={() => {
                        if (setThumbnailModalProps) {
                            data.thumbnail && setThumbnailModalProps({
                                path: data.view ?? (data.mimeType?.includes("image") ? data.thumbnail?.replaceAll('__SIZING__', 'extra-large') : data.view ?? ''),
                                name: data.name ?? 'noname',
                                mimeType: data.mimeType ?? '',
                            })
                        }
                    }} className="img" style={data.imageStyle && (condensed ? data.imageStyle.medium : data.imageStyle.large)}>
                        {data.badge && !condensed && <span className="badge bg-gray-light">{data.badge}</span>}
                    </a>
                </div>
                :
                mimeTypeVideo.includes(data.mimeType as string) ?
                    <div className="card-body">
                        <a href="#" onClick={() => {
                            if (setThumbnailModalProps) {
                                data.thumbnail && setThumbnailModalProps({
                                    path: data.view ?? (data.mimeType?.includes("image") ? data.thumbnail?.replaceAll('__SIZING__', 'extra-large') : data.view ?? ''),
                                    name: data.name ?? 'noname',
                                    mimeType: data.mimeType ?? '',
                                })
                            }
                        }} className="img" style={data.imageStyle && (condensed ? data.imageStyle.medium : data.imageStyle.large)}>
                            <i className="fa-regular fa-file-video" style={{ fontSize: condensed ? '2rem' : '3rem', color: '#323746' }}></i>
                        </a>
                    </div>
                    :
                    mimeTypeAudio.includes(data.mimeType as string) ?
                        <div className="card-body">
                            <a href="#" onClick={() => {
                                if (setThumbnailModalProps) {
                                    data.thumbnail && setThumbnailModalProps({
                                        path: data.view ?? (data.mimeType?.includes("image") ? data.thumbnail?.replaceAll('__SIZING__', 'extra-large') : data.view ?? ''),
                                        name: data.name ?? 'noname',
                                        mimeType: data.mimeType ?? '',
                                    })
                                }
                            }} className="img" style={data.imageStyle && (condensed ? data.imageStyle.medium : data.imageStyle.large)}>
                                <i className="fa-regular fa-file-audio" style={{ fontSize: condensed ? '2rem' : '3rem', color: '#323746' }}></i>
                            </a>
                        </div>
                        :
                        mimeTypeDocument.includes(data.mimeType as string) ?
                            <div className="card-body">
                                <a href="#" onClick={() => {
                                    if (setThumbnailModalProps) {
                                        data.thumbnail && setThumbnailModalProps({
                                            path: data.view ?? (data.mimeType?.includes("image") ? data.thumbnail?.replaceAll('__SIZING__', 'extra-large') : data.view ?? ''),
                                            name: data.name ?? 'noname',
                                            mimeType: data.mimeType ?? '',
                                        })
                                    }
                                }} className="img" style={data.imageStyle && (condensed ? data.imageStyle.medium : data.imageStyle.large)}>
                                    <i className="fa-regular fa-file-alt" style={{ fontSize: condensed ? '2rem' : '3rem', color: '#323746' }}></i>
                                </a>
                            </div>
                            :
                            <div className="card-body">
                                <a href="#" onClick={() => {
                                    if (setThumbnailModalProps) {
                                        data.thumbnail && setThumbnailModalProps({
                                            path: data.view ?? (data.mimeType?.includes("image") ? data.thumbnail?.replaceAll('__SIZING__', 'extra-large') : data.view ?? ''),
                                            name: data.name ?? 'noname',
                                            mimeType: data.mimeType ?? '',
                                        })
                                    }
                                }} className="img" style={data.imageStyle && (condensed ? data.imageStyle.medium : data.imageStyle.large)}>
                                </a>
                            </div>
            }
            <div className="card-body d-flex flex-column">
                {data.name && <h5 className={c("card-title", canEdit && editAction && 'card-title-clickable')} onClick={() => {
                    editAction && editAction(item)
                }}>{data.name}</h5>}
            </div>
            <div className="card-footer">
                {!condensed &&
                    <div className="row justify-content-evenly card-tags">
                        {properties.map((property, key) =>
                            <span className="col-auto mb-3 card-tag text-decoration-none" key={key}>
                                <span className="fw-bold">{property.label}</span>
                                <br/>
                                {property.value}
                            </span>)}
                    </div>
                }
                {data.tags && data.tags.length &&
                    <div className="row justify-content-evenly card-tags">
                        {data.tags.map((tag, key) => (<span className="col-auto mb-3 card-tag" key={key}>{tag}</span>))}
                    </div>
                }
                {setting.cardShowSchedule && !condensed && (data.createdAt || data.updatedAt) &&
                    <p className="card-meta card-meta-date">
                        {data.createdAt && <>
                            {t('app.table_columns.createdAt')} <span data-bs-toggle="tooltip" data-bs-title="Par Thibaud Favareille">{data.createdAt.toLocaleDateString()}</span>{data.updatedAt && ' • '}
                        </>}
                        {data.updatedAt && <>{t('app.table_columns.updatedAt')} <span data-bs-toggle="tooltip" data-bs-title="Par Florian Le Menach">{data.updatedAt.toLocaleDateString()}</span></>}
                    </p>
                }
                {setting.cardShowSizeAndWeight && !condensed && data.meta &&
                    <span className="bg-gray-light">{data.meta}</span>
                }
                {data.uses && data.uses.length &&
                    <div className="card-uses">
                        {data.uses.map((use, key) => (
                            <span key={key} className="badge bg-secondary">
                                <i className={`fa-brands fa-${use.icon}`}></i>{use.count}
                            </span>
                        ))}
                    </div>
                }
            </div>
        </div>
    )
}

export default _KanbanCard
