import React, {FC, MutableRefObject, useContext, useEffect, useState} from 'react'
import EntityInterface from "Vendor/Definition/EntityInterface";
import View from "Config/View";
import {Alert} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import _Table, {generateRows} from "./View/_Table";
import ListingSetting from "Entity/Collection/ListingSetting";
import {Rows} from "Components/Layout/Table";
import {CustomAction, ListingItems, OptionsType, PaginationMode} from "./Listing";
import {UserContext} from "../../Vendor/Context/UserContextProvider";
import Organization from "../../Entity/Organization";
import Collection from "../../Config/Collection";
import _CardGroup, {ThumbnailModalProps} from "./View/_CardGroup";
import _PreviewModal from "./Modal/_PreviewModal";
import TableColumn from "../../Entity/Collection/ListingSetting/View/TableColumn";
import _Kanban from "./View/_Kanban";

export interface _ContentProps {
    isLoaded: boolean
    onError: boolean
    setting: ListingSetting
    items: ListingItems
    setItems: (items: ListingItems) => void
    editAction?: (item: EntityInterface) => void
    editUri?: string
    deleteAction?: (item: EntityInterface) => void
    view: View
    options?: OptionsType
    entity?: Collection
    selectable: boolean
    selected: number[]
    setSelected: (selected: number[]) => void
    selectMultiple: boolean
    visibleColumns: TableColumn[]
    condensed: boolean
    customActions?: CustomAction[]
    hideDownloadButton?: boolean
    paginationMode: PaginationMode
    loader: MutableRefObject<null>
}

const _Content: FC<_ContentProps> = ({
                                         isLoaded,
                                         onError,
                                         setting,
                                         items,
                                         setItems,
                                         editAction,
                                         editUri,
                                         deleteAction,
                                         view,
                                         options,
                                         entity,
                                         selectable,
                                         selected,
                                         setSelected,
                                         selectMultiple,
                                         visibleColumns,
                                         condensed,
                                         customActions,
                                         hideDownloadButton = false,
                                         paginationMode,
                                         loader,
                                     }) => {
    const {t} = useTranslation()
    const {currentUser} = useContext(UserContext)
    const [tableRows, setTableRows] = useState<Rows>(
        generateRows(visibleColumns, currentUser?.currentOrganization ?? {} as Organization, entity, setting)
    )
    const [thumbnailModalProps, setThumbnailModalProps] = useState<ThumbnailModalProps | undefined>(undefined)

    useEffect(() => {
        setTableRows(generateRows(visibleColumns, currentUser?.currentOrganization ?? {} as Organization, entity, setting));
    }, [currentUser?.currentOrganization, entity, visibleColumns])

    if (view === 'card') {
        return <>
            <_CardGroup
                isLoaded={isLoaded}
                items={items}
                editAction={editAction}
                onError={onError}
                entity={entity}
                selectable={selectable}
                selected={selected}
                setSelected={setSelected}
                selectMultiple={selectMultiple}
                condensed={condensed}
                setThumbnailModalProps={setThumbnailModalProps}
                hideDownloadButton={hideDownloadButton}
                paginationMode={paginationMode}
                loader={loader}
                setting={setting}
            />
            <_PreviewModal thumbnailModalProps={thumbnailModalProps} setThumbnailModalProps={setThumbnailModalProps}/>
        </>
    }

    if (view === 'kanban') {
        return <>
            <_Kanban
                isLoaded={isLoaded}
                items={items}
                setItems={setItems}
                editAction={editAction}
                onError={onError}
                entity={entity}
                selectable={selectable}
                selected={selected}
                setSelected={setSelected}
                selectMultiple={selectMultiple}
                condensed={condensed}
                setThumbnailModalProps={setThumbnailModalProps}
                hideDownloadButton={hideDownloadButton}
                paginationMode={paginationMode}
                loader={loader}
                setting={setting}
            />
            <_PreviewModal thumbnailModalProps={thumbnailModalProps} setThumbnailModalProps={setThumbnailModalProps}/>
        </>
    }

    return (
        <>
            {view !== 'table' && <Alert key={0} variant="danger">{t('error.view_error')}</Alert>}
            <_Table
                isLoaded={isLoaded}
                rows={tableRows}
                items={items}
                editAction={editAction}
                editUri={editUri}
                deleteAction={deleteAction}
                onError={onError}
                options={options}
                selectable={selectable}
                selected={selected}
                setSelected={setSelected}
                selectMultiple={selectMultiple}
                entity={entity}
                key={1}
                setThumbnailModalProps={setThumbnailModalProps}
                setting={setting}
                customActions={customActions}
            />
            <_PreviewModal thumbnailModalProps={thumbnailModalProps} setThumbnailModalProps={setThumbnailModalProps} />
        </>
    )
}

export default _Content
