import React, {FC, ReactNode} from 'react'
import {Container} from "react-bootstrap";

interface BlankPageProps {
    children: ReactNode
}

const BlankPage: FC<BlankPageProps> = ({
                                           children,
                                       }) =>
<div  className="p-3 mb-3 rounded-4 bg-light">
    <Container>
        {children}
    </Container>
</div>
export default BlankPage
