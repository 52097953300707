import React, {FC, useContext, useEffect, useState} from 'react'
import Listing from "Components/Listing/Listing";
import {useOutletContext} from "react-router-dom";
import {UserContext} from "Vendor/Context/UserContextProvider";
import ListingSetting from "Entity/Collection/ListingSetting";
import Contact from "../../Entity/Contact";
import Api from "../../Vendor/Api";
import {
    FormOptionsInterface,
    FormPropertiesFieldInterface
} from "../../Vendor/Components/Form";
import EntityInterface from "../../Vendor/Definition/EntityInterface";
import DeleteModal from "../../Components/Modal/DeleteModal";
import FormEmptyOffCanvas from "../../Components/Modal/FormEmptyOffCanvas";
import {EditableProperty} from "../../Entity/Organization";
import _ImportModalTags from "../../Components/Modal/_ImportModalTags";
import Tag from 'Entity/Tag';

export interface ContactProps {

}

const ContactPage: FC<ContactProps> = ({}) => {
    const { currentUser } = useContext(UserContext)
    const [show, setShow, search, setSearch, disabled, setDisabled, setHandleAdd, setMatchingProperties] = useOutletContext<[boolean, (show: boolean) => void, string, (search: string) => void, boolean, (disabled: boolean) => void, (handleAdd: (() => void) | undefined) => void, (matchingProperties: EditableProperty[]) => void]>();
    const [setting] = useState<ListingSetting|undefined>(currentUser?.currentOrganization?.contactListingSetting)
    const [contact, setContact] = useState<Contact>({} as Contact)
    const [toggleReload, setToggleReload] = useState<string>('')
    const [propertyFields, setPropertyFields] = useState<FormPropertiesFieldInterface|undefined>(undefined)
    const [formOptions, setFormOptions] = useState<FormOptionsInterface|undefined>(undefined)
    const [localTags, setLocalTags] = useState<Tag[]>([])
    const [isLoaded, setIsLoaded] = useState<boolean>(false)

    useEffect(() => {
        const editableProperties = currentUser?.currentOrganization?.editableProperties;
        if (Array.isArray(editableProperties)) {
            const matchingProperties: EditableProperty[] = [];
            for (const property of editableProperties) {
                if (property.collection.contact) {
                    matchingProperties.push(property);
                }
            }
            if (setMatchingProperties) {
                setMatchingProperties(matchingProperties);
            }
        }
    }, [currentUser?.currentOrganization?.editableProperties, setMatchingProperties]);

        useEffect(() => {
        setHandleAdd(() => {
            setContact({} as Contact)
        })
    }, [setHandleAdd])

    const handleClose = (reload = false) => {
        setShow(false)
        if (reload) {
            setToggleReload(new Date().toISOString())
        }
    }

    const onExited = () => {
        setContact({} as Contact)
    }

    const onSuccess = (contact: Contact) => {
        setContact(contact)
        setShow(false)
        setToggleReload(new Date().toISOString())
    }

    const editAction = (contact: EntityInterface) => {
        setContact(contact as Contact)
        setShow(true)
    }

    const deleteAction = (contact: EntityInterface) => {
        setContact(contact as Contact)
        handleDeleteShow()
    }

    useEffect(() => {
        if(contact.tags){
            setLocalTags([...contact.tags])
        }
    }, [contact])

    // remove
    const [showDelete, setShowDelete] = useState<boolean>(false)
    const handleDeleteClose = (reload = false) => {
        setContact({} as Contact)
        setShowDelete(false)
        if (reload) {
            setToggleReload(new Date().toISOString())
        }
    }
    const handleDeleteShow = () => setShowDelete(true)

    useEffect(() => {
        setDisabled(true)
        Api.get(`/crm/contact/${contact.id ?? 'new'}`).then(response => {
            const formOptions: FormOptionsInterface = response.data
            setFormOptions(formOptions)
            setPropertyFields(formOptions.propertyFields)
            setDisabled(false)
        })
    }, [])

    if (!setting) return <></>

    return (
        <>
            <Listing
                setting={setting}
                search={search}
                setSearch={setSearch}
                path='/crm/contact'
                entity='contact'
                toggleReload={toggleReload}
                setToggleReload={setToggleReload}
                editAction={editAction}
                deleteAction={deleteAction}
            />
            <FormEmptyOffCanvas
                i18nTitle={contact.id ? 'app.edit_contact' : 'app.add_contact'}
                show={show}
                handleClose={handleClose}
                onSuccess={onSuccess}
                path={`/crm/contact/${contact.id ?? 'new'}`}
                formPath={!!contact.id}
                method={contact.id ? 'patch' : 'post'}
                onExited={onExited}
                disabled={!contact.id ? false : contact.deletedAt !== null}
                isLoading={isLoaded}
                setIsLoading={setIsLoaded}
                additionalData={{
                    tags: localTags,
                }}
                appendForm={contact && contact.enableTags && <_ImportModalTags tags={localTags} setTags={setLocalTags} onLoad={isLoaded} headingLevel={3}/>}
            />
            <DeleteModal
                handleClose={handleDeleteClose}
                show={showDelete}
                path={`/crm/contact/${contact.id}`}
                name={`#${contact.id} - ${contact.firstName} ${contact.lastName}`}
                archive={true}
            />
        </>
    )
}

export default ContactPage
