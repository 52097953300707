import React, {FC, MutableRefObject, ReactNode, useContext, useEffect, useState, useRef} from "react";
import EntityInterface from "Vendor/Definition/EntityInterface";
import Spinner from "../../Spinner";
import _Card from "./_Card";
import Collection from "Config/Collection";
import { classUtils as c } from 'Vendor/Utils/ClassUtils'
import {ListingItems, PaginationMode} from "../Listing";
import ListingSetting from "../../../Entity/Collection/ListingSetting";
import {ThumbnailModalProps} from "./_CardGroup";
import Choice from "../../../Entity/Property/Choice";
import {useTranslation} from "react-i18next";
import useTranslationDataUtils from "../../../Vendor/Utils/TranslationDataUtils";
import _KanbanCard from "./_KanbanCard";
import Api from "Vendor/Api";

export interface _KanbanProps {
    entity?: Collection
    isLoaded: boolean
    onError: boolean
    items: ListingItems
    setItems: (items: ListingItems) => void
    editAction?: (item: EntityInterface) => void
    selectable: boolean
    selected: number[]
    setSelected: (selected: number[]) => void
    selectMultiple: boolean
    condensed: boolean
    setThumbnailModalProps: (thumbnailPath: ThumbnailModalProps) => void
    hideDownloadButton?: boolean
    paginationMode: PaginationMode
    loader: MutableRefObject<null>
    setting: ListingSetting
}

const _Kanban: FC<_KanbanProps> = ({
                                             entity,
                                             isLoaded,
                                             onError,
                                             items,
                                             setItems,
                                             editAction,
                                             selected,
                                             setSelected,
                                             selectable,
                                             selectMultiple,
                                             condensed,
                                             setThumbnailModalProps,
                                             hideDownloadButton,
                                             paginationMode,
                                             loader,
                                             setting
}) => {
    const {t} = useTranslation()
    const {translate} = useTranslationDataUtils()
    const [columns, setColumns] = useState<Choice[]>([])
    const [discriminator, setDiscriminator] = useState<string>('')
    const [nullItems, setNullItems] = useState<ListingItems[]>([])
    const [groupedItems, setGroupedItems] = useState<{
        [key: string|number]: ListingItems[]
    }>({})
    const [offsetHeight, setOffsetHeight] = useState<number>(0)
    const kanbanRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const handleResize = () => {
            if (kanbanRef.current) {
                setOffsetHeight(kanbanRef.current.getBoundingClientRect().top +  window.scrollY + 100)
            }
        }

        window.addEventListener('resize', handleResize)

        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [items, discriminator])

    useEffect(() => {
        let tempGroupedItems: {[key: string]: ListingItems[]} = {}
        let tempNullItems: ListingItems[] = []

        for (const [index, item] of Object.entries(items)) {
            let discriminatorValue = item[discriminator]

            if (discriminatorValue === null || discriminatorValue === undefined) {
                discriminatorValue = 'null'
            } else if (typeof discriminatorValue === 'object') {
                if (discriminatorValue.hasOwnProperty('value')) {
                    discriminatorValue = discriminatorValue.value
                } else if (discriminatorValue.hasOwnProperty('id')) {
                    discriminatorValue = discriminatorValue.id
                } else {
                    discriminatorValue = 'null'
                }
            }

            if (discriminatorValue !== 'null') {
                if (!tempGroupedItems.hasOwnProperty(discriminatorValue)) {
                    tempGroupedItems[discriminatorValue] = []
                }
                tempGroupedItems[discriminatorValue].push(item)
            } else {
                tempNullItems.push(item)
            }
        }

        setGroupedItems(tempGroupedItems)
        setNullItems(tempNullItems)
    }, [items, discriminator])

    useEffect(() => {
        if (entity === 'shopping' || entity === 'pre-order') {
            // @todo add kanban columns
            setDiscriminator('status')
            setColumns([
                {value: 'draft', translations: {'fr': {'label': 'Brouillon',}}, color: '#6c757d'},
                {value: 'sent', translations: {'fr': {'label': 'Envoyé',}}, color: '#ffc107'},
                {value: 'validated', translations: {'fr': {'label': 'Validé',}}, color: '#28a745'},
                {value: 'refused', translations: {'fr': {'label': 'Refusé',}}, color: '#dc3545'},
                {value: 'outputted', translations: {'fr': {'label': 'Sortie',}}, color: '#17a2b8'},
                {value: 'finalized', translations: {'fr': {'label': 'Finalisé',}}, color: '#007bff'},
            ])
        } else if (setting.kanbanTo) {
            setDiscriminator(setting.kanbanTo.slug)
            setColumns([...setting.kanbanTo.choices])
        } else {
            // @todo error ?
        }
    }, [setting, entity])

    const handleDrop = (e: any) => {
        e.preventDefault()
        e.stopPropagation()

        const column = e.target as HTMLElement

        const currentColumnDiscriminator = e.dataTransfer.getData('columnId')
        const nextColumnDiscriminator    = e.target.dataset.columnId
        const itemId                     = e.dataTransfer.getData('itemId')

        if (column) {
            let tempItems = { ...items }
            for (const [index, item] of Object.entries(tempItems)) {
                if (item.id === parseInt(itemId, 10)) {
                    item[discriminator] = nextColumnDiscriminator
                }
            }
            setItems(tempItems)

            Api.patch(`/kanban/property/${entity}/${itemId}`, { [discriminator]: nextColumnDiscriminator })
                .then((response) => {
                })
                .catch((error) => {
                    let tempItems = { ...items }
                    for (const [index, item] of Object.entries(tempItems)) {
                        if (item.id === parseInt(itemId, 10)) {
                            item[discriminator] = currentColumnDiscriminator
                        }
                    }
                    setItems(tempItems)
                });
            
            const container = column.closest('div')
            if (container) {
                container.classList.remove('hovered')
            }
        }
    }

    const handleDragOver = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const handleDragEnter = (e: any) => {
        e.preventDefault()
        e.stopPropagation()

        const column = e.target as HTMLElement
        if (!column.classList.contains('kanban-column')) {
            return
        }

        const container = column.closest('div')
        if (container) {
            container.classList.add('hovered')
        }
    }

    const handleDragLeave = (e: any) => {
        e.preventDefault()
        e.stopPropagation()

        const column = e.target as HTMLElement
        if (!column.classList.contains('kanban-column')) {
            return
        }

        const container = column.closest('div')
        if (container) {
            container.classList.remove('hovered')
        }
    }

    return (
        <div className="row kanban"
            ref={kanbanRef}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
        >
            {/* Null columns */}
            {nullItems.length > 0 && (
                <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <h3 className="mt-3">{t('app.empty')}</h3>
                    <div className="card-list kanban-column" style={{ height: `calc(100vh - ${offsetHeight}px)` }}>
                        {nullItems.map((item, key) => {
                            return <_KanbanCard
                                key={key}
                                item={item}
                                entity={entity}
                                setThumbnailModalProps={setThumbnailModalProps}
                                selected={selected}
                                condensed={condensed}
                                hideDownloadButton={hideDownloadButton}
                                selectable={selectable}
                                setSelected={setSelected}
                                selectMultiple={selectMultiple}
                                editAction={editAction}
                                setting={setting}
                            />
                        })}
                    </div>
                </div>
            )}
            {columns.map((column, index) => {
                let currentColumns: ListingItems[] = []
                let columnId = column.value || column.id || null;

                if (groupedItems.hasOwnProperty(column.value)) {
                    currentColumns = [...groupedItems[column.value]]
                }
                if (column.id && groupedItems.hasOwnProperty(column.id)) {
                    currentColumns = [...currentColumns, ...groupedItems[column.id]]
                }

                return <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <h3 className="mt-3" style={{color: column.color}}>{translate(column, 'label')}</h3>
                    <div className="card-list kanban-column" data-column-id={columnId} style={{ height: `calc(100vh - ${offsetHeight}px)` }}>
                        {currentColumns.map((item, key) => {
                            return <_KanbanCard
                                key={key}
                                item={item}
                                entity={entity}
                                setThumbnailModalProps={setThumbnailModalProps}
                                selected={selected}
                                selectable={selectable}
                                condensed={condensed}
                                hideDownloadButton={hideDownloadButton}
                                setSelected={setSelected}
                                selectMultiple={selectMultiple}
                                editAction={editAction}
                                setting={setting}
                            />
                        })}
                    </div>
                </div>
            })}
        </div>
    )
}

export default _Kanban
