import React, {FC, useContext, useEffect, useState} from "react";
import {Outlet} from "react-router-dom";
import {Navbar as GlobalNavbar, NavbarItem} from "Components/Layout/Navbar";
import Header from "Components/Layout/Header";
import {UserContext} from "../../../../../Vendor/Context/UserContextProvider";
import {useTranslation} from "react-i18next";

const _Navbar: FC = () => {
    const { currentUser } = useContext(UserContext)
    const [search, setSearch] = useState<string>('')
    const [show, setShow] = useState<boolean>(false)
    const [items, setItems] = useState<NavbarItem[]>([])
    const [disabled, setDisabled] = useState<boolean>(true)
    const [handleAdd, setHandleAdd] = useState<(() => void)|undefined>(undefined)
    const { t } = useTranslation()

    useEffect(() => {
        let items: NavbarItem[] = [
            {
                i18nLabel: 'app.contact_groups',
                path: 'contact-group'
            },
            {
                i18nLabel: 'app.company_groups',
                path: 'company-group'
            },
            {
                i18nLabel: 'app.deal_groups',
                path: 'deal-group'
            },
        ]
        setItems(items)
    }, [])

    return (
        <>
            <Header title={t('app.crm_groups')} search={search} setSearch={setSearch} add={() => {
                handleAdd !== undefined && handleAdd()
                setShow(true)
            }}
            />
            <GlobalNavbar locationBase='/admin/edition/crm' items={items} />
            <Outlet context={[show, setShow, search, setSearch, disabled, setDisabled, setHandleAdd]} />
        </>
    )
}

export default _Navbar
