import React, {FC} from 'react'
import {BrowserRouter} from 'react-router-dom'
import Routing from 'Vendor/Components/Routing'
import UserContextProvider from 'Vendor/Context/UserContextProvider'
import CollectionContextProvider from 'Vendor/Context/CollectionContextProvider'
import BannerContextProvider from "./Context/BannerContextProvider";
import ListingContextProvider from './Context/ListingContextProvider'
import routes from 'Routing/AppRouting'
import 'bootstrap/dist/js/bootstrap.bundle.min';
import UploadSystem from "./UploadSystem";

const App: FC = () => (
    <BannerContextProvider>
        <UserContextProvider>
            <CollectionContextProvider>
                <ListingContextProvider>
                    <BrowserRouter>
                        <UploadSystem>
                            <Routing routes={routes} />
                        </UploadSystem>
                    </BrowserRouter>
                </ListingContextProvider>
            </CollectionContextProvider>
        </UserContextProvider>
    </BannerContextProvider>
)

export default App