import React, { FC, useContext, useEffect, useState } from 'react'
import {Outlet, useLocation, useNavigate, useParams} from 'react-router-dom'
import { UserContext } from 'Vendor/Context/UserContextProvider'
import { classUtils as c } from 'Vendor/Utils/ClassUtils'
import Access from "Config/Access";

export const BrandedRoute: FC<{
    path: string,
    param: string,
}> = ({path, param}) => {
    const navigate = useNavigate()
    const [isGranted, setIsGranted] = useState(false)
    const { currentUser } = useContext(UserContext)
    const params = useParams()
    const location = useLocation()

    useEffect(() => {
        let isGranted: boolean = false
        if (currentUser && currentUser.currentOrganization && params.hasOwnProperty(param)) {
            let currentParam = params[param];
            let currentBrand: undefined|null|string = undefined

            // Bucket
            if (path === 'b') {
                currentUser.currentOrganization.buckets.forEach(bucket => {
                    if (bucket.primarySlug === currentParam) {
                        currentBrand = bucket.brand?.slug ?? null
                    }
                })

            }
            // Catalog
            else if (path === 'c') {
                currentUser.currentOrganization.catalogs.forEach(catalog => {
                    if (catalog.primarySlug === currentParam) {
                        currentBrand = catalog.brand?.slug ?? null
                    }
                })
            }
            // Data Collection
            else if (path === 'd') {
                currentUser.currentOrganization.dataCollections.forEach(dataCollection => {
                    if (dataCollection.primarySlug === currentParam) {
                        currentBrand = dataCollection.brand?.slug ?? null
                    }
                })
            }

            isGranted = currentBrand !== undefined && ((currentBrand && currentBrand === currentUser.currentBrand) || (!currentBrand && !currentUser.currentBrand))
        } else {
            isGranted = false
        }

        setIsGranted( isGranted )
        if (!isGranted) {
            navigate('/')
        }
    }, [currentUser])

    return isGranted ? (
        <Outlet />
    ) : null
}
