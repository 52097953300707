import React, {FC, useContext, useEffect, useState} from 'react'
import {UserContext} from "Vendor/Context/UserContextProvider";
import TranslationType from "./TranslationType";
import TranslationTypeType from "Vendor/Config/Form/Type/TransationType"
import {TranslationField} from "../../../../Components/Listing/Listing";

export interface FieldType {
    type: TranslationTypeType
    name: string
    label?: string
    i18nLabel?: string
    i18nLabels?: TranslationField
}

interface TranslationProps {
    id: string
    name: string
    fullName: string
    values: TranslationValues
    setValues?: (values: TranslationValues) => void
    fields: FieldType[]
    locales?: string[]
    default_locale?: string
    required_locales?: string[]
}
export interface TranslationValue {
    [key: string]: string
}
export interface TranslationValues {
    [key: string]: TranslationValue
}

const TranslationsType: FC<TranslationProps> = ({
    id,
    name,
    fullName,
    fields,
    locales,
    default_locale,
    required_locales,
    values,
    setValues,
}) => {
    const { currentUser } = useContext(UserContext)
    const [languages, setLanguages] = useState<string[]>([])
    const [defaultLanguage, setDefaultLanguage] = useState<string>('')

    useEffect(() => {
        if (typeof locales === 'undefined') {
            if (!currentUser) {
                throw new Error('Current User not found');
            }
            if (!currentUser.currentOrganization) {
                throw new Error('Current Organization not found');
            }
            setLanguages(currentUser.currentOrganization.languages)
        }
        else {
            setLanguages(locales)
        }

        let defaultLocale: string|undefined = default_locale ?? undefined;
        if (typeof default_locale === 'undefined') {
            if (!currentUser) {
                throw new Error('Current User not found');
            }
            if (!currentUser.currentOrganization) {
                throw new Error('Current Organization not found');
            }

            defaultLocale = currentUser.currentOrganization.defaultLanguage;
        }

        if (!defaultLocale) {
            throw new Error('Default locale not found');
        }

        setDefaultLanguage(defaultLocale)
    }, [])

    useEffect(() => {
        if (languages.length && defaultLanguage.length) {
            if (!languages.includes(defaultLanguage)) {
                throw new Error('Invalid default language ' + defaultLanguage + ', is not in languages : ' + languages.join(', '));
            }
        }
    }, [languages, defaultLanguage])

    const getLocalValue = (locale: string, name:string): string => {
        if (values && values.hasOwnProperty(locale) && values[locale].hasOwnProperty(name)) {
            return values[locale][name]
        }

        return ''
    }

    const setLocalValue = (locale: string, name:string, value:string) => {
        let tempValues:TranslationValues = Object.assign({}, values)
        if (!tempValues.hasOwnProperty(locale)) {
            tempValues[locale] = {};
        }

        tempValues[locale][name] = value
        setValues && setValues(tempValues)
    }

    return (
        <>
            {languages.map((language) => (
                <>
                    {fields.map((field, fieldIndex) => (
                        <>
                            <TranslationType
                                key={fieldIndex}
                                type={field.type}
                                initialValue={getLocalValue(language, field.name)}
                                id={`${id}_${language}_${field.name}`}
                                fullName={`${fullName}[${language}][${field.name}]`}
                                name={field.name}
                                language={language}
                                required={language === defaultLanguage || typeof (required_locales && required_locales.length > 0 && required_locales.includes(language)) !== 'undefined'}
                                setTranslationValue={(translationValue) => {
                                    setLocalValue(language, field.name, translationValue)
                                }}
                            />
                        </>
                    ))}
                </>
            ))}
        </>
    )
}

export default TranslationsType
