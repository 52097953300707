import React, {FC, useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import Table from "Components/Layout/Table";
import {Button} from "react-bootstrap";
import FormEmptyModal from "Components/Modal/FormEmptyModal";
import EmailTable from "Table/Admin/Edition/MailingIdentity/EmailTable";
import {useOutletContext} from "react-router-dom";
import Sender from "Entity/Mailing/Sender";
import DeleteModal from "Components/Modal/DeleteModal";

const EmailPage: FC = () => {
    const { t } = useTranslation()
    const [show, setShow, search, setSearch, disabled, setDisabled] = useOutletContext<[boolean, (show:boolean) => void, string, (search:string) => void, boolean, (disabled: boolean) => void]>()
    const [toggleReload, setToggleReload] = useState<string>('init')
    const [senders, setSenders] = useState<Sender[]>([])
    const [sender, setSender] = useState<Sender|undefined>(undefined)
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

    useEffect(() => {
        setDisabled(false)

        return () => {
            setDisabled(true)
        }
    }, [setDisabled])

    const handleEdit = (sender: Sender) => {
        setSender(sender)
        setShow(true)
    }
    const handleDelete = (sender: Sender) => {
        setSender(sender)
        setShowDeleteModal(true)
    }

    let table = EmailTable;

    const actionsRender = (sender: Sender) => (
        <>
            <Button variant="primary" size="sm" onClick={() => handleEdit(sender)}>
                <i className="fa-solid fa-pencil me-2"></i>
                {t('app.edit')}
            </Button>
            <Button variant="danger" size="sm" onClick={() => {handleDelete(sender)}}>
                <i className="fa-solid fa-trash me-2"></i>
                {t('app.delete')}
            </Button>
        </>
    );

    if (table.hasOwnProperty('actions')) {
        table.actions.render = actionsRender
    }

    return (
        <>
            <Table path="/admin/edition/mailing-identity/email" rows={table}
                toggleReload={toggleReload}
                items={senders}
                setItems={setSenders}
                keyword={search}
                setKeyword={setSearch}
            />
            <FormEmptyModal
                path={`/admin/edition/mailing-identity/email/${sender?.id ?? 'add'}`}
                i18nTitle={`app.mailing_identity.email.${sender?.id ? 'edit' : 'add'}`}
                formPath={!!sender?.id}
                method={sender?.id ? 'patch' : 'post'}
                show={show}
                handleClose={() => {
                    setSender(undefined)
                    setShow(false)
                }}
                onSuccess={() => {
                    setToggleReload(new Date().toISOString())
                    setShow(false)
                }}
            />
            <DeleteModal
                handleClose={() => {setShowDeleteModal(false)}}
                show={showDeleteModal}
                path={`/admin/edition/mailing-identity/email/${sender?.id ?? 'error'}/delete`}
                name={'#' + (sender ? `${sender.name} <${sender.email}>` : 'error')}
            />
        </>
    )
}

export default EmailPage
